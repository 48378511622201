<div>
    <h1 mat-dialog-title>Turnkey</h1>
    <mat-dialog-content>
        <mat-dialog-actions class="topright">
            <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </mat-dialog-actions>
        <div class="row">
            <div class="row">
                <div class="col">
                    <p><strong>Loan Number: </strong>{{turnkeyData?.PublicId}}</p>
                </div>
                <div class="col">
                    <p><strong>Past Due: </strong>{{turnkeyData?.BadDebt}}</p>
                </div>
                <div class="col">
                    <p><strong>DPD: </strong>{{turnkeyData?.DaysPastDue}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><strong>Status: </strong>{{loanStatus}}</p>
                </div>
                <div class="col">
                    <p><strong>Next Payment Amount: </strong>{{turnkeyData?.NextPaymentAmount}}</p>
                </div>
                <div class="col">
                    <p><strong>Next Payment Date: </strong>{{turnkeyData?.NextPaymentDate | date:'yyyy-MM-dd'}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><strong>OutstandingBalance: </strong>{{turnkeyData?.OutstandingBalance}}</p>
                </div>
                <div class="col">
                    <p><strong>OutstandingInterest</strong>{{turnkeyData?.OutstandingInterest}}</p>
                </div>
                <div class="col">
                    <p><strong>OutstandingPrincipal: </strong>{{turnkeyData?.OutstandingPrincipal}}</p>
                </div>
            </div>

        </div>
        <mat-tab-group>
            <mat-tab label="Schedule">
                <ag-grid-angular style="width: 100%; height: 470px" class="ag-theme-material"
                    [columnDefs]="columnDefs_Schedules" [rowData]="scheduleData" [pagination]="false"
                    [paginationPageSize]="10000" [getRowStyle]="getRowStyle_Schedule">
                </ag-grid-angular>
            </mat-tab>
            <mat-tab label="Transaction">
                <ag-grid-angular style="width: 100%; height: 470px" class="ag-theme-material"
                    [columnDefs]="columnDefs_Repayments" [rowData]="rowDataRepayment" [pagination]="false"
                    [paginationPageSize]="10000">
                </ag-grid-angular>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
</div>