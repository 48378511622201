import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ButtonRendererComponent } from '../button-renderer/button-renderer.component';
import { PromisePayComponent } from './promise-pay/promise-pay.component';
import { CommentFormComponent } from '../comment-form/comment-form.component';
import { AmoScheduleComponent } from './amo-schedule/amo-schedule.component';
import { loanMeta } from '../models/loanMeta';
import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	AfterViewInit
} from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonCellRendererComponent } from '@lmc-app/custom-table/button-cell-renderer/button-cell-renderer.component';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { addWorkDays } from '@lmc-app/utils/date-functions';
import { environment } from '@lmc-env/environment';
import { sumNumbers } from '@lmc-app/utils/filter-functions';
import { TurnkeyDataComponent } from './turnkey-data/turnkey-data.component';

interface jsPDFWIthPlugin extends jsPDF {
	autoTable: (options: UserOptions) => jsPDF;
}
interface Comment_Type {
	value: string;
	viewValue: string;
}
interface Disposition {
	id: string;
	name: string;
}
@Component({
	selector: 'app-loan-meta',
	templateUrl: './loan-meta.component.html',
	styleUrls: ['./loan-meta.component.scss'],
	animations: [
		trigger('sideNavMenuAnimationState', [
			state(
				'topX',
				style({
					transform: 'rotate(45deg)',
					transformOrigin: 'left',
					margin: '7px',
				})
			),
			state(
				'hide',
				style({
					opacity: 0,
				})
			),
			state(
				'bottomX',
				style({
					transform: 'rotate(-45deg)',
					transformOrigin: 'left',
					margin: '7px',
				})
			),
			state('line', style({})),
			state(
				'right',
				style({
					marginTop: '2px',
					marginLeft: '2px',
				})
			),
			state(
				'left',
				style({
					transform: 'rotate(-180deg)',
					transformOrigin: 'center',
					marginBottom: '2px',
					marginRight: '2px',
				})
			),
			transition(':enter', []),
			transition('* => *', [animate('0.2s')]),
		]),
	],
	encapsulation: ViewEncapsulation.Emulated,
})
export class LoanMetaComponent implements OnInit {
	@ViewChild('content') content: ElementRef;
	isSideNavMenuOpen = true;
	//collections queue variables
	isQueue = false;
	queuedLoans: any;
	loanIndex: any;

	amo_schedule;
	payoff_flag = false;
	release_flag = false;
	userID;
	test_date = new Date();
	mydate;
	comment: any;
	openAmoFlag = false;
	promisesFlag = false;
	comment_types: Comment_Type[] = [
		{ value: 'Regular', viewValue: 'Regular' },
		{ value: 'Email Notes', viewValue: 'Email Notes' },
	];

	unbilledInterest: any;

	dispositions: Disposition[] = [
		{ id: 'Internal Email', name: 'Internal Email' },
		{ id: 'RIGHT PARTY CONTACT', name: 'RIGHT PARTY CONTACT' },
		{ id: 'WRONG NUMBER', name: 'WRONG NUMBER' },
		{ id: 'THIRD PARTY CONTACT', name: 'THIRD PARTY CONTACT' },
		{ id: 'BANKO/CCS', name: 'BANKO/CCS' },
		{ id: 'LEFT MESSAGE WITH 3RD PARTY', name: 'LEFT MESSAGE WITH 3RD PARTY' },
		{ id: 'LEFT VOICEMAIL WITH RPC', name: 'LEFT VOICEMAIL WITH RPC' },
		{ id: 'HUNG UP/ REFUSAL TO PAY', name: 'HUNG UP/ REFUSAL TO PAY' },
		{ id: 'NO ANSWER', name: 'NO ANSWER' },
		{ id: 'NO SERVICE', name: 'NO SERVICE' },
		{ id: 'ARRANGMENT MADE', name: 'ARRANGMENT MADE' },
		{ id: 'SKIP', name: 'SKIP' },
		{ id: 'Tel poe lft msg on v/m', name: 'Tel poe lft msg on v/m' },
		{ id: 'Tel poe lft msg', name: 'Tel poe lft msg' },
		{ id: 'Tel poe n/a', name: 'Tel poe n/a' },
		{ id: 'Tel cell lft msg on v/m', name: 'Tel cell lft msg on v/m' },
		{ id: 'Tel cel n/a', name: 'Tel cel n/a' },
		{ id: 'Tel res mail box full', name: 'Tel res mail box full' },
	];
	payment_flag = true;
	meta = new loanMeta();
	// image_base64 = new Images()
	staticAlertClosed = false;
	successMessage = '';
	panelOpenState = true;
	selectedType = 'Regular';
	selectedType_d = 'NO ANSWER';
	collection_info: any;
	client_info: any;
	payment_history: any;
	payment_due: any;
	PD: any;
	manual_payment: any;
	Def_payment: any;
	Comments_table: any;
	// manual_flag: boolean = false;
	amount: number;
	date: any;
	payoff: any;
	payoffRequestedDate: any;
	payoffDownloadData: any;
	payoffRequestedInfo: any;
	showDownloadPayoff: boolean;
	rowDataClicked_Due = [];
	rowDataClicked_Man = [];
	rowDataClicked_Com = [];
	FollowupDate_Formatted: any;
	NLSStatuses: any;
	selectedStatus: any;
	allStatuses: any;

	//PTP
	defaultColDef_Due: any;
	getRowStyle_Due: any;
	public columnDefs_Due = [];
	public rowData_Due;
	public gridApi_Due;
	public gridColumnApi_Due;
	public gridOptions_Due;
	//Promise to pay ag-grid row data
	public rowData_PTP;
	payment_PTP: any
	//Arrangement ag-grid row data
	public rowData_arrangement;
	payment_arrangement: any;

	//Def
	defaultColDef_Def: any;
	public columnDefs_Def = [];
	public rowData_Def;
	public gridApi_Def;
	public gridColumnApi_Def;
	public gridOptions_Def;
	getRowStyle_Def;
	// //Comments
	// defaultColDef_Com: any;
	// getRowStyle_Com: any;
	// public columnDefs_Com = [];
	// public rowData_Com;
	// public gridApi_Com;
	// public gridColumnApi_Com;
	// public gridOptions_Com;
	clickEventSubscription;
	//Payment History Summary
	paymentHistorySummary;
	paymentDueSummary;
	paymentDueSummaryFlag = false;
	paymentHistorySummaryFlag = false;
	total_obligation_entire_term = 0;

	// Right child
	moreInfo = false;
	p = 1;
	count = 8;
	spinner = false;

	// Meta Data to assign loan details
	dataFromMeta: any;

	// Balance Total
	totalBalance = 0;


	selectedEmail = '';
	isEmail = false;
	officeEmails: any;
	serverURL: string = environment.iframeURL;

	ptpForm = new UntypedFormGroup({
		firstName: new UntypedFormControl(''),
		lastName: new UntypedFormControl(''),
		clientType: new UntypedFormControl(''),
	});

	emailFormControl = new FormControl('', [Validators.required, Validators.email]);
	sentEmailAddress = '';

	collectorNames: any;

	userName: any;
	zohoUserID: any;
	empID: any;
	totalPTPCreated: any;
	createdByUpdatePermission: any;

	isMatured: boolean = false;
	isTurnkey: any;
	constructor(
		public datepipe: DatePipe,
		private apiService: ApiServiceService,
		private dialog: MatDialog,
		private router: Router
	) {
		this.mydate = this.datepipe.transform(this.test_date, 'yyyy-MM-dd');
		this.clickEventSubscription = this.apiService
			.getClickEvent()
			.subscribe(() => {
				// this.confirmUpdate();
				this.reloadPtpTable();
			});

		// //Promise to Pay
		// this.columnDefs_Due = [
		// 	{
		// 		headerName: 'Cancel',
		// 		cellRenderer: ButtonCellRendererComponent,
		// 		cellRendererParams: {
		// 			label: 'Cancel',
		// 			matIconLabel: 'cancel',
		// 			clicked: (data: any) => {
		// 				this.onCancelDue(data);
		// 			},
		// 		},
		// 		width: 100,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Date',
		// 		field: 'PtP_Date',
		// 		minWidth: 110,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Type',
		// 		field: 'payment_type',
		// 		minWidth: 150,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Amount',
		// 		field: 'payment_amount',
		// 		minWidth: 150,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Comments',
		// 		field: 'comments',
		// 		cellEditor: 'agLargeTextCellEditor',
		// 		editable: true,
		// 		minWidth: 300,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Created at',
		// 		field: 'created_at',
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 210,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Created',
		// 		field: 'created_by',
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 210,
		// 		autoHeight: true,
		// 		editable: true,
		// 		cellEditor: 'agSelectCellEditor',
		// 		cellEditorParams: {
		// 			values: this.collectorNames
		// 		}
		// 	},
		// 	{
		// 		headerName: 'Applied',
		// 		field: 'applied_by',
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 210,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Status',
		// 		field: 'status',
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 210,
		// 		autoHeight: true,
		// 	},
		// ];
		// this.defaultColDef_Due = {
		// 	resizable: true,
		// };
		// this.getRowStyle_Due = (params) => {
		// 	if (params.data.Invalid)
		// 		return { background: 'red', 'font-size': '15px' };
		// 	if (params.data.nls_data)
		// 		return { background: '#cad3e3', 'font-size': '15px' };
		// 	else {
		// 		return { 'font-size': '15px' };
		// 	}
		// };

		// //Deferred Payments
		// this.columnDefs_Def = [
		// 	{
		// 		headerName: 'Payment #',
		// 		field: 'payment_number',
		// 		sortable: true,
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 150,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Deferred to Date',
		// 		field: 'deferred_to_date',
		// 		sortable: true,
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 200,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'User',
		// 		field: 'user',
		// 		sortable: true,
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 200,
		// 		autoHeight: true,
		// 	},
		// 	{
		// 		headerName: 'Date Entered',
		// 		field: 'date_entered',
		// 		sortable: true,
		// 		filter: 'agTextColumnFilter',
		// 		minWidth: 200,
		// 		autoHeight: true,
		// 	},
		// ];
		// this.defaultColDef_Def = {
		// 	resizable: true,
		// };
		// this.getRowStyle_Def = (params) => {
		// 	return { 'font-size': '15px' };
		// };
	}

	async ngOnInit() {
		this.toggleSideNav();
		// if (localStorage.getItem('SESSIONID')) {
		// 	console.log("-------------------->")
		// await this.getCredentials();
		// this.apiService
		// 	.verifysession(localStorage.getItem('SESSIONID'))
		// 	.subscribe((res: any) => {
		// 		console.log(res, "&&&&&&&&&&&&&&&&&&&&&&&&")
		// 		if (res.message === 'Success') {
		// 			this.userID = res?.data?.[0]?.['email'];
		// 			this.userName = res?.data?.[0]?.['full_name']; // Created by for Notes and Comments on Promise to Pay
		// 			this.zohoUserID = res?.data?.[0]?.['zoho_owner_id'];
		// 			this.empID = res?.data?.[0]?.['employeeID'];
		// 		}
		// 	});
		// 	console.log(this.empID, "^^^^^^^^^^^^^^^^^^^")
		// }
		// this.userID = this.apiService.getSignInInfo()[0]["email"]
		// console.log(this.empID, "^^^^^^^^^^^^^^^^^^^")
	}

	async getCredentials() {
		return new Promise((resolve, reject) => {
			// if (lovcalStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.userID = res?.data?.[0]?.['email'];
						this.userName = res?.data?.[0]?.['full_name']; // Created by for Notes and Comments on Promise to Pay
						this.zohoUserID = res?.data?.[0]?.['zoho_owner_id'];
						this.empID = res?.data?.[0]?.['employeeID'];
						resolve(true)
					} else {
						console.log(res, "SESSION Error_________________________")
					}
				});
			// }
		})
	}

	async ngAfterViewInit() {
		this.spinner = true;
		this.panelOpenState = true;
		await this.getCredentials();
		this.collection_info = this.apiService?.getMessage()[0];

		this.isTurnkey = await this.isTurnkeyFile(this.collection_info?.acctrefno);		// If Turnkey file

		this.isQueue = this.apiService.getMessage()['isQueue'];
		this.queuedLoans = this.apiService.getMessage()['queuedLoans'];
		// console.log(this.queuedLoans, '---------------------------');
		try {
			this.loanIndex = this.queuedLoans?.indexOf(
				this.collection_info?.Loan_Number
			);
		} catch (err) {
			console.log(err);
		}

		this.apiService.sendLoanInfo(this.collection_info);
		this.apiService.getNLSStatuses().subscribe((res) => {
			// console.log(res, 'getNLSStatuses');
			this.NLSStatuses = res;
			// console.log('Loan Statuses');
			// console.log(this.NLSStatuses);
			this.apiService
				.getNLSStatus(this.collection_info?.acctrefno)
				.subscribe((res1) => {
					console.log('LOAN STATUSes OF THE LOAN');
					this.allStatuses = res;
					console.log(res1, "status_code__________________")
					console.log(res1?.[0]?.['Status_Code_No'], 'Status_Code_No');
					console.log('___NLS Statuses___');
					console.log(this.NLSStatuses);
					if (!this.NLSStatuses || this.NLSStatuses?.['status']?.['code'] == 401) {
						console.log('Unable to get NLS Statuses');
					} else {
						try {
							this.selectedStatus = this.NLSStatuses.filter((obj) => {
								return obj.Status_Code_No == res1?.[0]?.['Status_Code_No'];
							});
						} catch (error) {
							this.selectedStatus = 'NA';
						}
						console.log(this.selectedStatus, 'selectedStatus');
					}
				});
		});

		// console.log('COLLECTION INFO');
		// console.log(this.collection_info);
		this.confirmUpdate();

		if (
			this.collection_info?.Followup_Date != null &&
			this.collection_info?.Followup_Date != '0000-00-00 00:00:00'
		) {
			let date = new Date(this.collection_info?.Followup_Date);
			date.setHours(date.getHours() - 0); //we are subtracting 4 hours to get the right time
			this.FollowupDate_Formatted = date.toISOString().slice(0, -5); //We are removing the last charcters for it to support the datepicker
			this.collection_info.Followup_Date = this.FollowupDate_Formatted;
		} else {
			this.FollowupDate_Formatted = null;
			// console.log('Soumith', this.collection_info);
			this.collection_info.Followup_Date = this.FollowupDate_Formatted;
		}

		await this.getCollectorNames();
		await this.ptpCreatedByUpdatePermission();
		//Promise to Pay
		this.columnDefs_Due = [
			{
				headerName: 'Cancel',
				cellRenderer: ButtonCellRendererComponent,
				cellRendererParams: {
					label: 'Cancel',
					matIconLabel: 'cancel',
					clicked: (data: any) => {
						this.onCancelDue(data);
					},
				},
				width: 100,
				autoHeight: true,
			},
			{
				headerName: 'Date',
				field: 'PtP_Date',
				minWidth: 110,
				autoHeight: true,
			},
			{
				headerName: 'Type',
				field: 'payment_type',
				minWidth: 150,
				autoHeight: true,
			},
			{
				headerName: 'Amount',
				field: 'payment_amount',
				minWidth: 150,
				autoHeight: true,
			},
			{
				headerName: 'Comments',
				field: 'comments',
				cellEditor: 'agLargeTextCellEditor',
				editable: true,
				minWidth: 300,
				autoHeight: true,
			},
			{
				headerName: 'Created at',
				field: 'created_at',
				filter: 'agTextColumnFilter',
				minWidth: 210,
				autoHeight: true,
			},
			{
				headerName: 'Created',
				field: 'created_by',
				filter: 'agTextColumnFilter',
				minWidth: 210,
				autoHeight: true,
				editable: this.createdByUpdatePermission?.includes(this?.empID) ? true : false,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: this.collectorNames
				}
			},
			{
				headerName: 'Applied',
				field: 'applied_by',
				filter: 'agTextColumnFilter',
				minWidth: 210,
				autoHeight: true,
			},
			{
				headerName: 'Status',
				field: 'status',
				filter: 'agTextColumnFilter',
				minWidth: 210,
				autoHeight: true,
			},
		];
		this.defaultColDef_Due = {
			resizable: true,
		};
		this.getRowStyle_Due = (params) => {
			if (params.data.Invalid)
				return { background: 'red', 'font-size': '15px' };
			if (params.data.nls_data)
				return { background: '#cad3e3', 'font-size': '15px' };
			else {
				return { 'font-size': '15px' };
			}
		};

		//Deferred Payments
		this.columnDefs_Def = [
			{
				headerName: 'Payment #',
				field: 'payment_number',
				sortable: true,
				filter: 'agTextColumnFilter',
				minWidth: 150,
				autoHeight: true,
			},
			{
				headerName: 'Deferred to Date',
				field: 'deferred_to_date',
				sortable: true,
				filter: 'agTextColumnFilter',
				minWidth: 200,
				autoHeight: true,
			},
			{
				headerName: 'User',
				field: 'user',
				sortable: true,
				filter: 'agTextColumnFilter',
				minWidth: 200,
				autoHeight: true,
			},
			{
				headerName: 'Date Entered',
				field: 'date_entered',
				sortable: true,
				filter: 'agTextColumnFilter',
				minWidth: 200,
				autoHeight: true,
			},
		];
		this.defaultColDef_Def = {
			resizable: true,
		};
		this.getRowStyle_Def = (params) => {
			return { 'font-size': '15px' };
		};
	}

	onModelUpdated(params) { }

	onGridReady_Due(params) {

		this.gridApi_Due = params.api;
		this.gridColumnApi_Due = params.columnApi;
		this.apiService
			.getParentPTP(this.collection_info?.acctrefno)
			.subscribe(async (res) => {
				this.payment_due = res;
				this.gridApi_Due.setRowData(this.payment_due);
				this.gridApi_Due.sizeColumnsToFit();
				this.rowData_Due = this.payment_due;

				this.payment_PTP = Object.values(res).filter(item => item['type'] === 'ptp');
				// Filtered ptp list based on requested and current date
				const filteredDateRequested = this.payment_PTP.filter((item) => item['created_at'] === this.datepipe.transform(this.test_date, 'yyyy-MM-dd') && (item['status'] !== 'Cancelled' || item['status'] !== 'Deleted'));
				this.totalPTPCreated = sumNumbers(filteredDateRequested, 'payment_amount')?.toFixed(2);		// Sum of amount of the filteredDateRequested
				this.payment_arrangement = Object.values(res).filter(item => item['type'] === 'arrangement');
				this.rowData_PTP = this.payment_PTP;
				this.rowData_arrangement = this.payment_arrangement;
			});
	}
	onGridReady_Def(params) {
		this.apiService
			.getDeferredPayments(this.collection_info?.acctrefno)
			.subscribe((res) => {
				this.Def_payment = res;
				this.gridApi_Def = params.api;
				this.gridColumnApi_Def = params.columnApi;
				this.gridApi_Def.setRowData(this.Def_payment);
				this.rowData_Def = this.Def_payment;
			});
	}
	// changeNLSStatus(e) {
	// 	console.log(e.target.value);
	// 	if (confirm('Are you sure you want to change the status of this loan?')) {
	// 		this.selectedStatus = this.NLSStatuses.filter((obj) => {
	// 			return obj.Status_Code === e.target.value;
	// 		});
	// 		this.apiService
	// 			.updateNLSStatus(
	// 				this.collection_info?.acctrefno,
	// 				this.selectedStatus,
	// 				this.allStatuses
	// 			)
	// 			.subscribe((res) => { });
	// 	}

	// 	console.log(this.selectedStatus);
	// }
	// showPayoffDate() {
	// 	this.payoff_flag = !this.payoff_flag;
	// 	this.release_flag = false;
	// }
	// showOpenDate() {
	// 	this.release_flag = !this.payoff_flag;
	// 	this.payoff_flag = false;
	// }
	// showpaymentHistorySummary() {
	// 	this.paymentHistorySummaryFlag = !this.paymentHistorySummaryFlag;
	// }
	// showpaymentDueSummary() {
	// 	this.paymentDueSummaryFlag = !this.paymentDueSummaryFlag;
	// }
	// openPTPForm() {
	// 	var Message = [];
	// 	this.collection_info.Followup_Date = this.FollowupDate_Formatted;
	// 	Message.push(this.collection_info);
	// 	Message.push(this.payment_history);
	// 	Message.push(this.payment_due);
	// 	Message.push({ user_name: this.userName, zoho_user_id: this.zohoUserID }); // User signed in email for Promise to pay comment created by. Following the same pattern as what was before
	// 	console.log(Message, '________________________________');
	// 	this.apiService.sendMessage(Message);
	// 	const dialogConfig = new MatDialogConfig();
	// 	dialogConfig.autoFocus = false;
	// 	dialogConfig.width = '95%';
	// 	this.dialog.open(PromisePayComponent, dialogConfig);
	// }
	openCommentForm(event: any) {
		console.log('This is the message we are sending to the comment form');
		Object.assign(event, { user_name: this.userName }); // Add signed in user for the Modal to detect the user
		this.apiService.sendMessage(event);
		const dialogConfig = new MatDialogConfig();
		dialogConfig.autoFocus = false;
		dialogConfig.width = '50%';
		this.dialog.open(CommentFormComponent, dialogConfig);
	}
	// openAmoForm() {
	// 	this.apiService.sendMessage(this.collection_info?.acctrefno);
	// 	const dialogConfig = new MatDialogConfig();
	// 	dialogConfig.autoFocus = false;
	// 	dialogConfig.width = '95%';
	// 	this.dialog.open(AmoScheduleComponent, dialogConfig);
	// }
	confirmUpdate() {
		this.mydate = this.datepipe.transform(this.test_date, 'yyyy-MM-dd');
		// this.apiService
		// 	.getPaymentHistorySummary(this.collection_info?.acctrefno)
		// 	.subscribe((res) => {
		// 		console.log('PAYMENT HISTORY SUMMARY');
		// 		console.log(res);
		// 		this.paymentHistorySummary = res;
		// 	});
		// this.apiService
		// 	.getPaymentDueSummary(this.collection_info?.acctrefno)
		// 	.subscribe((res) => {
		// 		console.log('Payments Due Summary');
		// 		this.paymentDueSummary = res;
		// 	});
		this.apiService
			.getNLSAmortizationSchedule(this.collection_info?.acctrefno, '2021-09-27')
			.subscribe((res) => {
				this.amo_schedule = res;
				console.log('Amortization Schedule');
				console.log(this.amo_schedule);
				try {
					for (var i = 0; i < this.amo_schedule.length; i++) {
						try {
							this.total_obligation_entire_term +=
								this.amo_schedule[i]['PaymentAmount'];
						} catch (err) { }
					}
				} catch (err) {
					console.log('Unable to get Amortization Schedule from NLS API');
				}

				console.log('Total Obligation Entire Term');
				console.log(this.total_obligation_entire_term);
			});

		this.reloadPtpTable();

		// this.apiService
		// 	.getPaymentHistory(this.collection_info?.acctrefno)
		// 	.subscribe((res) => {
		// 		console.log('Payments History Over here');
		// 		console.log(res);
		// 		this.payment_history = res;
		// 		this.gridApi_Hist.setRowData(this.payment_history);
		// 		this.rowData_Hist = this.payment_history;
		// 	});
		// // this.apiService
		// // 	.getParentPTP(this.collection_info?.acctrefno)
		// // 	.subscribe((res) => {
		// // 		this.payment_due = res;
		// // 		this.rowData_Due = this.payment_due;

		// // 		this.payment_PTP = Object.values(res).filter(item => item['type'] === 'ptp');
		// // 		this.payment_arrangement = Object.values(res).filter(item => item['type'] === 'arrangement');
		// // 		this.rowData_PTP = this.payment_PTP;
		// // 		this.rowData_arrangement = this.payment_arrangement;
		// // 	});
		// this.apiService
		// 	.getPaymentDue(this.collection_info?.acctrefno)
		// 	.subscribe((res) => {
		// 		this.PD = res;
		// 		this.rowData_PD = this.PD;
		// 	});
		this.apiService
			.getDeferredPayments(this.collection_info?.acctrefno)
			.subscribe((res) => {
				this.Def_payment = res;
				this.rowData_Def = this.Def_payment;
			});
		// // this.apiService
		// // 	.getComments(this.collection_info?.acctrefno)
		// // 	.subscribe((res) => {
		// // 		console.log(res, "Notes______________________")
		// // 		this.Comments_table = res;
		// // 		// this.rowData_Com = this.Comments_table;
		// // 		// // console.log(this.Comments_table, 'Comments Table');
		// // 	});
		this.apiService
			.getLoandetails(this.collection_info?.acctrefno)
			.subscribe(async (res: number) => {
				console.log('Here is the Payoff and total due info');
				console.log(res);
				let payoff_info = await this.getPayoffInfo(
					this.collection_info?.acctrefno,
					this.mydate
				);
				try {
					this.payoff = payoff_info['PayoffAmount'];
					//this.payoff = this.payoff.toLocaleString("en-US")
				} catch (err) {
					//alert("Maximum NLS Licenses reached. Contact Hamza or Travis")
					console.log('Maximum licenses have been reached');
					this.payoff = 'NA';
				}
				console.log('Payoff Information');
				console.log(payoff_info);
				this.meta.loan_group = payoff_info?.['LoanGroup'];
				this.meta.perdiem = payoff_info?.['PerDiem']?.toFixed(2);

				this.unbilledInterest = this.payoff - (this.collection_info?.current_principal_balance + this.collection_info?.total_current_due);
				this.unbilledInterest = this.unbilledInterest < 0 ? this.meta.perdiem : this.unbilledInterest.toFixed(2);


				if (res['meta'].length != 0) {
					this.dataFromMeta = res['meta']?.[0];
				} else {
					this.dataFromMeta = this.collection_info;
				}
				try {
					this.meta.insurance_premium = this.dataFromMeta?.['Insurance Prem'];
				} catch (error) {
					this.meta.insurance_premium = 0;
				}
				try {
					this.meta.insurance_tax = this.dataFromMeta?.['Insurance Tax'];
				} catch (error) {
					this.meta.insurance_tax = 0;
				}
				try {
					this.meta.insurance = isNaN((
						this.meta.insurance_premium + this.meta.insurance_tax
					)?.toFixed(2)) ? 0 : (
						this.meta.insurance_premium + this.meta.insurance_tax
					)?.toFixed(2);
				} catch (error) {
					this.meta.insurance = 0;
				}
				// New line Yeshey
				try {
					// this.apiService.getCollectionPaymentDue(this.collection_info?.acctrefno).subscribe((res: any) => {
					//   this.meta.total_due = res?.[0]?.total_payment_due
					//   this.meta.total_due = this.meta.total_due.toLocaleString("en-US")
					// })
					this.meta.total_due = this.dataFromMeta?.total_past_due_balance;
					this.meta.total_due = this.meta.total_due.toLocaleString('en-US');
				} catch (err) {
					this.meta.total_due = 'NA';
				}
				this.meta.status_code = this.dataFromMeta?.status_code;
				this.meta.loan_status = this.dataFromMeta?.LoanStatus;
				this.meta.amortized_payment_amount =
					this.dataFromMeta?.amortized_payment_amount;
				this.meta.closed_date = this.dataFromMeta?.closed_date;
				this.meta.current_rate = this.dataFromMeta?.current_rate;
				this.meta.loan_group_no = this.dataFromMeta?.loan_group_no;
				this.meta.eff_fees_balance = (this.dataFromMeta?.eff_fees_balance === undefined) ? 0 : this.dataFromMeta?.eff_fees_balance;

				this.totalBalance = (parseFloat(this.meta.insurance) + this.collection_info.current_principal_balance + this.collection_info.current_interest_balance + this.meta.eff_fees_balance)?.toFixed(2);
				console.log('___________________________________________________________________');
				console.log('META INFORMATION');
				console.log(this.meta);
				switch (this.meta.loan_group_no) {
					case 1:
					case 27:
						this.meta.account_no = 1971158;
						break;
					case 3:
					case 16:
						this.meta.account_no = 1970876;
						break;
					case 50:
					case 51:
						this.meta.account_no = 1964476;
						break;
					default:
						this.meta.account_no = 1974017;
				}
				console.log('________________________________________________');
				console.log('Account Number');
				console.log(this.meta.account_no);
				this.meta.maturity_date = this.dataFromMeta?.maturity_date;
				this.isMatured = new Date(this.dataFromMeta?.maturity_date) < new Date() ? true : false;
				this.meta.open_date = this.dataFromMeta?.open_date;
				this.meta.origination_date = this.dataFromMeta?.origination_date;
				switch (this.dataFromMeta?.principal_period) {
					case 'DA': {
						this.meta.principal_period = 'Daily';
						break;
					}
					case 'WE': {
						this.meta.principal_period = 'Weekly';
						break;
					}
					case 'BW': {
						this.meta.principal_period = 'Bi-Weekly';
						break;
					}
					case 'SM': {
						this.meta.principal_period = 'Semi-Monthly';
						break;
					}
					case 'MO': {
						this.meta.principal_period = 'Monthly';
						break;
					}
					case 'S4': {
						this.meta.principal_period = 'Semi-Monthly';
						break;
					}
					default: {
						this.meta.principal_period = '';
					}
				}

				this.spinner = false;
			});
	}
	// showManualPayment() {
	// 	this.manual_flag = !this.manual_flag;
	// 	this.amount = null;
	// 	this.date = null;
	// }
	onCancelDue(e) {
		this.rowDataClicked_Due = [];
		this.rowDataClicked_Due.push(e || e.rowData);
		let two_days = addWorkDays(new Date(), 1);
		let ptpDate = new Date(this.rowDataClicked_Due?.[0]?.['PtP_Date']);
		if (this.rowDataClicked_Due?.[0]?.['status'] === 'Processed')
			alert("It's already processed!");
		else if (two_days > ptpDate) {
			alert('PTP Date at least needs to be two days in future to Cancel');
		} else if (this.rowDataClicked_Due[0]['parent_id']) {
			this.apiService
				.cancelPTPParent(
					this.rowDataClicked_Due[0]['parent_id'],
					this.userID,
					this.rowDataClicked_Due[0]['acctrefno']
				)
				.subscribe((res) => {
					this.rowDataClicked_Due = [];
					console.log('PTP HAS BEEN CANCELLED');
					// this.confirmUpdate();
					this.reloadPtpTable();
				});
		} else alert('Promise to Pays Created on NLS cannot be deleted');
	}
	// setFollowUpDate() {
	// 	if (!this.FollowupDate_Formatted) alert('You must select a Follow Up Date');
	// 	else {
	// 		this.FollowupDate_Formatted = this.FollowupDate_Formatted.replace(
	// 			'T',
	// 			' '
	// 		);
	// 		this.apiService
	// 			.updateFollowUp(
	// 				this.FollowupDate_Formatted,
	// 				this.collection_info?.Loan_Number
	// 			)
	// 			.subscribe((res) => {
	// 				console.log(res);
	// 				this.FollowupDate_Formatted = this.FollowupDate_Formatted.replace(
	// 					' ',
	// 					'T'
	// 				);
	// 				this.confirmUpdate();
	// 			});
	// 	}
	// }
	// clearFollowUpDate() {
	// 	this.apiService
	// 		.updateFollowUp('', this.collection_info?.Loan_Number)
	// 		.subscribe((res) => {
	// 			this.FollowupDate_Formatted = '';
	// 			this.confirmUpdate();
	// 		});
	// }
	getDeferredPayments() {
		this.apiService
			.getDeferredPayments(this.collection_info?.acctrefno)
			.subscribe((res) => { });
	}
	async createComment() {
		if (this.comment == '' || this.comment == undefined) {
			alert('Comment should not be empty');
		} else {
			console.log('THIS IS THE USER THAT IS GOING TO CREATE THE COMMENT');
			console.log(this.collection_info, '----------------------------');
			if (this.selectedType === 'Email Notes' && this.selectedType_d === 'Internal Email' && this.selectedEmail === '') {
				alert('Select an email address');
				// } else if (this.selectedType === 'Email Notes' && this.selectedType_d === 'External Email' && this.emailFormControl.value === '') {
				// 	console.log(this.emailFormControl.value, "External Email")
				// 	alert('Type the external email address');
			} else {
				let result = await this.emailNotes();

				this.apiService
					.createComment(
						this.collection_info?.acctrefno,
						this.collection_info?.Name,
						this.comment,
						this.selectedType,
						this.selectedType_d,
						this.userName,
						this.zohoUserID,
						this.collection_info?.cifnumber,
						this.isEmail,
						this.sentEmailAddress
					)
					.subscribe((res) => {
						// this.apiService.createComment(this.collection_info?.acctrefno, this.comment, this.selectedType, this.selectedType_d, this.userID).subscribe((res) => {					
						alert(res['message']);
						this.comment = '';
						this.selectedType = 'Regular';
						this.selectedType_d = 'NO ANSWER';
						// this.confirmUpdate();
						this.reloadPtpTable();
						this.selectedEmail = '';
						this.sentEmailAddress = '';
						this.emailFormControl.reset();
						this.isEmail = false;
					});
			}
		}
	}
	// async createPaymentHistoryStatement() {
	// 	this.apiService.getPaymentHistoryStatement(this.collection_info?.Loan_Number).subscribe((res: any[]) => {
	// 		console.log("Payoff History Statement")
	// 		console.log(res)
	// 		this.client_info = res
	// 		let docs = new jsPDF('portrait', 'px', 'a4') as jsPDFWIthPlugin
	// 		let payment_history = this.client_info.map((entries) => Object.values(entries))
	// 		//docs.addImage(this.image_base64.LMCredit_image, "JPEG", 30, 20, 100, 50)
	// 		docs.setFontSize(12)
	// 		docs.text("STATEMENT OF ACCOUNT", 280, 75)
	// 		docs.setFontSize(10)
	// 		docs.text("Applicant Name: " + this.client_info[0]["name"], 30, 95)
	// 		docs.text("Applicant Address: ", 30, 107)
	// 		docs.text(this.client_info[0]["Debtor Address"].concat(", ").concat(this.client_info[0]["Debtor City"]).toUpperCase().concat(", ").concat(this.client_info[0]["Debtor Province"]), 30, 119)
	// 		docs.text(this.client_info[0]["Debtor Postal Code"].concat(", Canada"), 30, 131)
	// 		docs.text("Payoff Date: " + this.mydate, 280, 95)
	// 		docs.text("Maturity Date: " + this.client_info[0]["curr_maturity_date"], 280, 107)
	// 		docs.text("Next Payment Due: " + this.client_info[0]["next_billing_date"], 280, 119)
	// 		docs.text("Principle Balance: $ " + (this.client_info[0]["current_principal_balance"]).toFixed(2), 280, 131)
	// 		docs.text("Payoff Amount: $ " + (parseFloat(this.payoff)).toFixed(2), 280, 143)
	// 		docs.text("Account Number: " + this.client_info[0]["loan_number"], 280, 155)
	// 		docs.setFontSize(12)
	// 		docs.setFillColor("#000000")
	// 		docs.text("Account Activity", 30, 177)
	// 		docs.autoTable({
	// 			didParseCell: function (table) {

	// 				if (table.section === 'head') {
	// 					table.cell.styles.fillColor = '#687874';
	// 					table.cell.styles.textColor = "ffffff"
	// 				}
	// 			},
	// 			styles: {
	// 				fontSize: 10
	// 			},
	// 			theme: "grid",
	// 			startY: 187,
	// 			head: [["Payment Date", "Payment Description", "Amount", "Payment Method Reference"]],
	// 			body: payment_history
	// 		})
	// 		docs.save(this.client_info[0]["name"] + ".pdf")
	// 	})

	// }

	async getPayoffInfo(acctrefno, payoff_date) {
		return new Promise((resolve, reject) => {
			this.apiService.getPayoffInfo(acctrefno, payoff_date).subscribe((res) => {
				resolve(res);
			});
		});
	}

	viewNextLoan() {
		let nextLoan = '';
		if (this.loanIndex == -1) alert('Loan Number cannot be found');
		else {
			if (!this.queuedLoans[this.loanIndex + 1]) console.log('End of List');
			else {
				nextLoan = this.queuedLoans[this.loanIndex + 1];
				this.routeToNextLoan(nextLoan);
			}
		}
	}
	viewPreviousLoan() {
		let prevLoan = '';
		if (this.loanIndex == -1) alert('Loan Number cannot be found');
		else {
			if (!this.queuedLoans[this.loanIndex - 1]) console.log('End of List');
			else {
				prevLoan = this.queuedLoans[this.loanIndex - 1];
				this.routeToNextLoan(prevLoan);
			}
		}
	}
	routeToNextLoan(loan_number) {
		this.apiService.searchNLSLoan(loan_number).subscribe((res: any[]) => {
			if (res.length == 0) {
				alert('Loan Number cannot be found on NLS');
			} else {
				console.log(res);
				res['isQueue'] = this.isQueue;
				res['queuedLoans'] = this.queuedLoans; //Provide list of loans in the current view
				this.apiService.sendMessage(res);
				// this.ngOnInit();
				this.ngAfterViewInit();
			}
		});
	}

	toggleSideNav() {
		this.isSideNavMenuOpen = !this.isSideNavMenuOpen;
	}

	selectedTabValue(e) {
		if (e.tab.textLabel === "Future Payments") {
			this.openAmoFlag = true;
		}
		if (e.tab.textLabel === "Promises") {
			this.promisesFlag = true;
		}
	}

	displayEmails(e: string) {
		if (e === 'Email Notes') {
			this.selectedType_d = 'Internal Email';
			this.apiService.getOfficeEmails().subscribe((res) => {
				this.officeEmails = res?.['data'];
			})
		}
	}
	async emailNotes() {
		return new Promise((resolve, reject) => {
			// let chosenEmail = '';
			let sendLoanNumber = '';
			if (this.selectedType === 'Email Notes' && this.selectedType_d === 'Internal Email' && this.selectedEmail !== '') {
				this.sentEmailAddress = this.selectedEmail;
				sendLoanNumber = this.collection_info?.Loan_Number;
				this.apiService.toggleEmailNotes(this.comment, sendLoanNumber, this.collection_info?.Name, this.userID, this.sentEmailAddress, this.serverURL, 'meta', this.userName).subscribe((res) => {
					console.log(res, "Email Notes Internal")
					this.isEmail = true;
					resolve(true);
				})
				// } else if (this.selectedType === 'Email Notes' && this.selectedType_d === 'External Email' && this.emailFormControl.value !== '') {
				// 	this.sentEmailAddress = this.emailFormControl.value;
				// 	// // sendLoanNumber = 'external';
				// 	// // this.apiService.toggleEmailNotes(this.comment, sendLoanNumber, this.collection_info?.Name, this.userID, this.sentEmailAddress, this.serverURL, 'meta', this.userName).subscribe((res) => {
				// 	// // 	console.log(res, "Email Notes External")
				// 	this.isEmail = false;
				// 	resolve(true);
				// // })
			} else {
				this.sentEmailAddress = '';
				this.isEmail = false;
				resolve(false);
			}
		})
	}

	reloadPtpTable() {
		this.apiService.getParentPTP(this.collection_info?.acctrefno).subscribe((res) => {
			this.payment_due = res;
			this.rowData_Due = this.payment_due;

			this.payment_PTP = Object.values(res).filter(item => item['type'] === 'ptp');
			this.payment_arrangement = Object.values(res).filter(item => item['type'] === 'arrangement');
			const filteredDateRequested = this.payment_PTP.filter((item) => item['created_at'] === this.datepipe.transform(this.test_date, 'yyyy-MM-dd') && (item['status'] !== 'Cancelled' || item['status'] !== 'Deleted'));
			this.totalPTPCreated = sumNumbers(filteredDateRequested, 'payment_amount')?.toFixed(2);

			this.rowData_PTP = this.payment_PTP;
			this.rowData_arrangement = this.payment_arrangement;
		});
		this.apiService.getComments(this.collection_info?.acctrefno).subscribe((res) => {
			console.log(res, "Notes______________________")
			this.Comments_table = res;
		});
	}

	async getCollectorNames() {
		return new Promise((resolve, reject) => {
			this.apiService.getUnderwriters().subscribe((res: any) => {
				this.collectorNames = res?.['data']?.filter((item) => item?.['position'] === 'CO' && item?.['employee_status'] === true).map(item => item?.full_name);
				resolve(this.collectorNames);
			})
		})
	}

	async ptpCreatedByUpdatePermission() {
		// Get the btn permission IDs
		return new Promise((resolve, reject) => {
			this.apiService.getBtnPermission().subscribe((res) => {
				this.createdByUpdatePermission = res?.[0]?.ptp_createdby_update;
				resolve(this.createdByUpdatePermission);
			})
		})
	}

	onCellValueChanged(e) {
		// console.log(e.data.parent_id, e.value, "******************************")
		this.apiService.updatePTPCreatedBy(e.data.parent_id, e.value).subscribe((res) => {
			console.log(res, "Update Created_by__________________")
		})
	}

	isTurnkeyFile(acctrefno: number) {
		return new Promise((resolve, reject) => {
			this.apiService.isTurnkeyFile(acctrefno).subscribe((res) => {
				resolve(res)
			})
		})
	}

	openTurnkeyModal() {
		const dialogRef = this.dialog.open(TurnkeyDataComponent, {
			height: '750px',
			width: '950px',
			autoFocus: true,
			data: {
				acctrefno: this.collection_info?.acctrefno
			}
		});
	}
}
