import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { IRowNode, IToolPanelParams } from 'ag-grid-community';

@Component({
	selector: 'app-custom-stats-tool-panel',
	templateUrl: './custom-stats-tool-panel.component.html',
	styleUrls: ['./custom-stats-tool-panel.component.scss'],
})
export class CustomStatsToolPanelComponent implements IToolPanelAngularComp {
	public params!: IToolPanelParams | any;

	public summaryData!: any;

	agInit(params: IToolPanelParams | any): void {
		this.params = params;

		this.params.api.addEventListener(
			'modelUpdated',
			this.updateTotals.bind(this)
		);
	}

	updateTotals(): void {
		this.params.api.forEachNode((rowNode: IRowNode) => {
			const data = rowNode.data;
		});

		this.summaryData = {};
	}

	refresh(): void { }
}
