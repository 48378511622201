<div *ngIf="rowDataPaymentHistory">
	<div style="position: relative; overflow-x: hidden;">
		<ag-grid-angular style="width: 100%; height: 800px" class="ag-theme-alpine" [columnDefs]="columnDefs"
			[defaultColDef]="defaultColDef" [rowData]="rowDataPaymentHistory" [detailRowAutoHeight]="true"
			[masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams"
			[getRowStyle]="getRowStyle_Master">
		</ag-grid-angular>
		<div style="position: absolute; top:2px; right:24px;">
			<mat-icon (mouseenter)="showPaymentCard = true" (mouseleave)="showPaymentCard = false"
				title=" Payment Summary" style="margin-right: 2px;">info</mat-icon>
			<mat-card class="payment-details" *ngIf="showPaymentCard">
				<mat-card-header>
					<mat-card-title>Payment Summary</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table>
						<tr *ngFor="let item of paymentHistory">
							<td>{{item?.Description}}: <strong>${{item?.['Payment Amount']}}</strong></td>
						</tr>
					</table>
				</mat-card-content>
				<mat-card-header>
					<mat-card-title>Payment Due</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div>INS PREM BILLING: <strong>${{paymentDue?.INS_Prem_Billing?.toFixed(2)}}</strong></div>
					<div>INS TAX BILLIING: <strong>${{paymentDue?.INS_Tax_Billing?.toFixed(2)}}</strong></div>
					<div>Principal and Interest: <strong>${{paymentDue?.Principal_and_Interest?.toFixed(2)}}</strong>
					</div>
					<div>NSF Fee: <strong>${{paymentDue?.NSF_Fee?.toFixed(2)}}</strong></div>
					<div>TOT PAYMENT: <strong>${{paymentDue?.TOT_Payment?.toFixed(2)}}</strong></div>
				</mat-card-content>
			</mat-card>
			<mat-icon (click)="downloadPaymentHistoryStatement()" title="Payment History Statement">get_app</mat-icon>
		</div>
	</div>
</div>