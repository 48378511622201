<app-custom-betty-report-filters [showReportDropDown]="false" [showLoanStatus]="false" [showBreakdowns]="false"
    [showCurrentDate]="false" [showDateRange]="true" (start_date)="startDate($event)" (end_date)="endDate($event)"
    [btnColor]="'primary'" [btnShowText]="btnShowText" [btnText]="'Submit'" [btnSpinner]="btnSpinner"
    (onBtnClick)="downloadBalloon()"></app-custom-betty-report-filters>

<div class="row" style="height: calc(100% - 175px); margin: 10px;">
    <app-custom-table class="ag-theme-material" [rowData]="rowData_Hist" [columnDefs]="columnDefs_Hist"
        [defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"
        [hideGlobalFilter]="true" [animateRows]="true" [pagination]="true" [paginationPageSize]="100"
        (gridReady)="onGridReady_Hist($event)">
    </app-custom-table>
</div>