import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-uw-performance',
	templateUrl: './uw-performance.component.html',
	styleUrls: ['./uw-performance.component.scss'],
})
export class UwPerformanceComponent implements OnInit, AfterViewInit {
	public startDate: string;
	public endDate: string;
	public uwpList = [];
	public uwpTotals: any;
	public exceleExportFileName = 'UW Performance Report.csv';
	public isDashboard = false;

	@ViewChild('uwpTable') UWPTable: ElementRef;

	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		this.activatedRoute?.queryParams?.subscribe((queryParams) => {
			this.isDashboard = !!queryParams?.['uwp-dashboard'];
			if (this.isDashboard) {
				this.startDate = this.endDate = new Date().toISOString().split('T')[0];
				this.getUWPerformanceReport();
			}
		});
	}

	getUWPerformanceReport() {
		this.apiService
			.getUWPerformanceReport(this.startDate, this.endDate)
			.subscribe((res: any) => {
				console.log(res?.data);
				const uwpData = res?.data;
				this.uwpList = uwpData;
				const Conditionally_Approved = uwpData
					.map((t) => t['Conditionally_Approved'])
					.reduce((acc, val) => acc + val, 0);
				const Approved = uwpData
					.map((t) => t['Approved'])
					.reduce((acc, val) => acc + val, 0);
				const File_Review = uwpData
					.map((t) => t['File_Review'])
					.reduce((acc, val) => acc + val, 0);
				const Declined = uwpData
					.map((t) => t['Declined'])
					.reduce((acc, val) => acc + val, 0);
				const Needs_Documents_Missing_Docs = uwpData
					.map((t) => t['Needs_Documents_Missing_Docs'])
					.reduce((acc, val) => acc + val, 0);
				const Conditionally_Approved_Percent =
					uwpData
						.map((t) => t['Conditionally_Approved_Percent'])
						.reduce((acc, val) => acc + val, 0) / (uwpData.length || 1);
				const Approved_Percent =
					uwpData
						.map((t) => t['Approved_Percent'])
						.reduce((acc, val) => acc + val, 0) / (uwpData.length || 1);
				const File_Review_Percent =
					uwpData
						.map((t) => t['File_Review_Percent'])
						.reduce((acc, val) => acc + val, 0) / (uwpData.length || 1);
				const Declined_Percent =
					uwpData
						.map((t) => t['Declined_Percent'])
						.reduce((acc, val) => acc + val, 0) / (uwpData.length || 1);
				const Needs_Documents_Missing_Docs_Percent =
					uwpData
						.map((t) => t['Needs_Documents_Missing_Docs_Percent'])
						.reduce((acc, val) => acc + val, 0) / (uwpData.length || 1);
				const Total_Grand_Totals = uwpData
					.map((t) => t['Grand Total'])
					.reduce((acc, val) => acc + val, 0);
				const Front_End_Status_Complete = uwpData
					.map((t) => t['Front_End_Status_Complete'])
					.reduce((acc, val) => acc + val, 0);
				const Back_End_Status_Complete = uwpData
					.map((t) => t['Back_End_Status_Complete'])
					.reduce((acc, val) => acc + val, 0);
				this.uwpTotals = {
					Conditionally_Approved,
					Approved,
					File_Review,
					Declined,
					Needs_Documents_Missing_Docs,
					Conditionally_Approved_Percent,
					Approved_Percent,
					File_Review_Percent,
					Declined_Percent,
					Needs_Documents_Missing_Docs_Percent,
					Total_Grand_Totals,
					Front_End_Status_Complete,
					Back_End_Status_Complete,
				};
				console.log('uwp list', this.uwpList);
			});
	}

	// Todo: Move this to utils
	exportToExcel() {
		const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
			this.UWPTable.nativeElement
		);
		const workbook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			workbook,
			worksheet,
			`UWP ${this.startDate} - ${this.endDate}`
		);
		XLSX.writeFile(workbook, this.exceleExportFileName);
	}
}
