import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-iframe-view',
	templateUrl: './iframe-view.component.html',
	styleUrls: ['./iframe-view.component.scss'],
})
export class IframeViewComponent implements OnInit, AfterViewInit {
	public iframeUrl: SafeResourceUrl;
	public iframeView: string;
	constructor(
		private summaryDataService: SummaryDataService,
		private domSanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.activatedRoute?.queryParams?.subscribe((queryParams) => {
			this.iframeView = queryParams?.['iframeView'];
		});
	}

	ngAfterViewInit() {
		this.summaryDataService.summaryDataObservable.subscribe(
			(summaryData: any) => {
				switch (this.iframeView) {
					case 'documents':
						return (this.iframeUrl =
							this.domSanitizer.bypassSecurityTrustResourceUrl(
								summaryData.gdriveextension__Drive_URL
							));
					default:
						return (this.iframeUrl =
							this.domSanitizer.bypassSecurityTrustResourceUrl(
								summaryData.gdriveextension__Drive_URL
							));
				}
			}
		);
	}
}
