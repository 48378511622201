import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { inveriteRequest } from '@lmc-models/inveriteRequest';
import { AccountSelectComponent } from '../../account-select/account-select.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MatSelectionList } from '@angular/material/list';
import { Store } from '@ngrx/store';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

declare var require: any;

@Component({
	selector: 'app-inverite',
	templateUrl: './inverite.component.html',
	styleUrls: ['./inverite.component.scss'],
})
export class InveriteComponent implements OnInit, OnDestroy {
	@Input() index: number = 1;
	@ViewChild('bank_list') bank_list: MatSelectionList;
	zoho_application: any;
	application_id: any;
	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;
	co_app_required: any;
	folderID = '';
	loan_number: any;
	application_type: any;
	application_type_label = '';
	guid: any;
	banks: any;
	bank_view: any;
	dropdownList = [];
	selectedItems = new Array(1);
	selectedAccount = new Array(1);
	bankStatement: any;
	accounts = [];
	bank_requests = [];
	bank_id_selected;
	account_labels = [];
	selected = new UntypedFormControl(0);
	intervalId;
	inveriteStatus = 'Checking...';
	zohoUploadStatus = 'Checking...';
	model: any;
	inverite_entry = {};
	show_input = false;
	show_add_bank = false;
	guid_exist_flag = true;
	//
	selectBankFlag = false;
	createRequestFlag = false;
	displayRequestsFlag = false;
	homeFlag = false;
	clickEventSubscription;
	//
	downloadPDFFlag = false;
	hideForm = true;
	transaction_pill = 'All';

	summary$: Observable<any>;
	summaryData: any;
	userData: any;

	is_category = false;	// Flag to filter based on category if true and flag if false for transaction pills

	isExternalView: boolean;

	constructor(
		private store: Store,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		private apiService: ApiServiceService,
		private dialog: MatDialog
	) {
		this.clickEventSubscription = this.apiService
			.getClickEvent()
			.subscribe(() => {
				this.updateZOHOStatus();
			});
	}

	ngOnInit() {
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				async (summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.application_id = summaryData.id;
						this.summaryData = summaryData;
						this.application_type = 'primary';
						this.loan_number = summaryData?.Application_Number;
						this.folderID = summaryData?.gdriveextension__Drive_Folder_ID;
						this.co_app_required = summaryData.Co_Appl_Required;
						await this.loadInveritePage();
					}
				}
			);
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			const roleID = userData?.roleID;
			this.userData = userData;
			this.isExternalView = roleID === 12 ? true : false;
		});
		this.apiService.getInveriteBanks().subscribe((res) => {
			this.banks = res['banks'];
			if (this.banks.length === 0)
				console.log("No Inverite!");
		});
	}

	async loadInveritePage() {
		if (this.application_type == 'primary') {
			this.application_type_label = 'Primary';
		} else if (this.application_type == 'co_applicant') {
			this.application_type_label = 'Co-Applicant';
		}
		if (await this.checkGuidExists()) {
			console.log('A Request already exists');
			//this.intervalId = setInterval(() => this.checkInveriteRequestStatus(this.bank_requests[0]['guid']), 5000);
			this.checkInveriteRequestStatus(this.bank_requests[0]['guid']);
			this.homePage();
		} else {
			console.log('A Request does not exist');
			this.inveriteStatus = 'Not Created';
			clearInterval(this.intervalId);
			if (await this.NLSApiError()) {
				this.model = new inveriteRequest(
					this.application_id,
					this.loan_number,
					'',
					'',
					'',
					'',
					'',
					'',
					null,
					'202',
					this.application_type
				);
			}
			// console.log(this.model);
			console.log("Inverite model");
			this.selectBankPage();
		}
	}

	updateApplicationType(e) {
		this.application_type = e.target.value;
		this.loadInveritePage();
	}

	selectionChange(s) {
		this.bank_view = s;
		console.log('BankView');
		// console.log(this.bank_view);
		for (var i = 0; i < this.bank_requests.length; i++) {
			if (s.row_id == this.bank_requests[i]['row_id'])
				this.bank_requests[i]['checked'] = true;
			else this.bank_requests[i]['checked'] = false;
		}
		this.checkInveriteRequestStatus(s.guid);
		console.log('Selection Change');
		// console.log(s);
	}
	async checkGuidExists() {
		return new Promise((resolve, reject) => {
			this.bank_requests = [];
			this.apiService
				.getInveriteRequestApplication(
					this.application_id,
					this.application_type
				)
				.subscribe((res: []) => {
					try {
						let check = [];
						check = res;
						if (check.length == 0) {
							resolve(false);
						} else if (check.length >= 1) {
							this.model = new inveriteRequest(
								check[0]['application_id'],
								check[0]['loan_number'],
								check[0]['email'],
								check[0]['phone_number'],
								check[0]['first_name'],
								check[0]['last_name'],
								'',
								check[0]['province'],
								null,
								'202',
								check[0]['application_type']
							);
							if (!this.bank_id_selected)
								this.bank_id_selected = check[0]['bank_id'];
							for (var i = 0; i < check.length; i++) {
								this.bank_requests.push(check[i]);
							}
							for (var i = 0; i < this.bank_requests.length; i++) {
								this.bank_requests[i]['checked'] = false;
							}
							resolve(true);
						}
					} catch (err) {
						resolve(false);
					}
				});
		});
	}
	showHideForm() {
		this.hideForm = !this.hideForm;
	}
	async NLSApiError() {
		return new Promise((resolve, reject) => {
			this.apiService
				.getZohoApplication(this.application_id)
				.subscribe((res) => {
					if (!res) {
						alert('UNABLE TO CONNECT TO ZOHO - PLEASE TRY AGAIN IN 1 MINUTE');
						resolve(true);
					} else {
						let zoho_info = res['data'][0];
						console.log('ZOHO application information');
						// console.log(zoho_info);
						let first_name;
						let middle_name;
						let last_name;
						if (this.application_type == 'primary') {
							try {
								first_name = zoho_info['Deal_Name'].split(' ')[0];
							} catch (err) {
								first_name = '';
							}
							try {
								middle_name = zoho_info['Deal_Name'].split(' ')[1];
							} catch (err) {
								middle_name = '';
							}
							try {
								last_name = zoho_info['Deal_Name'].split(' ')[2];
							} catch (err) {
								try {
									last_name = zoho_info['Deal_Name'].split(' ')[1];
									middle_name = '';
								} catch (err) {
									last_name = '';
								}
							}
							if (!zoho_info['Mobile']) {
								zoho_info['Mobile'] = '';
							}
							this.model = new inveriteRequest(
								this.application_id,
								this.loan_number,
								zoho_info['Email1'],
								zoho_info['Mobile'],
								first_name,
								last_name,
								'',
								zoho_info['Province1'],
								null,
								'202',
								this.application_type
							);
							resolve(false);
						} else if (this.application_type == 'co_applicant') {
							first_name = zoho_info['Co_Applicant_Name'];
							middle_name = '';
							last_name = zoho_info['Guarantor_Name'];
							if (!zoho_info['Secondary_Cell_Number']) {
								zoho_info['Secondary_Cell_Number'] = '';
							}
							this.model = new inveriteRequest(
								this.application_id,
								this.loan_number,
								zoho_info['Email'],
								zoho_info['Secondary_Cell_Number'],
								first_name,
								last_name,
								'',
								zoho_info['Co_Applicant_Province'],
								null,
								'202',
								this.application_type
							);
							resolve(false);
						}
					}
				});
		});
	}
	addTab(selectAfterAdding: boolean) {
		this.account_labels = [];
		this.accounts = [];
		for (var i = 0; i < this.bankStatement['accounts'].length; i++) {
			this.accounts.push(this.bankStatement['accounts'][i]);
			try {
				this.account_labels.push(
					this.bankStatement['accounts'][i]['type'].concat(
						' (' + this.bankStatement['accounts'][i]['account'] + ')'
					)
				);
			} catch (err) {
				this.account_labels.push(this.bankStatement['accounts'][i]['type']);
			}
		}

		if (selectAfterAdding) {
			this.selected.setValue(this.accounts[0]);
		}
	}
	async checkInveriteRequestStatus(guid) {
		this.clearBankStatement();
		try {
			this.apiService.checkInveriteRequestStatus(guid).subscribe((res) => {
				try {
					if (res['status'][0]['result'] == 'Verified') {
						clearInterval(this.intervalId);
						this.showInveriteBankStatements(guid);
						this.inveriteStatus = res['status'][0]['result'];
					} else {
						this.inveriteStatus = res['status'][0]['result'];
						console.log('CHECKING FOR BANK STATEMENTS');
					}
				} catch (err) {
					this.inveriteStatus = "Client hasn't started";
				}
			});
		} catch (err) {
			this.inveriteStatus = 'Not Created';
			// alert("An Inverite Request has not yet been created for this client")
		}
	}

	filterTransactions(tag, index) {
		const categories = ['food', 'auto', 'shopping', 'entertainment', 'gambling', 'bills_and_utilities']
		if (
			this.hasProperties(this.bankStatement.accounts[index]['transactions'])
		) {
			if (categories.includes(tag)) {
				this.is_category = true;
			} else {
				this.is_category = false;
			}
			this.transaction_pill = tag;
			console.log(this.bankStatement);
			console.log(index, "Index____________");
			console.log(this.transaction_pill, "transaction_pill_____________");
		}
	}

	// Return 6 months of transaction summary
	monthlyTransactionSummary(index) {
		if (this.hasProperties(this.bankStatement.accounts[index]['statistics']['monthly_category_stats'])) {
			let monthly_category_stats = this.bankStatement.accounts[index]['statistics']['monthly_category_stats'];
			return (monthly_category_stats.length > 6) ? monthly_category_stats?.slice(0, 6) : monthly_category_stats;
		}
	}
	async showInveriteBankStatements(guid) {
		clearInterval(this.intervalId);
		let acc_check = await this.checkAcc_Check_Status(guid);
		this.clearBankStatement();
		this.apiService
			.getInveriteRequest(
				this.application_id,
				this.application_type,
				this.bank_id_selected
			)
			.subscribe((res) => {
				let data = res;
				console.log(data);
				try {
					this.apiService
						.showInveriteBankStatements(this.bank_view['guid'])
						.subscribe(async (res) => {
							console.log(res);
							if (res.hasOwnProperty('error')) {
								alert(res['error']);
							} else {
								if (acc_check) {
									console.log(acc_check);
									this.bankStatement = res;
									console.log('__________________________');
									console.log('The Bank Statement');
									console.log(this.bankStatement);
									//this.bankStatement["accounts"].push(this.bankStatement["accounts"][0]) //TESTING ZZZZZZ
									this.addTab(false);
								} else {
									//this.openSelectAccountModel(res)
									this.bankStatement = res;
									console.log('__________________________');
									console.log('The Bank Statement');
									console.log(this.bankStatement);
									this.addTab(false);
								}
							}
						});
				} catch (err) {
					console.log(err);
					alert('An Inverite Request has not yet been created for this client');
				}
			});
	}
	openSelectAccountModel(res) {
		console.log(res);
		res['application_id'] = this.application_id;
		res['application_type'] = this.application_type;
		res['bank_id'] = this.bank_id_selected;
		this.apiService.sendMessage(res);
		const dialogConfig = new MatDialogConfig();
		dialogConfig.autoFocus = false;
		dialogConfig.height = '90%';
		dialogConfig.disableClose = false;
		this.dialog.open(AccountSelectComponent, dialogConfig);
	}
	async checkAcc_Check_Status(guid) {
		return new Promise((resolve, reject) => {
			this.apiService.checkAcc_Check_Status(guid).subscribe((res) => {
				if (!res['status'] || res['data'][0]['acc_check'] == 0) {
					this.zohoUploadStatus = 'Not Submitted';
					resolve(false);
				} else {
					this.zohoUploadStatus = 'Submitted';
					resolve(true);
				}
			});
		});
	}
	onItemSelect(item: any) {
		this.model.bankid = this.selectedItems[0].bankID;
		this.model.Bank = this.selectedItems[0].name.toString();
	}

	changeBank(e) {
		this.selectedItems = this.banks.filter((obj) => {
			return obj.name === e.target.value;
		});
		this.model.bankid = this.selectedItems[0].bankID;
		this.model.Bank = this.selectedItems[0].name.toString();
		console.log(this.model);
	}
	changeAccount(e) {
		this.selectedAccount = this.banks.filter((obj) => {
			return obj.name === e.target.value;
		});
		this.model.bankid = this.selectedItems[0].bankID;
		this.model.Bank = this.selectedItems[0].name.toString();
	}
	onSubmit() {
		this.clearBankStatement();
		console.log(this.model);
		if (
			this.model.application_id == '' ||
			this.model.loan_number == '' ||
			this.model.email == '' ||
			this.model.FirstName == '' ||
			this.model.LastName == '' ||
			this.model.Bank == '' ||
			this.model.Province == '' ||
			this.model.bankid == null ||
			this.model.siteID == ''
		) {
			alert('Information Missing');
		} else {
			this.apiService
				.getInveriteRequest(
					this.application_id,
					this.application_type,
					this.model.bankid
				)
				.subscribe((res: []) => {
					let data = res;
					try {
						if (data.length != 0) {
							if (
								confirm(
									'An Inverite Request has already been created for this Client, would you like to create another one?'
								)
							) {
								this.createInveriteRequest(this.model);
							}
						} else {
							this.createInveriteRequest(this.model);
						}
					} catch (err) {
						alert('Failed to check if Request already exists');
						return;
					}
				});
		}
	}
	createInveriteRequest(model) {
		this.apiService.createInveriteRequest(model).subscribe(async (res) => {
			if (res.hasOwnProperty('request_guid')) {
				this.inverite_entry['iframeurl'] = res['iframeurl'];
				this.inverite_entry['guid'] = res['request_guid'];
				this.guid = res['request_guid'];
				this.inverite_entry['username'] = res['username'];
				this.inverite_entry['application_id'] = this.application_id;
				this.inverite_entry['loan_number'] = this.model.loan_number;
				this.inverite_entry['email'] = this.model.email;
				this.inverite_entry['first_name'] = this.model.FirstName;
				this.inverite_entry['last_name'] = this.model.LastName;
				this.inverite_entry['phone_number'] = this.model.phone;
				this.inverite_entry['bank_name'] = this.model.Bank;
				this.inverite_entry['bank_id'] = this.model.bankid;
				this.inverite_entry['province'] = this.model.Province;
				this.inverite_entry['application_type'] = this.model.application_type;
				//this.intervalId = setInterval(() => this.checkInveriteRequestStatus(this.inverite_entry["guid"]), 5000);
				this.apiService
					.updateInverite(this.inverite_entry)
					.subscribe(async (res) => {
						if (!res['status']) alert('Unable to update Database');
						else {
							alert('Request has been sent out successfully');
							//console.log(await this.updateBanksDropDown())
							if (await this.checkGuidExists()) {
								console.log('A Request already exists');
								//this.intervalId = setInterval(() => this.checkInveriteRequestStatus(this.bank_requests[0]['guid']), 5000);
								this.checkInveriteRequestStatus(this.bank_requests[0]['guid']);
								this.homePage();
							} else {
								console.log('A Request does not exist');
								this.inveriteStatus = 'Not Created';
								clearInterval(this.intervalId);
								if (await this.NLSApiError()) {
									this.model = new inveriteRequest(
										this.application_id,
										this.loan_number,
										'',
										'',
										'',
										'',
										'',
										'',
										null,
										'202',
										this.application_type
									);
								}
								console.log(this.model);
								this.selectBankPage();
							}
						}
					});
			} else {
				alert('Unable to create a Request');
			}
		});
	}
	newInveriteRequest() {
		this.model = new inveriteRequest(
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			null,
			'',
			this.application_type
		);
	}
	clearBankStatement() {
		this.bankStatement = null;
		this.account_labels = [];
		this.accounts = [];
	}
	ResetForm() {
		console.log(this.bank_view);
		//clearInterval(this.intervalId)
		this.clearBankStatement();
		// let bank_selected = this.bank_requests.filter((s) => {
		//   return s.bank_id == this.bank_id_selected
		// })
		this.apiService
			.deleteGuid(this.bank_view['guid'])
			.subscribe(async (res) => {
				if (res['status']) {
					alert('Bank has been deleted succesfully');
					this.inveriteStatus = 'Not Created';
					this.homePage();
				} else {
					alert('Failed to Delete Bank');
					this.homePage();
				}
			});
	}
	AddBankForm() {
		clearInterval(this.intervalId);
		this.clearBankStatement();
		this.selectBankPage();
	}
	updateBanksDropDown() {
		return new Promise((resolve, reject) => {
			this.apiService
				.getInveriteRequestApplication(
					this.application_id,
					this.application_type
				)
				.subscribe((res: []) => {
					try {
						let check = [];
						check = res;
						if (check.length == 0) {
							resolve(false);
						} else if (check.length >= 1) {
							this.bank_id_selected = check[0]['bank_id'];
							this.bank_requests = [];
							for (var i = 0; i < check.length; i++) {
								this.bank_requests.push(check[i]);
							}
							resolve(true);
						}
					} catch (err) {
						console.log('Error checking if guid exists');
						console.log(err);
						resolve(false);
					}
				});
		});
	}
	updateZohoApplication_Inverite(acc) {
		this.apiService
			.updateZohoApplication_Inverite(
				acc,
				this.application_id,
				this.application_type
			)
			.subscribe((res) => {
				try {
					let result = JSON.parse(res['body']);
					alert(result['data'][0]['code']);
					this.zohoUploadStatus = 'Submitted';
					this.apiService
						.updateAcc_Check(this.application_id, this.application_type)
						.subscribe((res) => { });
				} catch (err) {
					alert('Unable to Update ZOHO application');
					this.apiService
						.updateAcc_Check(this.application_id, this.application_type)
						.subscribe((res) => { });
				}
			});
	}
	hasProperties(something) {
		try {
			return Object.entries(something).length > 0;
		} catch (err) {
			return false;
		}
	}
	bankSelectionChange() {
		let bank_account_selected = this.bank_id_selected;
		clearInterval(this.intervalId);
		//this.show_input = false
		this.clearBankStatement();
		this.inveriteStatus = 'Checking...';
		let bank_selected = this.bank_requests.filter((s) => {
			return s.bank_id == this.bank_id_selected;
		});
		try {
			//this.intervalId = setInterval(() => this.checkInveriteRequestStatus(bank_selected[0]["guid"]), 5000);
			this.bank_id_selected = bank_account_selected;
		} catch (err) {
			console.log(err);
		}
	}
	bankSelected() {
		if (!this.model.bankid) {
			alert('You must select a Bank');
			return;
		} else {
			this.createRequestPage();
		}
	}
	selectBankPage() {
		clearInterval(this.intervalId);
		this.model.bankid = 1;
		this.model.Bank = 'TD Canada Trust';
		this.inveriteStatus = 'Not Created';
		this.selectBankFlag = true;
		this.createRequestFlag = false;
		this.homeFlag = false;
		this.homeFlag = false;
	}
	createRequestPage() {
		clearInterval(this.intervalId);
		this.selectBankFlag = false;
		this.createRequestFlag = true;
		this.homeFlag = false;
		this.homeFlag = false;
	}
	async homePage() {
		this.clearBankStatement();
		if (await this.checkGuidExists()) {
			console.log('Here are the bank requests');
			// console.log(this.bank_requests);
			this.bank_requests[0]['checked'] = true;
			this.bank_view = this.bank_requests[0];
			this.selectionChange(this.bank_view);
			this.selectBankFlag = false;
			this.createRequestFlag = false;
			this.homeFlag = true;
			let result = await this.checkAcc_Check_Status(this.bank_view['guid']);
		} else {
			this.inveriteStatus = 'Not Started';
			alert('No Requests have been created for this Application');
		}
	}
	async updateZOHOStatus() {
		let bank_selected = this.bank_requests.filter((s) => {
			return s.bank_id == this.bank_id_selected;
		});
		let result = await this.checkAcc_Check_Status(this.bank_view?.['guid']);
		console.log('Check acc check status');
		console.log(result);
	}
	async uploadInveriteGD() {
		let base64 = await this.createBase64File();
		console.log('BASE 64:');
		console.log(base64);
		this.apiService
			.uploadInveriteReportGD(
				this.folderID,
				base64,
				this.model.FirstName.concat(' ').concat(this.model.LastName)
			)
			.subscribe((res) => {
				console.log(res);
				alert(res['message']);
			});
	}
	async createBase64File() {
		return new Promise((resolve, reject) => {
			var element = document.getElementById('testing');
			html2canvas(element).then((canvas) => {
				var HTML_Width = canvas.width;
				var HTML_Height = canvas.height;
				var top_left_margin = 15;
				var PDF_Width = HTML_Width + top_left_margin * 2;
				var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
				var canvas_image_width = HTML_Width;
				var canvas_image_height = HTML_Height;

				var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
				console.log(HTML_Height);
				console.log(PDF_Height);
				console.log('Number of Pages: ' + totalPDFPages);
				console.log('____________________');

				var imgData = canvas.toDataURL('image/jpeg', 1.0);
				var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
				pdf.addImage(
					imgData,
					'JPG',
					top_left_margin,
					top_left_margin,
					canvas_image_width,
					canvas_image_height
				);

				for (var i = 1; i <= totalPDFPages; i++) {
					pdf.addPage();
					let margin = -(PDF_Height * i) + top_left_margin * 4;
					if (i > 1) {
						margin = margin + i * 8;
					}
					pdf.addImage(
						imgData,
						'JPG',
						top_left_margin,
						margin,
						canvas_image_width,
						canvas_image_height
					);
				}

				//pdf.save("HTML-Document.pdf");
				pdf.setFontSize(5);
				//let something = (canvas.toDataURL('image/png')).toString()
				let something = pdf.output('datauristring');
				something = something.split(/,(.+)/)[1];
				resolve(something);
			});
		});
	}

	ngOnDestroy() {
		clearInterval(this.intervalId);
		this.summaryDataSubscription?.unsubscribe();
	}
}
