import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { ColDef } from '@ag-grid-community/core';
import 'jspdf-autotable';

@Component({
	selector: 'app-amo-schedule',
	templateUrl: './amo-schedule.component.html',
	styleUrls: ['./amo-schedule.component.scss'],
})
export class AmoScheduleComponent implements OnInit, OnChanges {
	@Input() acctrefno?: any;
	@Input() openAmoFlag?: any;

	amo_schedule: any;
	// acctrefno: any;
	columnDefs_Amo;
	defaultColDef_Amo;
	getRowStyle_Amo;
	gridApi_Amo;
	gridColumnApi_Amo;
	// rowData_Amo;
	autoGroupColumnDef;
	Total_InterestAmount = 0;
	Total_OtherAmount = 0;
	Total_PaymentAmount = 0;
	Total_PrincipalAmount = 0;

	public columnDefs;
	public defaultColDef: ColDef = { resizable: true };
	public rowData_Amo: any[];
	public getRowStyle: any;

	constructor(private apiService: ApiServiceService) {
	}

	ngOnInit(): void {
		// console.log(this.acctrefno, "^^^^^^^^^^^^^^^^^^^^^^^^^")
		// this.acctrefno = this.apiService.getMessage();
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (this.acctrefno && this.openAmoFlag) {
			let result = await this.getNLSAmortizationSchedule(this.acctrefno).then(res => { this.amo_schedule = res; this.rowData_Amo = this.amo_schedule; });
			this.getAmoSummary(this.amo_schedule);
			//Amortization Schedule
			this.columnDefs = [
				{
					headerName: 'Payment Date',
					field: 'PaymentDate',
					filter: 'agTextColumnFilter',
					minWidth: 150,
					autoHeight: true,
				},
				{
					headerName: 'Payment Amount',
					field: 'PaymentAmount',
					filter: 'agTextColumnFilter',
					minWidth: 150,
					autoHeight: true,
				},
				{
					headerName: 'Interest Amount',
					field: 'InterestAmount',
					filter: 'agTextColumnFilter',
					minWidth: 150,
					autoHeight: true,
				},
				{
					headerName: 'Principal Amount',
					field: 'PrincipalAmount',
					filter: 'agTextColumnFilter',
					minWidth: 150,
					autoHeight: true,
				},
				{
					headerName: 'Other Amount',
					field: 'OtherAmount',
					filter: 'agTextColumnFilter',
					minWidth: 150,
					autoHeight: true,
				},
				{
					headerName: 'Balance Amount',
					field: 'BalanceAmount',
					filter: 'agTextColumnFilter',
					minWidth: 150,
					autoHeight: true,
				},
			];
			this.defaultColDef = {
				resizable: true,
			};
			this.autoGroupColumnDef = {
				minWidth: 300,
			};
			this.getRowStyle_Amo = (params) => {
				if (params.data.isHistory)
					return { background: '#D5DBE1', 'font-size': '15px' }; //grey
				else return { 'font-size': '15px' };
			};
		}
	}
	async getNLSAmortizationSchedule(acctref) {
		return new Promise((resolve, reject) => {
			this.apiService
				.getNLSAmortizationSchedule(acctref, '2021-09-27')
				.subscribe((res) => { resolve(res); })
		});
	}

	getAmoSummary(amo_schedule) {
		for (var i = 0; i < amo_schedule.length; i++) {
			try {
				this.Total_InterestAmount += amo_schedule[i]['InterestAmount'];
			} catch (err) {
				console.log('Invalid Number');
			}
			try {
				this.Total_PrincipalAmount += amo_schedule[i]['PrincipalAmount'];
			} catch (err) {
				console.log('Invalid Number');
			}
			try {
				this.Total_OtherAmount += amo_schedule[i]['OtherAmount'];
			} catch (err) {
				console.log('Invalid Number');
			}
			try {
				this.Total_PaymentAmount += amo_schedule[i]['PaymentAmount'];
			} catch (err) {
				console.log('Invalid Number');
			}
		}
	}
}
