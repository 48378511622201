import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	NgZone,
	Input,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	UntypedFormArray,
	UntypedFormBuilder,
	Validators,
} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core'; // Auto Addressing
import { ApplicationEntry } from '../models/application';

@Component({
	selector: 'app-create',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
	@ViewChild('search') public searchElement: ElementRef;
	@ViewChild('searchAddress') public searchElementAddress: ElementRef;
	@ViewChild('consentInput') consentInput: ElementRef;

	title = 'pagination';
	POSTS: any;
	page: number = 1;
	count: number = 0;
	tableSize: number = 10;
	tableSizes: any = [5, 10, 15, 20];

	private regex: RegExp = new RegExp('^[0-9]*$'); // Numbers only
	private specialKeys: Array<string> = [
		'Backspace',
		'ArrowLeft',
		'ArrowRight',
		'Delete',
		'.',
		'Tab',
	]; // Accept the ones in the array

	// Arguments to use the library
	constructor(
		private apiService: ApiServiceService,
		private router: ActivatedRoute,
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private fb: UntypedFormBuilder,
		private _routerLink: Router
	) { }

	getparamid: any; // For Edit ngOnInit() to get the ID
	readResidencyDropdown: any; // This is to use it on hmtl to populate the dropdown
	readEmploymentDropdown: any; // This is to use it on hmtl to populate the dropdown
	readCreditorDropdown: any;
	readCreditPortfolioDropdown: any;
	readCreditTypeDropdown: any;
	readProvinceDropdown: any;
	readPaymentMethodDropdown: any;
	readBankruptcyDropdown: any;
	readMaritalStatusDropdown: any;
	readNumberDependentsDropdown: any;
	readBestContactTimeDropdown: any;
	readNoSinReasonDropdown: any;
	readSalesAgentDropdown: any;
	readBrokerDropdown: any;
	readReferralCreditorDropdown: any;
	readReferralMethodDropdown: any;
	readAgencyLocationDropdown: any;
	readLoanTypeDropdown: any;
	readLeadSourceDropdown: any;
	readPayFrequencyDropdown: any;
	readResidenceLengthDropdown: any;
	readAgencyRecords: any; // Agency for Additional Debt Subform
	readCollectorRecords: any; // Agency for Emergency Contacts Subform
	readStageRecords: any; // Get Stage list status

	listFolders: any; // List the folders

	uniqueIDgenerated = ''; // Unique applicant ID
	newDebt: any;
	debttotal: any;
	folderName: any; // concat the foldername (firstname + ' ' + middlename + ' ' + lastname + ' - ' + uniqueID)

	submitted = false; // form submission

	applicationEntry = new ApplicationEntry(this.fb);

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.loadData(); // Load the whole dropdowns

						// if (this.applicationForm.controls['isPreviousEmployment'].value == false) {
						//   this.applicationForm.get('isPreviousEmployment').setValue(0);
						// }

						this.applicationEntry.applicationForm.controls['stage'].setValue(
							'2390707000004269221'
						); // Set the default value of of stage to incomplete application

						this.getparamid = this.router.snapshot.paramMap.get('id'); // This will retrieve the ID that is linked on the routerlink from the html

						// //console.log(this.getparamid)
						if (this.getparamid === null) {
							//console.log(this.uniqueIDgenerated)
							this.applicationEntry.applicationForm.controls[
								'uniqueID'
							].setValue(this.uniqueIDgenerated);
							return;
						} else {
							// Display will work based on the unique ID and record ID
							if (this.getparamid.length === 25) {
								// If this.getparamid is unique ID which is 25 in length then display from the local database
								//console.log(this.getparamid)
								this.displayLocalDataEdit();
							} else {
								//console.log(this.getparamid)
								this.displayZOHODataClone(); // Else, display from the ZOHO CRM based on the record ID
								this.getparamid = ''; // This will remove the record ID in order to initiate the create application features
							}
						}
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	// Display the data from local database to edit
	displayLocalDataEdit() {
		this.apiService.getSingleData(this.getparamid).subscribe((res) => {
			// Display the individual applicant fetched on the form controls. [0] Because only one array and the length is always 0
			this.applicationEntry.applicationForm.patchValue({
				uniqueID: this.getparamid, // Unique ID for local database update
				recordID: res.data[0].zoho_record_id, // Record ID for ZOHO application update
				folderID: res.data[0].folder_ID,

				// PRE-QUALIFYING
				Province1: res.data[0].province_name,
				Residency_Status: res.data[0].residency_status,
				co_signer: res.data[0].co_signer,
				employment_type: res.data[0].employment_type,
				employed: res.data[0].employed,
				least_earn: res.data[0].least_earn,
				payment_method: res.data[0].payment_method,
				payday_loans: res.data[0].payday_loans,
				bankruptcy_cc_pp: res.data[0].bankruptcy_cc_pp,
				home_owner: res.data[0].home_owner,
				lm_credit_loan: res.data[0].lm_credit_loan,
				consolidating_hil: res.data[0].consolidating_hil,

				// CLIENT - EXISTING MORTGAGE
				mortgage_balance: this.displayFormattedCurrency(
					res.data[0].mortgage_balance
				),
				mortgage_value: this.displayFormattedCurrency(
					res.data[0].mortgage_value
				),
				loan_details: res.data[0].loan_details,
				mortgage_details: res.data[0].mortgage_details,

				// REFERRAL
				referral_agency: res.data[0].referral_agency,
				sales_agent: res.data[0].sales_agent,
				referral_collector: res.data[0].referral_collector,
				broker: res.data[0].broker,
				referral_creditor: res.data[0].referral_creditor,
				referral_method: res.data[0].referral_method,
				creditor_details: res.data[0].creditor_details,
				referral_date: this.displayDate(res.data[0].referral_date),
				account_edp: res.data[0].account_edp,
				closing_date: this.displayDate(res.data[0].closing_date),
				referral_balance: this.displayFormattedCurrency(
					res.data[0].referral_balance
				),
				agency_location: res.data[0].agency_location,
				settlement_amount: this.displayFormattedCurrency(
					res.data[0].settlement_amount
				),
				referral_creditor_portfolio: res.data[0].referral_creditor_portfolio,
				requested_amount: this.displayFormattedCurrency(
					res.data[0].requested_amount
				),
				loan_type: res.data[0].loan_type,
				lead_source: res.data[0].lead_source,

				// ADDITIONAL DEBT
				isDebt: res.data[0].debt,

				// CLIENT - PERSONAL
				Deal_Name: res.data[0].application_name,
				gender: res.data[0].gender,
				another_name: res.data[0].another_name,
				marital_status: res.data[0].marital_status,
				alternative_name: res.data[0].alternative_name,
				number_dependents: res.data[0].number_dependents,
				best_contact_time: res.data[0].best_contact_time,
				birth_date: this.displayDate(res.data[0].birth_date),
				last_contact_date: this.displayDate(res.data[0].last_contact_date),
				// firstname: res.data[0].first_name,
				// middlename: res.data[0].middle_name,
				// lastname: res.data[0].last_name,
				S_I_N: res.data[0].sin,
				own_car: res.data[0].own_car,
				car_value: this.displayFormattedCurrency(res.data[0].car_value),
				email: res.data[0].email,
				mobile: res.data[0].mobile,
				phone: res.data[0].phone,
				sin_reason: res.data[0].sin_reason,
				co_applicant: res.data[0].co_applicant,
				isEmergency: res.data[0].emergency_contacts,

				// CLIENT - ADDRESS
				address1: res.data[0].address1,
				address2: res.data[0].address2,
				city: res.data[0].city,
				province_code: res.data[0].province_code,
				postal_code: res.data[0].postal_code,
				residence_length: res.data[0].residence_length,
				monthly_payments: this.displayFormattedCurrency(
					parseFloat(res.data[0].monthly_payments)
				),

				// CLIENT - EMPLOYMENT
				employer: res.data[0].employer,
				pay_frequency: res.data[0].pay_frequency,
				work_phone: res.data[0].work_phone,
				net_paycheque: this.displayFormattedCurrency(
					parseFloat(res.data[0].net_paycheque)
				),
				work_extension: res.data[0].work_extension,
				supervisor_name: res.data[0].supervisor_name,
				employment_length: res.data[0].employment_length,
				supervisor_phone: res.data[0].supervisor_phone,
				position: res.data[0].position,
				additional_employment: res.data[0].additional_employment,
				income_amount: this.displayFormattedCurrency(
					parseFloat(res.data[0].income_amount)
				),
				employer_name: res.data[0].employer_name,
				household_pay_frequency: res.data[0].household_pay_frequency,

				// CLIENT - PREVIOUS EMPLOYMENT
				isPreviousEmployment:
					res.data[0].isPreviousEmployment == 1 ? true : false, // In order to do check and uncheck
				previous_employment_type: res.data[0].previous_employment_type,
				previous_position: res.data[0].previous_position,
				previous_employer: res.data[0].previous_employer,
				previous_emp_length: res.data[0].previous_emp_length,
				previous_supervisor_name: res.data[0].previous_supervisor_name,
				previous_supervisor_phone: res.data[0].previous_supervisor_phone,
				previous_income: this.displayFormattedCurrency(
					parseFloat(res.data[0].previous_income)
				),
				previous_pay_frequency: res.data[0].previous_pay_frequency,
				previous_pay_method: res.data[0].previous_pay_method,

				// CO-APPLICANT - PERSONAL
				co_app_firstname: res.data[0].co_app_firstname,
				co_app_lastname: res.data[0].co_app_lastname,
				co_app_gender: res.data[0].co_app_gender,
				co_app_email: res.data[0].co_app_email,
				co_app_birth: this.displayDate(res.data[0].co_app_birth),
				co_app_homenumber: res.data[0].co_app_homenumber,
				co_app_sin: res.data[0].co_app_sin,
				co_app_mobile: res.data[0].co_app_mobile,

				// CO-APPLICANT - EMERGENCY CONTACT
				co_emergency_name: res.data[0].co_emergency_name,
				co_emergency_number: res.data[0].co_emergency_number,
				co_emergency_relation: res.data[0].co_emergency_relation,
				co_emergency_email: res.data[0].co_emergency_email,

				// CO-APPLICANT - ADDRESS
				co_app_address1: res.data[0].co_app_address1,
				co_app_residential_status: res.data[0].co_app_residential_status,
				co_app_residential_length: res.data[0].co_app_residential_length,
				co_app_address2: res.data[0].co_app_address2,
				co_app_city: res.data[0].co_app_city,
				co_app_province: res.data[0].co_app_province,
				co_app_postal: res.data[0].co_app_postal,
				co_app_country: res.data[0].co_app_country,

				// CO-APPLICANT - EMPLOYMENT
				co_app_emp_type: res.data[0].co_app_emp_type,
				co_app_position: res.data[0].co_app_position,
				co_app_employer: res.data[0].co_app_employer,
				co_app_years_worked: res.data[0].co_app_years_worked,
				co_app_supervisor_name: res.data[0].co_app_supervisor_name,
				co_app_supervisor_phone: res.data[0].co_app_supervisor_phone,
				co_app_annual_income: this.displayFormattedCurrency(
					parseFloat(res.data[0].co_app_annual_income)
				),
				co_app_work_number: res.data[0].co_app_work_number,
				co_app_pay_method: res.data[0].co_app_pay_method,
				co_app_pay_frequency: res.data[0].co_app_pay_frequency,

				stage: res.data[0].stage,
				consentBox: res.data[0].client_consent == 1 ? true : false,
				govtidBox: res.data[0].govt_id == 1 ? true : false,
				bankBox: res.data[0].bank_statement == 1 ? true : false,
				bureauBox: res.data[0].credit_bureau == 1 ? true : false,
			});

			this.populateEmergency(); // Populate the Emergency Subform
			this.populateAdditional(); // Populate the Additional Subform

			this.hideSubForm(); // Display the subforms according the record
			this.previousEmployment(); // Display the previous employment details according to the record

			this.fileCheckBoxParameters(); // The file upload checkbox values
			this.spanDesc(res.data[0]); // The file upload span description
		});
	}

	displayZOHODataClone() {
		this.applicationEntry.applicationForm.controls['uniqueID'].setValue(
			this.uniqueIDgenerated
		);

		//console.log(this.applicationForm.controls['uniqueID'].value)

		this.apiService.getSingleAppZOHO(this.getparamid).subscribe((res) => {
			let arr;
			arr = JSON.parse(res.message.body);

			// Display the individual applicant fetched on the form controls. [0] Because only one array and the length is always 0
			this.applicationEntry.applicationForm.patchValue({
				// PRE-QUALIFYING
				Province1: arr.data[0].Province1,

				// CLIENT - PERSONAL
				Deal_Name: arr.data[0].Deal_Name,
				Residency_Status: arr.data[0].Residency_Status,
				gender: arr.data[0].Gender,
				another_name: arr.data[0].Do_you_have_another_name,
				marital_status: arr.data[0].Marital_Status,
				alternative_name: arr.data[0].Alternative_Name,
				number_dependents: arr.data[0].Number_of_Dependents,
				best_contact_time: arr.data[0].Best_contact_time,
				birth_date:
					arr.data[0].Date_of_Birth == null
						? ''
						: this.displayDate(arr.data[0].Date_of_Birth),
				last_contact_date:
					arr.data[0].Last_Contact_Date == null
						? ''
						: this.displayDate(arr.data[0].Last_Contact_Date),
				// firstname: res.data[0].first_name,
				// middlename: res.data[0].middle_name,
				// lastname: res.data[0].last_name,
				S_I_N: arr.data[0].S_I_N,
				own_car: arr.data[0].Does_you_own_a_car,
				car_value:
					arr.data[0].What_is_the_value_of_the_car == null
						? '$0.00'
						: this.displayFormattedCurrency(
							parseFloat(arr.data[0].What_is_the_value_of_the_car)
						),
				email: arr.data[0].Email1,
				mobile: arr.data[0].Mobile,
				phone: arr.data[0].Phone,
				sin_reason: arr.data[0].No_SIN_Reason,

				// CLIENT - ADDRESS
				address1: arr.data[0].Address1,
				address2: arr.data[0].Address_Line_2,
				city: arr.data[0].City,
				province_code: arr.data[0].Province,
				postal_code: arr.data[0].Postal_Code,
				residence_length: arr.data[0].Length_of_Residence,
				monthly_payments:
					arr.data[0].Monthly_Payments == null
						? '$0.00'
						: this.displayFormattedCurrency(
							parseFloat(arr.data[0].Monthly_Payments)
						),

				// CLIENT - EMPLOYMENT
				employer: arr.data[0].Employer,
				pay_frequency: arr.data[0].Pay_Frequency2,
				work_phone: arr.data[0].Work_Phone,
				net_paycheque:
					arr.data[0].Income_net_paycheque_income == null
						? '$0.00'
						: this.displayFormattedCurrency(
							parseFloat(arr.data[0].Income_net_paycheque_income)
						),
				work_extension: arr.data[0].Work_Extension,
				supervisor_name: arr.data[0].Supervisor_Name,
				employment_length: arr.data[0].Length_of_Employment,
				supervisor_phone: arr.data[0].Supervisor_Phone_Number,
				position: arr.data[0].Position,
				additional_employment: arr.data[0].Additional_Employment,
				income_amount:
					arr.data[0].Income_Amount_Household_Income == null
						? '$0.00'
						: this.displayFormattedCurrency(
							parseFloat(arr.data[0].Income_Amount_Household_Income)
						),
				employer_name: arr.data[0].Employer_Name_Household_Income,
				household_pay_frequency: arr.data[0].Pay_Frequency_Household_Income,
			});
		});
	}

	// Display "yyyy-mm-dd" if the date is null or correct date if it is entered
	displayDate(displayDate: any) {
		if (displayDate === '1900-01-01T00:00:00.000Z') {
			return '';
		} else {
			return displayDate.split('T')[0];
		}
	}

	// Display the amounts in currency format on edit display
	displayFormattedCurrency(amount: any) {
		return amount.toLocaleString('en-CA', {
			style: 'currency',
			currency: 'CAD',
		});
	}

	// Done to make the checkbox of File Upload can be checked and unchecked
	checkFileUpload(event: any) {
		this.fileCheckBoxParameters();
	}

	// Set the File Upload according to the checkbox marked
	fileUploadDesc(checkBoxName: any) {
		if (checkBoxName.value == false) {
			checkBoxName.setValue(0);
		} else {
			checkBoxName.setValue(1);
		}
	}

	// CheckBox names passed on the fileUploadDesc(checkBoxName: any, spanName: any)
	fileCheckBoxParameters() {
		this.fileUploadDesc(
			this.applicationEntry.applicationForm.get('consentBox')
		);
		this.fileUploadDesc(this.applicationEntry.applicationForm.get('govtidBox'));
		this.fileUploadDesc(this.applicationEntry.applicationForm.get('bankBox'));
		this.fileUploadDesc(this.applicationEntry.applicationForm.get('bureauBox'));
	}

	// Display File Upload Span Description for Edit
	spanDesc(data: any) {
		document.getElementById('consent').innerText =
			data.client_consent == 1 ? 'Consent file was uploaded' : '';
		document.getElementById('govtid').innerText =
			data.govt_id == 1 ? 'Govt ID was uploaded' : '';
		document.getElementById('bank').innerText =
			data.bank_statement == 1 ? 'Bank statement was uploaded' : '';
		document.getElementById('bureau').innerText =
			data.credit_bureau == 1 ? 'Credit bureau was uploaded' : '';
	}

	loadData() {
		this.generateUniqueID(); // Load the hidden applicant ID

		// PRE-QUALIFYING
		this.getProvinceDesc(); // Load prequalifying Province dropdown
		this.getResidencyDesc(); // Load residency dropdown
		this.getEmploymentDesc(); // Load employment dropdown
		this.getPaymentMethodDesc();
		this.getBankruptcyDesc();

		// REFERRAL
		this.getAgencyRecords(); // Load the Agency on REFERRAL and Additional Debt Subform
		this.getCollectorRecords(); // Load the Agency on Emergency Contacts Subform
		this.getSalesAgent();
		this.getBroker();
		this.getCreditor();
		this.getReferralMethod();
		this.getAgencyLocation();
		this.getLoanType();
		this.getLeadSource();
		this.getCreditorPortfolioDesc(); // Load Creditor Portfolio on both Referral and additional subform

		//this.getCreditorDesc(); // Load employment dropdown Was used on the additional debt subform creditor

		// ADDITIONAL SUB FROM
		this.getCreditTypeDesc();

		// CLIENT PERSONAL
		this.getMaritalStatus();
		this.getNumberDependents();
		this.getBestContactTime();
		this.getNoSinReason();

		// CLIENT ADDRESS
		this.loadAutoAddressing(); // Load the AutoAddresing

		// CLIENT - EMPLOYMENT
		this.getPayFrequency();

		// CO-APPLICANT
		this.getResidenceLength();
		this.loadCoAppAutoAddressing();

		// Stage
		this.getStage();

		this.hideFormsOnLoad(); // Hide the subForms on LOAD
	}

	// Reset all the sub forms to hide
	hideFormsOnLoad() {
		document.getElementById('myHomeOwner').style.display = 'none';
		document.getElementById('myAddDebt').style.display = 'none';
		document.getElementById('myEmergency').style.display = 'none';
		document.getElementById('coPersonal').style.display = 'none';
		document.getElementById('coEmergency').style.display = 'none';
		document.getElementById('coAddress').style.display = 'none';
		document.getElementById('coEmployment').style.display = 'none';
		document.getElementById('myPrevEmp').style.display = 'none';
	}

	// Pass the subform names to hide
	hideSubForm() {
		this.subFormSetHide('home_owner', 'myHomeOwner');
		this.subFormSetHide('isDebt', 'myAddDebt');
		this.subFormSetHide('isEmergency', 'myEmergency');
		this.subFormSetHide('co_applicant', 'coPersonal');
		this.subFormSetHide('co_applicant', 'coEmergency');
		this.subFormSetHide('co_applicant', 'coAddress');
		this.subFormSetHide('co_applicant', 'coEmployment');
	}

	// style hide for the sub forms
	subFormSetHide(name: any, name2: any) {
		if (this.applicationEntry.applicationForm.get(name).value == 'Yes') {
			document.getElementById(name2).style.display = 'block';
		} else {
			document.getElementById(name2).style.display = 'none';
		}
	}

	/* SUB FORM of ADDITIONAL DEBTS */
	// get Additional_Debt() {
	//   return this.applicationForm.get('Additional_Debt') as FormArray;
	// }

	/* SUB FORM of ADDITIONAL DEBTS */
	addAdditionalDebts() {
		let debtArray = this.applicationEntry.applicationForm.get(
			'Additional_Debt'
		) as UntypedFormArray;
		this.newDebt = this.fb.group({
			Agency: '',
			misc: '',
			Creditor_Portfolio: '',
			Account_Number: '',
			Collector: '',
			Account_Balance: '0',
			Type_of_Credit: '',
		});

		debtArray.push(this.newDebt);
	}

	// Remove emergency contacts
	removeDebts(i) {
		let listArray = this.applicationEntry.applicationForm.get(
			'Additional_Debt'
		) as UntypedFormArray;
		listArray.removeAt(i);
	}

	// Adding all the debt amounts to display on the total value
	addDebts() {
		this.calculateDebt();
	}

	// Function to calculate the total Additional Debt
	displayTotalDebt() {
		this.debttotal = this.applicationEntry.applicationForm
			.get('Additional_Debt')
			.value.map((item) => item.Account_Balance)
			.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
		//console.log(debttotal);
		this.applicationEntry.applicationForm.patchValue({
			debtTotal: this.formatMoney(this.debttotal.toFixed(2)),
		});
	}

	// Call the total additional debt function and display on click event
	calculateDebt() {
		//console.log(this.applicationForm.get('additionalDebts').value, "debt value-->")
		document.addEventListener('click', (e) => {
			this.displayTotalDebt();
		});
	}

	// Additional Debt Subform amount calculation

	/* SUB FORM of EMERGENCY CONTACTS */
	// get EMERGANCY_CONTACTS() {
	//   return this.applicationForm.get('EMERGANCY_CONTACTS') as FormArray;
	// }

	// addEmergency() {
	//   let emergencyArray = this.applicationForm.get('EMERGANCY_CONTACTS') as FormArray;
	//   let newEmergency = this.fb.group({
	//     'Name1': '',
	//     'Relation_to_Applicant': '',
	//     'Phone_Number': '',
	//     'Email': ''
	//   });

	//   emergencyArray.push(newEmergency);
	// }

	// To fetch and populate the records that are present into the subform
	populateEmergency() {
		//console.log(this.getparamid)
		this.apiService.getEmergencyContacts(this.getparamid).subscribe((res) => {
			console.log(res.data);
			let existingEmergency = this.applicationEntry.applicationForm.get(
				'EMERGANCY_CONTACTS'
			) as UntypedFormArray;
			for (let j = 0; j < res.data.length; j++) {
				existingEmergency.push(
					this.fb.group({
						Name1: res.data[j].name,
						Relation_to_Applicant: res.data[j].relation,
						Phone_Number: res.data[j].emergency_phone,
						Email: res.data[j].emergency_email,
					})
				);
			}
			console.log(existingEmergency);
		});
	}

	// To fetch and populate the records that are present into the subform
	populateAdditional() {
		//console.log(this.getparamid)
		this.apiService.getAdditionalDebt(this.getparamid).subscribe((res) => {
			//console.log(res)
			let existingAdditional = this.applicationEntry.applicationForm.get(
				'Additional_Debt'
			) as UntypedFormArray;
			for (let j = 0; j < res.data.length; j++) {
				existingAdditional.push(
					this.fb.group({
						Agency: res.data[j].agency_ref_value,
						misc: res.data[j].creditor_ref_value,
						Creditor_Portfolio: res.data[j].credit_portfolio_ref_value,
						Account_Number: res.data[j].account_number,
						Collector: res.data[j].collector_ref_value,
						Account_Balance: res.data[j].account_balance,
						Type_of_Credit: res.data[j].credit_type_ref_value,
					})
				);
			}
			this.displayTotalDebt();
		});
	}

	// Remove emergency contacts
	removeEmergency(i) {
		let listArray = this.applicationEntry.applicationForm.get(
			'EMERGANCY_CONTACTS'
		) as UntypedFormArray;
		listArray.removeAt(i);
	}

	// First create the Zoho Workdrive Folder and call the folder ID
	userSubmit() {
		this.submitted = true;
		if (this.applicationEntry.applicationForm.invalid) {
			alert('Please enter the required fields!');
			return;
		} else {
			if (confirm('Are you sure, you want to submit a new application?')) {
				this.apiService
					.createFolder(this.applicationEntry.applicationForm.value)
					.subscribe((res) => {
						//console.log("SUCCESS")
						this.getFolderID();
						this._routerLink.navigate(['/', 'applications']);
					});
			}
		}
	}

	// New ZOHO Application
	async createZohoApplicant() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.createZohoApplicant(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res)
					if (res.message.statusCode == '201') {
						//console.log(res, 'res==> insert');
						//this.apiService.sendClickEvent(); // Call the API to refresh the Read component on Display
						console.log('SUCCESS: Application pushed to ZOHO CRM');
						resolve(true);
					} else {
						console.log('Error pushing Application on ZOHO!');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	//Push the data to Zoho
	async pushToZoho() {
		await this.getRecordID(); // On this it will perform API push to ZOHO and then get the recordID

		return new Promise((resolve, reject) => {
			let result = this.apiService
				.createClient(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res, "createClient")
					if (res.message == 'Success') {
						//console.log(res, 'res==> insert');
						console.log('SUCCESS: Client Application added on local database!');
						resolve(true);
						this.apiService.sendClickEvent(); // Call the API to refresh the Read component on Display
						//this._routerLink.navigate(['/', 'applications']);
					} else {
						console.log('Error adding Client Application on local database');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	async getRecordID() {
		await this.createZohoApplicant(); // Calling API push to local database can be performed after this: i.e. API push to Zoho
		return new Promise((resolve, reject) => {
			let result = this.apiService.getAllZOHOApplications().subscribe((res) => {
				//console.log(res, "res recordID")
				if (res.message.statusCode == '200') {
					let arr = JSON.parse(res.message.body);
					for (let i = 0; i < arr.data.length; i++) {
						if (
							arr.data[i].Local_id ===
							this.applicationEntry.applicationForm.get('uniqueID').value
						) {
							//console.log(arr.data[i],"Local_id==>"+ i)
							this.applicationEntry.applicationForm.patchValue({
								recordID: arr.data[i].id,
							});
						}
					}
					//console.log("SUCCESS: RecordID retrieved!")
					resolve(true);
				} else {
					console.log('Error retrieving RecordID');
					console.log(result);
					resolve(false);
				}
			});
		});
	}

	// In order to react on click event for check and uncheck
	checkValue(event: any) {
		this.previousEmployment();
	}

	// According to the checkbox checked and unchecked display and save value
	previousEmployment() {
		if (
			this.applicationEntry.applicationForm.get('isPreviousEmployment').value ==
			false
		) {
			this.applicationEntry.applicationForm
				.get('isPreviousEmployment')
				.setValue(0);
			document.getElementById('myPrevEmp').style.display = 'none';
			//console.log(this.applicationForm.get('isPreviousEmployment').value, "previousEmployment")
		} else {
			this.applicationEntry.applicationForm
				.get('isPreviousEmployment')
				.setValue(1);
			document.getElementById('myPrevEmp').style.display = 'block';
			//console.log(this.applicationForm.get('isPreviousEmployment').value, "previousEmployment")
		}
	}

	// Only Accept numbers
	numberOnly(e: any) {
		//console.log(e)
		if (this.specialKeys.indexOf(e.key) !== -1) {
			return;
		}
		const inputValue: string = e.key;
		if (!inputValue.match(this.regex)) {
			e.preventDefault();
		}
	}
	// Format the currency for all the amounts entered into Ex.: ($1,000,000.00)
	formatCurrency(e: any) {
		e.target.value = this.removeFormat(e.target.value); // Remove any formatting

		let newFormat = this.formatMoney(e.target.value);
		e.target.value = newFormat;
	}

	// Remove $ and comma sign
	removeFormat(str) {
		return str.replace(/[,\$]/g, '');
	}

	// Format Curreny with commas
	formatMoney(str: any) {
		const formatStr = new Intl.NumberFormat('en-CA', {
			style: 'currency',
			currency: 'CAD',
		}).format(str);
		return formatStr;
	}

	// Update the new applicant information
	async userUpdate() {
		this.submitted = true;
		if (this.applicationEntry.applicationForm.invalid) {
			alert('Please enter the required fields!');
			return;
		} else {
			if (
				confirm(
					'Are you sure, you want to update the client application selected?'
				)
			) {
				this.uploadLocal();
				this.updateZohoApplicant();
				this.apiService
					.updateZohoFolder(this.applicationEntry.applicationForm.value)
					.subscribe((res) => {
						this.updateClients();
						this._routerLink.navigate(['/', 'applications']);
					});
			}
		}
	}
	// Push the update on Zoho Workdrive
	async updateZohoApplicant() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.updateZohoDeals(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res,"ZOHO APPLICANT")
					if (res.message.statusCode == '200') {
						console.log('SUCCESS: ZOHO Application Update!');
						resolve(true);
					} else {
						console.log('Error updating ZOHO Application');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	// UPDATE Zoho Workdrive Folder Name
	async updateClients() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.updateClients(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res,"CLIENTS")
					if (res.message == 'Success') {
						console.log('SUCCESS: Client Update Local Database!');
						resolve(true);
						this.apiService.sendClickEvent(); // Step 1. Call the API to refresh the Read component on Display
						this._routerLink.navigate(['/', 'applications']); // Step 2. Then open the link to applications after refresh to display the updated records.
					} else {
						console.log('Error updating on Local Database!');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	// Display the residency for dropdown
	getResidencyDesc() {
		this.apiService.getResidencyDesc().subscribe((res) => {
			this.readResidencyDropdown = res.data;
		});
	}

	// Display the province for dropdown
	getProvinceDesc() {
		this.apiService.getProvinceDesc().subscribe((res) => {
			this.readProvinceDropdown = res.data;
		});
	}

	// Display the Marital Status for dropdown
	getMaritalStatus() {
		this.apiService.getMaritalStatus().subscribe((res) => {
			this.readMaritalStatusDropdown = res.data;
		});
	}

	// Display the Number of Dependents for dropdown
	getNumberDependents() {
		this.apiService.getNumberDependents().subscribe((res) => {
			this.readNumberDependentsDropdown = res.data;
		});
	}

	// Display the Number of Dependents for dropdown
	getBestContactTime() {
		this.apiService.getBestContactTime().subscribe((res) => {
			this.readBestContactTimeDropdown = res.data;
		});
	}

	// Display the Number of Dependents for dropdown
	getNoSinReason() {
		this.apiService.getNoSinReason().subscribe((res) => {
			this.readNoSinReasonDropdown = res.data;
		});
	}

	// Display the Payment Method for dropdown
	getPaymentMethodDesc() {
		this.apiService.getPaymentMethodDesc().subscribe((res) => {
			this.readPaymentMethodDropdown = res.data;
		});
	}
	// Display the Bankruptcy for dropdown
	getBankruptcyDesc() {
		this.apiService.getBankruptcyDesc().subscribe((res) => {
			this.readBankruptcyDropdown = res.data;
		});
	}

	// Display the residency for dropdown
	getEmploymentDesc() {
		this.apiService.getEmploymentDesc().subscribe((res) => {
			this.readEmploymentDropdown = res.data;
		});
	}

	// Display the creditor portfolio for dropdown additional debt
	getCreditorPortfolioDesc() {
		this.apiService.getCreditorPortfolioDesc().subscribe((res) => {
			this.readCreditPortfolioDropdown = res.data;
		});
	}

	// Display the creditor type for dropdown additional debt
	getCreditTypeDesc() {
		this.apiService.getCreditTypeDesc().subscribe((res) => {
			this.readCreditTypeDropdown = res.data;
		});
	}

	// Display the Sales Agent for dropdown Referral
	getSalesAgent() {
		this.apiService.getSalesAgent().subscribe((res) => {
			this.readSalesAgentDropdown = res.data;
		});
	}

	// Display the Broker for dropdown Referral
	getBroker() {
		this.apiService.getBroker().subscribe((res) => {
			this.readBrokerDropdown = res.data;
		});
	}

	// Display the Creditor for dropdown Referral
	getCreditor() {
		this.apiService.getCreditor().subscribe((res) => {
			this.readReferralCreditorDropdown = res.data;
		});
	}

	// Display the Referral Method for dropdown Referral
	getReferralMethod() {
		this.apiService.getReferralMethod().subscribe((res) => {
			this.readReferralMethodDropdown = res.data;
		});
	}

	// Display the Agency Location for dropdown Referral
	getAgencyLocation() {
		this.apiService.getAgencyLocation().subscribe((res) => {
			this.readAgencyLocationDropdown = res.data;
		});
	}

	// Display the Loan Type for dropdown Referral
	getLoanType() {
		this.apiService.getLoanType().subscribe((res) => {
			this.readLoanTypeDropdown = res.data;
		});
	}

	// Display the Agency Location for dropdown Referral
	getLeadSource() {
		this.apiService.getLeadSource().subscribe((res) => {
			this.readLeadSourceDropdown = res.data;
		});
	}

	// Display the Agency Location for dropdown Referral
	getPayFrequency() {
		this.apiService.getPayFrequency().subscribe((res) => {
			this.readPayFrequencyDropdown = res.data;
		});
	}

	// Display the Co-Applicant Residence Length for dropdown Referral
	getResidenceLength() {
		this.apiService.getResidenceLength().subscribe((res) => {
			this.readResidenceLengthDropdown = res.data;
		});
	}

	// Google API for auto location for Client
	loadAutoAddressing() {
		this.mapsAPILoader.load().then(() => {
			let autocomplete = new google.maps.places.Autocomplete(
				this.searchElement.nativeElement,
				{ types: ['address'] }
			);

			// Set country to CANADA only
			autocomplete.setComponentRestrictions({
				country: ['ca'],
			});

			autocomplete.addListener('place_changed', () => {
				//console.log(autocomplete, "Address=>");
				this.ngZone.run(() => {
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();
					//console.log(place.formatted_address);
					let address = place.formatted_address; // Formatted addressing example: 148 Robinson St #8, Hamilton, ON L8P 1Z5, Canada

					// If the formatted address has unit number separate the unit number from street name and display
					if (address.match('#')) {
						let unit = address.split('#')[1]; // This will display the addresses right after #
						this.applicationEntry.applicationForm.patchValue({
							address2: unit[0].trim(),
							address1: address.split(' #')[0].trim(), // This will split from one space and # i.e (' #')
						});
					}
					// Else if without unit number then display the street number
					else {
						this.applicationEntry.applicationForm.patchValue({
							address2: '',
							address1: address.split(',')[0].trim(),
						});
					}

					let postal = address.split(',')[2];
					//console.log((postal.slice(1,3)).length);
					// Display the rest of the addresses
					this.applicationEntry.applicationForm.patchValue({
						city: address.split(',')[1].trim(),
						province_code: postal.slice(0, 3).trim(),
						postal_code: postal.slice(4, postal.length).trim(),
					});

					//let field: google.maps.places.PlaceResult = autocomplete.getBounds();
					//console.log(place, "Place=>");
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}
				});
			});
		});
	}

	// Google API for auto location fo Co-Applicant
	loadCoAppAutoAddressing() {
		this.mapsAPILoader.load().then(() => {
			//console.log(this.searchElementAddress)
			let autocomplete = new google.maps.places.Autocomplete(
				this.searchElementAddress.nativeElement,
				{ types: ['address'] }
			);

			// Set country to CANADA only
			autocomplete.setComponentRestrictions({
				country: ['ca'],
			});

			autocomplete.addListener('place_changed', () => {
				//console.log(autocomplete, "Address=>");
				this.ngZone.run(() => {
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();
					//console.log(place);
					let address = place.formatted_address; // Formatted addressing example: 148 Robinson St #8, Hamilton, ON L8P 1Z5, Canada

					// If the formatted address has unit number separate the unit number from street name and display
					if (address.match('#')) {
						let unit = address.split('#')[1]; // This will display the addresses right after #
						this.applicationEntry.applicationForm.patchValue({
							co_app_address2: unit[0].trim(),
							co_app_address1: address.split(' #')[0].trim(), // This will split from one space and # i.e (' #')
						});
					}
					// Else if without unit number then display the street number
					else {
						this.applicationEntry.applicationForm.patchValue({
							co_app_address2: '',
							co_app_address1: address.split(',')[0].trim(),
						});
					}

					let postal = address.split(',')[2];

					this.applicationEntry.applicationForm.patchValue({
						co_app_city: address.split(',')[1].trim(),
						co_app_province: postal.slice(0, 3).trim(),
						co_app_postal: postal.slice(4, postal.length).trim(),
						co_app_country: address.split(',')[3].trim(),
					});

					if (place.geometry === undefined || place.geometry === null) {
						return;
					}
				});
			});
		});
	}

	// Unique ID generate for Application
	generateUniqueID() {
		let chars =
			'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
		//let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		let uniqueLength = 24;

		for (let i = 0; i <= uniqueLength; i++) {
			let randNumber = Math.floor(Math.random() * chars.length);
			this.uniqueIDgenerated += chars.substring(randNumber, randNumber + 1);
		}
	}

	// Get Agency Records for Additional Subform
	getAgencyRecords() {
		this.apiService.getAgencyRecords().subscribe((res) => {
			//console.log(res, "res==>reload");
			let arr;
			arr = JSON.parse(res.message.body);

			this.readAgencyRecords = arr.data;
			//console.log(this.readAgencyRecords)
		});
	}

	// Get Collector Records for Emergency Contacts Subform
	getCollectorRecords() {
		this.apiService.getCollectorRecords().subscribe((res) => {
			let arr;
			arr = JSON.parse(res.message.body);
			this.readCollectorRecords = arr.data;
		});
	}

	/*
	WORKFLOW:
	1. First create the folder with unique ID
	2. Retrieve the folder id from the step 1
	3. Upload the file if there is one present
	  (a) upload file locally with custom name + unique ID
	  (b) Use the absolute path to upload the file to ZOHO. Get the Workdrive folderID and folderURl
	4. Push the application to ZOHO
	5. Retrieve the Record ID of the application pushed on step 4.
	6. Insert the application details to local database with the ZOHO Workdrive folderID, folderURl and Record ID (Step4)
  */

	// CreateFolder on API call
	// createFolder(){
	//   this.apiService.createFolder(this.applicationForm.value).subscribe((res) => {
	//     //console.log("SUCCESS")
	//     this.getFolderID()
	//   });
	// }

	// Get the Folder ID created above on API call createFolder()
	getFolderID() {
		this.apiService.getFolderID().subscribe((res) => {
			let arr;
			arr = JSON.parse(res.message.body);
			this.listFolders = arr.data;
			//console.log(this.listFolders,"Folder List")
			//this.folderName = this.applicationForm.get('firstname').value + ' ' + this.applicationForm.get('middlename').value + ' ' + this.applicationForm.get('lastname').value + ' - ' + this.applicationForm.get('uniqueID').value
			this.folderName =
				this.applicationEntry.applicationForm.get('Deal_Name').value +
				' - ' +
				this.applicationEntry.applicationForm.get('uniqueID').value;

			for (let i = 0; i < this.listFolders.length; i++) {
				// If the folder name exist
				if (this.listFolders[i].attributes.name == this.folderName) {
					this.applicationEntry.applicationForm.patchValue({
						folderID: this.listFolders[i].id,
						folderURL: this.listFolders[i].attributes.permalink,
					});
					this.uploadLocal(); // Upload the file attach under the folder created
					this.apiService.sendClickEvent();
					this.pushToZoho(); // The push to zoho application has to be here in order to capture the folder ID and folder URL
				}
			}
		});
	}

	// Document Upload
	private fileList: Array<string> = []; // This will store the files uploaded
	private fileNameList: Array<string> = []; // This will store the filename and the unique number
	private fileNameExt: Array<string> = []; // This will store the file extensions

	// File size calculation
	fileSize(filesize: any) {
		return Math.round(filesize / 1000);
	}
	// Capture the consent file on event file upload
	consentFile: any;
	uploadConsent(event: Event) {
		// const ext = ["doc","docx","xls","xlsx","pdf","html","htm","ppt","pptx","jpg","jpeg","gif","png","tif","tiff","bmp","eps"]

		// for(let i=0; i<ext.length;i++){
		//   let uploadedExt = (((event[0].type).split('/')))[1]
		//   if(ext[i]==uploadedExt)  {
		//     this.consentInput.nativeElement.value=null
		//       alert("File not supported: " + uploadedExt)
		//   }
		// }
		//console.log((((event[0].type).split('/')))[1])
		try {
			if (event[0].length != 0) {
				this.consentFile = event;
				if (this.fileSize(this.consentFile[0].size) > 250000) {
					this.consentInput.nativeElement.value = null;
					alert('File size cannot exceed ');
					document.getElementById('consent').innerText = '';
				} else {
					document.getElementById('consent').innerText =
						this.fileSize(this.consentFile[0].size) + 'kb';
				}
			}
		} catch (e) {
			document.getElementById('consent').innerText = '';
		}
	}

	// Capture the govtID file on event file upload
	govtID: any;
	uploadGovt(event: Event) {
		try {
			if (event[0].length != 0) {
				this.govtID = event;
				document.getElementById('govtid').innerText =
					this.fileSize(this.govtID[0].size) + 'kb';
			}
		} catch (e) {
			document.getElementById('govtid').innerText = '';
		}
	}

	// Capture the bank statement file on event file upload
	bankFile: any;
	uploadBank(event: Event) {
		try {
			if (event[0].length != 0) {
				this.bankFile = event;
				document.getElementById('bank').innerText =
					this.fileSize(this.bankFile[0].size) + 'kb';
			}
		} catch (e) {
			document.getElementById('bank').innerText = '';
		}
	}

	// Capture the credit bureau file on event file upload
	bureauFile: any;
	uploadBureau(event: Event) {
		try {
			if (event[0].length != 0) {
				this.bureauFile = event;
				document.getElementById('bureau').innerText =
					this.fileSize(this.bureauFile[0].size) + 'kb';
			}
		} catch (e) {
			document.getElementById('bureau').innerText = '';
		}
	}

	// Upload the files locally first.
	// Adding to fileList and fileNameList together to maintain the sequence of the files added.
	// Done in order to match the indexing of files related in the both the arrays
	// Example: [567consent,got12id][clientconsentfcye%$%hndsf---,govtiddsfh734&#%---]
	// --- using this deliminator to differentiate for comparison on backend api call for file upload

	selectedFile: any;
	async uploadLocal() {
		// Do only if there is consent file
		try {
			if (this.consentFile[0].length != 0) {
				this.fileList.push(this.consentFile); // Add the consent file to the fileList array pool
				this.fileNameList.push(
					'clientconsent' +
					this.applicationEntry.applicationForm.get('uniqueID').value +
					'---'
				); // Add the file name to the fileNameList array pool
			}
		} catch (e) { }
		// Do only if there is Bank file
		try {
			if (this.govtID[0].length != 0) {
				this.fileList.push(this.govtID);
				this.fileNameList.push(
					'govtid' +
					this.applicationEntry.applicationForm.get('uniqueID').value +
					'---'
				);
			}
		} catch (e) { }

		try {
			if (this.bankFile[0].length != 0) {
				this.fileList.push(this.bankFile); // Add the consent file to the fileList array pool
				this.fileNameList.push(
					'bankstatement' +
					this.applicationEntry.applicationForm.get('uniqueID').value +
					'---'
				); // Add the file name to the fileNameList array pool
			}
		} catch (e) { }
		// Do only if there is Credit Bureau file
		try {
			if (this.bureauFile[0].length != 0) {
				this.fileList.push(this.bureauFile);
				this.fileNameList.push(
					'creditbureau' +
					this.applicationEntry.applicationForm.get('uniqueID').value +
					'---'
				);
			}
		} catch (e) { }

		// Here it will append all the files to the formdata.
		// During the process file type(ext) is retrieved in order to concat with the custom file name
		// Because if no ext is provided file is not readable
		const formdata = new FormData();
		for (let i = 0; i < this.fileList.length; i++) {
			this.selectedFile = this.fileList[i];
			//console.log(this.selectedFile[0].name)
			let fileType = this.selectedFile[0].type.split('/'); // Retrieve the file type
			formdata.append(
				'files',
				this.selectedFile[0],
				this.fileNameList[i] + '.' + fileType[1]
			); // (files, filename, ext) files is used on multer backend as identifier
			this.fileNameExt.push(this.fileNameList[i] + '.' + fileType[1]); // Adding all the filename with ext to the array to send it as response
		}

		// Assign the fileNameExt array to the formControl. It's to store the file extensions
		//console.log(this.fileList)
		//console.log(this.fileNameList)
		//console.log(this.fileNameExt)
		this.applicationEntry.applicationForm.patchValue({
			documentsUploaded: this.fileNameExt,
		});
		//console.log(formdata)

		// API call to upload locally on server.
		// We are doing this as API call for file upload on Workdrive needs an absolute path
		// Call the respective file upload API only if there is file present
		return new Promise((resolve, reject) => {
			let result = this.apiService.uploadLocal(formdata).subscribe((res) => {
				//console.log(res, "localStorage")
				if (res.length != '0') {
					try {
						if (this.consentFile[0].length != 0) this.uploadFileDrive();
					} catch (e) {
						console.log('Client consent document not submitted!');
					}

					try {
						if (this.govtID[0].length != 0) this.uploadFileDriveGovtID();
					} catch (e) {
						console.log('Government identification document not submitted!');
					}

					try {
						if (this.bankFile[0].length != 0) this.uploadFileDriveBank();
					} catch (e) {
						console.log('Bank statement document not submitted!');
					}

					try {
						if (this.bureauFile[0].length != 0) this.uploadFileDriveBureau();
					} catch (e) {
						console.log('Credit bureau document not submitted!');
					}

					console.log('SUCCESS: Files uploaded on local server!');
					resolve(true);
				} else {
					console.log('Error uploading files on local server');
					console.log(result);
					resolve(false);
				}
			});
			this.apiService.sendClickEvent();
		});
	}

	// Push to Zoho Workdrive
	async uploadFileDrive() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.uploadFileDrive(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res)
					if (res.message.statusCode == '200') {
						console.log('SUCCESS: ZOHO Client Consent File upload!');
						resolve(true);
					} else {
						console.log('Error uploading ZOHO Client Consent');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	// Push to Zoho Workdrive GovtID
	async uploadFileDriveGovtID() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.uploadFileDriveGovtID(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res)
					if (res.message.statusCode == '200') {
						console.log('SUCCESS: ZOHO Government Identification File upload!');
						resolve(true);
						this.applicationEntry.applicationForm.patchValue({
							hiddenGovtid: 'YES',
						});
					} else {
						console.log('Error uploading ZOHO Government Identification');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	// Push to Zoho Workdrive GovtID
	async uploadFileDriveBank() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.uploadFileDriveBank(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res)
					if (res.message.statusCode == '200') {
						console.log('SUCCESS: ZOHO Bank Statement File upload!');
						resolve(true);
						this.applicationEntry.applicationForm.patchValue({
							hiddenBank: 'YES',
						});
					} else {
						console.log('Error uploading ZOHO Bank Statement');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	// Push to Zoho Workdrive GovtID
	async uploadFileDriveBureau() {
		return new Promise((resolve, reject) => {
			let result = this.apiService
				.uploadFileDriveBureau(this.applicationEntry.applicationForm.value)
				.subscribe((res) => {
					//console.log(res)
					if (res.message.statusCode == '200') {
						console.log('SUCCESS: ZOHO Credit Bureau File upload!');
						resolve(true);
						this.applicationEntry.applicationForm.patchValue({
							hiddenBureau: 'YES',
						});
					} else {
						console.log('Error uploading ZOHO Credit Bureau Document');
						console.log(result);
						resolve(false);
					}
				});
		});
	}

	// GET Stage API call to poplulate on dropdown on Create Page
	getStage() {
		this.apiService.getStage().subscribe((res) => {
			let arr;
			arr = JSON.parse(res.message.body);
			//console.log(arr)
			this.readStageRecords = arr.fields[6].pick_list_values; // Index 6 is where the Stage field is
			// console.log(arr,"arr")
			// for(let i=0; i<this.readStageRecords.length; i++){
			//     console.log(this.readStageRecords[i])
			// }
		});
	}

	// This is for single file upload
	// Get the file details
	// selectedFile: any;
	// uploadLocally(event: Event){
	//   this.selectedFile = event;
	// }

	// // Upload locally
	// uploadLocal(){
	//   const formdata = new FormData();
	//   formdata.append('file', this.selectedFile[0], this.applicationForm.get('uniqueID').value)
	//   // console.log(formdata)
	//   // console.log(this.applicationForm.get('uniqueID').value)
	//   this.apiService.uploadLocal(formdata).subscribe(res =>{

	//   });
	//   this.uploadFileDrive(); // Push to Zoho after the file is uploadded successfully locally to get the file name and path
	// }

	// // Push to Zoho Workdrive
	// uploadFileDrive() {
	//   this.apiService.uploadFileDrive(this.applicationForm.value).subscribe(response => {
	//   });
	// }

	getFiles() {
		this.apiService.checkFiles().subscribe((res) => {
			let file1 = 'lion.jpg';
			let file2 = 'angular.pdf';
			let arr = JSON.parse(res.message.body);
			for (let i = 0; i < arr.data.length; i++) {
				// Upload Consent Form on the Workdrive Folder
				if (this.consentFile[0].length != 0) {
					if (arr.data[i].attributes.name != this.consentFile[0].name) {
						this.uploadFileDrive();
					}
				}
				// Push to Zoho, Bureau ID File
				//console.log(arr.data[i].attributes.name)
				if (file2 != '') {
					if (arr.data[i].attributes.name != file2) {
						console.log('LION');
					}
				}
				if (file2 != '') {
					if (arr.data[i].attributes.name == file2) {
						console.log('Angular Exist');
					}
				}
			}
			//console.log(arr)
		});
	}

	/**
	 * VALIDATIONS
	 */
	get applicationName() {
		return this.applicationEntry.applicationForm.get('Deal_Name');
	}

	get alternativename() {
		return this.applicationEntry.applicationForm.get('alternative_name');
	}

	// Validate SIN only numbers and length 9
	sinValidate(e: any) {
		let x = e.target.value
			.replace(/\D/g, '')
			.match(/(\d{0,3})(\d{0,3})(\d{0,3})/);
		e.target.value = !x[2] ? x[1] : x[1] + x[2] + (x[3] ? x[3] : '');
	}

	// Phone Number Input Mask
	formatPhoneNumber(event: any) {
		let x = event.target.value
			.replace(/\D/g, '')
			.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		event.target.value = !x[2]
			? x[1]
			: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	}

	// get salesagent(){
	//   return this.applicationForm.get('sales_agent');
	// }

	// get stage(){
	//   return this.applicationForm.get('stage');
	// }
}
