import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-balloon-payments',
  templateUrl: './balloon-payments.component.html',
  styleUrls: ['./balloon-payments.component.scss']
})
export class BalloonPaymentsComponent implements OnDestroy {
  baloonDataSubscription: Subscription;
  Balloon_Report: any;
  start_date: any;
  end_date: any;
  btnSpinner = false;
  btnShowText = true;

  //_Hist
  getRowStyle_Hist: any
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public gridOptions_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;


  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    //Payment History
    this.columnDefs_Hist = [
      { headerName: 'Loan Number', field: 'Loan Number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Date Due', field: 'Date Due', sortable: true, filter: 'agDateColumnFilter' },
      { headerName: 'Pmt#', field: 'Pmt#', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Type', field: 'Type', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Transcode', field: 'Transcode', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Late Fee Code', field: 'Late Fee Code', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Amount', field: 'Amount', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Amount Paid', field: 'Amount Paid', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Balance', field: 'Balance', sortable: true, filter: 'agNumberColumnFilter' },
    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'baloon_payments_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'baloon_payments_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.Balloon_Report);
    this.rowData_Hist = this.Balloon_Report

  }

  startDate(e) {
    this.start_date = e;
  }

  endDate(e) {
    this.end_date = e;
  }

  downloadBalloon() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else {
      this.btnSpinner = true;
      this.btnShowText = false;
      this.Balloon_Report = null;
      this.rowData_Hist = [];
      this.baloonDataSubscription = this.apiService.getBalloonPayments(this.start_date, this.end_date).subscribe((res) => {
        console.log(res);
        this.Balloon_Report = res;
        this.gridApi_Hist.setRowData(this.Balloon_Report);
        this.rowData_Hist = this.Balloon_Report;
        this.btnSpinner = false;
        this.btnShowText = true;
      })
    }
  }

  ngOnDestroy() {
    this.baloonDataSubscription?.unsubscribe();
  }

}
