<app-custom-betty-report-filters [showReportDropDown]="true" (reportType)="getReportType($event)"
    (customLoanGroups)="getCustomLoanGroups($event)" [showLoanStatus]="true" (loanStatus)="selectedLoanStatus($event)"
    [showBreakdowns]="false" [showCurrentDate]="true" [showDateRange]="false" (current_date)="currentDate($event)"
    [btnColor]="'primary'" [btnShowText]="btnShowText" [btnText]="'Submit'" [btnSpinner]="btnSpinner"
    (onBtnClick)="onSubmit()"></app-custom-betty-report-filters>

<div class="row" style="height: calc(100% - 175px); margin: 10px;">
    <app-custom-table class="ag-theme-material" [rowData]="rowData" [columnDefs]="columnDefs"
        [defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"
        [animateRows]="true" [hideGlobalFilter]="true" [pagination]="true" [paginationPageSize]="100"
        [hideGlobalFilter]="true" (gridReady)="onGridReady($event)">
    </app-custom-table>
</div>