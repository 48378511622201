<div class="container-fluid ptp-wrapper">
    <div class="overlayDiv">
        <span>
            <button mat-stroked-button [color]="allSelected" (click)="resetFilters()">ALL</button>
            <button style="margin-left: 10px;" [color]="currentSelected" mat-stroked-button
                (click)="currentMonth()">Current Month</button>
            <button style="margin-left: 10px;" [color]="nextSelected" mat-stroked-button (click)="nextMonth()">Next
                Month</button>
            <button *ngIf="adminView" style="margin-left: 10px;" mat-stroked-button (click)="applyFilter()"
                [color]="calculateFilterSelected">Calculate
                Total</button>
        </span>
    </div>
    <div class="row" style="height: calc(100% - 40px);">
        <app-custom-table class="ag-theme-material" class="collection-wrapper" [rowData]="rowData_Collector_PTP"
            [showLoader]="showLoader" [columnDefs]="columnDefs_Collection" [hideGlobalFilter]="true"
            [animateRows]="true" [pagination]="false" [paginationPageSize]="10000" [rowClassRules]="rowClassRules"
            (gridReady)="onGridReady($event)">
        </app-custom-table>
    </div>
    <div style="display: flex; gap: 1em;">
        <h5><strong>{{monthIndicator}}:</strong></h5>
        <h5><strong>PTP: </strong>{{countPTP}} ({{totalPTP | currency}})</h5> |
        <h5><strong>Processed: </strong>{{countProcessed}} ({{totalProcessed | currency}})</h5> |
        <h5><strong>Arranged: </strong>{{countArranged}} ({{totalArranged | currency}})</h5> |
        <h5><strong>Requested: </strong>{{countRequested}} ({{totalRequested | currency}})</h5> |
        <h5><strong>NSF: </strong>{{countNSF}} ({{totalNSF | currency}})</h5> |
        <h5><strong>Broken: </strong>{{countBroken}} ({{totalBroken | currency}})</h5>
    </div>
</div>