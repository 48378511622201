import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { CellClickedEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { dateFilterAgGrid } from '@lmc-app/utils/filter-functions';

@Component({
  selector: 'app-nsf-collection-list',
  templateUrl: './nsf-collection-list.component.html',
  styleUrls: ['./nsf-collection-list.component.scss']
})
export class NsfCollectionListComponent implements OnInit, AfterViewInit, OnDestroy {
  nsfListDataSubscription: Subscription;
  collectorIDdataSubecription: Subscription;

  viewCollectorNSF: any;
  nsfCollectionList: any;
  sessionInfo: any;
  adminView = false; // Component permission flag
  collectorIDs: any;
  nsfTotalCount: any;


  current_date = new Date();
  past_date = new Date();

  defaultColDef_nsf: any;
  public columnDefs_nsf = [];
  public rowData_nsf;
  public gridApi_nsf;
  public gridColumnApi_nsf;

  showLoader: boolean;
  showEmptyResults: boolean;

  constructor(private apiService: ApiServiceService, private router: Router) {
    this.columnDefs_nsf = [
      {
        headerName: 'Loan Number', field: 'loan_number', filter: "agTextColumnFilter",
        onCellClicked: (event: CellClickedEvent) => this.openLoanMetaNSF(event?.data?.loan_number),
        cellStyle: { cursor: 'pointer', color: '#116abd' },
      },
      { headerName: 'Name', field: 'name', filter: "agTextColumnFilter", },
      { headerName: 'Collector', field: 'employee_name', filter: "agTextColumnFilter", },
      { headerName: 'Days past due', field: 'days_past_due', filter: 'agNumberColumnFilter', },
      {
        headerName: 'PTP Date', field: 'ptp_date', filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterDate, cellValue) => dateFilterAgGrid(filterDate, cellValue),
          browserDatePickerDateFormat: 'yyyy-mm-dd',
        }
      }
    ];
    this.defaultColDef_nsf = {
      resizable: true
    };
  }

  ngOnInit(): void {
    this.past_date.setDate(this.past_date.getDate() - 14);  // 14 days

    if (localStorage.getItem('SESSIONID')) {
      this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe(async (res: any) => {
        if (res.message === 'Success') {
          this.sessionInfo = res?.['data']?.[0];
          this.adminView = this.sessionInfo?.['collection_list'] === null ? false : this.sessionInfo?.['collection_list'];  // Defining Admin from the permission_component table
        }
      });

      this.collectorIDdataSubecription = this.apiService.getCollectorNames().subscribe((res) => {
        if (res['status']) {
          this.collectorIDs = Object.values(res?.['data']).map(item => item['employeeID']);
        }
      });
    }
  }
  ngAfterViewInit(): void {
    this.nsfListDataSubscription = this.apiService.getNsfCollectionList().subscribe((res) => {
      this.showLoader = true;
      if (this.sessionInfo?.collection_list) {
        this.nsfCollectionList = '';
        this.nsfCollectionList = res;
        this.nsfTotalCount = this.nsfCollectionList?.length;
        this.gridApi_nsf?.setRowData(this.nsfCollectionList);
        this.hideCollectorName();
        console.log(this.nsfCollectionList)
        this.rowData_nsf = this.nsfCollectionList;
        this.showLoader = false;
        this.showEmptyResults = false;
      } else {
        this.nsfCollectionList = '';
        this.nsfCollectionList = Object.values(res).filter(item => item?.employeeID === this.sessionInfo?.employeeID);
        this.nsfTotalCount = this.nsfCollectionList?.length;
        this.gridApi_nsf?.setRowData(this.nsfCollectionList);
        this.hideCollectorName();
        console.log(this.nsfCollectionList)
        this.rowData_nsf = this.nsfCollectionList;
        this.showLoader = false;
        this.showEmptyResults = false;
      }
    });
  }

  resetFilters() {
    this.gridApi_nsf!.setFilterModel(null);
  }

  hideCollectorName() {
    return this.adminView ? this.gridColumnApi_nsf?.setColumnsVisible(['employee_name'], true) : this.gridColumnApi_nsf?.setColumnsVisible(['employee_name'], false);;
  }

  //NSF
  openLoanMetaNSF(loan_number: number) {
    this.apiService.searchNLSLoan(loan_number).subscribe((res: any[]) => {
      let collection_queue = [];
      if (res.length == 0) {
        alert('Loan Number cannot be found on NLS');
      } else {
        this.gridApi_nsf.forEachNodeAfterFilter((rowNode, index) => {
          collection_queue.push(rowNode?.data?.loan_number)
        });
        console.log(collection_queue, "*********")
        // let collection_queue = this.nsfCollectionList.map((obj) => {
        //   return obj.loan_number;
        // });
        res['isQueue'] = true;
        res['queuedLoans'] = collection_queue;
        this.apiService.sendMessage(res);
        this.router.navigate(['loan-meta']);
      }
    });
  }

  onGridReady(params) {
    this.gridApi_nsf = params.api;
    this.gridColumnApi_nsf = params.columnApi;
    this.gridApi_nsf?.setRowData(this.nsfCollectionList);
    this.hideCollectorName();
    this.rowData_nsf = this.nsfCollectionList
  }

  ngOnDestroy(): void {
    this.collectorIDdataSubecription?.unsubscribe();
    this.nsfListDataSubscription?.unsubscribe();
    console.log("Dashboard list unsubscribed!")
  }

}
