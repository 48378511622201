import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { groupBy } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ETIMEDOUT } from 'constants';

@Component({
	selector: 'app-intermedia-call-reports',
	templateUrl: './intermedia-call-reports.component.html',
	styleUrls: ['./intermedia-call-reports.component.scss'],
})
export class IntermediaCallReportsComponent implements OnInit {
	public EmployeesList: any;
	public EmployeesCallData: any;
	public employeeType: any;
	public noAverage: boolean;
	public colspan: number;
	public notesLeft: any;
	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute
	) {}

	async ngOnInit() {
		this.activatedRoute?.queryParams?.subscribe((queryParams) => {
			this.employeeType = queryParams?.['employeeType'];
			this.noAverage = queryParams?.['noAverage'];
			this.colspan = this.noAverage ? 2 : 3;
		});
		this.EmployeesList = await this.getEmployeesForUniteCalls(this.employeeType);
		this.notesLeft = await this.getFilesTouched(this.employeeType);
		this.apiService.getUniteAuth().subscribe((res: any) => {
			const token = res.access_token;
			this.apiService.getUniteCalls(token).subscribe((res: any) => {
				const calls = res.calls;
				calls.forEach((call, i, arr) => {
					const name =
						call.direction === 'inbound' ? call.to.name : call.from.name;
					return (arr[i] = { ...call, ...{ name } });
				});
				this.EmployeesCallData = groupBy(
					calls.filter((t) => this.EmployeesList.includes(t.name)),
					'name'
				);
				Object.keys(this.EmployeesCallData).forEach((key, index) => {
					const inboundCalls = this.EmployeesCallData[key].filter(
						(call) => call.direction === 'inbound'
					);
					const outboundCalls = this.EmployeesCallData[key].filter(
						(call) => call.direction === 'outbound'
					);
					var filesTouched : number;
					this.notesLeft.forEach((item) => {
						if (item["created_by"] == key) {
							filesTouched = item["Notes Left"];
						}
					});
					const inboundTotalCallTime = inboundCalls
						.map((callList) => callList.duration)
						.reduce((acc, val) => acc + val, 0);
					const inboundTotalCallTimeFormatted =
						this.secondsToTime(inboundTotalCallTime);
					const inboundAvgCallTime =
						inboundTotalCallTime / inboundCalls.length || 0;
					const inboundAvgCallTimeFormatted =
						this.secondsToTime(inboundAvgCallTime);
					const inboundTotalCalls = inboundCalls.length;
					const outboundTotalCallTime = outboundCalls
						.map((callList) => callList.duration)
						.reduce((acc, val) => acc + val, 0);
					const outboundTotalCallTimeFormatted = this.secondsToTime(
						outboundTotalCallTime
					);
					const outboundAvgCallTime =
						outboundTotalCallTime / outboundCalls.length || 0;
					const outboundAvgCallTimeFormatted =
						this.secondsToTime(outboundAvgCallTime);
					const outboundTotalCalls = outboundCalls.length;
					const totalCalls = outboundTotalCalls + inboundTotalCalls;
					const totalCallTime = outboundTotalCallTime + inboundTotalCallTime;
					const totalCallTimeFormatted = this.secondsToTime(totalCallTime);
					this.EmployeesCallData[key] = {
						inboundTotalCalls,
						inboundAvgCallTimeFormatted,
						inboundTotalCallTimeFormatted,
						outboundTotalCalls,
						outboundAvgCallTimeFormatted,
						outboundTotalCallTimeFormatted,
						totalCalls,
						totalCallTimeFormatted,
						filesTouched
					};
					console.log(this.EmployeesCallData[key]);
				});
				});
			});
	}

	secondsToTime(seconds: number) {
		let dateObj = new Date(seconds * 1000);
		let hours = dateObj.getUTCHours();
		let minutes = dateObj.getUTCMinutes();
		seconds = dateObj.getSeconds();

		return (
			hours.toString().padStart(2, '0') +
			':' +
			minutes.toString().padStart(2, '0') +
			':' +
			seconds.toString().padStart(2, '0')
		);
	}
	getEmployeesForUniteCalls(et) {
		return new Promise((resolve,reject) => {
			this.apiService.getEmployeesForUniteCalls(et).subscribe((res: any) => {
				resolve(res?.data?.map((emp) => emp.full_name));
			});
		})
	}
	getFilesTouched(et) {
		return new Promise((resolve,reject) => {
			this.apiService.getFilesTouched(et).subscribe((res: any) => {
				resolve(res.data)
			});
		});
	}
}
