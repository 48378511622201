import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Component({
	selector: 'app-underwriting-timerstart',
	templateUrl: './underwriting-timerstart.component.html',
	styleUrls: ['./underwriting-timerstart.component.scss'],
})
export class UnderwritingTimerstartComponent implements OnInit {
	//Timer
	counter = 0;
	timerRef;
	running: boolean = false;
	display_time = '';
	timeInfo;
	constructor(private apiService: ApiServiceService) {}

	ngOnInit(): void {}
	editToStartTimer() {
		this.timeInfo = {
			applicationID: '2390707000034288237',
			applicantType: 'Primary',
			duration: 0,
			underwriter: 'Travis Poulose',
			start_date: new Date(),
			end_date: '',
			action: 'Loan Interview',
		};
		this.running = !this.running;
		if (this.running) {
			const startTime = Date.now() - (this.counter || 0);
			this.timerRef = setInterval(() => {
				this.counter = Date.now() - startTime;
				this.display_time = this.editTime(this.counter);
			});
		} else {
			clearInterval(this.timerRef);
		}
	}
	editTime(milliseconds) {
		return new Date(milliseconds).toISOString().slice(11, 19);
	}

	clearTimer() {
		this.timeInfo.end_date = new Date();
		this.timeInfo.duration = this.counter;
		this.counter = 0;
		//Enter the time info in the table
		this.apiService
			.recordLoanInterviewDuration(this.timeInfo)
			.subscribe((res) => {
				console.log('Insert Timing Info into Durationg Table');
				console.log(res);
			});
		this.apiService
			.updateLoanInterviewComplete(
				this.timeInfo.applicationID,
				this.timeInfo.applicantType
			)
			.subscribe((res) => {
				console.log('Update Loan Interview Complete');
				console.log(res);
			});
		this.running = false;
		clearInterval(this.timerRef);
	}
}
