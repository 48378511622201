import { CustomFunctionService } from '../custom-function.service';

export class LookUps {
	Employment_Status: any;
	Residency_Status: any;
	Province1: any;
	Payment_Method: any;
	Bankruptcy_CC_CP: any;
	Sales_Agent: any;
	Creditt: any;
	Referral_type: any;
	Broker: any;
	Agency_Location: any;
	Creditor_Portfolio: any;
	Loan_Type: any;
	Lead_Source: any;
	Type_of_Credit = [
		{ display_value: 'Credit Card' },
		{ display_value: 'Personal Loan' },
		{ display_value: 'Payday Loan' },
		{ display_value: 'Automobile Finance' },
		{ display_value: 'Line of Credit' },
		{ display_value: 'Other' },
	];
	pay_frequency = [
		{ display_value: 'Bi-weekly' },
		{ display_value: 'Weekly' },
		{ display_value: 'Monthly' },
		{ display_value: 'Semi monthly' },
		{ display_value: '15th and EOM' },
	];
	general = [
		{ actual_value: '', display_value: '-None-' },
		{ actual_value: 'Yes', display_value: 'Yes' },
		{ actual_value: 'No', display_value: 'No' },
	];
	gender = [
		{ actual_value: '', display_value: '-None-' },
		{ actual_value: 'Male', display_value: 'Male' },
		{ actual_value: 'Female', display_value: 'Female' },
	];
	Marital_Status: any;
	Number_of_Dependents: any;
	Best_contact_time: any;
	No_SIN_Reason: any;
	country = [
		{ actual_value: '', display_value: '-None-' },
		{ actual_value: 'Canada', display_value: 'Canada' },
		{ actual_value: 'US', display_value: 'US' },
	];
	Industry: any;
	Employment_Status1: any;
	Occupation: any;
	Pay_Frequency2: any;
	Pay_Frequency_Household_Income: any;
	Previous_Employment_Type: any;
	Previous_Payment_Method: any;
	Co_Applicant_Length_of_Residence: any;
	residential_status = [
		{ actual_value: '', display_value: '-None-' },
		{ actual_value: 'Rent', display_value: 'Rent' },
		{ actual_value: 'Own', display_value: 'Own' },
	];
	Co_Applicant_Status: any;
	Co_Applicant_Industry: any;
	Co_Applicant_Occupation: any;
	Stage: any;
	Users: any; // ZOHO users
	Opportunity_Status = [
		{ actual_value: '', display_value: '-None-' },
		{ actual_value: 'Monthly', display_value: 'Monthly' },
		{ actual_value: 'Semi-Monthly', display_value: 'Semi-Monthly' },
		{ actual_value: 'Bi-Weekly', display_value: 'Bi-Weekly' },
		{ actual_value: 'Weekly', display_value: 'Weekly' },
		{ actual_value: '15th and EOM', display_value: '15th and EOM' },
		{ actual_value: '1st and 15th', display_value: '1st and 15th' },
	];
	LPP_Included: any;
	LPP_Type: any;
	LPP_Sold_by: any;

	constructor(private customFunction: CustomFunctionService) {}

	getLookUp(arr: any, apiName: any) {
		return this.customFunction.singleFilterName(
			arr.fields,
			'api_name',
			apiName
		)[0]['pick_list_values'];
	}
}
