import {
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomFunctionService } from '../custom-function.service';
import { MapsAPILoader } from '@agm/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { SummaryDataService } from '../services/summary/summary-data.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-summary',
	templateUrl: './summary.component.html',
	styleUrls: ['./summary.component.scss'],
	animations: [
		trigger('sideNavMenuAnimationState', [
			state(
				'topX',
				style({
					transform: 'rotate(45deg)',
					transformOrigin: 'left',
					margin: '7px',
				})
			),
			state(
				'hide',
				style({
					opacity: 0,
				})
			),
			state(
				'bottomX',
				style({
					transform: 'rotate(-45deg)',
					transformOrigin: 'left',
					margin: '7px',
				})
			),
			state('line', style({})),
			state(
				'right',
				style({
					marginTop: '2px',
					marginLeft: '2px',
				})
			),
			state(
				'left',
				style({
					transform: 'rotate(-180deg)',
					transformOrigin: 'center',
					marginBottom: '2px',
					marginRight: '2px',
				})
			),
			transition(':enter', []),
			transition('* => *', [animate('0.2s')]),
		]),
	],
})
export class SummaryComponent implements OnInit, OnDestroy {
	@ViewChild('addressPrimary') public searchElementPrimary: ElementRef;
	@ViewChild('addressCoapplicant') public searchElementCoApplicant: ElementRef;
	summaryData: any;
	zohoApplicationID: any;
	Deal_Name: any;
	underwritingID: any;
	isSideNavMenuOpen = false;
	// pandaDocsAccessRoles: any;
	// checklistAccessRoles: any;
	// underwritingAccessRoles: any;
	// loanCalculatorAccessRoles: any;
	// transunionAccessRoles: any;
	// inveriteAccessRoles: any;
	// loanInterviewAccessRoles: any;
	// exceptionAccessRoles: any;

	// documentsAccessRoles = [0, 1, 3, 6, 7, 8, 9];
	pandaDocsAccessRoles = [0, 1, 3, 6, 7, 8, 9];
	checklistAccessRoles = [0, 1, 3, 6];
	underwritingAccessRoles = [0, 1, 3, 6, 8, 9];
	loanCalculatorAccessRoles = [0, 1, 3, 6, 8, 9];
	transunionAccessRoles = [0, 1, 3, 6, 7, 8, 9];
	inveriteAccessRoles = [0, 1, 3, 6, 8, 9];
	loanInterviewAccessRoles = [0, 1, 6];
	exceptionAccessRoles = [0];
	oldloanCalculatorAccessRoles = [7, 9, 27];

	isLandedFromZoho: boolean;

	// User login information
	userName: any;
	roleID: any;
	employeeId: any;

	selectedTab = 'general-information';

	summaryAppDataSubcription: Subscription;

	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private customFunction: CustomFunctionService,
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private summaryDataService: SummaryDataService
	) {

	}

	async ngOnInit(): Promise<void> {
		let URI = window.location.href	// URI for ZOHO side links
		let queryString = URI?.split('/');	// Split the url
		const tabName = queryString?.slice(-1)?.[0];	// Get the last element name
		if (!containsSpecialChars(tabName)) {	// If last element doesn't contain special chars
			this.selectedTab = tabName;
			this.isLandedFromZoho = this.isSideNavMenuOpen = true;
		}

		this.summaryDataService.getZohoLoggedUserDetails();
		this.activatedRoute?.params?.subscribe((params) => {
			this.zohoApplicationID = params?.['id'];
			this.summaryDataService.getZohoSingleAppSummary(this.zohoApplicationID);
		});

		await this.getNavPermission();
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			// console.log(userData,"TESTINGTESTINGTESTING-----------------------")
			this.userName = userData['email'];
			this.roleID = userData['roleID'];
			this.employeeId = userData['employeeID'];
		});

		this.summaryAppDataSubcription = this.summaryDataService.summaryDataObservable.subscribe(
			(summaryData: any) => {
				this.zohoApplicationID = summaryData.id;
				this.Deal_Name = summaryData.Deal_Name;
				this.summaryData = summaryData;
				if (this.Deal_Name) {
					this.apiService.insertPageHistory(
						this.Deal_Name,
						this.zohoApplicationID,
						this.userName
					).subscribe((res) => {
						if (res?.message === 'Success') {
							console.log("Insert page history!")
						} else {
							console.log(res, "Error insert page history!")
						}
					});
				}
			}
		);
	}

	getNavPermission() {
		return new Promise((resolve, reject) => {
			// Get summary side navigation permissions
			this.apiService.getBtnPermission().subscribe((res: any) => {
				let permissionResult = JSON.parse(res[0]?.['external_summary_side_nav'])?.SummaryPermission;
				this.pandaDocsAccessRoles = permissionResult?.pandaDocsAccessRoles.split(',').map(Number);
				this.checklistAccessRoles = permissionResult?.checklistAccessRoles.split(',').map(Number);
				this.underwritingAccessRoles = permissionResult?.underwritingAccessRoles.split(',').map(Number);
				this.loanCalculatorAccessRoles = permissionResult?.loanCalculatorAccessRoles.split(',').map(Number);
				this.transunionAccessRoles = permissionResult?.transunionAccessRoles.split(',').map(Number);
				this.inveriteAccessRoles = permissionResult?.inveriteAccessRoles.split(',').map(Number);
				this.loanInterviewAccessRoles = permissionResult?.loanInterviewAccessRoles.split(',').map(Number);
				this.exceptionAccessRoles = permissionResult?.exceptionAccessRoles.split(',').map(Number);
				this.oldloanCalculatorAccessRoles = permissionResult?.oldloanCalculatorAccessRoles.split(',').map(Number);
			})
			resolve(true);
		})

	}

	toggleSideNav() {
		this.isSideNavMenuOpen = !this.isSideNavMenuOpen;
	}

	updateSelectedTab(tabName) {
		this.selectedTab = tabName;
	}

	ngOnDestroy() {
		// no-op
		this.summaryAppDataSubcription?.unsubscribe();
		this.summaryData = {};
		console.log(this.summaryData, "@@@@___________________")
	}
}

/**
 *
 * @param str url
 * @returns true if contains special characters
 */
function containsSpecialChars(str) {
	const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
	return specialChars.test(str);
}
