<div id="summary-bar-wrapper">
	<mat-accordion>
		<mat-expansion-panel [expanded]="expandSummaryPanel">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<span style="font-size: 20px">
						<a class="noline" target="_blank" href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
								zohoApplicationID
							}}" *ngIf="!isExternalView ; else externalView">{{ Deal_Name }}</a>
						<ng-template #externalView>
							<a class="noline">{{ Deal_Name }}</a>
						</ng-template>
					</span>
					({{ Amount | currency }})
				</mat-panel-title>
				<mat-panel-description>
					<span style="font-size: 14px">
						[{{ Account_Name }} - {{ Creditt }} - {{ Referred_By }}]
					</span>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<div class="row row-cols-12">
				<div class="col-sm-3">
					<label class="labelBold">Stage</label><br />
					<div>
						<div class="row" style="
								display: flex;
								flex-direction: row;
								align-items: center;
								margin: 0;
							">
							<div *ngIf="!editingStage; else editStage" style="
									display: flex;
									flex-direction: row;
									align-items: center;
									padding: 0;
								">
								{{ Stage }}
								<button mat-icon-button (click)="flipEditingStageMode()" [disabled]="isExternalView">
									<mat-icon style="margin-left: 5px">edit</mat-icon>
								</button>
								<!-- <mat-icon (click)="flipEditingStageMode()"
									style="cursor: pointer; margin-left: 5px">edit</mat-icon> -->
							</div>
							<ng-template #editStage style="display: flex; flex-direction: row; align-items: center">
								<select class="form-select" [(ngModel)]="selectedStage"
									style="width: 60%; cursor: pointer">
									<option *ngFor="let item of stageName"
										[selected]="item.display_value === selectedStage" [value]="item.display_value">
										{{ item.display_value }}
									</option>
								</select>
								<div style="width: 35%">
									<mat-icon id="saveStageButton" (click)="saveStage()"
										style="cursor: pointer; margin-left: 5px">check</mat-icon>
									<mat-icon (click)="cancelSaveStage()"
										style="cursor: pointer; margin-left: 5px">close</mat-icon>
								</div>
							</ng-template>
							<small id="saveStageMessage"></small>
						</div>
					</div>
				</div>
				<div class="col-sm-2">
					<label class="labelBold">Address</label><br />
					<label>
						{{ Address_Line_2 ? Address_Line_2 + '-' : '' }}{{ Address1 }},
						{{ City }}, {{ Province }},
						{{ Postal_Code }}
					</label>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">DOB</label><br /><label>{{ dob }} ({{ Age }})</label>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">Tel#</label>
					<br />
					<label *ngIf="Mobile != null">{{ Mobile }}</label>
					<label *ngIf="Mobile === null">{{ Phone }}</label>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">Credit Score</label><br /><label>{{
						Credit_Score
						}}</label>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">TDSR (%)</label><br /><label style="padding-right: 10px">{{ TDSR }}</label>
					<span *ngIf="!isExternalView" (click)="updateTDSR()" style="cursor: pointer; padding: 5px"><img
							title="Refresh" src="/assets/images/refreshpage.png" width="16" height="17" />
					</span>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">Loan Number</label><br /><label>{{
						loan_number
						}}</label>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">Sales Agent</label><br /><label>{{
						Sales_Agent
						}}</label>
				</div>
				<div class="col-sm-1">
					<label class="labelBold">Homeowner</label><br /><label>{{
						Are_you_currently_a_Home_Owner
						}}</label>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="accordion" id="summary-bar-accordion" *ngIf="false">
		<div class="accordion-item">
			<div>
				<div class="row row-cols-12">
					<div class="col-sm-9" style="width: 80%">
						<h5>
							<span>
								<a class="noline" target="_blank" href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
										zohoApplicationID
									}}">{{ Deal_Name }}</a>
							</span>

							({{ Amount | currency }})
							<span style="font-size: 14px">
								[{{ Account_Name }} - {{ Creditt }} - {{ Referred_By }}]
							</span>
						</h5>
					</div>
					<div class="col-sm-2">
						Referred: {{ Referral_Date | date : 'mediumDate' }}
					</div>
					<button class="accordion-button col-1" [ngClass]="showSummaryBar ? 'collapsed' : ''" type="button"
						data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
						aria-controls="collapseOne"></button>
				</div>
			</div>
			<div id="collapseOne" class="accordion-collapse collapse" [ngClass]="showSummaryBar ? 'hide' : 'show'"
				aria-labelledby="headingOne" data-bs-parent="#summary-bar-accordion">
				<div class="accordion-body">
					<div class="row row-cols-12">
						<div class="col-sm-1">
							<label class="labelBold">Stage</label><br /><label>{{
								Stage
								}}</label>
						</div>
						<div class="col-sm-3">
							<label class="labelBold">Address</label><br />
							<label *ngIf="Address_Line_2 != null">{{ Address_Line_2 }}-{{ Address1 }}, {{ City }},
								{{ Province }}, {{ Postal_Code }}</label>
							<label *ngIf="Address_Line_2 === null">{{ Address1 }}, {{ City }}, {{ Province }},
								{{ Postal_Code }}</label>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">DOB</label><br /><label>{{ dob }} ({{ Age }})</label>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">Tel#</label>
							<br />
							<label *ngIf="Mobile != null">{{ Mobile }}</label>
							<label *ngIf="Mobile === null">{{ Phone }}</label>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">Credit Score</label><br /><label>{{
								Credit_Score
								}}</label>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">TDSR (%)</label><br /><label style="padding-right: 10px">{{ TDSR
								}}</label>
							<span (click)="updateTDSR()" style="cursor: pointer; padding: 5px"><img title="Refresh"
									src="/assets/images/refreshpage.png" width="16" height="17" />
							</span>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">UW Tier</label><br /><label>{{
								uw_tier
								}}</label>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">Sales Agent</label><br /><label>{{
								Sales_Agent
								}}</label>
						</div>
						<div class="col-sm-1">
							<label class="labelBold">Homeowner</label><br /><label>{{
								Are_you_currently_a_Home_Owner
								}}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>