import { Injectable } from '@angular/core';
import {
	GetObjectCommand,
	ListObjectsCommand,
	PutObjectCommand,
	S3Client,
	DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class S3ClientService {
	private bucket: S3Client;
	constructor() {
		this.bucket = new S3Client({
			credentials: {
				accessKeyId: environment.AWS_ACCESS_KEY_ID,
				secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
			},
			region: environment.AWS_REGION,
		});
	}

	async uploadFile(file: File, folderName: string, zohoApplicationID: string) {
		const params = {
			Bucket: environment.AWS_BUCKET,
			Key: `checklistFiles/${zohoApplicationID}/${folderName}/${file.name}`,
			Body: file,
			ACL: environment.AWS_ACL,
			ContentType: file.type,
		};

		try {
			const response = await this.bucket.send(new PutObjectCommand(params));
			console.log('SUCCESS', response);
		} catch (error) {
			console.log('FAILURE', error);
		}
	}

	async getFile(
		fileName: string,
		folderName: string,
		zohoApplicationID: string
	) {
		const params = {
			Bucket: environment.AWS_BUCKET,
			Key: `checklistFiles/${zohoApplicationID}/${folderName}/${fileName}`,
			ResponseContentEncoding: 'UTF-8',
		};

		try {
			const response = await this.bucket.send(new GetObjectCommand(params));
			console.log('SUCCESS', response);
			return response;
		} catch (error) {
			console.log('FAILURE', error);
		}
	}

	async getFilesFromFolder(folderName: string, zohoApplicationID: string) {
		const params = {
			Bucket: environment.AWS_BUCKET,
			Key: `checklistFiles/${zohoApplicationID}/${folderName}/`,
			Prefix: `checklistFiles/${zohoApplicationID}/${folderName}/`,
		};

		try {
			const response = await this.bucket.send(new ListObjectsCommand(params));
			// console.log('SUCCESS', response);
			return response;
		} catch (error) {
			console.log('FAILURE', error);
		}
	}

	async deleteFile(
		fileName: string,
		folderName: string,
		zohoApplicationID: string
	) {
		const params = {
			Bucket: environment.AWS_BUCKET,
			Key: `checklistFiles/${zohoApplicationID}/${folderName}/${fileName}`,
		};

		try {
			const response = await this.bucket.send(new DeleteObjectCommand(params));
			console.log('SUCCESS', response);
			return response;
		} catch (error) {
			console.log('FAILURE', error);
		}
	}
}
