import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mft-lpp0876',
  templateUrl: './mft-lpp0876.component.html',
  styleUrls: ['./mft-lpp0876.component.scss']
})
export class MftLpp0876Component implements OnInit, OnDestroy {

  mftDataSubscription: Subscription;
  MFTLPP_Report: any;

  //_Hist
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  btnSpinner = false;
  btnShowText = true;
  start_date: any;
  end_date: any;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    //Payment History
    this.columnDefs_Hist = [
      { headerName: 'acctrefno', field: 'acctrefno', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'loan_number', field: 'loan number', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Account Name', field: 'Account Name', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 250, width: 250, },
      { headerName: 'Loan Group', field: 'Loan Group', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Maturity Date', field: 'Maturity Date', sortable: true, filter: 'agDateColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Status Code', field: 'Status Code', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Credit Score', field: 'Credit Score', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Orginal Loan Amount', field: 'Orginal Loan Amount', sortable: true, filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Insurance Premium', field: 'Insurance Premium', sortable: true, filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Ins tax', field: 'Ins tax', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Industry', field: 'Industry', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 200, width: 250, },
      { headerName: 'Payments Remaining', field: 'Payments Remaining', sortable: true, filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Principal Period', field: 'Principal Period', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Payoff Date', field: 'Payoff Date', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Reg Payment', field: 'Reg Payment', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Open Date', field: 'Open Date', sortable: true, filter: 'agDateColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'State', field: 'State', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Position', field: 'Position', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 200, width: 150, },
      { headerName: 'LoanStatus', field: 'LoanStatus', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'TDSR', field: 'TDSR', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 200, width: 150, },
      { headerName: 'Setup Date', field: 'setup date', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'mft_lpp_0876_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'mft_lpp_0876_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  ngOnInit(): void {
  }

  startDate(e) {
    this.start_date = e;
  }
  endDate(e) {
    this.end_date = e;
  }

  downloadMFTLPP() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else {
      this.btnSpinner = true;
      this.btnShowText = false;
      this.MFTLPP_Report = null;
      this.rowData_Hist = [];
      this.mftDataSubscription = this.apiService.getMFTLPP(this.start_date, this.end_date).subscribe((res) => {
        console.log(res);
        this.MFTLPP_Report = res;
        this.gridApi_Hist.setRowData(this.MFTLPP_Report);
        this.rowData_Hist = this.MFTLPP_Report;
        this.btnSpinner = false;
        this.btnShowText = true;
      })
    }
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.MFTLPP_Report);
    this.rowData_Hist = this.MFTLPP_Report;
  }

  ngOnDestroy() {
    this.mftDataSubscription?.unsubscribe();
  }

}
