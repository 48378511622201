<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card class="insolvency-mat-card">
                <mat-card-content class="delinquency-mat-card-content">
                    <div style="font-size: 45px;font-weight: bold;">{{currentMonth}} Collection</div>
                    <mat-divider></mat-divider>
                    <div class="row" style="justify-content: space-between;">
                        <div class="col-auto">
                            {{collectedAmount | currency}}  
                        </div>
                        <div class="col-auto">
                            {{targetAmount | currencyShortForm}}   
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{collectedPercent}}" ></mat-progress-bar>
                    <div class="row" style="margin-top: 0; justify-content: space-between;">
                        <div class="col-auto">
                            {{collectedPercent}}%   
                        </div>
                        <div class="col-auto">
                            {{daysCountdown}}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card style="color: #2b2c49;background: white; ">
                <mat-card-content>
                    <div class="metrics-container">
                        <div>Debenture</div>
                        <div class="row">
                            <div>{{total_debenture_less_ninety | currency}}</div>
                            <div>{{percent_debenture_less_ninety}}%</div>
                            <div>Early Stage (1-90)</div>
                        </div>
                        <div class="row">  
                            <div>{{current_debenture_ninety_plus | currency}}</div>                          
                            <div>{{percent_debenture_ninety_plus}}%</div>
                            <div>Defaulting (90+)</div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card style="color: #2b2c49;background: white; ">
                <mat-card-content>
                    <div class="metrics-container">
                        <div>MFT</div>
                        <div class="row">
                            <div>{{total_mft_less_ninety | currency}}</div>
                            <div>{{percent_mft_less_ninety}}%</div>
                            <div>Early Stage (1-90)</div>
                        </div>
                        <div class="row"> 
                            <div>{{current_mft_ninety_plus | currency}}</div>                           
                            <div>{{percent_mft_ninety_plus}}%</div>
                            <div>Defaulting (90+)</div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card style="color: #2b2c49;background: white; ">
                <mat-card-content>
                    <div class="metrics-container">
                        <div>QVEST</div>
                        <div class="row">
                            <div>{{total_qvest_less_ninety | currency}}</div>
                            <div>{{percent_qvest_less_ninety}}%</div>
                            <div>Early Stage (1-90)</div>
                        </div>
                        <div class="row">   
                            <div>{{current_qvest_ninety_plus | currency}}</div>                         
                            <div>{{percent_qvest_ninety_plus}}%</div>
                            <div>Defaulting (90+)</div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card style="color: #2b2c49;background: white; ">
                <mat-card-content>
                    <div class="metrics-container">
                        <div>SPV</div>
                        <div class="row">
                            <div>{{total_spv_less_ninety | currency}}</div>
                            <div>{{percent_spv_less_ninety}}%</div>
                            <div>Early Stage (1-90)</div>
                        </div>
                        <div class="row"> 
                            <div>{{current_spv_ninety_plus | currency}}</div>                           
                            <div>{{percent_spv_ninety_plus}}%</div>
                            <div>Defaulting (90+)</div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>