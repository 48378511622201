import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nsf-reports-date',
  templateUrl: './nsf-reports-date.component.html',
  styleUrls: ['./nsf-reports-date.component.scss']
})
export class NsfReportsDateComponent {
  nsfDataSubscription: Subscription;
  nsf_report: any;

  //_Hist
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  btnSpinner = false;
  btnShowText = true;

  dateSelected: any;
  selectedLoans: any;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    // NSF report
    this.columnDefs_Hist = [
      { headerName: 'Loan Number', field: 'loan_number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Account Name', field: 'account_name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Loan Group', field: 'loan_group', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Status Code', field: 'status_code', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Payment Amount', field: 'payment_amount', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Pymt Method', field: 'payment_method_code', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Pymt Method Ref', field: 'payment_method_reference', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'NSF Date', field: 'nsf_date', sortable: true, filter: "agDateColumnFilter" }
    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'nsf_report_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'nsf_report_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  currentDate(e) {
    this.dateSelected = e;
  }

  getReportType(e) {
    this.selectedLoans = e?.['data'];
  }

  getCustomLoanGroups(e) {
    this.selectedLoans = e;
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.nsf_report);
    this.rowData_Hist = this.nsf_report

  }

  downloadNSF() {
    this.btnSpinner = true;
    this.btnShowText = false;
    this.nsf_report = null;
    this.rowData_Hist = [];
    this.nsfDataSubscription = this.apiService.getNSFDateReport(this.dateSelected, this.selectedLoans).subscribe((res: any) => {
      if (res?.status) {
        this.nsf_report = res?.data;
        this.gridApi_Hist.setRowData(this.nsf_report);
        this.rowData_Hist = this.nsf_report;
        this.btnSpinner = false;
        this.btnShowText = true;
      } else {
        console.log(res)
      }
    })
  }

  ngOnDestroy() {
    this.nsfDataSubscription?.unsubscribe();
  }


}
