<mat-tree
	[dataSource]="dataSource"
	[treeControl]="treeControl"
	class="hierarchy-data"
>
	<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
		{{ node.name }}
	</mat-tree-node>
	<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
		<div class="mat-tree-node">
			<button
				mat-icon-button
				matTreeNodeToggle
				[attr.aria-label]="'Toggle ' + node.name"
			>
				<mat-icon class="mat-icon-rtl-mirror">
					{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
				</mat-icon>
			</button>
			{{ node.name }}
		</div>
		<div
			[class.hierarchy-data-invisible]="!treeControl.isExpanded(node)"
			role="group"
		>
			<ng-container matTreeNodeOutlet></ng-container>
		</div>
	</mat-nested-tree-node>
</mat-tree>
