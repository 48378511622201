import { Action, createReducer, on } from '@ngrx/store';
import * as SummaryActions from './summary.actions';
import { initialState, SummaryState } from './summary.state';

const reducer = createReducer(
	initialState,
	on(SummaryActions.updateSummaryDetails, (state, payload) => {
		// console.log('Setting updateSummaryDetails', payload);
		console.log('Setting updateSummaryDetails!');
		return {
			...state,
			summary: payload.summary,
		};
	})
);

export function SummaryReducer(
	state: SummaryState | undefined,
	action: Action
) {
	return reducer(state, action);
}
