import { Component } from '@angular/core';

@Component({
  selector: 'app-sanity-checks',
  templateUrl: './sanity-checks.component.html',
  styleUrls: ['./sanity-checks.component.scss']
})
export class SanityChecksComponent {

}
