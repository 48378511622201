import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiServiceService } from 'app/api-service.service';
import { GeneralObjects } from 'app/models/globalObjects';

@Component({
  selector: 'app-funded-tv-report',
  templateUrl: './funded-tv-report.component.html',
  styleUrls: ['./funded-tv-report.component.scss']
})
export class FundedTvReportComponent {
  getMonthName = new GeneralObjects();

  currentMonth: any;
  targetAmount: any;
  fundedAmount: any;
  fundedPercent: any;
  daysCountdown: any;

  agencyFunded: any;
  creditorFunded: any;

  intervalCheck: any;
  
  constructor(private apiService: ApiServiceService) {}

  async ngOnInit(): Promise<void> {   
    let today = new Date();
    this.currentMonth = this.getMonthName.monthNames[today.getMonth()];
    let daysInMonth = getDays(today.getFullYear(), today.getMonth()+1);
    let calCountDown = daysInMonth - today.getDate();
    this.daysCountdown = (calCountDown === 0 ? "Last Day" : calCountDown + ' Days left');

    this.getFundedMonth();  
    this.getTVAgencyFunded(); 
    this.getTVCreditorFunded();
    this.intervalCheck = setInterval(() => {
      this.getFundedMonth();   
      this.getTVAgencyFunded(); 
      this.getTVCreditorFunded();
      console.log(this.fundedAmount)
    }, 60000);
  }

  async getFundedMonth(){
    let result = await this.getTargetFunded().then(value=>this.targetAmount = value)
    this.apiService.getCurrentMonthFundedTV().subscribe((res:any)=>{
      if(res['status']){
        if(res['data'].Length !==0){
          this.fundedAmount = 0;
          this.fundedAmount = res['data']?.[0]?.['Funded'];
          this.fundedPercent = ((this.fundedAmount / this.targetAmount)*100).toFixed(2)
        }else{
          this.fundedAmount=0
        }
      }else{
        console.log(res)
      }
    });
  }

  async getTargetFunded(){
    return new Promise((resolve,reject)=>{
      this.apiService.getTargetVariables().subscribe((res)=>{
        resolve(res['data']?.[0]?.['monthly_funded_target'])
      })
    })
  }

  async getTVAgencyFunded(){
    this.apiService.getTVAgencyFunded().subscribe((res)=>{
      this.agencyFunded = res?.['data']
    })
  }

  async getTVCreditorFunded(){
    this.apiService.getTVCreditorFunded().subscribe((res)=>{
      this.creditorFunded = res?.['data']
    })
  }

  ngOnDestroy() {
    if (this.intervalCheck) {
      clearInterval(this.intervalCheck);
    }
  }

}

const getDays = (year, month) => {
  return new Date(year, month, 0).getDate();
};

