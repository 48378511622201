import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReadComponent } from './read/read.component'; // Display new applications
import { PreApprovalComponent } from './pre-approval/pre-approval.component';
import { SalesdashboardComponent } from './Dashboard/ZOHO/salesdashboard/salesdashboard.component';
import { UnderwritingdashboardComponent } from './Dashboard/ZOHO/underwritingdashboard/underwritingdashboard.component';
import { DealsPipelineComponent } from './Dashboard/ZOHO/deals-pipeline/deals-pipeline.component';
import { NlsdashboardComponent } from './Dashboard/NLS/funded/current/nlsdashboard/nlsdashboard.component';
import { NlsagencyfundedComponent } from './Dashboard/NLS/funded/monthly/nlsagencyfunded/nlsagencyfunded.component';
import { NlsagencycollectorsComponent } from './Dashboard/NLS/funded/monthly/nlsagencycollectors/nlsagencycollectors.component';
import { UnderwritingexceptionComponent } from './Dashboard/ZOHO/underwritingexception/underwritingexception.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { UnderwritingagencyexceptionComponent } from './Dashboard/ZOHO/underwritingagencyexception/underwritingagencyexception.component';
import { NlscollectionsComponent } from './Dashboard/NLS/collections/nlscollections/nlscollections.component';
import { NlsagencycollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsagencycollections/nlsagencycollections.component';
import { NlsagencycollectionscollectorComponent } from './Dashboard/NLS/collections/grouped/nlsagencycollectionscollector/nlsagencycollectionscollector.component';
import { NlsagencycollectionscollectorclientsComponent } from './Dashboard/NLS/collections/grouped/nlsagencycollectionscollectorclients/nlsagencycollectionscollectorclients.component';
import { NlslocationcollectionsComponent } from './Dashboard/NLS/collections/grouped/nlslocationcollections/nlslocationcollections.component';
import { NlsloansizecollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsloansizecollections/nlsloansizecollections.component';
import { NlscreditorcollectionsComponent } from './Dashboard/NLS/collections/grouped/nlscreditorcollections/nlscreditorcollections.component';
import { NlscreditorcollectionscollectorComponent } from './Dashboard/NLS/collections/grouped/nlscreditorcollectionscollector/nlscreditorcollectionscollector.component';
import { NlsportfoliocollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsportfoliocollections/nlsportfoliocollections.component';
import { NlsportfoliocollectionsbreakdownComponent } from './Dashboard/NLS/collections/grouped/nlsportfoliocollectionsbreakdown/nlsportfoliocollectionsbreakdown.component';
import { ViewUnderwritingComponent } from './view-underwriting/view-underwriting.component';
import { InterviewerqueueComponent } from './Dashboard/ZOHO/interviewerqueue/interviewerqueue.component';
import { ValidatorqueueComponent } from './Dashboard/ZOHO/validatorqueue/validatorqueue.component';
import { MonthlyincomecalcComponent } from './monthlyincomecalc/monthlyincomecalc.component';
import { ApplicationComponent } from './application/application.component';
import { LoanMetaComponent } from './loan-meta/loan-meta.component';
import { NlsexceptioncollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsexceptioncollections/nlsexceptioncollections.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { PtpReportComponent } from './Dashboard/NLS/ptp-report/ptp-report.component';
import { PtpCreatedReportComponent } from './Dashboard/NLS/ptp-created-report/ptp-created-report.component';
import { UwPerformanceComponent } from './Dashboard/NLS/uw-performance/uw-performance.component';
import { ReportsComponent } from './Dashboard/NLS/reports/reports.component';
import { ReportsDashboardComponent } from './Dashboard/NLS/reports/reports-dashboard/reports-dashboard.component';
import { RollOversComponent } from './Dashboard/NLS/reports/roll-overs/roll-overs.component';
import { IntermediaCallReportsComponent } from './tv-custom-dashboards/intermedia-call-reports/intermedia-call-reports.component';
import { CollectionListComponent } from './Dashboard/NLS/collections/collection-list/collection-list.component';
import { CollectionTvReportComponent } from './tv-custom-dashboards/collection-tv-report/collection-tv-report.component';
import { FundedTvReportComponent } from './tv-custom-dashboards/funded-tv-report/funded-tv-report.component';

// Summary
import { SummaryComponent } from './summary/summary.component';
import { GeneralinformationComponent } from './summary/generalinformation/generalinformation.component';
import { PandadocsComponent } from './summary/pandadocs/pandadocs.component';
import { ChecklistComponent } from './summary/checklist/checklist.component';
import { UnderwritingComponent } from './summary/underwriting/underwriting.component';
import { LoancalculatorComponent } from './summary/loancalculator/loancalculator.component';
import { TransunionComponent } from './summary/transunion/transunion.component';
import { InveriteComponent } from './summary/inverite/inverite.component';
import { LoanInterviewComponent } from './summary/loan-interview/loan-interview.component';
import { UnderwritingExceptionComponent } from './summary/underwriting-exception/underwriting-exception.component';
import { IframeViewComponent } from '@lmc-app/summary/iframe-view/iframe-view.component';

import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PromiseToPaysComponent } from '@lmc-app/loan-servicing/promise-to-pays/promise-to-pays.component';
// Finance Components
import { AccountInformationComponent } from './loan-servicing/reports/account-information/account-information.component';
import { PaymentBreakdownBoardComponent } from './loan-servicing/reports/payment-breakdown-board/payment-breakdown-board.component';
import { SanityChecksComponent } from './loan-servicing/reports/sanity-checks/sanity-checks.component';
import { LppReportsComponent } from './loan-servicing/reports/lpp-reports/lpp-reports.component';
import { CollectionsComponent } from './Dashboard/NLS/collections/collections.component';

// Reports
import { PtpDateReportComponent } from './general-reports/ptp-date-report/ptp-date-report.component';

// Admin Settings
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AssignCollectorsComponent } from './admin-settings/assign-collectors/assign-collectors.component';

// Betty
import { LoanValidationComponent } from './betty/loan-validation/loan-validation.component';

// NLS 
import { InsuranceTransCancellationComponent } from './loan-servicing/insurance-trans-cancellation/insurance-trans-cancellation.component';

//Rapid Program
import { RapidProgramComponent } from './betty/rapid-program/rapid-program.component';
import { TransunionConsentFormComponent } from './betty/transunion-consent-form/transunion-consent-form.component';

import { OldLoanCalculatorComponent } from './summary/old-loan-calculator/old-loan-calculator.component';

type PathMatch = 'full' | 'prefix' | undefined;

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full' as PathMatch, // Direct to login page
	},
	{
		path: 'transunion',
		canActivate: [AuthGuard], // To authenticate session
		component: TransunionComponent,
		data: { title: 'TransUnion', breadcrumb: 'TransUnion' },
	},
	{
		path: 'general-information',
		canActivate: [AuthGuard], // To authenticate session
		component: GeneralinformationComponent,
		data: { title: 'General Information', breadcrumb: 'General Information' },
	},
	{
		path: 'underwriting',
		canActivate: [AuthGuard], // To authenticate session
		component: UnderwritingComponent,
		data: { title: 'Underwriting', breadcrumb: 'Underwriting' },
	},
	{
		path: 'viewUnderwriting',
		canActivate: [AuthGuard], // To authenticate session
		component: ViewUnderwritingComponent,
		data: { title: 'Viewunderwriting', breadcrumb: 'Underwriting View' },
	},
	{
		path: 'inverite',
		canActivate: [AuthGuard], // To authenticate session
		component: InveriteComponent,
		data: { title: 'Inverite', breadcrumb: 'Inverite' },
	},
	{
		path: 'preapproval',
		component: RapidProgramComponent,
		data: { title: 'Pre-Approval', breadcrumb: 'Pre-Approval' },
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard], // To authenticate session
		component: HomeComponent,
		data: { title: 'Home Page', breadcrumb: 'EditForm' },
	},
	{
		path: 'pdf-viewer',
		canActivate: [AuthGuard], // To authenticate session
		component: PdfViewerComponent,
		data: { title: 'PDF Viewer', breadcrumb: 'PDF Viewer' },
	},
	{
		path: 'applications',
		canActivate: [AuthGuard], // To authenticate session
		component: ReadComponent,
		data: { title: 'Read application form TEST', breadcrumb: 'readForm' },
	},
	{
		path: 'applications/create',
		canActivate: [AuthGuard], // To authenticate session
		component: ApplicationComponent,
		data: { title: 'Create client form TEST', breadcrumb: 'createForm' },
	},
	{
		path: 'applications/edit/:id',
		canActivate: [AuthGuard], // To authenticate session
		component: ApplicationComponent,
		data: { title: 'Edit Form', breadcrumb: 'EditForm' },
	},
	{
		path: 'applications/clone/:id',
		canActivate: [AuthGuard], // To authenticate session
		component: ApplicationComponent,
		data: { title: 'Edit Form', breadcrumb: 'EditForm' },
	},
	{
		path: 'applications/summary/:id',
		canActivate: [AuthGuard], // To authenticate session
		component: SummaryComponent,
		children: [
			{
				path: '',
				redirectTo: '',
				pathMatch: 'full' as PathMatch,
			},
			{
				path: 'general-information',
				component: GeneralinformationComponent,
			},
			{
				path: 'iframe-view',
				component: IframeViewComponent,
			},
			{
				path: 'pandadocs',
				component: PandadocsComponent,
			},
			{
				path: 'checklist',
				component: ChecklistComponent,
			},
			{
				path: 'underwriting',
				component: UnderwritingComponent,
			},
			{
				path: 'calculator',
				component: LoancalculatorComponent,
				data: { title: 'Loan Calculator', breadcrumb: 'Loan Calculator' },
			},
			{
				path: 'transunion',
				canActivate: [AuthGuard],
				component: TransunionComponent,
				data: { title: 'TransUnion', breadcrumb: 'TransUnion' },
			},
			{
				path: 'inverite',
				canActivate: [AuthGuard],
				component: InveriteComponent,
				data: { title: 'Inverite', breadcrumb: 'Inverite' },
			},
			{
				path: 'loaninterview',
				canActivate: [AuthGuard],
				component: LoanInterviewComponent,
				data: { title: 'ZOHO', breadcrumb: 'readForm' },
			},
			{
				path: 'underwriting-exception',
				canActivate: [AuthGuard],
				component: UnderwritingExceptionComponent,
				data: { title: 'Underwriting Dashboard', breadcrumb: 'readForm' },
			},
			{
				path: 'oldCalculator',
				component: OldLoanCalculatorComponent,
				data: { title: 'Loan Calculator', breadcrumb: 'Loan Calculator' },
			},
		],
	},
	{
		path: 'salesdashboard',
		canActivate: [AuthGuard], // To authenticate session
		component: SalesdashboardComponent,
		data: { title: 'Sales Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'underwritingdashboard',
		canActivate: [AuthGuard], // To authenticate session
		component: UnderwritingdashboardComponent,
		data: { title: 'Underwriting Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'underwritingexception',
		canActivate: [AuthGuard], // To authenticate session
		component: UnderwritingexceptionComponent,
		data: { title: 'Underwriting Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'underwritingagencyexception',
		canActivate: [AuthGuard], // To authenticate session
		component: UnderwritingagencyexceptionComponent,
		data: { title: 'Underwriting Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'dealspipeline',
		canActivate: [AuthGuard], // To authenticate session
		component: DealsPipelineComponent,
		data: { title: 'Deals Pipeline', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsdashboard',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsdashboardComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsagencyfunded',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsagencyfundedComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsagencycollectors',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsagencycollectorsComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlscollections',
		canActivate: [AuthGuard], // To authenticate session
		component: NlscollectionsComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsagencycollections',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsagencycollectionsComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsagencycollectionscollectors',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsagencycollectionscollectorComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsagencycollectionscollectorclients',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsagencycollectionscollectorclientsComponent,
		data: { title: 'NLS Dashboard', breadcrumb: 'readForm' },
	},
	{
		path: 'nlscreditorcollectionscollectors',
		canActivate: [AuthGuard], // To authenticate session
		component: NlscreditorcollectionscollectorComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'nlscreditorcollections',
		canActivate: [AuthGuard], // To authenticate session
		component: NlscreditorcollectionsComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsloansizecollections',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsloansizecollectionsComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'nlslocationcollections',
		canActivate: [AuthGuard], // To authenticate session
		component: NlslocationcollectionsComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsportfoliocollections',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsportfoliocollectionsComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsportfoliodetails',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsportfoliocollectionsbreakdownComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'nlsexception',
		canActivate: [AuthGuard], // To authenticate session
		component: NlsexceptioncollectionsComponent,
		data: { title: 'NLS Collection Report', breadcrumb: 'readForm' },
	},
	{
		path: 'login',
		component: LoginComponent,
		data: { title: 'Login', breadcrumb: 'readForm' },
	},
	{
		path: 'interviewer',
		canActivate: [AuthGuard], // To authenticate session
		component: InterviewerqueueComponent,
		data: { title: 'ZOHO', breadcrumb: 'readForm' },
	},
	{
		path: 'validator',
		canActivate: [AuthGuard], // To authenticate session
		component: ValidatorqueueComponent,
		data: { title: 'ZOHO', breadcrumb: 'readForm' },
	},
	{
		path: 'loaninterview',
		canActivate: [AuthGuard], // To authenticate session
		component: LoanInterviewComponent,
		data: { title: 'ZOHO', breadcrumb: 'readForm' },
	},
	{
		path: 'incomecalculator',
		component: MonthlyincomecalcComponent,
	},
	{
		path: 'checklist',
		component: ChecklistComponent,
	},
	{
		path: 'documents/:id',
		component: AgreementsComponent,
	},
	{
		path: 'loan-meta',
		component: LoanMetaComponent,
	},
	{
		path: 'reports',
		component: ReportsComponent,
		children: [
			{
				path: '',
				redirectTo: 'reports-dashboard',
				pathMatch: 'full' as PathMatch,
			},
			{
				path: 'reports-dashboard',
				component: ReportsDashboardComponent,
			},
			{
				path: 'roll-overs',
				component: RollOversComponent,
			},
		],
	},
	{
		path: 'promise-to-pays',
		canActivate: [AuthGuard],
		component: PromiseToPaysComponent,
	},
	{
		path: 'ptp-report',
		component: PtpReportComponent,
	},
	{
		path: 'ptp-created-report',
		component: PtpCreatedReportComponent,
	},
	{
		path: 'uw-performance',
		component: UwPerformanceComponent,
	},
	{
		path: 'tv-intermedia-calls',
		component: IntermediaCallReportsComponent,
	},
	{
		path: 'collection-list',
		component: CollectionsComponent,
	},
	{
		path: 'tv-collection-reports',
		component: CollectionTvReportComponent
	},
	{
		path: 'tv-funded-reports',
		component: FundedTvReportComponent
	},
	// Finance routes
	{
		path: 'loan-servicing/reports/account-informations',
		component: AccountInformationComponent
	},
	{
		path: 'loan-servicing/reports/payment-breakdowns',
		component: PaymentBreakdownBoardComponent
	},
	{
		path: 'loan-servicing/reports/sanity-checks',
		component: SanityChecksComponent
	},
	{
		path: 'loan-servicing/reports/lpp-reports',
		component: LppReportsComponent
	},
	{
		path: 'reports/collections/payments-received',
		component: PtpDateReportComponent
	},
	{
		path: 'admin-settings',
		component: AdminSettingsComponent,
		children: [
			{
				path: '',
				redirectTo: 'admin-dashboard',
				pathMatch: 'full' as PathMatch,
			},
			{
				path: 'admin-dashboard',
				component: AssignCollectorsComponent,
			},
		],
	},
	{
		path: 'loan-validation',
		component: LoanValidationComponent,
	},
	{
		path: 'loan-servicing/insurance-cancellaction',
		component: InsuranceTransCancellationComponent
	},
	{
		path: 'transunion-consent-form',
		component: TransunionConsentFormComponent,
		data: { title: 'Consent to Pull Credit', breadcrumb: 'LMCredit' }
	},
	{ path: '**', redirectTo: 'login' }	// This one has to be the last
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
