<div class="flex-container">
    <div class="row justify-content-evenly">
        <div class="col-4">
            <span style="display:flex; justify-content: center;">
                <span>
                    <h5 style="margin-top: 22px;">Zero balance</h5>
                </span>
                <span *ngIf="zero_balance_list">
                    <app-custom-export-excel [sheetArray]="zeroFileSheet"
                        [fileName]="zeroFile"></app-custom-export-excel>
                </span>
            </span>
            <div class="fixTableHead">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Loan Number</th>
                            <th>Payoff Date</th>
                            <th>Interest Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of zero_balance_list">
                            <td>{{ item.loan_number }}</td>
                            <td>{{ item.payoff_date }}</td>
                            <td>{{ item.current_interest_rate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-4">
            <span style="display:flex; justify-content: center;">
                <span>
                    <h5 style="margin-top: 22px;">Zero balance with interest</h5>
                </span>
                <span *ngIf="zero_with_interest_list">
                    <app-custom-export-excel *ngIf="zero_with_interest_list" [sheetArray]="interestFileSheet"
                        [fileName]="interestFile"></app-custom-export-excel>
                </span>
            </span>
            <div class="fixTableHead">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Loan Number</th>
                            <th>Current Interest Balance</th>
                            <th>DPD</th>
                            <th>Interest Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of zero_with_interest_list">
                            <td>{{ item.loan_number }}</td>
                            <td>{{ item.current_interest_balance }}</td>
                            <td>{{ item.days_past_due }}</td>
                            <td>{{ item.current_interest_rate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>