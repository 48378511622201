import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-deals-pipeline',
	templateUrl: './deals-pipeline.component.html',
	styleUrls: ['./deals-pipeline.component.scss'],
})
export class DealsPipelineComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private _routerLink: Router
	) { }

	timestamp = Date();
	groupedStage: string[] = []; // Array to store the new filtered and calculated objects

	//ORIGINATION
	totalOriginationCount: any;
	totalOriginationAmount: any;
	// Incomplete Documents
	todayDealIncompleteApps: IDeals[] = []; // Store reponse array
	countIncomplete: any;
	totalIncomplete: any;

	// Needs Documents
	todayDealNeedDocs: IDeals[] = []; // Store reponse array
	countDocuments: any;
	totalDocuments: any;

	// Pending EC
	todayDealPendingEC: IDeals[] = []; // Store reponse array
	countPendingEC: any;
	totalPendingEC: any;

	// Pending VOE
	todayDealPendingVOE: IDeals[] = []; // Store reponse array
	countPendingVOE: any;
	totalPendingVOE: any;

	// Pending-Manager Approval
	todayDealPendingMA: IDeals[] = []; // Store reponse array
	countPendingMA: any;
	totalPendingMA: any;

	// UNDERWRITING
	totalUnderwritingCount: any;
	totalUnderwritingAmount: any;
	// Budget Assessment
	todayDealCreditBureau: IDeals[] = []; // Store reponse array
	countCreditBureau: any;
	totalCreditBureau: any;

	// Ready for Loans Interview
	todayDealLoanInterview: IDeals[] = []; // Store reponse array
	countLoanInterview: any;
	totalLoanInterview: any;

	// Ready for Loans Interview
	todayDealFileReview: IDeals[] = []; // Store reponse array
	countFileReview: any;
	totalFileReview: any;

	// CLOSING
	totalClosingCount: any;
	totalClosingAmount: any;
	// Conditionally Approved
	todayDealCA: IDeals[] = []; // Store reponse array
	countCA: any;
	totalCA: any;

	// Approved
	todayDealApproved: IDeals[] = []; // Store reponse array
	countApproved: any;
	totalApproved: any;

	// Agreements
	todayDealAgreements: IDeals[] = []; // Store reponse array
	countAgreements: any;
	totalAgreements: any;

	// Validation
	todayDealValidation: IDeals[] = []; // Store reponse array
	countValidation: any;
	totalValidation: any;

	// Funded
	todayDealFunded: IDeals[] = []; // Store reponse array
	countFunded: any;
	totalFunded: any;

	// Partners Funded
	todayDealPF: IDeals[] = []; // Store reponse array
	countPF: any;
	totalPF: any;

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						// this.timestamp = Date();
						this.fetchDeals();
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	// API call to fetch the deals created for today
	fetchDeals() {
		this.apiService.getDealsCreatedPipeline().subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			// console.log(arr.data)

			const filterStage = arr.data
				.map((item) => item.Stage)
				.filter((value, index, self) => self.indexOf(value) === index); // Filter duplicate stages
			// console.log(filterStage)

			//  Loop the filtered stage through the response array
			for (let i = 0; i < filterStage.length; i++) {
				let countStage = 0;
				let subTotalAmount = 0;
				let stage = '';
				for (let j = 0; j < arr.data.length; j++) {
					if (filterStage[i] === arr.data[j].Stage) {
						stage = arr.data[j].Stage; // stage name
						subTotalAmount += arr.data[j].Amount; // sum the amount of the stage occurrences
						countStage++; // count the stage occurrences
					}
				}
				this.groupedStage.push(
					new groupByStage(stage, countStage, subTotalAmount)
				); // Push the loop objects to the new array list: groupedStageInfo
			}
			// console.log(this.groupedStage)

			// ORIGINATION (Incomplete Applications, Needs Documents, Pending EC, Pending VOE, Pending Manager Approval)
			// Incomplete Applications
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Incomplete Application') {
					this.todayDealIncompleteApps.push(arr.data[i]);
					// console.log(arr.data[i].Stage)
				}
			}
			this.totalIncomplete = sumAmount(this.todayDealIncompleteApps).toFixed(2); // Total the incomplete amount
			this.countIncomplete = this.todayDealIncompleteApps.length;
			// console.log(this.todayDealIncompleteApps)
			// console.log(this.totalIncomplete)

			// Need Documents
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Needs Documents') {
					this.todayDealNeedDocs.push(arr.data[i]);
				}
			}
			this.totalDocuments = sumAmount(this.todayDealNeedDocs).toFixed(2); // Total the Documents amount
			this.countDocuments = this.todayDealNeedDocs.length;

			//Pending EC
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Pending EC') {
					this.todayDealPendingEC.push(arr.data[i]);
				}
			}
			this.totalPendingEC = sumAmount(this.todayDealPendingEC).toFixed(2); // Total the EC amount
			this.countPendingEC = this.todayDealPendingEC.length;

			//Pending VOE
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Pending VOE') {
					this.todayDealPendingVOE.push(arr.data[i]);
				}
			}
			this.totalPendingVOE = sumAmount(this.todayDealPendingVOE).toFixed(2); // Total the VOE amount
			this.countPendingVOE = this.todayDealPendingVOE.length;

			this.totalOriginationCount = totalPipeline(
				this.countIncomplete,
				this.countDocuments,
				this.countPendingEC,
				this.countPendingVOE,
				0,
				0,
				0,
				0
			);
			this.totalOriginationAmount = totalPipeline(
				this.totalIncomplete,
				this.totalDocuments,
				this.totalPendingEC,
				this.totalPendingVOE,
				0,
				0,
				0,
				0
			).toFixed(2);

			// UNDERWRITING (Budget Assessment, Ready for Loans Interview, File Review)
			// Budget Assessment
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Budget Assessment') {
					this.todayDealCreditBureau.push(arr.data[i]);
				}
			}
			this.totalCreditBureau = sumAmount(this.todayDealCreditBureau).toFixed(2); // Total the credit amount
			this.countCreditBureau = this.todayDealCreditBureau.length;

			// Ready for Loans Interview
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Ready for Loans Interview') {
					this.todayDealLoanInterview.push(arr.data[i]);
				}
			}
			this.totalLoanInterview = sumAmount(this.todayDealLoanInterview).toFixed(
				2
			); // Total the interview amount
			this.countLoanInterview = this.todayDealLoanInterview.length;

			// File Review
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'File Review') {
					this.todayDealFileReview.push(arr.data[i]);
				}
			}
			this.totalFileReview = sumAmount(this.todayDealFileReview).toFixed(2); // Total the interview amount
			this.countFileReview = this.todayDealFileReview.length;

			//Pending Manager Approval
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Pending - Manager Approval') {
					this.todayDealPendingMA.push(arr.data[i]);
				}
			}
			this.totalPendingMA = sumAmount(this.todayDealPendingMA).toFixed(2); // Total the MA amount
			this.countPendingMA = this.todayDealPendingMA.length;

			this.totalUnderwritingCount = totalPipeline(
				this.countCreditBureau,
				this.countLoanInterview,
				this.countFileReview,
				this.countPendingMA,
				0,
				0,
				0,
				0
			);
			this.totalUnderwritingAmount = totalPipeline(
				this.totalCreditBureau,
				this.totalLoanInterview,
				this.totalFileReview,
				this.totalPendingMA,
				0,
				0,
				0,
				0
			).toFixed(2);

			// CLOSING (Conditionally Approved, Approved, Agreements, Validation, Funded, Partners Funded)
			// Conditionally Approved
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Conditionally Approved') {
					this.todayDealCA.push(arr.data[i]);
				}
			}
			this.totalCA = sumAmount(this.todayDealCA).toFixed(2); // Total the CA amount
			this.countCA = this.todayDealCA.length;

			// Approved
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Approved') {
					this.todayDealApproved.push(arr.data[i]);
				}
			}
			this.totalApproved = sumAmount(this.todayDealApproved).toFixed(2); // Total the Approved amount
			this.countApproved = this.todayDealApproved.length;

			// Agreements
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Agreements') {
					this.todayDealAgreements.push(arr.data[i]);
				}
			}
			this.totalAgreements = sumAmount(this.todayDealAgreements).toFixed(2); // Total the Agreements amount
			this.countAgreements = this.todayDealAgreements.length;

			// Validation
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Validation') {
					this.todayDealValidation.push(arr.data[i]);
				}
			}
			this.totalValidation = sumAmount(this.todayDealValidation).toFixed(2); // Total the Validation amount
			this.countValidation = this.todayDealValidation.length;

			// Funded
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Funded') {
					this.todayDealFunded.push(arr.data[i]);
				}
			}
			this.totalFunded = sumAmount(this.todayDealFunded).toFixed(2); // Total the Funded amount
			this.countFunded = this.todayDealFunded.length;

			// Partners Funded
			for (let i = 0; i < arr.data.length; i++) {
				if (arr.data[i].Stage === 'Partners Funded') {
					this.todayDealPF.push(arr.data[i]);
				}
			}
			this.totalPF = sumAmount(this.todayDealPF).toFixed(2); // Total the Funded amount
			this.countPF = this.todayDealPF.length;

			this.totalClosingCount = totalPipeline(
				this.countCA,
				this.countApproved,
				this.countAgreements,
				this.countValidation,
				this.countFunded,
				this.countPF,
				0,
				0
			);
			this.totalClosingAmount = totalPipeline(
				this.totalCA,
				this.totalApproved,
				this.totalAgreements,
				this.totalValidation,
				this.totalFunded,
				this.totalPF,
				0,
				0
			).toFixed(2);
		});
	}

	// Window Refresh
	refresh() {
		window.location.reload();
		// setTimeout(function() {
		//   location.reload();
		// }, 120000);
	}
}

/**
 * Constructor to add the objects to the array
 * @param stage Stage name
 * @param count Count the stage occurence
 * @param amount Amount on the Stage name provided
 */
function groupByStage(stage: any, count: any, amount: any) {
	this.stage = stage;
	this.count = count;
	this.amount = amount;
}

// Interface to store the elements below
interface IDeals {
	Deal_Name: string;
	Amount: number;
}

/**
 *
 * @param arr Array
 * @returns Sum of Amounts
 */
function sumAmount(arr: any) {
	return arr.map((item) => item.Amount).reduce((prev, curr) => prev + curr, 0);
}

/**
 *
 * @param total1
 * @param total2
 * @param total3
 * @param total4
 * @param total5
 * @param total6
 * @param total7
 * @param total8
 * @returns Sum of the parameters passed
 */
function totalPipeline(
	total1: any,
	total2: any,
	total3: any,
	total4: any,
	total5: any,
	total6: any,
	total7: any,
	total8: any
) {
	return (
		parseFloat(total1) +
		parseFloat(total2) +
		parseFloat(total3) +
		parseFloat(total4) +
		parseFloat(total5) +
		parseFloat(total6) +
		parseFloat(total7) +
		parseFloat(total8)
	);
}
