export const dateFilterAgGrid = (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;

    if (dateAsString == null) {
        return 0;
    }

    // In the example application, dates are stored as yyyy-mm-dd
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('-');
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2]);
    const cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    return 0;
}

export const sortData = (arr: any, fieldName: any, number1: any, number2: any) => {
    return arr.sort((x, y) => x[fieldName] > y[fieldName] ? number1 : y[fieldName] > x[fieldName] ? number2 : 0);
}

export const sumNumbers = (arr?: any, fieldName?: any) => {
    return (arr.length !== 0) ? arr.map((num) => num[fieldName]).reduce((prev, next) => (prev === null ? 0 : parseFloat(prev)) + (next === null ? 0 : parseFloat(next))) : 0;
}