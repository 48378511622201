export class inveriteRequest {
	constructor(
		public application_id: string,
		public loan_number: string,
		public email: string,
		public phone: string,
		public FirstName: string,
		public LastName: string,
		public Bank: string,
		public Province: string,
		public bankid: number,
		public siteID: string,
		public application_type: string
	) {}
}
