import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { getSummaryDetails, updateSummaryDetails } from '@lmc-store/summary';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class SummaryDataService {
	zohoApplicationID: string;
	summaryData: any;
	summary$: Observable<any>;
	summaryDataSubject = new BehaviorSubject<any>({});
	userData = new BehaviorSubject<any>({});
	public summaryDataObservable = this.summaryDataSubject.asObservable();
	public userDataObservable = this.userData.asObservable();

	constructor(
		private store: Store,
		private apiService: ApiServiceService,
		private router: Router
	) {
		this.summary$ = this.store.select(getSummaryDetails);
		this.summary$.subscribe({
			next: (summaryData) => {
				this.zohoApplicationID = summaryData.id;
				this.summaryData = summaryData;
			},
			error: (err) => console.error(err),
		});
	}

	getZohoSingleAppSummary(zohoApplicationID) {
		if (this.zohoApplicationID === zohoApplicationID) {
			this.summaryDataSubject.next(this.summaryData);
		} else {
			this.apiService
				.getSingleAppSummary(zohoApplicationID)
				.subscribe((res: any) => {
					if (res['message']['statusCode'] == '200') {
						const parsedResponse = JSON.parse(res.message.body);
						const summaryData = parsedResponse.data?.[0];
						this.summaryData = summaryData;
						this.store.dispatch(updateSummaryDetails({ summary: summaryData }));
						this.summaryDataSubject.next(summaryData);
					}
				});
		}
	}

	getZohoLoggedUserDetails() {
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe(async (res: any) => {
					if (res.message === 'Success') {
						// console.log('User details', res.data?.[0]);
						this.userData.next(res.data?.[0]);
					} else {
						this.router.navigate(['login'], {
							queryParams: { returnUrl: this.router.url },
						});
					}
				});
		} else {
			this.router.navigate(['login'], {
				queryParams: { returnUrl: this.router.url },
			});
		}
	}
}
