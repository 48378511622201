<div style="padding-left: 3%;padding-top: 2%;height:100%; overflow: auto;">
    <img src="assets/images/lmcredit.png" alt="" class="mb-05">
    <p>
        LM Credit Inc. ("LM CREDIT", "we", ''us" and "our"), in accordance with our privacy policy and our obligations
        at
        law, may
        collect, receive, retain, use and disclose personal information about you, our client, for the purposes of:
        verification; assessing
        credit-worthiness; establishing credit limits; maintaining client relationships; presenting your unsecured loan
        application to
        various lenders and insurers and providing information to you about other products and services that may be of
        interest to
        you. We may also disclose your personal information to (i) any potential purchaser of our business and their
        advisors, (ii) any
        third-party service providers to whom we may outsource our business functions and (iii) any parties involved in
        the
        securitization, assignment or pledge of a loan that is obtained through us.
    </p>
    <p>
        By signing this form, you are providing your acknowledgement and express consent to LM Credit and our agent:
    </p>
    <ol>
        <li>
            that the information given in the loan application is true and correct
        </li>
        <li>
            to collect, use, retain and disclose your personal information for seven (7) years following the later of a)
            the date
            of your latest application with us, or b) the date on which your loans we have arranged for you have expired
            or
            been terminated;
        </li>
        <li>
            to inquire about and receive your personal information from: consumer reporting agencies, credit bureaus,
            your
            bank(s) or other financial institutions with whom you deal, your present and past employers and other third
            parties who may have information about your financial status;
        </li>
        <li>
            that the approval or granting of any loan by a lender to you, as arranged by LM CREDIT, is not to be
            construed or
            relied on by you as representing the value or condition of any underlying security or representing that you
            have
            the ability to repay the arranged loan amount;
        </li>
        <li>
            to receive commercial electronic messages and other forms of business information, communications and
            marketing regarding products and services that may be of interest to you;
        </li>
        <li>
            that LM CREDIT and our agent may receive fees or incentives from a lender for arranging a loan for you. Fees
            and incentives include but are not limited to money, points or goods and services. Fees and incentives may
            vary
            between lenders for similar products and may also be dependent on the amounts and types of loan products
            originated.
        </li>
        <li>
            that LM CREDIT and our agent have not provided you with any investment advice or services and has advised
            you
            of the need for independent financial and tax planning advice from a qualified professional.
        </li>
    </ol>
    <p>
        CLIENT EXPRESS CONSENT & ACKNOWLEDGEMENT:
    </p>
    <p>
        <input [(ngModel)]="isAccepted" type="checkbox" name="isAccepted" />
        <strong>I hereby acknowledge the information disclosed above and provide my express consent</strong>
    </p>
    <div>
        Client Name: {{model.first_name}} {{model.middle_name}} {{model.last_name}}
    </div>
    <p>
        Date: {{today_date}}
    </p>
    <p>
        LM Credit Inc. collects personal information, including your name. address. telephone number, Social Insurance
        number,
        income and other financial information, banking. vehicle, and reference information to confirm your identity and
        qualify you for
        the services for which you have applied. With your authorization, LM Credit Inc. may share this information with
        third party
        collection agencies to whom you are indebted to assist in the collection or negotiation of bad debts.
    </p>
    <p>
        By completing this form, you acknowledge that you authorize LM Credit Inc. to discuss these accounts with these
        third-party
        collection agencies and· to send and receive information on your behalf.
    </p>
    <button class="buttonSubmit" (click)="onSubmit()">Submit</button>
    <hr>
    LM Credit INC. 33 City Centre Drive . Suite 250 Mississauga, Ontario . L5B 2N5
</div>