<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img
				title="refresh"
				src="/assets/images/refresh.png"
				width="30"
				height="30"
				(click)="refresh()"
			/>
		</h5>
	</div>

	<!-- <div class="cardContainer">
        <div class="cardContainer-scroll">
            <div class="card">
                <div class="container">
                <h4><strong>Underwriting Queue</strong></h4>
                <h2><strong>{{+readyLoanInterview + +totalCredit + +totalFileReview}}</strong></h2>
                <h2><strong>{{+totalRLIAmount + +totalCBAmount + +totalFRAmount | currency}}</strong></h2>
                </div>
            </div>
            <div class="card" id ="callWidth">
                <div class="container">
                <h4><strong>Call Attempts</strong></h4>
                <h2><strong>0: {{zeroInterviewAttempts}} | 1: {{oneInterviewAttempts}}</strong></h2>
                <h2><strong>1 < {{moreInterviewAttempts}}</strong></h2>
                </div>
            </div>
        </div>
    </div> -->

	<ul class="tabs" role="tablist">
		<li>
			<input type="radio" name="tabs" id="tab1" checked />
			<label
				for="tab1"
				role="tab"
				aria-selected="true"
				aria-controls="panel2"
				tabindex="0"
				>Ready for Underwriting<br />
				<span class="totalRowPipeline"
					>[{{ totalCredit }}] {{ totalCBAmount | currency }}</span
				>
			</label>
			<div
				id="tab-content1"
				class="tab-content"
				role="tabpanel"
				aria-labelledby="description"
				aria-hidden="false"
			>
				<div class="row row-cols-12">
					<div class="col-6">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Modified Time</th>
									<th>Name</th>
									<th>Agency</th>
									<th>Drive</th>
								</tr>
							</thead>
							<tbody>
								<div
									*ngIf="
										!creditArray || creditArray.length === 0;
										else contentCB
									"
								>
									No Ready for Underwriting!
								</div>
								<ng-template #contentCB>
									<tr *ngFor="let item of creditArray">
										<td>{{ item.Modified_Time | date : 'medium' }}</td>
										<td>
											<a
												class="noline"
												target="_self"
												[routerLink]="['/applications/summary', item.id]"
												[queryParams]="{ dealname: item.Deal_Name }"
												>{{ item.Deal_Name }}</a
											>
										</td>
										<td>{{ item.Agency_Formula }}</td>
										<td>
											<a
												class="noline"
												target="_self"
												href="{{ item.gdriveextension__Drive_URL }}"
												>Open</a
											>
										</td>
									</tr>
								</ng-template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</li>

		<li>
			<input type="radio" name="tabs" id="tab2" />
			<label
				for="tab2"
				role="tab"
				aria-selected="true"
				aria-controls="panel2"
				tabindex="0"
				>Underwriting Complete<br />
				<span class="totalRowPipeline"
					>[{{ totalUC }}] {{ totalUCAmount | currency }}</span
				>
			</label>
			<div
				id="tab-content2"
				class="tab-content"
				role="tabpanel"
				aria-labelledby="description"
				aria-hidden="false"
			>
				<div class="row row-cols-12">
					<div class="col-6">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Modified Time</th>
									<th>Name</th>
									<th>Agency</th>
								</tr>
							</thead>
							<tbody>
								<div
									*ngIf="
										!underwritingCompleteArray ||
											underwritingCompleteArray.length === 0;
										else contentFR
									"
								>
									No Underwriting Complete!
								</div>
								<ng-template #contentFR>
									<tr *ngFor="let item of underwritingCompleteArray">
										<td>{{ item.Modified_Time | date : 'medium' }}</td>
										<td>
											<a
												class="noline"
												target="_self"
												[routerLink]="['/applications/summary', item.id]"
												[queryParams]="{ dealname: item.Deal_Name }"
												>{{ item.Deal_Name }}</a
											>
										</td>
										<td>{{ item.Agency_Formula }}</td>
									</tr>
								</ng-template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</li>

		<li>
			<input type="radio" name="tabs" id="tab3" />
			<label
				for="tab3"
				role="tab"
				aria-selected="true"
				aria-controls="panel3"
				tabindex="0"
				>CALL QUEUE<br />
				<span class="nothing">CALL QUEUE</span>
			</label>
			<div
				id="tab-content3"
				class="tab-content"
				role="tabpanel"
				aria-labelledby="description"
				aria-hidden="false"
			>
				<iframe class="responsive-iframe" id="ooma" height="800px"></iframe>
			</div>
		</li>
	</ul>
</div>
