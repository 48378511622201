<div class="container-fluid">
	<div class="row row-cols-12">
		<!-- <h5>{{timestamp | date:'medium'}}<img title="refresh" src="/assets/images/refresh.png" width="30" height="30" (click)="refresh()"></h5>  -->
	</div>

	<div class="col-sm-12">
		<h5>NLS Collections</h5>
	</div>
	<div class="cardContainer">
		<div class="cardContainer-scroll">
			<div class="card" id="newAppsCard">
				<div class="container">
					<h4 class="newAppsFont"><strong>NLS Collections</strong></h4>
					<h2>
						<strong
							>{{ countNLScollections }} ({{
								(
									(countNLScollections / countOverallCollections) *
									100
								).toFixed()
							}}%)</strong
						>
					</h2>
					<h3 class="newAppsFont">
						<strong
							>{{ totalNLScollectionAmount | currency }} ({{
								(
									(totalNLScollectionAmount / totalOverallAmount) *
									100
								).toFixed()
							}}%) | ({{
								(
									(totalNLScollectionDefaultAmount / totalOverallAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>1 - 30</strong></h4>
					<h2>
						<strong
							>{{ count_one_to_thirty }} ({{
								((count_one_to_thirty / countNLScollections) * 100).toFixed()
							}}%)</strong
						>
					</h2>
					<h3>
						<strong
							>{{ amount_one_to_thirty | currency }} ({{
								(
									(amount_one_to_thirty / totalNLScollectionAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>31 - 60</strong></h4>
					<h2>
						<strong
							>{{ count_thirtyone_to_sixty }} ({{
								(
									(count_thirtyone_to_sixty / countNLScollections) *
									100
								).toFixed()
							}}%)</strong
						>
					</h2>
					<h3>
						<strong
							>{{ amount_thirtyone_to_sixty | currency }} ({{
								(
									(amount_thirtyone_to_sixty / totalNLScollectionAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>61 - 90</strong></h4>
					<h2>
						<strong
							>{{ count_sixtyone_to_ninety }} ({{
								(
									(count_sixtyone_to_ninety / countNLScollections) *
									100
								).toFixed()
							}}%)</strong
						>
					</h2>
					<h3>
						<strong
							>{{ amount_sixtyone_to_ninety | currency }} ({{
								(
									(amount_sixtyone_to_ninety / totalNLScollectionAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>91 - 120</strong></h4>
					<h2>
						<strong
							>{{ count_ninetyone_to_onetwenty }} ({{
								(
									(count_ninetyone_to_onetwenty / countNLScollections) *
									100
								).toFixed()
							}}%)</strong
						>
					</h2>
					<h3>
						<strong
							>{{ amount_ninetyone_to_onetwenty | currency }} ({{
								(
									(amount_ninetyone_to_onetwenty / totalNLScollectionAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>121 - 365</strong></h4>
					<h2>
						<strong
							>{{ count_onetwentyone_to_threesixtyfive }} ({{
								(
									(count_onetwentyone_to_threesixtyfive / countNLScollections) *
									100
								).toFixed()
							}}%)</strong
						>
					</h2>
					<h3>
						<strong
							>{{ amount_onetwentyone_to_threesixtyfive | currency }} ({{
								(
									(amount_onetwentyone_to_threesixtyfive /
										totalNLScollectionAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>365+</strong></h4>
					<h2>
						<strong
							>{{ count_threesixtyfive_plus }} ({{
								(
									(count_threesixtyfive_plus / countNLScollections) *
									100
								).toFixed()
							}}%)</strong
						>
					</h2>
					<h3>
						<strong
							>{{ amount_threesixtyfive_plus | currency }} ({{
								(
									(amount_threesixtyfive_plus / totalNLScollectionAmount) *
									100
								).toFixed()
							}}%)</strong
						>
					</h3>
				</div>
			</div>
		</div>
	</div>
	<div class="cardContainer">
		<div class="cardContainer-scroll">
			<div class="card" id="newAppsCard">
				<div class="container">
					<h4 class="newAppsFont"><strong>Insolvency</strong></h4>
					<h2>
						<strong>{{ count_insolvency }}</strong>
					</h2>
					<h3 class="newAppsFont">
						<strong>{{ amount_insolvency | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>CP</strong></h4>
					<h2>
						<strong>{{ count_cp }}</strong>
					</h2>
					<h3>
						<strong>{{ amount_cp | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>CC</strong></h4>
					<h2>
						<strong>{{ count_cc }}</strong>
					</h2>
					<h3>
						<strong>{{ amount_cc | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>BK</strong></h4>
					<h2>
						<strong>{{ count_bk }}</strong>
					</h2>
					<h3>
						<strong>{{ amount_bk | currency }}</strong>
					</h3>
				</div>
			</div>
		</div>
	</div>
	<div class="cardContainer">
		<div class="cardContainer-scroll">
			<div class="card" id="newAppsCard">
				<div class="container">
					<h4 class="newAppsFont"><strong>Third Party Assigned</strong></h4>
					<h2>
						<strong>{{ count_third_party }}</strong>
					</h2>
					<h3 class="newAppsFont">
						<strong>{{ amount_third_party | currency }}</strong>
					</h3>
				</div>
			</div>
		</div>
	</div>
	<div class="cardContainer">
		<div class="cardContainer-scroll">
			<div class="card" id="newAppsCard">
				<div class="container">
					<h4 class="newAppsFont"><strong>Deceased</strong></h4>
					<h2>
						<strong>{{ count_deceased }}</strong>
					</h2>
					<h3 class="newAppsFont">
						<strong>{{ amount_deceased | currency }}</strong>
					</h3>
				</div>
			</div>
		</div>
	</div>
</div>
