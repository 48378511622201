<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>Agency: {{ agencyName }}</h5>
		<h6>
			Total Count: {{ countAgencyMonth }} | Total Amount:
			{{ totalAgencyfundedMonth | currency }}
		</h6>
	</div>
	<div>
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>App. Name</th>
					<th>Creditor</th>
					<th>Approved</th>
					<th>Override</th>
					<th>Override Type</th>
					<th>Exception</th>
					<th>Closing</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of agencyIssuedArray">
					<td>
						<a
							class="noline"
							target="_blank"
							href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
								item.id
							}}"
							>{{ item.Deal_Name }}</a
						>
					</td>
					<td>{{ item.Creditt }}</td>
					<td>{{ item.Approved_Amount | currency }}</td>
					<td>{{ item.Underwriting_Override }}</td>
					<td>{{ item.Override_Type }}</td>
					<td>{{ item.Exception_Funded_Amount | currency }}</td>
					<td>{{ item.Closing_Date }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
