export const environment = {
  production: false,
  DO_URL: 'https://lmc-staging.xyz/api',

  version: 'v3.1.9',
  iframeURL: 'https://lmc.lol',
  accountsEmail: 'testing@lmcredit.com',

  // AWS
  AWS_ACCESS_KEY_ID: 'AKIAXYX3H6GWVPZAPNNL',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'us-east-1',
  AWS_BUCKET: 'lmcredit-client-do-backup',
  AWS_ACL: 'public-read',
};
