import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { UserOptions } from 'jspdf-autotable';
import { Controls } from '@lmc-models/controls';
export enum ArrangementType {
	PayOff = 'pay_off',
	PayDown = 'pay_down',
	PayOffExcludeNSF = 'pay_off_exclude_nsf'
}
interface jsPDFWIthPlugin extends jsPDF {
	autoTable: (options: UserOptions) => jsPDF;
}

@Component({
	selector: 'app-promise-pay',
	templateUrl: './promise-pay.component.html',
	styleUrls: ['./promise-pay.component.scss'],
})
export class PromisePayComponent implements OnInit, OnChanges {
	public ArrangementType = ArrangementType;
	Loan_Details: any;
	PtP_Amount: number;
	PtP_Date;
	Comments = '';
	payment_type = '';
	arrangementType = '';
	payoffRequestedDate: any;
	payoffDownloadData: any;
	payoffRequestedInfo: any;
	showDownloadPayoff: boolean;
	showPayoffCard: boolean;
	dpd_save_button_disable = false;

	client_info: any;
	FollowupDate_Formatted: any;
	btnPTPpermissionsIDs: any;		// Save PTP
	btnPayOffpermissionIDs: any;	// Payoffs permission IDs
	@Input() data?: any;
	@Input() collection_info?: any;
	@Input() meta?: any;
	@Input() existing_PTP?: any;
	@Input() Collector?: any;
	@Input() type?: any;
	@Input() zoho_user_id?: any;
	@Input() promisesFlag?: any;
	@Input() empID?: any;
	@Input() totalPTPCreated?: any;
	myFilter = (d: Date | null): boolean => {
		const day = (d || new Date()).getDay();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	};
	controls = new Controls();
	constructor(
		private apiService: ApiServiceService,
		public datepipe: DatePipe
	) { }
	ngOnChanges(changes: SimpleChanges): void {
		if (changes && this.promisesFlag) {
			// If save button permission grant then enable ptp save button
			if (this.btnPTPpermissionsIDs?.includes(this?.empID)) {
				this.dpd_save_button_disable = false;
			} else {
				(this.meta?.['total_due'] === '0' && this.collection_info?.Days_Missed === 0) ? this.dpd_save_button_disable = true : this.dpd_save_button_disable = false;
			}
			this.totalPTPCreated = this.totalPTPCreated ?? 0;
			this.clearPTPInputs();
			document.getElementById('savePtpMsg').style.display = 'none';
		}
	}
	ngOnInit() {
		this.apiService
			.getFollowUpDate(this.data?.[0]?.Loan_Number)
			.subscribe((res) => {
				console.log(res);
				let fdate = res?.[0]?.['Followup_Date'];
				if (fdate != null && fdate != '0000-00-00 00:00:00') {
					let date = new Date(fdate);
					date.setHours(date.getHours() - 0); //We need to change this for dalylight savings time
					this.FollowupDate_Formatted = date.toISOString().slice(0, -5); //removing the last charcters for it to support the datepicker
				} else {
					this.FollowupDate_Formatted = null;
				}
			});
		// Get the btn permission IDs
		this.apiService.getBtnPermission().subscribe((res) => {
			this.btnPTPpermissionsIDs = res?.[0]?.ptp_save_btn;
			this.btnPayOffpermissionIDs = res?.[0]?.pay_off_download;
		})
	}

	datesAreOnSameDay(first, second) {
		if (
			first.getFullYear() === second.getFullYear() &&
			first.getMonth() === second.getMonth() &&
			first.getDate() === second.getDate()
		)
			return true;
		else return false;
	}

	createPayoffStatement() {
		if (!this.btnPayOffpermissionIDs?.includes(this?.empID) && (!this.PtP_Date || (this.PtP_Date < new Date() && !this.datesAreOnSameDay(this.PtP_Date, new Date())))) {
			alert('Not a valid Payoff Date');
		} else {
			let payoff_date = this.datepipe.transform(this.PtP_Date, 'yyyy-MM-dd');
			this.payoffRequestedDate = payoff_date;
			this.apiService
				.getPayoffStatment(this.collection_info?.Loan_Number)
				.subscribe(async (res: any[]) => {
					this.client_info = res;
					let payoff_info = await this.getPayoffInfo(
						this.collection_info?.acctrefno,
						payoff_date
					);
					this.payoffRequestedInfo = payoff_info;
					this.PtP_Amount = this.arrangementType == 'pay_off' ?
						this.payoffRequestedInfo?.['PayoffAmount'] :
						(this.payoffRequestedInfo?.['PayoffAmount'] - this.meta?.eff_fees_balance);
					let direct_deposit_name = '';
					switch (this.meta.account_no) {
						case 1974017: {
							direct_deposit_name = '12029944 Canada Inc';
							break;
						}
						case 1970876: {
							direct_deposit_name = 'LM CREDIT Private Debt Fund';
							break;
						}
						case 1971158: {
							direct_deposit_name = 'LM CREDIT INC';
							break;
						}
						case 1964476: {
							direct_deposit_name = 'LM CREDIT PRIVATE DEBT 1 LIMITED PARTN';
							break;
						}
						default: {
							direct_deposit_name = 'INVALID LOAN GROUP';
						}
					}
					this.payoffDownloadData = new jsPDF(
						'portrait',
						'px',
						'a4'
					) as jsPDFWIthPlugin;
					// let imgData = 'C:/Users/it/Documents/GitLMCredit/LMCreditExternal/src/assets/images/lmcredit.jpeg';
					// this.payoffDownloadData.addImage(imgData, "JPEG", 40, 20, 100, 50)
					this.payoffDownloadData.setFontSize(12);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
						40,
						45
					);
					this.payoffDownloadData.text(this.collection_info?.Name, 40, 65);
					this.payoffDownloadData.text(
						this.client_info[0]['Debtor Address']
							.concat(' ')
							.concat(this.client_info[0]['Debtor City']),
						40,
						75
					);
					this.payoffDownloadData.text(
						this.client_info[0]['Debtor Province']
							.concat(' ')
							.concat(this.client_info[0]['Debtor Postal Code']),
						40,
						85
					);
					this.payoffDownloadData.text(
						'Dear ' + this.collection_info?.Name + ',',
						40,
						105
					);
					this.payoffDownloadData.text(
						`The following table is a breakdown of the funds required to pay off your loan. You are authorized to use`,
						40,
						125
					);
					this.payoffDownloadData.text(
						'the following amounts to pay off the above-mentioned loan. All necessary legal documents will be',
						40,
						135
					);
					this.payoffDownloadData.text(
						'forwarded to the trustee for full reconveyance upon receipt of payment in full.',
						40,
						145
					);
					this.payoffDownloadData.text(
						'Account Number: ' + this.client_info[0]['loan_number'],
						40,
						165
					);
					this.payoffDownloadData.text(
						'Loan Date: ' + this.meta['origination_date'],
						40,
						185
					);
					this.payoffDownloadData.text(
						'Maturity Date: ' + this.client_info[0]['curr_maturity_date'],
						40,
						205
					);
					this.payoffDownloadData.text('Payoff Date: ' + payoff_date, 40, 225);
					this.payoffDownloadData.text(
						'Principal Balance: $' + payoff_info['PrincipalBalance'],
						40,
						245
					);
					this.payoffDownloadData.text(
						'Accrued Interest: $' + payoff_info['AccruedInterest']?.toFixed(2),
						40,
						265
					);
					this.payoffDownloadData.text(
						'Insurance: $' + this.meta?.insurance,
						40,
						285
					);
					const displayNSF = this.arrangementType === 'pay_off' ? this.payoffDownloadData.text('NSF Fees: $' + this.meta?.eff_fees_balance, 40, 305) : '';
					const adjustLineSpacePerDiem = this.arrangementType === 'pay_off' ?
						this.payoffDownloadData.text(
							'Per Diem: $' + payoff_info['PerDiem']?.toFixed(2),
							40,
							325
						) : this.payoffDownloadData.text(
							'Per Diem: $' + payoff_info['PerDiem']?.toFixed(2),
							40,
							305
						);
					this.payoffDownloadData.setFont('Roman', 'bold');
					const adjustLineSpacePayOffAmount = this.arrangementType === 'pay_off' ?
						this.payoffDownloadData.text(
							'Payoff Amount: $' + payoff_info['PayoffAmount'],
							40,
							345
						) :
						this.payoffDownloadData.text(
							'Payoff Amount: $' + (this.payoffRequestedInfo?.['PayoffAmount'] - this.meta?.eff_fees_balance),
							40,
							325
						);

					//missing insurance premium and ins tax
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text('Payment options include: ', 40, 365);
					this.payoffDownloadData.setFont('Roman', 'bold');
					this.payoffDownloadData.text(
						'--written consent by mail or email',
						40,
						385
					);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						'to customerservice@lmcredit.com authorizing LM CREDIT Inc.',
						174,
						385
					);
					this.payoffDownloadData.text(
						'to debit this outstanding balance from the preauthorized account on file.',
						40,
						395
					);
					this.payoffDownloadData.setFont('Roman', 'bold');
					this.payoffDownloadData.text('--e-transfer', 40, 415);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						'to payments@lmcredit.com. The security answer to the e-transfer must be',
						87,
						415
					);
					this.payoffDownloadData.setFont('Roman', 'bold');
					// this.payoffDownloadData.setFillColor(255, 0, 0)
					this.payoffDownloadData.text('financial', 357, 415);
					// this.payoffDownloadData.setFillColor(0, 0, 0)
					this.payoffDownloadData.text(
						'Direct deposit /payable to ' + direct_deposit_name,
						40,
						435
					);
					this.payoffDownloadData.text(
						'INSTITUTION NUMBER: 0001 (BMO)',
						40,
						445
					);
					this.payoffDownloadData.text('TRANSIT NUMBER: 38582', 40, 455);
					this.payoffDownloadData.text(
						'ACCOUNT NUMBER: ' + this.meta.account_no,
						40,
						465
					);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						'To ensure your payment is processed in a timely manner, please send a copy of the receipt to', 40, 485);
					this.payoffDownloadData.setFont('Roman', 'bold');
					this.payoffDownloadData.text('payments@lmcredit.com', 40, 495);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						'. Once the receipt is received, the payment will be processed and applied to',
						136,
						495
					);
					this.payoffDownloadData.text(
						'the account accordingly.',
						40,
						505
					);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						'If payment is made via certified cheque or bank draft, please include a picture of the item along with the ',
						40,
						525
					);
					this.payoffDownloadData.text(
						'picture of the deposit receipt.',
						40,
						535
					);
					// this.payoffDownloadData.setFont('Roman', 'bold');
					// this.payoffDownloadData.text('payments@lmcredit.com', 40, 535);
					this.payoffDownloadData.setFont('Roman', 'normal');
					this.payoffDownloadData.text(
						'We reserve the right to amend this statement should any changes occur that would increase the total',
						40,
						555
					);
					this.payoffDownloadData.text('amount for payoff.', 40, 565);
					this.payoffDownloadData.text('Thank you,', 40, 585);
					this.payoffDownloadData.text('LM CREDIT INC.', 40, 605);
					this.payoffDownloadData.setFontSize(9);
					this.payoffDownloadData.setFont('Roman', 'italic');
					this.payoffDownloadData.text(
						'T: 1-833-301-1058  F: 1-866-509-0341  A: 1 CITY CENTRE DRIVE, UNIT 801, MISSISSAUGA, ON, L5B 1M2',
						40,
						625
					);
					this.showDownloadPayoff = true;
				});
		}
	}

	clearPTPInputs() {
		this.PtP_Date = '';
		this.PtP_Amount = 0;
		this.payment_type = '';
		this.Comments = '';

	}

	downloadPayoffData() {
		this.payoffDownloadData.save(
			this.collection_info['Name'] + ' Payoff Statement.pdf'
		);
		this.payoffDownloadData = new jsPDF(
			'portrait',
			'px',
			'a4'
		) as jsPDFWIthPlugin;
	}
	async getPayoffInfo(acctrefno, payoff_date) {
		return new Promise((resolve, reject) => {
			this.apiService.getPayoffInfo(acctrefno, payoff_date).subscribe((res) => {
				resolve(res);
			});
		});
	}

	addWorkDays(startDate, days) {
		// Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
		var dow = startDate.getDay();
		var daysToAdd = parseInt(days);
		// If the current day is Sunday add one day
		if (dow == 0) daysToAdd++;
		// If the start date plus the additional days falls on or after the closest Saturday calculate weekends
		if (dow + daysToAdd >= 6) {
			//Subtract days in current working week from work days
			var remainingWorkDays = daysToAdd - (5 - dow);
			//Add current working week's weekend
			daysToAdd += 2;
			if (remainingWorkDays > 5) {
				//Add two days for each working week by calculating how many weeks are included
				daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
				//Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
				if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
			}
		}
		startDate.setDate(startDate.getDate() + daysToAdd);
		console.log(startDate);
		return startDate;
	}
	createPtP() {
		let ptpDate = new Date(this.PtP_Date);
		let ptp_check;
		let today_date = new Date();
		let two_days = new Date();
		let three_days = new Date();
		let ptp_already_exists = false;
		for (var i = 0; i < this.existing_PTP.length; i++) {
			if (!this.existing_PTP[i].nls_data) {
				ptp_check = this.existing_PTP[i].PtP_Date.split('-')
					.reverse()
					.join('/');
				ptp_check = new Date(ptp_check);
				if (
					ptp_check.getFullYear() == ptpDate.getFullYear() &&
					ptp_check.getMonth() == ptpDate.getMonth() &&
					ptp_check.getDate() == ptpDate.getDate() &&
					this.payment_type == 'manual_debit'
				) {
					ptp_already_exists = true;
					break;
				}
			}
		}
		three_days = this.addWorkDays(new Date(), 2);
		two_days = this.addWorkDays(new Date(), 1);
		if (!this.PtP_Date) {
			alert('Cannot create Promise to Pay without a Date');
		} else if (ptp_already_exists)
			alert('A PTP already exisits with this date');
		else if (!this.btnPTPpermissionsIDs?.includes(this?.empID) && this.type === 'ptp' && ((parseFloat(this.totalPTPCreated) + this.PtP_Amount) > this.collection_info?.['total_current_due'])) {
			alert('Total PTP created $' + (parseFloat(this.totalPTPCreated) + this.PtP_Amount) + ' cannot be more than Total Current Due $' + this.collection_info?.['total_current_due']);
		}
		else if (today_date.getHours() >= 18) {
			console.log('IT IS TIME');
			if (three_days > ptpDate && this.payment_type == 'manual_debit')
				alert("It's passed 6pm, PTP needs to be at least 3 days from today");
			else if (two_days > ptpDate && this.payment_type == 'manual_debit') {
				alert('PTP needs to be at least two days from today');
			} else if (!this.PtP_Amount || this.PtP_Amount == 0) {
				alert('A PTP needs an Amount!');
			} else {
				this.dpd_save_button_disable = true;
				this.apiService
					.createPTP(
						this.collection_info,
						this.data,
						this.PtP_Date,
						this.Comments,
						this.PtP_Amount?.toFixed(2),
						this.Collector,
						this.payment_type,
						this.type,
						this.arrangementType
					)
					.subscribe((res) => {

						this.controls.displayMessage(
							'savePtpBtn',
							true,
							'savePtpMsg',
							'Save in progress...',
							'orange',
							'block'
						);
						if (res?.['result'] === 'duplicate') {
							this.controls.displayMessage(
								'savePtpBtn',
								false,
								'savePtpMsg',
								'',
								'green',
								'block'
							);
							alert("Duplicate Promise to Pay!");
						} else {
							this.apiService.sendClickEvent();
							this.controls.displayMessage(
								'savePtpBtn',
								false,
								'savePtpMsg',
								'Save successful',
								'green',
								'block'
							);
							this.dpd_save_button_disable = false;
							this.clearPTPInputs();
						}
					});
			}
		} else if (two_days > ptpDate && this.payment_type == 'manual_debit') {
			alert('PTP needs to be at least two days from today');
		} else if (!this.Collector) {
			alert('You must specify the Collector responsible for this PTP');
		} else if (!this.PtP_Amount || this.PtP_Amount == 0) {
			alert('A PTP needs an Amount!');
		} else {
			this.dpd_save_button_disable = true;
			this.apiService
				.createPTP(
					this.collection_info,
					this.data,
					this.PtP_Date,
					this.Comments,
					this.PtP_Amount?.toFixed(2),
					this.Collector,
					this.payment_type,
					this.type,
					this.arrangementType
				)
				.subscribe((res) => {
					this.controls.displayMessage(
						'savePtpBtn',
						true,
						'savePtpMsg',
						'Save in progress...',
						'orange',
						'block'
					);
					if (res?.['result'] === 'duplicate') {
						this.controls.displayMessage(
							'savePtpBtn',
							false,
							'savePtpMsg',
							'',
							'green',
							'block'
						);
						alert("Duplicate Promise to Pay!");
					} else {
						this.apiService.sendClickEvent();
						this.controls.displayMessage(
							'savePtpBtn',
							false,
							'savePtpMsg',
							'Save successful',
							'green',
							'block'
						);
						this.dpd_save_button_disable = false;
						this.clearPTPInputs();
					}
				});
		}
	}
}
