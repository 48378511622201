import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'app/api-service.service';
import { GeneralObjects } from 'app/models/globalObjects';

@Component({
  selector: 'app-collection-tv-report',
  templateUrl: './collection-tv-report.component.html',
  styleUrls: ['./collection-tv-report.component.scss']
})
export class CollectionTvReportComponent {
  getMonthName = new GeneralObjects();

  currentMonth: any;
  targetAmount: any;
  collectedAmount: any;
  collectedPercent: any;
  daysCountdown: any;
  
  total_debenture_less_ninety: any;
  percent_debenture_less_ninety: any;
  current_debenture_ninety_plus: any;
  percent_debenture_ninety_plus: any;

  total_mft_less_ninety: any;
  percent_mft_less_ninety: any;
  current_mft_ninety_plus: any;
  percent_mft_ninety_plus: any;

  total_qvest_less_ninety: any;
  percent_qvest_less_ninety: any;
  current_qvest_ninety_plus: any;
  percent_qvest_ninety_plus: any;

  total_spv_less_ninety: any;
  percent_spv_less_ninety: any;
  current_spv_ninety_plus: any;
  percent_spv_ninety_plus: any;

  constructor(private apiService: ApiServiceService) {}

  async ngOnInit(): Promise<void> {
    let today = new Date();
    this.currentMonth = this.getMonthName.monthNames[today.getMonth()];
    let daysInMonth = getDays(today.getFullYear(), today.getMonth()+1);
    let calCountDown = daysInMonth - today.getDate();
    this.daysCountdown = (calCountDown === 0 ? "Last Day" : calCountDown + ' Days left');
    
    let result = await this.getTargetCollected().then(value=>this.targetAmount = value)
    this.apiService.getCurrentMonthCollectionTV().subscribe((res:any)=>{
      if(res['status']){
        if(res['data'].Length !==0){
          this.collectedAmount = res['data']?.[0]?.['Collected'];
          this.collectedPercent = ((this.collectedAmount / this.targetAmount)*100).toFixed(2)
        }else{
          this.collectedAmount=0
        }
      }else{
        console.log(res)
      }
    });

    let result1 = await this.getTVCollectionGroups('debenture').then(value=>{
      this.total_debenture_less_ninety = value?.['total_current_due_balance_less_ninety'];
      this.percent_debenture_less_ninety = value?.['percent_total_current_due_balance_less_ninety'];
      this.current_debenture_ninety_plus = value?.['current_principal_balance_ninety_plus'];
      this.percent_debenture_ninety_plus = value?.['percent_current_principal_balance_ninety_plus'];
    });

    let result2 = await this.getTVCollectionGroups('mft').then(value=>{
      this.total_mft_less_ninety = value?.['total_current_due_balance_less_ninety'];
      this.percent_mft_less_ninety = value?.['percent_total_current_due_balance_less_ninety'];
      this.current_mft_ninety_plus = value?.['current_principal_balance_ninety_plus'];
      this.percent_mft_ninety_plus = value?.['percent_current_principal_balance_ninety_plus'];
    });

    let result3 = await this.getTVCollectionGroups('qvest').then(value=>{
      this.total_qvest_less_ninety = value?.['total_current_due_balance_less_ninety'];
      this.percent_qvest_less_ninety = value?.['percent_total_current_due_balance_less_ninety'];
      this.current_qvest_ninety_plus = value?.['current_principal_balance_ninety_plus'];
      this.percent_qvest_ninety_plus = value?.['percent_current_principal_balance_ninety_plus'];
    });

    let result4 = await this.getTVCollectionGroups('spv').then(value=>{
      this.total_spv_less_ninety = value?.['total_current_due_balance_less_ninety'];
      this.percent_spv_less_ninety = value?.['percent_total_current_due_balance_less_ninety'];
      this.current_spv_ninety_plus = value?.['current_principal_balance_ninety_plus'];
      this.percent_spv_ninety_plus = value?.['percent_current_principal_balance_ninety_plus'];
    });
  }

  async getTargetCollected(){
    return new Promise((resolve,reject)=>{
      this.apiService.getTargetVariables().subscribe((res)=>{
        resolve(res['data']?.[0]?.['monthly_collection_target'])
      })
    })
  }

  async getTVCollectionGroups(loanGroupName){
    return new Promise((resolve,reject)=>{      
      this.apiService.getTVCollectionGroups(loanGroupName).subscribe((res)=>{
        console.log(res)
        resolve(res?.['data']?.[0])
      })
    })
  }

}

const getDays = (year, month) => {
  return new Date(year, month, 0).getDate();
};


