import { Component, OnInit } from '@angular/core';
import { now } from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';

@Component({
	selector: 'app-nlsagencycollectors',
	templateUrl: './nlsagencycollectors.component.html',
	styleUrls: ['./nlsagencycollectors.component.scss'],
})
export class NlsagencycollectorsComponent implements OnInit {
	constructor(
		private router: ActivatedRoute,
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private _routerLink: Router
	) {}

	agencyName: string;
	monthNum = new Date(now()).getMonth();
	collectorsArray: any;

	countCollectedMonth: any;
	totalCollectedfundedMonth: any;

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						// Get the collector name from the nlsAgencyFunded page
						this.router.queryParams.subscribe((params) => {
							(this.agencyName = params['agency']),
								(this.monthNum = params['monthNum']);
						});

						// Send the Agency name to get the collectors listed under the agency
						this.apiService
							.getNLSAgencyCollectors(this.agencyName, this.monthNum)
							.subscribe((res) => {
								this.collectorsArray = res.data;

								this.countCollectedMonth = this.customFunction.sumNumbers(
									this.collectorsArray,
									'CollecterCount'
								);
								this.totalCollectedfundedMonth = this.customFunction.sumNumbers(
									this.collectorsArray,
									'TotalAmount'
								);
							});
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}
}
