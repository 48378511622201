import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-loan-validation',
  templateUrl: './loan-validation.component.html',
  styleUrls: ['./loan-validation.component.scss']
})
export class LoanValidationComponent implements OnInit {
  validated_result
  validation_errors
  NLS_errors
  NLS_errors_flag = false
  loan_errors
  showUploadLoan = true
  showUploadClients = true
  showUploadRecurring = true
  confirmation_message = false


  constructor(private apiService: ApiServiceService, private activatedRouter: ActivatedRoute) { }
  ngOnInit(): void {
    this.getZohoApplication()
  }
  async getZohoApplication() {
    //a change in the preapproval-branch
    let testing_url = "https://betty-alpha.web.app/forms/loan-validation?City=&Application_ID=2390707000244524027&UserId=2390707000063682025&version=new" //Test Multiple Applications
    let URI = window.location.href
    let decoded = decodeURI(URI)//URI//testing_url
    const urlParams = new URLSearchParams(decoded);
    if (urlParams.get('version') == 'new') {
      this.apiService.getValidatedZohoApplicationUpdated(urlParams.get("Application_ID")).subscribe((res) => {
        this.validated_result = res
        console.log("THIS IS THE VALIDATED LOAN APPLICATION")
        console.log(this.validated_result)
        this.validation_errors = this.validated_result[3]
      })
    }
    else {
      this.apiService.getValidatedZohoApplication(urlParams.get("Application_ID")).subscribe((res) => {
        this.validated_result = res
        console.log("THIS IS THE VALIDATED LOAN APPLICATION")
        console.log(this.validated_result)
        this.validation_errors = this.validated_result[3]
      })
    }

  }
  onBoardClientsToNLS() {
    this.apiService.pushClientsToNLS(this.validated_result, true).subscribe((res) => {
      try {
        if (res['status']['code'] == 500)
          alert("INTERNAL SERVER ERROR, PLEASE CONTACT IT")
      } catch (err) {
        console.log(err)
      }
      console.log(res)
      if (res["status"]["code"] != 200) {
        this.NLS_errors = res["errors"]
        this.NLS_errors_flag = true
      }
      else {
        this.NLS_errors = null
        this.NLS_errors_flag = false
        this.apiService.pushClientsToNLS(this.validated_result, false).subscribe((res) => {
          if (res["status"]["code"] != 200) {
            this.NLS_errors = res["errors"]
            this.NLS_errors_flag = true
          }
          else {
            this.NLS_errors = null
            this.NLS_errors_flag = false
            // this.showUploadClients = false
            // this.showUploadLoan = true
            alert("Applicant and/or Co-Application has been uploaded to NLS Successfully")
          }
        })
      }
    })
  }
  onBoardLoanToNLS() {
    this.apiService.pushLoanToNLS(this.validated_result, true).subscribe((res) => {
      try {
        if (res['status']['code'] == 500)
          alert("INTERNAL SERVER ERROR, PLEASE CONTACT IT")
      } catch (err) {
        console.log(err)
      }
      console.log(res)
      if (res["status"]["code"] != 200) {
        this.NLS_errors = res["errors"]
        this.NLS_errors_flag = true
      }
      else {
        this.NLS_errors = null
        this.NLS_errors_flag = false
        this.apiService.pushLoanToNLS(this.validated_result, false).subscribe((res) => {
          if (res["status"]["code"] != 200) {
            this.NLS_errors = res["errors"]
            this.NLS_errors_flag = true
          }
          else {
            this.NLS_errors = null
            this.NLS_errors_flag = false
            // this.showUploadLoan = false
            alert("Loan Information has been uploaded to NLS Successfully")
          }
        })
      }
    })
  }
  onBoardRecurringToNLS() {
    this.apiService.pushRecurringToNLS(this.validated_result, true).subscribe((res) => {
      try {
        if (res['status']['code'] == 500)
          alert("INTERNAL SERVER ERROR, PLEASE CONTACT IT")
      } catch (err) {
        console.log(err)
      }
      console.log(res)
      if (res["status"]["code"] != 200) {
        this.NLS_errors = res["errors"]
        this.NLS_errors_flag = true
      }
      else {
        this.NLS_errors = null
        this.NLS_errors_flag = false
        this.apiService.pushRecurringToNLS(this.validated_result, false).subscribe((res) => {
          if (res["status"]["code"] != 200) {
            this.NLS_errors = res["errors"]
            this.NLS_errors_flag = true
          }
          else {
            this.NLS_errors = null
            this.NLS_errors_flag = false
            // this.showUploadLoan = false
            alert("Recurring Transaction Information has been uploaded to NLS Successfully")
            this.confirmation_message = true
          }
        })
      }
    })
  }
}