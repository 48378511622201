<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card class="insolvency-mat-card">
                <mat-card-content class="delinquency-mat-card-content">
                    <div style="font-size: 45px;font-weight: bold;">{{currentMonth}} Funded</div>
                    <mat-divider></mat-divider>
                    <div class="row" style="justify-content: space-between;">
                        <div class="col-auto">
                            {{fundedAmount | currency}}
                        </div>
                        <div class="col-auto">
                            {{targetAmount | currencyShortForm}}
                        </div>
                    </div>
                    <mat-progress-bar mode="determinate" value="{{fundedPercent}}"></mat-progress-bar>
                    <div class="row" style="margin-top: 0; justify-content: space-between;">
                        <div class="col-auto">
                            {{fundedPercent}}%
                        </div>
                        <div class="col-auto">
                            {{daysCountdown}}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card style="color: #2b2c49;background: white; ">
                <mat-card-content>
                    <div class="metrics-container">
                        <div style="font-size: 35px;">Top 10 Agencies</div>
                        <div class="row" style="justify-content: center;">
                            <div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Agency Name</th>
                                        <th>Count</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr *ngFor="let item of agencyFunded">
                                        <td style="font-size: 28px;">{{item.agency_name.split('(')[0]}}</td>
                                        <td style="font-size: 28px;">{{item.agency_count}}</td>
                                        <td style="font-size: 28px;">{{item.agency_total_amount | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card style="color: #2b2c49;background: white; ">
                <mat-card-content>
                    <div class="metrics-container">
                        <div style="font-size: 35px;">Top 10 Creditors</div>
                        <div class="row">
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Creditor Name</th>
                                            <th>Count</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let item of creditorFunded">
                                            <td style="font-size: 28px;">{{item.creditor_name.split('(')[0]}}</td>
                                            <td style="font-size: 28px;">{{item.creditor_count}}</td>
                                            <td style="font-size: 28px;">{{item.creditor_total_amount | currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>