import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import {
	UntypedFormControl,
	UntypedFormGroup,
	FormBuilder,
} from '@angular/forms';

let today = new Date();
let month = today.getMonth();
let year = today.getFullYear();
let startDate;
let endDate;

export interface Head {
	groupName: string;
	badDebt: number;
	fundedAmount: number;
	countDeliquency: number;
	countFunded: number;
	delinquencyRate: number;
	defaultAmount: number;
	countDefault: number;
	defaultRate: number;
}

@Component({
	selector: 'app-nlslocationcollections',
	templateUrl: './nlslocationcollections.component.html',
	styleUrls: ['./nlslocationcollections.component.scss'],
})

// const startDate = new Date(year, month, 1)
// // let endDate = new Date(year, month, new Date(year, month, 0).getDate())
// const endDate = new Date()
export class NlslocationcollectionsComponent implements OnInit {
	groupProvinceArray: Head[] = [];
	sortedData: Head[];
	countGroupBadDebt: number;
	amountGroupBadDebt: any;
	countGroupFunded: number;
	amountGroupFunded: any;
	countGroupDefault: number;
	amountGroupDefault: any;

	filterDate = new UntypedFormGroup({
		start: new UntypedFormControl(''),
		end: new UntypedFormControl(''),
	});

	toggleFilter = new UntypedFormControl(false);
	// dateForm = new FormGroup({
	//   'startDate': new FormControl(Date()),
	//   'endDate': new FormControl('')
	// })

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private route: Router
	) {}

	ngOnInit(): void {
		// Login Session
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						startDate = '2016-01-01';
						// let endDate = new Date(year, month, new Date(year, month, 0).getDate())
						endDate = new Date();
						this.fetchLocation(startDate, endDate); // Fetch the display data

						document.getElementById('dateRange').style.display = 'none'; // Hide the date Range
					} else {
						this.route.navigate(['login']);
					}
				});
		} else {
			this.route.navigate(['login']);
		}
	}

	/**
	 * Retrieve the whole overall funded query
	 * Group By Province
	 * Get the Delinquency and Default
	 */
	fetchLocation(start: any, end: any) {
		let startDate = start;
		let endDate = end;
		this.apiService
			.getnlsoverallfundedCheck(startDate, endDate)
			.subscribe((res) => {
				if (res.message === 'Success') {
					this.groupProvinceArray = [];
					// Get the distinct Provinces by using custom filter function
					const filterProvince = this.customFunction.filterDuplicates(
						res.data,
						'Province'
					);

					// Loop through the filtered provinces
					for (let i = 0; i < filterProvince.length; i++) {
						let countDeliquency = 0;
						let countDefault = 0;
						let fieldName = '';
						let badDebt = 0;
						let defaultAmount = 0;
						let fundedAmount = 0;
						let countFunded = 0;
						let delinquencyRate = '';
						let defaultRate = '';

						// Loop through the overall raw data
						for (let j = 0; j < res.data.length; j++) {
							// If filtered province match the raw data province
							if (filterProvince[i] === res.data[j].Province) {
								fieldName = res.data[j].Province; // Get the province name
								fundedAmount += res.data[j].original_note_amount; // Add the orignal funded amount to the respective province
								countFunded++; // count the funded occurrences
								// Delinquency
								if (res.data[j].internal_DPD > 0) {
									badDebt += res.data[j].current_principal_balance; // Add the principal balance to the respective province
									countDeliquency++; // count the delinquency occurrences
								}
								// Default
								if (res.data[j].internal_DPD > 120) {
									defaultAmount += res.data[j].current_principal_balance; // Add the principal balance to the respective province
									countDefault++; // count the default occurrences
								}
							}
						}

						delinquencyRate = ((badDebt / fundedAmount) * 100).toFixed();
						defaultRate = ((defaultAmount / fundedAmount) * 100).toFixed();

						this.groupProvinceArray.push(
							new this.customFunction.groupCollections(
								fieldName,
								badDebt,
								fundedAmount,
								countDeliquency,
								countFunded,
								parseInt(delinquencyRate),
								defaultAmount,
								countDefault,
								parseInt(defaultRate)
							)
						); // Push the loop objects to the new array list
						this.countGroupBadDebt = this.customFunction.sumNumbers(
							this.groupProvinceArray,
							'countDeliquency'
						); // Total up the numbers
						this.amountGroupBadDebt = this.customFunction.sumNumbers(
							this.groupProvinceArray,
							'badDebt'
						);
						this.countGroupFunded = res.data.length;
						this.amountGroupFunded = this.customFunction.sumNumbers(
							res.data,
							'original_note_amount'
						);
						this.countGroupDefault = this.customFunction.sumNumbers(
							this.groupProvinceArray,
							'countDefault'
						);
						this.amountGroupDefault = this.customFunction.sumNumbers(
							this.groupProvinceArray,
							'defaultAmount'
						);
					}
					this.sortData({ active: 'defaultRate', direction: 'desc' });
				} else {
					this.groupProvinceArray = [];
					this.countGroupBadDebt = 0;
					this.amountGroupBadDebt = 0;
					this.countGroupFunded = 0;
					this.amountGroupFunded = 0;
					this.countGroupDefault = 0;
					this.amountGroupDefault = 0;
				}
			});
	}

	sortData(sort: Sort) {
		const data = this.groupProvinceArray.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData = data;
			return;
		}

		this.sortedData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'groupName':
					return this.customFunction.compare(a.groupName, b.groupName, isAsc);
				case 'badDebt':
					return this.customFunction.compare(a.badDebt, b.badDebt, isAsc);
				case 'fundedAmount':
					return this.customFunction.compare(
						a.fundedAmount,
						b.fundedAmount,
						isAsc
					);
				case 'countDeliquency':
					return this.customFunction.compare(
						a.countDeliquency,
						b.countDeliquency,
						isAsc
					);
				case 'countFunded':
					return this.customFunction.compare(
						a.countFunded,
						b.countFunded,
						isAsc
					);
				case 'delinquencyRate':
					return this.customFunction.compare(
						a.delinquencyRate,
						b.delinquencyRate,
						isAsc
					);
				case 'defaultAmount':
					return this.customFunction.compare(
						a.defaultAmount,
						b.defaultAmount,
						isAsc
					);
				case 'countDefault':
					return this.customFunction.compare(
						a.countDefault,
						b.countDefault,
						isAsc
					);
				case 'defaultRate':
					return this.customFunction.compare(
						a.defaultRate,
						b.defaultRate,
						isAsc
					);
				default:
					return 0;
			}
		});
	}

	showDatePicker() {
		if (this.toggleFilter.value === false) {
			this.toggleFilter.setValue(true);
			document.getElementById('dateRange').style.display = 'block';
			const today = new Date();
			const month = today.getMonth();
			const year = today.getFullYear();

			this.filterDate.get('start').setValue(new Date(year, month, 1));
			this.filterDate.get('end').setValue(new Date());
			this.fetchLocation(new Date(year, month, 1), new Date());
		} else if (this.toggleFilter.value === true) {
			this.toggleFilter.setValue(false);
			document.getElementById('dateRange').style.display = 'none';
			startDate = '2016-01-01';
			// let endDate = new Date(year, month, new Date(year, month, 0).getDate())
			endDate = new Date();
			this.fetchLocation(startDate, endDate); // Fetch the display data
		}
	}
}
