import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';

class credit_report_input {
  constructor(
    public first_name: string,
    public middle_name: string,
    public last_name: string,
    public dob: string,
    public street: string,
    public province: string,
    public city: string,
    public postal_code: string,
    public application_id: string,
    public loan_number: number,
    public telephone: string,
    public sin: number,
    public application_type: string,
    public email: string,
    public loan_amount: number,
    public token: string,
    public created_at: string
  ) { }
}

@Component({
  selector: 'app-rapid-program',
  templateUrl: './rapid-program.component.html',
  styleUrls: ['./rapid-program.component.scss']
})
export class RapidProgramComponent implements OnInit {
  model = new credit_report_input("", "", "", '', '', '', '', '', '', null, "", null, 'primary', 'yeshey@lmcredit.com', 0, '17331', '')
  //model = new credit_report_input("", "", "", '', '', '', '', '', "", '', '', '', 0, '')
  show_submit = true
  tranunion_pulled_by
  TU_Report
  approval_status = false
  consent_status = false
  createApplicationFlag = true
  approvedPageFlag = false
  declinedPageFlag = false
  viewExistingApplicationFlag = false
  application_id = ''
  Hash = ''
  pre_questions = 'No'
  prelim_view = false
  show_send_consent_button = true
  show_consent_status_button = false

  constructor(private apiService: ApiServiceService) { }

  ngOnInit(): void {
  }
  validateEmail() {
    if (!this.model.email || this.model.email == '') {
      alert("An Email must be specified to send the Consent Agreement")
      return false
    }
    return true
  }
  validateInputs() {
    if (this.model.first_name == '' || this.model.token == '') {
      alert("Missing Information")
      return false
    }
    return true
  }
  resetForm() {
    if (confirm("Are you sure you want to start a new application?")) {
      this.show_submit = true
      console.log(this.prelim_view)
      this.prelim_view = false
      this.show_send_consent_button = true
      this.pre_questions = 'No'
      this.consent_status = false
      this.Hash = ''
      this.model = new credit_report_input("", "", "", '', '', '', '', '', '', null, "", null, 'primary', 'travis@lmcredit.com', 0, '17331', '')
      this.createApplicationFlag = true
      this.approvedPageFlag = false
      this.declinedPageFlag = false
      this.viewExistingApplicationFlag = false
      this.show_consent_status_button = false
      this.show_send_consent_button = true
    }

  }
  submitPrelimQuestions() {
    this.prelim_view = false
  }
  async onSubmit() {
    console.log("Input Values")
    console.log(this.model)
    console.log(this.pre_questions)
    if (this.consent_status) {
      if (await this.checkTransunionToken()) {
        this.show_submit = false
        if (this.pre_questions == 'No') {
          this.apiService.getTransunionCreditReport(this.model).subscribe((res) => {
            this.TU_Report = res
            console.log("Transunion Credit Report:")
            console.log(res)
            console.log(this.TU_Report.REL4Report.TU_FFR_Report[0])
            if (this.isHit(this.TU_Report)) {
              this.apiService.preapprove(this.model, this.TU_Report.REL4Report.TU_FFR_Report[0]).subscribe(async (res) => {
                console.log(res)
                this.approval_status = res["message"]
                // //We can now create the application on ZOHO
                // let application_creation_status = await this.createZOHOApplication(this.model, this.TU_Report)
                // try {
                //   application_creation_status = JSON.parse(application_creation_status["message"])
                //   console.log(application_creation_status)
                //   if (application_creation_status['data'][0]['code'] == 'SUCCESS')
                //     console.log("Application Created")
                //   else
                //     alert("Application Creation Failed")
                // }
                // catch (err) {
                //   console.log(err)
                //   alert("Error Creating Application on ZOHO")
                // }
                if (this.approval_status)
                  this.showApprovedPage()
                else
                  this.showDeclinedPage()
              })
            }
            else {
              alert("No Hit was found")
            }

          })
        }
        else { //If the Prelim Qustions is Yes
          console.log("Automatic Decline")
          this.approval_status = false
          // let application_creation_status = await this.createZOHOApplication(this.model, this.TU_Report)
          // try {
          //   application_creation_status = JSON.parse(application_creation_status["message"])
          //   console.log(application_creation_status)
          //   if (application_creation_status['data'][0]['code'] == 'SUCCESS')
          //     alert("Application Created")
          //   else
          //     alert("Application Creation Failed")
          // }
          // catch (err) {
          //   console.log(err)
          //   alert("Error Creating Application on ZOHO")
          // }
          console.log("Decline Page")
          this.showDeclinedPage()
        }
      }
    }
    else {
      alert("You Need Consent to Pull Credit for this Client")
    }
  }
  isHit(TU_Report) {
    try {
      if (TU_Report.REL4Report.TU_FFR_Report[0].Hit[0] == 'Y')
        return true
      else return false
    }
    catch (err) {
      return false
    }
  }
  async checkTransunionToken() {
    return new Promise((resolve, reject) => {
      if (this.model.token == null || this.model.token == "") {
        resolve(false)
      } else {
        this.apiService.checkTransunionToken(this.model.token).subscribe((res) => {
          if (!res["status"]) {
            alert("Syntax Error")
            resolve(false)
          }
          else if (res["data"].length == 0) {
            alert("Invalid Token")
            resolve(false)
          }
          else {
            this.model["pulled_by"] = res["data"][0]["emp_id"]
            this.tranunion_pulled_by = res["data"][0]["emp_id"]
            resolve(true)
          }
        })
      }
    })
  }
  async createZOHOApplication(model, TU_Report) {
    let result = await this.updateTransunionDB()
    return new Promise((resolve, reject) => {
      this.apiService.createZohoApplication(model, TU_Report, this.approval_status).subscribe(async (res) => {
        console.log("Creating Zoho Application")
        console.log(res)
        this.model.application_id = res['application_id']
        let result = await this.updateTransunionDB()
        resolve(res)
      })
    })
  }
  async updateTransunionDB() {
    return new Promise((resolve, reject) => {
      this.apiService.updateTransunionDB(this.model, this.TU_Report).subscribe(async (res) => {
        console.log("Updating Original Transunion DB")
        console.log(res)
        resolve(res)
      })
    })

  }

  async generateHash() {
    return new Promise(async (resolve, reject) => {
      this.apiService.generateHash(this.model.email).subscribe(async (res) => {
        this.Hash = res['message']
        resolve(true)
      })
    })
  }
  async createEntryPreapproval(hash: string) {
    return new Promise((resolve, reject) => {
      this.apiService.createEntryPreapproval(this.model, hash).subscribe((res) => {
        console.log(res)
        resolve(res)
      })
    })
  }
  createNewApplicationPage() {
    this.createApplicationFlag = true
    this.viewExistingApplicationFlag = false
  }
  viewExistingApplication() {
    this.createApplicationFlag = false
    this.viewExistingApplicationFlag = true
  }

  async sendConsentFormEmailClient() {
    if (this.validateEmail()) {
      await this.generateHash()
      await this.createEntryPreapproval(this.Hash)
      let url = 'http://localhost:4200/transunion-consent-form?City=&id='
      //http://localhost:4200/transunion-consent-form?City=&id= 
      //betty-alpha FIREBASE
      //https://betty-alpha.web.app/transunion-consent-form?City=&id=
      //stagingbetty FIREBASE
      //https://stagingbetty.web.app/transunion-consent-form?City=&id=
      url = url.concat(this.Hash)
      console.log(url)
      this.apiService.sendConsentFormEmailClient(this.model, url).subscribe(async (res) => {
        console.log(res)
        if (res['result']['status']) {
          this.show_send_consent_button = false
          this.show_consent_status_button = true
          alert("An Email has been sent out to the client")
        }
        else
          alert("Error sending email to client")
      })
    }

  }
  checkConsentStatus() {
    console.log(this.Hash)
    this.apiService.checkConsentStatus(this.Hash).subscribe((res) => {
      console.log(res)
      if (!res["status"])
        alert("Error Getting Consent Status")
      else {
        if (res['data'].length != 0)
          this.consent_status = res['data'][0]['consent']
        else
          alert("A Consent Form Email should be sent to the Client first")
      }
    })
  }
  showApprovedPage() {
    this.createApplicationFlag = false
    this.approvedPageFlag = true
    this.declinedPageFlag = false
    this.viewExistingApplicationFlag = false
    console.log("CLIENT HAS BEEN APPROVED")
  }
  showDeclinedPage() {
    this.createApplicationFlag = false
    this.approvedPageFlag = false
    this.declinedPageFlag = true
    this.viewExistingApplicationFlag = false
    console.log("CLIENT HAS BEEN DECLINED")
  }
}

