import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../../api-service.service';
import { mergeMap } from 'rxjs/operators';

@Component({
	selector: 'app-roll-overs',
	templateUrl: './roll-overs.component.html',
	styleUrls: ['./roll-overs.component.scss'],
})
export class RollOversComponent implements OnInit {
	public rolloverStart = 7;
	public rolloverEnd = 7;
	public rolloverPeriodSize = 30;
	public rolloverPeriodEnd = 90;
	public grandTotals = {
		totalEndAmount: 0,
		totalEndTotal: 0,
		rolloverStartAmount: 0,
		rolloverStartTotal: 0,
		rolloverEndAmount: 0,
		rolloverEndTotal: 0,
	};

	public buckets: Array<any>;
	public balanceTypes = {
		TOTAL_PAST_DUE: 'TOTAL_PAST_DUE',
		CURRENT_PRINCIPAL: 'CURRENT_PRINCIPAL',
	};

	constructor(private apiService: ApiServiceService) {}

	ngOnInit() {
		this.refreshRollOverData();
	}

	public refreshRollOverData(): void {
		this.buckets = Array.from(
			this.bucketsGenerator(0, this.rolloverPeriodSize, this.rolloverPeriodEnd)
		);
		this.buckets.forEach((bucket, i) => {
			this.apiService
				.getReportsDashboardDelinquencyRatio(
					this.balanceTypes.TOTAL_PAST_DUE,
					this.buckets[i].start,
					this.buckets[i].start + this.rolloverStart
				)
				.pipe(
					mergeMap((res: any) => {
						this.buckets.find(
							(bucket) => bucket.start === res.start
						).rolloverStartAmount = res.data?.[0]?.['total_past_due_balance'];
						this.buckets.find(
							(bucket) => bucket.start === res.start
						).rolloverStartTotal = res.data?.[0]?.['total_count'];
						return this.apiService.getReportsDashboardDelinquencyRatio(
							this.balanceTypes.TOTAL_PAST_DUE,
							this.buckets[i].end - this.rolloverEnd,
							this.buckets[i].end
						);
					})
				)
				.pipe(
					mergeMap((res: any) => {
						this.buckets.find(
							(bucket) => bucket.end === res.end
						).rolloverEndAmount = res.data?.[0]?.['total_past_due_balance'];
						this.buckets.find(
							(bucket) => bucket.end === res.end
						).rolloverEndTotal = res.data?.[0]?.['total_count'];
						return this.apiService.getReportsDashboardDelinquencyRatio(
							this.balanceTypes.TOTAL_PAST_DUE,
							this.buckets[i].start,
							this.buckets[i].end
						);
					})
				)
				.subscribe((res: any) => {
					this.buckets.find((bucket) => bucket.end === res.end).totalEndAmount =
						res.data?.[0]?.['total_past_due_balance'];
					this.buckets.find((bucket) => bucket.end === res.end).totalEndTotal =
						res.data?.[0]?.['total_count'];
					this.grandTotals.totalEndAmount = this.buckets
						.map((bucket) => bucket.totalEndAmount)
						.reduce((acc, val) => acc + val, 0);
					this.grandTotals.totalEndTotal = this.buckets
						.map((bucket) => bucket.totalEndTotal)
						.reduce((acc, val) => acc + val, 0);
					this.grandTotals.rolloverStartAmount = this.buckets
						.map((bucket) => bucket.rolloverStartAmount)
						.reduce((acc, val) => acc + val, 0);
					this.grandTotals.rolloverStartTotal = this.buckets
						.map((bucket) => bucket.rolloverStartTotal)
						.reduce((acc, val) => acc + val, 0);
					this.grandTotals.rolloverEndAmount = this.buckets
						.map((bucket) => bucket.rolloverEndAmount)
						.reduce((acc, val) => acc + val, 0);
					this.grandTotals.rolloverEndTotal = this.buckets
						.map((bucket) => bucket.rolloverEndTotal)
						.reduce((acc, val) => acc + val, 0);
				});
		});
	}
	*bucketsGenerator(start = 0, inc = 30, limit = 180) {
		let i = start;
		yield { start: i + 1, end: 2 * inc > limit ? limit : inc };
		while (2 * inc + i <= limit) {
			i += inc;
			yield {
				start: start + i + 1,
				end: 2 * inc + i > limit ? limit : inc + i,
			};
		}
		return limit;
	}
}
