<div class="modelPadding">
	<div class="float-main-left">
		<mat-form-field style="width: 90%">
			<mat-label>Created By</mat-label>
			<input
				[readonly]="true"
				matInput
				type="string"
				[(ngModel)]="commentInfo.created_by"
			/>
		</mat-form-field>
		<mat-form-field style="width: 90%">
			<mat-label>Created At</mat-label>
			<input
				[readonly]="true"
				matInput
				type="string"
				[(ngModel)]="commentInfo.created_at"
			/>
		</mat-form-field>
		<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
			<mat-label>Disposition</mat-label>
			<mat-select [(ngModel)]="commentInfo.disposition">
				<mat-option *ngFor="let d of dispositions" [value]="d.name">
					{{ d.id }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="float-main-right">
		<mat-form-field style="width: 90%">
			<mat-label>Last Modified By</mat-label>
			<input
				[readonly]="true"
				matInput
				type="string"
				[(ngModel)]="commentInfo.last_modified_by"
			/>
		</mat-form-field>
		<mat-form-field style="width: 90%">
			<mat-label>Last Modified At</mat-label>
			<input
				[readonly]="true"
				matInput
				type="string"
				[(ngModel)]="commentInfo.last_modified_at"
			/>
		</mat-form-field>
		<mat-form-field appearance="fill" style="font-weight: bold">
			<mat-label>Comment Type</mat-label>
			<mat-select [(ngModel)]="commentInfo.type">
				<mat-option *ngFor="let ct of comment_types" [value]="ct.value">
					{{ ct.viewValue }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<textarea
		style="margin-top: 10px; width: 100%"
		name="Comments"
		placeholder="Comments"
		rows="5"
		[(ngModel)]="commentInfo.comment"
	></textarea>
	<br />
	<button class="buttonSubmit" (click)="deleteComment()">Delete</button>
	<button class="buttonSubmit" (click)="updateComment()">Update</button>
</div>
