<div class="flex-container">
    <mat-form-field class="table-filter" appearance="outline" style="margin-right: 15px" *ngIf="showReportDropDown">
        <mat-label>Report Type</mat-label>
        <mat-select [(ngModel)]="selectedReport" (ngModelChange)="selectedReportStyle($event)">
            <mat-option *ngFor="let item of reports" [value]="item.value">
                {{item.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="selectedReport==='Custom'" style="margin-bottom: 10px; width: 100%;">
        <ng-multiselect-dropdown [placeholder]="'Loan Groups'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedLoans" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
    <mat-form-field class="table-filter" appearance="outline" style="margin-right: 15px" *ngIf="showLoanStatus">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="selectedStatus" (ngModelChange)="selectedLoanStatus($event)">
            <mat-option *ngFor="let item of status" [value]="item.id">
                {{item.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="table-filter" appearance="outline" style="margin-right: 15px" *ngIf="showBreakdowns">
        <mat-label>Partial Breakdown</mat-label>
        <mat-select [(ngModel)]="selectedBreakdown" (ngModelChange)="selectedBreakdownType($event)">
            <mat-option *ngFor="let item of breakdown_report_type" [value]="item.id">
                {{item.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <span [formGroup]="dateForm" *ngIf="showCurrentDate">
        <mat-form-field class="table-filter" appearance="outline" style="margin-right: 15px">
            <mat-label>Choose a date</mat-label>
            <input formControlName="currentDate" matInput [matDatepicker]="picker"
                (dateChange)="addCurrentDateEvent($event)">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="
                            this.dateForm.controls['currentDate'].hasError(
                                'required'
                            ) && this.dateForm.controls['currentDate'].touched
                        ">
                Date is required
            </mat-error>
        </mat-form-field>
    </span>
    <span [formGroup]="dateFormRange" *ngIf="showDateRange">
        <mat-form-field class="table-filter" appearance="outline" style="margin-right: 15px">
            <mat-label>Choose start date</mat-label>
            <input formControlName="startDate" matInput [matDatepicker]="picker1" (dateChange)="addStartEvent($event)"
                required>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="
                        this.dateFormRange.controls['startDate'].hasError(
                            'required'
                        ) && this.dateFormRange.controls['startDate'].touched
                    ">
                Date is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="table-filter" appearance="outline" style="margin-right: 15px">
            <mat-label>Choose end date</mat-label>
            <input formControlName="endDate" matInput [matDatepicker]="picker2" (dateChange)="addEndEvent($event)"
                required>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="
                        this.dateFormRange.controls['endDate'].hasError(
                            'required'
                        ) && this.dateFormRange.controls['endDate'].touched
                    ">
                Date is required
            </mat-error>
        </mat-form-field>
    </span>
    <button mat-raised-button color={{btnColor}} style="align-items: center; justify-content: center;"
        (click)="onClick()" disabled={{btnSpinner}}
        [disabled]="(this.dateFormRange.invalid && showDateRange) || (this.dateForm.invalid && showCurrentDate)">
        <span *ngIf="btnSpinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="btnShowText">{{btnText}}</span>
    </button>
</div>