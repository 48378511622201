import { Component, Input, OnInit } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CustomFunctionService } from '../../custom-function.service';
import { Controls } from '@lmc-models/controls';
import { Subscription } from 'rxjs';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { SharedServiceService } from '@lmc-app/shared-service.service';

@Component({
	selector: 'app-summary-bar',
	templateUrl: './summary-bar.component.html',
	styleUrls: ['./summary-bar.component.scss'],
})
export class SummaryBarComponent implements OnInit {
	zohoApplicationID: any;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;
	summaryData: any;
	@Input() showSummaryBar: boolean;
	@Input() expandSummaryPanel: boolean;
	Deal_Name: any;
	Amount: any;
	Credit_Score: any;
	Account_Name: any;
	Sales_Agent: any;
	Referred_By: any;
	Creditt: any;
	Creditor_Agency_Name: any;
	Referral_Date: any;
	Account_Balance: any;
	Creditor_Portfolio: any;
	Address1: any;
	Address_Line_2: any;
	City: any;
	Province: any;
	Postal_Code: any;
	fullAddressPrimary: any;
	dob: any;
	Stage: any;
	Mobile: any;
	Phone: any;
	Are_you_currently_a_Home_Owner: any;

	TDSR: any;
	uw_tier: any;
	loan_number: any;

	under_TDSR: any;
	under_uw_tier: any;

	clickEventSubscription: any;
	primaryValidStatus: any;
	coAppValidStatus: any;

	Age: any;

	controls = new Controls();
	stageName: any;
	selectedStage: any;
	editingStage: boolean;

	isExternalView: boolean;
	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute,
		private customFunction: CustomFunctionService,
		private summaryDataService: SummaryDataService,
		private router: Router,
		private sharedService: SharedServiceService		
	) {
		this.clickEventSubscription = this.apiService
			.getClickEvent()
			.subscribe(() => {
				console.log(this.clickEventSubscription, 'clickEventSubscription');
				this.loadApplicationInfo();
			});
			this.sharedService.currentMessage.subscribe((newLoanAmount) => {
				this.Amount = newLoanAmount; // Update the message dynamically
			  });
	}

	async ngOnInit(): Promise<void> {
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				async (summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.summaryData = summaryData;
						this.setApplicationInfo(summaryData);
					}
				}
			);
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			this.isExternalView = userData?.roleID === 12 ? true : false;
		})
		this.apiService.getLookupsdeals().subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			// Stage
			this.stageName = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Stage'
			)[0]['pick_list_values'];
		});
	}

	flipEditingStageMode() {
		this.editingStage = !this.editingStage;
	}

	saveStage() {
		this.controls.displayMessage(
			'saveStageButton',
			true,
			'saveStageMessage',
			'Save in progress...',
			'orange',
			'block'
		);
		this.apiService
			.updateSummaryStage(this.selectedStage, this.zohoApplicationID)
			.subscribe((res) => {
				if (res['message']['statusCode'] == '200') {
					this.controls.displayMessage(
						'saveStageButton',
						false,
						'saveStageMessage',
						'Save Successful',
						'green',
						'block'
					);
					console.log('Stage Save Success!');
					this.Stage = this.selectedStage;
				} else {
					this.controls.displayMessage(
						'saveStageButton',
						false,
						'saveStageMessage',
						'Save unsuccessful',
						'red',
						'block'
					);
					console.log(res);
				}
				this.flipEditingStageMode();
			});
	}

	cancelSaveStage() {
		this.selectedStage = this.Stage;
		this.flipEditingStageMode();
	}

	async updateTDSR() {
		this.apiService
			.updateTDSRInfo(this.zohoApplicationID, 'Primary')
			.subscribe((res) => {
				console.log('Updating TDSR on Database and ZOHO Application');
				console.log(res);
				this.loadApplicationInfo();
				window.location.reload();
			});
	}
	async loadApplicationInfo() {
		return new Promise((resolve, reject) => {
			// Load the Application Information
			this.apiService
				.getSingleAppSummary(this.zohoApplicationID)
				.subscribe((res) => {
					if (res['message']['statusCode'] == '200') {
						let arr = JSON.parse(res.message.body);
						this.setApplicationInfo(arr.data?.[0]);
						resolve(true);
					} else {
						resolve(false);
					}
				});
		});
	}

	setApplicationInfo(summaryData) {
		this.Deal_Name = summaryData.Deal_Name;
		this.Amount = summaryData.Amount;
		this.Credit_Score = summaryData.Credit_Score;
		this.dob = summaryData.Date_of_Birth;
		this.Stage = this.selectedStage = summaryData.Stage;
		this.Are_you_currently_a_Home_Owner =
			summaryData.Are_you_currently_a_Home_Owner;

		if (summaryData.Account_Name != null) {
			this.Account_Name = summaryData.Account_Name['name'];
		}
		this.Sales_Agent = summaryData.Sales_Agent;
		this.Referred_By = summaryData.Collector_Formula;
		this.Creditt = summaryData.Creditt;
		this.Creditor_Agency_Name = summaryData.Creditor_Agency_Name;
		this.Referral_Date = summaryData.Referral_Date;
		this.Account_Balance = summaryData.Account_Balance;
		this.Creditor_Portfolio = summaryData.Creditor_Portfolio;

		this.Address1 = summaryData.Address1;
		this.Address_Line_2 = summaryData.Address_Line_2;
		this.City = summaryData.City;
		this.Province = summaryData.Province1;
		this.Postal_Code = summaryData.Postal_Code;

		this.TDSR = summaryData.TDSR;
		this.Mobile = summaryData.Mobile;
		this.Phone = summaryData.Phone;

		this.uw_tier = summaryData.uw_tier;
		this.loan_number = summaryData.App_Number;

		this.fullAddressPrimary =
			this.Address1 +
			', ' +
			this.City +
			', ' +
			this.Province +
			', ' +
			this.Postal_Code;

		this.primaryValidStatus = summaryData.Primary_Checklist_Status;
		this.coAppValidStatus = summaryData.Co_applicant_Checklist_Status;

		if (summaryData.Date_of_Birth != null) {
			this.Age = this.customFunction.getAge(summaryData.Date_of_Birth);
		}

		// this.loadUnderwritingInfo(summaryData.Underwriting_ID);
		this.getValidatioNStatus();
	}

	primaryStatus: any;
	coAppStatus: any;
	getValidatioNStatus() {
		this.apiService
			.getValidation(this.zohoApplicationID, 'Primary')
			.subscribe((res) => {
				if (res['message'] == 'Success') {
					if (res.data.length != 0) {
						if (this.primaryValidStatus === 'Complete') {
							this.primaryStatus = 'Complete';
						} else {
							this.primaryStatus = 'Incomplete';
						}
					} else {
						this.primaryStatus = 'Not Started';
					}
				}
			});
		this.apiService
			.getValidation(this.zohoApplicationID, 'Co-Applicant')
			.subscribe((res) => {
				if (res['message'] == 'Success') {
					if (res.data.length != 0) {
						if (this.coAppValidStatus === 'Complete') {
							this.coAppStatus = 'Complete';
						} else {
							this.coAppStatus = 'Incomplete';
						}
					} else {
						this.coAppStatus = 'Not Started';
					}
				}
			});
	}

	loadUnderwritingInfo(id: any) {
		// Load the Underwriting Details
		this.apiService.getUnderwritingDetails(id).subscribe((res) => {
			if (res.message.statusCode == '200') {
				let arr = JSON.parse(res.message.body);
				if (arr.data.length != 0) {
					this.under_TDSR = arr.data[0].TDSR;
					this.under_uw_tier = arr.data[0].UW_Tier; // Use from Underwriting module until the local platform is completely into production
				}
			} else {
				console.log('Underwriting Error!');
			}
		});
	}
}
