<div style="padding:15px">
	<h5><span class="prevent-select">{{ PTP_info.name }} </span>({{ PTP_info.loan_number }})</h5>
	<br />
	<mat-form-field appearance="fill">
		<mat-label>Choose New PTP Date</mat-label>
		<input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="datepicker1" placeholder="Start Date"
			[(ngModel)]="date" required />
		<mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
		<mat-datepicker #datepicker1></mat-datepicker>
	</mat-form-field>
	<mat-form-field appearance="fill">
		<mat-label>Payment Type</mat-label>
		<mat-select [(ngModel)]="payment_type">
			<mat-option *ngFor="let type of p_type" [value]="type.value">
				{{ type.viewValue }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="fill">
		<mat-label>PTP Status</mat-label>
		<mat-select [(ngModel)]="status">
			<mat-option *ngFor="let type of s_type" [value]="type.value">
				{{ type.viewValue }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="fill">
		<mat-label>Amount</mat-label>
		<input type="number" matInput [(ngModel)]="amount" />
	</mat-form-field>
	<br /><br />
	Comments
	<br />
	<textarea id="ptp_comments" rows="4" cols="50" readonly>{{
	PTP_info.comments
}}</textarea>

	<br />
	<button class="buttonSubmit" (click)="updatePTP()">Update</button>
	<div *ngIf="payment_type === 'customer_deposit'; else elseOther">
		<button class="buttonSubmit" (click)="setStatusPTP('Processed', PTP_info.parent_id)">
			Payment Processed
		</button>
		<button class="buttonSubmit" (click)="setStatusPTP('Broken', PTP_info.parent_id)">
			Broken
		</button>
	</div>
	<ng-template #elseOther>
		<div [ngSwitch]="status">
			<div *ngSwitchCase="'Requested'">
				<button class="buttonSubmit" (click)="onApplyPTP()">
					Payment Processing
				</button>
			</div>
			<div *ngSwitchDefault>
				<button class="buttonSubmit" (click)="onApplyPTP()">Apply</button>
			</div>
		</div>
	</ng-template>
</div>