import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import { ApiServiceService } from '../../api-service.service';
import { UnderwritingEntry } from '@lmc-models/underwriting';
import { CustomFunctionService } from '../../custom-function.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MonthlyincomecalcComponent } from '../../monthlyincomecalc/monthlyincomecalc.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Controls } from '@lmc-models/controls';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';

@Component({
	selector: 'app-underwriting',
	templateUrl: './underwriting.component.html',
	styleUrls: ['./underwriting.component.scss'],
})
export class UnderwritingComponent implements OnInit, OnDestroy {
	//Interviwer Information
	interViewerInfo = {
		interviewer: '',
		duration: '00:00',
		modified_by: '',
		modified_at: '',
	};
	//Primary Industry
	dropdownSettings_Primary_Ind: IDropdownSettings;
	dropdownList_Primary_Ind = [];
	selectedItems_Primary_Ind: any;
	//Co Applicant Industry
	dropdownSettings_Co_Ind: IDropdownSettings;
	dropdownList_Co_Ind = [];
	selectedItems_Co_Ind = [];

	//Primary Occupation
	dropdownSettings_Primary_Occ: IDropdownSettings;
	dropdownList_Primary_Occ = [];
	selectedItems_Primary_Occ = [];

	//Co Applicant Occupation
	dropdownSettings_Co_Occ: IDropdownSettings;
	dropdownList_Co_Occ = [];
	selectedItems_Co_Occ = [];

	URI_link = '';
	clickEventSubscription;
	//Timer
	counter = 0;
	timerRef;
	running: boolean = false;
	display_time = '';
	timeInfo;
	//Date Pipe
	//
	n_total_expenses = 0;
	n_tdsr;
	n_current_loan_amount_payments = 0;
	n_total_monthly_income;
	n_total_additional_debt = 0;
	//
	// additionalDebt = [];
	// totalDebts: any;

	expensetotal: any;
	tdsr = 0;
	loan_amount_payments_monthly = 0;
	total_monthly_income = 0;
	underwritingEntry = new UnderwritingEntry();

	Expense_Types: any;
	zohoApplicationID: string;
	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;
	underwritingID: string;
	applicantType: string;
	co_app_required: any;
	credit_score: string;
	uw_tier: any;
	TDSR: any;
	userID: string;
	newDebt: any;
	newExpense: any;

	// Lookups from ZOHO
	emp_type_look_up: any;
	co_app_emp_type_look_up: any;
	industry_look_up: any;
	occupation_look_up: any;
	industry_coapp_look_up: any;
	occupation_coapp_look_up: any;
	salary_frequency_look_up: any;
	salary_frequency_household_look_up: any;
	pay_method_look_up: any;
	override_look_up: any;
	override_type_look_up: any;
	stageName: any;

	underwriter_names: any;
	userRole: any;
	userName: any;

	//Transunion Stats
	tu_stats: any;

	//Read Only Flag
	readOnly = true;

	//monthly_calc
	monthly_income_calc_result;

	//Messages
	errorMessage: any[];
	successMessage = false;
	warningMessage = false;
	saveInProgress = false;

	// For messages
	controls = new Controls();

	// File Upload
	@ViewChild('consentInput') consentInput: ElementRef;
	private fileList: Array<string> = []; // This will store the files uploaded
	private fileNameList: Array<string> = [];
	private fileNameExt: Array<string> = []; // This will store the file extensions
	selectedFile: any;

	summary$: Observable<any>;

	constructor(
		private store: Store,
		private apiService: ApiServiceService,
		private fb: UntypedFormBuilder,
		private customFunction: CustomFunctionService,
		public datepipe: DatePipe,
		private route: Router,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog
	) {
		this.clickEventSubscription = this.apiService
			.getClickEvent()
			.subscribe(() => {
				this.displayTotals();
			});
	}

	private regex: RegExp = new RegExp('^[0-9]*$'); // Numbers only
	private specialKeys: Array<string> = [
		'Backspace',
		'ArrowLeft',
		'ArrowRight',
		'Delete',
		'Tab',
	]; // Accept the ones in the array

	ngOnInit() {
		this.errorMessage = [];
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});

		this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe(async (res: any) => {
			if (res.message === 'Success') {
				this.userRole = res?.['data']?.[0]?.['position'];
				this.userName = res?.['data']?.[0]?.['full_name'];
				this.userID = res?.['data']?.[0]?.['zoho_owner_id'];

				document.getElementById('messageDisplay').style.display = 'none';
			}
		})
		// this.summaryDataService.userDataObservable.subscribe((userData: any) => {
		// 	this.userRole = userData['position'];
		// 	this.userName = userData['full_name'];
		// 	this.userID = userData['zoho_owner_id'];

		// 	document.getElementById('messageDisplay').style.display = 'none';
		// });
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				(summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.applicantType = 'Primary';
						this.underwritingID = summaryData.Underwriting_ID;
						this.co_app_required = summaryData.Co_Appl_Required;
						this.uw_tier = summaryData.uw_tier;
						this.TDSR = summaryData.TDSR;
						// this.getUnderwritingAddDebts(this.zohoApplicationID);
						this.loadUnderwritingInfo();
					}
				}
			);
	}

	loadUnderwritingInfo() {
		this.getUnderwriters();
		this.getLookups();
		this.getTransunionStats();
		this.getzoho_Expense_Types();
		this.checkForUnderwritingEntry();
		this.getZohoApplicationSettings();
		this.getInterviewerInfo();
		this.resetExpenses();
	}

	updateApplicationType(e) {
		try {
			document.getElementById('messageDisplay').style.display = 'none';
		} catch (err) {
			console.log(err);
		}
		this.lockedMode();
		this.errorMessage = [];
		this.applicantType = e.target.value;
		this.checkForUnderwritingEntry();
		this.getTransunionStats();
		this.getInterviewerInfo();
		this.resetExpenses();
		//this.loadUnderwritingInfo();
	}

	getInterviewerInfo() {
		this.apiService
			.getInterviewerInfo(this.zohoApplicationID, this.applicantType)
			.subscribe((res) => {
				console.log('Interviewer Information__________________');
				// console.log(res);
				try {
					this.interViewerInfo = res['data'][0];
					if (!this.interViewerInfo) {
						this.interViewerInfo = {
							interviewer: '',
							duration: '00:00',
							modified_by: '',
							modified_at: '',
						};
					}
				} catch (err) {
					console.log('_______________________________________');
					console.log(err);
					this.interViewerInfo = {
						interviewer: '',
						duration: '00:00',
						modified_by: '',
						modified_at: '',
					};
				}
			});
	}
	//Transunion Stats
	getTransunionStats() {
		let application_Type = '';
		if (this.applicantType == 'Primary') {
			application_Type = 'primary';
		} else {
			application_Type = 'co_applicant';
		}
		this.apiService
			.getTransunionStats(this.zohoApplicationID, application_Type)
			.subscribe((res) => {
				this.tu_stats = res;
				console.log('Transunion Stats');
				// console.log(this.tu_stats);
				this.credit_score = this.tu_stats.credit_score;
			});
	}
	//Expenses Functions
	addExpense() {
		let expenseArray = this.userForm.get(
			'ADDITIONAL_EXPENSES'
		) as UntypedFormArray;
		this.newExpense = this.fb.group({
			Type: '',
			Name1: '',
			Amount: 0,
		});
		expenseArray.push(this.newExpense);
	}
	removeExpenses(i) {
		let listArray = this.userForm.get(
			'ADDITIONAL_EXPENSES'
		) as UntypedFormArray;
		listArray.removeAt(i);
	}
	addExpenses() {
		this.calculateExpenses();
		this.calculatorTDSR();
	}
	calculatorTDSR() {
		document.addEventListener('click', (e) => {
			//this.displayTDSR();
			this.displayTotals();
		});
	}
	calculateExpenses() {
		document.addEventListener('click', (e) => {
			//this.displayTotalExpenses();
			this.displayTotals();
		});
	}
	displayTotalExpenses() {
		this.expensetotal = this.userForm
			.get('ADDITIONAL_EXPENSES')
			.value.map((item) => item.Amount)
			.reduce((accumulator, currentValue) => accumulator + currentValue);
		this.userForm.patchValue({
			expenseTotal: this.formatMoney(this.expensetotal.toFixed(2)),
		});
	}
	displayTDSR() {
		this.expensetotal = this.userForm
			.get('ADDITIONAL_EXPENSES')
			.value.map((item) => item.Amount)
			.reduce((accumulator, currentValue) => accumulator + currentValue);
		let total =
			this.expensetotal +
			parseFloat(this.userForm.get('loan_amount_payments_monthly').value);
		let tdsr =
			(total / parseFloat(this.userForm.get('total_monthly_income').value)) *
			100;
		this.userForm.patchValue({
			tdsr: tdsr.toFixed(2),
		});
	}
	displayTotals() {
		try {
			this.n_total_expenses = parseFloat(
				this.userForm
					.get('ADDITIONAL_EXPENSES')
					.value.map((item) => item.Amount)
					.reduce((accumulator, currentValue) => accumulator + currentValue)
					.toFixed(2)
			);
		} catch (err) {
			this.n_total_expenses = 0;
		}
		// console.log(this.n_total_expenses);
		let total = this.n_total_expenses + this.n_current_loan_amount_payments;
		if (total && this.n_total_monthly_income)
			this.n_tdsr = ((total / this.n_total_monthly_income) * 100).toFixed(2);
		this.n_total_additional_debt = this.userForm
			.get('Additional_Debt')
			.value.map((item) => item.Account_Balance)
			.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
		// console.log("________________________________")
		// console.log('TOTAL:' + total)
		// console.log('total expenses' + this.n_total_expenses)
		this.underwritingEntry['Total_Additional_Expenses'] = this.n_total_expenses;
		// console.log('total monthly income' + this.n_total_monthly_income)
		this.underwritingEntry['Total_Monthly_Income'] =
			this.n_total_monthly_income;
		// console.log('tdsr' + this.n_tdsr
		this.underwritingEntry['TDSR'] = this.n_tdsr;
		// console.log('loan amount payments' + this.n_current_loan_amount_payments)
		this.underwritingEntry['Loan_Monthly_Payments'] =
			this.n_current_loan_amount_payments;
	}
	calculateLTV() {
		document.addEventListener('click', (e) => {
			try {
				if (
					!this.underwritingEntry.Purview_Value &&
					!this.underwritingEntry.What_is_current_value_of_the_property
				) {
					this.underwritingEntry.LTV = '0';
				} else if (!this.underwritingEntry.Purview_Value) {
					this.underwritingEntry.LTV = (
						(this.underwritingEntry.What_is_the_balance_of_your_mortgage /
							this.underwritingEntry.What_is_current_value_of_the_property) *
						100
					).toFixed(2);
				} else {
					this.underwritingEntry.LTV = (
						(this.underwritingEntry.What_is_the_balance_of_your_mortgage /
							this.underwritingEntry.Purview_Value) *
						100
					).toFixed(2);
				}
			} catch (err) {
				this.underwritingEntry.LTV = '0';
			}
		});
	}

	getZohoApplicationSettings() {
		this.apiService.getApplicationSettings().subscribe((res) => {
			let app_settings = JSON.parse(res['message']['body']);
		});
	}

	getUnderwriters() {
		this.apiService.getUnderwriters().subscribe((res) => {
			this.underwriter_names = this.customFunction.singleFilterName(
				res['data'],
				'position',
				'UW'
			);
		});
	}

	getUserName(e) {
		this.userID = e.target.value;
		this.userName = this.customFunction.singleFilterName(
			this.underwriter_names,
			'zoho_owner_id',
			e.target.value
		)[0]['full_name'];

		this.underwritingEntry.Created_By = this.userID;
		this.underwritingEntry.Modified_By = this.userID;
		this.underwritingEntry.Interviewer = this.userName;
		// console.log(this.userID,this.userName)
	}

	getzoho_Expense_Types() {
		this.apiService.getzoho_Expense_Types().subscribe((res) => {
			if (res['status']) {
				this.Expense_Types = res['data'];
			} else {
				console.log(res);
			}
		});
	}
	//Get Underwriting Entry if it already exists
	// getUnderwritingAddDebts() {
	//   this.apiService.getUnderwritingAddDebts(this.zohoApplicationID, this.applicantType).subscribe((res) => {
	//     if (!res['status']) {
	//       alert('Unable to retrieve additional debts')
	//       console.log(res)
	//     }
	//     else {
	//       let additional_debts = res['data']
	//       let add_debts_array = this.userForm.get('Additional_Debt') as FormArray
	//       for (var i = 0; i < additional_debts.length; i++) {
	//         add_debts_array.push(this.fb.group({
	//           'Agency': additional_debts[i]['agency_name'],
	//           'Account_Number': additional_debts[i]['account_number'],
	//           'Account_Balance': additional_debts[i]['account_balance'],
	//         }))
	//       }
	//       this.displayTotals()
	//     }
	//   })
	// }
	getUnderwritingExpenses() {
		this.apiService
			.getUnderwritingExpenses(this.zohoApplicationID, this.applicantType)
			.subscribe((res) => {
				if (!res['status']) {
					alert('Unable to retrieve Expenses');
				} else {
					console.log('EXPENSES WE ARE GETTING FROM DATABASE');
					// console.log(res);
					if (res['data'].length != 0) {
						let expenses = res['data'];
						let expenses_array = this.userForm.get(
							'ADDITIONAL_EXPENSES'
						) as UntypedFormArray;
						for (var i = 0; i < expenses.length; i++) {
							expenses_array.push(
								this.fb.group({
									Type: expenses[i]['type'],
									Name1: expenses[i]['creditor_name'],
									Amount: expenses[i]['amount'],
								})
							);
						}
						this.displayTotals();
					} else {
						this.populateUnderwritingSubform();
					}
				}
			});
	}

	questionBanks: any;
	underwritingPool: any;
	populateUnderwritingSubform() {
		this.apiService.getInterviewQuestions().subscribe((res) => {
			this.questionBanks = res.data;
			console.log('INTERVIEW QUESTIONS');
			console.log(this.questionBanks);
			// Push the values into subform array
			this.underwritingPool = this.userForm.get(
				'ADDITIONAL_EXPENSES'
			) as UntypedFormArray;
			if (
				this.provinceName == 'AB' ||
				this.provinceName == 'QC' ||
				this.provinceName == 'BC' ||
				this.provinceName == 'ON' ||
				this.provinceName == 'Alberta' ||
				this.provinceName == 'Quebec' ||
				this.provinceName == 'British Columbia' ||
				this.provinceName == 'Ontario'
			) {
				for (let i = 0; i < this.questionBanks.length; i++) {
					this.underwritingPool.push(
						this.fb.group({
							Type: this.questionBanks[i]['expenses_type'],
							Name1: this.questionBanks[i]['creditor_name'],
							Amount: this.questionBanks[i]['amount'],
						})
					);
				}
				this.n_total_expenses = this.customFunction.sumNumbers(
					res.data,
					'amount'
				);
			} else {
				for (let i = 0; i < this.questionBanks.length; i++) {
					this.underwritingPool.push(
						this.fb.group({
							Type: this.questionBanks[i].expenses_type,
							Name1: this.questionBanks[i].creditor_name,
							Amount: this.questionBanks[i].amount1,
						})
					);
				}
				this.n_total_expenses = this.customFunction.sumNumbers(
					res.data,
					'amount1'
				);
			}
		});
	}

	provinceName: any;
	underwritingEntryLocal: any;
	checkForUnderwritingEntry() {
		this.apiService
			.getUnderwritingZohoAppDetails(this.zohoApplicationID)
			.subscribe((res) => {
				if (res.message.statusCode == '200') {
					let arr = JSON.parse(res.message.body);
					this.underwritingEntry.applicationID = this.zohoApplicationID;
					this.underwritingEntry.underwritingID = this.underwritingID;
					this.underwritingEntry.Applicant_Type = this.applicantType;
					this.underwritingEntry.Credit_Score = this.credit_score;
					console.log('Zoho Application data for Client');
					// console.log(arr['data'][0],"*****************************")
					this.underwritingEntry = arr['data'][0];
					this.underwritingEntry.Stage = arr['data'][0]['Stage'];
					// console.log(arr['data'][0])
					this.selectedItems_Primary_Ind = this.underwritingEntry.Industry;
					console.log('____________----------------___________-----------');
					console.log(this.underwritingEntry.Industry);
					this.selectedItems_Co_Ind.push({
						display_value: this.underwritingEntry.Co_Applicant_Industry,
					});
					this.selectedItems_Primary_Occ.push({
						display_value: this.underwritingEntry.Occupation,
					});
					this.selectedItems_Co_Occ.push({
						display_value: this.underwritingEntry.Co_Applicant_Occupation,
					});
					this.underwritingEntry['Approved_Amount'] = parseFloat(
						this.underwritingEntry['Approved_Amount']
					);
					console.log('Approved Amount________________');
					// console.log(this.underwritingEntry['Approved_Amount']);
					this.n_current_loan_amount_payments =
						arr['data'][0]['Scheduled_Payments_Monthly'];

					if (this.applicantType === 'Primary') {
						this.provinceName = arr['data'][0]['Province'];
					} else {
						this.provinceName = arr['data'][0]['Co_Applicant_Province'];
					}
					this.underwritingEntry.Created_By = this.userID;
					this.underwritingEntry.Modified_By = this.userID;
					this.underwritingEntry.Interviewer = this.userName;
					//this.getLookups()
					this.apiService
						.getUnderwritingEntry(this.zohoApplicationID, this.applicantType)
						.subscribe((res) => {
							if (!res['status']) console.log(res);
							else {
								if (res['data'].length == 0) {
									console.log(
										'No underwriting exists for this applicant type on the database'
									);
									this.underwritingEntry.Review_any_payday_loan_usage = '';
									this.underwritingEntry.Overdraft_Limit = 0.0;
									this.underwritingEntry.Is_this_a_joint_Account = '';
									this.underwritingEntry.Payday_Loans_Comments = '';
									this.underwritingEntry.Reason_for_falling_behind = '';
									this.underwritingEntry.When_was_the_last_time_you_filed_taxes =
										'';
									this.underwritingEntry.Have_you_setup_an_arrangement_with_the_CRA =
										'';
									this.underwritingEntry.Dependents = '';
									this.underwritingEntry.How_much_do_you_owe_Revenue_Canada =
										null;
									this.underwritingEntry.Do_you_live_with_anyone_else = '';
									this.underwritingEntry.Are_you_a_homeowner = '';
									this.underwritingEntry.Is_there_a_mortgage_on_the_property =
										'';
									this.underwritingEntry.Purview_Value = null;
									this.underwritingEntry.What_is_the_balance_of_your_mortgage =
										null;
									this.underwritingEntry.Do_you_owe_any_property_tax_Howe_owners_only =
										'';
									this.underwritingEntry.Who_is_your_mortgage_with = '';
									this.underwritingEntry.What_is_current_value_of_the_property =
										null;
									this.underwritingEntry.What_interest_rate_on_your_mortgage =
										null;
									this.underwritingEntry.LTV = '0';
									this.underwritingEntry.Other_Bank_Account = '';
									this.underwritingEntry.Co_Applicant_Other_Bank_Account = '';
									this.underwritingEntry['Total_Monthly_Income'] =
										this.n_total_monthly_income;
									this.n_total_monthly_income = 0;
									this.n_tdsr = 0;
									this.getUnderwritingExpenses();
									this.displayTotals();
								} else {
									//Underwriting Already exists so prepopulate the variables
									console.log(
										'Underwriting already exists for this applicant type'
									);
									this.underwritingEntry.Review_any_payday_loan_usage =
										res['data'][0]['Review_any_payday_loan_usage'];
									this.underwritingEntry.Overdraft_Limit =
										res['data'][0]['Overdraft_Limit'];
									this.underwritingEntry.Is_this_a_joint_Account =
										res['data'][0]['Is_this_a_joint_Account'];
									this.underwritingEntry.Payday_Loans_Comments =
										res['data'][0]['Payday_Loans_Comments'];
									this.underwritingEntry.Reason_for_falling_behind =
										res['data'][0]['Reason_for_falling_behind'];
									this.underwritingEntry.When_was_the_last_time_you_filed_taxes =
										res['data'][0]['When_was_the_last_time_you_filed_taxes'];
									this.underwritingEntry.Have_you_setup_an_arrangement_with_the_CRA =
										res['data'][0][
										'Have_you_setup_an_arrangement_with_the_CRA'
										];
									this.underwritingEntry.Dependents =
										res['data'][0]['Dependents'];
									try {
										this.underwritingEntry.How_much_do_you_owe_Revenue_Canada =
											parseFloat(
												res['data'][0]['How_much_do_you_owe_Revenue_Canada']
											);
									} catch (err) {
										this.underwritingEntry.How_much_do_you_owe_Revenue_Canada =
											null;
									}
									console.log(
										'_________________________________________________________________________________________________________'
									);
									// console.log(
									// 	this.underwritingEntry.How_much_do_you_owe_Revenue_Canada
									// );
									this.underwritingEntry.Do_you_live_with_anyone_else =
										res['data'][0]['Do_you_live_with_anyone_else'];
									this.underwritingEntry.Are_you_a_homeowner =
										res['data'][0]['Are_you_a_homeowner'];
									this.underwritingEntry.Is_there_a_mortgage_on_the_property =
										res['data'][0]['Is_there_a_mortgage_on_the_property'];
									this.underwritingEntry.Purview_Value = parseFloat(
										res['data'][0]['Purview_Value']
									);
									this.underwritingEntry.What_is_the_balance_of_your_mortgage =
										res['data'][0]['What_is_the_balance_of_your_mortgage'];
									this.underwritingEntry.Do_you_owe_any_property_tax_Howe_owners_only =
										res['data'][0][
										'Do_you_owe_any_property_tax_Howe_owners_only'
										];
									this.underwritingEntry.Who_is_your_mortgage_with =
										res['data'][0]['Who_is_your_mortgage_with'];
									this.underwritingEntry.What_is_current_value_of_the_property =
										res['data'][0]['What_is_current_value_of_the_property'];
									this.underwritingEntry.What_interest_rate_on_your_mortgage =
										res['data'][0]['What_interest_rate_on_your_mortgage'];
									this.underwritingEntry.Other_Bank_Account =
										res['data'][0]['Other_Bank_Account'];
									this.underwritingEntry.Co_Applicant_Other_Bank_Account =
										res['data'][0]['Co_Applicant_Other_Bank_Account'];
									this.underwritingEntry.Overdraft_Limit_Question =
										res['data'][0]['Overdraft_Limit_Question'];
									this.underwritingEntry.Is_this_a_joint_Account_Note =
										res['data'][0]['Is_this_a_joint_Account_Note'];
									this.underwritingEntry.LTV = res['data'][0]['LTV'].toString();

									this.getUnderwritingExpenses();
									this.underwritingEntry.What_interest_rate_on_your_mortgage;
									this.n_current_loan_amount_payments =
										this.underwritingEntry['Scheduled_Payments_Monthly'];
									this.n_total_monthly_income =
										res['data'][0]['Total_Monthly_Income'];
									this.n_tdsr = 0;
									this.displayTotals();

									// this.underwritingEntry.Modified_By = this.userID
									// this.underwritingEntry.Interviewer = this.userName
								}
							}
						});
				} else {
					console.log('API call failed for ZOHO deals');
				}
			});
	}
	deleteUnderwritingEntry() {
		this.errorMessage = [];
		if (confirm('Are you sure you want to reset the form?')) {
			if (this.userRole === 'UW' || this.userRole === 'FO') {
				this.apiService
					.deleteUnderwritingEntry(this.zohoApplicationID, this.applicantType)
					.subscribe((res) => {
						if (res['status']) {
							document.getElementById('messageDisplay').innerHTML =
								'Reset Successful!';
							document.getElementById('messageDisplay').style.color = 'green';
							document.getElementById('messageDisplay').style.display = 'block';
							this.successMessage = true;
							this.resetForm();
						} else {
							console.log(
								'ERROR DELETING UNDERWRITING____________________________________________________________'
							);
							this.errorMessage.push(res['data']);
							// document.getElementById('messageDisplay').innerHTML = "Reset unsuccessful!"
							// document.getElementById('messageDisplay').style.color = 'red'
							// document.getElementById('messageDisplay').style.display = 'block'
						}
					});
			} else {
				document.getElementById('messageDisplay').innerHTML =
					'Reset not allowed. Contact IT!';
				document.getElementById('messageDisplay').style.color = 'red';
				document.getElementById('messageDisplay').style.display = 'block';
				this.warningMessage = true;
			}
		}
	}
	resetForm() {
		this.underwritingEntry.Review_any_payday_loan_usage = '';
		this.underwritingEntry.Overdraft_Limit = 0.0;
		this.underwritingEntry.Is_this_a_joint_Account = '';
		this.underwritingEntry.Payday_Loans_Comments = '';
		this.underwritingEntry.Reason_for_falling_behind = '';
		this.underwritingEntry.When_was_the_last_time_you_filed_taxes = '';
		this.underwritingEntry.Have_you_setup_an_arrangement_with_the_CRA = '';
		this.underwritingEntry.Dependents = '';
		this.underwritingEntry.How_much_do_you_owe_Revenue_Canada = null;
		this.underwritingEntry.Do_you_live_with_anyone_else = '';
		this.underwritingEntry.Are_you_a_homeowner = '';
		this.underwritingEntry.Is_there_a_mortgage_on_the_property = '';
		this.underwritingEntry.Purview_Value = null;
		this.underwritingEntry.What_is_the_balance_of_your_mortgage = null;
		this.underwritingEntry.Do_you_owe_any_property_tax_Howe_owners_only = '';
		this.underwritingEntry.Who_is_your_mortgage_with = '';
		this.underwritingEntry.What_is_current_value_of_the_property = null;
		this.underwritingEntry.What_interest_rate_on_your_mortgage = null;
		this.underwritingEntry.LTV = '0';
		//Reset the Form here
		this.resetExpenses();
	}
	resetExpenses() {
		let listArray = this.userForm.get(
			'ADDITIONAL_EXPENSES'
		) as UntypedFormArray;
		while (listArray.length !== 0) {
			listArray.removeAt(0);
		}
	}
	userForm = new UntypedFormGroup({
		// Total additional debt
		debtTotal: new UntypedFormControl({ value: '', disabled: true }),
		expenseTotal: new UntypedFormControl({ value: '', disabled: true }),
		tdsr: new UntypedFormControl({ value: '', disabled: true }),
		loan_amount_payments_monthly: new UntypedFormControl({
			value: 0,
			disabled: true,
		}),
		total_monthly_income: new UntypedFormControl({ value: 0, disabled: false }),
		// Subform ADDITIONAL DEBT
		Additional_Debt: this.fb.array([]),
		//Expenses
		ADDITIONAL_EXPENSES: this.fb.array([]),
	});
	async updateUnderwritingModuleZoho() {
		return new Promise((resolve, reject) => {
			console.log('Updating Underwriting Module');
			console.log(this.underwritingEntry);
			let underwritingIDApp = '';
			if (
				this.applicantType == 'Primary' &&
				(this.underwritingEntry['Underwriting_ID'] == '' ||
					!this.underwritingEntry['Underwriting_ID'])
			) {
				this.errorMessage.push(
					'Primary Underwriting Module does not exist on ZOHO'
				);
				console.log(this.errorMessage);
				resolve(false);
			} else if (
				this.applicantType == 'Co-applicant' &&
				(this.underwritingEntry['Underwriting_ID_Co_Applicant'] == '' ||
					!this.underwritingEntry['Underwriting_ID_Co_Applicant'])
			) {
				this.errorMessage.push(
					'Co-Applicant Underwriting Module does not exist on ZOHO'
				);
				console.log(this.errorMessage);
				resolve(false);
			} else {
				this.apiService
					.updateUnderwritingZoho(
						this.underwritingID,
						this.underwritingEntry,
						this.userForm.get('ADDITIONAL_EXPENSES').value
					)
					.subscribe((res) => {
						console.log('Updating Underwriting Module on ZOHO');
						console.log(res);
						try {
							let something = JSON.parse(res['message']['body']);
							if (something['data'][0]['code'] == 'SUCCESS') {
								console.log(something['data'][0]['message']);
								resolve(true);
							} else {
								console.log(
									'ERROR UPDATING UNDERWRITING MODULE ON ZOHO_______________________________'
								);
								console.log(something['data'][0]);
								this.errorMessage.push(JSON.stringify(something['data'][0]));
								console.log(this.errorMessage);
								resolve(false);
							}
						} catch (err) {
							console.log('Syntax Error');
							this.errorMessage.push(err);
							console.log(this.errorMessage);
							resolve(false);
						}
					});
			}
		});
	}
	async userSubmit() {
		if (isNaN(Number(this.underwritingEntry?.Bank_Account_Number))) {
			document.getElementById('messageDisplay').innerHTML =
				'Save not allowed. Bank account must be a number!';
			document.getElementById('messageDisplay').style.color = 'red';
			document.getElementById('messageDisplay').style.display = 'block';
			return;
		}
		this.errorMessage = [];
		try {
			document.getElementById('messageDisplay').innerHTML = 'Save in Progress!';
			document.getElementById('messageDisplay').style.color = 'orange';
			document.getElementById('messageDisplay').style.display = 'block';
		} catch (err) {
			console.log(err);
		}

		this.saveInProgress = true;
		if (this.userRole === 'UW' || this.userRole === 'FO') {
			console.log(this.userRole, "***********************************")
			if (await this.insertUnderwriting()) {
				this.getInterviewerInfo();
				this.apiService
					.updateZohoFromUnderwriting(
						this.underwritingEntry,
						this.zohoApplicationID,
						this.applicantType
					)
					.subscribe((res) => {
						if (res.message.statusCode == '200') {
							document.getElementById('messageDisplay').innerHTML =
								'Save Successful!';
							document.getElementById('messageDisplay').style.color = 'green';
							document.getElementById('messageDisplay').style.display = 'block';
							this.successMessage = true;
							this.saveInProgress = false;
						} else {
							console.log(
								'ERROR INSERTING INFORMATION BACK TO ZOHO APPLICATION'
							);
							console.log(res);
							this.errorMessage.push(res['message']['body']);
							console.log(this.errorMessage);
							this.saveInProgress = false;
							document.getElementById('messageDisplay').innerHTML =
								'Save unsuccessful!';
							document.getElementById('messageDisplay').style.color = 'red';
							document.getElementById('messageDisplay').style.display = 'block';
						}
					});
			} else {
				this.saveInProgress = false;
				document.getElementById('messageDisplay').innerHTML =
					'Save unsuccessful!';
				document.getElementById('messageDisplay').style.color = 'red';
				document.getElementById('messageDisplay').style.display = 'block';
			}
		} else {
			this.saveInProgress = false;
			document.getElementById('messageDisplay').innerHTML =
				'Save not allowed. Contact IT!';
			document.getElementById('messageDisplay').style.color = 'red';
			document.getElementById('messageDisplay').style.display = 'block';
		}
	}

	async insertUnderwriting() {
		return new Promise((resolve, reject) => {
			this.underwritingEntry.applicationID = this.zohoApplicationID;
			this.underwritingEntry.Applicant_Type = this.applicantType;
			this.apiService
				.createUnderwriting(this.underwritingEntry, this.userForm.value)
				.subscribe((res) => {
					console.log('Attemting to Update Local Database');
					console.log(res);
					if (res['status']) {
						console.log(res);
						resolve(true);
					} else {
						resolve(false);
						console.log(
							'ERROR INSERTING INTO UNDERWRITING DATABASE___________________________________'
						);
						this.errorMessage.push(JSON.stringify(res['data']));
						console.log(res);
					}
				});
		});
	}

	formatMoney(str: any) {
		const formatStr = new Intl.NumberFormat('en-CA', {
			style: 'currency',
			currency: 'CAD',
		}).format(str);
		return formatStr;
	}

	// getUnderwritingAddDebts(zohoApplicationID: any){
	//   this.apiService.getUnderwritingAddDebts(zohoApplicationID).subscribe((res)=>{
	//     // console.log(res)
	//     if(res.message.statusCode == '200'){
	//       let arr = JSON.parse(res.message.body)
	//       // console.log(arr.data)
	//       this.additionalDebt = arr.data[0]['Additional_Debt']
	//       this.totalDebts = arr.data[0]['Total']
	//     }else{
	//       console.log("API call failed for ZOHO deals")
	//     }
	//   })
	// }

	getLookups() {
		this.apiService.getLookupsdeals().subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			// this.emp_type = arr.fields['Employment_Status'].pick_list_values let arr;
			const emp_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Employment_Status1'
			);
			this.emp_type_look_up = emp_result[0]['pick_list_values'];

			const co_app_emp_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Co_Applicant_Status'
			);
			this.co_app_emp_type_look_up = co_app_emp_result[0]['pick_list_values'];

			const industry_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Industry'
			);
			this.industry_look_up = industry_result[0]['pick_list_values'];
			// console.log("___________________________")
			// console.log(this.industry_look_up)
			this.dropdownList_Primary_Ind = this.industry_look_up;
			//this.onItemSelect_Primary_Ind(this.selectedItems_Primary_Ind[0])
			this.dropdownSettings_Primary_Ind = {
				singleSelection: true,
				idField: 'display_value',
				textField: 'display_value',
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				itemsShowLimit: 3,
				allowSearchFilter: true,
			};
			const occupation_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Occupation'
			);
			this.occupation_look_up = occupation_result[0]['pick_list_values'];
			// console.log(this.occupation_look_up);
			// console.log('+++++++++++++++++++++++++++++++++++++++');
			this.dropdownList_Primary_Occ = this.occupation_look_up;
			this.onItemSelect_Primary_Occ(this.selectedItems_Primary_Occ[0]);
			this.dropdownSettings_Primary_Occ = {
				singleSelection: true,
				idField: 'display_value',
				textField: 'display_value',
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				itemsShowLimit: 3,
				allowSearchFilter: true,
			};
			//get lookups for coapplicant occupation and industry
			const occupation_look_up_c = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Co_Applicant_Occupation'
			);
			this.occupation_coapp_look_up =
				occupation_look_up_c[0]['pick_list_values'];
			// console.log("Occupation Lookup_______________________")
			// console.log(this.occupation_coapp_look_up)
			this.dropdownList_Co_Occ = this.occupation_coapp_look_up;
			this.onItemSelect_Co_Occ(this.selectedItems_Co_Occ[0]);
			this.dropdownSettings_Co_Occ = {
				singleSelection: true,
				idField: 'display_value',
				textField: 'display_value',
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				itemsShowLimit: 3,
				allowSearchFilter: true,
			};

			const industry_look_up_c = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Co_Applicant_Industry'
			);
			this.industry_coapp_look_up = industry_look_up_c[0]['pick_list_values'];
			// console.log('Inudstry______________________');
			// console.log(this.industry_coapp_look_up);
			this.dropdownList_Co_Ind = this.industry_coapp_look_up;
			this.onItemSelect_Co_Ind(this.selectedItems_Co_Ind[0]);
			this.dropdownSettings_Co_Ind = {
				singleSelection: true,
				idField: 'display_value',
				textField: 'display_value',
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				itemsShowLimit: 3,
				allowSearchFilter: true,
			};

			const salary_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Pay_Frequency2'
			);
			this.salary_frequency_look_up = salary_result[0]['pick_list_values'];
			// console.log(
			// 	'Salary frequency options_____________________________________________'
			// );
			// console.log(this.salary_frequency_look_up);

			const salary_result1 = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Pay_Frequency_Household_Income'
			);
			this.salary_frequency_household_look_up =
				salary_result1[0]['pick_list_values'];

			const pay_method_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Payment_Method'
			);
			this.pay_method_look_up = pay_method_result[0]['pick_list_values'];

			// Stage
			this.stageName = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Stage'
			)[0]['pick_list_values'];
			// console.log(this.stageName,"STAGEEEEEEEEEEEEEEEEEEEEEEEEEEE")
		});

		this.apiService.getLookupsunderwriting().subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			const override_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Override'
			);
			this.override_look_up = override_result[0]['pick_list_values'];

			const override_type_result = this.customFunction.singleFilterName(
				arr.fields,
				'api_name',
				'Override_Type'
			);
			this.override_type_look_up = override_type_result[0]['pick_list_values'];
		});
	}
	// onItemSelect_Primary_Ind(item: any) {
	//   console.log(item)
	//   this.selectedItems_Primary_Ind = []
	//   this.selectedItems_Primary_Ind.push(item)
	//   this.underwritingEntry.Industry = item.display_value
	// }
	onItemSelect_Co_Ind(item: any) {
		// console.log(item);
		this.selectedItems_Co_Ind = [];
		this.selectedItems_Co_Ind.push(item);
		this.underwritingEntry.Co_Applicant_Industry = item.display_value;
	}
	onItemSelect_Primary_Occ(item: any) {
		// console.log(item);
		this.selectedItems_Primary_Occ = [];
		this.selectedItems_Primary_Occ.push(item);
		this.underwritingEntry.Occupation = item.display_value;
	}
	onItemSelect_Co_Occ(item: any) {
		// console.log('____________________------_______________');
		// console.log(item);
		this.selectedItems_Co_Occ = [];
		this.selectedItems_Co_Occ.push(item);
		this.underwritingEntry.Co_Applicant_Occupation = item.display_value;
	}
	validateForm() {
		if (this.applicantType == 'Primary') {
			if (!this.bankingValidationPrimary_InsNumber()) {
				alert('Invalid Insitution Number');
				return false;
			} else if (!this.bankingValidationPrimary_TransNumber()) {
				alert('Invalid Transit Number');
				return false;
			} else return true;
		} else if (this.applicantType == 'Co-applicant') {
			if (!this.bankingValidationCo_Applicant_InsNumber()) {
				alert('Invalid Insitution Number');
				return false;
			} else if (!this.bankingValidationCo_Applicant_TransNumber()) {
				alert('Invalid Transit Number');
				return false;
			} else return true;
		} else {
			alert('Invalid Applicant Type');
		}
	}
	click_bankingValidationPrimary_InsNumber() {
		document.addEventListener('click', (e) => {
			this.bankingValidationPrimary_InsNumber();
		});
	}
	click_bankingValidationCo_Applicant_InsNumber() {
		document.addEventListener('click', (e) => {
			this.bankingValidationCo_Applicant_InsNumber();
		});
	}

	// Only Accept numbers
	numberOnly(e: any) {
		if (this.specialKeys.indexOf(e.key) !== -1) {
			return;
		}
		const inputValue: string = e.key;
		if (!inputValue.match(this.regex)) {
			e.preventDefault();
		}
	}

	bankingValidationPrimary_InsNumber(): Boolean {
		let zeroes_added = '';
		if (this.underwritingEntry.Financial_Institution_Number.length > 4) {
			console.log(this.underwritingEntry.Financial_Institution_Number.length);
			console.log('Invalid Institution Number');
			return false;
		} else if (
			this.underwritingEntry.Financial_Institution_Number.length == 4
		) {
			console.log(this.underwritingEntry.Financial_Institution_Number.length);
			console.log('Length of Institution Number is Correct');
			return true;
		} else {
			for (
				var i = 0;
				i < 4 - this.underwritingEntry.Financial_Institution_Number.length;
				i++
			) {
				zeroes_added = '0' + zeroes_added;
			}
			this.underwritingEntry.Financial_Institution_Number =
				zeroes_added + this.underwritingEntry.Financial_Institution_Number;
			return true;
		}
	}
	bankingValidationCo_Applicant_InsNumber(): Boolean {
		let zeroes_added = '';
		if (
			this.underwritingEntry.Co_ApplicantFinancial_Institution_Number1.length >
			4
		) {
			console.log('Invalid Institution Number');
			return false;
		} else if (
			this.underwritingEntry.Co_ApplicantFinancial_Institution_Number1.length ==
			4
		) {
			console.log('Length of Instituion Number is Correct');
			return true;
		} else {
			for (
				var i = 0;
				i <
				4 -
				this.underwritingEntry.Co_ApplicantFinancial_Institution_Number1
					.length;
				i++
			) {
				zeroes_added = '0' + zeroes_added;
			}
			this.underwritingEntry.Co_ApplicantFinancial_Institution_Number1 =
				zeroes_added +
				this.underwritingEntry.Co_ApplicantFinancial_Institution_Number1;
			return true;
		}
	}
	bankingValidationPrimary_TransNumber(): Boolean {
		if (this.underwritingEntry.Branch_Transit_Number.toString().length != 5) {
			console.log('Invalid Transit Number');
			console.log(this.underwritingEntry.Branch_Transit_Number);
			return false;
		} else return true;
	}
	bankingValidationCo_Applicant_TransNumber() {
		if (
			this.underwritingEntry.Co_Applicant_Branch_Transit_Number.toString()
				.length != 5
		) {
			console.log('Invalid Transit Number');
			console.log();
			return false;
		} else return true;
	}
	editToStartTimer() {
		this.errorMessage = [];
		this.timeInfo = {
			applicationID: this.zohoApplicationID,
			applicantType: this.applicantType,
			duration: 0,
			underwriter: this.userName,
			start_date: new Date(),
			end_date: '',
			action: 'Loan Interview',
		};
		this.readOnly = false;
		this.running = !this.running;
		if (this.running) {
			const startTime = Date.now() - (this.counter || 0);
			this.timerRef = setInterval(() => {
				this.counter = Date.now() - startTime;
				this.display_time = this.editTime(this.counter);
			});
		} else {
			clearInterval(this.timerRef);
		}
	}
	editTime(milliseconds) {
		return new Date(milliseconds).toISOString().slice(11, 19);
	}

	clearTimer() {
		this.errorMessage = [];
		this.timeInfo.end_date = new Date();
		this.timeInfo.duration = this.counter;
		this.counter = 0;
		//Enter the time info in the table
		this.apiService
			.recordLoanInterviewDuration(this.timeInfo)
			.subscribe((res) => {
				console.log('Insert Timing Info into Duration Table');
				console.log(res);
			});
		this.apiService
			.updateLoanInterviewComplete(this.zohoApplicationID, this.applicantType)
			.subscribe((res) => {
				console.log('Update Loan Interview Complete');
				console.log(this.zohoApplicationID);
				console.log(res);
			});
		//Update Underwriting Interviewer on Underwriting table
		this.apiService
			.updateInterviewerUnderwriting(
				this.zohoApplicationID,
				this.applicantType,
				this.userName
			)
			.subscribe((res) => {
				console.log('Updating InterViewer Underwriting Table');
				console.log(res);
			});
		this.readOnly = true;
		this.running = false;
		clearInterval(this.timerRef);
		this.editMode();
		this.getInterviewerInfo();
		this.userSubmit();
	}
	editMode() {
		this.readOnly = false;
		try {
			document.getElementById('messageDisplay').style.display = 'none';
		} catch (err) {
			console.log(err);
		}
	}
	lockedMode() {
		this.readOnly = true;
	}
	switchApplicantType() {
		let listArray = this.userForm.get(
			'ADDITIONAL_EXPENSES'
		) as UntypedFormArray;
		while (listArray.length !== 0) {
			listArray.removeAt(0);
		}
		this.getUnderwritingExpenses();
		this.calculateNextPayDate(
			this.underwritingEntry.Last_Pay_Date,
			this.underwritingEntry.Pay_Frequency2
		);
		//this.checkForUnderwritingEntry()
		// this.getZohoApplicationSettings()
		this.getTransunionStats();
		// this.getUnderwritingAddDebts(this.zohoApplicationID);
		// this.getLookups();
	}
	calculateNextPayDate(lastPayDate, frequency) {
		document.addEventListener('click', (e) => {
			if (this.underwritingEntry.Last_Pay_Date) {
				console.log('Trying to Calculate last pay date');
				var dt = new Date(lastPayDate);
				switch (frequency) {
					case 'Bi-weekly': {
						this.underwritingEntry.Next_Pay_Date = this.datepipe.transform(
							dt.setDate(dt.getDate() + 14),
							'yyyy-MM-dd'
						);
						break;
					}
					case 'Weekly': {
						this.underwritingEntry.Next_Pay_Date = this.datepipe.transform(
							dt.setDate(dt.getDate() + 7),
							'yyyy-MM-dd'
						);
						break;
					}
					case 'Monthly': {
						this.underwritingEntry.Next_Pay_Date = this.datepipe.transform(
							dt.setMonth(dt.getMonth() + 1),
							'yyyy-MM-dd'
						);
						break;
					}
					case 'Twice monthly': {
						this.underwritingEntry.Next_Pay_Date = this.datepipe.transform(
							dt.setDate(dt.getDate() + 15),
							'yyyy-MM-dd'
						);
						break;
					}
					case '15th and EOM': {
						if (dt.getUTCDate() == 15) {
							this.underwritingEntry.Next_Pay_Date = this.datepipe.transform(
								new Date(dt.getFullYear(), dt.getMonth() + 1, 0),
								'yyyy-MM-dd'
							);
						} else if (
							this.datepipe.transform(dt, 'yyyy-MM-dd') ==
							this.datepipe.transform(
								new Date(dt.getFullYear(), dt.getMonth() + 1, 0),
								'yyyy-MM-dd'
							)
						) {
							this.underwritingEntry.Next_Pay_Date = this.datepipe.transform(
								new Date(dt.getFullYear(), dt.getMonth() + 1, 15),
								'yyyy-MM-dd'
							);
						} else {
							console.log(
								'Last Pay Date must be either end of month or 15th of month'
							);
						}
						break;
					}
					default: {
						console.log('Payment Frequency Does Not Exist');
					}
				}
			}
		});
	}
	openMonthlyIncomeCalc() {
		const dialogRef = this.dialog.open(MonthlyincomecalcComponent);

		dialogRef.afterClosed().subscribe((result) => {
			this.monthly_income_calc_result = result;
			this.n_total_monthly_income = parseFloat(
				this.monthly_income_calc_result['data']['averageSalary']
			);
			this.underwritingEntry['Total_Monthly_Income'] =
				this.n_total_monthly_income;
			console.log('MONTHLY INCOME CALC RESULT _______________________________');
			console.log(this.monthly_income_calc_result);
			console.log(this.underwritingEntry);
			this.addExpenses();
		});
	}
	clearErrors() {
		this.warningMessage = false;
		this.successMessage = false;
		this.errorMessage = [];
	}
	clearOverdraftLimit() {
		if (this.underwritingEntry.Overdraft_Limit_Question == 'No') {
			this.underwritingEntry.Overdraft_Limit = null;
		}
	}
	clearJointAccount() {
		if (this.underwritingEntry.Is_this_a_joint_Account == 'No') {
			this.underwritingEntry.Is_this_a_joint_Account_Note = '';
		}
	}
	quickSave() {
		console.log('SAVING HERE');
	}

	saveStage() {
		this.controls.displayMessage(
			'saveStageButton',
			true,
			'saveStageMessage',
			'Save in progress...',
			'orange',
			'block'
		);
		this.apiService
			.updateSummaryStage(this.underwritingEntry, this.zohoApplicationID)
			.subscribe((res) => {
				if (res['message']['statusCode'] == '200') {
					this.controls.displayMessage(
						'saveStageButton',
						false,
						'saveStageMessage',
						'Save Successful',
						'green',
						'block'
					);
					console.log('Stage Save Success!');
				} else {
					this.controls.displayMessage(
						'saveStageButton',
						false,
						'saveStageMessage',
						'Save unsuccessful',
						'red',
						'block'
					);
					console.log(res);
				}
			});
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}
