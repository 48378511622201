<div class="container-fluid" style="padding: 20px;font-size: 0.3em;">
	<div class="row row-cols-12">
		<div class="col-12">
			<h3>{{employeeType}} Call Reports</h3>
			<table class="table table-bordered table-hover ptp-table">
				<thead>
					<tr>
						<th rowspan="2">Name</th>
						<th colspan="2">Inbound</th>
						<th colspan="2">Outbound</th>
						<th colspan="3">Total</th>
					</tr>
					<tr>
						<th>No of Calls</th>
						<th>Total Call Time</th>
						<th>No of Calls</th>
						<th>Total Call Time</th>
						<th>Total No Calls</th>
						<th>Total Call Time</th>
						<th>Files Touched</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of EmployeesCallData | keyvalue">
						<td>{{ item.key }}</td>
						<td>{{ item.value?.inboundTotalCalls }}</td>
						<td>{{ item.value?.inboundTotalCallTimeFormatted }}</td>
						<td>{{ item.value?.outboundTotalCalls }}</td>
						<td>{{ item.value?.outboundTotalCallTimeFormatted }}</td>
						<td>{{ item.value?.totalCalls }}</td>
						<td>{{ item.value?.totalCallTimeFormatted }}</td>	
						<td>{{ item.value?.filesTouched }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
