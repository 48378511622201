import { Component, Input, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { Router } from '@angular/router';
//import { MatDialog } from '@angular/material/dialog'

@Component({
	selector: 'app-read',
	templateUrl: './read.component.html',
	styleUrls: ['./read.component.scss'],
})
export class ReadComponent implements OnInit {
	constructor(private apiService: ApiServiceService, private _router: Router) {
		this.apiService.getClickEvent().subscribe(() => {
			console.log('Refresh Display');
			this.getAllData();
		});
	}

	readData: any; // This is to use it on hmtl to display information getAllData()

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						// Reload the current document
						this.getAllData();
					} else {
						this._router.navigate(['login']);
					}
				});
		} else {
			this._router.navigate(['login']);
		}
	}

	// Delete the record/applicant row
	deleteID(id: any) {
		if (confirm('Are you sure, you want to delete the client selected?')) {
			//console.log(id, "delete==>");
			this.apiService.deleteData(id).subscribe((res) => {
				//console.log(res, "inside==>");
				this.getAllData();
			});
		}
	}

	// Display the data on the applicants on the user management portal
	getAllData() {
		this.apiService.getAllData().subscribe((res) => {
			this.readData = res.data;
		});
	}
}
