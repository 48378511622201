<div class="container-fluid">
	<!-- <div  *ngIf="existingPrimaryClientInfo" class="alert alert-info alert-dismissible " role="alert">
        <span id="existingPrimaryClientInfo">Existing Client:<br>{{existingPrimaryClientInfo}}<br>{{existingCoAppClientInfo}}</span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div> -->
	<div *ngIf="warningMessage" id="hideChecklistCoAppWarning" class="alert alert-warning alert-dismissible"
		role="alert">
		<span id="checklistCoAppWarning">{{ warningMessage }}</span>
		<!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
	</div>
	<div class="row">
		<div class="col-2" *ngIf="coAppRequired === 'Yes'; else elseCoAppRequired">
			<select class="form-select" (change)="clickType($event.target.value)">
				<option value="Primary Applicant" selected>Primary Applicant</option>
				<option value="Co-Applicant">Co-Applicant</option>
			</select>
		</div>
		<ng-template #elseCoAppRequired>
			<div class="col-2">
				<select class="form-select" (change)="clickType($event.target.value)">
					<option value="Primary Applicant" selected>Primary Applicant</option>
				</select>
			</div>
		</ng-template>
		<!-- <div class="col-2">
            <h6><strong>Frontend:</strong> {{completeStatusCheck(this.checklistEntry.frontEndForm.get('frontend_status'))}}</h6>
        </div>
        <div class="col-2">
            <h6><strong>Backend:</strong> {{completeStatusCheck(this.checklistEntry.backEndForm.get('backend_status'))}}</h6>
        </div>
        <div class="col-2">
            <h6><strong>Funding:</strong> {{completeStatusCheck(this.checklistEntry.fundingForm.get('funding_status'))}}</h6>
        </div> -->
	</div>
	<br />
	<div class="row">
		<span class="spinner-border" id="loading" *ngIf="spinner === true"></span>
		<div class="col">
			<div class="card">
				<div class="card-header">
					<span class="card-head">Frontend </span>
					<span class="card-head-status">({{ frontendCompleted }})</span>
					<!-- <span class="card-head-status">({{completeStatusCheck(this.checklistEntry.frontEndForm.get('frontend_status'))}})</span> -->
				</div>
				<div class="card-body">
					<form [formGroup]="this.checklistEntry.frontEndForm">
						<div class="cb-wrapper">
							<mat-checkbox value="" color="primary" formControlName="valid_id">Valid ID</mat-checkbox>
							<mat-checkbox value="" color="primary" formControlName="date_of_birth">Date of Birth
								(Confirm client age < 70)</mat-checkbox>
									<mat-checkbox value="" color="primary" formControlName="correct_address">Correct
										address (Confirm client is not living in reserve)</mat-checkbox>
									<input class="form-control" type="text" autocapitalize="off" spellcheck="false"
										id="keyboard" #address formControlName="populate_address" />
									<button class="btn btn-outline-secondary" (click)="populateText()">Populate</button>
									<mat-checkbox value="" color="primary" formControlName="consent_signed">Consent
										signed</mat-checkbox>
									<mat-checkbox value="" color="primary" formControlName="credit_bureau_pulled">Credit
										Bureau Pulled</mat-checkbox>
									<mat-checkbox value="" color="primary"
										formControlName="income_is_more_than_1200">Verify income is more than
										$1200.00</mat-checkbox>
									<mat-checkbox value="" color="primary" formControlName="verify_tdsr">Verify
										TDSR</mat-checkbox>
									<mat-checkbox value="" color="primary" formControlName="verify_credit_score">Verify
										Credit Score</mat-checkbox>
									<mat-checkbox value="" color="primary"
										formControlName="creditor">Creditor</mat-checkbox>
									<mat-checkbox value="" color="primary"
										formControlName="loan_interview_complete">Loan interview complete</mat-checkbox>
									<mat-checkbox value="" color="primary"
										formControlName="final_decision_complete">Final Decision Complete
										(Approved/Declined) Signed loan agreement</mat-checkbox>
									<label class="form-label" for="front13">Existing Client?</label>
									<select class="form-select" formControlName="existing_client" id="front13">
										<option value="" selected disabled hidden>-None-</option>
										<option *ngFor="let i of this.checklistEntry.refinance_list"
											[value]="i.actual_value">{{ i.display_value }}</option>
									</select>
									<div class="form-check" hidden>
										<input class="form-check-input" type="checkbox" value="" id="front12"
											formControlName="frontend_status" />
										<label class="form-check-label" for="front12">Frontend Complete</label>
									</div>
									<label class="form-label">Frontend Officer*</label>
									<select class="form-select" formControlName="frontend_officer" required>
										<option value="" selected disabled hidden>-None-</option>
										<option *ngFor="let i of this.underwriter_names" [value]="i.employeeID">{{
											i.full_name }}</option>
									</select>
						</div>
						<small
							*ngIf="this.checklistEntry.frontEndForm.controls['frontend_officer'].hasError('required') && this.checklistEntry.frontEndForm.controls['frontend_officer'].touched"
							class="form-error-msg">Frontend Officer name required!<br />
						</small>
					</form>
					<h4 id="saveFrontendMsg"></h4>
					<button id="saveFrontendBtn" mat-raised-button color="primary"
						style="width: 150px; text-align: center;" (click)="submitFrontEndChecklist()"
						[disabled]="this.checklistEntry.frontEndForm.invalid">
						Save
					</button>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-header">
					<span class="card-head">Backend </span>
					<span class="card-head-status">({{ backendCompleted }})</span>
					<!-- <span class="card-head-status">({{completeStatusCheck(this.checklistEntry.backEndForm.get('backend_status'))}})</span> -->
				</div>
				<div class="card-body">
					<form [formGroup]="this.checklistEntry.backEndForm">
						<div class="cb-wrapper">
							<mat-checkbox value="" color="primary" formControlName="banking_info_verified">Banking info
								verified</mat-checkbox>
							<div *ngIf="
								this.applicantType !== 'Co-Applicant' && LPP_Included === 'Yes'
							">
								<mat-checkbox value="" color="primary" formControlName="lpp_included">LPP
									included</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="sif_pif_letter">SIF/PIF letter
									from agency</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="payment_instructions">Clear
									payment instructions</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="date_of_sif_pif">Date of
									SIF/PIF</mat-checkbox>
							</div>
							<mat-checkbox value="" color="primary" formControlName="borrower_name_matches_pla">Name of
								borrower matches personal loan agreement</mat-checkbox>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="verify_loan_payment">Verify loan
									payment</mat-checkbox>
							</div>
							<div *ngIf="
								this.applicantType !== 'Co-Applicant' && LPP_Included === 'Yes'
							">
								<mat-checkbox value="" color="primary" formControlName="lpp_sold_by">LPP sold
									by</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="loan_agreement_prepared_by">Loan
									Agreement prepared by</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="loan_date">Loan
									Date</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="account_balance">Account
									Balance</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="account_number">Account
									Number</mat-checkbox>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="verify_ec">Verify
									EC</mat-checkbox>
							</div>
							<mat-checkbox value="" color="primary" formControlName="verify_poa">Verify
								POA</mat-checkbox>
							<mat-checkbox value="" color="primary" formControlName="verify_voe">Verify
								VOE</mat-checkbox>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<mat-checkbox value="" color="primary" formControlName="additional_debts">Additional
									debts</mat-checkbox>
							</div>
							<div *ngIf="
								this.applicantType !== 'Co-Applicant' &&
								this.checklistEntry.backEndForm.get('manager_exception')
									.value === 'No'
							">
								<mat-checkbox value="" color="primary" formControlName="final_approval_note">Final
									Approval Note</mat-checkbox>
							</div>
							<label class="form-check-label">Active judgement - BK/CP/CC?</label>
							<select class="form-select" formControlName="active_judgement">
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.checklistEntry.refinance_list" [value]="i.actual_value">{{
									i.display_value }}</option>
							</select>
							<label class="form-check-label">UW conditions
								met?</label>
							<select class="form-select" formControlName="uw_conditions_met">
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.checklistEntry.refinance_list" [value]="i.actual_value">{{
									i.display_value }}</option>
							</select>
							<label class="form-check-label" for="back22"
								*ngIf="this.applicantType !== 'Co-Applicant'">Manager Exception?</label>
							<select class="form-select" formControlName="manager_exception" id="back22"
								*ngIf="this.applicantType !== 'Co-Applicant'"
								(change)="onManagerExceptionChange($event.target.value)">
								<option *ngFor="let i of this.checklistEntry.refinance_list" [value]="i.actual_value">
									{{ i.display_value }}
								</option>
							</select>
							<div class="form-check" hidden>
								<input class="form-check-input" type="checkbox" value="" id="back21"
									formControlName="backend_status" />
								<label class="form-check-label" for="back21">
									Backend Complete
								</label>
							</div>
							<div *ngIf="this.applicantType !== 'Co-Applicant'">
								<label class="form-label">BDM Payment Method</label>
								<select class="form-select" formControlName="bdm_payment_method">
									<option value="" selected disabled hidden>-None-</option>
									<option *ngFor="let i of this.checklistEntry.funding_payment_method"
										[value]="i.display_value">
										{{ i.display_value }}
									</option>
								</select>
							</div>
							<label class="form-label">Backend Officer*</label>
							<select class="form-select" formControlName="backend_officer" required>
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.underwriter_names" [value]="i.employeeID">
									{{ i.full_name }}
								</option>
							</select>
							<small *ngIf="
								this.checklistEntry.backEndForm.controls[
									'backend_officer'
								].hasError('required') &&
								this.checklistEntry.backEndForm.controls['backend_officer']
									.touched
							" class="form-error-msg">Backend Officer name required!<br />
							</small>
						</div>
					</form>
					<h4 id="saveBackendMsg"></h4>
					<button mat-raised-button color="primary" style="width: 150px; text-align: center;"
						id="saveBackendBtn" (click)="submitBackEndChecklist()"
						[disabled]="this.checklistEntry.backEndForm.invalid">
						Save
					</button>
				</div>
			</div>
		</div>
		<div class="col" *ngIf="this.applicantType !== 'Co-Applicant'">
			<div class="card">
				<div class="card-header">
					<span class="card-head">Funding </span>
					<span class="card-head-status">({{
						completeStatusCheck(
						this.checklistEntry.fundingForm.get('funding_status')
						)
						}})</span>
				</div>
				<div class="card-body">
					<form [formGroup]="this.checklistEntry.fundingForm">
						<div class="cb-wrapper">
							<h5 class="card-title">Funding Check List</h5>
							<label class="form-check-label" for="funding1">
								Source of Funding
							</label>
							<select class="form-select" formControlName="source_of_funding"
								style="font-weight: bold; color: red" id="funding1">
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.checklistEntry.source_of_funding"
									[value]="i.display_value">
									{{ i.display_value }}
								</option>
							</select>
							<mat-checkbox value="" color="primary" formControlName="mft_lpp">MFT LPP</mat-checkbox>
							<label class="form-check-label" for="funding3">
								Funding Payment Method
							</label>
							<select class="form-select" formControlName="funding_payment_method" id="funding3"
								style="font-weight: bold; color: red">
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.checklistEntry.funding_payment_method"
									[value]="i.display_value">
									{{ i.display_value }}
								</option>
							</select>
							<label class="form-check-label" for="funding4">Refinance?</label>
							<select class="form-select" formControlName="refinance"
								style="font-weight: bold; color: red" id="funding4">
								<!-- <option value="" selected disabled hidden>-None-</option> -->
								<option *ngFor="let i of this.checklistEntry.refinance_list" [value]="i.actual_value">
									{{ i.display_value }}
								</option>
							</select>
							<label class="form-label" for="funding5"> Referral Agency </label>
							<input class="form-control" type="text" id="funding5" formControlName="referral_agency" />
							<label class="form-label" for="funding6"> Funding Officer* </label>
							<select class="form-select" formControlName="funding_officer" id="funding6" required>
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.funding_officers" [value]="i.employeeID">
									{{ i.full_name }}
								</option>
							</select>
							<small *ngIf="
								this.checklistEntry.fundingForm.controls[
									'funding_officer'
								].hasError('required') &&
								this.checklistEntry.fundingForm.controls['funding_officer']
									.touched
							" class="form-error-msg">Funding Officer name required!<br />
							</small>
							<!-- <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="funding4"
                                formControlName="amortization_schedule">
                            <label class="form-check-label" for="funding4">
                                Amortization Schedule
                            </label>
                        </div> -->

							<mat-checkbox value="" color="primary"
								formControlName="verified_all_documents_uploaded_correctly">Verified all documents are
								uploaded correctly</mat-checkbox>
							<!-- <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="funding6"
                                formControlName="balance_letters">
                            <label class="form-check-label" for="funding6">
                                Balance Letters </label>
                        </div> -->
							<div class="mb-3">
								<label for="formFile" class="form-label">Balance Letter Files</label>
								<input class="form-control" type="file" id="formFile"
									formControlName="balance_letters_file"
									(change)="onBalanceLetterChange($event.target.files)" multiple />
								<table>
									<tbody>
										<tr *ngFor="let item of balanceLetterFiles">
											<td style="width: 90%">
												<a (click)="
													getfile(zohoApplicationID, 'BalanceLetters', item)
												" class="showFile" title="{{ item }}">{{ item }}</a>
											</td>
											<td style="width: 10%" *ngIf="
												this.sessionUser['position'] === 'FO' ||
												this.sessionUser['position'] === 'UW'
											">
												<a (click)="
													deleteFile(zohoApplicationID, 'BalanceLetters', item)
												">
													<img src="../../../assets/images/trash.png" width="10"
														height="13" />
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="mb-3">
								<label for="formFile1" class="form-label">Amortization Schedule Files</label>
								<input class="form-control" type="file" id="formFile1"
									formControlName="amortization_schedule_file"
									(change)="onAmortizationChange($event.target.files)" multiple />
								<table>
									<tbody>
										<tr *ngFor="let item of amortizationFiles">
											<td style="width: 90%">
												<a (click)="
													getfile(
														zohoApplicationID,
														'AmortizationSchedule',
														item
													)
												" class="showFile" title="{{ item }}">{{ item }}</a>
											</td>
											<td style="width: 10%" *ngIf="
												this.sessionUser['position'] === 'FO' ||
												this.sessionUser['position'] === 'UW'
											">
												<a (click)="
													deleteFile(
														zohoApplicationID,
														'AmortizationSchedule',
														item
													)
												">
													<img src="../../../assets/images/trash.png" width="10"
														height="13" />
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<label class="form-label">Final Funding Officer </label>
							<select class="form-select" formControlName="final_funding_officer">
								<option value="" selected disabled hidden>-None-</option>
								<option *ngFor="let i of this.funding_officers" [value]="i.employeeID">
									{{ i.full_name }}
								</option>
							</select>
							<small *ngIf="
								this.checklistEntry.fundingForm.controls[
									'final_funding_officer'
								].hasError('required') &&
								this.checklistEntry.fundingForm.controls[
									'final_funding_officer'
								].touched
							" class="form-error-msg">Final Funding Officer name required!<br />
							</small>
							<h4 id="saveFundingEmailMsg"></h4>
							<button id="saveFundingEmailBtn" mat-raised-button color="primary"
								style="width: 150px; text-align: center;" (click)="sendEmailFundingOfficer()"
								[disabled]="this.checklistEntry.fundingForm.invalid">
								Save
							</button>
							<br /><br />
							<h5 class="card-title">Final Funding Check List</h5>
							<mat-checkbox value="" color="primary" formControlName="all_verfied">ALL
								VERIFIED</mat-checkbox>

							<div class="form-check" hidden>
								<input class="form-check-input" type="checkbox" value="" id="funding9"
									formControlName="funding_status" />
								<label class="form-check-label" for="funding9">
									Funding Complete
								</label>
							</div>
							<div class="mb-3">
								<label for="formFile3" class="form-label">Proof of Payment Files</label>
								<input class="form-control" type="file" id="formFile3"
									formControlName="proof_of_payment_file" (change)="onPOPchange($event.target.files)"
									multiple />
								<table>
									<tbody>
										<tr *ngFor="let item of popFiles">
											<td style="width: 90%">
												<a (click)="getfile(zohoApplicationID, 'POP', item)" class="showFile"
													title="{{ item }}">{{ item }}</a>
											</td>
											<td style="width: 10%" *ngIf="
												this.sessionUser['position'] === 'FO' ||
												this.sessionUser['position'] === 'UW'
											">
												<a (click)="deleteFile(zohoApplicationID, 'POP', item)">
													<img src="../../../assets/images/trash.png" width="10"
														height="13" />
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</form>
					<h4 id="saveFundingMsg"></h4>
					<button mat-raised-button color="primary" style="width: 150px; text-align: center;"
						id="saveFundingBtn" (click)="submitFundingChecklist()">
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</div>