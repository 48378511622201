<div class="container-md">
	<!--<p>Read Component Works!</p>-->
	<h4>Application Management</h4>
	<div class="row">
		<hr />
	</div>
	<div class="row">
		<!-- <div class="g-col-4">
                <a [routerLink]="['/applications/create']" class="btn btn-secondary">Create</a>
            </div> -->
		<!-- <div class="g-col-4">
                <a [routerLink]="['/dashboard']" class="btn btn-secondary">Back</a>
            </div> -->
		<table class="table table-bordered table-hover">
			<thead class="table-light">
				<tr>
					<th scope="col" hidden>UNIQUE ID</th>
					<th scope="col" hidden>ID</th>
					<th scope="col">Application Name</th>
					<th scope="col">ZOHO</th>
					<th scope="col">Lead Source</th>
					<th scope="col">Province</th>
					<th scope="col">Amount Balance</th>
					<!-- <th scope="col">Settltement Amount</th>
                        <th scope="col">Requested Amount</th> -->
					<th scope="col">Sales Agent</th>
					<th scope="col">Mobile Number</th>
					<!-- <th scope="col">Work Drive Link</th> -->
					<th scope="col">Creation Date</th>
					<th scope="col">Record Edit</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of readData; let i = index">
					<td hidden>{{ item.uniqueID }}</td>
					<td hidden>{{ item.userID }}</td>
					<td>{{ item.application_name }}</td>
					<td>
						<a
							class="noline"
							target="_blank"
							href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
								item.zoho_record_id
							}}"
							>Open</a
						>
					</td>
					<td>{{ item.lead_source }}</td>
					<td>{{ item.province_code }}</td>
					<td>{{ item.referral_balance }}</td>
					<!-- <td>{{item.settlement_amount}}</td>
                        <td>{{item.requested_amount}}</td> -->
					<td>{{ item.sales_agent }}</td>
					<td>{{ item.mobile }}</td>
					<!-- <td>{{item.folder_url}}</td> -->
					<td>{{ item.created_date | date : 'medium' : 'UTC' }}</td>
					<td>
						<button
							class="btn btn-sm btn-danger"
							(click)="deleteID(item.uniqueID)"
						>
							Delete
						</button>
						&nbsp;
						<a
							[routerLink]="['/applications/edit/', item.uniqueID[0]]"
							class="btn btn-sm btn-success"
							>Update</a
						>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- <input type="text" (keydown)="numberOnly($event)" (blur)="formatCurrency($event)"> -->

<!-- <input type="text" [value]="formattedCurrency" (keydown)="numberOnly($event)" (oninput)="formatInput($event)" > -->
