import { Component, OnInit } from '@angular/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss'],
	animations: [
		trigger('sideNavMenuAnimationState', [
			state(
				'topX',
				style({
					transform: 'rotate(45deg)',
					transformOrigin: 'left',
					margin: '7px',
				})
			),
			state(
				'hide',
				style({
					opacity: 0,
				})
			),
			state(
				'bottomX',
				style({
					transform: 'rotate(-45deg)',
					transformOrigin: 'left',
					margin: '7px',
				})
			),
			state('line', style({})),
			transition(':enter', []),
			transition('* => *', [animate('0.2s')]),
		]),
	],
})
export class ReportsComponent implements OnInit {
	isSideNavMenuOpen = false;
	constructor() {}

	ngOnInit(): void {}

	toggleSideNav() {
		this.isSideNavMenuOpen = !this.isSideNavMenuOpen;
	}
}
