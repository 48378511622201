<app-custom-betty-report-filters [showReportDropDown]="true" (reportType)="getReportType($event)"
    (customLoanGroups)="getCustomLoanGroups($event)" [showLoanStatus]="false" [showBreakdowns]="false"
    [showCurrentDate]="true" [showDateRange]="false" (current_date)="currentDate($event)" [btnColor]="'primary'"
    [btnShowText]="btnShowText" [btnText]="'Submit'" [btnSpinner]="btnSpinner"
    (onBtnClick)="downloadNSF()"></app-custom-betty-report-filters>

<div class="row" style="height: calc(100% - 175px); margin: 10px;">
    <app-custom-table class="ag-theme-material" [rowData]="rowData_Hist" [columnDefs]="columnDefs_Hist"
        [defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"
        [hideGlobalFilter]="true" [animateRows]="true" [pagination]="true" [paginationPageSize]="100"
        (gridReady)="onGridReady_Hist($event)">
    </app-custom-table>
</div>