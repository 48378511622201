<div class="container-fluid">
	<div class="row row-cols-12">
		<div class="col-2">
			<h5>
				Agency Funded
				<!-- <h5>Agency Funded on {{currentMonth}} -->
				<img
					title="refresh"
					src="/assets/images/refresh.png"
					width="30"
					height="30"
					(click)="refresh()"
				/>
			</h5>
			<br />
			<div [formGroup]="monthForm">
				<select
					class="form-select"
					formControlName="months"
					(change)="selectMonth()"
				>
					<option *ngFor="let item of months" [ngValue]="item.id">
						{{ item.name }}
					</option>
				</select>
			</div>
		</div>
	</div>
	<div class="agencyRow">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>Agency</th>
					<th>Count</th>
					<th>Amount</th>
				</tr>
			</thead>
			<tbody>
				<div
					*ngIf="
						!nlsFundedMonth || nlsFundedMonth.length === 0;
						else contentNLSM
					"
				>
					No NLS Onboarding!
				</div>
				<ng-template #contentNLSM>
					<tr *ngFor="let item of nlsFundedMonth">
						<td>
							<a
								class="noline"
								target="_self"
								[routerLink]="['/nlsagencycollectors']"
								[queryParams]="{
									agency: item.AgencyName,
									monthNum: hiddenMonthNum
								}"
								>{{ item.AgencyName }}</a
							>
						</td>
						<td>{{ item.AgencyCount }}</td>
						<td>{{ item.TotalAmount | currency }}</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
	<div>
		<h6>
			Total Count: {{ countNLSfundedMonth }} | Total Amount:
			{{ totalNLSfundedMonth | currency }}
		</h6>
	</div>
</div>
