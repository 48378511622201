import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-nlsloansizecollections',
	templateUrl: './nlsloansizecollections.component.html',
	styleUrls: ['./nlsloansizecollections.component.scss'],
})
export class NlsloansizecollectionsComponent implements OnInit {
	groupLoanSizeArray = [];
	one_five: string[] = [];
	fiveone_ten: string[] = [];
	tenone_fifteen: string[] = [];
	fifteenone_twenty: string[] = [];
	twentyone_twentyfive: string[] = [];
	twentyfive_thirty: string[] = [];

	countGroupBadDebt: number;
	amountGroupBadDebt: any;
	countGroupFunded: number;
	amountGroupFunded: any;
	countGroupDefault: number;
	amountGroupDefault: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private route: Router
	) {}

	ngOnInit(): void {
		// Login Session
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchLoanSize(); // Fetch the display data
					} else {
						this.route.navigate(['login']);
					}
				});
		} else {
			this.route.navigate(['login']);
		}
	}

	fetchLoanSize() {
		this.apiService.getnlsoverallfunded().subscribe((res) => {
			// console.log(res.data)
			if (res.message === 'Success') {
				//-----------------------BUCKET 1 (1-500)------------------------------------------------------------------------------
				let countDeliquency1 = 0;
				let countDefault1 = 0;
				let fieldName1 = '';
				let badDebt1 = 0;
				let defaultAmount1 = 0;
				let fundedAmount1 = 0;
				let countFunded1 = 0;
				let delinquencyRate1 = '';
				let defaultRate1 = '';

				const bucket1 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					1,
					500
				);
				// Loop through the overall raw data

				for (let i = 0; i < bucket1.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName1 = '1-500'; // Get the loan range name
					fundedAmount1 += bucket1[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded1++; // count the funded occurrences
					// Delinquency
					if (bucket1[i].internal_DPD > 0) {
						badDebt1 += bucket1[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency1++; // count the delinquency occurrences
					}
					// Default
					if (bucket1[i].internal_DPD > 120) {
						defaultAmount1 += bucket1[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault1++; // count the default occurrences
					}
				}

				delinquencyRate1 = ((badDebt1 / fundedAmount1) * 100).toFixed();
				defaultRate1 = ((defaultAmount1 / fundedAmount1) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName1,
						badDebt1,
						fundedAmount1,
						countDeliquency1,
						countFunded1,
						parseInt(delinquencyRate1),
						defaultAmount1,
						countDefault1,
						parseInt(defaultRate1)
					)
				);

				//-----------------------BUCKET 2 (501-1000)------------------------------------------------------------------------------
				let countDeliquency2 = 0;
				let countDefault2 = 0;
				let fieldName2 = '';
				let badDebt2 = 0;
				let defaultAmount2 = 0;
				let fundedAmount2 = 0;
				let countFunded2 = 0;
				let delinquencyRate2 = '';
				let defaultRate2 = '';

				const bucket2 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					501,
					1000
				);
				// Loop through the overall raw data

				for (let i = 0; i < bucket2.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName2 = '501-1000'; // Get the loan range name
					fundedAmount2 += bucket2[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded2++; // count the funded occurrences
					// Delinquency
					if (bucket2[i].internal_DPD > 0) {
						badDebt2 += bucket2[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency2++; // count the delinquency occurrences
					}
					// Default
					if (bucket2[i].internal_DPD > 120) {
						defaultAmount2 += bucket2[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault2++; // count the default occurrences
					}
				}

				delinquencyRate2 = ((badDebt2 / fundedAmount2) * 100).toFixed();
				defaultRate2 = ((defaultAmount2 / fundedAmount2) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName2,
						badDebt2,
						fundedAmount2,
						countDeliquency2,
						countFunded2,
						parseInt(delinquencyRate2),
						defaultAmount2,
						countDefault2,
						parseInt(defaultRate2)
					)
				);

				//-----------------------BUCKET 3 (1001-5000)------------------------------------------------------------------------------
				let countDeliquency3 = 0;
				let countDefault3 = 0;
				let fieldName3 = '';
				let badDebt3 = 0;
				let defaultAmount3 = 0;
				let fundedAmount3 = 0;
				let countFunded3 = 0;
				let delinquencyRate3 = '';
				let defaultRate3 = '';

				const bucket3 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					1001,
					5000
				);
				// Loop through the overall raw data

				for (let i = 0; i < bucket3.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName3 = '1001-5000'; // Get the loan range name
					fundedAmount3 += bucket3[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded3++; // count the funded occurrences
					// Delinquency
					if (bucket3[i].internal_DPD > 0) {
						badDebt3 += bucket3[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency3++; // count the delinquency occurrences
					}
					// Default
					if (bucket3[i].internal_DPD > 120) {
						defaultAmount3 += bucket3[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault3++; // count the default occurrences
					}
				}

				delinquencyRate3 = ((badDebt3 / fundedAmount3) * 100).toFixed();
				defaultRate3 = ((defaultAmount3 / fundedAmount3) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName3,
						badDebt3,
						fundedAmount3,
						countDeliquency3,
						countFunded3,
						parseInt(delinquencyRate3),
						defaultAmount3,
						countDefault3,
						parseInt(defaultRate3)
					)
				);

				//-----------------------BUCKET 4 (5001-10000)------------------------------------------------------------------------------
				let countDeliquency4 = 0;
				let countDefault4 = 0;
				let fieldName4 = '';
				let badDebt4 = 0;
				let defaultAmount4 = 0;
				let fundedAmount4 = 0;
				let countFunded4 = 0;
				let delinquencyRate4 = '';
				let defaultRate4 = '';

				const bucket4 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					5001,
					10000
				);

				for (let i = 0; i < bucket4.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName4 = '5001-10000'; // Get the loan range name
					fundedAmount4 += bucket4[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded4++; // count the funded occurrences
					// Delinquency
					if (bucket4[i].internal_DPD > 0) {
						badDebt4 += bucket4[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency4++; // count the delinquency occurrences
					}
					// Default
					if (bucket4[i].internal_DPD > 120) {
						defaultAmount4 += bucket4[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault4++; // count the default occurrences
					}
				}

				delinquencyRate4 = ((badDebt4 / fundedAmount4) * 100).toFixed();
				defaultRate4 = ((defaultAmount4 / fundedAmount4) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName4,
						badDebt4,
						fundedAmount4,
						countDeliquency4,
						countFunded4,
						parseInt(delinquencyRate4),
						defaultAmount4,
						countDefault4,
						parseInt(defaultRate4)
					)
				);

				//-----------------------BUCKET 5 (10001-15000)------------------------------------------------------------------------------
				let countDeliquency5 = 0;
				let countDefault5 = 0;
				let fieldName5 = '';
				let badDebt5 = 0;
				let defaultAmount5 = 0;
				let fundedAmount5 = 0;
				let countFunded5 = 0;
				let delinquencyRate5 = '';
				let defaultRate5 = '';

				const bucket5 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					10001,
					15000
				);

				for (let i = 0; i < bucket5.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName5 = '10001-15000'; // Get the loan range name
					fundedAmount5 += bucket5[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded5++; // count the funded occurrences
					// Delinquency
					if (bucket5[i].internal_DPD > 0) {
						badDebt5 += bucket5[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency5++; // count the delinquency occurrences
					}
					// Default
					if (bucket5[i].internal_DPD > 120) {
						defaultAmount5 += bucket5[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault5++; // count the default occurrences
					}
				}

				delinquencyRate5 = ((badDebt5 / fundedAmount5) * 100).toFixed();
				defaultRate5 = ((defaultAmount5 / fundedAmount5) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName5,
						badDebt5,
						fundedAmount5,
						countDeliquency5,
						countFunded5,
						parseInt(delinquencyRate5),
						defaultAmount5,
						countDefault5,
						parseInt(defaultRate5)
					)
				);

				//-----------------------BUCKET 6 (15001-20000)------------------------------------------------------------------------------
				let countDeliquency6 = 0;
				let countDefault6 = 0;
				let fieldName6 = '';
				let badDebt6 = 0;
				let defaultAmount6 = 0;
				let fundedAmount6 = 0;
				let countFunded6 = 0;
				let delinquencyRate6 = '';
				let defaultRate6 = '';

				const bucket6 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					15001,
					20000
				);

				for (let i = 0; i < bucket6.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName6 = '15001-20000'; // Get the loan range name
					fundedAmount6 += bucket6[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded6++; // count the funded occurrences
					// Delinquency
					if (bucket6[i].internal_DPD > 0) {
						badDebt6 += bucket6[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency6++; // count the delinquency occurrences
					}
					// Default
					if (bucket6[i].internal_DPD > 120) {
						defaultAmount6 += bucket6[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault6++; // count the default occurrences
					}
				}

				delinquencyRate6 = ((badDebt6 / fundedAmount6) * 100).toFixed();
				defaultRate6 = ((defaultAmount6 / fundedAmount6) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName6,
						badDebt6,
						fundedAmount6,
						countDeliquency6,
						countFunded6,
						parseInt(delinquencyRate6),
						defaultAmount6,
						countDefault6,
						parseInt(defaultRate6)
					)
				);

				//-----------------------BUCKET 7 (20001-25000)------------------------------------------------------------------------------
				let countDeliquency7 = 0;
				let countDefault7 = 0;
				let fieldName7 = '';
				let badDebt7 = 0;
				let defaultAmount7 = 0;
				let fundedAmount7 = 0;
				let countFunded7 = 0;
				let delinquencyRate7 = '';
				let defaultRate7 = '';

				const bucket7 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					20001,
					25000
				);

				for (let i = 0; i < bucket7.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName7 = '20001-25000'; // Get the loan range name
					fundedAmount7 += bucket7[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded7++; // count the funded occurrences
					// Delinquency
					if (bucket7[i].internal_DPD > 0) {
						badDebt7 += bucket7[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency7++; // count the delinquency occurrences
					}
					// Default
					if (bucket7[i].internal_DPD > 120) {
						defaultAmount7 += bucket7[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault7++; // count the default occurrences
					}
				}

				delinquencyRate7 = ((badDebt7 / fundedAmount7) * 100).toFixed();
				defaultRate7 = ((defaultAmount7 / fundedAmount7) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName7,
						badDebt7,
						fundedAmount7,
						countDeliquency7,
						countFunded7,
						parseInt(delinquencyRate7),
						defaultAmount7,
						countDefault7,
						parseInt(defaultRate7)
					)
				);

				//-----------------------BUCKET 8 (20001-25000)------------------------------------------------------------------------------
				let countDeliquency8 = 0;
				let countDefault8 = 0;
				let fieldName8 = '';
				let badDebt8 = 0;
				let defaultAmount8 = 0;
				let fundedAmount8 = 0;
				let countFunded8 = 0;
				let delinquencyRate8 = '';
				let defaultRate8 = '';

				const bucket8 = this.customFunction.bucketFilter(
					res.data,
					'original_note_amount',
					25001,
					30000
				);

				for (let i = 0; i < bucket8.length; i++) {
					// If filtered loan range match the raw data loan range
					fieldName8 = '25001-30000'; // Get the loan range name
					fundedAmount8 += bucket8[i].original_note_amount; // Add the orignal funded amount to the respective loan range
					countFunded8++; // count the funded occurrences
					// Delinquency
					if (bucket8[i].internal_DPD > 0) {
						badDebt8 += bucket8[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDeliquency8++; // count the delinquency occurrences
					}
					// Default
					if (bucket8[i].internal_DPD > 120) {
						defaultAmount8 += bucket8[i].current_principal_balance; // Add the principal balance to the respective loan range
						countDefault8++; // count the default occurrences
					}
				}

				delinquencyRate8 = ((badDebt8 / fundedAmount8) * 100).toFixed();
				defaultRate8 = ((defaultAmount8 / fundedAmount8) * 100).toFixed();
				this.groupLoanSizeArray.push(
					new this.customFunction.groupCollections(
						fieldName8,
						badDebt8,
						fundedAmount8,
						countDeliquency8,
						countFunded8,
						parseInt(delinquencyRate8),
						defaultAmount8,
						countDefault8,
						parseInt(defaultRate8)
					)
				);

				this.countGroupBadDebt = this.customFunction.sumNumbers(
					this.groupLoanSizeArray,
					'countDeliquency'
				); // Total up the numbers
				this.amountGroupBadDebt = this.customFunction.sumNumbers(
					this.groupLoanSizeArray,
					'badDebt'
				);
				// this.countGroupFunded = this.customFunction.sumNumbers(this.groupLoanSizeArray,'countFunded');
				this.countGroupFunded = res.data.length;
				this.amountGroupFunded = this.customFunction.sumNumbers(
					res.data,
					'original_note_amount'
				);
				this.countGroupDefault = this.customFunction.sumNumbers(
					this.groupLoanSizeArray,
					'countDefault'
				);
				this.amountGroupDefault = this.customFunction.sumNumbers(
					this.groupLoanSizeArray,
					'defaultAmount'
				);
			} else {
				this.groupLoanSizeArray = [];
				this.countGroupBadDebt = 0;
				this.amountGroupBadDebt = 0;
				this.countGroupFunded = 0;
				this.amountGroupFunded = 0;
				this.countGroupDefault = 0;
				this.amountGroupDefault = 0;
			}
		});
	}
}
