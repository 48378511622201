import { AfterViewInit, Component } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { SummaryDataService } from '@lmc-app/services/summary/summary-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-banking-summary',
  templateUrl: './banking-summary.component.html',
  styleUrls: ['./banking-summary.component.scss']
})
export class BankingSummaryComponent implements AfterViewInit {

  applicationId: string;
  Co_Appl_Required: boolean;

  applicantTypeBanks: any
  bankStatement: any;
  isVerified: boolean = true;
  isDefault: string = 'primary';
  summaryDataSubscription: Subscription;

  loadAllBanks: boolean = true;
  loadStatements: boolean = true;
  activeGuid: string = '';
  defaultGuid: any;

  constructor(private apiService: ApiServiceService,
    private summaryDataService: SummaryDataService,) { }

  ngAfterViewInit(): void {
    this.summaryDataSubscription =
      this.summaryDataService.summaryDataObservable.subscribe(
        async (summaryData: any) => {
          this.applicationId = summaryData.id;
          this.Co_Appl_Required = summaryData?.Co_Appl_Required === 'Yes' ? true : false;
          await this.displayBankings();
          this.displayBankingInformation(this.defaultGuid);
        }
      );
  }

  async getApplicantTypeBanks() {
    await this.displayBankings();
    this.displayBankingInformation(this.defaultGuid);
  }

  displayBankings() {
    return new Promise(async (resolve, reject) => {
      this.loadAllBanks = true;
      let allBanks;
      allBanks = await this.getAllClientBankings(this.applicationId);
      this.applicantTypeBanks = allBanks?.filter((item: any) => item?.application_type === this.isDefault);
      this.loadAllBanks = false;
      this.defaultGuid = this.applicantTypeBanks[0]?.guid;
      resolve(true);
    })
  }

  // All bank names
  getAllClientBankings(applicationId: string) {
    return new Promise((resolve, reject) => {
      this.loadAllBanks = true;
      this.isVerified = true;
      this.apiService.getAllClientBankings(applicationId).subscribe((res: any) => {
        this.loadAllBanks = false;
        resolve(res?.data);
      })
    })
  }

  // Individual bank accounts
  displayBankingInformation(guid: string) {
    this.isVerified = true;
    if (typeof guid === 'undefined') {
      this.isVerified = false;
      this.loadStatements = false;
    } else {
      this.activeGuid = guid;
      this.loadStatements = true;
      this.apiService
        .showInveriteBankStatements(guid)
        .subscribe((res: any) => {
          try {
            if (res?.accounts.length > 0) {
              this.bankStatement = res?.accounts;
              this.loadStatements = false;
            } else {
              this.isVerified = false;
              this.loadStatements = false;
            }
          } catch (error) {
            this.isVerified = false;
            this.loadStatements = false;
          }
        })
    }
  }

  currentBalance(amount: any) {
    return typeof amount === 'undefined' ? 0 : amount;
  }

  cashflow(amount: any) {
    return amount < 0 ? 'Negative' : 'Positive';
  }

  cashflowColor(amount: any) {
    return amount < 0 ? { color: 'red' } : { color: 'green' };
  }

  ngOnDestroy() {
    this.summaryDataSubscription?.unsubscribe();
  }
}