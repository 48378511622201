import { Component, OnInit } from '@angular/core';
import { now } from 'moment';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';
import { Router } from '@angular/router';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector: 'app-nlsagencyfunded',
	templateUrl: './nlsagencyfunded.component.html',
	styleUrls: ['./nlsagencyfunded.component.scss'],
})
export class NlsagencyfundedComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private _routerLink: Router,
		private fb: UntypedFormBuilder
	) {}

	currentMonth = new Date(now()).toLocaleString('en-us', { month: 'long' }); // Get the month from current date
	hiddenMonthNum: number;
	nlsFundedMonth: any;
	totalNLSfundedMonth: any;
	countNLSfundedMonth: any;

	monthForm = new UntypedFormGroup({
		months: new UntypedFormControl(''),
	});

	months = [
		{ id: 0, name: 'January' },
		{ id: 1, name: 'February' },
		{ id: 2, name: 'March' },
		{ id: 3, name: 'April' },
		{ id: 4, name: 'May' },
		{ id: 5, name: 'June' },
		{ id: 6, name: 'July' },
		{ id: 7, name: 'August' },
		{ id: 8, name: 'September' },
		{ id: 9, name: 'October' },
		{ id: 10, name: 'November' },
		{ id: 11, name: 'December' },
	];

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.getNLSFundedMonth();

						const monthNum = this.months.findIndex(
							(x) => x.name === this.currentMonth
						);
						this.monthForm = this.fb.group({
							months: [monthNum],
						});
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	getNLSFundedMonth() {
		const monthNum = this.months.findIndex((x) => x.name === this.currentMonth);
		this.apiService.getNLSFundedMonth(monthNum).subscribe((res) => {
			this.nlsFundedMonth = res.data;

			this.customFunction.sortColumn(this.nlsFundedMonth, 'TotalAmount', -1, 1); // Sort by descending

			this.totalNLSfundedMonth = this.customFunction
				.sumNumbers(this.nlsFundedMonth, 'TotalAmount')
				.toFixed(2);
			this.countNLSfundedMonth = this.customFunction.sumNumbers(
				this.nlsFundedMonth,
				'AgencyCount'
			);
		});
	}

	selectMonth() {
		this.apiService
			.getNLSFundedMonth(this.monthForm.get('months').value)
			.subscribe((res) => {
				this.hiddenMonthNum = this.monthForm.get('months').value;
				// console.log(this.hiddenMonthNum)
				this.nlsFundedMonth = res.data;

				this.customFunction.sortColumn(
					this.nlsFundedMonth,
					'TotalAmount',
					-1,
					1
				); // Sort by descending

				this.totalNLSfundedMonth = this.customFunction
					.sumNumbers(this.nlsFundedMonth, 'TotalAmount')
					.toFixed(2);
				this.countNLSfundedMonth = this.customFunction.sumNumbers(
					this.nlsFundedMonth,
					'AgencyCount'
				);
			});
	}

	refresh() {
		window.location.reload();
	}
}
