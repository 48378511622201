export class GeneralObjects {
	call_attempts = [
		{ display_value: '1' },
		{ display_value: '2' },
		{ display_value: '3' },
		{ display_value: '4' },
		{ display_value: '5' },
		{ display_value: '6' },
		{ display_value: '7' },
		{ display_value: '8' },
		{ display_value: '9' },
		{ display_value: '10' },
		{ display_value: '11' },
		{ display_value: '12' },
		{ display_value: '13' },
		{ display_value: '14' },
		{ display_value: '15' },
		{ display_value: '16' },
		{ display_value: '17' },
		{ display_value: '18' },
		{ display_value: '19' },
		{ display_value: '20' },
	];

	loan_groups = [
		{ display_value: 'All' },
		{ display_value: 'Debenture' },
		{ display_value: 'MFT' },
		{ display_value: 'QVEST' },
		{ display_value: 'SPV' },
	];


	loan_group_no = {
		QVEST: [50, 51],
		MFT: [3, 16],
		SPV: [],    // Populated from database as the SPV loan group number varies everyday
		Debenture: [1, 27],
	}

	monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"];

	dateOptions = [
		{ insertValue: 'PtP_Date', displayValue: 'PTP Date' },
		{ insertValue: 'created_at', displayValue: 'Created At' }
	];
}
