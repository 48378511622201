import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { ChecklistEntry } from '@lmc-models/checklistInputs';
import { CustomFunctionService } from '../../custom-function.service';
import { Controls } from '@lmc-models/controls';
import { Functions } from '@lmc-models/commonFunctions';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@lmc-env/environment';
import { S3ClientService } from '@lmc-services/s3/s3-client.service';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { Subscription } from 'rxjs';

interface NLSObject {
	name: string;
	loan_number: number;
}

@Component({
	selector: 'app-checklist',
	templateUrl: './checklist.component.html',
	styleUrls: ['./checklist.component.scss'],
})
export class ChecklistComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
	files = [];

	@ViewChild('address') public searchElementAddress: ElementRef;

	primary_nls_object: NLSObject;
	co_app_nls_object: NLSObject;

	zohoApplicationID: any;
	Deal_Name: any;
	co_app_required: any;
	zoho_application_stage: any;

	// dob: any;

	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;

	serverURL: string = environment.iframeURL; //this needs to be changed on deployment for checklist url
	@Input() summaryData: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private route: Router,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		private s3Service: S3ClientService
	) { }
	controls = new Controls();
	fileLimitSize = 5000000;
	fileTypes = ['application/pdf', 'image/jpg', 'image/png', 'image/heic'];
	functions = new Functions();

	checklistEntry = new ChecklistEntry(); // Create New Objects

	balanceLetterFile: File = null;
	amortizationFile: File = null;
	popFile: File = null;

	coAppRequired: any;
	LPP_Included: any;

	applicationID: any;
	applicantType: any;
	underwriter_names: any;
	Address1: any;
	Address_Line_2: any;
	City: any;
	Province: any;
	Postal_Code: any;
	fullAddress: any;
	funding_officers: any;

	// File Upload
	@ViewChild('consentInput') consentInput: ElementRef;
	selectedFile: any;
	consentFile: any;

	balanceLetterFiles: any;
	amortizationFiles: any;
	popFiles: any;

	sessionUser: any;

	emailPurpose = ['BackendComplete', 'EmailFinalFundingOfficer', 'AllVerified'];

	// Email Flags
	validationEmail = false;
	finalFundingEmail = false;
	fundedEmail = false;
	timer = (ms) => new Promise((res) => setTimeout(res, ms));

	// Checklist Status
	frontendCompleted = 'Incomplete';
	backendCompleted = 'Incomplete';
	warningMessage: any; // This is to display warning messages on changes detected"

	spinner = false;

	ngOnInit(): void {
		// no-op
	}

	ngAfterViewInit(): void {
		this.customFunction.loadAutoAddressing(this.searchElementAddress);
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			this.sessionUser = userData;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				async (summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.summaryData = summaryData;
						this.zohoApplicationID = summaryData.id;
						this.Deal_Name = summaryData.Deal_Name;
						this.co_app_required = summaryData.Co_Appl_Required;
						this.zoho_application_stage = summaryData.Stage;
						await this.getUnderwriters();
						this.clickType('Primary Applicant');
						this.getCompleteAddress('Primary Applicant');
						await this.filesToLoad();
					}
				}
			);
	}

	saveToS3(files, folderName: string, fileSuffix: any) {
		for (let i = 0; i < files?.['length']; i++) {
			const renamedFile = this.renameFileToAppendSuffix(files[i], fileSuffix);
			setTimeout(() => {
				console.log('uploading file', renamedFile.name);
				this.s3Service.uploadFile(
					renamedFile,
					folderName,
					this.zohoApplicationID
				);
				// this.timer(3000);
			}, 0);
		}
	}

	renameFileToAppendSuffix(file, fileSuffix: any) {
		const originalFileName = file.name?.split('.').slice(0, -1).join('.');
		const originalFileExtension = file.name?.split('.').pop();
		return new File(
			[file],
			`${originalFileName} ${fileSuffix}.${originalFileExtension}`,
			{
				type: file.type,
				lastModified: file.lastModified,
			}
		);
	}

	// Get the underwriters for validation
	async getUnderwriters() {
		return new Promise((resolve, reject) => {
			this.apiService.getUnderwriters().subscribe((res: any) => {
				if (res.message == 'Success') {
					this.underwriter_names = this.customFunction.singleFilterName(
						res['data'],
						'position',
						'UW'
					);
					let underwriterID = this.underwriter_names.filter(
						(item) => item.employeeID === this.sessionUser['employeeID']
					);
					if (underwriterID.length !== 0) {
						this.checklistEntry.backEndForm
							.get('backend_officer')
							.patchValue(underwriterID[0]['employeeID']);
						this.checklistEntry.frontEndForm
							.get('frontend_officer')
							.patchValue(underwriterID[0]['employeeID']);
					}
					this.funding_officers = this.customFunction.singleFilterName(
						res['data'],
						'position',
						'FO'
					);
					let financeID = this.funding_officers.filter(
						(item) => item.employeeID === this.sessionUser['employeeID']
					);
					if (financeID.length !== 0) {
						this.checklistEntry.fundingForm
							.get('final_funding_officer')
							.patchValue(financeID[0]['employeeID']);
						this.checklistEntry.fundingForm
							.get('funding_officer')
							.patchValue(financeID[0]['employeeID']);
					}
					resolve(true);
				} else {
					resolve(false);
				}
			});
		});
	}

	// Paste the address to the text from the google addressing dropdown
	populateText() {
		this.checklistEntry.frontEndForm
			.get('populate_address')
			.patchValue(this.fullAddress);
	}

	// API call to ZOHO to get the application informations
	getCompleteAddress(appType: any) {
		if (appType === 'Primary Applicant') {
			this.Address1 = this.summaryData.Address1;
			this.Address_Line_2 = this.summaryData.Address_Line_2;
			this.City = this.summaryData.City;
			this.Province = this.summaryData.Province1;
			this.Postal_Code = this.summaryData.Postal_Code;
		}
		if (appType === 'Co-Applicant') {
			this.Address1 = this.summaryData.Co_Applicant_Property_Address;
			// this.Address_Line_2 = this.summaryData.Co_Applicant_Property_Address;
			this.City = this.summaryData.Co_Applicant_City;
			this.Province = this.summaryData.Co_Applicant_Province;
			this.Postal_Code = this.summaryData.Co_Applicant_Postal_Code;
		}
		this.fullAddress =
			this.Address1 +
			', ' +
			this.City +
			', ' +
			this.Province +
			', ' +
			this.Postal_Code;

		this.coAppRequired = this.summaryData.Co_Appl_Required;

		this.LPP_Included = this.summaryData.LPP_Included;
	}

	// Trigger the checklist form based on the Applicant Type
	clickType(e) {
		switch (e) {
			case 'Primary Applicant':
				this.spinner = true;
				this.getCompleteAddress('Primary Applicant');
				this.applicantType = 'Primary Applicant'; // In order to do *ngIf for show and hide of form controls
				// This will display the Checklist Statuses for Frontend and Backend and the warning messages if changes detected
				this.displayStatus().then(() => {
					this.getChecklistRecords(
						this.zohoApplicationID,
						this.applicantType
					).then(() => {
						this.clearSaveMessage();
					});
				});
				break;
			case 'Co-Applicant':
				if (this.co_app_required === 'Yes') {
					this.spinner = true;
					this.getCompleteAddress('Co-Applicant');
					this.applicantType = 'Co-Applicant'; // In order to do *ngIf for show and hide of form controls
					this.displayStatus().then(() => {
						this.getChecklistRecords(
							this.zohoApplicationID,
							this.applicantType
						).then(() => {
							this.clearSaveMessage();
						});
					});
				}
				break;
			default:
		}
	}

	/**
	 * This will return the statuses for frontend and backend and also the warning message
	 */
	async displayStatus() {
		let frontendResult = await this.returnStatus(
			this.zohoApplicationID,
			this.co_app_required,
			'frontend_status'
		).then((value: any) => {
			this.frontendCompleted = value['checklistStatusResult'];
		});
		let backendResult = await this.returnStatus(
			this.zohoApplicationID,
			this.co_app_required,
			'backend_status'
		).then((value: any) => {
			this.backendCompleted = value['checklistStatusResult'];
			if (
				value['checklistStatusResult'] === 'Complete' &&
				!value['validationEmailFlag']
			) {
				this.warningMessage =
					"The Co-app doesn't exist anymore. Please save frontend/backend to send the file to validation!";
			}
			if (
				value['checklistStatusResult'] === 'Incomplete' &&
				value['validationEmailFlag']
			) {
				this.warningMessage =
					'The file is in funding and a change has been made on the checklist!';
			}
		});
	}

	// Save button messages
	clearSaveMessage() {
		this.controls.displayMessage(
			'saveFrontendBtn',
			false,
			'saveFrontendMsg',
			'',
			'transparent',
			'block'
		);
		this.controls.displayMessage(
			'saveBackendBtn',
			false,
			'saveBackendMsg',
			'',
			'transparent',
			'block'
		);
		this.controls.displayMessage(
			'saveBackendBtn',
			false,
			'saveBackendMsg',
			'',
			'transparent',
			'block'
		);
	}

	//Submit Front End Checklist
	submitFrontEndChecklist() {
		if (
			this.sessionUser['position'] === 'UW' ||
			this.sessionUser['position'] === 'FO'
		) {
			//Check whether all boxes are checked
			this.controls.displayMessage(
				'saveFrontendBtn',
				true,
				'saveFrontendMsg',
				'Save in progress...',
				'orange',
				'block'
			);
			// Insert/Update the checklist values
			this.apiService
				.uploadFrontEndChecklist(
					this.checklistEntry.frontEndForm.value,
					this.zohoApplicationID,
					this.applicantType
				)
				.subscribe(async (res) => {
					if (res['message'] == 'Success') {
						// Update the frontend status
						let result = await this.updateChecklistStatusMethod(
							this.checklistEntry.frontEndForm.get('frontend_status'),
							'Frontend',
							this.applicantType
						);
						// Get the frontend and backend statuses and assign the Complete/Incomplete to this.frontendCompleted
						let result1 = await this.returnStatus(
							this.zohoApplicationID,
							this.co_app_required,
							'frontend_status'
						).then((value: any) => {
							this.frontendCompleted = value['checklistStatusResult'];
						});
						// Get the frontend and backend statuses and assign the Complete/Incomplete to this.frontendCompleted
						let result2 = await this.returnStatus(
							this.zohoApplicationID,
							this.co_app_required,
							'backend_status'
						).then((value: any) => {
							this.backendCompleted = value['checklistStatusResult'];
						});
						// If the both backend and frontend is completed
						if (this.backendCompleted === 'Complete') {
							this.warningMessage = '';
							if (this.zoho_application_stage !== 'Funded') {
								this.checkBoxes('Backend', 'Validation');
							}
						}

						this.controls.displayMessage(
							'saveFrontendBtn',
							false,
							'saveFrontendMsg',
							'Save successful',
							'green',
							'block'
						);
						// this.checkBoxes("Frontend", "Ready for Loans Interview")

						// Get the checklist records. Performing await to get the validation email flag
						let resultRecords = await this.getChecklistRecords(
							this.zohoApplicationID,
							this.applicantType
						);

						if (
							this.backendCompleted === 'Incomplete' &&
							this.emailedValidation
						) {
							this.warningMessage =
								'The file is in funding and a change has been made on the checklist!';
							this.emailWarning();
						}
						this.saveLogFrontEnd();
					} else {
						console.log(res, 'uploadFrontEndChecklist');
						this.controls.displayMessage(
							'saveFrontendBtn',
							false,
							'saveFrontendMsg',
							'Save unsuccessful',
							'red',
							'block'
						);
					}
				});
		} else {
			this.controls.displayMessage(
				'saveFrontendBtn',
				false,
				'saveFrontendMsg',
				'Save not authorized',
				'red',
				'block'
			);
		}
	}

	//Submit Backend Checklist
	submitBackEndChecklist() {
		if (
			this.sessionUser['position'] === 'UW' ||
			this.sessionUser['position'] === 'FO'
		) {
			this.controls.displayMessage(
				'saveBackendBtn',
				true,
				'saveBackendMsg',
				'Save in progress...',
				'orange',
				'block'
			);
			// Insert/Update the Checklist values
			this.apiService
				.uploadBackEndChecklist(
					this.checklistEntry.backEndForm.value,
					this.zohoApplicationID,
					this.applicantType
				)
				.subscribe(async (res) => {
					// Update the backend status flag
					let result = await this.updateChecklistStatusMethod(
						this.checklistEntry.backEndForm.get('backend_status'),
						'Backend',
						this.applicantType
					);
					if (res['message'] == 'Success') {
						// if(this.checklistEntry.frontEndForm.get('frontend_status').value){
						//   this.checkBoxes("Backend", "Validation")
						// }
						// if(this.checklistEntry.frontEndForm.get('frontend_status').value){
						//   let result = await this.updateChecklistStatusMethod(this.checklistEntry.backEndForm.get('backend_status'), "Backend", this.applicantType);
						//   this.checkBoxes("Backend", "Validation")
						// }
						// Get the frontend and backend statuses and assign the Complete/Incomplete to this.backendCompleted
						let result1 = await this.returnStatus(
							this.zohoApplicationID,
							this.co_app_required,
							'backend_status'
						).then((value: any) => {
							this.backendCompleted = value['checklistStatusResult'];
						});
						// Complete on both frontend and backend, then trigger the stage
						if (this.backendCompleted === 'Complete') {
							this.warningMessage = '';
							if (this.zoho_application_stage !== 'Funded') {
								this.checkBoxes('Backend', 'Validation');
							}
						}
						this.controls.displayMessage(
							'saveBackendBtn',
							false,
							'saveBackendMsg',
							'Save successful',
							'green',
							'block'
						);
						// Get the validation email flag
						let resultRecords = await this.getChecklistRecords(
							this.zohoApplicationID,
							this.applicantType
						);
						// Checktlist modified after completed
						// Incomplete Checklist and the email was already sent before
						// Display warning sign and email Accounts
						if (
							this.backendCompleted === 'Incomplete' &&
							this.emailedValidation
						) {
							this.warningMessage =
								'The file is in funding and a change has been made on the checklist!';
							this.emailWarning();
						}
						this.saveLogBackEnd();
					} else {
						console.log(res, 'uploadBackEndChecklist');
						this.controls.displayMessage(
							'saveBackendBtn',
							false,
							'saveBackendMsg',
							'Save unsuccessful',
							'red',
							'block'
						);
					}
				});
		} else {
			this.controls.displayMessage(
				'saveBackendBtn',
				false,
				'saveBackendMsg',
				'Save not authorized',
				'red',
				'block'
			);
		}
	}

	//Submit Funding Checklist
	submitFundingChecklist() {
		if (
			this.sessionUser['position'] === 'FO' ||
			this.sessionUser['position'] === 'UW'
		) {
			this.controls.displayMessage(
				'saveFundingBtn',
				true,
				'saveFundingMsg',
				'Save in progress...',
				'orange',
				'block'
			);
			// Insert/Update the checklist values
			this.apiService
				.uploadFundingChecklist(
					this.checklistEntry.fundingForm.value,
					this.zohoApplicationID,
					this.applicantType
				)
				.subscribe(async (res) => {
					if (res['message'] == 'Success') {
						// load the files uploaded if any
						this.balanceLetterFiles = await this.loadUploadedFiles(
							this.zohoApplicationID,
							'BalanceLetters'
						);
						this.amortizationFiles = await this.loadUploadedFiles(
							this.zohoApplicationID,
							'AmortizationSchedule'
						);

						// If files are uploaded then, update the funding status
						if (
							this.balanceLetterFiles.length !== 0 &&
							this.amortizationFiles.length !== 0
						) {
							let result = await this.updateChecklistStatusMethod(
								this.checklistEntry.fundingForm.get('funding_status'),
								'Funding',
								this.applicantType
							);
							this.controls.displayMessage(
								'saveFundingBtn',
								false,
								'saveFundingMsg',
								'Save successful',
								'green',
								'block'
							);

							// Get the frontend and backend status
							let result1 = await this.returnStatus(
								this.zohoApplicationID,
								this.co_app_required,
								'backend_status'
							).then((value: any) => {
								this.backendCompleted = value['checklistStatusResult'];
							});
							// If frontend and backend completed then, trigger the stage
							if (this.backendCompleted === 'Complete') {
								this.checkBoxes('Funding', 'Funded');
							} else {
								alert('Underwriting checklist is incomplete');
							}
						}
						this.saveFinalFiles(); // Upload file attachments
						this.getChecklistRecords(
							this.zohoApplicationID,
							this.applicantType
						);
						this.saveLogFundingEnd();
					} else {
						console.log(res, 'uploadFundingChecklist');
						this.controls.displayMessage(
							'saveFundingBtn',
							false,
							'saveFundingMsg',
							'Save unsuccessful',
							'red',
							'block'
						);
					}
				});
		} else {
			this.controls.displayMessage(
				'saveFundingBtn',
				false,
				'saveFundingMsg',
				'Save not authorized',
				'red',
				'block'
			);
		}
	}
	// Submitting the first half of the funding checklist
	sendEmailFundingOfficer() {
		if (
			this.sessionUser['position'] === 'FO' ||
			this.sessionUser['position'] === 'UW'
		) {
			this.controls.displayMessage(
				'saveFundingEmailBtn',
				true,
				'saveFundingEmailMsg',
				'Save in progress...',
				'orange',
				'block'
			);
			this.apiService
				.uploadFundingChecklist(
					this.checklistEntry.fundingForm.value,
					this.zohoApplicationID,
					this.applicantType
				)
				.subscribe(async (res) => {
					if (res['message'] == 'Success') {
						this.controls.displayMessage(
							'saveFundingEmailBtn',
							false,
							'saveFundingEmailMsg',
							'Save successful',
							'green',
							'block'
						);
						// let result = await this.updateChecklistStatusMethod(this.checklistEntry.fundingForm.get('funding_status'), "Funding");
						let folderResult = await this.createChecklistFolders();
						let balanceResult = await this.uploadFiles(
							this.balanceLetterFile,
							this.zohoApplicationID,
							'BalanceLetters'
						);
						let amortizationResult = await this.uploadFiles(
							this.amortizationFile,
							this.zohoApplicationID,
							'AmortizationSchedule'
						);
						// this.filesToLoad();
						this.balanceLetterFiles = await this.loadUploadedFiles(
							this.zohoApplicationID,
							'BalanceLetters'
						);
						this.amortizationFiles = await this.loadUploadedFiles(
							this.zohoApplicationID,
							'AmortizationSchedule'
						);
						this.popFiles = await this.loadUploadedFiles(
							this.zohoApplicationID,
							'POP'
						);
						this.resetFileUploadControl();

						// Get the frontend and backend status
						let result1 = await this.returnStatus(
							this.zohoApplicationID,
							this.co_app_required,
							'backend_status'
						).then((value: any) => {
							this.backendCompleted = value['checklistStatusResult'];
						});
						if (
							this.backendCompleted === 'Incomplete' &&
							this.checklistEntry.fundingForm.get(
								'verified_all_documents_uploaded_correctly'
							).value
						) {
							alert('Underwriting checklist incomplete!');
						} else {
							if (
								this.balanceLetterFiles.length !== 0 &&
								this.amortizationFiles.length !== 0
							) {
								this.checkBoxes('Funding-Email', false);
							}
						}
						// if((!this.checklistEntry.frontEndForm.get('frontend_status').value || !this.checklistEntry.backEndForm.get('backend_status').value) && this.checklistEntry.fundingForm.get('verified_all_documents_uploaded_correctly').value){
						//   alert("Underwriting checklist incomplete!")
						// }else{
						//   if(this.balanceLetterFiles.length !== 0  && this.amortizationFiles.length !==0){
						//     this.checkBoxes("Funding-Email", false);
						//   }
						// }

						// // if((!this.checklistEntry.frontEndForm.get('frontend_status').value || !this.checklistEntry.backEndForm.get('backend_status').value) && this.checklistEntry.fundingForm.get('amortization_schedule').value && this.checklistEntry.fundingForm.get('verified_all_documents_uploaded_correctly').value && this.checklistEntry.fundingForm.get('balance_letters').value){
						// if((!this.checklistEntry.frontEndForm.get('frontend_status').value || !this.checklistEntry.backEndForm.get('backend_status').value) && this.checklistEntry.fundingForm.get('verified_all_documents_uploaded_correctly').value && (!uploadedBalanceLetter || !uploadedAmortization)){
						//   console.log("sdfsdfsdf")
						//   alert("Underwriting checklist incomplete!")
						// }else{
						//   this.checkBoxes("Funding-Email", false);
						// }
						this.getChecklistRecords(
							this.zohoApplicationID,
							this.applicantType
						);
						this.saveLogFundingEnd();
					} else {
						this.controls.displayMessage(
							'saveFundingEmailBtn',
							false,
							'saveFundingEmailMsg',
							'Save unsuccessful',
							'red',
							'block'
						);
					}
				});
		} else {
			this.controls.displayMessage(
				'saveFundingEmailBtn',
				false,
				'saveFundingEmailMsg',
				'Save not authorized',
				'red',
				'block'
			);
		}
	}
	checkBoxes(checklistStage, stageChange) {
		// console.log("Checking checkboxes")
		//CHECK WHETHER APPLICANT HAS CO APP OR NOT
		this.apiService
			.checkCheckBoxes(
				this.zohoApplicationID,
				checklistStage,
				this.co_app_required,
				this.LPP_Included,
				this.applicantType
			)
			.subscribe((res) => {
				console.log(res);
				try {
					if (res['message']) {
						// let alertMessage ='';
						// if(stageChange === false)
						//   alertMessage = "Final funding email notification sent!"
						// else
						//   alertMessage = "Updating stage to " + stageChange
						// alert(alertMessage)

						if (stageChange) {
							alert('Updating stage to ' + stageChange);
							//Decide whether to update the stage or not based on Flag
							switch (checklistStage) {
								// case 'Frontend': {
								//   if (this.checklistEntry.frontEndForm.get('frontend_status').value) {
								//     this.apiService.updateZohoApplicationStage(this.zohoApplicationID, stageChange).subscribe((res) => {
								//       console.log("Updating ZOHO Application Stage")
								//       console.log(res, "updateZohoApplicationStage")
								//       this.apiService.sendClickEvent();
								//     })
								//   }
								//   break;
								// }
								case 'Backend': {
									// if (this.checklistEntry.backEndForm.get('backend_status').value) {
									this.apiService
										.updateZohoApplicationStage(
											this.zohoApplicationID,
											stageChange
										)
										.subscribe((res) => {
											this.apiService.sendClickEvent();
											this.emailChecklistNotifications(
												environment.accountsEmail,
												this.emailPurpose[0],
												'validation_email',
												this.validationEmail
											);
										});
									// }
									break;
								}
								default: {
									this.zoho_application_stage = '';
									this.apiService
										.updateStageClosingDate(this.zohoApplicationID, stageChange)
										.subscribe((res) => {
											this.apiService.sendClickEvent();
											this.emailChecklistNotifications(
												environment.accountsEmail,
												this.emailPurpose[2],
												'funded_email',
												this.fundedEmail
											);
											this.zoho_application_stage = 'Funded';
										});
								}
							}
						} else {
							// this.emailChecklistNotifications(environment.accountsEmail, this.emailPurpose[1]);    // For testing purpose
							// alert("Final funding email notification sent!");
							// Email Final Funding Officer
							let finalFundingOfficerEmail = this.funding_officers.filter(
								(item) =>
									item.employeeID ==
									this.checklistEntry.fundingForm.get('final_funding_officer')
										.value
							);
							if (finalFundingOfficerEmail.length === 0) {
								console.log('Funding Saved!');
							} else {
								this.emailChecklistNotifications(
									finalFundingOfficerEmail[0]['email'],
									this.emailPurpose[1],
									'final_funding_email',
									this.finalFundingEmail
								); // Uncomment on production
								if (!this.finalFundingEmail) {
									alert('Email notification sent to final funding officer!');
								}
							}
						}
					} else {
						console.log('Form Not Filled');
					}
				} catch (err) {
					console.log(err);
					console.log('Form Not Filled');
				}
			});
	}

	emailChecklistNotifications(
		finalFundingOfficer,
		purpose,
		emailStage,
		emailFlag
	) {
		if (!emailFlag) {
			this.apiService
				.sendEmailFundingOfficer(
					finalFundingOfficer,
					this.zohoApplicationID,
					this.Deal_Name,
					this.co_app_required,
					purpose,
					this.serverURL
				)
				.subscribe((res) => {
					if (res['status']) {
						this.apiService
							.updateEmailStatus(this.zohoApplicationID, emailStage)
							.subscribe((res) => {
								console.log(res, 'updateEmailStatus');
							});
						console.log(res, 'Attempting to Send Email');
					} else {
						console.log(res, 'Email delivery FAILED!');
					}
				});
		}
	}

	emailWarning() {
		this.apiService
			.sendEmailFundingOfficer(
				environment.accountsEmail,
				this.zohoApplicationID,
				this.Deal_Name,
				this.co_app_required,
				'Warning',
				this.serverURL
			)
			.subscribe((res) => {
				if (res['status']) {
					console.log(res, 'Attempting to Send Email');
				} else {
					console.log(res, 'Email delivery FAILED!');
				}
			});
	}

	// Update files based on Primary and Co-applicant
	async updateChecklistStatusMethod(
		controlName,
		checklistStage,
		applicantType
	) {
		return new Promise((resolve, reject) => {
			this.apiService
				.checkCheckBoxes(
					this.zohoApplicationID,
					checklistStage,
					this.co_app_required,
					this.LPP_Included,
					applicantType
				)
				.subscribe(async (res) => {
					try {
						if (res['message']) {
							switch (checklistStage) {
								case 'Frontend':
									if (!res['frontendStatus']) {
										controlName.patchValue(true);
										let resultFront = await this.updateChecklistStatus(
											controlName,
											checklistStage,
											applicantType
										);
									}
									break;
								case 'Backend':
									if (!res['backendStatus']) {
										controlName.patchValue(true);
										let resultBack = await this.updateChecklistStatus(
											controlName,
											checklistStage,
											applicantType
										);
									}
									break;
								case 'Funding':
									if (!res['fundingStatus']) {
										controlName.patchValue(true);
										let resultFunding = await this.updateChecklistStatus(
											controlName,
											checklistStage,
											applicantType
										);
									}
									break;
								default:
							}
						} else {
							controlName.patchValue(false);
							switch (checklistStage) {
								case 'Frontend':
									let resultFront = await this.updateChecklistStatus(
										controlName,
										checklistStage,
										applicantType
									);
									break;
								case 'Backend':
									let resultBack = await this.updateChecklistStatus(
										controlName,
										checklistStage,
										applicantType
									);
									break;
								case 'Funding':
									let resultFunding = await this.updateChecklistStatus(
										controlName,
										checklistStage,
										applicantType
									);
									break;
								default:
							}
						}
						resolve(true);
					} catch (err) {
						console.log(err);
						console.log('Form Not Filled');
						resolve(false);
					}
				});
		});
	}

	completeStatusCheck(controlName) {
		return controlName.value === true ? 'Complete' : 'Incomplete';
	}

	/**
	 * Checklist Status
	 * @param controlName Form control value
	 * @param checklistStage Frontend, Backend, Funding
	 * @param applicantType Primary Applicant, Co-applicant
	 * @returns Update the status
	 */
	async updateChecklistStatus(controlName, checklistStage, applicantType) {
		return new Promise((resolve, reject) => {
			this.apiService
				.updateChecklistStatus(
					controlName.value,
					this.zohoApplicationID,
					checklistStage,
					applicantType
				)
				.subscribe((res: any) => {
					console.log(res, 'updateChecklistStatus');
					resolve(true);
				});
		});
	}

	/**
	 *
	 * @returns Promise to create folder
	 */
	async createChecklistFolders() {
		return new Promise((resolve, reject) => {
			this.apiService
				.createChecklistFolders(this.zohoApplicationID)
				.subscribe(async (res) => {
					console.log(res, 'FOLDER CREATE');
					resolve(res);
				});
		});
	}

	// Get the file details on change of input type: file
	onBalanceLetterChange(event: any) {
		// Balance letter type: file
		let fileSize = event[0]['size'];
		let file_Type = event[0];
		if (fileSize > this.fileLimitSize) alert('Invalid File Type/Size');
		else this.balanceLetterFile = event;

		this.balanceLetterFile = event;
	}

	onAmortizationChange(event: any) {
		// Amortization type: file
		this.amortizationFile = event;
	}

	onPOPchange(event: any) {
		// Proof of payment type: file
		this.popFile = event;
	}

	/**
	 *
	 * @param file files events from onchange event of the input type: file
	 * @returns formData of the list of files selected to upload
	 */
	collectFiles(file: any) {
		try {
			const formData = new FormData();
			for (var i = 0; i < file['length']; i++) {
				formData.append('files', file[i], file[i].name);
			}
			return formData;
		} catch (err) {
			console.log('No attachment' + file);
		}
	}

	/**
	 *
	 * @param files
	 * @param folderID unique folder ID i.e zoho application record ID
	 * @param folderName name of the respective uploads i.e BalanceLetters, AmortizationSchedule and POP
	 * @returns Promise of uploading the files
	 */
	async uploadFiles(files: any, folderID: any, folderName: any) {
		const fileSuffix = new Date().toJSON().replace(new RegExp(':', 'g'), '.');
		return new Promise((resolve, reject) => {
			try {
				this.saveToS3(files, folderName, fileSuffix);
				let result = this.apiService
					.checklistUploadFile(
						this.collectFiles(files),
						folderID,
						folderName,
						fileSuffix
					)
					.subscribe(
						(res) => {
							if (res.length != '0') {
								console.log(res);
							} else {
								console.log('Error uploading files on local server');
								console.log(res);
							}
							resolve(result);
						},
						(error) => {
							reject(error);
						}
					);
			} catch (err) {
				resolve(err);
				console.log('Error: ' + folderName);
			}
		});
	}

	/**
	 * 1. Create Checklist Folder
	 * 2. Upload all the files on the respective folder
	 * 3. Display the files uploaded
	 * 4. Reset the input controls of file uploader
	 */
	async saveFiles() {
		let folderResult = await this.createChecklistFolders();
		// let balanceResult = await this.uploadFiles(this.balanceLetterFile, this.zohoApplicationID, 'BalanceLetters');
		// let amortizationResult = await this.uploadFiles(this.amortizationFile, this.zohoApplicationID, 'AmortizationSchedule');
		// this.filesToLoad();
		// this.resetFileUploadControl();
		this.controls.displayMessage(
			'saveFundingEmailBtn',
			false,
			'saveFundingEmailMsg',
			'Save successful',
			'green',
			'block'
		);
	}

	async saveFinalFiles() {
		let folderResult = await this.createChecklistFolders();
		let popResult = await this.uploadFiles(
			this.popFile,
			this.zohoApplicationID,
			'POP'
		);
		this.filesToLoad();
		this.checklistEntry.fundingForm.get('proof_of_payment_file').reset();
		// this.resetFileUploadControl();
		this.controls.displayMessage(
			'saveFundingBtn',
			false,
			'saveFundingMsg',
			'Save successful',
			'green',
			'block'
		);
	}

	// Reset the file upload controls
	resetFileUploadControl() {
		this.checklistEntry.fundingForm.get('balance_letters_file').reset();
		this.checklistEntry.fundingForm.get('amortization_schedule_file').reset();
	}

	// Display the files uploaded
	async filesToLoad() {
		this.balanceLetterFiles = await this.loadUploadedFiles(
			this.zohoApplicationID,
			'BalanceLetters'
		);
		this.amortizationFiles = await this.loadUploadedFiles(
			this.zohoApplicationID,
			'AmortizationSchedule'
		);
		this.popFiles = await this.loadUploadedFiles(this.zohoApplicationID, 'POP');
	}

	/**
	 *
	 * @param folderID Zoho Application ID
	 * @param folderName Folder Name (Example: BalanceLetters, AmortizationSchedule, POP)
	 * @returns List of Files in the directory
	 */
	async loadUploadedFiles(folderID: any, folderName: any) {
		return new Promise((resolve, reject) => {
			try {
				this.apiService.readDirectory(folderID, folderName).subscribe(
					async (res) => {
						let result = res;
						if (res.message === 'No Directory') {
							this.getAwsBucketFiles(folderName, folderID).then((result) => {
								resolve(result);
							});
						} else {
							if (result.length !== 0) {
								resolve(result);
							} else {
								this.getAwsBucketFiles(folderName, folderID).then((result) => {
									resolve(result);
								});
							}
						}
					},
					(error) => {
						console.log('Error loading contents form do', error);
						this.getAwsBucketFiles(folderName, folderID).then((result) => {
							resolve(result);
						});
					}
				);
			} catch (err) {
				this.getAwsBucketFiles(folderName, folderID).then((result) => {
					resolve(result);
				});
				console.log('Error: ' + folderID + '/' + folderName);
			}
		});
	}

	// Read the files from the AWS
	async getAwsBucketFiles(folderName, folderID) {
		return new Promise((resolve, reject) => {
			this.s3Service.getFilesFromFolder(folderName, folderID).then((files) => {
				const contents =
					files?.Contents?.map((meta) => meta.Key.replace(files.Prefix, '')) ||
					[];
				resolve(contents);
			});
		});
	}

	/**
	 *
	 * @param folderID Zoho Application ID
	 * @param folderName Folder Name (Example: BalanceLetters, AmortizationSchedule, POP)
	 * @param fileName File Name
	 */
	getfile(folderID: any, folderName: any, fileName: any) {
		this.apiService.readFile(folderID, folderName, fileName).subscribe(
			(res: any) => {
				if (res) {
					console.log(res, "Drive--------------")
					let buffer = new Uint8Array(res['content']['data']);
					var blob;
					switch (res['fileExt']) {
						case '.pdf':
						case '.PDF':
							blob = new Blob([buffer], { type: 'application/pdf' });
							break;
						case '.jpg':
						case '.JPG':
							blob = new Blob([buffer], { type: 'image/jpg' });
							break;
						case '.png':
						case '.PNG':
							blob = new Blob([buffer], { type: 'image/png' });
							break;
						case '.heic':
						case '.HEIC':
							blob = new Blob([buffer], { type: 'image/heic' });
							break;
						case '.doc':
						case '.DOC':
							blob = new Blob([buffer], { type: 'application/msword' });
							break;
						case '.docx':
						case '.DOCX':
							blob = new Blob([buffer], {
								type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
							});
							break;
						case '.csv':
						case '.CSV':
							blob = new Blob([buffer], { type: 'text/csv' });
							break;
						case '.xlsx':
						case '.XLXS':
							blob = new Blob([buffer], {
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							});
							break;
						default:
							alert('Unable to open File');
					}
					var url = URL.createObjectURL(blob);
					window.open(url);
				} else {
					console.log('S3 bucket--------------')
					this.s3Service
						.getFile(fileName, folderName, folderID)
						.then((file: any) => {
							file.Body.transformToByteArray().then((byteArray) => {
								const blob = new Blob([byteArray], { type: file.ContentType });
								window.open(URL.createObjectURL(blob));
							});
						});
				}
			}
			// (error) => {
			// 	console.log('Error getting file from do', error);
			// 	this.s3Service
			// 		.getFile(fileName, folderName, folderID)
			// 		.then((file: any) => {
			// 			file.Body.transformToByteArray().then((byteArray) => {
			// 				const blob = new Blob([byteArray], { type: file.ContentType });
			// 				window.open(URL.createObjectURL(blob));
			// 			});
			// 		});
			// }
		);
	}

	/**
	 *
	 * @param folderID Zoho Application ID
	 * @param folderName Folder Name (Example: BalanceLetters, AmortizationSchedule, POP)
	 * @param fileName File Name
	 */
	async deleteFile(folderID: any, folderName: any, fileName: any) {
		this.apiService
			.deleteFile(folderID, folderName, fileName)
			.subscribe((res: any) => {
				if (res === null) {
					// Deleting the file response with null
					this.filesToLoad();
					console.log('Delete successful!');
				}
			});
		this.s3Service.deleteFile(fileName, folderName, folderID).then((files) => {
			console.log('Deleted successfully from s3', files);
			this.filesToLoad();
		});
	}

	initialFrontEndFormValue: any;
	initialBackendFormValue: any;
	initialFundingFormValue: any;

	emailedValidation = false;
	// Populate the checklist if exist
	async getChecklistRecords(zohoAppID: any, appType: any) {
		return new Promise((resolve, reject) => {
			this.apiService
				.getChecklistInfo(zohoAppID, appType)
				.subscribe(async (res) => {
					if (res.message == 'Success' && res.data.length != 0) {
						// Frontend
						this.emailedValidation = res.data[0]['validation_email']; // Assigning email flag for the warning email to funding
						// console.log(res, "++++++++++++++++++++++++++getChecklistRecords")
						for (const field in this.checklistEntry.frontEndForm.controls) {
							if (field.match(res.data[0]) || field.search(res.data[0])) {
								this.customFunction.setControlValue(
									this.checklistEntry.frontEndForm,
									field,
									res.data[0][field]
								);
							}
						}
						this.initialFrontEndFormValue = Object.assign(
							{},
							this.checklistEntry.frontEndForm.value
						);
						if (res['data'][0]['frontend_officer'] === null) {
							if (
								this.underwriter_names.filter(
									(item) => item.employeeID === this.sessionUser['employeeID']
								).length !== 0
							) {
								this.checklistEntry.frontEndForm
									.get('frontend_officer')
									.patchValue(this.sessionUser['employeeID']);
							} else {
								this.checklistEntry.frontEndForm
									.get('frontend_officer')
									.patchValue('');
								this.controls.displayMessage(
									'saveFrontendBtn',
									true,
									'saveFrontendMsg',
									'',
									'transparent',
									'block'
								);
							}
						}

						// Backend
						for (const field in this.checklistEntry.backEndForm.controls) {
							if (field.match(res.data[0]) || field.search(res.data[0])) {
								this.customFunction.setControlValue(
									this.checklistEntry.backEndForm,
									field,
									res.data[0][field]
								);
							}
						}
						this.initialBackendFormValue = Object.assign(
							{},
							this.checklistEntry.backEndForm.value
						);
						if (res['data'][0]['manager_exception'] === null) {
							this.checklistEntry.backEndForm
								.get('manager_exception')
								.patchValue('No');
						}
						if (res['data'][0]['backend_officer'] === null) {
							if (
								this.underwriter_names.filter(
									(item) => item.employeeID === this.sessionUser['employeeID']
								).length !== 0
							) {
								this.checklistEntry.backEndForm
									.get('backend_officer')
									.patchValue(this.sessionUser['employeeID']);
							} else {
								this.checklistEntry.backEndForm
									.get('backend_officer')
									.patchValue('');
								this.controls.displayMessage(
									'saveBackendBtn',
									true,
									'saveBackendMsg',
									'',
									'transparent',
									'block'
								);
							}
						}

						// Funding
						if (appType == 'Primary Applicant') {
							for (const field in this.checklistEntry.fundingForm.controls) {
								if (field.match(res.data[0]) || field.search(res.data[0])) {
									if (
										field !== 'balance_letters_file' &&
										field !== 'amortization_schedule_file'
									)
										this.customFunction.setControlValue(
											this.checklistEntry.fundingForm,
											field,
											res.data[0][field]
										);
								}
							}
							this.initialFundingFormValue = Object.assign(
								{},
								this.checklistEntry.fundingForm.value
							);
							if (res['data'][0]['funding_officer'] === null) {
								if (
									this.funding_officers.filter(
										(item) => item.employeeID === this.sessionUser['employeeID']
									).length !== 0
								) {
									this.checklistEntry.fundingForm
										.get('funding_officer')
										.patchValue(this.sessionUser['employeeID']);
								} else {
									this.checklistEntry.fundingForm
										.get('funding_officer')
										.patchValue('');
									this.controls.displayMessage(
										'saveFundingEmailBtn',
										true,
										'saveFundingEmailMsg',
										'',
										'transparent',
										'block'
									);
								}
							}
						}

						this.validationEmail =
							res.data[0]['validation_email'] === null
								? false
								: res.data[0]['validation_email'];
						this.finalFundingEmail =
							res.data[0]['final_funding_email'] === null
								? false
								: res.data[0]['final_funding_email'];
						this.fundedEmail =
							res.data[0]['final_funding_email'] === null
								? false
								: res.data[0]['funded_email'];
					} else {
						this.checklistEntry = <ChecklistEntry>{};
						this.checklistEntry = new ChecklistEntry();
						this.initialFrontEndFormValue = '';
						this.initialBackendFormValue = '';
						this.initialFundingFormValue = '';

						if (
							this.underwriter_names.filter(
								(item) => item.employeeID === this.sessionUser['employeeID']
							).length !== 0
						) {
							this.checklistEntry.backEndForm
								.get('backend_officer')
								.patchValue(this.sessionUser['employeeID']);
							this.checklistEntry.frontEndForm
								.get('frontend_officer')
								.patchValue(this.sessionUser['employeeID']);
						} else {
							this.checklistEntry.frontEndForm
								.get('frontend_officer')
								.patchValue('');
							this.controls.displayMessage(
								'saveBackendBtn',
								true,
								'saveBackendMsg',
								'',
								'transparent',
								'block'
							);
							this.checklistEntry.backEndForm
								.get('backend_officer')
								.patchValue('');
							this.controls.displayMessage(
								'saveFrontendBtn',
								true,
								'saveFrontendMsg',
								'',
								'transparent',
								'block'
							);
						}

						if (appType == 'Primary Applicant') {
							if (
								this.funding_officers.filter(
									(item) => item.employeeID === this.sessionUser['employeeID']
								).length !== 0
							) {
								this.checklistEntry.fundingForm
									.get('funding_officer')
									.patchValue(this.sessionUser['employeeID']);
							} else {
								this.checklistEntry.fundingForm
									.get('funding_officer')
									.patchValue('');
								this.controls.displayMessage(
									'saveFundingEmailBtn',
									true,
									'saveFundingEmailMsg',
									'',
									'transparent',
									'block'
								);
							}
						}
						this.checklistEntry.backEndForm
							.get('manager_exception')
							.patchValue('No');
					}
					this.spinner = false;
				});
			resolve(true);
		});
	}

	// Saving Frontend Logs
	saveLogFrontEnd() {
		const logDetails = this.functions.logChanges(
			this.initialFrontEndFormValue,
			this.checklistEntry.frontEndForm.value
		);
		if (logDetails === 'New') {
			this.apiService
				.addChecklistLog(
					this.zohoApplicationID,
					this.applicantType,
					JSON.stringify(this.checklistEntry.frontEndForm.value),
					'Frontend',
					this.sessionUser['employeeID']
				)
				.subscribe((res) => {
					console.log(res);
				});
		} else {
			if (Object.entries(logDetails).length != 0) {
				this.apiService
					.addChecklistLog(
						this.zohoApplicationID,
						this.applicantType,
						JSON.stringify(logDetails),
						'Frontend',
						this.sessionUser['employeeID']
					)
					.subscribe((res) => {
						console.log(res);
					});
			}
		}
	}

	// Saving Backend Logs
	saveLogBackEnd() {
		const logDetails = this.functions.logChanges(
			this.initialBackendFormValue,
			this.checklistEntry.backEndForm.value
		);
		if (logDetails === 'New') {
			this.apiService
				.addChecklistLog(
					this.zohoApplicationID,
					this.applicantType,
					JSON.stringify(this.checklistEntry.backEndForm.value),
					'Backend',
					this.sessionUser['employeeID']
				)
				.subscribe((res) => {
					console.log(res);
				});
		} else {
			if (Object.entries(logDetails).length != 0) {
				this.apiService
					.addChecklistLog(
						this.zohoApplicationID,
						this.applicantType,
						JSON.stringify(logDetails),
						'Backend',
						this.sessionUser['employeeID']
					)
					.subscribe((res) => {
						console.log(res);
					});
			}
		}
	}

	// Saving Funding Logs
	saveLogFundingEnd() {
		const logDetails = this.functions.logChanges(
			this.initialFundingFormValue,
			this.checklistEntry.fundingForm.value
		);
		if (logDetails === 'New') {
			this.apiService
				.addChecklistLog(
					this.zohoApplicationID,
					this.applicantType,
					JSON.stringify(this.checklistEntry.fundingForm.value),
					'Funding',
					this.sessionUser['employeeID']
				)
				.subscribe((res) => {
					console.log(res);
				});
		} else {
			if (Object.entries(logDetails).length != 0) {
				this.apiService
					.addChecklistLog(
						this.zohoApplicationID,
						this.applicantType,
						JSON.stringify(logDetails),
						'Funding',
						this.sessionUser['employeeID']
					)
					.subscribe((res) => {
						console.log(res);
					});
			}
		}
	}

	downloadFile(fileName: string, blob: Blob) {
		try {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			if (link.download !== undefined) {
				// feature detection
				link.setAttribute('href', url);
				link.setAttribute('download', fileName);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (e) {
			console.error('BlobToSaveAs error', e);
		}
	}
	getBase64Image(img) {
		var canvas = document.createElement('canvas');
		canvas.classList.add('myStyle');
		console.log(img.width, 'x', img.height);
		canvas.width = 446;
		canvas.height = 631;
		var ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0);
		var dataURL = canvas.toDataURL('image/png');
		return { img: dataURL, width: canvas.width, height: canvas.height };
	}

	download(event) {
		let doc = new jsPDF('p', 'px', 'a4');
		var width = doc.internal.pageSize;
		for (var i = 0; i < event.target.files.length; i++) {
			let imageData;
			let file = event.target.files[i];
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				imageData = reader.result;
				doc.addImage(
					imageData.img,
					'JPG',
					10,
					10,
					imageData.width,
					imageData.height
				);
				doc.addPage();
			};
			doc.save('FirstPdf.pdf');
		}
	}

	/**
	 * This function will return complete/incomplete from the backend
	 * @param applicationID
	 * @param coAppRequired
	 * @param stageStatus frontend_status, backend_status
	 * @returns Returns Complete/Incomplete Status and validation email flag
	 */
	async returnStatus(applicationID, coAppRequired, stageStatus) {
		return new Promise((resolve, reject) => {
			this.apiService
				.getChecklistDetails(applicationID, coAppRequired, stageStatus)
				.subscribe((res) => {
					// console.log(res, 'returnStatus');
					console.log('returnStatus');
					resolve(res);
				});
		});
	}

	onManagerExceptionChange(e) {
		if (e === 'Yes')
			this.checklistEntry.backEndForm
				.get('final_approval_note')
				.patchValue(false);
	}

	//   existingPrimaryClientInfo: {};
	//   existingCoAppClientInfo: {};
	//   checkExistingClientOnNLS(){
	//     // if(this.zoho_application_stage === "Underwriting Checklist"){
	//       if(this.Deal_Name !== '' && this.dob !== ''){
	//         this.apiService.checkExistingClientOnNLS(this.Deal_Name,this.dob).subscribe((res)=>{
	//           if(res.status && res.data.length !== 0){
	//             this.existingPrimaryClientInfo = 'Primary: ' + this.Deal_Name + ' Loan Number: ' + res?.['data']?.[0]?.['loan_number'];
	//           }else{
	//             this.existingPrimaryClientInfo='';
	//           }
	//         })
	//       }

	//       if(this.co_app_name !== '' && (typeof this.co_app_dob === 'object' && this.co_app_dob !== null)){
	//         this.apiService.checkExistingClientOnNLS(this.co_app_name,this.co_app_dob).subscribe((res)=>{
	//           if(res.status && res.data.length !== 0){
	//             this.existingCoAppClientInfo = 'Primary: ' + this.co_app_name + ' Loan Number: ' + res?.['data']?.[0]?.['loan_number'];
	//           }else{
	//             this.existingCoAppClientInfo ='';
	//           }
	//         })
	//       }
	//     // }

	//     }

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}
