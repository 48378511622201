import { UntypedFormArray } from '@angular/forms';

export class Controls {
	getControlName(formName: any, controlName: any) {
		return formName.get(controlName);
	}

	getFormArray(formName: any, name: any) {
		return formName.get(name) as UntypedFormArray;
	}

	removeRow(formName: any, i: any, groupName: any) {
		let formArray = this.getFormArray(formName, groupName);
		formArray.removeAt(i);
	}

	resetControl(formname: any, controlName: any) {
		formname.get(controlName).reset();
	}

	resetSubForm(formname: any, subformName: any) {
		let subformArray = this.getFormArray(formname, subformName);
		while (subformArray.length !== 0) {
			subformArray.removeAt(0);
		}
	}

	hideDiv(id: any) {
		document.getElementById(id).style.display = 'none';
	}

	displayDiv(id: any) {
		document.getElementById(id).style.display = 'block';
	}

	displayMessage(
		buttonName: any,
		disableButton: any,
		idName: any,
		message: any,
		colorName: any,
		displayStyle: any
	) {
		(<HTMLInputElement>document.getElementById(buttonName)).disabled =
			disableButton;
		document.getElementById(idName).innerHTML = message;
		document.getElementById(idName).style.color = colorName;
		document.getElementById(idName).style.display = displayStyle;
	}
}
