export class Functions {
	private regex: RegExp = new RegExp('^[0-9]*$'); // Numbers only
	private specialKeys: Array<string> = [
		'Backspace',
		'ArrowLeft',
		'ArrowRight',
		'Delete',
		'.',
		'Tab',
	]; // Accept the ones in the array

	logChanges(initialForm: any, currentForm: any) {
		try {
			const currentFormValue = currentForm;
			const logChecklist = Object.keys(currentFormValue)
				.filter((key) => currentFormValue[key] != initialForm[key])
				.reduce((obj, key) => {
					obj[key] = currentFormValue[key];
					return obj;
				}, {});

			console.log(JSON.stringify(logChecklist));
			return logChecklist;
		} catch (err) {
			console.log('New Entry!');
			return 'New';
		}
	}

	// Only Accept numbers
	numberOnly(e: any) {
		if (this.specialKeys.indexOf(e.key) !== -1) {
			return;
		}
		const inputValue: string = e.key;
		if (!inputValue.match(this.regex)) {
			e.preventDefault();
		}
	}
}
