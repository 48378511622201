<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>Underwriting Exception report</h5>
		<div class="col-2">
			<label class="form-label">Start Date</label>
			<input type="date" class="form-control" [(ngModel)]="startDate" />
		</div>
		<div class="col-2">
			<label class="form-label">End Date</label>
			<input type="date" class="form-control" [(ngModel)]="endDate" />
		</div>
		<div class="col-2">
			<label class="form-label">As of Date</label>
			<input type="date" class="form-control" [(ngModel)]="asOfDate" />
		</div>
		<div class="col-1">
			<br />
			<button class="btn btn-success" (click)="filterReport()">Submit</button>
		</div>
	</div>
	<div class="row row-cols-12">
		<h5>Summary</h5>
		<div class="col-12">
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<th>Summary</th>
						<th>Funded (#)</th>
						<th>Delinq. Count</th>
						<th>Delinq. Amount</th>
						<th>Delinq. Amount %</th>
						<th>Default Count</th>
						<th>Default Amount</th>
						<th>Default Amount %</th>
						<th>CC/CP Count</th>
						<th>CC/CP Amount</th>
						<th>BK Count</th>
						<th>BK Amount</th>
						<th>Insolvency Rate %</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of exceptionList">
						<td>{{ item.Summary }}</td>
						<td>{{ item.Funded }}</td>
						<td>{{ item.DelinqCount }}</td>
						<td>{{ item.DelinqAmount }}</td>
						<td>{{ item.DelinqRate }}</td>
						<td>{{ item.DefaultCount }}</td>
						<td>{{ item.DefaultAmount }}</td>
						<td>{{ item.DefaultRate }}</td>
						<td>{{ item.CC_CP_Count }}</td>
						<td>{{ item.CC_CP_Amount }}</td>
						<td>{{ item.BK_Count }}</td>
						<td>{{ item.BK_Amount }}</td>
						<td>{{ item.InsolvencyRate }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="row row-cols-12" *ngIf="exceptionList">
		<h5>Detailed Breakdown</h5>
		<div class="col-12">
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<th>Underwriter</th>
						<th>Funded (#)</th>
						<th>Delinq. Count</th>
						<th>Delinq. Amount</th>
						<th>Delinq. Amount %</th>
						<th>Default Count</th>
						<th>Default Amount</th>
						<th>Default Amount %</th>
						<th>CC/CP Count</th>
						<th>CC/CP Amount</th>
						<th>BK Count</th>
						<th>BK Amount</th>
						<th>Insolvency Rate %</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of underwriterBreakdownList">
						<td>{{ item.Underwriter }}</td>
						<td>{{ item.Funded }}</td>
						<td>{{ item.DelinqCount }}</td>
						<td>{{ item.DelinqAmount }}</td>
						<td>{{ item.DelinqRate }}</td>
						<td>{{ item.DefaultCount }}</td>
						<td>{{ item.DefaultAmount }}</td>
						<td>{{ item.DefaultRate }}</td>
						<td>{{ item.CC_CP_Count }}</td>
						<td>{{ item.CC_CP_Amount }}</td>
						<td>{{ item.BK_Count }}</td>
						<td>{{ item.BK_Amount }}</td>
						<td>{{ item.InsolvencyRate }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="row row-cols-12" *ngIf="exceptionList">
		<div class="col-12">
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<th>Manager</th>
						<th>Funded (#)</th>
						<th>Delinq. Count</th>
						<th>Delinq. Amount</th>
						<th>Delinq. Amount %</th>
						<th>Default Count</th>
						<th>Default Amount</th>
						<th>Default Amount %</th>
						<th>CC/CP Count</th>
						<th>CC/CP Amount</th>
						<th>BK Count</th>
						<th>BK Amount</th>
						<th>Insolvency Rate %</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of exceptionBreakdownList">
						<td>{{ item.Manager }}-{{ item.Override_Type }}</td>
						<td>{{ item.Funded }}</td>
						<td>{{ item.DelinqCount }}</td>
						<td>{{ item.DelinqAmount }}</td>
						<td>{{ item.DelinqRate }}</td>
						<td>{{ item.DefaultCount }}</td>
						<td>{{ item.DefaultAmount }}</td>
						<td>{{ item.DefaultRate }}</td>
						<td>{{ item.CC_CP_Count }}</td>
						<td>{{ item.CC_CP_Amount }}</td>
						<td>{{ item.BK_Count }}</td>
						<td>{{ item.BK_Amount }}</td>
						<td>{{ item.InsolvencyRate }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
