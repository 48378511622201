export class loanInput {
	//testing
	existing_flag: boolean;
	hidden_start_date: any;
	hidden_funded_date: any;
	//
	exception = false;
	dob: any;
	created_by: String;
	loan_calc_created_by: any;
	id: String;
	application_id: String;
	underwriting_id: String;
	Application_Name: String;
	schedule = [];
	set_Payment_Date = [];
	set_Payment_Number = [];
	set_Payment = [];
	set_Interest = [];
	set_Principal = [];
	set_Loan_Amount = [];
	set_Beg_Balance = [];
	set_End_Balance = [];
	set_Cumulative_Interest = [];
	set_LPP_Ending_Balance = [];
	//// Our Data
	CU: String;
	CU_Fee = 0;
	CU_Total_OB = 0;
	CU_Feq = 0;
	CU_AMT = 15;
	LPP_total_interest = 0;
	Interest_Adjusted_Balance_Forward = 0;
	Setup_Fee_APR: Number;
	Setup_Fee = 0;
	Loan_Type: String;
	Amortization: Number;
	Amortization_Interest: Number;
	Amortization_Interest_Monthly: Number;
	Amortization_Interest_SemiMonthly: Number;
	Amortization_Interest_Weekly: Number;
	Amortization_Interest_Biweekly: Number;
	LPP_Monthly: Number;
	LPP_SemiMonthly: Number;
	LPP_Weekly: Number;
	LPP_Biweekly: Number;
	Payment_Amount_Insurance_Monthly: Number;
	Payment_Amount_Insurance_SemiMonthly: Number;
	Payment_Amount_Insurance_Weekly: Number;
	Payment_Amount_Insurance_Biweekly: Number;
	Loan_Amount: Number;
	Interest_Adjusted_Loan_Amount: Number;
	Interest_Rate: Number;
	apr: Number;
	Term_Type: String;
	Funded_Date: String;
	Start_Date: String;
	Maturity_Date: String;
	Anchor_Date: String;
	IAD: String;
	IAP: Number;
	IAA: Number;
	new_IAP: String;
	Effective_Interest_Rate: Number;
	Periodic_Interest_Rate: Number;
	Duration: Number;
	Term_of_Loan: Number;
	Term_of_Loan_Months: Number;
	Term_of_Loan_Years: Number;
	paymentsPerYear: Number;
	paymentsPerMonth: Number;
	Frequency_Days: Number;
	Folder_ID: String;
	base64_PDF: String;
	Insurance_Check: String;
	isEnhanced: boolean;
	////Zoho Data
	Loan_Number: Number;
	Scheduled_Payments: Number;
	LPP_Included: String;
	Loan_Protection: Number;
	LPP_Taxes: Number;
	LPP_withPayments: Number;
	Total_Obligation: Number;
	Total_Obligation_withLPP: Number;
	Loan_Date: String;
	Last_Payment_Date: String;
	////Insurance
	LPP = 0;
	Insurance: String;
	Province: String;
	Province_Name: String;
	Actual_Cumulative_Interest = 0;
	Actual_Total_Obligation_Entire_Term = 0;
	Total_Obligation_Entire_Term = 0;
	Total_Obligation_Entire_Term_Final = 0;
	Total_Insurance_Term_Without_Tax = 0;
	Insurance_Payment_Without_Prov_Taxes = 0;
	Total_Insurance_With_Prov_Taxes = 0;
	Insurance_Payments_With_Prov_Taxes = 0;
	Tax_Amount_Per_Payment = 0;
	Tax_Amount_Entire_Term = 0;
	Payment_Amount_Insurance = 0;
	Total_Insurance_For_Term = 0;
	Insurance_Factor = 0;
	Province_Insurance_Factor = 0;
	Enhanced_Insurance_Factor = 0;
	Enhanced_Insurance_Factor_Base = 0;
	//checking
	Base_Insurance_Enhanced_Insurance = 0;
	Tax = 0;
	Original_Loan_Amount: Number;
	Creditor_Portfolio: String;
	Is_Old_Calculator: Boolean;
	Additional_Debt_Creditor_Portfolio: String;
	custom_settlement_counts: Number;
}
