import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-breakdown',
  templateUrl: './payment-breakdown.component.html',
  styleUrls: ['./payment-breakdown.component.scss']
})

export class PaymentBreakdownComponent implements OnDestroy {
  selectedLoans = [];

  paymentBreakdownDataSubscription: Subscription;

  selectedReport: any;
  selectedStatus: any;
  selectedBreakdownType: any;
  PaymentBreakdown_Report: any;

  start_date: any;
  end_date: any;

  //_Hist
  getRowStyle_Hist: any
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public gridOptions_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  btnSpinner = false;
  btnShowText = true;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    this.columnDefs_Hist = [
      { headerName: 'LoanGroup', field: 'LoanGroup', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Transaction Date', field: 'Transaction Date', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Effective Date', field: 'Effective Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Account Name', field: 'Short Name', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Loan number', field: 'Loan number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Open Date', field: 'Open Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Principal', field: 'Principal', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Interest', field: 'Interest', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Fees', field: 'Fees', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Late Charges', field: 'Late Charges', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Insurance Premium', field: 'Insurance Premium', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Ins tax', field: 'Ins tax', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'UDBs', field: 'UDBs', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Payment Amount', field: 'Payment Amount', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Loan Status', field: 'Loan Status', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Status Code', field: 'Status Code', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Days Past Due', field: 'Days Past Due', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Original Loan Amount', field: 'Original Loan Amount', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Trial Principal', field: 'Trial Principal', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Trial Balance Date', field: 'Trial Balance Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Payment Type', field: 'Payment Type', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Suspense', field: 'Suspense', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'NSF', field: 'NSF', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Manual DPD', field: 'Manual DPD', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Payoff Date', field: 'Payoff Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Closed Date', field: 'Closed Date', sortable: true, filter: "agDateColumnFilter" }

    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'payment_breakdown_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'payment_breakdown_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  startDate(e) {
    this.start_date = e;
  }
  endDate(e) {
    this.end_date = e;
  }

  getReportType(e) {
    this.selectedLoans = e?.['data'];
  }

  getCustomLoanGroups(e) {
    this.selectedLoans = e;
  }

  selectedLoanStatus(e) {
    this.selectedStatus = e;
  }

  getSelectedBreakdownType(e) {
    this.selectedBreakdownType = e;
  }

  downloadPaymentBreakdown() {
    this.btnSpinner = true;
    this.btnShowText = false;
    this.PaymentBreakdown_Report = null;
    this.rowData_Hist = [];
    if (this.start_date > this.end_date) {
      alert("Invalid Dates")
    } else {
      this.paymentBreakdownDataSubscription = this.apiService.getPaymentBreakdown(this.start_date, this.end_date, this.selectedStatus, this.selectedLoans, this.selectedBreakdownType).subscribe((res) => {
        console.log(res);
        this.PaymentBreakdown_Report = res;
        this.gridApi_Hist.setRowData(this.PaymentBreakdown_Report);
        this.rowData_Hist = this.PaymentBreakdown_Report;
        this.btnSpinner = false;
        this.btnShowText = true;
      });
    }
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.PaymentBreakdown_Report);
    this.rowData_Hist = this.PaymentBreakdown_Report

  }

  ngOnDestroy(): void {
    this.paymentBreakdownDataSubscription?.unsubscribe();
  }
}
