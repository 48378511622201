import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '@lmc-env/environment';

@Component({
	selector: 'app-top-nav',
	templateUrl: './top-nav.component.html',
	styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
	pageHistoryArray: string[] = []; // Assign the response on this array
	currentRoute: string; // History Page route

	userName: string;

	userPosition: string;

	searchKeyword: string;

	currentRouteURL: string;
	currentAfterRedirectsURL: string;

	hideNavBarUrls = ['login', '/oldCalculator?', '/calculator?', '/transunion?', '/inverite?', '/underwriting?', '/note', 'uwp-dashboard', 'tv-intermedia-calls', 'tv-collection-reports', 'tv-funded-reports', 'documents', 'preapproval', 'transunion-consent-form'];

	hideNavBar: boolean;

	ignoreBodyPaddingUrls = ['reports', 'summary', 'loan-meta'];
	ignoreBodyPadding = false;

	appVersion = environment.version;

	// Formcontrol for page history dropdown
	// historyForm = new FormGroup({
	//   'page_history': new FormControl('')
	// })

	collectionListComponentFlag = false;
	collectionManagerReportComponentFlag = false;
	ptpFinanceFlag = false;
	adminDashboardFlag = false;
	userRoleID: any;
	insuranceCancellationFlag = false;
	loanServicingReportsFlag = false;

	isExternalView: boolean;
	fullName: string;

	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const { url, urlAfterRedirects } = event;
				this.currentRouteURL = url;
				this.currentAfterRedirectsURL = urlAfterRedirects;
				this.loadVerifySignin();
				this.updateNavBarVisibility();
				console.log('Navigated to: ', url);
				console.log('After Redirects to: ', urlAfterRedirects);
			}
		});
	}

	ngOnInit(): void {

	}

	loadVerifySignin() {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe((res: any) => {
				if (res.message === 'Success') {
					this.router.routeReuseStrategy.shouldReuseRoute = () => false;
					const userDetails = res.data?.[0];
					this.userName = userDetails?.['email'];
					this.userPosition = userDetails?.['position'];
					this.collectionListComponentFlag = userDetails?.['collection_list'];
					this.collectionManagerReportComponentFlag = userDetails?.['collection_manager_reports'];
					this.ptpFinanceFlag = userDetails?.['ptp_finance'];
					this.adminDashboardFlag = userDetails?.['admin_dashboard'];
					this.insuranceCancellationFlag = userDetails?.['insurance_cancellation'];
					this.loanServicingReportsFlag = userDetails?.['loan_servicing_reports'];
					this.userRoleID = userDetails?.['roleID'];
					this.fullName = userDetails?.['full_name'];
					if (['UW', 'FO'].includes(this.userPosition)) {
						const notesIndex = this.hideNavBarUrls.indexOf('/note');
						if (notesIndex > -1) {
							this.hideNavBarUrls.splice(notesIndex, 1)
						}
					}
					this.isExternalView = userDetails?.['roleID'] === 12 ? true : false;
					this.updateNavBarVisibility();
					this.loadPageHistory();

					this.currentRoute = " ";
				} else {
					this.router.navigate(["login"]);
				}

			})
		} else {
			this.router.navigate(["login"]);
		}
	}

	updateNavBarVisibility() {
		this.hideNavBar = !!this.hideNavBarUrls.find(
			(navUrl: string) =>
				this.currentRouteURL.includes(navUrl) ||
				this.currentAfterRedirectsURL.includes(navUrl)
		);
		this.ignoreBodyPadding = !!this.ignoreBodyPaddingUrls.find(
			(navUrl: string) =>
				this.currentRouteURL.includes(navUrl) ||
				this.currentAfterRedirectsURL.includes(navUrl)
		);
		if (this.hideNavBar) {
			this.renderer.removeClass(this.document.body, 'has-nav-bar');
		} else {
			this.renderer.addClass(this.document.body, 'has-nav-bar');
		}
		if (this.ignoreBodyPadding) {
			this.renderer.addClass(this.document.body, 'ignore-body-padding');
		} else {
			this.renderer.removeClass(this.document.body, 'ignore-body-padding');
		}
	}

	logout() {
		localStorage.removeItem('SESSIONID');
	}

	// Load the page history on the dropdown
	loadPageHistory() {
		this.apiService.getPageHistory(this.userName).subscribe((res) => {
			// console.log(res)
			this.pageHistoryArray = res.data;
		});
	}

	routeTo(e) {
		this.router.navigate(['/' + e]);
	}

	onSearchKeywordChange(event) {
		if (event.key === 'Enter') {
			this.router.navigate(['dashboard'], {
				queryParams: {
					searchKeyword: this.searchKeyword || event.target.value,
				},
			});
		}
	}

	onSearchClicked() {
		this.router.navigate(['dashboard'], {
			queryParams: {
				searchKeyword: this.searchKeyword,
			},
		});
	}
}
