<div class="arrangement-form" *ngIf="type === 'arrangement'">
  <mat-form-field appearance="outline">
    <mat-label>Arrangement Type</mat-label>
    <mat-select [(ngModel)]="arrangementType">
      <mat-option [value]="ArrangementType.PayOff">Pay off</mat-option>
      <mat-option [value]="ArrangementType.PayOffExcludeNSF">Pay off (exclude NSF)</mat-option>
      <mat-option [value]="ArrangementType.PayDown">Pay Down</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="arrangement-payoff-form" style="display: flex"
    *ngIf="type === 'arrangement' && (arrangementType === ArrangementType.PayOff || arrangementType === ArrangementType.PayOffExcludeNSF) && PtP_Date">
    <button mat-raised-button color="primary" class="buttonSubmit" (click)="createPayoffStatement()"
      style="width: 200px; margin-left: 10px">
      Request
    </button>
    <button mat-mini-fab color="primary" (click)="downloadPayoffData()" *ngIf="showDownloadPayoff"
      style="margin-left: 10px">
      <mat-icon>get_app</mat-icon>
    </button>
    <div style="position: relative">
      <button mat-mini-fab color="primary" (mouseenter)="showPayoffCard = true" (mouseleave)="showPayoffCard = false"
        *ngIf="showDownloadPayoff" style="margin-left: 10px">
        <mat-icon>info</mat-icon>
      </button>
      <mat-card class="payoff-details" *ngIf="type === 'arrangement' && showDownloadPayoff && showPayoffCard">
        <mat-card-header>
          <mat-card-title>Payoff Statement</mat-card-title>
          <mat-card-subtitle>Date: {{ this.payoffRequestedDate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="payoff-details-content">
          <div>Maturity Date: {{client_info?.[0]?.['curr_maturity_date']}}</div>
          <div>Principal Balance: ${{payoffRequestedInfo?.['PrincipalBalance']}}</div>
          <div>
            Accrued Interest: ${{payoffRequestedInfo?.['AccruedInterest']?.toFixed(2)}}
          </div>
          <div>Insurance: ${{ meta?.insurance }}</div>
          <div *ngIf="arrangementType === ArrangementType.PayOff">NSF Fees: ${{ meta?.eff_fees_balance }}</div>
          <div>Per Diem: ${{payoffRequestedInfo?.['PerDiem']?.toFixed(2)}}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="ptp-form" *ngIf="type !== 'arrangement' || arrangementType">
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="datepicker1" placeholder="Start Date"
      [(ngModel)]="PtP_Date" required />
    <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
    <mat-datepicker #datepicker1></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Amount</mat-label>
    <input matInput type="number" required [(ngModel)]="PtP_Amount" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Payment Type</mat-label>
    <mat-select [(ngModel)]="payment_type" required>
      <mat-option value="manual_debit">Manual Debit</mat-option>
      <mat-option value="customer_deposit">Customer Deposit</mat-option>
      <!-- <mat-option value="manual_overwrite">Manual Overwrite (Principle Advanced)</mat-option> -->
    </mat-select>
  </mat-form-field>
  <!--  <mat-form-field appearance="outline">-->
  <!--    <mat-label>Follow-up Date</mat-label>-->
  <!--    <input-->
  <!--      matInput-->
  <!--      readonly-->
  <!--      type="datetime-local"-->
  <!--      id="FollowupDate_Formatted"-->
  <!--      name="FollowupDate_Formatted"-->
  <!--      [(ngModel)]="FollowupDate_Formatted"-->
  <!--    />-->
  <!--  </mat-form-field>-->
  <mat-form-field appearance="outline" style="grid-column-start: 1; grid-column-end: 3">
    <mat-label>Comments</mat-label>
    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
      [(ngModel)]="Comments"></textarea>
  </mat-form-field>
  <div style="display:flex; flex-direction: column;">
    <span id="savePtpMsg"></span>
    <button id="savePtpBtn" mat-raised-button color="primary" class="buttonSubmit" (click)="createPtP()"
      style="width: 150px" disabled={{dpd_save_button_disable}}>
      Save
      <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="sr-only">Saving...</span> -->
    </button>
  </div>
</div>