import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
	selector: 'app-pandadocs',
	templateUrl: './pandadocs.component.html',
	styleUrls: ['./pandadocs.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class PandadocsComponent implements OnInit, OnDestroy {
	zohoApplicationID: string;
	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;
	constructor(
		private store: Store,
		private apiService: ApiServiceService,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		private domSanitizer: DomSanitizer
	) { }

	//Pandadocs Documents
	pandadoc_documents: any;
	pandadocMsg = '';
	spinner = false;
	public gDriveUrlRaw: string;
	public iframeUrl: SafeResourceUrl;
	@Input() summaryData: any;
	@ViewChild('documentsTab', { static: false }) documentsTab: MatTabGroup;

	public switchTab(index) {
		const tabGroup = this.documentsTab;
		if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

		const tabCount = tabGroup._tabs.length;
		tabGroup.selectedIndex = index % tabCount;
	}

	ngOnInit(): void {
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				(summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.gDriveUrlRaw = summaryData.gdriveextension__Drive_URL;
						if (summaryData.gdriveextension__Drive_URL) {
							this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
								summaryData.gdriveextension__Drive_URL
							);
							this.switchTab(0);
						}
						this.ListDocumentsPandadocs();
					}
				}
			);
	}

	ListDocumentsPandadocs() {
		this.apiService
			.ListDocumentsPandadocs(this.zohoApplicationID)
			.subscribe((res: any) => {
				// console.log(res, '==============================================');
				// this.pandadoc_documents= res
				if (res?.['results'].length !== 0) {
					this.pandadoc_documents = res?.['results'];
					this.spinner = false;
					this.pandadocMsg = '';
					// console.log(this.pandadoc_documents);
				} else {
					this.spinner = false;
					this.pandadocMsg = 'No Documents!';
				}
				// if(res.length !==0){
				//   try {
				//     for (var i = 0; i < res.length; i++) {
				//       this.pandadoc_documents.push(JSON.parse(res[i]))
				//       this.pandadoc_documents[i].date_created = this.pandadoc_documents[i].date_created.substring(0, 10)
				//       this.pandadoc_documents[i].date_modified = this.pandadoc_documents[i].date_modified.substring(0, 10)
				//       switch (this.pandadoc_documents[i]['status']) {
				//         case 'document.draft': this.pandadoc_documents[i]['status'] = 'Draft'; break;
				//         case 'document.sent': this.pandadoc_documents[i]['status'] = 'Sent'; break;
				//         case 'document.viewed': this.pandadoc_documents[i]['status'] = 'Viewed'; break;
				//         case 'document.completed': this.pandadoc_documents[i]['status'] = 'Completed'; break;
				//         case 'document.voided': this.pandadoc_documents[i]['status'] = 'Voided'; break;
				//         default:
				//       }
				//     }
				//     this.spinner = false;
				//     this.pandadocMsg = '';
				//     console.log(this.pandadoc_documents)
				//   }
				//   catch (err) {
				//     console.log("Unable to connect with Pandadocs API")
				//   }
				// }else{
				//   this.spinner = false;
				//   this.pandadocMsg = "No Documents!"
				// }
			});
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}
