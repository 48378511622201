import { Component, OnInit } from '@angular/core';
import {
	CellClickedEvent,
	ColDef,
	GridReadyEvent,
	SideBarDef,
} from 'ag-grid-community';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { EditPtpModalComponent } from '@lmc-app/loan-servicing/edit-ptp-modal/edit-ptp-modal.component';
import { ButtonCellRendererComponent } from '@lmc-app/custom-table/button-cell-renderer/button-cell-renderer.component';
import { CustomStatsToolPanelComponent } from '@lmc-app/custom-table/custom-stats-tool-panel/custom-stats-tool-panel.component';

@Component({
	selector: 'app-promise-to-pays',
	templateUrl: './promise-to-pays.component.html',
	styleUrls: ['./promise-to-pays.component.scss'],
})
export class PromiseToPaysComponent implements OnInit {
	public columnDefs: ColDef[] = [
		{
			field: '',
			colId: 'apply',
			headerName: 'Apply',
			cellRenderer: ButtonCellRendererComponent,
			cellRendererParams: {
				label: 'Apply',
				matIconLabel: 'playlist_add_check',
				clicked: (data: any) => {
					if (data?.payment_type === "customer_deposit") {
						this.setStatusPTP(data);
					}
					if (data?.payment_type === "manual_debit") {
						this.onApplyPTP(data);
					}
				},
			},
			maxWidth: 80,
			pinned: 'left',
			filter: false,
			suppressMenu: true,
		},
		{
			field: '',
			colId: 'delete',
			headerName: 'Delete',
			cellRenderer: ButtonCellRendererComponent,
			cellRendererParams: {
				label: 'Delete',
				matIconLabel: 'delete_forever',
				clicked: (data: any) => {
					this.onDeletePTP(data);
				},
			},
			maxWidth: 80,
			pinned: 'left',
			filter: false,
			suppressMenu: true,
		},
		{
			field: 'loan_number',
			headerName: 'Loan Number',
			minWidth: 140,
			// wrapText: true,
			autoHeight: true,
			pinned: 'left',
			suppressMenu: true,
			// cellRenderer: (params) => {
			// 	console.log(params)
			// 	return `<a class="noline"">${params.data.loan_number}</a>`;
			// },
		},
		{
			field: 'name',
			// field: 'loan_number',
			headerName: 'Name',
			minWidth: 200,
			autoHeight: true,
			pinned: 'left',
			// cellRenderer: (params) => {
			// 	return `${params.data.name}`;
			// },
		},
		{
			field: 'loan_group',
			headerName: 'Loan Group',
			minWidth: 180,
			autoHeight: true,
		},
		{
			field: 'ptp_type',
			headerName: 'Type',
			minWidth: 120,
			autoHeight: true,
		},
		{
			field: 'payment_type',
			headerName: 'Payment Type',
			minWidth: 150,
			autoHeight: true,
		},
		{
			field: 'payment_amount',
			headerName: 'Amount',
			minWidth: 110,
			autoHeight: true,
			valueGetter: (params) => {
				console.log('Here params', params);
				return this.currencyFormatter.transform(
					params.data?.payment_amount,
					'CAD',
					'symbol-narrow'
				);
			},
		},
		{
			field: 'PtP_Date',
			headerName: 'PTP Date',
			minWidth: 130,
			autoHeight: true,
			// valueGetter: (params) =>
			// 	this.dateFormatter.transform(
			// 		params.data?.PtP_Date,
			// 		'mediumDate',
			// 		'America/Toronto'
			// 	),
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
			autoHeight: true,
		},
		{
			field: 'cancelled_by',
			headerName: 'Cancelled By',
			minWidth: 140,
			autoHeight: true,
			valueGetter: (params) => params.data?.cancelled_by?.split('@')[0],
		},
		{
			field: 'cancelled_at',
			headerName: 'Cancelled At',
			minWidth: 180,
			autoHeight: true,
			// valueGetter: (params) =>
			// 	this.dateFormatter.transform(
			// 		params.data?.cancelled_at,
			// 		'medium',
			// 		'America/Toronto'
			// 	),
		},
		{
			field: 'created_by',
			headerName: 'Created By',
			minWidth: 200,
			autoHeight: true,
			valueGetter: (params) => params.data?.created_by?.split('@')[0],
		},
		{
			field: 'created_at',
			headerName: 'Created',
			minWidth: 180,
			autoHeight: true,
			// valueGetter: (params) =>
			// 	this.dateFormatter.transform(
			// 		params.data?.created_at,
			// 		'medium',
			// 		'America/Toronto'
			// 	),
		},
		{
			field: 'applied_by',
			headerName: 'Applied By',
			minWidth: 130,
			autoHeight: true,
			valueGetter: (params) => params.data?.applied_by?.split('@')[0],
		},
		{
			field: 'applied_at',
			headerName: 'Applied At',
			minWidth: 180,
			autoHeight: true,
		},
		{
			field: 'deleted_by',
			headerName: 'Deleted By',
			minWidth: 130,
			autoHeight: true,
			valueGetter: (params) => params.data?.deleted_by?.split('@')[0],
		},
		{
			field: 'deleted_at',
			headerName: 'Deleted At',
			minWidth: 180,
			autoHeight: true,
		} /*
		{
			field: 'payment_type',
			headerName: 'Payment Type',
			minWidth: 200,
			wrapText: true,
			autoHeight: true,
		},*/,
	];

	ptpRawData = [];
	cancelledCount = 0;
	requestedManualPaymentsCount = 0;
	requestedCustomerDepositsCount = 0;
	showLoader: boolean;
	showEmptyResults: boolean;
	userData: any;
	public dropdownFilters = [
		{
			label: 'Filter PTPs',
			filterColumn: 'status',
			filters: [
				{
					label: 'All',
					value: 'All',
				},
				{
					label: 'Requested',
					value: 'Requested',
					defaultSelected: true,
				},
				{
					label: 'Arranged',
					value: 'Arranged',
				},
				{
					label: 'Processed',
					value: 'Processed',
				},
				{
					label: 'Deleted',
					value: 'Deleted',
				},
				{
					label: 'Cancelled',
					value: 'Cancelled',
				},
			],
		},
		{
			label: 'Payment Type',
			filterColumn: 'payment_type',
			values: ['manual_debit', 'customer_deposit'],
			filters: [
				{
					label: 'Manual Debit',
					value: 'manual_debit',
				},
				{
					label: 'Customer Deposit',
					value: 'customer_deposit',
				},
			],
		},
	];

	public sideBar: SideBarDef | string | string[] | boolean | null;

	public summaryData;

	public rowClassRules = {
		// 'arranged-row': (params) => params.data.status == 'Arranged',
		'requested-row': (params) =>
			params.data.status == 'Requested' && new Date(params.data?.PtP_Date).getTime() < new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0),
		// new Date(params.data?.PtP_Date).getTime() <=
		// new Date().setHours(0, 0, 0, 0),

		// 'cancelled-row': (params) => params.data.status == 'Cancelled',
		// 'deleted-row': (params) => params.data.status == 'Deleted',
		'processed-row': (params) => params.data.status == 'Processed',
		// 'broken-row': (params) => params.data.status == 'Broken',
		// 'nsf-row': (params) => params.data.status == 'NSF',
	};

	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private dateFormatter: DatePipe,

		private currencyFormatter: CurrencyPipe,
		public dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.showEmptyResults = false;
		this.setPTPRawData([]);
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.userData = res.data?.[0];
						this.getPTPData();
					} else {
						this.router.navigate(['login']);
					}
				});
		} else {
			this.router.navigate(['login']);
		}
		/*this.apiService.getLoanGroups().subscribe((res) => {
	  console.log('Loan Groups', res);
	});*/
	}

	getPTPData() {
		this.showLoader = true;
		this.apiService.getAllParentPTPs().subscribe((res: any) => {
			if (res.length) {
				console.log('PTP data', res);
				this.setPTPRawData(res);
				console.log(res)
				this.showLoader = false;
				this.showEmptyResults = false;
			} else {
				this.showLoader = false;
				this.setPTPRawData([]);
				this.showEmptyResults = true;
			}
		});
	}

	setPTPRawData(data) {
		this.ptpRawData = data;
		let todayDate = new Date();
		let previousDate = todayDate.setDate(todayDate.getDate() - 1);		// Get previous date
		this.cancelledCount = data.filter(
			(val) => val.status === 'Cancelled' && val.payment_type === 'manual_debit'
		)?.length;
		this.requestedManualPaymentsCount = data.filter(
			(val) => val.status === 'Requested' && val.payment_type === 'manual_debit'
		)?.length;
		this.requestedCustomerDepositsCount = data.filter(
			(val) =>
				new Date(val.PtP_Date) < new Date(previousDate) && val.status === 'Requested' && val.payment_type === 'customer_deposit'
		)?.length;
		this.setSummaryData();
	}

	setSummaryData() {
		this.sideBar = {
			toolPanels: [
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressPivotMode: true,
						suppressValues: true,
						suppressRowGroups: true,
					},
				},
				{
					id: 'customStats',
					labelDefault: 'Summary',
					labelKey: 'customStats',
					iconKey: 'custom-stats',
					toolPanel: CustomStatsToolPanelComponent,
					toolPanelParams: {
						summaryData: [
							{
								label: 'Cancelled',
								value: this.cancelledCount,
							},
							{
								// label: 'Requested Manual Payments',
								label: 'Manual',
								value: this.requestedManualPaymentsCount,
							},
							{
								// label: 'Requested Customer Deposits',
								label: 'Customer',
								value: this.requestedCustomerDepositsCount,
							},
						],
					},
				},
			],
			defaultToolPanel: 'customStats',
		};
		this.summaryData = [
			{
				name: 'Summary',
				children: [
					{
						name: `Pending cancellations: ${this.cancelledCount}`,
					},
					{
						name: 'Requested',
						children: [
							{
								name: `Manual Payments: ${this.requestedManualPaymentsCount}`,
							},
							{
								name: `Customer Deposits: ${this.requestedCustomerDepositsCount}`,
							},
						],
					},
				],
			},
		];
	}

	onRowDoubleClicked(e) {
		const { data } = e;
		const dialogRef = this.dialog.open(EditPtpModalComponent, {
			autoFocus: true,
			data,
		});
		dialogRef.afterClosed().subscribe(() => {
			this.getPTPData();
		})
	}

	setStatusPTP(data) {
		if (data['status'] == 'NSF') {
			alert('This Payment has already been NSF');
		} else {
			this.apiService.setStatusPTP('Processed', data['parent_id'], this.userData?.email).subscribe((res) => {
				this.getPTPData();
			});
		}
	}

	onApplyPTP(data) {
		let dataArr = [];
		dataArr.push(data);		// Sending as an array to match modal data
		if (data['status'] == 'NSF') {
			alert('This Payment has already been NSF');
		} else {
			this.apiService.applyPTP(dataArr, this.userData?.email).subscribe((res) => {
				this.getPTPData();
			});
		}
	}
	onDeletePTP(data) {
		if (data['status'] == 'NSF') {
			alert('This Payment has already been NSF');
		} else {
			this.apiService
				.deletePTPParent(data['parent_id'], this.userData?.email)
				.subscribe((res) => {
					console.log(res);
					this.getPTPData();
				});
		}
	}

	onGridReady(params: GridReadyEvent) {
		// no-op
	}

	// onCellClicked(e: CellClickedEvent) {
	// 	console.log('cellClicked', e);
	// 	const clickedCol = e.colDef.field;
	// 	const { loan_number } = e.data;
	// 	switch (clickedCol) {
	// 		case 'loan_number':
	// 			return this.openLoanMeta(loan_number);
	// 		default:
	// 			return null;
	// 	}
	// }

	openLoanMeta(loan_number: number) {
		this.apiService.searchNLSLoan(loan_number).subscribe((res: any[]) => {
			if (res.length == 0) {
				alert('Loan Number cannot be found on NLS');
			} else {
				this.apiService.sendMessage(res);
				this.router.navigate(['loan-meta']);
			}
		});
	}
}
