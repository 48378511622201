import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';

@Component({
  selector: 'app-ach-checks',
  templateUrl: './ach-checks.component.html',
  styleUrls: ['./ach-checks.component.scss']
})
export class AchChecksComponent implements OnInit, AfterViewInit {
  automated_payments_ne: any;
  double_payment_res: any;
  constructor(private apiService: ApiServiceService) { }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.apiService.getLGACHCompanyNEACH().subscribe((res) => {
      this.automated_payments_ne = res;
    });

    this.apiService.getDoublePayments().subscribe((res) => {
      this.double_payment_res = res;
    });
  }

}
