import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';
import { extModules } from './build-specifics';
import { ApiServiceService } from './api-service.service';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { AccountSelectComponent } from './account-select/account-select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReadComponent } from './read/read.component';
import { CreateComponent } from './create/create.component';
import { AgmCoreModule } from '@agm/core'; // Included by Yeshey,
import { MatTabsModule } from '@angular/material/tabs';
import { PreApprovalComponent } from './pre-approval/pre-approval.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SalesdashboardComponent } from './Dashboard/ZOHO/salesdashboard/salesdashboard.component';
import { DealsPipelineComponent } from './Dashboard/ZOHO/deals-pipeline/deals-pipeline.component';
import { UnderwritingdashboardComponent } from './Dashboard/ZOHO/underwritingdashboard/underwritingdashboard.component';
import { CustomFunctionService } from './custom-function.service';
import { NlsdashboardComponent } from './Dashboard/NLS/funded/current/nlsdashboard/nlsdashboard.component';
import { NlsagencyfundedComponent } from './Dashboard/NLS/funded/monthly/nlsagencyfunded/nlsagencyfunded.component';
import { NlsagencycollectorsComponent } from './Dashboard/NLS/funded/monthly/nlsagencycollectors/nlsagencycollectors.component';
import { UnderwritingexceptionComponent } from './Dashboard/ZOHO/underwritingexception/underwritingexception.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UnderwritingagencyexceptionComponent } from './Dashboard/ZOHO/underwritingagencyexception/underwritingagencyexception.component';
import { NlscollectionsComponent } from './Dashboard/NLS/collections/nlscollections/nlscollections.component';
import { NlscreditorcollectionsComponent } from './Dashboard/NLS/collections/grouped/nlscreditorcollections/nlscreditorcollections.component';
import { NlslocationcollectionsComponent } from './Dashboard/NLS/collections/grouped/nlslocationcollections/nlslocationcollections.component';
import { NlsloansizecollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsloansizecollections/nlsloansizecollections.component';
import { NlscreditorcollectionscollectorComponent } from './Dashboard/NLS/collections/grouped/nlscreditorcollectionscollector/nlscreditorcollectionscollector.component';
import { NlsagencycollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsagencycollections/nlsagencycollections.component';
import { NlsagencycollectionscollectorComponent } from './Dashboard/NLS/collections/grouped/nlsagencycollectionscollector/nlsagencycollectionscollector.component';
import { NlsagencycollectionscollectorclientsComponent } from './Dashboard/NLS/collections/grouped/nlsagencycollectionscollectorclients/nlsagencycollectionscollectorclients.component';
import { MatSortModule } from '@angular/material/sort';
import { NlsportfoliocollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsportfoliocollections/nlsportfoliocollections.component';
import { NlsportfoliocollectionsbreakdownComponent } from './Dashboard/NLS/collections/grouped/nlsportfoliocollectionsbreakdown/nlsportfoliocollectionsbreakdown.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ViewUnderwritingComponent } from './view-underwriting/view-underwriting.component';
import { InterviewerqueueComponent } from './Dashboard/ZOHO/interviewerqueue/interviewerqueue.component';
import { ValidatorqueueComponent } from './Dashboard/ZOHO/validatorqueue/validatorqueue.component';
import { UnderwritingTimerstartComponent } from './underwriting-timerstart/underwriting-timerstart.component';
import { MonthlyincomecalcComponent } from './monthlyincomecalc/monthlyincomecalc.component';
import { PhoneMaskDirective } from './phone-mask.directive';
import { ApplicationComponent } from './application/application.component';
import { LoanMetaComponent } from './loan-meta/loan-meta.component';
import { ButtonRendererComponent } from './button-renderer/button-renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { PromisePayComponent } from './loan-meta/promise-pay/promise-pay.component';
import { AmoScheduleComponent } from './loan-meta/amo-schedule/amo-schedule.component';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { NlsexceptioncollectionsComponent } from './Dashboard/NLS/collections/grouped/nlsexceptioncollections/nlsexceptioncollections.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PtpReportComponent } from './Dashboard/NLS/ptp-report/ptp-report.component';
import { UwPerformanceComponent } from './Dashboard/NLS/uw-performance/uw-performance.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { PtpCreatedReportComponent } from './Dashboard/NLS/ptp-created-report/ptp-created-report.component';
import { NgChartsModule } from 'ng2-charts';
import { ReportsComponent } from './Dashboard/NLS/reports/reports.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportsDashboardComponent } from './Dashboard/NLS/reports/reports-dashboard/reports-dashboard.component';
import { RollOversComponent } from './Dashboard/NLS/reports/roll-overs/roll-overs.component';
import { CurrencyShortFormPipe } from './pipes/currency-short-form.pipe';
import { IntermediaCallReportsComponent } from './tv-custom-dashboards/intermedia-call-reports/intermedia-call-reports.component';
import { CollectionListComponent } from './Dashboard/NLS/collections/collection-list/collection-list.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { CustomTooltipComponent } from './custom-table/custom-tooltip/custom-tooltip.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxPrintModule } from 'ngx-print';
import { FundedTvReportComponent } from './tv-custom-dashboards/funded-tv-report/funded-tv-report.component';
import { CollectionTvReportComponent } from './tv-custom-dashboards/collection-tv-report/collection-tv-report.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Summary
import { SummaryComponent } from './summary/summary.component';
import { SummaryBarComponent } from './summary/summary-bar/summary-bar.component';
import { GeneralinformationComponent } from './summary/generalinformation/generalinformation.component';
import { PandadocsComponent } from './summary/pandadocs/pandadocs.component';
import { ChecklistComponent } from './summary/checklist/checklist.component';
import { UnderwritingComponent } from './summary/underwriting/underwriting.component';
import { LoancalculatorComponent } from './summary/loancalculator/loancalculator.component';
import { TransunionComponent } from './summary/transunion/transunion.component';
import { InveriteComponent } from './summary/inverite/inverite.component';
import { LoanInterviewComponent } from './summary/loan-interview/loan-interview.component';
import { UnderwritingExceptionComponent } from './summary/underwriting-exception/underwriting-exception.component';
import { IframeViewComponent } from './summary/iframe-view/iframe-view.component';

import { SafePipe } from '@lmc-app/pipes/safe.pipe';
import { MatGridListModule } from '@angular/material/grid-list';
import { PromiseToPaysComponent } from './loan-servicing/promise-to-pays/promise-to-pays.component';
import { EditPtpModalComponent } from './loan-servicing/edit-ptp-modal/edit-ptp-modal.component';
import { HierarchyDataComponent } from './hierarchy-data/hierarchy-data.component';
import { MatTreeModule } from '@angular/material/tree';
import { CustomStatsToolPanelComponent } from './custom-table/custom-stats-tool-panel/custom-stats-tool-panel.component';
import { ButtonCellRendererComponent } from './custom-table/button-cell-renderer/button-cell-renderer.component';
import { PaymentReportLoanServicingComponent } from './loan-meta/payment-report-loan-servicing/payment-report-loan-servicing.component';
import { AccountInformationComponent } from './loan-servicing/reports/account-information/account-information.component';
import { CurrentAccountInformationComponent } from './loan-servicing/reports/account-information/current-account-information/current-account-information.component';
import { SpvCustomAccountInformationComponent } from './loan-servicing/reports/account-information/spv-custom-account-information/spv-custom-account-information.component';
import { PaymentBreakdownBoardComponent } from './loan-servicing/reports/payment-breakdown-board/payment-breakdown-board.component';
import { MftLpp0876Component } from './loan-servicing/reports/payment-breakdown-board/mft-lpp0876/mft-lpp0876.component';
import { MftLpp4476Component } from './loan-servicing/reports/payment-breakdown-board/mft-lpp4476/mft-lpp4476.component';
import { PaymentBreakdownComponent } from './loan-servicing/reports/payment-breakdown-board/payment-breakdown/payment-breakdown.component';
import { SanityChecksComponent } from './loan-servicing/reports/sanity-checks/sanity-checks.component';
import { AchChecksComponent } from './loan-servicing/reports/sanity-checks/ach-checks/ach-checks.component';
import { AchValidationComponent } from './loan-servicing/reports/sanity-checks/ach-validation/ach-validation.component';
import { DailyReconComponent } from './loan-servicing/reports/sanity-checks/daily-recon/daily-recon.component';
import { BalloonPaymentsComponent } from './loan-servicing/reports/sanity-checks/balloon-payments/balloon-payments.component';
import { ExpiredAchComponent } from './loan-servicing/reports/sanity-checks/expired-ach/expired-ach.component';
import { NsfCollectionListComponent } from './Dashboard/NLS/nsf-collection-list/nsf-collection-list.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { HomeDashboardComponent } from './Dashboard/home-dashboard/home-dashboard.component';
import { CollectionDasboardMetricsComponent } from './Dashboard/home-dashboard/collection-dasboard-metrics/collection-dasboard-metrics.component';
import { CustomBettyReportFiltersComponent } from './loan-servicing/reports/custom-betty-report-filters/custom-betty-report-filters.component';
import { LppReportsComponent } from './loan-servicing/reports/lpp-reports/lpp-reports.component';
import { LppClaimsComponent } from './loan-servicing/reports/lpp-reports/lpp-claims/lpp-claims.component';
import { LppCancellationComponent } from './loan-servicing/reports/lpp-reports/lpp-cancellation/lpp-cancellation.component';
import { InsuranceRemitComponent } from './loan-servicing/reports/lpp-reports/insurance-remit/insurance-remit.component';
import { PtpCollectionListComponent } from './Dashboard/NLS/collections/ptp-collection-list/ptp-collection-list.component';
import { CollectionsComponent } from './Dashboard/NLS/collections/collections.component';
import { PtpDateReportComponent } from './general-reports/ptp-date-report/ptp-date-report.component';
import { PtpDateBasedComponent } from './general-reports/ptp-date-report/ptp-date-based/ptp-date-based.component';
import { ArrangementDateBasedComponent } from './general-reports/ptp-date-report/arrangement-date-based/arrangement-date-based.component';
import { MyInnerRendererComponent } from './custom-table/my-inner-renderer/my-inner-renderer.component';
import { CustomExportExcelComponent } from './custom-export-excel/custom-export-excel.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AssignCollectorsComponent } from './admin-settings/assign-collectors/assign-collectors.component';
import { BettyComponent } from './betty/betty.component';
import { LoanValidationComponent } from './betty/loan-validation/loan-validation.component';
import { InsuranceTransCancellationComponent } from './loan-servicing/insurance-trans-cancellation/insurance-trans-cancellation.component';
import { NsfReportsDateComponent } from './loan-servicing/reports/payment-breakdown-board/nsf-reports-date/nsf-reports-date.component';
import { RapidProgramComponent } from './betty/rapid-program/rapid-program.component';
import { TransunionConsentFormComponent } from './betty/transunion-consent-form/transunion-consent-form.component';
import { ZeroBalanceComponent } from './loan-servicing/reports/sanity-checks/zero-balance/zero-balance.component';
import { LoginInquiryModalComponent } from './login/login-inquiry-modal/login-inquiry-modal.component';
import { TurnkeyDataComponent } from './loan-meta/turnkey-data/turnkey-data.component';
import { ExternalDashboardComponent } from './Dashboard/home-dashboard/external-dashboard/external-dashboard.component';
import { BankingSummaryComponent } from './summary/banking-summary/banking-summary.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OldLoanCalculatorComponent } from './summary/old-loan-calculator/old-loan-calculator.component';
import { LoanCalculatorV1Component } from './summary/loan-calculator-v1/loan-calculator-v1.component';

@NgModule({
	declarations: [
		PdfViewerComponent,
		PhoneMaskDirective,
		AppComponent,
		TransunionComponent,
		InveriteComponent,
		AccountSelectComponent,
		LoancalculatorComponent,
		ReadComponent,
		CreateComponent,
		PreApprovalComponent,
		SummaryComponent,
		SalesdashboardComponent,
		DealsPipelineComponent,
		UnderwritingdashboardComponent,
		NlsdashboardComponent,
		NlsagencyfundedComponent,
		NlsagencycollectorsComponent,
		UnderwritingexceptionComponent,
		LoginComponent,
		HomeComponent,
		UnderwritingagencyexceptionComponent,
		UnderwritingComponent,
		NlscollectionsComponent,
		NlscreditorcollectionsComponent,
		NlslocationcollectionsComponent,
		NlsloansizecollectionsComponent,
		NlscreditorcollectionscollectorComponent,
		NlsagencycollectionsComponent,
		NlsagencycollectionscollectorComponent,
		NlsagencycollectionscollectorclientsComponent,
		NlsportfoliocollectionsComponent,
		NlsportfoliocollectionsbreakdownComponent,
		TopNavComponent,
		ViewUnderwritingComponent,
		InterviewerqueueComponent,
		ValidatorqueueComponent,
		UnderwritingTimerstartComponent,
		LoanInterviewComponent,
		MonthlyincomecalcComponent,
		UnderwritingExceptionComponent,
		GeneralinformationComponent,
		ApplicationComponent,
		LoanMetaComponent,
		ButtonRendererComponent,
		PromisePayComponent,
		AmoScheduleComponent,
		CommentFormComponent,
		SummaryBarComponent,
		ChecklistComponent,
		PandadocsComponent,
		NlsexceptioncollectionsComponent,
		AgreementsComponent,
		PtpReportComponent,
		UwPerformanceComponent,
		CustomModalComponent,
		ReportsComponent,
		ReportsDashboardComponent,
		RollOversComponent,
		CurrencyShortFormPipe,
		SafePipe,
		PtpCreatedReportComponent,
		IntermediaCallReportsComponent,
		CollectionListComponent,
		CustomTableComponent,
		CustomTooltipComponent,
		IframeViewComponent,
		PromiseToPaysComponent,
		EditPtpModalComponent,
		HierarchyDataComponent,
		CustomStatsToolPanelComponent,
		ButtonCellRendererComponent,
		PaymentReportLoanServicingComponent,
		FundedTvReportComponent,
		CollectionTvReportComponent,
		CurrentAccountInformationComponent,
		SpvCustomAccountInformationComponent,
		AccountInformationComponent,
		PaymentBreakdownBoardComponent,
		MftLpp0876Component,
		MftLpp4476Component,
		PaymentBreakdownComponent,
		SanityChecksComponent,
		AchChecksComponent,
		AchValidationComponent,
		DailyReconComponent,
		BalloonPaymentsComponent,
		ExpiredAchComponent,
		NsfCollectionListComponent,
		CustomButtonComponent,
		NsfCollectionListComponent,
		HomeDashboardComponent,
		CollectionDasboardMetricsComponent,
		CustomBettyReportFiltersComponent,
		LppReportsComponent,
		LppClaimsComponent,
		LppCancellationComponent,
		InsuranceRemitComponent,
		PtpCollectionListComponent,
		CollectionsComponent,
		PtpDateReportComponent,
		PtpDateBasedComponent,
		ArrangementDateBasedComponent,
		MyInnerRendererComponent,
		CustomExportExcelComponent,
		AdminSettingsComponent,
		AssignCollectorsComponent,
		BettyComponent,
		LoanValidationComponent,
		InsuranceTransCancellationComponent,
		NsfReportsDateComponent,
		RapidProgramComponent,
		TransunionConsentFormComponent,
		ZeroBalanceComponent,
		LoginInquiryModalComponent,
		ExternalDashboardComponent,
		BankingSummaryComponent,
		TurnkeyDataComponent,
  OldLoanCalculatorComponent,
  LoanCalculatorV1Component,
	],
	imports: [
		NgxExtendedPdfViewerModule,
		NgMultiSelectDropDownModule,
		NgSelectModule,
		AgGridModule,
		NgbAlertModule,
		NgbPaginationModule,
		NgbAccordionModule,
		MatToolbarModule,
		MatChipsModule,
		MatListModule,
		MatTabsModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		MatDialogModule,
		CommonModule,
		BrowserAnimationsModule,
		MatInputModule,
		MatNativeDateModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		MatCardModule,
		MatFormFieldModule,
		MatSelectModule,
		MatIconModule,
		MatDatepickerModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatSortModule,
		NgChartsModule,
		RouterModule.forRoot(routes),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBMa4AiGQM-ndsUHPm7oHa_Mlml3tdJyoA',
			libraries: ['places'],
		}),
		StoreModule.forRoot(reducers, { metaReducers }),
		NgxPaginationModule,
		PdfViewerModule,
		extModules,
		NgChartsModule,
		MatSidenavModule,
		MatExpansionModule,
		MatGridListModule,
		MatTreeModule,
		NgxPrintModule,
		MatProgressBarModule,
		MatRadioModule,
		MatProgressSpinnerModule
	],
	exports: [MatFormFieldModule, MatInputModule, PhoneMaskDirective],
	providers: [DatePipe, CurrencyPipe, ApiServiceService, CustomFunctionService],
	bootstrap: [AppComponent],
})
export class AppModule { }
