import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Component({
	selector: 'app-view-underwriting',
	templateUrl: './view-underwriting.component.html',
	styleUrls: ['./view-underwriting.component.scss'],
})
export class ViewUnderwritingComponent implements OnInit {
	applicantType: string;
	applicationID: string;
	constructor(private apiService: ApiServiceService) {}

	ngOnInit(): void {}
	createUnderwriting(applicantType, applicationID) {
		//redirect to underwriting page
	}
	editUnderwriting(applicantType, applicationID) {
		//redirect to underwriting page
	}
	deleteUnderwritingEntry(applicantType, applicationID) {
		this.apiService
			.deleteUnderwritingEntry(applicantType, applicationID)
			.subscribe((res) => {
				console.log(res);
				if (res['status']) alert('Underwriting deleted ');
				else alert('Failed to delete Underwriting');
			});
	}
}
