export function splitName(full_name: any) {
    const names = full_name?.split(" ")
    var output
    if (names.length > 2) {
        output = {
            firstname: names?.[0],
            middlenames: names.slice(1, -1).join(' '),
            lastname: names?.[names.length - 1]
        };
        return output
    } else if (names.length < 2) {
        output = {
            firstname: names?.[0],
            middlenames: '',
            lastname: ''
        };
        return output

    } else {
        output = {
            firstname: names?.[0],
            middlenames: '',
            lastname: names?.[names.length - 1]
        };
        return output
    }
}