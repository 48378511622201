import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SummaryState } from './summary.state';

export const summaryDetails = createFeatureSelector<SummaryState>('summary');

export const getSummaryDetails = createSelector(
	summaryDetails,
	(state: any) => {
		// console.log('Heree', state);
		console.log('Heree Summary data!');
		return state?.summary;
	}
);
