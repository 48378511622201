<div class="container-fluid">
	<mat-tab-group
		mat-stretch-tabs="false"
		mat-align-tabs="start"
		dynamicHeight
		#documentsTab
	>
		<mat-tab label="Workdrive">
			<div style="height: 100%" *ngIf="iframeUrl; else loadingWorkdriveFail">
				<iframe
					class="responsive-iframe"
					id="fDrive"
					[src]="iframeUrl"
				></iframe>
			</div>
			<ng-template #loadingWorkdriveFail>
				<div
					style="
						font-size: 20px;
						font-weight: bold;
						margin-top: 20px;
						text-align: center;
					"
				>
					No files on Workdrive
				</div>
			</ng-template>
		</mat-tab>
		<mat-tab label="Pandadoc">
			<span class="spinner-border" id="loading" *ngIf="spinner"></span>
			<table class="p_table" *ngIf="!pandadocMsg">
				<tr class="p_tr">
					<th class="p_th">Document Name</th>
					<th class="p_th">Status</th>
					<th class="p_th">Created</th>
					<th class="p_th">Modified</th>
				</tr>
				<tr *ngFor="let doc of pandadoc_documents" class="p_tr">
					<td class="p_td">
						<a
							target="_blank"
							href="https://app.pandadoc.com/a/#/documents/{{ doc.id }}"
							>{{ doc.name }}</a
						>
					</td>
					<td class="p_td">{{ doc.status }}</td>
					<td class="p_td">{{ doc.date_created | date : 'dd-MM-yyyy' }}</td>
					<td class="p_td">{{ doc.date_modified | date : 'dd-MM-yyyy' }}</td>
				</tr>
			</table>
			<div
				*ngIf="pandadocMsg"
				style="
					font-size: 20px;
					font-weight: bold;
					margin-top: 20px;
					text-align: center;
				"
			>
				No files on Pandadoc
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
