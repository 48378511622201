import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core'; // Auto Addressing
import { NgZone } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CustomFunctionService {
	name: any;
	count: any;
	amount: number;
	groupName: any;
	badDebt: any;
	fundedAmount: any;
	countDeliquency: number;
	countFunded: number;
	delinquencyRate: number;
	defaultAmount: any;
	countDefault: number;
	defaultRate: number;

	age: any;

	constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

	/**
	 *
	 * @param amount Field Name
	 * @returns Display the formatted currency in CAD
	 */
	displayFormattedCurrency(amount: any) {
		if (isNaN(amount)) return null;
		else {
			return parseFloat(amount).toLocaleString('en-CA', {
				style: 'currency',
				currency: 'CAD',
			});
		}
	}
	/**yyyy-mm-dd
	 */
	formatDate(date) {
		var d = date.getDate();
		var m = date.getMonth() + 1;
		var y = date.getFullYear();
		var dateString =
			y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
		return dateString;
	}
	/**
	 *
	 * @param arr Array list
	 * @param fieldName fieldName that needs to be summed
	 * @returns Total of amount or numbers
	 */
	sumNumbers(arr: any, fieldName: any) {
		return arr
			.map((item) => item[fieldName])
			.reduce((prev, curr) => prev + curr, 0);
	}

	/**
	 *
	 * @param arr Array list
	 * @param fieldName API or field name
	 * @param fieldValue Field value
	 * @returns Filtered array according to the fieldName and fieldValue
	 */
	singleFilterName(arr: any, fieldName: any, fieldValue: any) {
		return arr.filter((item) => item[fieldName] === fieldValue);
	}

	/**
	 *
	 * @param arr Array list
	 * @param fieldName API or field name
	 * @param fieldValue Field value
	 * @returns Filtered array according to the fieldName and fieldValue
	 */
	filterNotListedName(arr: any, fieldName: any, fieldValue: any) {
		return arr.filter((item) => item[fieldName] !== fieldValue);
	}

	/**
	 *
	 * @param arr Array list
	 * @param fieldName API or field name to be compared with
	 * @param firstValue First value comparator >= (Small)
	 * @param secondValue Second value comparator <= (Big)
	 * @returns Filtered array according to the given value comparators
	 */
	bucketFilter(arr: any, fieldName: any, firstValue: any, secondValue: any) {
		return arr.filter(
			(item) => item[fieldName] >= firstValue && item[fieldName] <= secondValue
		);
	}

	/**
	 *
	 * @param arr Array list
	 * @param fieldName API or field name to be compared with
	 * @param firstValue First value comparator
	 * @param secondValue Second value comparator
	 * @returns Filtered array consisting both the firstValue and secondValue
	 */
	filterTwoCompares(
		arr: any,
		fieldName: any,
		firstValue: any,
		secondValue: any
	) {
		return arr.filter(
			(item) =>
				item[fieldName] === firstValue || item[fieldName] === secondValue
		);
	}

	/**
	 *
	 * @param arr Array response
	 * @param fieldName API name for the filter
	 * @returns List of unique elements based on the filter API name
	 */
	filterDuplicates(arr: any, fieldName: any) {
		return arr
			.map((item) => item[fieldName])
			.filter((value, index, self) => self.indexOf(value) === index);
	}

	/**
	 *
	 * @param arr Array response or array list
	 * @param fieldName Field name for the sorting
	 * @param number1 The number that defines the sorting: 1 for ASC, -1 DESC
	 * @param number2 Enter the opposite from number1 i.e. if number1 is 1 then number2 is -1
	 * @returns Sorted array list based on the number provided
	 */
	sortColumn(arr: any, fieldName: any, number1: any, number2: any) {
		return arr.sort((x, y) =>
			x[fieldName] > y[fieldName]
				? number1
				: y[fieldName] > x[fieldName]
				? number2
				: 0
		);
	}

	/**
	 *
	 * @param name
	 * @param count
	 * @param amount
	 */
	groupByName(name: any, count: any, amount: number) {
		this.name = name;
		this.count = count;
		this.amount = amount;
	}

	/**
	 *
	 * @param groupName
	 * @param badDebt
	 * @param fundedAmount
	 * @param countDeliquency
	 * @param countFunded
	 * @param countDefault
	 * @param defaultAmount
	 */
	groupCollections(
		groupName: any,
		badDebt: any,
		fundedAmount: any,
		countDeliquency: number,
		countFunded: number,
		delinquencyRate: number,
		defaultAmount: any,
		countDefault: number,
		defaultRate: number
	) {
		this.groupName = groupName;
		this.badDebt = badDebt;
		this.fundedAmount = fundedAmount;
		this.countDeliquency = countDeliquency;
		this.countFunded = countFunded;
		this.delinquencyRate = delinquencyRate;
		this.countDefault = countDefault;
		this.defaultAmount = defaultAmount;
		this.defaultRate = defaultRate;
	}

	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	/**
	 *
	 * @param birthDate : Pass the date of birth
	 * @returns : Returns the age as of current year
	 */
	getAge(birthDate) {
		let birth_date = new Date(birthDate.replace(/-/g, '/'));
		let birth_year = birth_date.getFullYear();
		let current_date = new Date();
		let current_year = current_date.getFullYear();
		this.age = current_year - birth_year;
		return this.age;
	}

	loadAutoAddressing(searchElement) {
		this.mapsAPILoader.load().then(() => {
			let autocomplete = new google.maps.places.Autocomplete(
				searchElement.nativeElement,
				{
					types: ['address'],
					componentRestrictions: { country: 'ca' },
				}
			);
		});
	}

	setControlValue(formName: any, control: string, value: string) {
		formName.patchValue({
			[control]: value,
		});
	}
}
