import { AfterViewInit, Component } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-lpp-cancellation',
  templateUrl: './lpp-cancellation.component.html',
  styleUrls: ['./lpp-cancellation.component.scss']
})
export class LppCancellationComponent implements AfterViewInit {

  getRowStyle: any
  defaultColDef: any;
  public columnDefs = [];
  public rowData;
  public gridApi;
  public gridColumnApi;
  public gridOptions;
  lpp_cancellation: any;
  public defaultExcelExportParams: any;
  public defaultCsvExportParams: any;

  public rowDataFilteredLPP;
  filtered_lpp_cancellation: any;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    this.columnDefs = [
      { headerName: 'Loan number', field: 'Loan number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Group Policy', field: 'Group Policy', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor First Name', field: 'Debtor First Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Last Name', field: 'Debtor Last Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Address', field: 'Debtor Address', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor City', field: 'Debtor City', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor Province', field: 'Debtor Province', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Postal Code', field: 'Debtor Postal Code', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Phone Number', field: 'Debtor Phone Number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor Dob', field: 'Debtor Dob', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Co-Debtor First Name', field: 'Co-Debtor First Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor Last Name', field: 'Co-Debtor Last Name', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor Address', field: 'Co-Debtor Address', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor City', field: 'Co-Debtor City', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor Province', field: 'Co-Debtor Province', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor Postal Code', field: 'Co-Debtor Postal Code', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor Phone Number', field: 'Co-Debtor Phone Number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor DOB', field: 'Co-Debtor DOB', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Effective Date', field: 'Effective Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Cancel Reason', field: 'Cancel Reason', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Cancel Date', field: 'Cancel Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Status Code', field: 'State Code', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Days Past Due', field: 'days_past_due', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Loan Group', field: 'Loan Group', sortable: true, filter: "agTextColumnFilter" }

    ]
    this.defaultColDef = {
      resizable: true
    }
    this.getRowStyle = (params) => {
      return { "font-size": '15px' }
    }
    this.defaultExcelExportParams = { fileName: 'lpp_cancellation_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'lpp_cancellation_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }
  ngAfterViewInit(): void {
    this.apiService.getLPPCancellationReport(0).subscribe((res) => {
      this.lpp_cancellation = res
      this.gridApi.setRowData(this.lpp_cancellation);
      this.rowData = this.lpp_cancellation
      console.log(this.lpp_cancellation)
    })
    this.apiService.getFilteredLPPCancellationReport().subscribe((res) => {
      this.filtered_lpp_cancellation = res
      this.gridApi.setRowData(this.filtered_lpp_cancellation);
      this.rowDataFilteredLPP = this.filtered_lpp_cancellation
      console.log(this.filtered_lpp_cancellation)
    })
  }
  onGridReady_Hist(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.apiService.getLPPCancellationReport(0).subscribe((res) => {
      this.lpp_cancellation = res
      this.gridApi.setRowData(this.lpp_cancellation);
      this.rowData = this.lpp_cancellation
      console.log(this.lpp_cancellation)
    })
  }

  onGridReady_FilteredLPP(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.apiService.getFilteredLPPCancellationReport().subscribe((res) => {
      this.filtered_lpp_cancellation = res
      this.gridApi.setRowData(this.filtered_lpp_cancellation);
      this.rowDataFilteredLPP = this.filtered_lpp_cancellation
      console.log(this.filtered_lpp_cancellation)
    })
  }

}
