import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICustomModalOptions } from './custom-modal-options';

@Injectable({
	providedIn: 'root',
})
export class CustomModalService {
	public modalActive = new BehaviorSubject<any>(undefined);
	public modelClosed = new BehaviorSubject<any>(undefined);
	public modelClosedObservable = this.modelClosed.asObservable();
	constructor() {}

	openModal(modalOptions?: ICustomModalOptions) {
		this.modalActive.next({
			...modalOptions,
		});
	}
	closeModal(closedOptions) {
		this.modelClosed.next(closedOptions);
	}
}
