import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import 'jspdf-autotable';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-account-select',
	templateUrl: './account-select.component.html',
	styleUrls: ['./account-select.component.scss'],
})
export class AccountSelectComponent implements OnInit {
	zoho_application: any;
	application_id: any;
	bank_id: any;
	loan_number: any;
	application_type: any;
	application_type_label = '';
	guid: any;
	banks: any;
	dropdownList = [];
	selectedItems = new Array(1);
	selectedAccount = new Array(1);
	bankStatement: any;
	accounts = [];
	account_labels = [];
	selected = new UntypedFormControl(0);
	intervalId;
	inveriteStatus = 'Checking...';
	model: any;
	inverite_entry = {};
	show_input = false;
	Number_of_NSF_Tag = false;

	constructor(
		public dialogRef: MatDialogRef<AccountSelectComponent>,
		private apiService: ApiServiceService
	) {}

	ngOnInit(): void {
		this.bankStatement = this.apiService.getMessage();
		this.application_id = this.bankStatement?.['application_id'];
		this.application_type = this.bankStatement?.['application_type'];
		this.bank_id = this.bankStatement?.['bank_id'];
		//this.model = new inveriteRequest(this.bankStatement["Email1"], this.bankStatement["Phone"], this.bankStatement["Deal_Name"], this.bankStatement["Deal_Name"], 'TestBank', this.bankStatement["Province1"], '202', '117');
		console.log('HERE IS THE DATA FROM Inverite Component');
		console.log(this.bankStatement);
		this.addTab(false);
	}
	addTab(selectAfterAdding: boolean) {
		for (var i = 0; i < this.bankStatement?.['accounts']?.length; i++) {
			this.accounts.push(this.bankStatement?.['accounts']?.[i]);
			try {
				this.account_labels.push(
					this.bankStatement?.['accounts']?.[i]?.['bank'].concat(
						' (' + this.bankStatement?.['accounts']?.[i]?.['account'] + ')'
					)
				);
			} catch (err) {
				this.account_labels.push(
					this.bankStatement?.['accounts']?.[i]?.['bank']
				);
			}
		}

		if (selectAfterAdding) {
			this.selected.setValue(this.accounts[0]);
		}
	}
	hasProperties(something) {
		return Object.entries(something).length > 0;
	}
	updateZohoApplication_Inverite(acc) {
		console.log('This is the bank Account that was selected');
		console.log(acc);
		this.apiService
			.updateZohoApplication_Inverite(
				acc,
				this.application_id,
				this.application_type
			)
			.subscribe((res) => {
				try {
					console.log(res);
					let result = JSON.parse(res['body']);
					alert(result['data'][0]['code']);
					this.apiService
						.updateAcc_Check(this.application_id, this.application_type)
						.subscribe((res) => {
							console.log(res);
							console.log(res['message']);
						});
					this.apiService.sendClickEvent();
					this.dialogRef.close();
				} catch (err) {
					alert('Unable to Update ZOHO application');
					this.apiService.sendClickEvent();
					this.dialogRef.close();
				}
			});
	}
	doNotUploadZoho() {
		this.apiService.sendClickEvent();
		this.dialogRef.close();
	}
}
