import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { CellClickedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-external-dashboard',
  templateUrl: './external-dashboard.component.html',
  styleUrls: ['./external-dashboard.component.scss']
})
export class ExternalDashboardComponent implements OnInit, AfterViewInit {
  @Input() agencyId: string;

  public gridApi_agency: any;
  public gridApi_agency_data: any;
  public gridColumnApi_agency: any;

  agencyData: any;

  defaultColDef_agency: any;
  public columnDefs_agency = [];
  rowClassRules = {
    'funded-row': (params) => params?.data?.Stage === 'Funded'
  };

  fundedCount: number;
  fundedAmount: any;
  showLoader: boolean;

  constructor(private apiService: ApiServiceService, private router: Router) {
    this.columnDefs_agency = [
      { headerName: 'Stage', field: 'Stage', filter: "agTextColumnFilter", minWidth: 250, maxWidth: 250 },
      {
        headerName: 'Name', field: 'Deal_Name', filter: "agTextColumnFilter", pinned: 'left', minWidth: 250, maxWidth: 250,
        cellStyle: { cursor: 'pointer', color: '#116abd' },
      },
      { headerName: 'Amount', field: 'Amount', filter: "agNumberColumnFilter", minWidth: 130, maxWidth: 130 },
      { headerName: 'Agency Summary', field: 'Agency_Summary', filter: "agTextColumnFilter", minWidth: 450 },
      { headerName: 'Collector', field: 'Collector_Formula', filter: "agTextColumnFilter", minWidth: 230, maxWidth: 230 },
      { headerName: 'Account Number (EDP)', field: 'Acc_Number', filter: "agTextColumnFilter", minWidth: 230, maxWidth: 230 },
      { headerName: 'Creditor', field: 'Creditt', filter: 'agTextColumnFilter', minWidth: 250, maxWidth: 250 },
      { headerName: 'Mobile', field: 'Mobile', filter: "agNumberColumnFilter", minWidth: 130, maxWidth: 130 },
      { headerName: 'Referral Date', field: 'Referral_Date', filter: 'agDateColumnFilter', minWidth: 150, maxWidth: 150 },
    ];
    this.defaultColDef_agency = {
      resizable: true
    };
  };

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.apiService.getExternalAgencyList(this.agencyId).subscribe(((res: any) => {
      this.agencyData = res?.message;
      if (this.agencyData === false) {
        console.log("ZOHO error!");
      } else {
        this.gridApi_agency?.setRowData(this.agencyData);
        this.gridApi_agency_data = this.agencyData;

        this.totalAmountCalculations();
      }
    }))
  }

  onGridReady(params: any): void {
    this.gridApi_agency = params.api;
    this.showLoader = true;
    this.gridColumnApi_agency = params.columnApi;
    this.gridApi_agency?.setRowData(this.agencyData);
    this.gridApi_agency_data = this.agencyData;
  }

  onCellClicked(e: CellClickedEvent) {
    const { id } = e.data;
    const url = this.router.serializeUrl(this.router.createUrlTree(['/applications/summary', id]));
    window.open(url, '_blank');
    // return this.router.navigate(['/applications/summary', id]);
  }

  async getStageMetrics(fieldName: string) {
    return new Promise((resolve, reject) => {
      const filteredList = [];
      this.gridApi_agency.forEachNodeAfterFilterAndSort((rowNode: any, index: any) => {
        if (rowNode?.data?.[fieldName] === 'Funded') {
          filteredList.push(rowNode?.data);
        }
      });
      let resultObject = {};
      resultObject = { count: filteredList?.length, total: (filteredList.length === 0) ? 0 : filteredList.map(item => item?.Amount)?.reduce((prev, next) => parseFloat(prev) + parseFloat(next))?.toFixed(2) };
      resolve(resultObject);
    })

  }

  onFirstDataRendered(): void {
    if (this.gridApi_agency) {
      this.totalAmountCalculations();
    }
  }

  async totalAmountCalculations() {
    let resultTotal = await this.getStageMetrics('Stage').then((item) => {
      this.fundedCount = item['count'];
      this.fundedAmount = item['total'];
    })
  }


}
