import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { AgreementsService } from 'app/services/agreements/agreements.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { KeyValue } from '@angular/common';
import { CustomModalService } from '@lmc-app/services/modal/custom-modal.service';
import { ModalNames } from '@lmc-app/services/modal/custom-modal-options';
import { CustomFunctionService } from '@lmc-app/custom-function.service';
import { resolve } from 'dns';
@Component({
	selector: 'app-agreements',
	templateUrl: './agreements.component.html',
	styleUrls: ['./agreements.component.scss'],
})
export class AgreementsComponent implements OnInit, AfterViewInit {
	app_info: any;
	public documentName: string;
	public applicationId: string;
	public applicationName: string;
	public documentTitle: string;
	public pdfBlob: Blob;

	public documentTitleMapper = {
		'apply-on-behalf': 'Apply on Behalf',
		consent: 'Consent Form',
	};
	public docId = '';
	public pdfSrc = '';
	public editMode = false
	public pdfName = '';
	public showLoadingSpinner = true;
	public displayInfo: any;
	public pandadocsInfo: any;
	public Tokens: any;
	public recipientInfo: any;
	public spinner = false;
	public docStatus: string;
	public docStatusInterval: any;
	public platformType;
	public signing_agent: any
	public signing_manager: any
	public loadingMessage = ''
	private xhr = new XMLHttpRequest();

	agreementName: any;

	constructor(
		private apiService: ApiServiceService,
		private agreementsService: AgreementsService,
		private sanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
		private customModalService: CustomModalService,
		private customFunctions: CustomFunctionService
	) { }

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHandler(event) {
		console.log('Unloading tab');
		clearInterval(this.docStatusInterval);
		this.deleteDraft();
	}

	ngOnInit(): void {
		this.customModalService.modelClosedObservable.subscribe((closedOptions) => {
			this.handleModalClosed(closedOptions);
			window.resizeTo(screen.width, screen.height);
		});

	}

	ngAfterViewInit() {
		this.activatedRoute.params.subscribe((params) => {
			this.documentName = params['id'];
			this.documentTitle = this.documentTitleMapper[this.documentName];
		});
		this.activatedRoute.queryParams.subscribe((queryParams) => {
			this.applicationId = queryParams['Application_ID'];
			this.applicationName = queryParams['Application_Name'];
			this.getZohoApplication();
		});
	}
	async displayZohoAgreementInformation(documentName, tokens) {
		return new Promise(async (resolve, reject) => {
			let AgreementInfo = await this.agreementsService.getAgreementID(tokens, this.signing_agent, this.signing_manager);
			this.displayInfo = AgreementInfo[0];
			this.pandadocsInfo = AgreementInfo[1];
			this.agreementName = this.pandadocsInfo['name']
			console.log(this.displayInfo, "@________________")
			this.recipientInfo = AgreementInfo[2];
			resolve(true);
		});
	}
	async getEmployeeEmail(zoho_id) {
		return new Promise((resolve, reject) => {
			this.apiService.getEmployeeEmail(zoho_id).subscribe(async (res: any) => {
				if (!res.status) {
					console.log("Error getting Employees Table")
					console.log(res)
				} else if (res.data.length == 0) {
					alert("Invalid Signing Agent/Manger has been selected")

				}
				else
					resolve(res.data[0])
			})
		})
		// await this.apiService.getEmployeeEmail(zoho_id).subscribe(async (res: any) => {
		// 	if (!res?.status) {
		// 		console.log("Error getting Employees Table")
		// 		console.log(res)
		// 	} else if (res?.data.length == 0) {
		// 		alert("Invalid Signing Agent/Manger has been selected")

		// 	}
		// 	else
		// 	console.log(res?.data?.[0],"______________________________")
		// 		return (res?.data?.[0])
		// })

	}
	getZohoApplication() {
		return new Promise((resolve) => {
			this.apiService
				.getZohoApplication(this.applicationId)
				.subscribe(async (res) => {
					if (!res) {
						alert('UNABLE TO CONNECT TO ZOHO - PLEASE TRY AGAIN IN 1 MINUTE');
						resolve(false);
					} else {
						console.log('Application details from ZOHO has been retrieved');
						console.log(res);
						this.app_info = res;
						// this.platformType = res?.['data']?.[0]?.['Type'];
						this.platformType = "Panda Docs";		// Default panda docs
						let signingAgentID = (res['data'][0]['Signing_Agent'] === null) ? null : res['data'][0]['Signing_Agent']['id'];
						if (signingAgentID === null) {
							alert('Please update the Signing Agent for Agreement on ZOHO!');
							window.close();
						} else if ((res['data'][0]['Email1']) === null) {
							alert('Please update the Client Email Address!');
							window.close();
						} else if ((res['data'][0]['Co_Appl_Required']) === 'Yes' && (res['data'][0]['Email']) === null) {
							alert('Please update the Co-applicant Email Address!');
							window.close();
						} else if ((res['data'][0]['Deal_Name']) === null) {
							alert('Application name cannot be empty!');
							window.close();
						} else if ((res['data'][0]['App_Number']) === null) {
							alert('Application number cannot be empty!');
							window.close();
						} else if (((res['data'][0]['LPP_Included']) === null || (res['data'][0]['LPP_Included']) === 'No' || (res['data'][0]['LPP_Included']) === 'Non-Qualified') && (res['data'][0]['Co_Applicant_LPP'] === 'Yes')) {
							alert('No template for Primary without LPP and Co-app with LPP!');
							window.close();
						}
						// else if ((res?.['data']?.[0]?.['Manager']?.['id']) === undefined) {
						// 	alert('Please update the Signing Manager for Agreement on ZOHO!');
						// 	window.close();
						// } 
						else {
							this.signing_agent = await this.getEmployeeEmail(signingAgentID)
							// this.signing_manager = await this.getEmployeeEmail(res?.['data']?.[0]?.['Manager']?.['id'])
							// Get Agreement Details
							if (this.platformType == 'Panda Docs') {
								await this.displayZohoAgreementInformation(
									this.documentName,
									res['data'][0]
								);
								this.Tokens = this.transponseDisplayInfo(this.displayInfo);
								await this.getDocumentPanda();
								resolve(true);
							} else {
								alert("You have not selected a valid Document Signing Platform");
								window.close();
							}
						}
					}
				});
		});
	}

	keyPreservedOrder = (
		a: KeyValue<string, Array<any>>,
		b: KeyValue<string, Array<any>>
	): number => {
		return;
	};

	/**
	 * 
	 * @param data nested array with objects
	 * @returns one nested objects
	 */
	transponseDisplayInfo(data) {
		let refined = [];
		for (const [key, value] of Object.entries(data)) {
			refined = refined.concat(value);
		}
		return refined;
	}
	getDocumentPanda() {
		return new Promise((resolve) => {
			this.apiService
				.getDocumentPanda(this.pandadocsInfo, this.recipientInfo, this.Tokens)
				.subscribe(async (res) => {
					if (!res) {
						alert('UNABLE TO FETCH DOCUMENT');
						resolve(false);
					} else {
						console.log('Response Body');
						console.log(res);
						this.docId = res['id'];
						this.docStatus = res['status'];
						if (res['status'] === 'document.draft') {
							this.downloadAgreementFromPandaDoc(res['id']);
						} else {
							let counter = 0;
							// Wait for status to change from uploaded to draft
							this.docStatusInterval = setInterval(() => {
								counter++;
								if (counter === 10) {
									// Safe side clear interval if document status does not return anything after 10th attempt,
									// i.e., after 10 seconds just in case of network failures
									clearInterval(this.docStatusInterval);
								}
								this.getDocumentStatusFromPandaDoc(res['id']).then(
									(status) => {
										console.log('Document status', res);
										this.docStatus = status['status'];
										if (status['status'] === 'document.draft') {
											clearInterval(this.docStatusInterval);
											this.downloadAgreementFromPandaDoc(res['id']);
										}
									}
								);
							}, 1000);
						}
						resolve(true);
					}
				});
		});
	}

	onClickDownload(e) {
		if (!this.pdfSrc) {
			e.preventDefault();
			return;
		}
	}

	getDocumentStatusFromPandaDoc(doc_ID: string) {
		return this.apiService.getDocumentStatus(doc_ID).toPromise();
	}

	downloadAgreementFromPandaDoc(doc_ID: string) {
		this.apiService.downloadAgreementPanda().subscribe((res) => {
			this.getAgreement(doc_ID, res);
		});
	}
	sendAttempt() {
		this.openModal(
			ModalNames.PANDADOCS_USER_CONFIRMATION,
			'Document Confirmation',
			'Are you sure you want to send this Document?',
			['OK', 'Cancel']
		);
	}
	send() {
		this.spinner = true
		this.editMode = false
		this.loadingMessage = 'Sending Document'
		this.getDocumentStatusFromPandaDoc(this.docId).then((docStatus: any) => {
			console.log('Document status while sending', docStatus);
			const { status } = docStatus;
			if (status === 'document.draft') {
				this.apiService.sendDocumentPanda(this.docId).subscribe((res: any) => {
					console.log('Send response', res);
					if (res.status != 'document.sent') {
						this.spinner = false
						this.editMode = true
						this.openModal(ModalNames.PANDADOCS_DOCUMENT_FAILED,
							'Error Sending Document',
							res.info_message,
							['OK'])
					}
					else {
						this.spinner = false;
						this.docStatus = res.status;
						this.openModal(ModalNames.PANDADOCS_DOCUMENT_SUCCESS,
							'Document Status',
							'Document has been sent successfully. PLEASE CLOSE THIS TAB',
							[]);

						// window.top.close()
					}
				});
			} else {
				this.spinner = false
				this.editMode = true
				this.openModal(ModalNames.PANDADOCS_DOCUMENT_FAILED,
					'Error Sending Document',
					'Error in sending document as status is not draft',
					['OK'])
				console.log('Error in sending document as status is not draft. Please refresh the page');
			}
		});
	}

	deleteDraft() {
		const documentStatus = this.getDocumentStatusFromPandaDoc(this.docId);
		console.log('Document status on destroy', documentStatus);
		this.deleteDocumentExit();
	}

	deleteDocumentAttempt() {
		this.openModal(
			ModalNames.PANDADOCS_DOCUMENT_DELETE_CONFIRMATION,
			'Document Deletion Confirmation',
			'Are you sure you want to delete this Document?',
			['OK', 'Cancel'],
			{ 'color': 'black', 'font-size': '20px' }
		);

	}
	deleteDocumentExit() {
		this.apiService.deleteDocumentPanda(this.docId, this.docStatus).subscribe((res: any) => {
			console.log(res)
		})
	}
	deleteDocument() {
		this.apiService.deleteDocumentPanda(this.docId, this.docStatus).subscribe((res: any) => {
			console.log('Delete response',);
			console.log(res)
			this.spinner = false
			if (!res) {
				window.top.close()
			} else {
				this.openModal(
					ModalNames.PANDADOCS_DOCUMENT_DELETE_FAIL,
					'Document Status',
					res.detail,
					['OK']
				);
			}
		});
	}
	getAgreement(doc_ID: string, res) {
		this.xhr.open(
			'GET',
			'https://api.pandadoc.com/public/v1/documents/' +
			doc_ID +
			'/download?separate_files=false',
			true
		);
		this.xhr.responseType = 'arraybuffer';
		this.xhr.setRequestHeader('content-type', 'application/json');
		this.xhr.setRequestHeader('Authorization', `Bearer ${res['token']}`);
		this.xhr.onload = function (e) {
			if (this.xhr.status == 200) {
				this.pdfSrc = window.URL.createObjectURL(
					new Blob([this.xhr.response], { type: 'application/pdf' })
				);
				console.log('XHR Response');
				console.log(this.xhr.response);
				this.pdfBlob = this.pdfSrc;
				this.pdfName = `Report_${doc_ID}.pdf`;
				this.editMode = true
			}
		}.bind(this);
		this.xhr.onerror = () => {
			// Todo: Soumith: May be show an error message. TBD.
			this.showLoadingSpinner = false;
			console.log('Could not download pdf');
		};
		this.xhr.onreadystatechange = function () {
			switch (this.xhr.readyState) {
				case 1:
				case 3:
					return (this.showLoadingSpinner = true);
				case 4:
					return (this.showLoadingSpinner = false);
				default:
					return (this.showLoadingSpinner = true);
			}
		}.bind(this);
		this.xhr.send();
	}

	getSanitizedUrl(url: string): SafeUrl {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}
	openModal(
		modalName,
		headerTitle: string = '',
		bodyText: string = '',
		buttons: Array<string> = [''],
		options = {}
	) {
		this.customModalService.openModal({
			modalName,
			activateModal: true,
			headerTitle,
			bodyText,
			buttons,
			options
		});
	}
	async handleModalClosed(closedOptions = {}) {

		console.log(closedOptions, "#################")
		const { modalName, result } = closedOptions as any;

		console.log(closedOptions, "!!!!!!!!!!!!!!!!")
		if (
			modalName === ModalNames.PANDADOCS_USER_CONFIRMATION &&
			result === 'OK'
		) {
			this.send()
		} else if (modalName === ModalNames.PANDADOCS_DOCUMENT_DELETE_CONFIRMATION && result === 'OK') {
			this.spinner = true
			this.loadingMessage = 'Deleting Document'
			this.deleteDocument()
		}
	}
	ngOnDestroy() {
		clearInterval(this.docStatusInterval);
		this.deleteDraft();
	}
}
