import { Injectable } from '@angular/core';
import { applyOnBehalf } from '../../models/agreements/apply-on-behalf.model';
import { consent } from '../../models/agreements/consent.model';
import { Agreement } from '../../models/agreements/agreement.model';
import { CustomFunctionService } from '@lmc-app/custom-function.service';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { _countGroupLabelsBeforeOption } from '@angular/material/core';
@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  constructor(private customFunctions: CustomFunctionService, private apiService: ApiServiceService) { }

  async getAgreementID(zoho_info, signing_agent, signing_manager) {
    //return the agreement ID along with the post Object needed to send Agreement
    console.log(zoho_info, "zoho_res__________________")
    const hasCoapp = (zoho_info.Co_Appl_Required == "Yes") ? true : false;
    const province = zoho_info.Province1;
    const hasLPP = (zoho_info.LPP_Included == "Yes") ? true : false;
    const isEnahnced = (zoho_info.Amount >= 7500 && hasLPP) ? true : false;
    const coApphasLPP = (zoho_info.Co_Applicant_LPP == "Yes") ? true : false;
    // const coApphasLPP = false;    // To be assigned from ZOHO field
    let insuranceType;
    if (!hasLPP)
      insuranceType = 'N/A';    // Doesn't have LPP then the regular Agreement (Primary LPP field indicator)
    else if (isEnahnced)
      insuranceType = 'Enhanced Insurance';   // Enhanced if Amount is >=7500 and has LPP (Primary LPP field indicator)
    else insuranceType = 'Standard Insurance';    // Standard and has LPP (Primary LPP field indicator)
    // let agreement = new Agreement(zoho_info, insuranceType, this.customFunctions, signing_agent, signing_manager);
    let agreement = new Agreement(zoho_info, insuranceType, this.customFunctions, signing_agent, signing_manager);

    let templateInfo = {};
    let result = await this.getTemplateData(insuranceType, hasLPP, hasCoapp, coApphasLPP, province).then((res) => templateInfo = res);  // Get Pandadocs template details
    if (hasCoapp) {
      agreement.addCoapp();   // Add CoApp Model if has Coapp
    }
    if (hasLPP) {
      agreement.addLPP();     // Add LPP Model if has LPP
    }
    return [agreement.getAgreementInfo(), templateInfo, agreement.getRecipientInfo()];
  }

  /**
   * 
   * @param insuranceType 
   * @param hasLPP 
   * @param hasCoapp 
   * @param coApphasLPP 
   * @param province 
   * @returns Specific Template information based on the params
   */
  getTemplateData(insuranceType, hasLPP, hasCoapp, coApphasLPP, province) {
    return new Promise((resolve) => {
      this.apiService.getPandadocsTemplates(insuranceType, hasLPP, hasCoapp, coApphasLPP, province).subscribe((res) => {
        console.log(res[0], "Pandadocs Template_________________________")
        const templateDetails = res[0];
        resolve({ name: templateDetails['template_name'], id: templateDetails['template_id'] });

      })

    })
  }
}
