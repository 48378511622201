import { Component, Input, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface HierarchyNode {
	name: string;
	children?: HierarchyNode[];
}

@Component({
	selector: 'app-hierarchy-data',
	templateUrl: './hierarchy-data.component.html',
	styleUrls: ['./hierarchy-data.component.scss'],
})
export class HierarchyDataComponent implements OnInit {
	treeControl = new NestedTreeControl<HierarchyNode>((node) => node.children);
	dataSource = new MatTreeNestedDataSource<HierarchyNode>();
	@Input() set hierarchyData(value) {
		this.dataSource.data = value;
	}
	constructor() {}
	ngOnInit() {
		// this.dataSource.data = this.hierarchyData;
	}

	hasChild = (_: number, node: HierarchyNode) =>
		!!node.children && node.children.length > 0;
}
