<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img
				title="refresh"
				src="/assets/images/refresh.png"
				width="30"
				height="30"
				(click)="refresh()"
			/>
		</h5>
	</div>

	<div class="col-sm-12">
		<h5>Today's stats</h5>
	</div>
	<div class="cardContainer">
		<div class="cardContainer-scroll">
			<div class="card" id="newAppsCard">
				<div class="container">
					<h4 class="newAppsFont"><strong>New Apps.</strong></h4>
					<h2>
						<strong>{{ totalApplications }}</strong>
					</h2>
					<h3 class="newAppsFont">
						<strong>{{ totalAmount | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>Incomplete Apps.</strong></h4>
					<h2>
						<strong>{{ totalIncomplete }}</strong>
					</h2>
					<h3>
						<strong>{{ totalIncompleteAmount | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>Needs Docs.</strong></h4>
					<h2>
						<strong>{{ totalNeedsDocs }}</strong>
					</h2>
					<h3>
						<strong>{{ totalNeedsDocsAmount | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>Pending EC</strong></h4>
					<h2>
						<strong>{{ totalPendingEC }}</strong>
					</h2>
					<h3>
						<strong>{{ totalPendingECAmount | currency }}</strong>
					</h3>
				</div>
			</div>
			<div class="card">
				<div class="container">
					<h4><strong>Pending VOE</strong></h4>
					<h2>
						<strong>{{ totalPendingVOE }}</strong>
					</h2>
					<h3>
						<strong>{{ totalPendingVOEAmount | currency }}</strong>
					</h3>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="table-Row"> -->
	<div class="row col-12">
		<div class="col-sm-6">
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Stage</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of groupedStage">
						<td>{{ item.name }}</td>
						<td>{{ item.count }}</td>
						<td>{{ item.amount | currency }}</td>
					</tr>
					<tr>
						<td class="subTableBold">TOTAL</td>
						<td class="subTableBold">{{ totalApplications }}</td>
						<td class="subTableBold">{{ totalAmount | currency }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="col-sm-6">
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Agency</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of groupedAgency">
						<td *ngIf="item.name == null">-</td>
						<td *ngIf="item.name != null">{{ item.name }}</td>
						<td>{{ item.count }}</td>
						<td>{{ item.amount | currency : '' }}</td>
					</tr>
					<tr>
						<td class="subTableBold">TOTAL</td>
						<td class="subTableBold">{{ totalApplications }}</td>
						<td class="subTableBold">{{ totalAmount | currency }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<!-- </div>  -->
</div>
