<div class="modelPadding">
	<div class="row row-cols-12">
		<div [formGroup]="incomeForm">
			<div class="col-2">
				<label class="form-label">Payment Type</label>
				<select
					class="form-select"
					(change)="payType($event)"
					formControlName="payType"
				>
					<option value="Equal">Equal</option>
					<option value="Variable">Variable</option>
				</select>
			</div>
			<div class="col-2" id="displayMonths">
				<label class="form-label">Number of Months</label>
				<select
					class="form-select"
					formControlName="months"
					(change)="generateForm()"
				>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
					<option value="9">9</option>
					<option value="10">10</option>
					<option value="11">11</option>
					<option value="12">12</option>
				</select>
			</div>
			<div class="col-2">
				<label class="form-label">Income</label>
				<div class="form-group" formArrayName="SALARIES">
					<table>
						<tbody>
							<tr
								*ngFor="
									let control of incomeForm.controls.SALARIES['controls'];
									let i = index
								"
								[formGroupName]="i"
							>
								<td>
									<input
										type="number"
										class="form-control"
										formControlName="amount"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<label class="form-label">Average Income</label>
				<input
					type="number"
					class="form-control"
					formControlName="averageSalary"
					readonly
				/>
				<small
					*ngIf="incomeForm.controls['averageSalary'].hasError('required')"
					class="invalid-feedback"
					>Amount is required</small
				>
			</div>

			<button mat-raised-button (click)="calculate()">Calculate</button>
			<button
				mat-raised-button
				(click)="okSend()"
				[disabled]="incomeForm.invalid"
			>
				Save
			</button>
			<button mat-button (click)="onNoClick()">Close</button>
		</div>
	</div>
</div>
