<div class="container-fluid">
	<div class="row row-cols-12">
		<!-- <h5>{{timestamp | date:'medium'}}<img title="refresh" src="/assets/images/refresh.png" width="30" height="30" (click)="refresh()"></h5>  -->
	</div>

	<div class="col-sm-12">
		<h5>Location based Collections</h5>
	</div>
	<!-- <mat-checkbox class="switch" (change)="checkCheckBoxvalue($event)" #ch></mat-checkbox> -->

	<div class="col-sm-12">
		<input
			type="checkbox"
			class="switch"
			(change)="showDatePicker()"
			formControlName="toggleFilter"
			unchecked
		/>
	</div>
	<div id="dateRange">
		<mat-form-field class="example-form-field" appearance="fill">
			<mat-label>Select Date Range</mat-label>
			<mat-date-range-input
				[formGroup]="filterDate"
				[rangePicker]="filterDatePicker"
			>
				<input matStartDate placeholder="Start date" formControlName="start" />
				<input matEndDate placeholder="End date" formControlName="end" />
			</mat-date-range-input>
			<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
			<mat-datepicker-toggle
				matSuffix
				[for]="filterDatePicker"
			></mat-datepicker-toggle>
			<mat-date-range-picker #filterDatePicker></mat-date-range-picker>
		</mat-form-field>
	</div>

	<div class="col-sm-12">
		<table
			class="table table-bordered"
			matSort
			(matSortChange)="sortData($event)"
		>
			<thead>
				<tr>
					<th mat-sort-header="groupName">Province</th>
					<th mat-sort-header="badDebt">Bad Debt</th>
					<th mat-sort-header="fundedAmount">Funded Amount</th>
					<th mat-sort-header="countDeliquency">Bad Debt Count</th>
					<th mat-sort-header="countFunded">Funded Amount Count</th>
					<th mat-sort-header="delinquencyRate">Delinquency Rate</th>
					<th mat-sort-header="defaultAmount">Default Amount</th>
					<th mat-sort-header="countDefault">Default Count</th>
					<th mat-sort-header="defaultRate">Default Rate</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of sortedData">
					<td *ngIf="item.countDeliquency != '0'">{{ item.groupName }}</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.badDebt | currency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.fundedAmount | currency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.countDeliquency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">{{ item.countFunded }}</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.delinquencyRate }}%
					</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.defaultAmount | currency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">{{ item.countDefault }}</td>
					<td *ngIf="item.countDeliquency != '0'">{{ item.defaultRate }}%</td>
				</tr>
				<tr>
					<td class="subTableBold">TOTAL</td>
					<td class="subTableBold">{{ amountGroupBadDebt | currency }}</td>
					<td class="subTableBold">{{ amountGroupFunded | currency }}</td>
					<td class="subTableBold">{{ countGroupBadDebt }}</td>
					<td class="subTableBold">{{ countGroupFunded }}</td>
					<td class="subTableBold">
						{{ ((amountGroupBadDebt / amountGroupFunded) * 100).toFixed() }}%
					</td>
					<td class="subTableBold">{{ amountGroupDefault | currency }}</td>
					<td class="subTableBold">{{ countGroupDefault }}</td>
					<td class="subTableBold">
						{{ ((amountGroupDefault / amountGroupFunded) * 100).toFixed() }}%
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
