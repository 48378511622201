import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginInquiryModalComponent } from './login-inquiry-modal/login-inquiry-modal.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	returnUrl: string;
	signinForm: UntypedFormGroup;

	appVersion = environment.version;

	constructor(
		private router: Router,
		private apiservice: ApiServiceService,
		private route: ActivatedRoute,
		public dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.generateUniqueID();
		//console.log(this.sessionID)
		this.returnUrl = this.route.snapshot?.queryParams['returnUrl'] || '/';

		this.signinForm = new UntypedFormGroup({
			username: new UntypedFormControl('', Validators.required),
			password: new UntypedFormControl('', Validators.required),
			rememberMe: new UntypedFormControl(false),
		});
	}

	Signin(event) {
		event.preventDefault();
		const target = event.target;
		let username = target.querySelector('#username').value;
		let password = target.querySelector('#password').value;
		// this.store.dispatch(login({username, password, ...{test: Math.random().toString()}}));
		let info = {
			user: username,
			pass: password,
			sessionid: this.sessionID,
		};
		this.apiservice.verify_signin(info).subscribe((res: any) => {
			let final = res;
			if (final.message != 'Success') {
				console.log(final)
				alert(final.message);
			} else {
				this.apiservice.sendSignInInfo(final.data);

				this.apiservice.updatesession(info).subscribe((res: any) => {
					if (res.message == 'Success') {
						//Update the table with sessionID
						localStorage.setItem('SESSIONID', this.sessionID);
						if (this.returnUrl == '/') {
							this.router.navigate(['dashboard']); //
						} else {
							this.router.navigateByUrl(this.returnUrl); // open the url that the session got expired
						}
						this.apiservice.setLoggedIn(true);
					}
				});
			}
		});
	}

	// handleCantLogin = (username) => {
	// 	let clientInquiryObject = {
	// 		emailAddress: "testing123@gmail.com",
	// 		source: "I am not sure how I got to this website"
	// 	};
	// 	this.apiservice.sendLoginFailureEmail(username, clientInquiryObject).subscribe(() => {
	// 		console.log('Login failure email sent');
	// 	});
	// };

	sessionID = '';

	generateUniqueID() {
		let chars =
			'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
		let uniqueLength = 41;

		for (let i = 0; i <= uniqueLength; i++) {
			let randNumber = Math.floor(Math.random() * chars.length);
			this.sessionID += chars.substring(randNumber, randNumber + 1);
		}
	}

	openMoreDetails() {
		const username = this.signinForm.get('username').value;
		const dialogRef = this.dialog.open(LoginInquiryModalComponent, {
			autoFocus: true,
			data: username,
		});
	}
}
