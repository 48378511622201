<div class="flex-container">
    <div class="row justify-content-evenly">
        <div class="col-4">
            <h5>Double Payments</h5>
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Loan Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of double_payment_res">
                        <td>{{ item.loan_number }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-4">
            <h5>Loan Group ACH Company not matching ACH</h5>
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Loan Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of automated_payments_ne">
                        <td>{{ item.loan_number }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>