import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { CustomFunctionService } from '../../custom-function.service';
import { ActivatedRoute } from '@angular/router';
import { Controls } from '@lmc-models/controls';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-underwriting-exception',
	templateUrl: './underwriting-exception.component.html',
	styleUrls: ['./underwriting-exception.component.scss'],
})
export class UnderwritingExceptionComponent implements OnInit, OnDestroy {
	// @Input() zohoApplicationID: any;
	zohoApplicationID: any;
	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute
	) {}

	// EXCEPTIONS

	override_look_up: string[] = [];
	override_type_look_up: string[] = [];

	loggedZohoID: any;
	loggedName: any;

	stageLookUp: any;
	Deal_Name: any;

	subjects = [{ subject: 'Exception' }, { subject: 'Manager Note' }];
	loanInterviewNote: any;

	controls = new Controls();

	exceptionForm = new UntypedFormGroup({
		override: new UntypedFormControl(''),
		override_type: new UntypedFormControl(''),
		Loyalty_Payment_Plan: new UntypedFormControl(''),
		Exception_Funded_Amount: new UntypedFormControl(0),
		Exception_Increase: new UntypedFormControl(0),
		Household_Income: new UntypedFormControl(''),
		Loan_Amount: new UntypedFormControl(0),
	});

	stageForm = new UntypedFormGroup({
		Stage: new UntypedFormControl(''),
	});

	exceptionNote = new UntypedFormGroup({
		subject: new UntypedFormControl('Exception', Validators.required),
		note: new UntypedFormControl('', Validators.required),
	});

	ngOnInit(): void {
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			this.loggedZohoID = userData['zoho_owner_id'];
			this.loggedName = userData['full_name'];
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				(summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.getExceptions();
						this.getCurrentStage();
						this.exceptionNote.get('subject').setValue('Exception');
					}
				}
			);
	}

	// Exceptions

	// getLookups() {
	//   this.apiService.getLookupsunderwriting().subscribe((res) => {
	//     let arr = JSON.parse(res.message.body)
	//     const override_result = this.customFunction.singleFilterName(arr.fields, 'api_name', 'Override');
	//     this.override_look_up = override_result[0]['pick_list_values'];

	//     const override_type_result = this.customFunction.singleFilterName(arr.fields, 'api_name', 'Override_Type');
	//     this.override_type_look_up = override_type_result[0]['pick_list_values'];
	//   })
	// }

	saveException() {
		this.controls.displayMessage(
			'exceptionButton',
			true,
			'successfulException',
			'Save in progress...',
			'orange',
			'block'
		);

		this.apiService
			.addExceptionslocal(
				this.exceptionForm.value,
				this.zohoApplicationID,
				this.loggedZohoID,
				this.loggedName
			)
			.subscribe((res) => {
				console.log(res);
				if (res['message'] == 'Success') {
					// Push ZOHO Underwriting Exception
					// this.apiService.zohoUnderwritingException(this.exceptionForm.value, this.underwritingID).subscribe((res) => {
					//   if (res.message.statusCode == '200') {
					// Push ZOHO
					this.apiService
						.zohoAppException(this.exceptionForm.value, this.zohoApplicationID)
						.subscribe((res) => {
							if (res.message.statusCode == '200') {
								this.controls.displayMessage(
									'exceptionButton',
									false,
									'successfulException',
									'Save Successful!',
									'green',
									'block'
								);
							} else {
								console.log('ZOHO Application exception push Failed!');
								this.controls.displayMessage(
									'exceptionButton',
									false,
									'successfulException',
									'Save unsuccessful!',
									'red',
									'block'
								);
							}
						});
					//   } else {
					//     console.log("Underwriting Exception Update Failed!");
					//     this.controls.displayMessage('exceptionButton', false, 'successfulException', 'Save unsuccessful!', 'red', 'block');
					//   }
					// })
				} else {
					console.log('Underwriting Local Update Failed!');
					this.controls.displayMessage(
						'exceptionButton',
						false,
						'successfulException',
						'Save unsuccessful!',
						'red',
						'block'
					);
				}
			});
	}

	getExceptions() {
		// this.apiService.getExceptionslocal(this.zohoApplicationID).subscribe((res) => {
		this.getOverridePicklist();
		this.apiService
			.getSingleAppSummary(this.zohoApplicationID)
			.subscribe((res) => {
				if (res.message.statusCode == '200') {
					let arr = JSON.parse(res.message.body);
					if (arr.data.length != 0) {
						this.exceptionForm.patchValue({
							override: arr.data[0]['Underwriting_Override'],
							override_type: arr.data[0]['Override_Type'],
							Loyalty_Payment_Plan: arr.data[0]['Loyalty_Payment_Plan'],
							Exception_Funded_Amount: arr.data[0]['Exception_Funded_Amount'],
							Exception_Increase: (
								arr.data[0]['Approved_Amount'] -
								arr.data[0]['Exception_Funded_Amount']
							).toFixed(2),
							// Exception_Increase: arr.data[0]['Exception_Increase'],
							Household_Income: arr.data[0]['Household_Income'],
							Loan_Amount: arr.data[0]['Approved_Amount'],
						});
					}
				}
			});
		// this.apiService.getUnderwritingDetails(this.underwritingID).subscribe((res) => {
		//   if (res.message.statusCode == '200') {
		//     let arr = JSON.parse(res.message.body)
		//     if (arr.data.length != 0) {
		//       this.exceptionForm.patchValue({
		//         override: arr.data[0]['Override'],
		//         override_type: arr.data[0]['Override_Type'],
		//         Loyalty_Payment_Plan: arr.data[0]['Loyalty_Payment_Plan'],
		//         Exception_Funded_Amount: arr.data[0]['Exception_Funded_Amount'],
		//         Exception_Increase: arr.data[0]['Exception_Increase'],
		//         Household_Income: arr.data[0]['Household_Income'],
		//         Loan_Amount: arr.data[0]['Loan_Amount']
		//       })
		//     }
		//   }
		// })
	}

	// Calculate Funded
	autoCalculateFunded() {
		let result = (
			this.exceptionForm.get('Loan_Amount').value -
			this.exceptionForm.get('Exception_Increase').value
		).toFixed(2);

		this.exceptionForm.patchValue({
			Exception_Funded_Amount: result,
		});
	}

	async getStageSummary() {
		return new Promise((resolve, reject) => {
			this.apiService.getLookupsdeals().subscribe((res) => {
				if (res['message']['statusCode'] == '200') {
					let arr = JSON.parse(res.message.body);
					this.stageLookUp = this.customFunction.singleFilterName(
						arr.fields,
						'api_name',
						'Stage'
					)[0]['pick_list_values'];
					resolve(true);
				} else {
					console.log('Stage Error!');
					resolve(false);
				}
			});
		});
	}

	// Update the Summary Stage pickuplist
	updateExceptionStage() {
		this.controls.displayMessage(
			'exceptionUpdateStage',
			true,
			'successfulExceptionStage',
			'Save in progress...',
			'orange',
			'block'
		);

		this.apiService
			.updateSummaryStage(
				this.stageForm.get('Stage').value,
				this.zohoApplicationID
			)
			.subscribe((res) => {
				if (res['message']['statusCode'] == '200') {
					this.controls.displayMessage(
						'exceptionUpdateStage',
						false,
						'successfulExceptionStage',
						'Save Successful',
						'green',
						'block'
					);
					this.apiService.sendClickEvent();
				} else {
					this.controls.displayMessage(
						'exceptionUpdateStage',
						false,
						'successfulExceptionStage',
						'Save unsuccessful',
						'red',
						'block'
					);
				}
			});
	}

	async getCurrentStage() {
		if (await this.getStageSummary()) {
			this.apiService
				.getSingleAppSummary(this.zohoApplicationID)
				.subscribe((res) => {
					if (res['message']['statusCode'] == '200') {
						let arr = JSON.parse(res.message.body);
						this.Deal_Name = arr.data[0].Deal_Name;

						let stage = this.customFunction.singleFilterName(
							this.stageLookUp,
							'display_value',
							arr.data[0]['Stage']
						);
						this.stageForm.patchValue({
							Stage: stage[0]['id'],
						});
					}
				});
		}
	}

	saveExceptionNote() {
		this.controls.displayMessage(
			'saveExceptionNoteButton',
			true,
			'successfulExceptionNote',
			'Save in progress...',
			'orange',
			'block'
		);
		// Push to ZOHO
		this.apiService
			.addLoanInterviewNotesLocal(
				this.exceptionNote.value,
				this.zohoApplicationID,
				this.loggedZohoID,
				this.Deal_Name,
				this.loggedName
			)
			.subscribe((res) => {
				if (res.message == 'Success') {
					let app_note_id = '';
					try {
						app_note_id = res['data'][0]['app_note_id'];
					} catch (err) {
						app_note_id = '';
					}
					// Push to ZOHO
					this.apiService
						.addLoanInterviewNotesZOHO(
							this.exceptionNote.value,
							this.zohoApplicationID,
							this.loggedZohoID
						)
						.subscribe((res) => {
							// console.log(res)
							if (res['message']['statusCode'] === 201) {
								let zoho_note_id = res['app_id'];
								this.apiService
									.updateNoteIDLocal(app_note_id, zoho_note_id)
									.subscribe((res) => {
										// console.log(res)
									});
								console.log(`app note id : ${app_note_id}`);
								console.log(`zoho_note_id : ${zoho_note_id}`);
								this.apiService
									.updateNoteIDLocal(app_note_id, zoho_note_id)
									.subscribe((res) => {
										console.log(res);
									});
								this.controls.displayMessage(
									'saveExceptionNoteButton',
									false,
									'successfulExceptionNote',
									'Save successful!',
									'green',
									'block'
								);
								this.apiService.sendClickEvent();
							} else {
								this.apiService
									.emailNotesToIT(
										this.exceptionNote.value,
										this.zohoApplicationID,
										this.Deal_Name,
										this.loggedName
									)
									.subscribe((res) => {
										console.log(res);
									});
								this.controls.displayMessage(
									'saveExceptionNoteButton',
									false,
									'successfulExceptionNote',
									'Save unsuccessful!',
									'red',
									'block'
								);
								console.log(res, 'ZOHO Note push failed!');
							}

							// Implement after complete transition to platform notes: This updated the recent note on ZOHO
							// this.apiService.updateRecentNoteZohoApplication(this.zohoApplicationID,this.noteEntry.noteForm.value,this.loggedName).subscribe((res)=>{
							//   // console.log(res, "********************")
							//   this.loadZohoAppNotes();
							// })
						});
				} else {
					this.controls.displayMessage(
						'saveNoteButton',
						false,
						'saveNoteMessage',
						'Save unsuccessful!',
						'red',
						'block'
					);
				}
			});

		// let zoho_note_id = ''
		// this.apiService.addLoanInterviewNotesZOHO(this.exceptionNote.value, this.zohoApplicationID, this.loggedZohoID).subscribe((res) => {
		//   console.log("_______________________________________________________________________")
		//   console.log(res)
		//   zoho_note_id = res['app_id']
		//   // Insert on local platform
		//   this.apiService.addLoanInterviewNotesLocal(this.exceptionNote.value, this.zohoApplicationID, this.loggedZohoID, this.Deal_Name, this.loggedName).subscribe((r) => {
		//     if (r.message == "Success") {
		//       let app_note_id = ''
		//       try {
		//         app_note_id = r['data'][0]['app_note_id']
		//       }
		//       catch (err) {
		//         app_note_id = ''
		//       }
		//       console.log(`app note id : ${app_note_id}`)
		//       console.log(`zoho_note_id : ${zoho_note_id}`)
		//       this.apiService.updateNoteIDLocal(app_note_id, zoho_note_id).subscribe((res) => {
		//         console.log(res)
		//       })
		//       this.controls.displayMessage('saveExceptionNoteButton', false, 'successfulExceptionNote', 'Save successful!', 'green', 'block');
		//       this.apiService.sendClickEvent();
		//     } else {
		//       this.controls.displayMessage('saveExceptionNoteButton', false, 'successfulExceptionNote', 'Save unsuccessful!', 'red', 'block');
		//     }
		//   })
		// })
	}

	getOverridePicklist() {
		this.apiService.getzoho_Override_Types().subscribe((res: any) => {
			if (res.message == 'Success') {
				this.override_type_look_up = res.data;
			}
		});
		this.apiService.getzoho_Overrides().subscribe((res: any) => {
			if (res.message == 'Success') {
				this.override_look_up = res.data;
			}
		});
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}
