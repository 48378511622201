import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import { CustomFunctionService } from '../../../custom-function.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
	selector: 'app-underwritingdashboard',
	templateUrl: './underwritingdashboard.component.html',
	styleUrls: ['./underwritingdashboard.component.scss'],
})
export class UnderwritingdashboardComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private http: HttpClient,
		private _routerLink: Router
	) { }

	timestamp = Date();

	// Overall
	creditArray: any;
	loanInterviewsArray: Array<string> = [];
	zeroInterviewArray: [];
	zeroInterviewAttempts: any;
	totalZeroAmount: any;

	oneInterviewArray: [];
	oneInterviewAttempts: any;
	totalOneAmount: any;

	moreInterviewArray: string[] = [];
	moreInterviewAttempts: any;
	totalMoreAmount: any;

	fileReviewArray: any;
	scheduleArray: string[] = [];
	totalSchedule: any;

	totalCredit: any;
	totalFileReview: any;
	totalRLIAmount: any;
	totalCBAmount: any;
	totalFRAmount: any;

	readyLoanInterview: any;

	pM: Number; // Pagination More call attempts
	countM: Number; // Pagination

	p1: Number; // Pagination One call attempts
	count1: Number; // Pagination

	p0: Number; // Pagination 0 call attempts
	count0: Number; // Pagination

	underwritingChecklist: any;
	totalUC: any;

	uw_needs_docs: any;
	total_uw_needs_docs: any;

	uw_unresponsive: any;
	total_uw_unresponsive: any;

	username: any;

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe(async (res: any) => {
					if (res.message === 'Success') {
						this.username = res.data[0]['full_name'];
						// this.fetchUnderwritingRLI();

						this.fetchUnderwritingSchedule();

						this.fetchUnderwritingCB();
						this.fetchUnderwritingFR();
						this.fetchUnderwritingUC();
						this.fetchUWneedsDocs();
						this.fetchUWunresponsive();

						// this.fileReviewArray = await this.getUWfiles('File Review');
						// this.totalFileReview = this.fileReviewArray.length
						// this.totalFRAmount = this.customFunction.sumNumbers(this.fileReviewArray, 'Amount').toFixed(2)

						// this.creditArray = await this.getUWfiles('Credit Bureau');
						// this.totalCredit = this.creditArray.length
						// this.totalCBAmount = this.customFunction.sumNumbers(this.creditArray, 'Amount').toFixed(2)

						// this.underwritingChecklist = await this.getUWfiles('Underwriting Checklist');
						// console.log(this.uw_needs_docs, "this.uw_unresponsive")
						// this.totalUC = this.underwritingChecklist.length

						// this.uw_unresponsive = await this.getUWfiles('UW unresponsive');
						// console.log(this.uw_unresponsive, "this.uw_unresponsive")
						// this.total_uw_unresponsive = this.uw_unresponsive.length

						// this.uw_needs_docs = await this.getUWfiles('UW needs docs');
						// console.log(this.uw_needs_docs, "this.uw_needs_docs")
						// this.total_uw_needs_docs = this.uw_needs_docs.length

						this.pM = 1;
						this.countM = 20;
						this.p1 = 1;
						this.count1 = 20;
						this.p0 = 1;
						this.count0 = 20;
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	// API call to fetch the deals created for today
	fetchUnderwritingRLI(scheduledList) {
		this.apiService.getDealsCreatedUnderwritingRLI().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.loanInterviewsArray = arr.data;
				this.customFunction.sortColumn(arr.data, 'RLI_Called_Count', 1, -1);

				// // Date to get the current date
				// let date = new Date();

				// // Filter the scheduled loan interviews that are only scheduled and only for today
				// let newScheduledList = scheduledList.filter(
				// 	(item) =>
				// 		item['Call_Status'] === 'Scheduled' &&
				// 		item['Call_Start_Time'].split('T')[0] ===
				// 			date.toISOString().split('T')[0]
				// );

				// If there is newScheduledList then empty this.loanInterviewsArray
				// Create new Ready for Loan Interview Queue that doesn't exist on the newScheduledList
				scheduledList = scheduledList.map(
					// newScheduledList = newScheduledList.map(
					(item) => item['What_Id']['id']
				);
				this.loanInterviewsArray = [...arr.data]; // Spread operator

				let tempRLIqueue;
				if (scheduledList.length != 0) {
					// if (newScheduledList.length != 0) {
					// Spread operator to create a temporary array
					tempRLIqueue = this.loanInterviewsArray.filter(
						(item) => !scheduledList.includes(item['id'])
						// (item) => !newScheduledList.includes(item['id'])
					);
					this.loanInterviewsArray = tempRLIqueue;
				}

				this.customFunction.sortColumn(
					this.loanInterviewsArray,
					'RLI_Called_Count',
					1,
					-1
				);

				this.readyLoanInterview = this.loanInterviewsArray.length;

				this.zeroInterviewArray = this.customFunction.singleFilterName(
					this.loanInterviewsArray,
					'RLI_Called_Count',
					null
				);
				this.zeroInterviewAttempts = this.zeroInterviewArray.length;
				this.totalZeroAmount = this.customFunction.sumNumbers(
					this.zeroInterviewArray,
					'Amount'
				);

				this.oneInterviewArray = this.customFunction.singleFilterName(
					this.loanInterviewsArray,
					'RLI_Called_Count',
					1
				);
				this.oneInterviewAttempts = this.oneInterviewArray.length;
				this.totalOneAmount = this.customFunction.sumNumbers(
					this.oneInterviewArray,
					'Amount'
				);

				this.moreInterviewArray = this.loanInterviewsArray.filter(
					(item) => item['RLI_Called_Count'] > 1
				);
				this.moreInterviewAttempts = this.moreInterviewArray.length;
				this.totalMoreAmount = this.customFunction.sumNumbers(
					this.moreInterviewArray,
					'Amount'
				);

				this.totalRLIAmount = this.customFunction
					.sumNumbers(this.loanInterviewsArray, 'Amount')
					.toFixed(2);
			} else {
				this.loanInterviewsArray = [];
				this.readyLoanInterview = 0;
				this.totalRLIAmount = 0;
			}
		});
	}

	fetchUnderwritingCB() {
		this.apiService.getDealsCreatedUnderwritingCB().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.creditArray = arr.data;
				this.totalCredit = this.creditArray.length;
				this.totalCBAmount = this.customFunction
					.sumNumbers(this.creditArray, 'Amount')
					.toFixed(2);
			} else {
				this.creditArray = [];
				this.totalCredit = 0;
				this.totalCBAmount = 0;
			}
		});
	}

	fetchUnderwritingUC() {
		this.apiService.getDealsCreatedUnderwritingChecklist().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.underwritingChecklist = arr.data;
				this.totalUC = this.underwritingChecklist.length;
			} else {
				this.underwritingChecklist = [];
			}
		});
	}

	fetchUnderwritingFR() {
		this.apiService.getDealsCreatedUnderwritingFR().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.fileReviewArray = arr.data;
				this.totalFileReview = this.fileReviewArray.length;
				this.totalFRAmount = this.customFunction
					.sumNumbers(this.fileReviewArray, 'Amount')
					.toFixed(2);
			} else {
				this.fileReviewArray = [];
				this.totalFileReview = 0;
				this.totalFRAmount = 0;
			}
		});
	}

	// API call to get the schedules with Ready for loan interview and call status scheduled
	fetchUnderwritingSchedule() {
		let number = new Date().getFullYear();
		this.apiService.getUnderwritingScheduled().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);

				// Populating the array with the schedules that are current year
				for (let i = 0; i < arr.data.length; i++) {
					// Splitting the datetime to get the year
					if (parseInt(arr.data[i].Call_Start_Time.split('-')[0]) === number) {
						this.scheduleArray.push(arr.data[i]);
					}
				}
				this.customFunction.sortColumn(
					this.scheduleArray,
					'Call_Start_Time',
					1,
					-1
				);
				// console.log(this.scheduleArray)
				this.fetchUnderwritingRLI(this.scheduleArray);
				this.totalSchedule = this.scheduleArray.length;
			} else {
				this.scheduleArray = [];
			}
		});
	}

	// UW unresponsive
	fetchUWunresponsive() {
		this.apiService.getUWunresponsive().subscribe((res: any) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.uw_unresponsive = arr.data;
				this.total_uw_unresponsive = this.uw_unresponsive.length;
			} else {
				this.uw_unresponsive = [];
			}
		});
	}

	// UW needs docs
	fetchUWneedsDocs() {
		this.apiService.getUWneedsDocs().subscribe((res: any) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				// console.log(arr.data)
				this.uw_needs_docs = arr.data;
				this.total_uw_needs_docs = this.uw_needs_docs.length;
			} else {
				this.uw_needs_docs = [];
			}
		});
	}

	updateCallSchedule(id, subject, callPurpose) {
		if (confirm(`Are you sure you want to delete the ${subject}?`)) {
			this.apiService
				.updateCallStatus(id, 'callagenda', this.username)
				.subscribe((res: any) => {
					if (res.message.statusCode === 200) {
						this.apiService
							.updateCallStatus(id, callPurpose, this.username)
							.subscribe((res) => {
								this.scheduleArray = this.scheduleArray.filter(
									(item) => item['id'] !== id
								);
								this.totalSchedule = this.scheduleArray.length;
								this.fetchUnderwritingRLI(this.scheduleArray);
							});
					} else {
						console.log(res);
					}
				});
		}
	}

	// Window Refresh
	refresh() {
		window.location.reload();
		// setTimeout(function() {
		//   location.reload();
		// }, 120000);
	}
}
