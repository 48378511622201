<div class="container-fluid">
	<!--ADD PRE-QUALIFYING INFORMATION-->
	<div class="row">
		<div class="sticky">
			<div class="row row-cols-3">
				<div class="col-10" *ngIf="getparamid" id="headertitle">
					<h5>
						Edit Application -
						{{
							this.controls.getControlName(
								this.applicationEntry.applicationForm,
								'Deal_Name'
							).value
						}}
					</h5>
				</div>
				<div class="col-2" id="alignbtn">
					<button
						class="btn btn-primary"
						(click)="updateZohoDeals()"
						id="updateZoho"
						[disabled]="this.applicationEntry.applicationForm.invalid"
					>
						Save
					</button>
					&nbsp;
					<a [routerLink]="['/dashboard']" class="btn btn-secondary">Cancel</a>
					<span id="saveMessage"></span>
				</div>
			</div>
		</div>
	</div>

	<h5 class="pre">General</h5>
	<span class="spinner-border" id="loading" *ngIf="loader"></span>
	<form [formGroup]="this.applicationEntry.applicationForm">
		<div class="row row-cols-12">
			<div class="col-2">
				<label class="form-label"> Stage </label>
			</div>
			<div class="col-3">
				<select class="form-select" formControlName="Stage">
					<option
						*ngFor="let item of this.lookUps.Stage"
						[value]="item.display_value"
					>
						{{ item.display_value }}
					</option>
				</select>
			</div>
		</div>
		<div class="row row-cols-12">
			<div class="col-2">
				<label class="form-label"> Application Number </label>
			</div>
			<div class="col-3">
				<input class="form-control" formControlName="App_Number" readonly />
			</div>
			<div class="col-2">
				<label class="form-label"> Application Owner </label>
			</div>
			<div class="col-3">
				<select class="form-select" formControlName="Owner">
					<option *ngFor="let item of this.lookUps.Users" [value]="item.id">
						{{ item.full_name }}
					</option>
				</select>
			</div>
		</div>
	</form>

	<h5 class="pre">PRE-QUALIFYING</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Application Name </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Deal_Name"
						[ngClass]="
							this.controls.getControlName(
								this.applicationEntry.applicationForm,
								'Deal_Name'
							).errors
								? 'is-invalid'
								: ''
						"
						required
					/>
					<small class="invalid-feedback"> Application name required </small>
				</div>
				<div class="col-2">
					<label class="form-label"> Which Province do you live in? </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Province1">
						<option
							*ngFor="let item of this.lookUps.Province1"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>

			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Residency Status </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Residency_Status">
						<option
							*ngFor="let item of this.lookUps.Residency_Status"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label">
						If needed could you find a Co-Signer?
					</label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="If_needed_could_you_find_a_Co_Singer"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>

			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Employment Type </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Employment_Status">
						<option
							*ngFor="let item of this.lookUps.Employment_Status"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label">
						Are you currently employed (Completed probation)
					</label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Employed">
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>

			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label">
						Do you earn at least $1200 (Primary income)
					</label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Do_you_earn_at_least_1200_per_month"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label">
						How do you get paid (Payment Method)
					</label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Payment_Method">
						<option
							*ngFor="let item of this.lookUps.Payment_Method"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>

			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label">
						Do you have any Active Payday loans?
					</label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Payday_loans">
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label">
						Bankruptcy, Credit Counselling, Consumer Proposal
					</label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Bankruptcy_CC_CP">
						<option
							*ngFor="let item of this.lookUps.Bankruptcy_CC_CP"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>

			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Are you currently a Home Owner? </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Are_you_currently_a_Home_Owner"
						(change)="
							displayEvent(
								this.applicationEntry.applicationForm.get(
									'Are_you_currently_a_Home_Owner'
								).value,
								'homeOwner'
							)
						"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Do you have a LM Credit loan? </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Consent_Form_Signed">
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
		</form>
	</div>

	<div class="container-fluid" *ngIf="homeOwnerDisplay">
		<h5 class="pre">CLIENT - EXISTING MORTGAGE</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Mortgage Balance </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="MTG_Balance"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> EMV (Est. Mortgage Value) </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Estimated_Value"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Loan Details </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="Loan_Det" />
				</div>
				<div class="col-2">
					<label class="form-label"> Mortgage Details </label>
				</div>
				<div class="col-3">
					<textarea
						class="form-control"
						formControlName="Mortgage_Details"
					></textarea>
				</div>
			</div>
		</form>
	</div>

	<h5 class="pre">REFERRAL</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Agency Name </label>
				</div>
				<div class="col-3">
					<!-- <select class="form-select" formControlName="Account_Name" (click)="getContacts($event)">
                        <option *ngFor="let item of this.lookUps.Account_Name" [value]="item.id">{{item.Account_Name}}
                        </option>
                    </select> -->
				</div>
				<div class="col-2">
					<label class="form-label"> Sales Agent </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Sales_Agent"
						[ngClass]="
							this.controls.getControlName(
								this.applicationEntry.applicationForm,
								'Sales_Agent'
							).errors
								? 'is-invalid'
								: ''
						"
					>
						<option
							*ngFor="let item of this.lookUps.Sales_Agent"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
					<small class="invalid-feedback">
						Sales agent selection required
					</small>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Collector Name </label>
				</div>
				<div class="col-3">
					<!-- <select class="form-select" formControlName="Referred_By">
                        <option *ngFor="let item of this.lookUps.Referred_By" [value]="item.id">
                            {{item.name}}</option>
                    </select> -->
				</div>
				<div class="col-2">
					<label class="form-label"> Broker </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Broker">
						<option
							*ngFor="let item of this.lookUps.Broker"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Creditor </label>
				</div>
				<div class="col-3">
					<ng-select formControlName="Creditt">
						<ng-option
							*ngFor="let item of this.lookUps.Creditt"
							[value]="item.display_value"
						>
							{{ item.display_value }}</ng-option
						>
					</ng-select>
				</div>
				<div class="col-2">
					<label class="form-label"> Referral method </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Referral_type">
						<option
							*ngFor="let item of this.lookUps.Referral_type"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Creditor Details </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Creditor_Agency_Name"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Referral Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Referral_Date"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Account Number (EDP) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Acc_Number"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Closing Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Closing_Date"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Account Balance </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Account_Bal"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Agency Location </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Agency_Location">
						<option
							*ngFor="let item of this.lookUps.Agency_Location"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Settlement Amount </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Settlement_Amount"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Creditor Portfolio </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Creditor_Portfolio">
						<option
							*ngFor="let item of this.lookUps.Creditor_Portfolio"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Total Requested Amount </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Funded_Amount"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Loan Type </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Loan_Type">
						<option
							*ngFor="let item of this.lookUps.Loan_Type"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Additional Debt? </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Additional_Debt2"
						(change)="
							displayEvent(
								this.applicationEntry.applicationForm.get('Additional_Debt2')
									.value,
								'additionalDebt'
							)
						"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Lead Source </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Lead_Source">
						<option
							*ngFor="let item of this.lookUps.Lead_Source"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
		</form>
	</div>
	<div
		class="container-fluid"
		id="additionalDebt"
		*ngIf="additionalDebtDisplay"
	>
		<h5 class="pre">ADDITIONAL DEBT</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<form class="form-group" formArrayName="Additional_Debt">
					<table class="table table-bordered table-hover">
						<thead class="table-light">
							<tr class="interviewFont">
								<th></th>
								<th scope="col">Agency</th>
								<th scope="col">Creditor</th>
								<th scope="col">Creditor Portfolio</th>
								<th scope="col">Account Number</th>
								<th scope="col">Collector</th>
								<th scope="col">Account Balance (CA$)</th>
								<th scope="col">Type of Credit</th>
							</tr>
						</thead>
						<tbody class="interviewFont">
							<tr
								*ngFor="
									let control of this.applicationEntry.applicationForm.controls
										.Additional_Debt['controls'];
									let i = index
								"
								[formGroupName]="i"
							>
								<td class="subformClose">
									<button
										class="btn-close"
										(click)="
											this.controls.removeRow(
												this.applicationEntry.applicationForm,
												i,
												'Additional_Debt'
											)
										"
									></button>
								</td>
								<td>
									<select class="form-select" formControlName="Agency"></select>
								</td>
								<td>
									<input
										type="text"
										class="form-control"
										formControlName="misc"
									/>
								</td>
								<td>
									<input
										type="text"
										class="form-control"
										formControlName="Creditor_Portfolio"
									/>
								</td>
								<td>
									<input
										type="text"
										class="form-control"
										formControlName="Account_Number"
									/>
								</td>
								<td>
									<select
										class="form-select"
										formControlName="Collector"
									></select>
								</td>
								<td>
									<input
										type="number"
										class="form-control"
										formControlName="Account_Balance"
										(click)="this.applicationEntry.totalAdditional()"
										(change)="this.applicationEntry.totalAdditional()"
									/>
								</td>
								<td>
									<select class="form-select" formControlName="Type_of_Credit">
										<option
											*ngFor="let item of this.lookUps.Type_of_Credit"
											[value]="item.display_value"
										>
											{{ item.display_value }}
										</option>
									</select>
								</td>
							</tr>
						</tbody>
					</table>
				</form>
			</div>
			<div class="row row-cols-12">
				<div class="col-10">
					<button
						class="btn btn-outline-primary"
						(click)="this.applicationEntry.addAdditionalRows()"
					>
						+ Add row
					</button>
				</div>
				<div class="col-1">
					<label class="form-label"> Total </label>
				</div>
				<div class="col-1">
					<input
						type="number"
						class="form-control"
						formControlName="Total"
						readonly
					/>
				</div>
			</div>
		</form>
	</div>

	<h5 class="pre">CLIENT - PERSONAL</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Gender </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Gender">
						<option
							*ngFor="let item of this.lookUps.gender"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Do you have another name? </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Do_you_have_another_name"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Marital Status </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Marital_Status">
						<option
							*ngFor="let item of this.lookUps.Marital_Status"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Alternative Name </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Alternative_Name"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Number of Dependents </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Number_of_Dependents">
						<option
							*ngFor="let item of this.lookUps.Number_of_Dependents"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Best contact time </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Best_contact_time">
						<option
							*ngFor="let item of this.lookUps.Best_contact_time"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Date of Birth </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Date_of_Birth"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Last Contact Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Last_Contact_Date"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> SIN </label>
				</div>
				<div class="col-3">
					<input type="number" class="form-control" formControlName="S_I_N" />
				</div>
				<div class="col-2">
					<label class="form-label"> Do you currently own a car? </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Does_you_own_a_car">
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Email </label>
				</div>
				<div class="col-3">
					<input type="email" class="form-control" formControlName="Email1" />
					<small
						*ngIf="
							this.applicationEntry.applicationForm.get('Email1').errors
								?.pattern
						"
					>
						Invalid email address
					</small>
				</div>
				<div class="col-2">
					<label class="form-label"> What is the value of the car? </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="What_is_the_value_of_the_car"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Mobile </label>
				</div>
				<div class="col-3">
					<input type="tel" class="form-control" formControlName="Mobile" />
				</div>
				<div class="col-2">
					<label class="form-label"> No SIN Reason </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="No_SIN_Reason">
						<option
							*ngFor="let item of this.lookUps.No_SIN_Reason"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Phone </label>
				</div>
				<div class="col-3">
					<input type="tel" class="form-control" formControlName="Phone" />
				</div>
				<div class="col-7"></div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Co-Applicant Required? </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Co_Appl_Required"
						(change)="
							displayEvent(
								this.applicationEntry.applicationForm.get('Co_Appl_Required')
									.value,
								'coAppRequired'
							)
						"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-7"></div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Emergency Contact? </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Emer_Cont"
						(change)="
							displayEvent(
								this.applicationEntry.applicationForm.get('Emer_Cont').value,
								'emergencyContacts'
							)
						"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-7"></div>
			</div>
		</form>
	</div>

	<div class="container-fluid" *ngIf="emergencyContactsDisplay">
		<h5 class="pre">CLIENT -EMERGENCY CONTACT</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<form class="form-group" formArrayName="EMERGANCY_CONTACTS">
					<table class="table table-bordered table-hover">
						<thead class="table-light">
							<tr class="interviewFont">
								<th></th>
								<th scope="col">Name</th>
								<th scope="col">Relation to Applicant</th>
								<th scope="col">Phone Number</th>
								<th scope="col">Email</th>
							</tr>
						</thead>
						<tbody class="interviewFont">
							<tr
								*ngFor="
									let control of this.applicationEntry.applicationForm.controls
										.EMERGANCY_CONTACTS['controls'];
									let i = index
								"
								[formGroupName]="i"
							>
								<td class="subformClose">
									<button
										class="btn-close"
										(click)="
											this.controls.removeRow(
												this.applicationEntry.applicationForm,
												i,
												'EMERGANCY_CONTACTS'
											)
										"
									></button>
								</td>
								<td>
									<input
										type="text"
										class="form-control"
										formControlName="Name1"
									/>
								</td>
								<td>
									<input
										type="text"
										class="form-control"
										formControlName="Relation_to_Applicant"
									/>
								</td>
								<td>
									<input
										type="tel"
										class="form-control"
										formControlName="Phone_Number"
									/>
								</td>
								<td>
									<input
										type="email"
										class="form-control"
										formControlName="Email"
										[pattern]="this.regexEntry.emailPattern"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</form>
			</div>
			<div class="row row-cols-12">
				<div class="col-10">
					<button
						class="btn btn-outline-primary"
						(click)="this.applicationEntry.addEmergencyRows()"
					>
						+ Add row
					</button>
				</div>
			</div>
		</form>
	</div>

	<h5 class="pre">CLIENT - ADDRESS</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Address </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="Address1" />
				</div>
				<div class="col-2">
					<label class="form-label"> Length of Residence </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Length_of_Residence"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Address Line 2 (Unit number) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Address_Line_2"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Monthly Payments </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Monthly_Payments"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> City </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="City" />
				</div>
				<div class="col-2">
					<label class="form-label"> Address Lookup </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Find_Address"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Province </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="Province" />
				</div>
				<div class="col-7"></div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Postal Code </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Postal_Code"
					/>
				</div>
				<div class="col-7"></div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Country </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Country">
						<option
							*ngFor="let item of this.lookUps.country"
							[value]="item.actual_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-7"></div>
			</div>
		</form>
	</div>

	<h5 class="pre">CLIENT - EMPLOYMENT</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Employer </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="Employer" />
				</div>
				<div class="col-2">
					<label class="form-label"> Industry </label>
				</div>
				<div class="col-3">
					<ng-select formControlName="Industry">
						<ng-option
							*ngFor="let item of this.lookUps.Industry"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</ng-option>
					</ng-select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Employment Status </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Employment_Status1">
						<option
							*ngFor="let item of this.lookUps.Employment_Status1"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Occupation </label>
				</div>
				<div class="col-3">
					<ng-select formControlName="Occupation">
						<ng-option
							*ngFor="let item of this.lookUps.Occupation"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</ng-option>
					</ng-select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Work Phone </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Work_Phone"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Pay Frequency </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Pay_Frequency2">
						<option
							*ngFor="let item of this.lookUps.Pay_Frequency2"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Work Extension </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Work_Extension"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Income (net paycheque income) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Income_net_paycheque_income"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Length of Employment </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Length_of_Employment"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Last Pay Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Last_Pay_Date"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Position </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="Position" />
				</div>
				<div class="col-2">
					<label class="form-label"> Supervisor Name </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Supervisor_Name"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Previous Employment Section? </label>
				</div>
				<div class="col-3">
					<input
						type="checkbox"
						class="form-check-input"
						formControlName="Previous_Employment"
						(change)="
							displayEvent(
								this.controls.getControlName(
									this.applicationEntry.applicationForm,
									'Previous_Employment'
								).value,
								'previousEmp'
							)
						"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Next Pay Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Next_Pay_Date"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-5"></div>
				<div class="col-2">
					<label class="form-label"> Supervisor Phone Number </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Supervisor_Phone_Number"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-5"></div>
				<div class="col-2">
					<label class="form-label"> Additional Employment </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Additional_Employment"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-5"></div>
				<div class="col-2">
					<label class="form-label"> Income Amount (Household Income) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Income_Amount_Household_Income"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-5"></div>
				<div class="col-2">
					<label class="form-label"> Employer Name (Household Income) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Employer_Name_Household_Income"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-5"></div>
				<div class="col-2">
					<label class="form-label"> Pay Frequency(Household Income) </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Pay_Frequency_Household_Income"
					>
						<option
							*ngFor="let item of this.lookUps.Pay_Frequency_Household_Income"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
		</form>
	</div>

	<div class="container-fluid" *ngIf="previousEmpDisplay">
		<h5 class="pre">CLIENT - PREVIOUS EMPLOYMENT</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Previous Employment Type </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Previous_Employment_Type"
					>
						<option
							*ngFor="let item of this.lookUps.Previous_Employment_Type"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Previous Position </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Position"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Previous Employer </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Employer"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Previous Length of Employment </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Length_of_Employment"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Previous Supervisor Name </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Supervisor_Name"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Previous Supervisor Phone number </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Supervisor_Phone_number"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label">
						Previous Income(net paycheque income)
					</label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Income_net_paycheque_income"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Previous Pay Frequency </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Previous_Pay_Frequency"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Previous Payment Method </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Previous_Payment_Method">
						<option
							*ngFor="let item of this.lookUps.Previous_Payment_Method"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-7"></div>
			</div>
		</form>
	</div>

	<h5 class="pre">CLIENT - BANKING DETAILS</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Financial Institution Name </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Financial_Institution_Name"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Financial Institution Number </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Financial_Institution_Number"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Bank Account Number </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Bank_Account_Number"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Branch Transit Number </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Bank_Transit_No"
						(keydown)="this.functions.numberOnly($event)"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Branch Address </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Branch_Address"
					/>
				</div>
			</div>
		</form>
	</div>

	<div *ngIf="coAppRequiredDisplay">
		<h5 class="pre">CO-APPLICANT - PERSONAL</h5>
		<div class="container-fluid">
			<form [formGroup]="this.applicationEntry.applicationForm">
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant First Name </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Name"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Last Name </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Guarantor_Name"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Gender </label>
					</div>
					<div class="col-3">
						<select class="form-select" formControlName="Co_Applicant_Gender">
							<option
								*ngFor="let item of this.lookUps.gender"
								[value]="item.actual_value"
							>
								{{ item.display_value }}
							</option>
						</select>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Email </label>
					</div>
					<div class="col-3">
						<input type="email" class="form-control" formControlName="Email" />
						<small
							*ngIf="
								this.applicationEntry.applicationForm.get('Email').errors
									?.pattern
							"
						>
							Invalid email address
						</small>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Date of Birth </label>
					</div>
					<div class="col-3">
						<input
							type="date"
							class="form-control"
							formControlName="Co_Applicant_Date_of_Birth"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Home Number </label>
					</div>
					<div class="col-3">
						<input
							type="tel"
							class="form-control"
							formControlName="Secondary_Home_Number"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant SIN </label>
					</div>
					<div class="col-3">
						<input
							type="number"
							class="form-control"
							formControlName="Secondary_S_I_N"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Mobile </label>
					</div>
					<div class="col-3">
						<input
							type="tel"
							class="form-control"
							formControlName="Secondary_Cell_Number"
						/>
					</div>
				</div>
			</form>
		</div>

		<h5 class="pre">CO-APPLICANT - EMERGENCY CONTACT</h5>
		<div class="container-fluid">
			<form [formGroup]="this.applicationEntry.applicationForm">
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label">
							Co-Applicant emergency contact name
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_emergency_contact_name"
						/>
					</div>
					<div class="col-2">
						<label class="form-label">
							Co-Applicant Emergency contact number
						</label>
					</div>
					<div class="col-3">
						<input
							type="tel"
							class="form-control"
							formControlName="Co_Applicant_Emergency_contact_number2"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label">
							Co-Applicant emergency contact relation
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_emergency_contact_relation"
						/>
					</div>
					<div class="col-2">
						<label class="form-label">
							Co-Applicant emergency contact email
						</label>
					</div>
					<div class="col-3">
						<input
							type="email"
							class="form-control"
							formControlName="Co_Applicant_emergency_contact_email"
						/>
						<small
							*ngIf="
								this.applicationEntry.applicationForm.get(
									'Co_Applicant_emergency_contact_email'
								).errors?.pattern
							"
						>
							Invalid email address
						</small>
					</div>
				</div>
			</form>
		</div>

		<h5 class="pre">CO-APPLICANT - ADDRESS</h5>
		<div class="container-fluid">
			<form [formGroup]="this.applicationEntry.applicationForm">
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Address </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Property_Address"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Residential Status </label>
					</div>
					<div class="col-3">
						<select
							class="form-select"
							formControlName="Co_Applicant_Residential_Status"
						>
							<option
								*ngFor="let item of this.lookUps.residential_status"
								[value]="item.actual_value"
							>
								{{ item.display_value }}
							</option>
						</select>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label">
							Co-Applicant Address Line 2 (Unit number)
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Address_Line_2_Unit_number"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Length of Residence </label>
					</div>
					<div class="col-3">
						<select
							class="form-select"
							formControlName="Co_Applicant_Length_of_Residence"
						>
							<option
								*ngFor="
									let item of this.lookUps.Co_Applicant_Length_of_Residence
								"
								[value]="item.display_value"
							>
								{{ item.display_value }}
							</option>
						</select>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant City </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_City"
						/>
					</div>
					<div class="col-7"></div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Province </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Province"
						/>
					</div>
					<div class="col-7"></div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Postal Code </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Postal_Code"
						/>
					</div>
					<div class="col-7"></div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Country </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Country"
						/>
					</div>
					<div class="col-7"></div>
				</div>
			</form>
		</div>

		<h5 class="pre">CO-APPLICANT - EMPLOYMENT</h5>
		<div class="container-fluid">
			<form [formGroup]="this.applicationEntry.applicationForm">
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Employment Type </label>
					</div>
					<div class="col-3">
						<select class="form-select" formControlName="Co_Applicant_Status">
							<option
								*ngFor="let item of this.lookUps.Co_Applicant_Status"
								[value]="item.display_value"
							>
								{{ item.display_value }}
							</option>
						</select>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Industry </label>
					</div>
					<div class="col-3">
						<ng-select formControlName="Co_Applicant_Industry">
							<ng-option
								*ngFor="let item of this.lookUps.Co_Applicant_Industry"
								[value]="item.display_value"
							>
								{{ item.display_value }}
							</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Employer </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Employer"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Occupation </label>
					</div>
					<div class="col-3">
						<ng-select formControlName="Co_Applicant_Occupation">
							<ng-option
								*ngFor="let item of this.lookUps.Co_Applicant_Occupation"
								[value]="item.display_value"
							>
								{{ item.display_value }}
							</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Supervisor Name </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Supervisor_Name"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Position </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Secondary_Position"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Annual Income </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Sec_Annual_Income"
						/>
					</div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Years Worked </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Secondary_Years_Worked"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Payment Method </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Payment_Method"
						/>
					</div>
					<div class="col-2">
						<label class="form-label">
							Co-Applicant Supervisor Phone Number
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Supervisor_Phone_Number"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2"></div>
					<div class="col-3"></div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Work Number </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Secondary_Work_Number"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2"></div>
					<div class="col-3"></div>
					<div class="col-2">
						<label class="form-label"> Co-Applicant Pay Frequency </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Pay_Frequency"
						/>
					</div>
				</div>
			</form>
		</div>

		<h5 class="pre">CO-APPLICANT - BANKING DETAILS</h5>
		<div class="container-fluid">
			<form [formGroup]="this.applicationEntry.applicationForm">
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label">
							Co-Applicant Financial Institution Name
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Financial_Institution_Name"
						/>
					</div>
					<div class="col-2">
						<label class="form-label">
							Co-ApplicantFinancial Institution Number
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_ApplicantFinancial_Institution_Number1"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Bank Account Number </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Bank_Account"
						/>
					</div>
					<div class="col-2">
						<label class="form-label">
							Co-Applicant Branch Transit Number
						</label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Bank_Transit_No"
							(keydown)="this.functions.numberOnly($event)"
						/>
					</div>
				</div>
				<div class="row row-cols-12">
					<div class="col-2">
						<label class="form-label"> Co-Applicant Branch Address </label>
					</div>
					<div class="col-3">
						<input
							type="text"
							class="form-control"
							formControlName="Co_Applicant_Branch_Address"
						/>
					</div>
				</div>
			</form>
		</div>
	</div>

	<h5 class="pre">FUNDED DETAILS</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Payment Frequency </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Opportunity_Status">
						<option
							*ngFor="let item of this.lookUps.Opportunity_Status"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Loan Date </label>
				</div>
				<div class="col-3">
					<input type="date" class="form-control" formControlName="Loan_Date" />
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Term of Loan (Months) </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Term_of_Loan"
						(change)="calculateLoanYears()"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> First Payment Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Date_Ordered"
						(keydown)="this.functions.numberOnly($event)"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Term of Loan (Years) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Term_of_Loan_Years"
						readonly
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Last Payment Date </label>
				</div>
				<div class="col-3">
					<input
						type="date"
						class="form-control"
						formControlName="Date_Received"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label">
						Scheduled Payments (CU Fee Included)
					</label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Tertiary_Annual_Income"
						(change)="calculatePaymentCUFee()"
						(change)="calculateLPPScheduledPayment()"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Interest (APR) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Interest_APR1"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label">
						Total Obligation Entire Term(formula)
					</label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Total_Ob"
						readonly
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Setup Fee </label>
				</div>
				<div class="col-3">
					<input type="text" class="form-control" formControlName="Fee_Est" />
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Total Cost of Borrowing </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Referral_Amount"
						(change)="calculateTotalObligation()"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> Click n loan </label>
				</div>
				<div class="col-3">
					<input
						type="checkbox"
						class="form-check-input"
						formControlName="Click_n_loan"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> LPP Included? </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="LPP_Included">
						<option
							*ngFor="let item of this.lookUps.LPP_Included"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Deferral </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="Deferral">
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Credit Upgrader included? </label>
				</div>
				<div class="col-3">
					<select
						class="form-select"
						formControlName="Credit_Upgrader_included"
					>
						<option
							*ngFor="let item of this.lookUps.general"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Loan Agreement Created by </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Loan_Agreement_Created_by"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> CU Fee </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="CU_Fee"
						(change)="calculatePaymentCUFee()"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Sch. Payment (No CU Fee) </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="Sch_Payment_Plus_CU_Fee"
						readonly
					/>
				</div>
			</div>
		</form>
	</div>

	<h5 class="pre">LOAN PROTECTION PLAN (LPP)</h5>
	<div class="container-fluid">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> LPP Type </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="LPP_Type">
						<option
							*ngFor="let item of this.lookUps.LPP_Type"
							[value]="item.display_value"
						>
							{{ item.display_value }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> INS </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="LPP_Total"
						readonly
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> Loan Protection </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Loan_Protection"
						(change)="calculateLPPScheduledPayment()"
						(change)="calculateINS()"
					/>
				</div>
				<div class="col-2">
					<label class="form-label"> LPP Taxes </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="LPP_Taxes"
						(change)="calculateINS()"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> LPP with Scheduled Payment </label>
				</div>
				<div class="col-3">
					<input
						type="text"
						class="form-control"
						formControlName="LPP_with_Scheduled_Payment"
						readonly
					/>
				</div>
				<div class="col-2">
					<label class="form-label">
						Total Obligation for Entire Term with LPP
					</label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Debt_Total"
					/>
				</div>
			</div>
			<div class="row row-cols-12">
				<div class="col-2">
					<label class="form-label"> LPP Sold by </label>
				</div>
				<div class="col-3">
					<select class="form-select" formControlName="LPP_Sold_by">
						<option *ngFor="let item of this.lookUps.Users" [value]="item.id">
							{{ item.full_name }}
						</option>
					</select>
				</div>
				<div class="col-2">
					<label class="form-label"> Correct Total Obligation with LPP </label>
				</div>
				<div class="col-3">
					<input
						type="number"
						class="form-control"
						formControlName="Correct_Total_Obligation_with_LPP"
					/>
				</div>
			</div>
		</form>
	</div>
</div>
