<div class="container-fluid ptp-wrapper">
	<div style="height: calc(100% - 80px)">
		<!--    <app-hierarchy-data [hierarchyData]="summaryData"></app-hierarchy-data>-->
		<!-- <app-custom-table [columnDefs]="columnDefs" [rowData]="ptpRawData" [showLoader]="showLoader"
			[rowSelection]="'multiple'" [animateRows]="true" [pagination]="true" [paginationPageSize]="10"
			[showToolPanel]="true" [sideBar]="sideBar" [summaryDataToolPanel]="summaryData"
			[dropdownFilters]="dropdownFilters" [filterOneSelected]="'Requested'" [filterTwoSelected]="'manual_debit'"
			[rowClassRules]="rowClassRules" (rowDoubleClicked)="onRowDoubleClicked($event)"
			(gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)"></app-custom-table> -->

		<app-custom-table [columnDefs]="columnDefs" [rowData]="ptpRawData" [showLoader]="showLoader"
			[rowSelection]="'multiple'" [animateRows]="true" [pagination]="true" [paginationPageSize]="100"
			[showToolPanel]="true" [sideBar]="sideBar" [summaryDataToolPanel]="summaryData"
			[dropdownFilters]="dropdownFilters" [filterOneSelected]="'Requested'" [filterTwoSelected]="'manual_debit'"
			[rowClassRules]="rowClassRules" (rowDoubleClicked)="onRowDoubleClicked($event)"
			(gridReady)="onGridReady($event)"></app-custom-table>
	</div>
</div>