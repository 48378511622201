<span style="padding: 15px;"><strong>Loan Servicing</strong> | Reports/Payment Breakdowns</span>
<mat-tab-group style="height:  calc(100% - 30px);">
    <mat-tab label="MFT LPP 0876">
        <app-mft-lpp0876></app-mft-lpp0876>
    </mat-tab>
    <mat-tab label="MFT LPP 4476">
        <app-mft-lpp4476></app-mft-lpp4476>
    </mat-tab>
    <mat-tab label="Payment Breakdown">
        <app-payment-breakdown></app-payment-breakdown>
    </mat-tab>
    <mat-tab label="NSF">
        <app-nsf-reports-date></app-nsf-reports-date>
    </mat-tab>
</mat-tab-group>