<span class="spinner-border" id="loading" *ngIf="spinner"></span>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
	<mat-tab label="Activity">
		<div class="container-fluid" style="padding: 20px 20px 0 20px;">
			<div class="row">
				<form [formGroup]="this.noteEntry.noteForm">
					<div class="row row-cols-12">
						<div class="col-4">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>Note subject</mat-label>
								<mat-select formControlName="subject" (selectionChange)="onchangeNoteSubject()">
									<mat-option *ngFor="let item of this.noteEntry.subjects" [value]="item.subject">
										{{ item.subject }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="
											this.noteEntry.noteForm.controls['subject'].hasError(
												'required'
											) && this.noteEntry.noteForm.controls['subject'].touched
											">
									Subject is required
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-6">
							<div>
								<div>
									<mat-form-field appearance="outline" style="width: 100%">
										<mat-label>Description</mat-label>
										<input matInput class="form-control" formControlName="description" readonly />
										<mat-error *ngIf="
													this.noteEntry.noteForm.controls['subject'].hasError(
														'required'
													) &&
													this.noteEntry.noteForm.controls['subject'].touched
												">
											Subject is required
										</mat-error>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div class="col-2">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>Communication</mat-label>
								<mat-select formControlName="communication">
									<mat-option *ngFor="let item of this.noteEntry.displayCommunication"
										[value]="item.ref_value">
										{{ item.display_value }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">

						<div class="col-4" *ngIf="this.noteEntry.noteForm.get('subject').value === 'Email Notes'">
							<mat-form-field appearance="outline" style="width: 100%;">
								<mat-label>Select an email address</mat-label>
								<mat-select formControlName="email_address" [(ngModel)]="selectedEmail">
									<mat-option *ngFor="let item of officeEmails" [value]="item.email_addresses">
										{{ item.email_addresses }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="
							this.noteEntry.noteForm.controls['email_address'].hasError(
										'required'
									) && this.noteEntry.noteForm.controls['email_address'].touched
								">
									Email is required
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-4" *ngIf="callAttemptFlag">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>Call Attempt</mat-label>
								<mat-select formControlName="RLI_Called_Count">
									<mat-option *ngFor="let item of this.callAttempts.call_attempts"
										[value]="item.display_value">
										{{ item.display_value }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-10">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>Note</mat-label>
								<textarea matInput placeholder="Enter notes here..." rows="1" cols="30"
									class="form-control" formControlName="note" required></textarea>
								<mat-error *ngIf="
											this.noteEntry.noteForm.controls['note'].hasError(
												'required'
											) && this.noteEntry.noteForm.controls['note'].touched
											">
									Note is required
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-2"
							style="display: flex; align-items: start;  flex-direction: column; padding: 5px;">
							<button mat-raised-button color="primary" id="saveNoteButton"
								style="width: 150px; text-align: center" (click)="saveNote(this.noteEntry.editID)"
								[disabled]="this.noteEntry.noteForm.invalid" [disabled]="isExternalView">
								Save
							</button>
							<span id="saveNoteMessage"></span>
						</div>
					</div>
				</form>
				<div>
					<div class="table-responsive">
						<table class="table table-bordered table-hover" id="appNotes">
							<thead>
								<tr>
									<th class="colTime">Created</th>
									<th>Note</th>
									<th *ngIf="roleID === 0 || roleID === 1" class="colTime">
										Modified Time
									</th>
									<th *ngIf="roleID === 0 || roleID === 1">Status</th>
									<th class="actions"></th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngIf="roleID === 0 || roleID === 1">
									<tr *ngFor="
											let item of this.noteEntry.displayAdminNotes
												| paginate : { itemsPerPage: count, currentPage: p }
										">
										<td class="colTime">
											{{ item.created_time_formatted | date : 'medium' }}
										</td>
										<td>
											<strong>{{ item.note_subject }}
												<span *ngIf="item.description != null">( {{ item.description }} )</span>
												[ {{ item.created_by }} ] -
												{{ item.communication_method }}</strong><br />{{ item.note }}
										</td>
										<td class="colTime">
											<span *ngIf="item.modified_by != null"><strong>[ {{ item.modified_by }}
													]</strong></span><br />{{ item.modified_time_formatted }}
										</td>
										<td *ngIf="roleID === 0 || roleID === 1">
											{{ item.note_status }}
										</td>
										<td class="actions">
											<span *ngIf="roleID === 0 || roleID === 1" style="display: flex;">
												<button mat-icon-button (click)="editNote(item.app_note_id)">
													<mat-icon title="Edit" style="margin-right: 5px;">edit</mat-icon>
												</button>
												<button mat-icon-button (click)="deleteNote(item.app_note_id)">
													<mat-icon title="Delete">delete_forever</mat-icon>
												</button>
											</span>
										</td>
									</tr>
								</ng-container>
								<ng-container *ngIf="roleID !== 0 && roleID !== 1">
									<tr *ngFor="
											let item of this.noteEntry.displayUserNotes
												| paginate : { itemsPerPage: count, currentPage: p }
										">
										<td class="colTime">
											{{ item.created_time_formatted | date : 'medium' }}
										</td>
										<td>
											<strong>{{ item.note_subject }}
												<span *ngIf="item.description != null">( {{ item.description }} )</span>
												[ {{ item.created_by }} ] -
												{{ item.communication_method }}</strong><br />{{ item.note }}
										</td>
										<td *ngIf="roleID === 0 || roleID === 1">
											{{ item.note_status }}
										</td>
										<td class="actions">
											<button *ngIf="roleID !== 0 && roleID !== 1" mat-icon-button
												(click)="changeNoteStatus(item.app_note_id)" [disabled]="isExternal">
												<mat-icon title="Delete">delete_forever</mat-icon>
											</button>
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
						<div id="paginate" class="text-right">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="Details">
		<div class="col">
			<div class="tabs">
				<div class="tab">
					<input class="accordion" type="checkbox" id="chck1" />
					<label class="tab-label" for="chck1">GENERAL</label>
					<div class="tab-content">
						<div class="row row-cols-12">
							<div class="col-sm-2">
								<label class="labelBold">LPP Sold by</label><br /><label>{{
									LPP_Sold_by
									}}</label><br />
							</div>
							<div class="col-sm-2">
								<label class="labelBold">LPP with Scheduled Payment</label><br /><label>{{
									LPP_with_Scheduled_Payment }}</label><br />
							</div>
							<div class="col-sm-2">
								<label class="labelBold">Loan Agreement Created by</label><br /><label>{{
									Loan_Agreement_Created_by }}</label><br />
							</div>
							<div class="col-sm-2">
								<label class="labelBold">Loan Protection</label><br /><label>{{
									Loan_Protection
									}}</label><br />
							</div>
						</div>
					</div>
				</div>
				<div class="tab">
					<input class="accordion" type="checkbox" id="chck2" />
					<label class="tab-label" for="chck2">REFERRAL</label>
					<div class="tab-content">
						<div class="row row-cols-12">
							<div class="col-sm-2">
								<label class="labelBold">Creditor</label><br /><label>{{
									Creditt
									}}</label><br />
								<label class="labelBold">Creditor Portfolio</label><br /><label>{{
									Creditor_Portfolio
									}}</label><br />
								<label class="labelBold">Creditor Details</label><br /><label>{{
									creditor_details
									}}</label><br />
								<label class="labelBold">Account Number (EDP)</label><br /><label>{{ acct_edp
									}}</label><br />
								<label class="labelBold">Account Balance</label><br /><label>{{
									acct_bal | currency
									}}</label><br />
								<label class="labelBold">Settlement Amount</label><br /><label>{{ Settlement_Amount |
									currency }}</label><br />
								<label class="labelBold">Total Requested Amount</label><br /><label>{{ Funded_Amount |
									currency }}</label><br />
							</div>
							<div class="col-sm-4">
								<label *ngIf="Additional_Debt2 != null" class="labelBold">Additional Debt?</label><br />
								<label *ngIf="Additional_Debt2 != null">{{
									Additional_Debt2
									}}</label><br />
								<div *ngIf="Additional_Debt2 != null" class="table-responsive">
									<table class="table table-bordered">
										<thead class="table-light">
											<tr>
												<th scope="col">Agency</th>
												<th scope="col">Creditor</th>
												<th scope="col">Creditor Portfolio</th>
												<th scope="col">Account Balance (CA$)</th>
												<th scope="col">Settlement Amount (CA$)</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let item of debts">
												<td *ngIf="item.Agency === null"></td>
												<td *ngIf="item.Agency != null">
													{{ item.Agency['name'] }}
												</td>
												<td>{{ item.misc }}</td>
												<td>{{ item.Creditor_Portfolio }}</td>
												<td id="alignAmount">
													{{ item.Account_Balance | currency }}
												</td>
												<td id="alignAmount">{{ item.Settlement_Amount | currency}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="tab">
					<input class="accordion" type="checkbox" id="chck3" />
					<label class="tab-label" for="chck3">CLIENT</label>
					<div class="tab-content">
						<div class="row row-cols-12">
							<h6><strong>PRIMARY APPLICANT</strong></h6>
							<div class="row row-cols-6">
								<div class="col-sm-2">
									<label class="labelBold">Age</label><br /><label>{{
										Age
										}}</label><br />
									<label class="labelBold">Gender</label><br /><label>{{
										Gender
										}}</label><br />
									<label class="labelBold">Marital Status</label><br /><label>{{
										Marital_Status
										}}</label><br />
									<label class="labelBold">SIN</label><br /><label>{{
										S_I_N
										}}</label><br />
									<label class="labelBold">Mobile</label><br /><label>{{
										Mobile
										}}</label><br />
									<label class="labelBold">Phone</label><br /><label>{{
										Phone
										}}</label><br />
									<label class="labelBold">Email</label><br /><label>{{
										primary_email
										}}</label><br />
								</div>
								<div class="col-sm-2">
									<br />
									<label class="labelBold">Address</label><br /><label>{{ Address_Line_2 }}-{{
										Address1 }}</label><br />
									<label class="labelBold">City</label><br /><label>{{
										City
										}}</label><br />
									<label class="labelBold">Province</label><br /><label>{{
										Province
										}}</label><br />
									<label class="labelBold">Postal Code</label><br /><label>{{
										Postal_Code
										}}</label><br />
									<label class="labelBold">Length of Residence</label><br /><label>{{ residence_length
										}}</label><br />
									<label class="labelBold">Monthly Payments</label><br /><label>{{ Monthly_Payments
										}}</label>
								</div>
								<div class="col-sm-2">
									<br />
									<label class="labelBold">Employer</label><br /><label>{{
										Employer
										}}</label><br />
									<label class="labelBold">Work Phone</label><br /><label>{{
										Work_Phone
										}}</label><br />
									<label class="labelBold">Work Extension</label><br /><label>{{
										Work_Extension
										}}</label><br />
									<label class="labelBold">Length of Employment</label><br /><label>{{ emp_length
										}}</label><br />
									<label class="labelBold">Position</label><br /><label>{{
										Position
										}}</label>
								</div>
								<div class="col-sm-2">
									<br />
									<label class="labelBold">Pay Frequency</label><br /><label>{{
										Pay_Frequency
										}}</label><br />
									<label class="labelBold">Income (net paycheque income)</label><br /><label>{{
										income_net }}</label><br />
									<label class="labelBold">Supervisor Name</label><br /><label>{{ Supervisor_Name
										}}</label><br />
									<label class="labelBold">Supervisor Phone Number</label><br /><label>{{
										supervisor_number }}</label><br />
									<label class="labelBold">Additional Employment</label><br /><label>{{
										Additional_Employment }}</label><br />
									<label class="labelBold">Income Amount (Household Income)</label><br /><label>{{
										household_income }}</label><br />
									<label class="labelBold">Employer Name (Household Income)</label><br /><label>{{
										household_employer }}</label><br />
									<label class="labelBold">Pay Frequency(Household Income)</label><br /><label>{{
										household_frequency }}</label><br />
									<label class="labelBold">Previous Employment?</label><br /><label
										*ngIf="previous_employment == false">No</label><label
										*ngIf="previous_employment == true">Yes</label>
								</div>
								<div class="col-sm-3" *ngIf="previous_employment == true">
									<br />
									<label class="labelBold">Previous Employment Type</label><br /><label>{{
										previous_emp }}</label><br />
									<label class="labelBold">Previous Position</label><br /><label>{{ Previous_Position
										}}</label><br />
									<label class="labelBold">Previous Employer</label><br /><label>{{ Previous_Employer
										}}</label><br />
									<label class="labelBold">Previous Length of Employment</label><br /><label>{{
										prev_emp_length }}</label><br />
									<label class="labelBold">Previous Supervisor Name</label><br /><label>{{
										prev_supervisor_name }}</label><br />
									<label class="labelBold">Previous Supervisor Phone number</label><br /><label>{{
										prev_supervisor_phone }}</label><br />
									<label class="labelBold">Previous Income(net paycheque
										income):</label><br /><label>{{ previous_income }}</label><br />
									<label class="labelBold">Previous Pay Frequency</label><br /><label>{{
										prev_pay_frequency }}</label><br />
									<label class="labelBold">Previous Payment Method</label><br /><label>{{
										pre_pay_method }}</label>
								</div>
							</div>
							<h6 *ngIf="this.co_app_required === 'Yes'">
								<strong>CO-APPLICANT</strong>
							</h6>
							<div class="row row-cols-6" *ngIf="this.co_app_required === 'Yes'">
								<div class="col-sm-2">
									<label class="labelBold">Name</label><br /><label>{{ co_app_firstname }}{{
										co_app_lastname }}</label><br />
									<label class="labelBold">DOB</label><br /><label>{{
										co_app_dob
										}}</label><br />
									<label class="labelBold">Address</label><br /><label>{{ co_app_address_unit }}-{{
										co_app_address }}</label><br />
									<label class="labelBold">City</label><br /><label>{{
										co_app_address_city
										}}</label><br />
									<label class="labelBold">Province</label><br /><label>{{
										co_app_address_province
										}}</label><br />
									<label class="labelBold">Postal Code</label><br /><label>{{
										co_app_address_postal
										}}</label><br />
								</div>
								<div class="col-sm-2">
									<br />
									<label class="labelBold">Mobile</label><br /><label>{{
										Secondary_Cell_Number
										}}</label><br />
									<label class="labelBold">Email</label><br /><label>{{
										co_app_email
										}}</label><br />
									<label class="labelBold">Employer</label><br /><label>{{
										co_applicant_employer
										}}</label><br />
									<label class="labelBold">Position</label><br /><label>{{
										co_app_secondary_position
										}}</label><br />
									<label class="labelBold">Employment Type</label><br /><label>{{ co_app_emp_status
										}}</label><br />
									<label class="labelBold">Length of Employment</label><br /><label>{{
										co_app_secondary_years_worked }}</label><br />
									<label class="labelBold">Annual Income</label><br /><label>{{
										co_app_sec_annual_income
										}}</label><br />
									<label class="labelBold">Pay Frequency</label><br /><label>{{
										co_applicant_pay_frequency
										}}</label><br />
									<label class="labelBold">Credit Score</label><br /><label>{{
										Co_App_Credit_Score
										}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab">
					<input class="accordion" type="checkbox" id="chck4" checked />
					<label class="tab-label" for="chck4">UNDERWITING</label>
					<div class="tab-content">
						<div class="row row-cols-12">
							<div class="row row-cols-5">
								<div class="col-sm-3">
									<h5>
										<span *ngIf="co_app_required === 'Yes'">
											<select (change)="
													changeUnderwritingApplicantType(
														this.zohoApplicationID,
														$event
													)
												" [(ngModel)]="applicantType">
												<option value="Primary">Primary</option>
												<option value="Co-applicant">Co-applicant</option>
											</select>
										</span>
										<span *ngIf="
												co_app_required === 'No' || co_app_required === null
											">
											<select (change)="
													changeUnderwritingApplicantType(
														this.zohoApplicationID,
														$event
													)
												" [(ngModel)]="applicantType">
												<option value="Primary">Primary</option>
											</select>
										</span>
									</h5>
								</div>
							</div>
							<div class="row row-cols-5">
								<div class="col-sm-3">
									<h6><strong>EXPENSES</strong></h6>
									<div class="table-responsive">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th>Type</th>
													<th>Creditor Name</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of underwritingExpenses">
													<td>{{ item.type }}</td>
													<td>{{ item.creditor_name }}</td>
													<td id="alignAmount">
														{{ item.amount | currency : 'CAD' }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="alignExpenses">
										<label class="labelEx">Total Monthly
											Income:</label><label>{{underwritingInformation?.['Total_Monthly_Income'] |
											currency}}</label><br />
										<label class="labelEx">Total Additional
											Expenses:</label><label>{{underwritingInformation?.['Total_Additional_Expenses']
											|
											currency}}</label><br />
										<label class="labelEx">Current Loan Amount
											Payments(Monthly):</label><label>{{underwritingInformation?.['Loan_Monthly_Payments']|currency}}</label>
									</div>
								</div>
								<div class="col-sm-9">
									<div class="row">
										<div class="col-sm-3">
											<h6><strong>GENERAL</strong></h6>
											<label class="labelBold">Reason for falling
												behind</label><br /><label>{{underwritingInformation?.['Reason_for_falling_behind']}}</label><br />
											<label class="labelBold">Overdraft
												Limit</label><br /><label>{{underwritingInformation?.['Overdraft_Limit']}}</label><br />
											<label class="labelBold">Is this a Join
												Account</label><br /><label>{{underwritingInformation?.['Is_this_a_joint_Account']}}</label><br />
										</div>
										<div class="col-sm-3">
											<h6><strong>TAX AND DEPENDANT INFORMATION</strong></h6>
											<label class="labelBold">When was the last time you filed
												taxes?</label><br /><label>{{underwritingInformation?.['When_was_the_last_time_you_filed_taxes']}}</label><br />
											<label class="labelBold">Have you setup an arrangement with the
												CRA?</label><br /><label>{{underwritingInformation?.['Have_you_setup_an_arrangement_with_the_CRA']}}</label><br />
											<label class="labelBold">Number of
												Dependents</label><br /><label>{{underwritingInformation?.['Dependents']}}</label><br />
											<label class="labelBold">How much do owe to Revenue
												Canada?</label><br /><label>{{underwritingInformation?.['How_much_do_you_owe_Revenue_Canada']}}</label><br />
											<label class="labelBold">Do you live with anyone
												else?</label><br /><label>{{underwritingInformation?.['Do_you_live_with_anyone_else']}}</label><br />
										</div>
										<div *ngIf="underwritingInformation?.['Are_you_a_homeowner']==='Yes'"
											class="col-sm-3">
											<h6><strong>HOME OWNER QUESTIONS</strong></h6>
											<label
												class="labelBold">Homeowner?</label><br /><label>{{underwritingInformation?.['Are_you_a_homeowner']}}</label><br />
											<label class="labelBold">Mortgage on
												property?</label><br /><label>{{underwritingInformation?.['Is_there_a_mortgage_on_the_property']}}</label><br />
											<label class="labelBold">Purview
												Amount</label><br /><label>{{underwritingInformation?.['Purview_Value']}}</label><br />
											<label class="labelBold">Mortgage
												Balance?</label><br /><label>{{underwritingInformation?.['What_is_the_balance_of_your_mortgage']}}</label><br />
											<label class="labelBold">Property Tax
												Owing?</label><br /><label>{{underwritingInformation?.['Do_you_owe_any_property_tax_Howe_owners_only']}}</label><br />
											<label class="labelBold">Mortage
												Holder(s)?</label><br /><label>{{underwritingInformation?.['Is_there_a_mortgage_on_the_property']}}</label><br />
											<label class="labelBold">Property
												Amount?</label><br /><label>{{underwritingInformation?.['What_is_current_value_of_the_property']}}</label><br />
											<label class="labelBold">Mortgage Interest
												Rate?</label><br /><label>{{underwritingInformation?.['What_interest_rate_on_your_mortgage']}}</label><br />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>