import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import { CustomFunctionService } from '../../../custom-function.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
	selector: 'app-validatorqueue',
	templateUrl: './validatorqueue.component.html',
	styleUrls: ['./validatorqueue.component.scss'],
})
export class ValidatorqueueComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private http: HttpClient,
		private _routerLink: Router
	) {}

	timestamp = Date();

	// Overall
	creditArray: [];
	underwritingCompleteArray: [];

	totalCredit: any;
	totalUC: any;
	totalCBAmount: any;
	totalUCAmount: any;

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchUnderwritingCB();
						this.getUnderwritingComplateValidator();

						(<HTMLImageElement>document.querySelector('#ooma')).src =
							'https://manage.voxter.com/#';
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	fetchUnderwritingCB() {
		this.apiService.getDealsCreatedUnderwritingCB().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.creditArray = arr.data;
				this.totalCredit = this.creditArray.length;
				this.totalCBAmount = this.customFunction
					.sumNumbers(this.creditArray, 'Amount')
					.toFixed(2);
			} else {
				this.creditArray = [];
				this.totalCredit = 0;
				this.totalCBAmount = 0;
			}
		});
	}

	getUnderwritingComplateValidator() {
		this.apiService.getUnderwritingComplateValidator().subscribe((res) => {
			console.log(res);
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.underwritingCompleteArray = arr.data;
				this.totalUC = this.underwritingCompleteArray.length;
				this.totalUCAmount = this.customFunction
					.sumNumbers(this.underwritingCompleteArray, 'Amount')
					.toFixed(2);
			} else {
				this.underwritingCompleteArray = [];
				this.totalUC = 0;
				this.totalUCAmount = 0;
			}
		});
	}

	// Window Refresh
	refresh() {
		window.location.reload();
		// setTimeout(function() {
		//   location.reload();
		// }, 120000);
	}
}
