<div class="container-fluid ptp-wrapper">
	<div class="overlayDiv">
		<span>
			<button mat-stroked-button [color]="allSelected" (click)="resetFilters()">ALL</button>
			<button style="margin-left: 10px;" [color]="spvSelected" mat-stroked-button (click)="spvList()">SPV</button>
			<button style="margin-left: 10px;" [color]="mftSelected" mat-stroked-button (click)="mftList()">MFT</button>
			<button style="margin-left: 10px;" [color]="debentureSelected" mat-stroked-button
				(click)="debentureList()">Debenture</button>
			<button style="margin-left: 10px;" [color]="qvestSelected" mat-stroked-button
				(click)="qvestList()">QVEST</button>
			<button style="margin-left: 10px;" [color]="brokenSelected" mat-stroked-button
				(click)="brokenPTPList()">Broken PTP (NSF)</button>
			<button style="margin-left: 10px;" [color]="maturedSelected" mat-stroked-button
				(click)="maturedList()">Matured</button>
		</span>
	</div>
	<div class="row" style="height: calc(100% - 30px);">
		<app-custom-table class="ag-theme-material" class="collection-wrapper" [rowData]="rowData_Collection"
			[showLoader]="showLoader" [columnDefs]="columnDefs_Collection" [hideGlobalFilter]="true"
			[animateRows]="true" [pagination]="false" [paginationPageSize]="10000" [rowClassRules]="rowClassRules"
			(gridReady)="onGridReady($event)">
		</app-custom-table>
	</div>
</div>