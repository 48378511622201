<div style="padding: 0 25px">
	<div class="col-2">
		<label class="form-label">Loan Group</label>
		<select
			class="form-select"
			[(ngModel)]="loanGroupSelected"
			(change)="onLoanGroupFilterChange($event)"
		>
			<option value="All" selected>All</option>
			<option value="Debenture">Debenture</option>
			<option value="SPV">SPV</option>
			<option value="MFT">MFT</option>
			<option value="QVEST">QVEST</option>
		</select>
	</div>
	<div
		style="display: flex; justify-content: space-evenly; align-items: center"
	>
		<div class="delinquency-ratios-container">
			<mat-card
				class="delinquency-mat-card"
				style="background: #2b2c49; color: white"
				*ngIf="delinquencyCurrentPrincipalRatio"
			>
				<mat-card-content class="delinquency-mat-card-content">
					<div>Key metrics</div>
					<div class="metrics-container">
						<div>
							<div>{{ delinquencyPastDueTotalRatio }}%</div>
							<div>{{ delinquencyPastDueTotal | currencyShortForm }}</div>
							<div>Delinquency</div>
						</div>
						<div>
							<div>{{ delinquencyPastDueRatio }}%</div>
							<div>{{ delinquencyPastDue | currencyShortForm }}</div>
							<div>Early State (1-90)</div>
						</div>
						<div>
							<div>{{ delinquencyCurrentPrincipalRatio }}%</div>
							<div>{{ delinquencyCurrentPrincipal | currencyShortForm }}</div>
							<div>Default (>90)</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card
				class="delinquency-mat-card"
				*ngIf="delinquencyCurrentPrincipalRatio"
			>
				<mat-card-content class="delinquency-mat-card-content">
					<div>Insolvencies</div>
					<div class="metrics-container">
						<div>
							<div>{{ insolvencyTotalBKRatio }}%</div>
							<div>{{ insolvencyTotalBK | currencyShortForm }}</div>
							<div>BK</div>
						</div>
						<div>
							<div>{{ insolvencyTotalCPRatio }}%</div>
							<div>{{ insolvencyTotalCP | currencyShortForm }}</div>
							<div>CP</div>
						</div>
						<div>
							<div>{{ insolvencyTotalCCRatio }}%</div>
							<div>{{ insolvencyTotalCC | currencyShortForm }}</div>
							<div>CC</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<canvas
			baseChart
			class="chart"
			style="position: relative; height: 600px; width: 600px"
			[data]="pieChartData"
			[type]="pieChartType"
			[options]="pieChartOptions"
			[plugins]="pieChartPlugins"
		>
		</canvas>
	</div>
</div>

<!--<mat-divider style="margin: 1em 0;"></mat-divider>-->

<!--<div class="button-row">-->
<!--  <button mat-button mat-raised-button color="primary" (click)="toggleLegend()">Toggle Legend</button>-->
<!--  <button mat-button mat-raised-button color="primary" (click)="changeLabels()">Change Labels</button>-->
<!--  <button mat-button mat-raised-button color="primary" (click)="addSlice()">Add Slice</button>-->
<!--  <button mat-button mat-raised-button color="primary" (click)="removeSlice()">Remove Slice</button>-->
<!--  <button mat-button mat-raised-button color="primary" (click)="changeLegendPosition()">Change Legend Position</button>-->
<!--</div>-->

<mat-divider style="margin: 1em 0"></mat-divider>

<app-ptp-report [loanGroupSelected]="loanGroupSelected"></app-ptp-report>
