import {
	AfterContentChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HomeComponent
	implements OnInit, AfterViewInit, AfterContentChecked {
	public columnDefs: ColDef[] = [
		{
			field: 'Deal_Name',
			headerName: 'Name',
			cellRenderer: (params) => {
				return `<a class="noline"">${params.data.Deal_Name}</a>`;
			},
			pinned: 'left',
			minWidth: 180,
			// tooltipField: 'Deal_Name',
		},
		{
			field: 'App_Number',
			headerName: 'Loan Servicing',
			cellRenderer: (params) => {
				return `<a class="noline"">${params.data.App_Number}</a>`;
			},
			minWidth: 130,
		},
		{
			field: 'Stage',
			headerName: 'Stage',
			minWidth: 200,
			wrapText: true,
			autoHeight: true,
		},
		{
			field: 'Agency_Formula',
			headerName: 'Agency',
			minWidth: 200,
			wrapText: true,
			autoHeight: true,
		},
		{
			field: 'Collector_Formula',
			headerName: 'Collector',
			minWidth: 200,
			wrapText: true,
			autoHeight: true,
		},
		{
			field: 'Created_Time',
			headerName: 'Created',
			valueGetter: (params) =>
				this.dateFormatter.transform(params.data.Created_Time, 'mediumDate'),
		},
		{
			field: 'Referral_Date',
			headerName: 'Referral',
			valueGetter: (params) =>
				this.dateFormatter.transform(params.data.Referral_Date, 'mediumDate'),
		},
		{
			field: 'Amount',
			headerName: 'Amount',
			valueGetter: (params) =>
				this.currencyFormatter.transform(
					params.data.Amount,
					'CAD',
					'symbol-narrow'
				),
		},
		{
			field: 'gdriveextension__Drive_URL',
			headerName: 'Drive',
			cellRenderer: (params) => {
				const { gdriveextension__Drive_URL } = params.data;
				return gdriveextension__Drive_URL
					? `<a target="_blank"
          href="${gdriveextension__Drive_URL}"><i class="fa fa-folder-open-o fa-lg" aria-hidden="true"></i></a>`
					: '';
			},
		},
	];

	readAllClientApplications: any; // Display all the applications Deals

	searchKeyword: string;
	showLoader: boolean;
	showEmptyResults: boolean;

	pageHistoryArray: string[] = []; // Assign the response on this array
	currentRoute: string; // History Page route

	userName: string;
	roleID: any;

	userName$: Observable<string>;

	// Formcontrol for page history dropdown
	historyForm = new UntypedFormGroup({
		page_history: new UntypedFormControl(''),
	});

	constructor(
		private apiService: ApiServiceService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private cdref: ChangeDetectorRef,
		private dateFormatter: DatePipe,
		private currencyFormatter: CurrencyPipe
	) { }

	ngOnInit(): void {
		this.showEmptyResults = false;
		this.readAllClientApplications = [];

		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.userName = res.data[0]['email'];
						this.roleID = res.data[0]['roleID'];
						this.loadPageHistory();

						this.currentRoute = ' ';
					} else {
						this.router.navigate(['login']);
					}
				});
		} else {
			this.router.navigate(['login']);
		}
	}

	ngAfterViewInit() {
		this.activatedRoute?.queryParams?.subscribe((queryParams) => {
			this.searchKeyword = queryParams?.['searchKeyword'];
			if (queryParams?.['searchKeyword']) {
				this.getAllClientApplications();
			}
		});
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}

	onGridReady(params: GridReadyEvent) {
		// no-op
	}

	onCellClicked(e: CellClickedEvent) {
		console.log('cellClicked', e);
		const clickedCol = e.colDef.field;
		const { id, App_Number } = e.data;
		switch (clickedCol) {
			case 'Deal_Name':
				return this.router.navigate(['/applications/summary', id]);
			case 'App_Number':
				return this.openLoanMeta(App_Number);
			default:
				return null;
		}
	}

	// Fetch all the applications on the user management portal that has the first three letters on search bar
	// The search is based on deal name
	getAllClientApplications() {
		try {
			// Search pattern made to search on key Enter
			if (this.searchKeyword != '') {
				// Only do if the search term is not empty
				this.showLoader = true;
				this.apiService
					.getSearchApplication(this.searchKeyword)
					.subscribe((res) => {
						//console.log(res)
						if (res.message.statusCode == '200') {
							// If there is response with data release the information
							let arr;
							arr = JSON.parse(res.message.body);
							// Sort the response in descending order i.e. latest information by Created_Time
							this.readAllClientApplications = arr.data.sort((x, y) =>
								x.Created_Time > y.Created_Time
									? -1
									: y.Created_Time > x.Created_Time
										? 1
										: 0
							);
							this.showLoader = false;
							this.showEmptyResults = false;
						} else {
							this.showLoader = false;
							this.readAllClientApplications = []; // This will clear the search area
							this.showEmptyResults = true;
						}
					});
			} else {
				console.log('Empty Search!'); // If they enter without entering the search term then alert to enter the application name for search
				this.showEmptyResults = false;
			}
		} catch (e) {
			console.log('Search error', e);
		}
	}

	// Load the page history on the dropdown
	loadPageHistory() {
		this.apiService.getPageHistory(this.userName).subscribe((res) => {
			// console.log(res.data)
			this.pageHistoryArray = res.data;
		});
	}

	//INCLUDE THIS IN THE SEARCH COMPONENT TO OPEN LOAN META FOR SPECIFIC LOAN FOR NLS (NEW COLOUMN FOR NLS OPEN)
	openLoanMeta(loan_number: number) {
		this.apiService.searchNLSLoan(loan_number).subscribe((res: any[]) => {
			if (res.length == 0) {
				alert('Loan Number cannot be found on NLS');
			} else {
				this.apiService.sendMessage(res);
				this.router.navigate(['loan-meta']);
			}
		});
	}
}
