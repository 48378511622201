<div class="container-fluid">
    <div *ngIf="validated_result">
        <div class="row" style="padding-left: 20px;">
            <h5>Loan Validation</h5>
            <div *ngIf="confirmation_message">
                <h3>Zoho Application has successfully been uploaded to NLS</h3>
            </div>
            <div *ngIf="validation_errors.length===0" style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div *ngIf="showUploadClients">
                    <button type="button" class="btn btn-success btn-lg" (click)="onBoardClientsToNLS()">On Board
                        Clients to
                        NLS</button>
                </div>
                <div *ngIf='showUploadLoan'>
                    <button type="button" class="btn btn-success btn-lg" (click)="onBoardLoanToNLS()">On Board Loan to
                        NLS</button>
                </div>
                <div *ngIf='showUploadRecurring'>
                    <button type="button" class="btn btn-success btn-lg" (click)="onBoardRecurringToNLS()">On Board
                        Recurring
                        Transactions to
                        NLS</button>
                </div>
            </div>

            <div *ngIf='validation_errors.length!=0' style="margin-top: 10px; color: red;">
                <table>
                    <thead>
                        <tr>
                            <th>Zoho Errors</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let error of validation_errors">
                            <td>{{error}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf='NLS_errors_flag' style="margin-top: 10px; color: red;">
                <table>
                    <thead>
                        <tr>
                            <th>NLS Errors</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let error of NLS_errors">
                            <td>{{error.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div *ngIf='validated_result' style="padding-left: 30px;">
                <table class="table table-bordered">
                    <tr>
                        <td><strong>App_Number</strong>:{{validated_result[2].App_Number}}</td>
                        <td><strong>Application ID</strong>:{{validated_result[2].application_id}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>L7</strong>:{{validated_result[2].L7}}</td>
                        <td><strong>ResidentialStatus</strong>:{{validated_result[2].ResidentialStatus}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>SIN</strong>:{{validated_result[2].SIN}}</td>
                        <td><strong>APR</strong>:{{validated_result[2].apr}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>abaNumber</strong>:{{validated_result[2].abaNumber}}</td>
                        <td><strong>billingCutoff</strong>:{{validated_result[2].billingCutoff}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>Amount</strong>:{{validated_result[2].Amount}}</td>
                        <td><strong>Name</strong>:{{validated_result[2].Name}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>First Name</strong>:{{validated_result[2].First_Name}}
                        <td><strong>Last Name</strong>:{{validated_result[2].Last_Name}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>Phone</strong>:{{validated_result[2].Phone}}</td>
                        <td><strong>DOB</strong>:{{validated_result[2].DOB}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>Loan_Date</strong>:{{validated_result[2].Loan_Date}}</td>
                        <td><strong>PaymentAmount</strong>:{{validated_result[2].PaymentAmount}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>FirstPaymentDate</strong>:{{validated_result[2].FirstPaymentDate}}</td>
                        <td><strong>MaturityDate</strong>:{{validated_result[2].MaturityDate}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>RecentNote</strong>:{{validated_result[2].RecentNote}}</td>
                        <td><strong>Address</strong>:{{validated_result[2].Address}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>Country</strong>:{{validated_result[2].Country}}</td>
                        <td><strong>Province</strong>:{{validated_result[2].Province}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>City</strong>:{{validated_result[2].City}}</td>
                        <td><strong>Postal_Code</strong>:{{validated_result[2].Postal_Code}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>Email</strong>:{{validated_result[2].Email}}</td>
                        <td><strong>DriveID</strong>:{{validated_result[2].DriveID}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>hasLPP</strong>:{{validated_result[2].hasLPP}}</td>
                        <td><strong>LPPCost</strong>:{{validated_result[2].LPPCost}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>LPPTax</strong>:{{validated_result[2].LPPTax}}</td>
                        <td><strong>creditor</strong>:{{validated_result[2].creditor}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>supervisorName</strong>:{{validated_result[2].supervisorName}}</td>
                        <td><strong>supervisorNumber</strong>:{{validated_result[2].supervisorNumber}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>driveURL</strong>:{{validated_result[2].driveURL}}</td>
                        <td><strong>transitNumber</strong>:{{validated_result[2].transitNumber}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>bankAccountNumber</strong>:{{validated_result[2].bankAccountNumber}}</td>
                        <td><strong>finNumber</strong>:{{validated_result[2].finNumber}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>frequency</strong>:{{validated_result[2].frequency}}</td>
                        <td><strong>SIN</strong>:{{validated_result[2].SIN}}</td>
                        <td colspan="2" class="noBorder"></td>
                    </tr>
                    <tr>
                        <td><strong>coAppRequired</strong>:{{validated_result[2].coAppRequired}}</td>
                        <td><strong>position</strong>:{{validated_result[2].position}}</td>
                        <td colspan="2" style="border-color: white white #ddd white; visibility: collapse;">
                    </tr>
                    <tr>
                        <td><strong>apr</strong>:{{validated_result[2].apr}}</td>
                        <td><strong>cuFee</strong>:{{validated_result[2].cuFee}}</td>
                        <td colspan="2"><strong>Co-applicant Employment
                                Type</strong>:{{validated_result[2].coAppEmploymentType}}
                        </td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>CoAppEmail</strong>:{{validated_result[2].CoAppEmail}}</td>
                        <td><strong>coAppAddress</strong>:{{validated_result[2].coAppAddress}}</td>
                        <td colspan="2"><strong>Co-applicant Employer</strong>:{{validated_result[2].coAppEmployer}}
                        </td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>coAppCity</strong>:{{validated_result[2].coAppCity}}</td>
                        <td><strong>coAppCountry</strong>:{{validated_result[2].coAppCountry}}</td>
                        <td colspan="2"><strong>Co-applicant Occupation</strong>:{{validated_result[2].coAppoccupation}}
                        </td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>coAppCreditScore</strong>:{{validated_result[2].coAppCreditScore}}</td>
                        <td><strong>coAppDOB</strong>:{{validated_result[2].coAppDOB}}</td>
                        <td colspan="2"><strong>Co-applicant Supervisor
                                Name</strong>:{{validated_result[2].coAppSupervisorName}}
                        </td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>coAppFirstName</strong>:{{validated_result[2].coAppFirstName}}</td>
                        <td><strong>coAppLastName</strong>:{{validated_result[2].coAppLastName}}</td>
                        <td colspan="2"><strong>Co-applicant Supervisor
                                Number</strong>:{{validated_result[2].coAppSupervisorNumber}}</td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>coAppNumber</strong>:{{validated_result[2].coAppNumber}}</td>
                        <td><strong>coAppPostCode</strong>:{{validated_result[2].coAppPostCode}}</td>
                        <td colspan="2"><strong>Co-applicant Annual
                                Income</strong>:{{validated_result[2].coAppAnnualIncome}}
                        </td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>coAppProvince</strong>:{{validated_result[2].coAppProvince}}</td>
                        <td><strong>coAppResidential</strong>:{{validated_result[2].coAppResidential}}</td>
                        <td colspan="2"><strong>Co-applicant Emergency
                                Name</strong>:{{validated_result[2].coAppEmergencyName}}
                        </td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>coUnderwriting</strong>:{{validated_result[2].coUnderwriting}}</td>
                        <td><strong>contacts</strong>:{{validated_result[2].contacts}}</td>
                        <td colspan="2"><strong>Co-applicant Emergency
                                Number</strong>:{{validated_result[2].coAppEmergencyNumber}}</td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <td><strong>creditScore</strong>:{{validated_result[2].creditScore}}</td>
                        <td><strong>frequency</strong>:{{validated_result[2].frequency}}</td>
                        <td colspan="2"><strong>Co-applicant
                                Relation</strong>:{{validated_result[2].coAppEmergencyRelation}}</td>
                        <!-- <td colspan="2" class="noBorder"></td> -->
                    </tr>
                    <tr>
                        <!-- <td><strong>noteRate</strong>:{{validated_result[2].noteRate}}</td> -->
                        <td><strong>noteRate</strong>:{{validated_result[2].apr}}</td>
                        <td><strong>tdsr</strong>:{{validated_result[2].tdsr}}</td>
                        <td colspan="2"><strong>Co-applicant Email</strong>:{{validated_result[2].coAppEmergencyEmail}}
                        </td>
                        <!-- <td colspan="2" style="border-color: white white #ddd white; visibility: collapse;"></td> -->
                    </tr>
                    <tr>
                        <td><strong>Phone Number</strong>:{{validated_result[2].Phone}}</td>
                        <td><strong>Mobile Number</strong>:{{validated_result[2].Mobile}}</td>
                        <td><strong>Application
                                Owner</strong>:{{validated_result[2].Application_Owner}}</td>
                        <td><strong>Exception Funded
                                Amount</strong>:{{validated_result[2].Exception_Funded_Amount}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>