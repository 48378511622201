import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-breakdown-board',
  templateUrl: './payment-breakdown-board.component.html',
  styleUrls: ['./payment-breakdown-board.component.scss']
})
export class PaymentBreakdownBoardComponent {

}
