import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
interface Comment_Type {
	value: string;
	viewValue: string;
}
interface Disposition {
	id: string;
	name: string;
}
@Component({
	selector: 'app-comment-form',
	templateUrl: './comment-form.component.html',
	styleUrls: ['./comment-form.component.scss'],
})
export class CommentFormComponent implements OnInit {
	comment_types: Comment_Type[] = [
		{ value: 'Regular', viewValue: 'Regular' },
		{ value: 'Email Notes', viewValue: 'Email Notes' },
	];
	dispositions: Disposition[] = [
		{ id: 'RIGHT PARTY CONTACT', name: 'RIGHT PARTY CONTACT' },
		{ id: 'WRONG NUMBER', name: 'WRONG NUMBER' },
		{ id: 'THIRD PARTY CONTACT', name: 'THIRD PARTY CONTACT' },
		{ id: 'BANKO/CCS', name: 'BANKO/CCS' },
		{ id: 'LEFT MESSAGE WITH 3RD PARTY', name: 'LEFT MESSAGE WITH 3RD PARTY' },
		{ id: 'LEFT MESSAGE WITH RPC', name: 'LEFT MESSAGE WITH RPC' },
		{ id: 'Hung up/ refusal to pay', name: 'Hung up/ refusal to pay' },
		{ id: 'NO ANSWER', name: 'NO ANSWER' },
		{ id: 'NO SERVICE', name: 'NO SERVICE' },
		{ id: 'ARRANGMENT MADE', name: 'ARRANGMENT MADE' },
		{ id: 'SKIP', name: 'SKIP' },
	];
	commentInfo: any;
	userID: any;
	constructor(
		public dialogRef: MatDialogRef<CommentFormComponent>,
		public datepipe: DatePipe,
		private apiService: ApiServiceService,
		private dialog: MatDialog,
		private router: Router
	) {}

	ngOnInit(): void {
		this.userID = this.apiService.getSignInInfo()?.[0]?.['email'];
		this.commentInfo = Object.assign({}, this.apiService.getMessage());
		console.log(this.commentInfo, 'CommentForm');
	}
	deleteComment() {
		if (this.commentInfo.nls_data == 1) alert('Cannot Delete an NLS Note');
		else if (confirm('Are you sure you want to delete this Note?')) {
			this.apiService
				.deleteComment(this.commentInfo.comment_id, this.commentInfo.acctrefno)
				.subscribe((res) => {
					alert(res['message']);
					this.apiService.sendClickEvent();
					this.dialogRef.close();
				});
		}
	}
	updateComment() {
		if (this.commentInfo.nls_data == 1) alert('Cannot Update an NLS Note');
		else if (confirm('Are you sure you want to update this Note?')) {
			// this.apiService.updateComment(this.commentInfo, this.userID).subscribe((res) => {
			this.apiService.updateComment(this.commentInfo).subscribe((res) => {
				alert(res['message']);
				this.apiService.sendClickEvent();
				this.dialogRef.close();
			});
		}
	}
}
