import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { CustomFunctionService } from '../../custom-function.service';
import { MapsAPILoader } from '@agm/core'; // Auto Addressing
import { MatDialog } from '@angular/material/dialog';
import { MonthlyincomecalcComponent } from '../../monthlyincomecalc/monthlyincomecalc.component';
import { Functions } from '@lmc-models/commonFunctions';
import { LookUps } from '@lmc-models/lookUps';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';

@Component({
	selector: 'app-loan-interview',
	templateUrl: './loan-interview.component.html',
	styleUrls: ['./loan-interview.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class LoanInterviewComponent implements OnInit, OnChanges, OnDestroy {
	// @Input() zohoApplicationID: any;
	// @Input() co_app_required: any;

	zohoApplicationID: any;
	co_app_required: any;

	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;

	// User login information
	loggedName: any;
	loggedZohoID: any;
	userRole: any;
	userName: any;

	Deal_Name: any;
	displayMonthlyIncome: any;
	totalMonthNumbers: any;
	totalMonthlyIncome: any;
	totalSubformAdditional: any;
	tdsr: any;
	provinceName: any;

	underwritingID: any;

	newAddExpenses: any;
	subjects: any;
	questionBanks: any;

	applicationType: any;

	//Timer
	counter: number;
	timerRef;
	running: boolean = false;
	startText = 'Start';
	display_time = '';
	timeInfo;
	//Read Only Flag
	readOnly = true;

	// ltCurrentRoute: any;   //  Loan Interview route

	// Result from income calculator
	income_calc_result: any;

	// Lookups
	stageName: any;
	creditorName: any;
	primaryEmpStatus: any;
	primaryPayFrequency: any;
	primaryIndustry: any;
	primaryOccupation: any;
	coAppEmpStatus: any;
	coAppIndustry: any;
	coAppOccupation: any;

	// Get Agency Records for Additional Subform
	agencyName: any;

	// Hide the notes and stage
	show_notes_stage = false;

	yes_interview = false; // For autopopulate Edit
	spinner = false; // Loader

	//Messages
	errorMessageLog: any[];
	errorMessage: any;

	// Autopopulate Expenses
	expense_list: any;

	provinceList = [
		{ refValue: 'ON', picklistValue: 'Ontario' },
		{ refValue: 'QC', picklistValue: 'Quebec' },
		{ refValue: 'NS', picklistValue: 'Nova Scotia' },
		{ refValue: 'NB', picklistValue: 'New Brunswick' },
		{ refValue: 'MB', picklistValue: 'Manitoba' },
		{ refValue: 'BC', picklistValue: 'British Columbia' },
		{ refValue: 'PEI', picklistValue: 'Prince Edward Island' },
		{ refValue: 'SK', picklistValue: 'Saskatchewan' },
		{ refValue: 'AB', picklistValue: 'Alberta' },
		{ refValue: 'NL', picklistValue: 'Newfoundland and Labrador' },
		{ refValue: 'NT', picklistValue: 'Northwest Territories' },
		{ refValue: 'NU', picklistValue: 'Nunavut' },
		{ refValue: 'YT', picklistValue: 'Yukon' },
	];

	// Primary
	// Underwriting Expenses Form
	underwritingExForm = new UntypedFormGroup({
		Payment_Method: new UntypedFormControl(''),
		Next_Pay_Date: new UntypedFormControl(''),
		Last_Pay_Date: new UntypedFormControl(''),
		Review_any_payday_loan_usage: new UntypedFormControl(''),
		Payday_Loans_Comments: new UntypedFormControl(''),
		Overdraft_Limit: new UntypedFormControl(0),
		Is_this_a_joint_Account: new UntypedFormControl(''),
		Other_Bank_Account: new UntypedFormControl(''),
		When_was_the_last_time_you_filed_taxes: new UntypedFormControl(''),
		How_much_do_you_owe_Revenue_Canada: new UntypedFormControl(''),
		Have_you_setup_an_arrangement_with_the_CRA: new UntypedFormControl(''),
		Reason_for_falling_behind: new UntypedFormControl(''),
		Do_you_live_with_anyone_else: new UntypedFormControl(''),
		Dependents: new UntypedFormControl(''),
		are_u_homeowner: new UntypedFormControl(''),
		property_taxes: new UntypedFormControl(''),
		mortgage_property: new UntypedFormControl(''),
		mortgage_with: new UntypedFormControl(''),
		property_value: new UntypedFormControl(''),
		mortgage_balance: new UntypedFormControl(''),
		mortgage_interest: new UntypedFormControl(''),
		// 'uw_tier': new FormControl(''),
		Yes_No_Is_this_a_joint_Account: new UntypedFormControl(''),
		Overdraft_Limit_Question: new UntypedFormControl(''),
		Financial_Institution_Name: new UntypedFormControl(''),
		Financial_Institution_Number: new UntypedFormControl(''),
		Bank_Account_Number: new UntypedFormControl(''),
		Bank_Transit_No: new UntypedFormControl(''),
		Branch_Address: new UntypedFormControl(''),

		dob: new UntypedFormControl(''),
		unit: new UntypedFormControl(''),
		address: new UntypedFormControl(''),
		city: new UntypedFormControl(''),
		province: new UntypedFormControl(''),
		postalCode: new UntypedFormControl(''),
		creditt: new UntypedFormControl(''),
		accountBalance: new UntypedFormControl(''),
		agencies: new UntypedFormControl(''),
		jobStatus: new UntypedFormControl(''),
		company: new UntypedFormControl(''),
		position: new UntypedFormControl(''),
		empLength: new UntypedFormControl(''),
		salary: new UntypedFormControl(''),
		frequency: new UntypedFormControl(''),
		industry: new UntypedFormControl(''),
		occupation: new UntypedFormControl(''),

		totalMonthSubform: new UntypedFormControl(0),
		currentLoanSubform: new UntypedFormControl(0),
		ADDITIONAL_EXPENSES: this.fb.array([]), // Subform
	});

	// Loan Interview Notes
	loanInterviewNote = new UntypedFormGroup({
		subject: new UntypedFormControl('', Validators.required),
		description: new UntypedFormControl(''),
		communication: new UntypedFormControl('INT'),
		note: new UntypedFormControl('', Validators.required),
	});

	// Loan Interview Stage
	loanInterviewStage = new UntypedFormGroup({
		Stage: new UntypedFormControl(''),
	});

	functions = new Functions();
	lookUps = new LookUps(this.customFunction);

	summary$: Observable<any>;
	@Input() summaryData: any;
	userData: any;

	constructor(
		private store: Store,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		private apiService: ApiServiceService,
		private router: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private customFunction: CustomFunctionService,
		private mapsAPILoader: MapsAPILoader,
		private route: Router,
		public dialog: MatDialog
	) {}

	async ngOnInit() {
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				(summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.summaryData = summaryData;
						this.co_app_required = summaryData.Co_Appl_Required;
						this.getLookups();
						this.getAgencyRecords();
					}
				}
			);
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			this.userData = userData;
			this.userName = userData['email']; // Get the email address to save in the search history
			this.loggedName = userData['full_name'];
			this.loggedZohoID = userData['zoho_owner_id'];
			this.userRole = userData['position'];
			this.tdsr = 0;
			this.totalMonthlyIncome = 0;

			this.applicationType = '';
		});
		document.getElementById('loanInterviewDisplay').style.display = 'none';
		this.errorMessageLog = [];
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.summaryData) {
			if (changes.summaryData?.currentValue) {
				const summaryData = changes.summaryData?.currentValue;
				this.zohoApplicationID = summaryData.id;
				this.summaryData = summaryData;
				this.co_app_required = summaryData.Co_Appl_Required;
				// if (!this.currentRoute || this.currentRoute === 'loaninterview') {
				this.getLookups();
				this.getAgencyRecords();
			}
		}
	}

	switchInterview(e) {
		// this.ltCurrentRoute = e.target.value;
		this.resetPrimaryLoanInterview();
		if (e === 'Primary' || e === 'Co-Applicant') {
			this.spinner = true;
			this.yes_interview = true;
			this.applicationType = e;
			this.loadApplicationInfo();
			// this.populateUnderwritingSubform();
			document.getElementById('loanInterviewDisplay').style.display = 'block';
			this.editToStartTimer();
			// this.tdsr = 0;
			// this.totalMonthlyIncome = 0;
		}
	}

	selectedItem: any;
	hideMessageOnLoad() {
		document.getElementById('saveMessage').style.display = 'none';
		// document.getElementById('successfulNote').style.display = 'none';    // Note Primary
		// document.getElementById('jointAcctPrimary').style.display = 'none';   // Joint Accountv
		// document.getElementById('overdraftPrimary').style.display = 'none';   // Joint Accountv
		// document.getElementById('interviewHomeOwner').style.display = 'none' // Hide the Interview questions for Homeowner
		// document.getElementById('paydayCommentsPrimaryText').style.display = 'none';
		// document.getElementById('successfulStage').style.display = 'none';
	}

	/**
	 * Load Deals/Applications and Underwriting Information on Summary Report
	 */
	async loadApplicationInfo() {
		return new Promise((resolve, reject) => {
			// Load the Application Information

			// this.getLookups();
			// this.getAgencyRecords();
			this.apiService
				.getSingleAppSummary(this.zohoApplicationID)
				.subscribe(async(res) => {
					if (res['message']['statusCode'] == '200') {
						this.spinner = false;
						let arr = JSON.parse(res.message.body);
						// console.log(arr)

						if (arr.data[0].Account_Name != null) {
							this.underwritingExForm.patchValue({
								agencies: arr.data[0].Account_Name['name'],
							});
						}

						this.underwritingExForm.patchValue({
							creditt: arr.data[0].Creditt,
							accountBalance: arr.data[0].Account_Bal,
							currentLoanSubform: arr.data[0].Scheduled_Payments_Monthly,
						});

						let stage = this.customFunction.singleFilterName(
							this.stageName,
							'display_value',
							arr.data[0]['Stage']
						);
						this.loanInterviewStage.patchValue({
							Stage: stage[0]['id'],
						});

						if (this.applicationType === 'Primary') {
							this.Deal_Name = arr.data[0].Deal_Name;

							this.underwritingID = arr.data[0].Underwriting_ID;

							this.provinceName = arr['data'][0]['Province1'];

							this.underwritingExForm.patchValue({
								dob: arr.data[0].Date_of_Birth,
								unit: arr.data[0].Address_Line_2,
								address: arr.data[0].Address1,
								city: arr.data[0].City,
								province: arr.data[0].Province1,
								postalCode: arr.data[0].Postal_Code,
								jobStatus: arr.data[0].Employment_Status1,
								company: arr.data[0].Employer,
								position: arr.data[0].Position,
								empLength: arr.data[0].Length_of_Employment,
								salary: arr.data[0].Income_net_paycheque_income,
								frequency: arr.data[0].Pay_Frequency2,
								industry: arr.data[0].Industry,
								occupation: arr.data[0].Occupation,
								Financial_Institution_Name:
									arr.data[0].Financial_Institution_Name,
								Financial_Institution_Number:
									arr.data[0].Financial_Institution_Number,
								Bank_Account_Number: arr.data[0].Bank_Account_Number,
								Bank_Transit_No: arr.data[0].Bank_Transit_No,
								Branch_Address: arr.data[0].Branch_Address,
							});
							this.underwritingExForm
								.get('industry')
								.setValue(arr.data[0].Industry);

							this.selectedItem = arr.data[0].Industry;

							// document.getElementById('onlyPrimaryQuestion').style.display = 'block';
							// document.getElementById('onlyCoAppQuestion').style.display = 'none';
							// this.hideMessageOnLoad();
						}

						if (this.applicationType === 'Co-Applicant') {
							this.Deal_Name =
								arr.data[0].Co_Applicant_Name +
								' ' +
								arr.data[0].Guarantor_Name;
							this.co_app_required = arr.data[0].Co_Appl_Required;

							this.provinceName = arr['data'][0]['Co_Applicant_Province'];

							this.underwritingID = arr.data[0].Underwriting_ID_Co_Applicant;

							this.underwritingExForm.patchValue({
								dob: arr.data[0].Co_Applicant_Date_of_Birth,
								unit: arr.data[0].Co_Applicant_Address_Line_2_Unit_number,
								address: arr.data[0].Co_Applicant_Property_Address,
								city: arr.data[0].Co_Applicant_City,
								province: arr.data[0].Co_Applicant_Province,
								postalCode: arr.data[0].Co_Applicant_Postal_Code,
								jobStatus: arr.data[0].Co_Applicant_Status,
								company: arr.data[0].Co_Applicant_Employer,
								position: arr.data[0].Secondary_Position,
								empLength: arr.data[0].Secondary_Years_Worked,
								salary: arr.data[0].Sec_Annual_Income,
								frequency: arr.data[0].Co_Applicant_Pay_Frequency,
								industry: arr.data[0].Co_Applicant_Industry,
								occupation: arr.data[0].Co_Applicant_Occupation,
								Financial_Institution_Name:
									arr.data[0].Co_Applicant_Financial_Institution_Name,
								Financial_Institution_Number:
									arr.data[0].Co_ApplicantFinancial_Institution_Number1,
								Bank_Account_Number: arr.data[0].Co_Applicant_Bank_Account,
								Bank_Transit_No: arr.data[0].Co_Applicant_Bank_Transit_No,
								Branch_Address: arr.data[0].Co_Applicant_Branch_Address,
							});

							// document.getElementById('onlyCoAppQuestion').style.display = 'block';
							// document.getElementById('onlyPrimaryQuestion').style.display = 'none';
							// this.hideMessageOnLoad();
						}

						let result = await this.populateUnderwritingSubform(); // Populate the Interview questions Subform
						this.loadSubject();

						resolve(true);
					} else {
						console.log('Deals Error!');
						resolve(false);
					}
				});
		});
	}

	onItemSelect(item) {
		this.selectedItem = item;
	}

	jointAcctPrimary(e) {
		if (e.target.value === 'Yes') {
			// document.getElementById('jointAcctPrimary').style.display = 'block'
		} else {
			// document.getElementById('jointAcctPrimary').style.display = 'none'
			this.underwritingExForm.get('Is_this_a_joint_Account').reset();
		}
	}

	overdraftPrimary(e) {
		if (e.target.value === 'Yes') {
			// document.getElementById('overdraftPrimary').style.display = 'block'
		} else {
			// document.getElementById('overdraftPrimary').style.display = 'none'
			this.underwritingExForm.get('Overdraft_Limit').reset();
		}
	}

	// ----------------------UNDERWRITING LOAN INTERVIEW PRIMARY-----------------------------------------------------------------------------------
	interviewHomeOwner(e) {
		if (e.target.value === 'Yes') {
			// document.getElementById('interviewHomeOwner').style.display = 'block';
		} else {
			// document.getElementById('interviewHomeOwner').style.display = 'none';
			this.underwritingExForm.get('property_taxes').reset();
			this.underwritingExForm.get('mortgage_property').reset();
			this.underwritingExForm.get('mortgage_with').reset();
			this.underwritingExForm.get('property_value').reset();
			this.underwritingExForm.get('mortgage_balance').reset();
			this.underwritingExForm.get('mortgage_interest').reset();
		}
	}

	paydayCommentsPrimary(e) {
		if (e.target.value === 'Yes') {
			// document.getElementById('paydayCommentsPrimaryText').style.display = 'block'
		} else {
			// document.getElementById('paydayCommentsPrimaryText').style.display = 'none'
			this.underwritingExForm.get('Payday_Loans_Comments').reset();
		}
	}

	underwritingPool: any;
	/* SUB FORM of ADDITIONAL DEBTS */
	addUnderwitingSubform() {
		this.underwritingPool = [];
		this.underwritingPool = this.underwritingExForm.get(
			'ADDITIONAL_EXPENSES'
		) as UntypedFormArray;
		this.newAddExpenses = this.fb.group({
			questions_subform: '',
			Type: '',
			Name1: '',
			Amount: '0',
		});

		this.underwritingPool.push(this.newAddExpenses);
	}

	// To fetch and populate the records that are present into the subform
	async populateUnderwritingSubform() {
		return new Promise((resolve,reject)=>{
			this.apiService.getInterviewQuestions().subscribe((res) => {
				if (res.message === 'Success'){				
					this.questionBanks = res.data;
	
					// Push the values into subform array
					this.underwritingPool = this.underwritingExForm.get(
						'ADDITIONAL_EXPENSES'
					) as UntypedFormArray;
	
					let listArray = this.underwritingExForm.get(
						'ADDITIONAL_EXPENSES'
					) as UntypedFormArray;
					while (listArray.length !== 0) {
						listArray.removeAt(0);
					}
					if (
						this.provinceName == 'AB' ||
						this.provinceName == 'QC' ||
						this.provinceName == 'BC' ||
						this.provinceName == 'ON' ||
						this.provinceName == 'Alberta' ||
						this.provinceName == 'Quebec' ||
						this.provinceName == 'British Columbia' ||
						this.provinceName == 'Ontario'
					) {
						for (let i = 0; i < this.questionBanks.length; i++) {
							this.underwritingPool.push(
								this.fb.group({
									questions_subform: this.questionBanks[i].question_id,
									Type: this.questionBanks[i].expenses_type,
									Name1: this.questionBanks[i].creditor_name,
									Amount: this.questionBanks[i].amount,
								})
							);
						}
						this.totalSubformAdditional = this.customFunction.sumNumbers(
							res.data,
							'amount'
						);
					} else {
						for (let i = 0; i < this.questionBanks.length; i++) {
							this.underwritingPool.push(
								this.fb.group({
									questions_subform: this.questionBanks[i].question_id,
									Type: this.questionBanks[i].expenses_type,
									Name1: this.questionBanks[i].creditor_name,
									Amount: this.questionBanks[i].amount1,
								})
							);
						}
						this.totalSubformAdditional = this.customFunction.sumNumbers(
							res.data,
							'amount1'
						);
					}
					resolve(true);
				}else{
					resolve(false);
				}
			});
		})		
	}

	// Remove expenses
	removeSubformExpenses(i) {
		let listArray = this.underwritingExForm.get(
			'ADDITIONAL_EXPENSES'
		) as UntypedFormArray;
		listArray.removeAt(i);
		this.sumAmount();
	}

	// Event calculation
	addSubformAmount() {
		document.addEventListener('click', (e) => {
			this.sumAmount();
		});
	}
	addSubformAmountEnter(e) {
		if (e.key === 'Enter') {
			this.sumAmount();
		}
	}

	// Sum the amounts on subform for additional expenses
	sumAmount() {
		this.totalSubformAdditional = this.underwritingExForm
			.get('ADDITIONAL_EXPENSES')
			.value.map((item) => item.Amount)
			.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr));
		this.totalMonthSubform();
	}

	// event calculation
	clickedTotalMonthSubform() {
		// document.addEventListener("click", e => {
		this.totalMonthSubform();
		// })
	}
	clickedCurrentLoanSubform() {
		// document.addEventListener("click", e => {
		this.currentLoanSubform();
		// })
	}

	enteredTotalMonthSubform(e) {
		if (e.key === 'Enter') {
			this.totalMonthSubform();
		}
	}
	enteredCurrentLoanSubform(e) {
		if (e.key === 'Enter') {
			this.currentLoanSubform();
		}
	}

	// Calculate the tdsr on Total Monthly Income changes
	totalMonthSubform() {
		let current;
		if (
			this.underwritingExForm.get('currentLoanSubform').value != '' &&
			this.underwritingExForm.get('currentLoanSubform').value != null &&
			this.underwritingExForm.get('currentLoanSubform').value != 0
		) {
			current = this.underwritingExForm.get('currentLoanSubform').value;
		} else {
			current = 0;
		}

		if (
			this.underwritingExForm.get('totalMonthSubform').value != 0 &&
			this.underwritingExForm.get('totalMonthSubform').value != '' &&
			this.underwritingExForm.get('totalMonthSubform').value != null
		) {
			this.tdsr = (
				((this.totalSubformAdditional + parseFloat(current)) /
					parseFloat(this.underwritingExForm.get('totalMonthSubform').value)) *
				100
			).toFixed();
		} else {
			this.tdsr = 0;
		}
	}

	// Calculate the tdsr on Current Loan Amount Payments (Monthly) changes
	currentLoanSubform() {
		let current = this.underwritingExForm.get('currentLoanSubform').value;

		if (current != '' && current != null) {
			if (
				this.underwritingExForm.get('totalMonthSubform').value != 0 &&
				this.underwritingExForm.get('totalMonthSubform').value != '' &&
				this.underwritingExForm.get('totalMonthSubform').value != null
			) {
				this.tdsr = (
					((this.totalSubformAdditional + parseFloat(current)) /
						parseFloat(
							this.underwritingExForm.get('totalMonthSubform').value
						)) *
					100
				).toFixed();
			} else {
				this.tdsr = 0;
			}
		}
	}

	//Push to ZOHO to save the files
	// async pushZoho() {
	//   if (this.userRole === 'UW') {

	//     (<HTMLInputElement>document.getElementById('saveLoanInterviewButton')).disabled = true;
	//     document.getElementById('saveMessage').style.display = 'block';
	//     document.getElementById('saveMessage').innerHTML = "Save in progress...";
	//     document.getElementById('saveMessage').style.color = 'orange';

	//     if (await this.insertLocalUnderwritingPrimary()) {

	//       this.apiService.updateLoanInterview(this.underwritingExForm.value, this.underwritingID, this.loggedZohoID).subscribe((res) => {
	//         console.log(res, "*******************************************")
	//         if (res.message.statusCode == '200') {
	//           this.show_notes_stage = true;
	//           // Push interviewer name of Primary to ZOHO
	//           this.apiService.zohoInterviewerApplication(this.underwritingExForm.value, this.zohoApplicationID, this.loggedName, this.tdsr, this.applicationType).subscribe((res) => {
	//             if (!(res['message']['statusCode'] == '200')) {
	//               console.log("Zoho Interviewer Update fail!")
	//             } else {
	//               this.apiService.sendClickEvent()
	//               this.loadApplicationInfo();

	//             }
	//           })

	//           this.clearTimer(this.zohoApplicationID, this.applicationType);
	//           this.resetPrimaryLoanInterview();
	//           this.autoPopulateLoanInterview(this.zohoApplicationID, this.applicationType);

	//           (<HTMLInputElement>document.getElementById('saveLoanInterviewButton')).disabled = false;
	//           document.getElementById('saveMessage').innerHTML = "Loan Interview Successful!"
	//           document.getElementById('saveMessage').style.color = 'green';
	//           document.getElementById('saveMessage').style.display = 'block';

	//         } else {
	//           (<HTMLInputElement>document.getElementById('saveLoanInterviewButton')).disabled = true;
	//           document.getElementById('saveMessage').innerHTML = "ZOHO: Save unsuccessful!"
	//           document.getElementById('saveMessage').style.color = 'red';
	//           document.getElementById('saveMessage').style.display = 'block'
	//         }

	//       })
	//     } else {
	//       console.log("Insert failed on Local Database!");
	//       (<HTMLInputElement>document.getElementById('saveLoanInterviewButton')).disabled = true;
	//       document.getElementById('saveMessage').innerHTML = "Insert failed on Local Database!"
	//       document.getElementById('saveMessage').style.color = 'red';
	//       document.getElementById('saveMessage').style.display = 'block'
	//     }
	//   } else {
	//     (<HTMLInputElement>document.getElementById('saveLoanInterviewButton')).disabled = true;
	//     document.getElementById('saveMessage').innerHTML = "Save not allowed. Contact IT!"
	//     document.getElementById('saveMessage').style.color = 'red';
	//     document.getElementById('saveMessage').style.display = 'block'
	//   }
	// }

	resetPrimaryLoanInterview() {
		this.underwritingExForm.reset();
		this.totalSubformAdditional = '';
		this.displayMonthlyIncome = '';
		this.totalMonthNumbers = '';
		this.totalMonthlyIncome = 0;
		this.tdsr = 0;
		this.hideMessageOnLoad();
		this.show_notes_stage = false;
		// this.populateUnderwritingSubform()
	}

	loadSubject() {
		this.apiService.getNoteSubjects().subscribe((res) => {
			if (res.message == 'Success') {
				this.subjects = res.data;
			}
		});
	}

	matchSubject(e) {
		this.apiService.getSubjectDesc(e.target.value).subscribe((res) => {
			if (res.message == 'Success') {
				try {
					this.loanInterviewNote.patchValue({
						description: res.data[0]['description'],
					});
				} catch (e) {
					this.loanInterviewNote.patchValue({
						description: '',
					});
				}
			}
		});
	}

	saveLoanInterviewNotes() {
		if (this.userRole === 'UW') {
			(<HTMLInputElement>document.getElementById('saveLTNoteButton')).disabled =
				true;
			document.getElementById('successfulNote').innerHTML =
				'Save in progress...';
			document.getElementById('successfulNote').style.color = 'orange';
			document.getElementById('successfulNote').style.display = 'block';
			// Insert on local platform
			this.apiService
				.addLoanInterviewNotesLocal(
					this.loanInterviewNote.value,
					this.zohoApplicationID,
					this.loggedZohoID,
					this.Deal_Name,
					this.loggedName
				)
				.subscribe((res) => {
					if (res.message == 'Success') {
						(<HTMLInputElement>(
							document.getElementById('saveLTNoteButton')
						)).disabled = false;
						document.getElementById('successfulNote').innerHTML =
							'Save successful!';
						document.getElementById('successfulNote').style.color = 'green';
						document.getElementById('successfulNote').style.display = 'block';
						let app_note_id = '';
						try {
							app_note_id = res['data'][0]['app_note_id'];
						} catch (err) {
							app_note_id = '';
						}
						this.apiService
							.addLoanInterviewNotesZOHO(
								this.loanInterviewNote.value,
								this.zohoApplicationID,
								this.loggedZohoID
							)
							.subscribe((res) => {
								let zoho_note_id = res['app_id'];
								// console.log(zoho_note_id)
								// console.log(app_note_id)
								this.apiService
									.updateNoteIDLocal(app_note_id, zoho_note_id)
									.subscribe((res) => {
										console.log(res);
									});
							});
					} else {
						(<HTMLInputElement>(
							document.getElementById('saveLTNoteButton')
						)).disabled = false;
						document.getElementById('successfulNote').innerHTML =
							'Platform: Save unsuccessful!';
						document.getElementById('successfulNote').style.color = 'red';
						document.getElementById('successfulNote').style.display = 'block';
					}
				});
		} else {
			(<HTMLInputElement>document.getElementById('saveLTNoteButton')).disabled =
				false;
			document.getElementById('successfulNote').innerHTML =
				'Save not allowed. Contact IT!';
			document.getElementById('successfulNote').style.color = 'red';
			document.getElementById('successfulNote').style.display = 'block';
		}
	}

	editToStartTimer() {
		this.timeInfo = {
			applicationID: this.zohoApplicationID,
			applicantType: this.applicationType,
			duration: 0,
			underwriter: this.loggedName,
			start_date: new Date(),
			end_date: '',
			action: '',
		};
		this.readOnly = false;
		this.running = !this.running;
		if (this.running) {
			this.startText = 'Stop';
			const startTime = Date.now() - (this.counter || 0);
			this.timerRef = setInterval(() => {
				this.counter = Date.now() - startTime;
				this.display_time = this.editTime(this.counter);
			});

			// document.getElementById('stopButton').style.display = 'block';
			// document.getElementById('playButton').style.display = 'none';
		} else {
			this.startText = 'Resume';
			clearInterval(this.timerRef);
			// document.getElementById('stopButton').style.display = 'none';
			// document.getElementById('playButton').style.display = 'block';
		}
	}
	editTime(milliseconds) {
		return new Date(milliseconds).toISOString().slice(11, 19);
	}

	clearTimer(applicationID: any, applicantType: any) {
		this.timeInfo.end_date = new Date();
		this.timeInfo.duration = this.counter;
		//Enter the time info in the table
		this.apiService
			.recordLoanInterviewDuration(this.timeInfo)
			.subscribe((res) => {
				console.log('Insert Timing Info into Durationg Table');
				// console.log(res)
			});
		this.apiService
			.updateLoanInterviewComplete(applicationID, applicantType)
			.subscribe((res) => {
				console.log('Update Loan Interview Complete');
				// console.log(res)
			});
		this.readOnly = true;
		this.running = false;
		this.startText = 'Start';
		this.counter = undefined;
		clearInterval(this.timerRef);

		// document.getElementById('stopButton').style.display = 'none';
		// document.getElementById('playButton').style.display = 'none';

		// this.route.navigateByUrl('/loadrefresh', { skipLocationChange: true }).then(() => {
		//   this.route.navigate(['/applications/summary/2390707000034288237']);
		// });
	}
	editMode() {
		this.readOnly = false;
	}

	// Information from income calculator modal
	openCalc() {
		const dialogRef = this.dialog.open(MonthlyincomecalcComponent, {
			width: '300px',
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.income_calc_result = result.dataExpression;
			this.underwritingExForm.patchValue({
				totalMonthSubform: result.data['averageSalary'],
			});
			this.totalMonthlyIncome = result.dataExpression;
			this.totalMonthSubform();
		});
	}

	industryList: any;
	getLookups() {
		this.apiService.getLookupsdeals().subscribe((res) => {
			if (res['message']['statusCode'] == '200') {
				let arr1;
				arr1 = JSON.parse(res.message.body);
				this.stageName = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Stage'
				)[0]['pick_list_values'];
				this.creditorName = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Creditt'
				)[0]['pick_list_values'];
				this.primaryEmpStatus = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Employment_Status1'
				)[0]['pick_list_values'];
				this.primaryPayFrequency = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Pay_Frequency2'
				)[0]['pick_list_values'];
				this.primaryIndustry = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Industry'
				)[0]['pick_list_values'];
				this.primaryOccupation = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Occupation'
				)[0]['pick_list_values'];

				this.coAppEmpStatus = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Co_Applicant_Status'
				)[0]['pick_list_values'];
				this.coAppIndustry = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Co_Applicant_Industry'
				)[0]['pick_list_values'];
				this.coAppOccupation = this.customFunction.singleFilterName(
					arr1.fields,
					'api_name',
					'Co_Applicant_Occupation'
				)[0]['pick_list_values'];
			}
		});
	}

	getAgencyRecords() {
		this.apiService.getAgencies().subscribe((res) => {
			if (res['message']['statusCode'] == '200') {
				let arr = JSON.parse(res.message.body);
				this.agencyName = arr.data;
			}
		});
	}

	saveStageLoanInterview() {
		(<HTMLInputElement>document.getElementById('saveLTStageButton')).disabled =
			true;
		document.getElementById('successfulStage').style.display = 'block';
		document.getElementById('successfulStage').innerHTML =
			'Save in progress...';
		document.getElementById('successfulStage').style.color = 'orange';

		this.apiService
			.updateSummaryStage(
				this.loanInterviewStage.get('Stage').value,
				this.zohoApplicationID
			)
			.subscribe((res) => {
				if (res['message']['statusCode'] == '200') {
					(<HTMLInputElement>(
						document.getElementById('saveLTStageButton')
					)).disabled = false;
					document.getElementById('successfulStage').style.display = 'block';
					document.getElementById('successfulStage').innerHTML =
						'Save Successful';
					document.getElementById('successfulStage').style.color = 'green';
					this.apiService.sendClickEvent();
				} else {
					(<HTMLInputElement>(
						document.getElementById('saveLTStageButton')
					)).disabled = false;
					document.getElementById('successfulStage').style.display = 'block';
					document.getElementById('successfulStage').innerHTML =
						'ZOHO: Save unsuccessful';
					document.getElementById('successfulStage').style.color = 'red';
				}
			});
	}

	// autoPopulateLoanInterview(applicationID: any, applicantType: any) {
	//   this.populateUnderwriting(applicationID, applicantType);
	//   this.populateUnderwritingExpenses(applicationID, applicantType);
	// }

	// populateUnderwriting(applicationID: any, applicantType: any) {
	//   this.apiService.getUnderwritingEntry(applicationID, applicantType).subscribe((res) => {
	//     if (res['data'].length != 0) {
	//       this.underwritingExForm.patchValue({
	//         Payment_Method: res['data'][0]['Payment_Method'],
	//         Last_Pay_Date: res['data'][0]['Last_Pay_Date'],
	//         Next_Pay_Date: res['data'][0]['Next_Pay_Date'],
	//         Review_any_payday_loan_usage: res['data'][0]['Review_any_payday_loan_usage'],
	//         Payday_Loans_Comments: res['data'][0]['Payday_Loans_Comments'],
	//         Overdraft_Limit_Question: res['data'][0]['Overdraft_Limit_Question'],
	//         Overdraft_Limit: res['data'][0]['Overdraft_Limit'],
	//         Yes_No_Is_this_a_joint_Account: res['data'][0]['Is_this_a_joint_Account'],
	//         Is_this_a_joint_Account: res['data'][0]['Is_this_a_joint_Account_Note'],
	//         Other_Bank_Account: res['data'][0]['Other_Bank_Account'],
	//         When_was_the_last_time_you_filed_taxes: res['data'][0]['When_was_the_last_time_you_filed_taxes'],
	//         How_much_do_you_owe_Revenue_Canada: res['data'][0]['How_much_do_you_owe_Revenue_Canada'],
	//         Have_you_setup_an_arrangement_with_the_CRA: res['data'][0]['Have_you_setup_an_arrangement_with_the_CRA'],
	//         Reason_for_falling_behind: res['data'][0]['Reason_for_falling_behind'],
	//         Do_you_live_with_anyone_else: res['data'][0]['Do_you_live_with_anyone_else'],
	//         Dependents: res['data'][0]['Dependents'],
	//         are_u_homeowner: res['data'][0]['Are_you_a_homeowner'],
	//         property_taxes: res['data'][0]['Do_you_owe_any_property_tax_Howe_owners_only'],
	//         mortgage_property: res['data'][0]['Is_there_a_mortgage_on_the_property'],
	//         mortgage_with: res['data'][0]['Who_is_your_mortgage_with'],
	//         property_value: res['data'][0]['What_is_current_value_of_the_property'],
	//         mortgage_balance: res['data'][0]['What_is_the_balance_of_your_mortgage'],
	//         mortgage_interest: res['data'][0]['What_interest_rate_on_your_mortgage'],
	//         uw_tier: res['data'][0]['UW_Tier'],
	//         totalMonthSubform: res['data'][0]['Total_Monthly_Income']
	//       })
	//       this.tdsr = res['data'][0]['TDSR'];
	//     }

	//   })
	// }
	// populateUnderwritingExpenses(applicationID: any, applicantType: any) {
	//   this.apiService.getUnderwritingExpenses(applicationID, applicantType).subscribe((res) => {
	//     console.log(res,"**********************")
	//     if (!res['status']) {
	//       console.log('Unable to retrieve Expenses!')
	//     }
	//     else {
	//       if (res['data'].length != 0) {
	//         this.expense_list = res['data'];
	//         console.log(this.expense_list)
	//         let expenses_array = this.underwritingExForm.get('ADDITIONAL_EXPENSES') as FormArray;

	//         let list_array = this.underwritingExForm.get('ADDITIONAL_EXPENSES') as FormArray;
	//         while (list_array.length !== 0) {
	//           list_array.removeAt(0);
	//         }

	//         for (var i = 0; i < this.expense_list.length; i++) {
	//           expenses_array.push(this.fb.group({
	//             'Type': this.expense_list[i]['type'],
	//             'Name1': this.expense_list[i]['creditor_name'],
	//             'Amount': this.expense_list[i]['amount'],
	//           }))
	//         }
	//         this.totalSubformAdditional = this.customFunction.sumNumbers(res['data'], 'amount');
	//       }
	//     }
	//   })
	// }

	async insertLocalUnderwritingPrimary() {
		return new Promise((resolve, reject) => {
			//Local Platform
			this.apiService
				.addLoanInterviewlocal(
					this.underwritingExForm.value,
					this.zohoApplicationID,
					this.underwritingID,
					this.loggedZohoID,
					this.applicationType,
					this.totalSubformAdditional,
					this.tdsr,
					this.loggedName
				)
				.subscribe((res) => {
					if (res['status']) {
						// console.log(res)
						this.apiService
							.addunderwritingincomecalculation(
								this.zohoApplicationID,
								this.applicationType,
								this.income_calc_result
							)
							.subscribe((res) => {
								console.log(res, 'INSERT INCOME');
							});
						resolve(true);
					} else {
						this.errorMessageLog.push(JSON.stringify(res['message']['data']));
						resolve(false);
						console.log(res);
					}
				});
		});
	}

	async pushZohoApplicationDetails() {
		return new Promise((resolve, reject) => {
			this.apiService
				.zohoInterviewerApplication(
					this.underwritingExForm.value,
					this.zohoApplicationID,
					this.loggedName,
					this.tdsr,
					this.applicationType
				)
				.subscribe((res) => {
					if (!(res['message']['statusCode'] == '200')) {
						console.log(res['message']['body']);
						this.errorMessageLog.push(res['message']['body']);
						resolve(false);
						console.log('Zoho Application push: Fail!');
					} else {
						resolve(true);
						console.log('Zoho Application push: Success!');
					}
				});
		});
	}

	async pushZohoUnderwritingDetails() {
		return new Promise((resolve, reject) => {
			this.apiService
				.updateLoanInterview(
					this.underwritingExForm.value,
					this.underwritingID,
					this.loggedZohoID
				)
				.subscribe((res) => {
					if (res.message.statusCode == '200') {
						resolve(true);
						console.log('Zoho Underwriting push: Success!');
					} else {
						this.errorMessageLog.push(res['message']['body']);
						console.log(res['message']['body']);
						resolve(false);
						console.log('Zoho Underwriting push: Fail!');
					}
				});
		});
	}

	// Save Locally, ZOHO Application, ZOHO Underwriting
	saveInterviewScript() {
		if (this.userRole === 'UW') {
			(<HTMLInputElement>(
				document.getElementById('saveLoanInterviewButton')
			)).disabled = true;
			document.getElementById('saveMessage').style.display = 'block';
			document.getElementById('saveMessage').innerHTML = 'Save in progress...';
			document.getElementById('saveMessage').style.color = 'orange';
			// Promise.all([this.insertLocalUnderwritingPrimary(), this.pushZohoApplicationDetails(), this.pushZohoUnderwritingDetails()])
			Promise.all([
				this.insertLocalUnderwritingPrimary(),
				this.pushZohoApplicationDetails(),
			])
				.then((result) => {
					const checkTrue = result.filter((value) => value === true);
					// if (checkTrue.length === 3) {
					if (checkTrue.length === 2) {
						// 2 inorder to resolve both the promises
						if (this.yes_interview === true) {
							this.clearTimer(this.zohoApplicationID, this.applicationType);
							this.yes_interview = false;
						}
						// this.resetPrimaryLoanInterview();
						// this.loadApplicationInfo();
						this.show_notes_stage = true;
						// this.autoPopulateLoanInterview(this.zohoApplicationID, this.applicationType);
						// this.populateUnderwriting(this.zohoApplicationID, this.applicationType);
						// this.populateUnderwritingExpenses(this.zohoApplicationID, this.applicationType);
						(<HTMLInputElement>(
							document.getElementById('saveLoanInterviewButton')
						)).disabled = false;
						document.getElementById('saveMessage').innerHTML =
							'Loan Interview Successful!';
						document.getElementById('saveMessage').style.color = 'green';
						document.getElementById('saveMessage').style.display = 'block';
					} else {
						this.show_notes_stage = false;
						this.errorMessage = this.errorMessageLog;
						(<HTMLInputElement>(
							document.getElementById('saveLoanInterviewButton')
						)).disabled = false;
						document.getElementById('saveMessage').style.display = 'block';
						document.getElementById('saveMessage').innerHTML = 'Save failed!';
						document.getElementById('saveMessage').style.color = 'red';
					}
				})
				.catch((e) => {
					console.error(e.message, 'Save failed: PromiseAll!');
				});

			//   try {
			//     this.insertLocalUnderwritingPrimary()
			//       .then(result => this.pushZohoApplicationDetails())
			//       .then(result1 => this.pushZohoUnderwritingDetails())
			//       .then(result2 => {
			//         this.apiService.sendClickEvent();
			//         this.loadApplicationInfo();
			//       }).catch((e) => {
			//         console.error(e.message, "ZOHO Underwriting push failed!");
			//       })
			//   } catch (error) {
			//     console.log(`Caught by try/catch saveInterviewScript ${error}`);
			//   }
		} else {
			(<HTMLInputElement>(
				document.getElementById('saveLoanInterviewButton')
			)).disabled = false;
			document.getElementById('saveMessage').innerHTML =
				'Save not allowed. Contact IT!';
			document.getElementById('saveMessage').style.color = 'red';
			document.getElementById('saveMessage').style.display = 'block';
		}
	}

	clearErrors() {
		this.errorMessageLog = [];
		this.errorMessage = '';
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}
