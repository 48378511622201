import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import { CustomFunctionService } from '../../../custom-function.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-interviewerqueue',
	templateUrl: './interviewerqueue.component.html',
	styleUrls: ['./interviewerqueue.component.scss'],
})
export class InterviewerqueueComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private _routerLink: Router
	) {}

	timestamp = Date();

	loanInterviewsArray: [];

	p: Number; // Pagination 0 call attempts
	count: Number; // Pagination

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchUnderwritingRLI();
						this.p = 1;
						this.count = 20;
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	fetchUnderwritingRLI() {
		this.apiService.getDealsCreatedUnderwritingRLI().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.loanInterviewsArray = arr.data;
			}
		});
	}
	refresh() {
		window.location.reload();
	}
}
