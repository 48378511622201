import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiServiceService } from '@lmc-app/api-service.service';

@Component({
  selector: 'app-assign-collectors',
  templateUrl: './assign-collectors.component.html',
  styleUrls: ['./assign-collectors.component.scss']
})
export class AssignCollectorsComponent implements OnInit, AfterViewInit {

  input_value = [
    { insertValue: 'acctrefno' },
    { insertValue: 'loan_number' }
  ];

  selectedInputValue = 'acctrefno';
  isManager = false;

  btnSpinner = false;
  btnShowText = true;

  collectorNames: any;

  selectedEmpID: any;
  selectedFirstName: any;

  inputList = new FormControl('', Validators.required);
  collectorName = new FormControl('', Validators.required);

  constructor(private apiService: ApiServiceService) { }
  ngAfterViewInit(): void {
    this.apiService.getUnderwriters().subscribe((res: any) => {
      this.collectorNames = res?.['data']?.filter((item) => item?.['position'] === 'CO' && item?.['employee_status'] === true);
    })
  }

  ngOnInit() {
  }
  displayResult: any;
  assignCollectorList() {
    this.displayResult = '';
    this.loadResult();
    this.apiService.assignCollectorListInterface(this.selectedInputValue, this.inputList.value, this.collectorInfo, this.isManager).subscribe((res: any) => {
      console.log(res);
      this.displayResult = res;
      this.resultCompleted();
    })
  }

  loadResult() {
    this.btnSpinner = true;
    this.btnShowText = false;
  }

  resultCompleted() {
    this.btnSpinner = false;
    this.btnShowText = true;
  }

  selectedInputOption(e) {
    this.selectedInputValue = e;
  }
  collectorInfo: any;
  selectedCollector(e) {
    this.collectorInfo = {};
    this.selectedEmpID = e.value;
    this.selectedFirstName = e.source.triggerValue?.split(' ')?.[0];
    this.collectorInfo = { empID: this.selectedEmpID, collectorName: this.selectedFirstName };
  }
}
