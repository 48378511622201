export class UnderwritingEntry {
	row_id; //primary key
	Applicant_Type; //additional key
	//ZOHO ADDITIONAL FIELDS
	Scheduled_Payments_Monthly = 0;
	Employer = '';
	Employment_Status1 = '';
	Work_Phone = '';
	Work_Extension = '';
	Length_of_Employment = '';
	Position = '';
	Previous_Employment = '';
	Industry = '';
	Occupation = '';
	Pay_Frequency2 = '';
	Income_net_paycheque_income = '';
	Last_Pay_Date = '';
	Supervisor_Name = '';
	Next_Pay_Date = '';
	Supervisor_Phone_Number = '';
	Additional_Employment = '';
	Income_Amount_Household_Income = '';
	Employer_Name_Household_Income = '';
	Pay_Frequency_Household_Income = '';
	Stage = '';

	Previous_Employment_Type = '';
	Previous_Employer = '';
	Previous_Supervisor_Name = '';
	Previous_Income_net_paycheque_income = '';
	Previous_Payment_Method = '';
	Previous_Position = '';
	Previous_Length_of_Employment = '';
	Previous_Supervisor_Phone_number = '';
	Previous_Pay_Frequency = '';
	Current_Loan_Amount = '';

	// Banking - Primary
	Financial_Institution_Name = '';
	Financial_Institution_Number = '';
	Bank_Account_Number = '';
	Branch_Transit_Number = '';
	Branch_Address = '';

	// Co Applicant Employment Information
	Co_Applicant_Status = '';
	Co_Applicant_Employer = '';
	Co_Applicant_Supervisor_Name = '';
	Sec_Annual_Income = '';
	Co_Applicant_Payment_Method = '';
	Co_Applicant_Industry = '';
	Co_Applicant_Occupation = '';
	Secondary_Position = '';
	Secondary_Years_Worked = '';
	Co_Applicant_Supervisor_Phone_Number = '';
	Secondary_Work_Number = '';
	Co_Applicant_Pay_Frequency = '';

	// Banking - Co Applicant
	Co_Applicant_Financial_Institution_Name = '';
	Co_ApplicantFinancial_Institution_Number1 = '';
	Co_Applicant_Bank_Account = '';
	Co_Applicant_Branch_Transit_Number = '';
	Co_Applicant_Branch_Address = '';
	Co_Applicant_Other_Bank_Account = '';

	//Approval
	Approved_Amount = null;
	Opportunity_Status = '';
	UW_Tier = '';
	Date_Ordered = '';
	Term_of_Loan_Years = '';

	//
	applicationID = '';
	underwritingID = '';
	Employment_Details = '';
	Are_you_a_homeowner = '';
	Co_Applicant_Required = '';
	Created_By = '';
	Credit_Bureau_pulled = '';
	Credit_Score = '';
	Currency = '';
	Loan_Monthly_Payments = 0;
	Do_you_live_with_anyone_else = '';
	Do_you_owe_any_property_tax_Howe_owners_only = '';
	Employment_Status = '';
	Exception_Funded_Amount = '';
	Exception_Increase = '';
	Exchange_Rate = '';
	ADDITIONAL_EXPENSES = '';
	NSF_SP_Comments = '';
	First_Payment_Date = '';
	Have_you_setup_an_arrangement_with_the_CRA = '';
	Household_Income = '';
	How_much_do_you_owe_Revenue_Canada = null;
	Institution_Name = '';
	Institution_Number = '';
	Interviewer = '';
	Is_there_a_mortgage_on_the_property = '';
	Is_this_a_joint_Account = '';
	Is_this_a_joint_Account_Note = '';
	Loan_Amount = 0;
	Loyalty_Payment_Plan = '';
	LTV2 = '';
	Modified_By = '';
	Notes = '';
	Dependents = '';
	Other_Bank_Account = '';
	Overdraft_Limit = 0.0;
	Overdraft_Limit_Question = '';
	Override = '';
	Override_Type = '';
	Payday_Loans_Comments = '';
	Emp_Payment_Frequency = ''; //edited
	Loan_Payment_Frequency = ''; //edited
	Payment_Method = '';
	Previous_bankruptcy_CP_legal = '';
	Primary_Applicant = '';
	Purpose_of_the_loan = '';
	Purview_Value = 0;
	Reason_for_falling_behind = '';
	Referral_item = '';
	Review_any_payday_loan_usage = '';
	Risk = '';
	TDSR = 0;
	Term_in_Years = '';
	Total_Additional_Debt_Balance;
	Total_Additional_Expenses;
	Total_Monthly_Income = 0;
	Total_number_of_debts_in_collections = '';
	Total_Trades_Falling_behind = '';
	Total_Collection_Balance = '';
	Total_Trades_Balance = '';
	Name = '';
	Owner = '';
	Validator = '';
	What_interest_rate_on_your_mortgage = '';
	What_is_current_value_of_the_property;
	What_is_the_balance_of_your_mortgage;
	When_was_the_last_time_you_filed_taxes = '';
	Who_is_your_mortgage_with = '';
	LTV = '0';
	Additional_Debt;
	Expenses;
}
