export const ModalNames = {
	STANDARD: 'standard',
	TRANSUNION_HIT_CONFIRMATION: 'Transunion Hit Confirmation',
	TRANSUNION_RE_HIT_MANAGER_CONFIRMATION: 'Transunion Re Hit Manager Confirmation',
	TRANSUNION_HIT_FOUND: 'Transunion Hit Found',
	TRANSUNION_HIT_NOT_FOUND: 'Transunion Hit Not Found',
	TRANSUNION_ZOHO_UPDATED_WITH_REPORT: 'Transunion Zoho Updated With Report',
	PANDADOCS_USER_CONFIRMATION: 'Pandadocs User Confirmation',
	PANDADOCS_DOCUMENT_FAILED: 'Pandadocs Document Failed',
	PANDADOCS_DOCUMENT_SUCCESS: 'Pandadocs Document Success',
	PANDADOCS_DOCUMENT_DELETE_CONFIRMATION: 'Pandadocs Document Delete Confirmation',
	PANDADOCS_DOCUMENT_DELETE_SUCCESS: 'Pandadocs Document Delete Success',
	PANDADOCS_DOCUMENT_DELETE_FAIL: 'Pandadocs Document Delete Fail',
	INSURANCE_TRANSACTION_CANCELLATION: 'INSURANCE_TRANSACTION_CANCELLATION'
};
export interface ICustomModalOptions {
	activateModal: boolean;
	modalName: string;
	headerTitle: string;
	bodyText: string;
	buttons: Array<string>;
	options
}
