import { Component, Input } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'app-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
	@Input() pdfSrc: any;
	public page = 1;
	public pageLabel = 'Something';
	ngOnInit(): void {
		console.log('PDF VIEWER');
		console.log(this.pdfSrc);
	}
}
