<select class="form-select" (change)="updateApplicationType($event)" *ngIf="co_app_required"
	style="width: 250px; margin: 0; cursor: pointer">
	<option value="primary">Primary</option>
	<option value="co_applicant">Co-applicant</option>
</select>
<ng-template #emptystring>
	<td></td>
</ng-template>
<div style="padding-left: 3%; display: inline-block; vertical-align: top">
	<img src="../../../assets/images/inverite-logo.png" width="300" height="70" alt="" class="mb-05" />
</div>
<div *ngIf="selectBankFlag && banks" style="padding-left: 3%; font-size: 20px">
	<a (click)="homePage()">Home / </a>
	<a (click)="selectBankPage()" style="text-decoration: underline">
		<strong>Add Bank</strong>
	</a>
</div>
<div *ngIf="createRequestFlag" style="padding-left: 3%; font-size: 20px">
	<a (click)="homePage()">Home / </a>
	<a (click)="selectBankPage()">Add Bank / </a>
	<a style="text-decoration: underline"><strong>Create Request</strong></a>
</div>
<div *ngIf="homeFlag" style="padding-left: 3%; font-size: 20px">
	<a (click)="homePage()" style="text-decoration: underline"><strong>Home </strong></a>/
	<a (click)="selectBankPage()">Add Bank / </a>
	<a>Create Request</a>
</div>
<div style="padding-left: 3%">
	<div *ngIf="selectBankFlag && banks">
		<div class="form-group">
			<label style="font-size: 20px"><strong>Select Bank</strong></label><br />
			<select class="form-control" (change)="changeBank($event)" style="width: 30%; font-size: 170%"
				[disabled]="isExternalView">
				<option disabled selected>Select from the following Banks</option>
				<option *ngFor="let b of banks">{{ b.name }}</option>
			</select>
		</div>
		<div style="padding-top: 1%">
			<button class="button" (click)="bankSelected()" [disabled]="isExternalView">Next</button>
		</div>
	</div>
	<div *ngIf="createRequestFlag">
		<div class="float-child-left-main">
			<form #inveriteForm="ngForm">
				<div class="float-child-input-left">
					<div class="form-group">
						<label style="font-size: 20px"><strong>First Name</strong></label><br />

						<input placeholder="First Name" [(ngModel)]="model.FirstName" name="FirstName"
							style="width: 80%; font-size: 170%" />
					</div>
					<br />
					<div class="form-group">
						<label style="font-size: 20px"><strong>Phone Number</strong></label><br />

						<input placeholder="Phone Number" [(ngModel)]="model.phone" name="phone"
							style="width: 80%; font-size: 170%" />
					</div>
					<br />
				</div>
				<div class="float-child-input-right">
					<div class="form-group">
						<label style="font-size: 20px"><strong>Last Name</strong></label><br />
						<input placeholder="Last Name" [(ngModel)]="model.LastName" name="LastName"
							style="width: 150%; font-size: 170%" />
					</div>
					<br />
					<div class="form-group">
						<label style="font-size: 20px"><strong>Email</strong></label><br />

						<input placeholder="Email" [(ngModel)]="model.email" name="email"
							style="width: 150%; font-size: 170%" />
					</div>
					<br />
				</div>
			</form>
		</div>
		<br /><br /><br /><br /><br /><br /><br /><br /><br />
		<div class="form-group">
			<label style="font-size: 20px"><strong>Bank</strong></label><br />
			<input readonly name="banks" [(ngModel)]="model.Bank" class="form-control"
				style="font-size: 170%; width: 30%" />
		</div>
		<br />
		<br />
		<button class="button" (click)="onSubmit()">Submit</button>
		<br />
		<hr />
		<br />
	</div>
	<br />
	<div *ngIf="homeFlag" class="section-left">
		<div *ngIf="zohoUploadStatus === 'Submitted'; else elseStatus">
			<h2>
				Inverite Request : {{ inveriteStatus }} | Zoho Status: {{ acc_status }}
				<img src="../../../assets/images/checked.PNG" width="40" height="40" alt="" class="mb-05"
					style="padding-left: 20px" (click)="openSelectAccountModel(bankStatement)" />
			</h2>
		</div>
		<ng-template #elseStatus>
			<h2>
				Inverite Request : {{ inveriteStatus }} | Zoho Status: {{ acc_status }}
				<img src="../../../assets/images/unchecked.PNG" width="40" height="40" alt="" class="mb-05"
					style="padding-left: 10px" (click)="openSelectAccountModel(bankStatement)" />
			</h2>
		</ng-template>

		<div *ngIf="bankStatement">
			<table style="width: 100%">
				<tr>
					<th>Name: {{ bankStatement.name }}</th>
					<th>Reference: {{ bankStatement.referenceid }}</th>
				</tr>
				<tr>
					<th>Request GUID: {{ bankStatement.request }}</th>
					<th>Address: {{ bankStatement.address }}</th>
				</tr>
			</table>
			<br />
		</div>
	</div>
	<div class="section-right" style="float: right" *ngIf="homeFlag">
		<mat-card appearance="outlined" style="border-style: solid; border-width: 0.5px; border-color: grey">
			<h2>Bank Selector</h2>
			<mat-selection-list #bank_list [multiple]="false">
				<mat-list-option *ngFor="let bank of bank_requests; let i = index" (click)="selectionChange(bank)"
					[selected]="bank.checked">
					{{ bank.bank_name }}
				</mat-list-option>
			</mat-selection-list>
			<button (click)="selectBankPage()" class="buttonFront" [disabled]="isExternalView">Add Bank</button>
			<button (click)="ResetForm()" class="buttonFront" [disabled]="isExternalView">Delete Bank</button>
			<button (click)="checkInveriteRequestStatus(bank_view['guid'])" class="buttonFront"
				[disabled]="isExternalView">
				Request Status
			</button>
		</mat-card>
	</div>
	<div style="width: 99%" *ngIf="homeFlag">
		<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"
			animationDuration="0ms">
			<div>
				<mat-tab class="mt-label-content" *ngFor="let acc of accounts; let index = index"
					[label]="account_labels[index]">
					<div style="border-style: solid; border-width: 0.5px; border-color: gray">
						<div class="row" style="width: 99%">
							<div class="col-4">
								<h5>Detected Account</h5>
								<table class="table table-striped" style="width: 100%;">
									<thead>
										<tr>
											<th>Bank: {{ acc.bank }}</th>
											<th>Institution: {{ acc.institution }}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>Transit: {{ acc.transit }}</th>
											<th>Account: {{ acc.account }}</th>
										</tr>
										<tr *ngIf="hasProperties(acc.current_balance)"
											style="background-color: rgb(166, 253, 224);">
											<th>Current balance: {{ acc.current_balance }} </th>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="col-4">
								<h5 *ngIf="
									hasProperties(acc.statistics.quarter_all_time);
									else NumberofNSFs
								">
									Number of NSFs ({{
									acc.statistics.quarter_all_time.number_of_nsf
									}})
								</h5>
								<ng-template #NumberofNSFs>Number of NSFs</ng-template>
								<table class="table table-striped" style="width: 100%;"
									*ngIf="hasProperties(acc.statistics)">
									<thead>
										<tr>
											<th *ngIf="
											hasProperties(acc.statistics.quarter_3_months);
											else emptystring
										">
												3 Months:
												{{ acc.statistics.quarter_3_months.number_of_nsf }}
											</th>
											<th *ngIf="
											hasProperties(acc.statistics.quarter_6_months);
											else emptystring
										">
												6 Months:
												{{ acc.statistics.quarter_6_months.number_of_nsf }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th *ngIf="
											hasProperties(acc.statistics.quarter_9_months);
											else emptystring
										">
												9 Months:
												{{ acc.statistics.quarter_9_months.number_of_nsf }}
											</th>
											<th *ngIf="
											hasProperties(acc.statistics.quarter_12_months);
											else emptystring
										">
												12 Months:
												{{ acc.statistics.quarter_12_months.number_of_nsf }}
											</th>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="col-4" *ngIf="hasProperties(acc.statistics)">
								<h5>Monthly Transaction Summary</h5>
								<table class="table table-striped" style="width: 100%;">
									<thead>
										<tr>
											<th>Date Range</th>
											<th>Food</th>
											<th>Utilities</th>
											<th>Auto</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let item of monthlyTransactionSummary(index)">
											<td>{{item['start']}}-{{item['end']}}</td>
											<td>{{item?.['result']?.['food_and_dining_debit_total']}}</td>
											<td>{{item?.['result']?.['bills_and_utilities_debit_total']}}</td>
											<td>{{item?.['result']?.['auto_and_transport_debit_total']}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<br />
						<mat-tab-group animationDuration="0ms">
							<mat-tab label="Transactions">
								<div style="
										border-style: solid;
										border-width: 2px;
										border-color: gray;
										padding-top: 10px;
										padding-bottom: 10px;
									">
									<mat-chip-listbox aria-label="Transaction Selection">
										<mat-chip-option style="background-color: rgb(215, 213, 213)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											All</mat-chip-option>
										<mat-chip-option style="background-color: rgb(165, 168, 235)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											is_regular</mat-chip-option>
										<mat-chip-option style="background-color: rgb(230, 111, 168)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											is_bankruptcy_trustee
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(182, 228, 192)" (click)="
												filterTransactions($event.target.innerText, index)
											">is_return
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(249, 117, 117)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											is_in_overdraft
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(206, 213, 147)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											is_end_overdraft
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(244, 80, 219)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											is_start_overdraft
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(84, 245, 109)" (click)="
												filterTransactions($event.target.innerText, index)
											">is_payday
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(18, 178, 231)" (click)="
												filterTransactions($event.target.innerText, index)
											">is_payroll
										</mat-chip-option>
										<mat-chip-option style="background-color: rgb(237, 160, 104)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											is_inconsistent
										</mat-chip-option>
										<mat-chip-option style="background-color: rgba(158, 144, 215, 0.43)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											food</mat-chip-option>
										<mat-chip-option style="background-color: rgba(240, 219, 121, 0.43)" (click)="
												filterTransactions($event.target.innerText, index)
											">
											bills_and_utilities</mat-chip-option>
										<mat-chip-option style="background-color: rgb(16, 219, 148)" (click)="
													filterTransactions($event.target.innerText, index)
												">
											auto</mat-chip-option>
										<mat-chip-option style="background-color: rgb(131, 180, 230)" (click)="
											filterTransactions($event.target.innerText, index)
										">
											shopping</mat-chip-option>
										<mat-chip-option style="background-color: rgb(223, 131, 233)" (click)="
											filterTransactions($event.target.innerText, index)
										">
											entertainment</mat-chip-option>
										<mat-chip-option style="background-color: rgb(250, 77, 38)" (click)="
											filterTransactions($event.target.innerText, index)
										">
											gambling</mat-chip-option>
									</mat-chip-listbox>
								</div>
								<div *ngIf="transaction_pill === 'All'; else elseTrans">
									<table class="table-transaction" *ngIf="hasProperties(acc.transactions)">
										<tr>
											<th style="font-size: large">Date</th>
											<th style="font-size: large">Description</th>
											<th style="font-size: large">Debit</th>
											<th style="font-size: large">Credit</th>
											<th style="font-size: large">Balance</th>
											<th style="font-size: large">Flags</th>
											<th style="font-size: large">Category</th>
										</tr>
										<tr *ngFor="let pays of acc.transactions">
											<td style="width: 12%">
												{{ pays.date }}
											</td>
											<td>
												{{ pays.details }}
											</td>
											<td>
												{{ pays.debit }}
											</td>
											<td>
												{{ pays.credit }}
											</td>
											<td>
												{{ pays.balance }}
											</td>
											<td>
												{{ pays.flags }}
											</td>
											<td>
												{{ pays.category }}
											</td>
										</tr>
									</table>
								</div>
								<ng-template #elseTrans>
									<table class="table-transaction"
										*ngIf="hasProperties(acc.transactions) && !is_category">
										<tr>
											<th style="font-size: large">Date</th>
											<th style="font-size: large">Description</th>
											<th style="font-size: large">Debit</th>
											<th style="font-size: large">Credit</th>
											<th style="font-size: large">Balance</th>
											<th style="font-size: large">Flags</th>
											<th style="font-size: large">Category</th>
										</tr>
										<tr *ngFor="let pays of acc.transactions">
											<td style="width: 12%" *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.date }}
											</td>
											<td *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.details }}
											</td>
											<td *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.debit }}
											</td>
											<td *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.credit }}
											</td>
											<td *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.balance }}
											</td>
											<td *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.flags }}
											</td>

											<td *ngIf="pays.flags.includes(transaction_pill)">
												{{ pays.category }}
											</td>
										</tr>
									</table>
									<table class="table-transaction"
										*ngIf="hasProperties(acc.transactions) && is_category">
										<tr>
											<th style="font-size: large">Date</th>
											<th style="font-size: large">Description</th>
											<th style="font-size: large">Debit</th>
											<th style="font-size: large">Credit</th>
											<th style="font-size: large">Balance</th>
											<th style="font-size: large">Flags</th>
											<th style="font-size: large">Category</th>
										</tr>
										<tr *ngFor="let pays of acc.transactions">
											<td style="width: 12%" *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.date }}
											</td>
											<td *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.details }}
											</td>
											<td *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.debit }}
											</td>
											<td *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.credit }}
											</td>
											<td *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.balance }}
											</td>
											<td *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.flags }}
											</td>

											<td *ngIf="pays.category.includes(transaction_pill)">
												{{ pays.category }}
											</td>
										</tr>
									</table>
								</ng-template>
							</mat-tab>
							<mat-tab label="Employer Income">
								<table *ngIf="hasProperties(acc.transactions)">
									<tr>
										<th style="font-size: large">Date</th>
										<th style="font-size: large">Description</th>
										<th style="font-size: large">Credit</th>
									</tr>
									<tr *ngFor="let pays of acc.transactions">
										<td *ngIf="pays.category === 'income/paycheck'">
											{{ pays.date }}
										</td>
										<td *ngIf="pays.category === 'income/paycheck'">
											{{ pays.details }}
										</td>
										<td *ngIf="pays.category === 'income/paycheck'">
											{{ pays.credit }}
										</td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Employment Payments">
								<table class="table-emp-payments">
									<tr>
										<th style="font-size: large">Account</th>
										<th style="font-size: large">Details</th>
										<th style="font-size: large">Frequency</th>
										<th style="font-size: large">Past Payments</th>
										<th style="font-size: large">Future Payments</th>
									</tr>
									<tr *ngFor="let pays of acc.payschedules">
										<td>{{ pays.account }}</td>
										<td>{{ pays.details }}</td>
										<td>{{ pays.frequency }}</td>
										<td *ngFor="let p of pays.payments">{{ p.date }}</td>
										<td *ngFor="let p of pays.future_payments">{{ p.date }}</td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Income Verification">
								<table class="table-income-verification" *ngIf="hasProperties(acc.statistics)">
									<tr>
										<th></th>
										<th style="font-size: large">Employment Income</th>
										<th style="font-size: large">Employment Income Count</th>
										<th style="font-size: large">Government Income</th>
										<th style="font-size: large">Government Income Count</th>
										<th style="font-size: large">Total Credits</th>
									</tr>
									<tr>
										<td>0 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['0_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'0_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['0_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'0_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['0_months_ago'] }}
										</td>
									</tr>

									<tr>
										<td>1 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['1_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'1_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['1_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'1_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['1_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>2 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['2_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'2_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['2_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'2_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['2_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>3 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['3_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'3_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['3_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'3_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['3_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>4 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['4_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'4_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['4_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'4_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['4_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>5 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['5_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'5_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['5_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'5_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['5_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>6 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['6_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'6_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['6_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'6_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['6_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>7 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['7_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'7_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['7_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'7_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['7_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>8 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['8_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'8_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['8_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'8_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['8_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>9 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['9_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'9_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income['9_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'9_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['9_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>10 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['10_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'10_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income[
											'10_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'10_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['10_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>11 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['11_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'11_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income[
											'11_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'11_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['11_months_ago'] }}
										</td>
									</tr>
									<tr>
										<td>12 months</td>
										<td *ngIf="
												hasProperties(acc.statistics.employer_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income['12_months_ago']
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.employer_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.employer_monthly_income_freq[
											'12_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.government_monthly_income);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income[
											'12_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(
													acc.statistics.government_monthly_income_freq
												);
												else emptystring
											">
											{{
											acc.statistics.government_monthly_income_freq[
											'12_months_ago'
											]
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.total_monthly_credit);
												else emptystring
											">
											{{ acc.statistics.total_monthly_credit['12_months_ago'] }}
										</td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Deposit Report">
								<table class="table-transaction" *ngIf="hasProperties(acc.transactions)">
									<tr>
										<th style="font-size: large">Date</th>
										<th style="font-size: large">Description</th>
										<th style="font-size: large">Amount</th>
										<th style="font-size: large">Balance</th>
									</tr>
									<tr *ngFor="let pays of acc.transactions">
										<td style="width: 12%" *ngIf="pays.credit != '' && pays.debit === ''">
											{{ pays.date }}
										</td>
										<td *ngIf="pays.credit != '' && pays.debit === ''">
											{{ pays.details }}
										</td>
										<td *ngIf="pays.credit != '' && pays.debit === ''">
											{{ pays.credit }}
										</td>
										<td *ngIf="pays.credit != '' && pays.debit === ''">
											{{ pays.balance }}
										</td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Account Stats">
								<table *ngIf="hasProperties(acc.statistics)">
									<tr>
										<th></th>
										<th style="font-size: large">3 Months</th>
										<th style="font-size: large">6 Months</th>
										<th style="font-size: large">9 Months</th>
										<th style="font-size: large">12 Months</th>
										<th style="font-size: large">All Time</th>
									</tr>
									<tr>
										<td><strong>Number of NSFs</strong></td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.number_of_nsf }}
										</td>
									</tr>
									<tr>
										<td>Number of Deposits</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.number_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.number_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.number_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.number_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.number_of_deposit }}
										</td>
									</tr>
									<tr>
										<td>Amount of Deposits</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.amount_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.amount_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.amount_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.amount_of_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.amount_of_deposit }}
										</td>
									</tr>
									<tr>
										<td>Average Amount of Deposits</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_3_months
											.average_amount_of_deposit
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_6_months
											.average_amount_of_deposit
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_9_months
											.average_amount_of_deposit
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_12_months
											.average_amount_of_deposit
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{
											acc.statistics.quarter_all_time
											.average_amount_of_deposit
											}}
										</td>
									</tr>
									<tr>
										<td>Highest Deposit</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.highest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.highest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.highest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.highest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.highest_deposit }}
										</td>
									</tr>
									<tr>
										<td>Lowest Deposit</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.lowest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.lowest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.lowest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.lowest_deposit }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.lowest_deposit }}
										</td>
									</tr>
									<tr>
										<td>Number of Widthdrawals</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.number_of_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.number_of_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.number_of_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_12_months.number_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.number_of_withdrawal }}
										</td>
									</tr>
									<tr>
										<td>Amount of Widthdrawals</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.amount_of_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.amount_of_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.amount_of_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_12_months.amount_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.amount_of_withdrawal }}
										</td>
									</tr>
									<tr>
										<td>Average Number of Widthdrawals</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_3_months
											.average_number_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_6_months
											.average_number_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_9_months
											.average_number_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_12_months
											.average_number_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{
											acc.statistics.quarter_all_time
											.average_number_of_withdrawal
											}}
										</td>
									</tr>
									<tr>
										<td>Average Amount of Widthdrawals</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_3_months
											.average_amount_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_6_months
											.average_amount_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_9_months
											.average_amount_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_12_months
											.average_amount_of_withdrawal
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{
											acc.statistics.quarter_all_time
											.average_amount_of_withdrawal
											}}
										</td>
									</tr>
									<tr>
										<td>Highest Withdrawal</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.highest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.highest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.highest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.highest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.highest_withdrawal }}
										</td>
									</tr>
									<tr>
										<td>Lowest Withdrawal</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.lowest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.lowest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.lowest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.lowest_withdrawal }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.lowest_withdrawal }}
										</td>
									</tr>
									<tr>
										<td>Number of NSFs</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.number_of_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.number_of_nsf }}
										</td>
									</tr>
									<tr>
										<td>Average Number of NSFs</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.average_number_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.average_number_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.average_number_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.average_number_nsf }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.average_number_nsf }}
										</td>
									</tr>
									<tr>
										<td>Highest Balance</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.highest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.highest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.highest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.highest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.highest_balance }}
										</td>
									</tr>
									<tr>
										<td>Lowest Balance</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.lowest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.lowest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.lowest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.lowest_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.lowest_balance }}
										</td>
									</tr>
									<tr>
										<td>Average Balance</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.average_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.average_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.average_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.average_balance }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.average_balance }}
										</td>
									</tr>
									<tr>
										<td>Highest Overdraft</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.highest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.highest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.highest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.highest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.highest_overdraft }}
										</td>
									</tr>
									<tr>
										<td>Lowest Overdraft</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.lowest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.lowest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.lowest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.lowest_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.lowest_overdraft }}
										</td>
									</tr>
									<tr>
										<td>Number of Overdraft</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.number_of_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.number_of_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.number_of_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.number_of_overdraft }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.number_of_overdraft }}
										</td>
									</tr>
									<tr>
										<td>Average Amount of Overdraft</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_3_months.average_amount_overdraft
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_6_months.average_amount_overdraft
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_9_months.average_amount_overdraft
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{
											acc.statistics.quarter_12_months
											.average_amount_overdraft
											}}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{
											acc.statistics.quarter_all_time.average_amount_overdraft
											}}
										</td>
									</tr>
									<tr>
										<td>Newest Transaction</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.newest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.newest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.newest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.newest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.newest_transaction }}
										</td>
									</tr>
									<tr>
										<td>Oldest Transaction</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_3_months);
												else emptystring
											">
											{{ acc.statistics.quarter_3_months.oldest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_6_months);
												else emptystring
											">
											{{ acc.statistics.quarter_6_months.oldest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_9_months);
												else emptystring
											">
											{{ acc.statistics.quarter_9_months.oldest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_12_months);
												else emptystring
											">
											{{ acc.statistics.quarter_12_months.oldest_transaction }}
										</td>
										<td *ngIf="
												hasProperties(acc.statistics.quarter_all_time);
												else emptystring
											">
											{{ acc.statistics.quarter_all_time.oldest_transaction }}
										</td>
									</tr>
								</table>
							</mat-tab>
						</mat-tab-group>
					</div>
				</mat-tab>
			</div>
		</mat-tab-group>
	</div>
</div>