<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img title="refresh" src="/assets/images/refresh.png" width="30" height="30" (click)="refresh()" />
		</h5>
	</div>

	<div class="cardContainer">
		<div class="cardContainer-scroll">
			<div class="card">
				<div class="container">
					<h4><strong>Underwriting Queue</strong></h4>
					<h2>
						<strong>{{
							+readyLoanInterview + +totalCredit + +totalFileReview
							}}</strong>
					</h2>
					<h2>
						<strong>{{
							+totalRLIAmount + +totalCBAmount + +totalFRAmount | currency
							}}</strong>
					</h2>
				</div>
			</div>
			<!-- <div class="card">
                <div class="container">
                <h4><strong>Ready For Loan Interview</strong></h4>
                <h2><strong>{{readyLoanInterview}}</strong></h2>
                <h2><strong>{{totalRLIAmount | currency}}</strong></h2>
                </div>
            </div>
            <div class="card">
                <div class="container">
                <h4><strong>Credit Bureau</strong></h4>
                <h2><strong>{{totalCredit}}</strong></h2>
                <h2><strong>{{totalCBAmount | currency}}</strong></h2>
                </div>
            </div>
            <div class="card">
                <div class="container">
                <h4><strong>File Review</strong></h4>
                <h2><strong>{{totalFileReview}}</strong></h2>
                <h2><strong>{{totalFRAmount | currency}}</strong></h2>
                </div>
            </div> -->
			<div class="card" id="callWidth">
				<div class="container">
					<h4><strong>Call Attempts</strong></h4>
					<h2>
						<strong>0: {{ zeroInterviewAttempts }} | 1:
							{{ oneInterviewAttempts }}</strong>
					</h2>
					<h2>
						<strong>1 < {{ moreInterviewAttempts }}</strong>
					</h2>
				</div>
			</div>
		</div>
	</div>
	<div>
		<ul class="tabs" role="tablist">
			<li>
				<input type="radio" name="tabs" id="tab1" checked />
				<label for="tab1" role="tab" aria-selected="true" aria-controls="panel1" tabindex="0">Ready for Loan
					Interview Queue<br />
					<span class="totalRowPipeline">[{{ readyLoanInterview }}] {{ totalRLIAmount | currency }}</span>
				</label>
				<div id="tab-content1" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-4">
							<h5>
								0 Call Attempts ( {{ zeroInterviewAttempts }} |
								{{ totalZeroAmount | currency }} )
							</h5>
							<table class="table table-bordered table-hover" id="myTable">
								<thead>
									<tr>
										<th>Name</th>
										<th>Province</th>
										<th>Modified Time</th>
										<th>Agency Name</th>
									</tr>
								</thead>
								<tbody id="myBody">
									<div *ngIf="
											!zeroInterviewArray || zeroInterviewArray.length === 0;
											else contentLT0
										">
										No Ready for loan interview!
									</div>
									<ng-template #contentLT0>
										<tr *ngFor="
												let item of zeroInterviewArray
													| paginate
														: {
																id: 'pagination0',
																itemsPerPage: count0,
																currentPage: p0
														  }
											">
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Province }}</td>
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												{{ item.Agency_Formula }} -
												{{ item.Collector_Formula }} |
												{{ item['Created_By.email'].split('@')[0] }}
											</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
							<div id="paginate" class="text-right">
								<pagination-controls id="pagination0" (pageChange)="p0 = $event"></pagination-controls>
							</div>
						</div>
						<div class="col-4">
							<h5>
								1 Call Attempts ( {{ oneInterviewAttempts }} |
								{{ totalOneAmount | currency }} )
							</h5>
							<table class="table table-bordered table-hover" id="myTable">
								<thead>
									<tr>
										<th>Name</th>
										<th>Province</th>
										<th>Modified Time</th>
										<th>Agency Name</th>
									</tr>
								</thead>
								<tbody id="myBody">
									<div *ngIf="
											!oneInterviewArray || oneInterviewArray.length === 0;
											else contentLT1
										">
										No Ready for loan interview!
									</div>
									<ng-template #contentLT1>
										<tr *ngFor="
												let item of oneInterviewArray
													| paginate
														: {
																id: 'pagination1',
																itemsPerPage: count1,
																currentPage: p1
														  }
											">
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Province }}</td>
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												{{ item.Agency_Formula }} -
												{{ item.Collector_Formula }} |
												{{ item['Created_By.email'].split('@')[0] }}
											</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
							<div id="paginate" class="text-right">
								<pagination-controls id="pagination1" (pageChange)="p1 = $event"></pagination-controls>
							</div>
						</div>
						<div class="col-4">
							<h5>
								>1 Call Attempts ( {{ moreInterviewAttempts }} |
								{{ totalMoreAmount | currency }} )
							</h5>
							<table class="table table-bordered table-hover" id="myTable">
								<thead>
									<tr>
										<th>Call Count</th>
										<th>Name</th>
										<th>Province</th>
										<th>Modified Time</th>
										<th>Agency Name</th>
									</tr>
								</thead>
								<tbody id="myBody">
									<div *ngIf="
											!moreInterviewArray || moreInterviewArray.length === 0;
											else contentLTM
										">
										No Ready for loan interview!
									</div>
									<ng-template #contentLTM>
										<tr *ngFor="
												let item of moreInterviewArray
													| paginate
														: {
																id: 'paginationM',
																itemsPerPage: countM,
																currentPage: pM
														  }
											">
											<td style="text-align: center">
												{{ item.RLI_Called_Count }}
											</td>
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Province }}</td>
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												{{ item.Agency_Formula }} -
												{{ item.Collector_Formula }} |
												{{ item['Created_By.email'].split('@')[0] }}
											</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
							<div id="paginate" class="text-right">
								<pagination-controls id="paginationM" (pageChange)="pM = $event"></pagination-controls>
							</div>
						</div>
					</div>
				</div>
			</li>

			<li>
				<input type="radio" name="tabs" id="tab2" />
				<label for="tab2" role="tab" aria-selected="true" aria-controls="panel2" tabindex="0">Budget Assessment
					Queue<br />
					<span class="totalRowPipeline">[{{ totalCredit }}] {{ totalCBAmount | currency }}</span>
				</label>
				<div id="tab-content2" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-6">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>Modified Time</th>
										<th>Name</th>
										<th>Agency</th>
										<th>Drive</th>
									</tr>
								</thead>
								<tbody>
									<div *ngIf="
											!creditArray || creditArray.length === 0;
											else contentCB
										">
										No Budget Assessment!
									</div>
									<ng-template #contentCB>
										<tr *ngFor="let item of creditArray">
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Agency_Formula }}</td>
											<td>
												<a class="noline" target="_self"
													href="{{ item.gdriveextension__Drive_URL }}">Open</a>
											</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</li>

			<li>
				<input type="radio" name="tabs" id="tab3" />
				<label for="tab3" role="tab" aria-selected="true" aria-controls="panel3" tabindex="0">File Review
					Queue<br />
					<span class="totalRowPipeline">[{{ totalFileReview }}] {{ totalFRAmount | currency }}</span>
				</label>
				<div id="tab-content3" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-6">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>Modified Time</th>
										<th>Name</th>
										<th>Agency</th>
									</tr>
								</thead>
								<tbody>
									<div *ngIf="
											!fileReviewArray || fileReviewArray.length === 0;
											else contentFR
										">
										No File Review!
									</div>
									<ng-template #contentFR>
										<tr *ngFor="let item of fileReviewArray">
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Agency_Formula }}</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</li>

			<li>
				<input type="radio" name="tabs" id="tab4" />
				<label for="tab4" role="tab" aria-selected="true" aria-controls="panel4" tabindex="0">Scheduled Loan
					Interviews<br />
					<span>[{{ totalSchedule }}]</span>
				</label>
				<div id="tab-content4" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-8">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>Modified Time</th>
										<th>Subject</th>
										<th>Call Start Time</th>
										<th>Call Type</th>
										<th>Call Status</th>
										<th>Owner</th>
										<th style="text-align: center">Actions</th>
									</tr>
								</thead>
								<tbody id="myBody">
									<div *ngIf="
											!scheduleArray || scheduleArray.length === 0;
											else contentSLI
										">
										No Scheduled Loan Interviews!
									</div>
									<ng-template #contentSLI>
										<tr *ngFor="let item of scheduleArray">
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												<a class="noline" target="_self" [routerLink]="[
														'/applications/summary',
														item['What_Id']['id']
													]" [queryParams]="{ dealname: item.Deal_Name }">{{ item.Subject }}</a>
											</td>
											<td>{{ item.Call_Start_Time | date : 'medium' }}</td>
											<td>{{ item.Call_Type }}</td>
											<td>{{ item.Call_Status }}</td>
											<td>{{ item['Owner.email'].split('@')[0] }}</td>
											<td style="text-align: center">
												<button class="btn btn-secondary" (click)="
														updateCallSchedule(item.id, item.Subject, 'cancel')
													">
													Cancel
												</button>
											</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</li>

			<li>
				<input type="radio" name="tabs" id="tab5" />
				<label for="tab5" role="tab" aria-selected="true" aria-controls="panel5" tabindex="0">Underwriting
					Checklist<br />
					<span>[{{ totalUC }}]</span>
				</label>
				<div id="tab-content5" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-8">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>Modified Time</th>
										<th>Name</th>
										<th>Agency</th>
									</tr>
								</thead>
								<tbody>
									<div *ngIf="
											!underwritingChecklist ||
												underwritingChecklist.length === 0;
											else contentUC
										">
										No Underwriting Checklist!
									</div>
									<ng-template #contentUC>
										<tr *ngFor="let item of underwritingChecklist">
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Agency_Formula }}</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</li>

			<li>
				<input type="radio" name="tabs" id="tab6" />
				<label for="tab6" role="tab" aria-selected="true" aria-controls="panel6" tabindex="0">UW needs
					docs<br />
					<span>[{{ total_uw_needs_docs }}]</span>
				</label>
				<div id="tab-content6" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-10">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>Modified Time</th>
										<th>Name</th>
										<th>Province</th>
										<th>Agency</th>
										<th>Most Recent Note</th>
									</tr>
								</thead>
								<tbody>
									<div *ngIf="
											!uw_needs_docs || uw_needs_docs.length === 0;
											else content_uw_needs_docs
										">
										No UW needs docs!
									</div>
									<ng-template #content_uw_needs_docs>
										<tr *ngFor="let item of uw_needs_docs">
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Province }}</td>
											<td>{{ item.Agency_Formula }}</td>
											<td>{{ item.Reason_for_Poor_credit_score }}</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</li>

			<li>
				<input type="radio" name="tabs" id="tab7" />
				<label for="tab7" role="tab" aria-selected="true" aria-controls="panel7" tabindex="0">UW
					unresponsive<br />
					<span>[{{ total_uw_unresponsive }}]</span>
				</label>
				<div id="tab-content7" class="tab-content" role="tabpanel" aria-labelledby="description"
					aria-hidden="false">
					<div class="row row-cols-12">
						<div class="col-10">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>Modified Time</th>
										<th>Name</th>
										<th>Province</th>
										<th>Agency</th>
										<th>Most Recent Note</th>
									</tr>
								</thead>
								<tbody>
									<div *ngIf="
											!uw_unresponsive || uw_unresponsive.length === 0;
											else content_uw_unresponsive
										">
										No UW unresponsive!
									</div>
									<ng-template #content_uw_unresponsive>
										<tr *ngFor="let item of uw_unresponsive">
											<td>{{ item.Modified_Time | date : 'medium' }}</td>
											<td>
												<a class="noline" target="_self"
													[routerLink]="['/applications/summary', item.id]"
													[queryParams]="{ dealname: item.Deal_Name }">{{ item.Deal_Name
													}}</a>
											</td>
											<td>{{ item.Province }}</td>
											<td>{{ item.Agency_Formula }}</td>
											<td>{{ item.Reason_for_Poor_credit_score }}</td>
										</tr>
									</ng-template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>