import { Component, OnInit } from '@angular/core';
import { now } from 'moment';
import { ApiServiceService } from '../../../api-service.service';
import { CustomFunctionService } from '../../../custom-function.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-underwritingexception',
	templateUrl: './underwritingexception.component.html',
	styleUrls: ['./underwritingexception.component.scss'],
})
export class UnderwritingexceptionComponent implements OnInit {
	// currentkMonth = new Date(now()).getMonth();

	timestamp = Date();
	pmaArray: [];
	totalPMA: any;
	totalPMAAmount: any;

	exceptionArray: [];
	totalException: any;
	totalExceptionAmount: any;

	groupAgencyArray: string[] = [];
	countGroupAgency: any;
	amountGroupAgency: any;

	groupOverrideTypeArray: string[] = [];
	countGroupOverrideType: any;
	amountGroupOverrideType: any;

	groupOverrideArray: string[] = [];
	countGroupOverride: any;
	amountGroupOverride: any;

	groupOverrideArrayHamza: string[] = [];
	groupOverrideArrayHari: string[] = [];

	countHQQAA: any;
	amountHQQAA: any;

	countMO: any;
	amountMO: any;
	countIncentive: any;
	amountIncentive: any;

	countApproved: any;
	amountApproved: any;

	totalNLSfundedMonth: any;

	underwritingArray: [];

	p = 1;
	count = 10;

	pQ = 1;
	countQ = 10;

	pEx = 1;
	countEx = 20;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private _routerLink: Router
	) {}

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchUnderwritingPMA();
						this.fetchUnderwritingException();
						this.getNLSFundedMonth();
						this.getUnderwritingMonthly();
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	fetchUnderwritingPMA() {
		this.apiService.getDealsCreatedUnderwritingPMA().subscribe((res) => {
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.pmaArray = arr.data;
				this.customFunction.sortColumn(this.pmaArray, 'Created_Time', -1, 1);

				this.totalPMA = this.pmaArray.length;
				this.totalPMAAmount = this.customFunction
					.sumNumbers(this.pmaArray, 'Amount')
					.toFixed(2);
			} else {
				this.pmaArray = [];
				this.totalPMA = 0;
				this.totalPMAAmount = 0;
			}
		});
	}

	fetchUnderwritingException() {
		this.apiService.getDealsCreatedUnderwritingException().subscribe((res) => {
			// console.log(res)
			if (res.message.statusCode === 200) {
				let arr = JSON.parse(res.message.body);
				this.exceptionArray = arr.data;
				this.customFunction.sortColumn(
					this.exceptionArray,
					'Exception_Funded_Amount',
					-1,
					1
				);

				this.totalException = this.exceptionArray.length;
				this.totalExceptionAmount = this.customFunction
					.sumNumbers(this.exceptionArray, 'Exception_Funded_Amount')
					.toFixed(2);

				//---------------------------------------------AGENCY----------------------------------------------------------------------------
				//  Loop the filtered Agency through the response array to create a new grouped Agency Array

				const filterAgency = this.customFunction.filterDuplicates(
					arr.data,
					'Agency_Formula'
				);
				for (let i = 0; i < filterAgency.length; i++) {
					let count = 0;
					let subTotalAmount = 0;
					let fieldName = '';
					for (let j = 0; j < arr.data.length; j++) {
						if (filterAgency[i] === arr.data[j].Agency_Formula) {
							fieldName = arr.data[j].Agency_Formula; // agency name
							subTotalAmount += arr.data[j].Exception_Funded_Amount; // sum the amount of the agency occurrences
							count++; // count the agency occurrences
						}
					}
					this.groupAgencyArray.push(
						new this.customFunction.groupByName(
							fieldName,
							count,
							subTotalAmount
						)
					); // Push the loop objects to the new array list: groupedStageInfo
					this.countGroupAgency = this.customFunction.sumNumbers(
						this.groupAgencyArray,
						'count'
					);
					this.amountGroupAgency = this.customFunction.sumNumbers(
						this.groupAgencyArray,
						'amount'
					);
				}
				this.customFunction.sortColumn(this.groupAgencyArray, 'amount', -1, 1); // Sort by Amount Highest to Lowest

				//---------------------------------------------Override----------------------------------------------------------------------------
				const filterOverride = this.customFunction.filterDuplicates(
					arr.data,
					'Underwriting_Override'
				);
				for (let i = 0; i < filterOverride.length; i++) {
					let count = 0;
					let subTotalAmount = 0;
					let fieldName = '';
					for (let j = 0; j < arr.data.length; j++) {
						if (filterOverride[i] === arr.data[j].Underwriting_Override) {
							fieldName = arr.data[j].Underwriting_Override;
							subTotalAmount += arr.data[j].Exception_Funded_Amount;
							count++;
						}
					}
					this.groupOverrideArray.push(
						new this.customFunction.groupByName(
							fieldName,
							count,
							subTotalAmount
						)
					);
					this.countGroupOverride = this.customFunction.sumNumbers(
						this.groupOverrideArray,
						'count'
					);
					this.amountGroupOverride = this.customFunction.sumNumbers(
						this.groupOverrideArray,
						'amount'
					);
				}

				this.customFunction.sortColumn(
					this.groupOverrideArray,
					'amount',
					-1,
					1
				);

				// Override breakdown Hamza
				const filterOverrideHamza = this.customFunction.singleFilterName(
					arr.data,
					'Underwriting_Override',
					'Hamza'
				);
				const filterOverrideTypeHamza = this.customFunction.filterDuplicates(
					filterOverrideHamza,
					'Override_Type'
				);
				for (let i = 0; i < filterOverrideTypeHamza.length; i++) {
					let count = 0;
					let subTotalAmount = 0;
					let fieldName = '';
					for (let j = 0; j < filterOverrideHamza.length; j++) {
						if (
							filterOverrideTypeHamza[i] ===
							filterOverrideHamza[j].Override_Type
						) {
							fieldName = filterOverrideHamza[j].Override_Type;
							subTotalAmount += filterOverrideHamza[j].Exception_Funded_Amount;
							count++;
						}
					}
					this.groupOverrideArrayHamza.push(
						new this.customFunction.groupByName(
							fieldName,
							count,
							subTotalAmount
						)
					);
				}

				// Override breakdown Hari
				const filterOverrideHari = this.customFunction.singleFilterName(
					arr.data,
					'Underwriting_Override',
					'By Hari'
				);
				const filterOverrideTypeHari = this.customFunction.filterDuplicates(
					filterOverrideHari,
					'Override_Type'
				);
				for (let i = 0; i < filterOverrideTypeHari.length; i++) {
					let count = 0;
					let subTotalAmount = 0;
					let fieldName = '';
					for (let j = 0; j < filterOverrideHari.length; j++) {
						if (
							filterOverrideTypeHari[i] === filterOverrideHari[j].Override_Type
						) {
							fieldName = filterOverrideHari[j].Override_Type;
							subTotalAmount += filterOverrideHari[j].Exception_Funded_Amount;
							count++;
						}
					}
					this.groupOverrideArrayHari.push(
						new this.customFunction.groupByName(
							fieldName,
							count,
							subTotalAmount
						)
					);
				}

				//---------------------------------------------Override Type----------------------------------------------------------------------------
				const filterOverrideType = [
					'Agency Incentive',
					'Manager Override',
					'HQQAA',
					'Customer Paid down',
					'Internal Error',
					'Approved Amount Increase',
					'Loyalty Payment Program',
					'CC/CP Exception',
					'Agency Owner Exception',
					'Money Mart Exception',
					'HQQAA - High Risk',
					'Secured Bridge Loan',
					null,
					'No',
					'Yes',
				];
				// const filterOverrideType = this.customFunction.filterDuplicates(arr.data,'Override_Type')
				for (let i = 0; i < filterOverrideType.length; i++) {
					let count = 0;
					let subTotalAmount = 0;
					let fieldName = '';
					for (let j = 0; j < arr.data.length; j++) {
						fieldName = filterOverrideType[i]; // This will display all the Override Type
						if (filterOverrideType[i] === arr.data[j].Override_Type) {
							// fieldName = arr.data[j].Override_Type    // This will display only the ones that are available with names
							subTotalAmount += arr.data[j].Exception_Funded_Amount;
							count++;
						}
					}
					this.groupOverrideTypeArray.push(
						new this.customFunction.groupByName(
							fieldName,
							count,
							subTotalAmount
						)
					);
					this.countGroupOverrideType = this.customFunction.sumNumbers(
						this.groupOverrideTypeArray,
						'count'
					);
					this.amountGroupOverrideType = this.customFunction.sumNumbers(
						this.groupOverrideTypeArray,
						'amount'
					);
				}
				this.customFunction.sortColumn(
					this.groupOverrideTypeArray,
					'amount',
					-1,
					1
				);
				// console.log(this.groupOverrideTypeArray)

				const resultHQQAA = this.customFunction.singleFilterName(
					this.groupOverrideTypeArray,
					'name',
					'HQQAA'
				);
				this.countHQQAA = resultHQQAA[0]['count'];
				this.amountHQQAA = resultHQQAA[0]['amount'];

				const resultMO = this.customFunction.singleFilterName(
					this.groupOverrideTypeArray,
					'name',
					'Manager Override'
				);
				this.countMO = resultMO[0]['count'];
				this.amountMO = resultMO[0]['amount'];

				const resultIncentive = this.customFunction.singleFilterName(
					this.groupOverrideTypeArray,
					'name',
					'Agency Incentive'
				);
				this.countIncentive = resultIncentive[0]['count'];
				this.amountIncentive = resultIncentive[0]['amount'];

				const resultApproved = this.customFunction.singleFilterName(
					this.groupOverrideTypeArray,
					'name',
					'Approved Amount Increase'
				);
				this.countApproved = resultApproved[0]['count'];
				this.amountApproved = resultApproved[0]['amount'];

				// console.log(resultHQQAA)
			} else {
				this.exceptionArray = [];
				this.totalException = 0;
				this.totalExceptionAmount = 0;

				this.countHQQAA = 0;
				this.amountHQQAA = 0;

				this.countMO = 0;
				this.amountMO = 0;

				this.countIncentive = 0;
				this.amountIncentive = 0;

				this.countApproved = 0;
				this.amountApproved = 0;
			}
		});
	}

	//----------------------------------------------Underwriting Queue monthly--------------------------------
	getUnderwritingMonthly() {
		this.apiService
			.getDealsCreatedUnderwritingRLImonthly()
			.subscribe((res: any) => {
				let arr = JSON.parse(res.message.body);
				this.underwritingArray = arr.data;
				this.customFunction.sortColumn(this.underwritingArray, 'Amount', -1, 1);
			});
	}

	//---------------------------------------------------------------NLS Funded Month------------------------------------
	getNLSFundedMonth() {
		const currentMonth = new Date(now()).getMonth();
		let sendMonth = currentMonth;
		if (sendMonth < 1) {
			sendMonth = 0;
		}
		this.apiService.getNLSFundedMonth(sendMonth).subscribe((res) => {
			this.totalNLSfundedMonth = this.customFunction
				.sumNumbers(res.data, 'TotalAmount')
				.toFixed(2);
		});
	}

	refresh() {
		window.location.reload();
	}
}
