import { ValueFormatterParams } from "ag-grid-community";

export function percent(value: any) {
    if (isNaN(value))
        return null
    else {
        return (parseFloat(value).toFixed(2)).concat('%')
    }
}

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
};

// Currency formatter on ag-grid
export const currencyFormatterAgGrid = (params: ValueFormatterParams) => {
    return formatNumber(params.value, '$');
}

function formatNumber(currency, sign) {
    // this puts commas into the number eg 1000 goes to 1,000,
    let rawNum = currency?.toFixed(2);     // Round off to two decimal places
    let formatted = (typeof rawNum === 'undefined') ? 0 : rawNum?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
}