import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';

import { ApiServiceService } from '@lmc-app/api-service.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-ptp-created-report',
  templateUrl: './ptp-created-report.component.html',
  styleUrls: ['./ptp-created-report.component.scss']
})
export class PtpCreatedReportComponent implements OnInit {
  public startDate = new Date(new Date().setDate(new Date().getDate()))
		.toISOString()
		.split('T')[0];
	public endDate = new Date().toISOString().split('T')[0];

  public ptpCreatedList: any;
  public ptpCreatedTotals: any;
  public ptpCreatedDetailedList: any;
  public ptpExcelExportFileName = `PTP Created Report_${this.startDate}-${this.endDate}.xlsx`;

  @ViewChild('ptpCreatedTable') PTPCreatedTable: ElementRef;
  @ViewChild('ptpCreatedDetailedTable') PTPCreatedDetailedTable: ElementRef;

  constructor(private apiService: ApiServiceService) { }

  ngOnInit(): void {
  }

  getPtpCreatedReport(startDate?:string, endDate?:string) {
    console.log("Using these dates", startDate, endDate)
    const endpoint = '/api/ptpCreatedReport';
  const url = window.location.origin + endpoint;
    console.log('Request URL:', url);


    this.apiService.getPtpCreatedReport(startDate, endDate).subscribe((res: any) => {
      // console.log(res?.data);
      const ptpCreatedData = res?.data;
      console.log("ptpCreatedData", ptpCreatedData)
      const totalP2PCreated = ptpCreatedData.map((t)=> t['P2PCreated']).reduce((acc, value) => acc + value, 0)
      const totalP2PCreatedAmount = ptpCreatedData.map((t)=> t['P2PCreatedAmount']).reduce((acc, value) => acc + value, 0); 
      // console.log("total P2P Created", totalP2PCreated)
      // console.log("total P2P Created Amount", totalP2PCreatedAmount)
      this.ptpCreatedTotals = {totalP2PCreated, totalP2PCreatedAmount}
      console.log("ptpCreatedTotals", this.ptpCreatedTotals)
      this.ptpCreatedList = ptpCreatedData;
})}
}