import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss']
})
export class HomeDashboardComponent implements OnInit {
  constructor(private apiService: ApiServiceService) { };
  // @Output() agencyIdSent = new EventEmitter<string>();

  sessionInfo: any;
  adminView: any;
  isExternalView: boolean;
  agencyIdSent: string;
  ngOnInit(): void {
    if (localStorage.getItem('SESSIONID')) {
      this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe(async (res: any) => {
        if (res.message === 'Success') {
          this.sessionInfo = res?.['data']?.[0];
          this.adminView = this.sessionInfo?.['collection_dashboard_metrics'] === null ? false : this.sessionInfo?.['collection_dashboard_metrics'];  // Defining Admin from the permission_component table
          this.isExternalView = this.sessionInfo?.['roleID'] === 12 ? true : false;
          // this.agencyIdSent.emit(this.sessionInfo?.['agency_zoho_id']);
          this.agencyIdSent = this.sessionInfo?.['agency_zoho_id'];
        }
      });
    }
  }

}
