import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
@Component({
	selector: 'app-custom-tooltip',
	templateUrl: './custom-tooltip.component.html',
	styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
	private params!: { color: string } & ITooltipParams;
	public data!: any;
	public color!: string;

	agInit(params: { color: string } & ITooltipParams): void {
		this.params = params;

		this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
		this.color = this.params.color || 'white';
	}
}
