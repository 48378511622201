import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-ptp-report',
	templateUrl: './ptp-report.component.html',
	styleUrls: ['./ptp-report.component.scss'],
})
export class PtpReportComponent implements OnInit, OnChanges {
	public startDate = new Date(new Date().setDate(new Date().getDate()))
		.toISOString()
		.split('T')[0];
	public endDate = new Date().toISOString().split('T')[0];
	public prevWeekStartDate = new Date(new Date().setDate(new Date().getDate() - 7))
		.toISOString()
		.split('T')[0];
	public prevWeekEndDate = new Date(new Date().setDate(new Date().getDate() - 7))
		.toISOString()
		.split('T')[0];

	public ptpList: any;
	public ptpTotals: any;
	public ptpDetailedList: any;
	public ptpLoanGroupList: any;
	public ptpExcelExportFileName = 'PTP Report.xlsx';

	public prevWeekptpList: any;
	public prevWeekptpTotals: any;
	public prevWeekptpDetailedList: any;
	public prevWeekptpLoanGroupList: any;
	public prevWeekptpExcelExportFileName = 'PrevWeek PTP Report.xlsx';
	@Input() loanGroupSelected = 'All';

	@ViewChild('ptpTable') PTPTable: ElementRef;
	@ViewChild('ptpDetailedTable') PTPDetailedTable: ElementRef;
	@ViewChild('ptpLoanGroup') PTPLoanGroupTable: ElementRef;

	constructor(private apiService: ApiServiceService) { }

	ngOnInit(): void {

		// this.getPtpReport();

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.loanGroupSelected) {
			this.getPtpReport(false);
		}
	}

	getPtpReport(loadHiddenReports: boolean = true, startDate?: string, endDate?: string) {

		console.log("using these dates", startDate, endDate)
		const endpoint = '/api/ptpReport';
		const url = window.location.origin + endpoint;
		console.log('Request URL:', url);
		const applyFilter =
			(this.loanGroupSelected && this.loanGroupSelected) !== 'All'
				? this.loanGroupSelected
				: '';
		this.apiService
			.getPtpReport(startDate, endDate, applyFilter, '', 'PtP_Date')
			.subscribe((res: any) => {
				console.log(res?.data);
				const ptpData = res?.data;
				const totalPTPPaid = ptpData
					.map((t) => t['Total PTP Paid ($)'])
					.reduce((acc, val) => acc + val, 0);

				console.log("totalPTPPaid", totalPTPPaid)
				const totalPTPPaidCount = ptpData
					.map((t) => t['Total PTP Paid Count'])
					.reduce((acc, val) => acc + val, 0);
				const totalManualDebit = ptpData
					.map((t) => t['Manual Debit ($)'])
					.reduce((acc, val) => acc + val, 0);
				const totalManualDebitCount = ptpData
					.map((t) => t['Manual Debit Count'])
					.reduce((acc, val) => acc + val, 0);
				const totalCustomerDeposit = ptpData
					.map((t) => t['Customer Deposit ($)'])
					.reduce((acc, val) => acc + val, 0);
				const totalCustomerDepositCount = ptpData
					.map((t) => t['Customer Deposit Count'])
					.reduce((acc, val) => acc + val, 0);
				const totalPTPNSF = ptpData
					.map((t) => t['Total PTP NSF ($)'])
					.reduce((acc, val) => acc + val, 0);
				const totalPTPNSFCount = ptpData
					.map((t) => t['Total PTP NSF Count'])
					.reduce((acc, val) => acc + val, 0);
				const totalPTPBroken = ptpData
					.map((t) => t['Total PTP Broken ($)'])
					.reduce((acc, val) => acc + val, 0);
				const totalPTPBrokenCount = ptpData
					.map((t) => t['Total PTP Broken Count'])
					.reduce((acc, val) => acc + val, 0);
				const totalAllPTPs = ptpData
					.map((t) => t['All PTPs ($)'])
					.reduce((acc, val) => acc + val, 0);
				const totalAllPTPsCount = ptpData
					.map((t) => t['All PTPs Count'])
					.reduce((acc, val) => acc + val, 0);
				if (startDate == this.startDate) {
					this.ptpTotals = {
						totalPTPPaid,
						totalPTPPaidCount,
						totalManualDebit,
						totalManualDebitCount,
						totalCustomerDeposit,
						totalCustomerDepositCount,
						totalPTPNSF,
						totalPTPNSFCount,
						totalPTPBroken,
						totalPTPBrokenCount,
						totalAllPTPs,
						totalAllPTPsCount,
					};
				}
				else {
					this.prevWeekptpTotals = {
						totalPTPPaid,
						totalPTPPaidCount,
						totalManualDebit,
						totalManualDebitCount,
						totalCustomerDeposit,
						totalCustomerDepositCount,
						totalPTPNSF,
						totalPTPNSFCount,
						totalPTPBroken,
						totalPTPBrokenCount,
						totalAllPTPs,
						totalAllPTPsCount,
					};
				}
				if (startDate == this.startDate) {
					this.ptpList = ptpData;
				}
				else {
					this.prevWeekptpList = ptpData;
				}
			});

		if (loadHiddenReports) {
			this.getPtpDetailedReport(startDate, endDate);
			this.getPtpLoanGroupReport(startDate, endDate);
		}
	}

	getPtpDetailedReport(startDate?: string, endDate?: string) {
		this.apiService
			.getPtpDetailedReport(startDate, endDate, '', 'PtP_Date')
			.subscribe((res: any) => {
				if (startDate == this.startDate) {
					this.ptpDetailedList = res?.data;
				}
				else {
					this.prevWeekptpDetailedList = res?.data;
				}
			});
	}

	getPtpLoanGroupReport(startDate?: string, endDate?: string) {
		this.apiService
			.getPtpLoanGroupReport(startDate, endDate, '', 'PtP_Date')
			.subscribe((res: any) => {
				if (startDate == this.startDate) {
					this.ptpLoanGroupList = res?.data;
				}
				else {
					this.prevWeekptpLoanGroupList = res?.data;
				}
			});
	}

	// Todo: Move this to utils
	exportToExcel(sheets?: Array<{ table: any; tableName: string }>, startDate?: string, endDate?: string) {

		const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
			this.PTPTable.nativeElement
		);
		const worksheetDetailed: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
			this.PTPDetailedTable.nativeElement
		);
		const worksheetLoanGroup: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
			this.PTPLoanGroupTable.nativeElement
		);
		const workbook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			workbook,
			worksheet,
			`PTP ${startDate}-${endDate}`
		);
		XLSX.utils.book_append_sheet(
			workbook,
			worksheetDetailed,
			`PTP D ${startDate}-${endDate}`
		);
		XLSX.utils.book_append_sheet(
			workbook,
			worksheetLoanGroup,
			`PTP LG ${startDate}-${endDate}`
		);
		XLSX.writeFile(workbook, this.ptpExcelExportFileName, { bookType: 'xlsx' });
	}
}
