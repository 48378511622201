import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../../../api-service.service';

@Component({
	selector: 'app-nlsexceptioncollections',
	templateUrl: './nlsexceptioncollections.component.html',
	styleUrls: ['./nlsexceptioncollections.component.scss'],
})
export class NlsexceptioncollectionsComponent implements OnInit {
	constructor(private apiService: ApiServiceService) {}

	exceptionList: any;
	startDate: any;
	endDate: any;
	asOfDate: any;

	underwriterBreakdownList: any;
	exceptionBreakdownList: any;

	ngOnInit(): void {}

	getNLSExceptions(start, end, asOf) {
		this.apiService
			.nlsExceptionCollections(start, end, asOf)
			.subscribe((res: any) => {
				this.exceptionList = res['data'];
				console.log(res);
			});
	}

	async filterReport() {
		this.getNLSExceptions(this.startDate, this.endDate, this.asOfDate);
		this.underwriterBreakdownList = await this.getDetailedBreakdown(
			this.startDate,
			this.endDate,
			this.asOfDate,
			'Underwriter'
		);
		this.exceptionBreakdownList = await this.getDetailedBreakdown(
			this.startDate,
			this.endDate,
			this.asOfDate,
			'Exception'
		);
	}

	async getDetailedBreakdown(start, end, asOf, reportType) {
		return new Promise((resolve, reject) => {
			this.apiService
				.nlsExceptionDetailedBreakdownCollections(start, end, asOf, reportType)
				.subscribe((res: any) => {
					if (res['data'].Length != 0) resolve(res['data']);
				});
		});
	}
}
