import {
	UntypedFormControl,
	UntypedFormGroup,
	FormBuilder,
	Validators,
	FormArray,
} from '@angular/forms';
import { Controls } from '../models/controls';

export class NoteEntry {
	noteForm: UntypedFormGroup;
	displayAdminNotes: any;
	displayUserNotes: any;
	displayZohoNotes: any;
	subjects: any;
	// stageName: any;
	editID: any;
	recentNotes: any;
	displayCommunication = [
		{ ref_value: 'IB', display_value: 'Inbound' },
		{ ref_value: 'OB', display_value: 'Outbound' },
		{ ref_value: 'INT', display_value: 'Internal' },
		{ ref_value: 'EMAIL', display_value: 'Email' },
		{ ref_value: 'FAX', display_value: 'Fax' },
		{ ref_value: 'Internal Email', display_value: 'Internal Email' },
		{ ref_value: 'External Email', display_value: 'External Email' },
	];

	constructor() {
		// applicationForm controls define objects
		this.noteForm = new UntypedFormGroup({
			subject: new UntypedFormControl('', Validators.required),
			description: new UntypedFormControl(''),
			communication: new UntypedFormControl('', Validators.required),
			note: new UntypedFormControl('', Validators.required),
			RLI_Called_Count: new UntypedFormControl(''),
			email_notes: new UntypedFormControl(''),
			email_address: new UntypedFormControl(''),
			// 'Stage': new FormControl('', Validators.required)
		});
	}
}
