<!-- <h1 style="text-align: center;">Pre-Approval</h1> -->
<div style="padding-left: 3%; padding-top:3%;height:100%; overflow: auto;">
    <img src="assets/images/lmcredit.png" alt="" class="mb-05">
    <div *ngIf="createApplicationFlag">
        <div *ngIf="prelim_view">
            <h5>Are any of the following statements true for this client?</h5>
            <ul>
                <li>Is the client currently residing on crown land or a reserve?</li>
                <li>Does the client earn less than $1,200 monthly?</li>
                <li>Is the client still on probation at his/her place of employment?</li>
                <li>Is the client in any form of insolvency? This includes bankruptcy, consumer proposal or credit
                    counselling.</li>
            </ul>
            <select name="pre" id="pre" style="width:70px" [(ngModel)]="pre_questions">
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select><br><br>
            <button class="buttonSubmit" (click)="submitPrelimQuestions()">Next</button>
        </div>
        <div *ngIf="!prelim_view">
            <div style="display: inline-block">
                <label><strong>Loan Amount</strong></label><br>
                <input type='number' placeholder="Loan_Amount" [(ngModel)]="model.loan_amount" name='Loan_Amount'>
            </div>
            <div style="padding-left:2%;display: inline-block">
                <label><strong>Token</strong></label><br>
                <input type="password" placeholder="Token" name='Token' [(ngModel)]="model.token">
            </div>
            <div style="padding-left:2%;display: inline-block">
                <label><strong>Email</strong></label><br>
                <input placeholder="Email" name='Email' [(ngModel)]="model.email">
            </div>
            <br>
            <div style="padding-top:1%;display: inline-block">
                <label><strong>First Name</strong></label><br>
                <input placeholder="First Name" [(ngModel)]="model.first_name" name='First_Name'>
            </div>
            <div style="padding-left:2%;display: inline-block">
                <label><strong>Middle Name</strong></label><br>
                <input placeholder="Middle Name" [(ngModel)]="model.middle_name" name='Middle_Name'>
            </div>
            <div style="padding-left:2%;display: inline-block">
                <label><strong>Last Name</strong></label><br>
                <input placeholder="Last Name" [(ngModel)]="model.last_name" name='Last_Name'>
            </div>
            <br>
            <div style="padding-top:1%;display: inline-block">
                <label><strong>SIN</strong></label><br>
                <input type=number placeholder="SIN" [(ngModel)]="model.sin" name='sin'>
            </div>
            <div style="padding-top:1%;padding-left:2%;display: inline-block">
                <label><strong>Phone Number</strong></label><br>
                <input placeholder="Phone" [(ngModel)]="model.telephone" name='Phone'>
            </div>
            <div style="padding-top:1%;padding-left:2%;display: inline-block">
                <label><strong>Date of Birth</strong></label><br>
                <input placeholder="Date of Birth" [(ngModel)]="model.dob" name='Date_of_Birth' type="date"
                    style="width:160px">
            </div>
            <br>
            <div style="padding-top:1%;display: inline-block">
                <label><strong>Street No.</strong></label><br>
                <input readonly placeholder="Street Number">
            </div>
            <div style="padding-top:1%;padding-left:2%;display: inline-block">
                <label><strong>Address</strong></label><br>
                <input style="width:210%" placeholder="Address" [(ngModel)]="model.street" name='Address'>
            </div>
            <br>
            <div style="padding-top:1%;display: inline-block">
                <label><strong>City</strong></label><br>
                <input placeholder="City" [(ngModel)]="model.city" name='City'>
            </div>
            <div style="padding-top:1%;padding-left:2%;display: inline-block">
                <label><strong>Province</strong></label><br>
                <input placeholder="Province" [(ngModel)]="model.province" name='Province'>
            </div>
            <div style="padding-top:1%;padding-left:2%;display: inline-block">
                <label><strong>Postal Code</strong></label><br>
                <input placeholder="Postal Code" [(ngModel)]="model.postal_code" name='Postal Code'>
            </div>
            <br><br>
            <div *ngIf="consent_status&&show_submit">
                <button (click)="onSubmit()" class="buttonSubmit" style="display:inline-block;">Submit</button>
                <div style="display:inline-block">
                    <div style="padding-left:5px">
                        <div *ngIf="consent_status">
                            <h3 style="color:green;font-size:20px">Consent Completed</h3>
                        </div>
                        <div *ngIf="!consent_status">
                            <h3 style="color:red;font-size:20px">Consent Incomplete</h3>
                        </div>
                    </div>
                </div>
                <br>
                <button class="buttonReset" (click)="resetForm()">Reset</button>
            </div>
            <div *ngIf="!consent_status">
                <button *ngIf="show_send_consent_button" (click)="sendConsentFormEmailClient()" class="buttonSubmit"
                    style="display:inline-block;">Send
                    Consent</button>
                <button *ngIf="show_consent_status_button" (click)="checkConsentStatus()" class="buttonSubmit"
                    style="display:inline-block;">Consent
                    Status</button>
                <div style="display:inline-block">
                    <div style="padding-left:5px">
                        <div *ngIf="consent_status">
                            <h3 style="color:green;font-size:20px">Consent Completed</h3>
                        </div>
                        <div *ngIf="!consent_status">
                            <h3 style="color:red;font-size:20px">Consent Incomplete</h3>
                        </div>
                    </div>
                </div>
                <br>
                <button class="buttonReset" (click)="resetForm()">Reset</button>
            </div>
        </div>
        <br>

        <!-- Approved View -->
    </div>
    <div *ngIf="approvedPageFlag" style="text-align: center;">
        <h2 style="color:green">This Client has been Pre-Approved</h2>
        <p>Congratulations you have been pre-approved for your loan application. We are ready to process the rest of
            your application and collect the documents needed to verify the information submitted here. Please
            contact
            an LM Credit Inc. representative right now AT 1-833-301-1058 and select extension 1 to complete this
            referral.</p><br><br>
        <button (click)="resetForm()" class="buttonSubmit">Start New Application</button>
    </div>
    <!-- Declined View -->
    <div *ngIf="declinedPageFlag" style="text-align: center;">
        <h2 style="color:red">This Client has not been Pre-Approved</h2>
        <p>Thank you for your RAPID application. While we cannot extend an instant pre-approval right now, we have a
            representative available to take a closer look at this application. Please contact a
            representative at 1-833-301-1058 and select extension 1 to make a referral.</p><br><br>
        <button (click)="resetForm()" class="buttonSubmit">Start New Application</button>
    </div>