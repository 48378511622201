<ng-template #modalContent let-modal>
	<div class="modal-header">
		<h4>{{ modalOptions?.headerTitle }}</h4>
		<!-- <h4 [style]="modalOptions?.options">{{ modalOptions?.headerTitle }}</h4> -->
		<button type="button" class="btn-close" aria-label="Close" (click)="dismissModal('cross')"></button>
	</div>
	<div class="modal-body">
		<p>{{ modalOptions?.bodyText }}</p>
	</div>
	<div class="modal-footer">
		<div *ngFor="let button of modalOptions?.buttons">
			<button type="button" class="btn btn-light" (click)="closeModal(button, modal)">
				{{ button }}
			</button>
		</div>
	</div>
</ng-template>