<app-top-nav></app-top-nav>
<app-custom-modal [modalOptions]="modalOptions"></app-custom-modal>
<router-outlet></router-outlet>
<!-- <html>

<head>
  <script type="text/javascript" src="https://code.jquery.com/jquery-2.1.3.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.2/jspdf.debug.js"></script>
</head>

<body>
  <div *ngIf="loanInput.Application_Name">
    <span style="font-size: 25px;padding-left: 3%;">{{loanInput.Application_Name}} ({{loanInput.Province_Name}})</span>
    <mat-card>
      <div class="float-child-left" style="font-size:20px;font-weight: bold;">
        <br>
        <div style="padding-left: 1%;">
          <div class="float-child-left-input">
            <mat-form-field appearance="fill" style="font-weight: bold;width:90%">
              <mat-label>Interest Type</mat-label>
              <mat-select [disabled]="read_only_flag" (selectionChange)="LoanTypeChange($event.value)"
                [(ngModel)]="loanInput.Loan_Type">
                <mat-option *ngFor="let lt of loan_type" [value]="lt.value">
                  {{lt.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="loanInput.Loan_Type==='Custom'">
              <mat-form-field style="font-weight: bold;width:90%">
                <mat-label>Interest Rate</mat-label>
                <input [readonly]="read_only_flag" matInput type="number" [(ngModel)]="loanInput.Interest_Rate">
                <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <mat-form-field appearance="fill" style="font-weight: bold;width:90%">
              <mat-label>Loan Protection (LPP)?</mat-label>
              <mat-select [disabled]="read_only_flag" [(ngModel)]="loanInput.Insurance">
                <mat-option *ngFor="let lt of insurance_protection" [value]="lt.value">
                  {{lt.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field style="width:90%;">
              <mat-label>Loan Amount</mat-label>
              <input [readonly]="read_only_flag" matInput type="number" [(ngModel)]="loanInput.Loan_Amount">
              <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill" style="font-weight: bold;width:90%">
              <mat-label>Term</mat-label>
              <mat-select [disabled]="read_only_flag" [(ngModel)]="loanInput.Term_Type">
                <mat-option *ngFor="let lt of term_type" [value]="lt.value">
                  {{lt.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field appearance="fill" style="width:90%;">
              <mat-label>Funded Date</mat-label>
              <input [disabled]="read_only_flag" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="datepicker1"
                placeholder="Funded Date" [(ngModel)]="loanInput.Funded_Date" required>
              <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
              <mat-datepicker #datepicker1></mat-datepicker>
            </mat-form-field>
            <br>
            <button class='buttonSubmit' (click)="calculate(false)">Submit</button>

          </div>
          <div class="float-child-right-input">
            <mat-form-field appearance="fill" style="font-weight: bold;width:90%">
              <mat-label>Manager Overwrite</mat-label>
              <mat-select [disabled]="non_admin_user" (selectionChange)="updateException($event)"
                [(ngModel)]="loanInput.exception">
                <mat-option *ngFor="let lt of manager_overwrite" [value]="lt.value">
                  {{lt.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width:90%;">
              <mat-label>Loan Protection (LPP) Type</mat-label>
              <input [readonly]=true matInput type="string" [(ngModel)]="display_data.isEnhanced">
              <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <br>
            <mat-form-field style="width:90%;">
              <mat-label>Period (Years)</mat-label>
              <input [readonly]="read_only_flag" matInput type="number" [(ngModel)]="loanInput.Duration">
              <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill" style="font-weight: bold;width:90%">
              <mat-label>Credit Upgrader?</mat-label>
              <mat-select [disabled]="read_only_flag" [(ngModel)]="loanInput.CU">
                <mat-option *ngFor="let lt of credit_upgrader" [value]="lt.value">
                  {{lt.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>

            <mat-form-field appearance="fill" style="width:90%;">
              <mat-label>Start Date</mat-label>
              <input [disabled]="read_only_flag" matInput [matDatepickerFilter]="" [matDatepicker]="datepicker2"
                placeholder="Start Date" [(ngModel)]="loanInput.Start_Date" required>
              <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
              <mat-datepicker #datepicker2></mat-datepicker>
            </mat-form-field>
            <br>
            <div *ngIf="Payments">
              <button (click)="resetForm()" class="buttonReset">Reset</button>
            </div>
          </div>
        </div>
        <br>
      </div>
      <br>
      <div class="float-child-right">
        <div *ngIf="Payments">
          <div class="float-child-left-main">
            <mat-card>
              <h5 style="text-align: center;">{{Payments.Term_Type}} Scheduled Payments</h5>
              <h2 style="font-weight: bold;text-align:center;">${{display_data.Payment}}</h2>
              <br>
              <p style="text-align: left; font-size: 20px;">Principal and Interest(P+I)
                <span style="float:right;font-size: 20px;">
                  ${{display_data.Scheduled}}
                </span>
              </p>
              <hr>
              <p style="text-align: left;font-size: 20px;">LPP
                <span style="font-size: 20px;padding-left: 60%;">
                  ${{display_data.LPP}}<span class='supsub'><sup
                      class='superscript'>${{display_data.Insurance}}</sup><sub
                      class='subscript'>${{display_data.Tax}}</sub></span>
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Last Payment Amount
                <span style="float:right;font-size: 20px;">
                  ${{display_data.Last_payment_amount}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">CU Fee
                <span style="float:right;font-size: 20px;">
                  ${{display_data.CU_fee}}
                </span>
              </p>
              <hr>
            </mat-card>
            <mat-card style="padding-top: 5%;">
              <table style="width: 100%;">
                <tr>
                  <th style="font-size: 20px;text-align: left;">Frequency</th>
                  <th style="font-size: 20px;">P+I</th>
                  <th style="font-size: 20px;">LPP</th>
                  <th style="font-size: 20px;">Total</th>
                </tr>
                <tr>
                  <td height="50" style="text-align: left;font-size: 20px;width:30%;padding-left: 1%;">Monthly
                  </td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.Amortization_Interest_Monthly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.LPP_Monthly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.Payment_Amount_Insurance_Monthly}}</td>
                </tr>
                <tr>
                  <td height="50" style="text-align: left;font-size: 20px;padding-left: 1%;">Bi-weekly</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="bi-weekly-bwp">
                    ${{Payments.Amortization_Interest_Biweekly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.LPP_Biweekly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.Payment_Amount_Insurance_Biweekly}}</td>
                </tr>
                <tr>
                  <td height="50" style="text-align: left;font-size: 20px;padding-left: 1%;">Weekly</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="weekly-wp">
                    ${{Payments.Amortization_Interest_Weekly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.LPP_Weekly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.Payment_Amount_Insurance_Weekly}}</td>
                </tr>
                <tr>
                  <td height="50" style="text-align: left;font-size: 20px;padding-left: 1%;">Semi-monthly</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="weekly-wp">
                    ${{Payments.Amortization_Interest_SemiMonthly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.LPP_SemiMonthly}}</td>
                  <td height="50" style="text-align: right;font-size: 20px;padding-right: 1%;" id="monthly-mp">
                    ${{Payments.Payment_Amount_Insurance_SemiMonthly}}</td>
                </tr>
              </table>
            </mat-card>
          </div>
          <div class="float-child-right-main">
            <mat-card>
              <h5 style="text-align: center;">Total Obligation Entire Term</h5>
              <h2 style="font-weight: bold;text-align:center;">${{display_data.Total_obligation_entire_term}}</h2>
              <br>
              <p style="text-align: left; font-size: 20px;">Total Cost of Borrowing
                <span style="float:right;font-size: 20px;">
                  ${{display_data.Total_cost_of_borrowing}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Funded Date
                <span style="float:right;font-size: 20px;">
                  {{display_data.Funded_date}}
                </span>
              </p>
              <hr>

              <p style="text-align: left; font-size: 20px;">Start Date
                <span style="float:right;font-size: 20px;">
                  {{display_data.Start_date}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Maturity Date
                <span style="float:right;font-size: 20px;">
                  {{display_data.Maturity_date}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Setup Fee
                <span style="float:right;font-size: 20px;">
                  ${{display_data.Setup_fee}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Accumulated Interest
                <span style="float:right;font-size: 20px;">
                  ${{display_data.Accumulative_interest}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">LPP total Interest
                <span style="float:right;font-size: 20px;">
                  ${{display_data.LPP_total_interest}}
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Interest Rate
                <span style="float:right;font-size: 20px;">
                  {{Payments.Interest_Rate}}%
                </span>
              </p>
              <hr>
              <p style="text-align: left; font-size: 20px;">Term(months)
                <span style="float:right;font-size: 20px;">
                  {{display_data.Term}}
                </span>
              </p>
              <hr><br>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div *ngIf="Payments">
    <div id="content" #content>
      <div class="mat-box-shadow margin-333" *ngIf="Payments">
        <table id="customers" #content>
          <tr>
            <th>Payment No.</th>
            <th>Payment Date</th>
            <th>Beginning Balance</th>
            <th>Scheduled Payment</th>
            <th>Interest</th>
            <th>Principal</th>
            <th>Ending Balance</th>
            <th>Cumulative Interest</th>
            <th>LPP</th>
            <th>Ending Balance with LPP</th>
          </tr>

          <tr *ngFor="let payment of ProperSchedule">
            <td>{{payment.set_Payment_Number}}</td>
            <td>{{payment.set_Payment_Date}}</td>
            <td>{{payment.set_Beg_Balance}}</td>
            <td>{{payment.set_Amortization}}</td>
            <td>{{payment.set_Interest}}</td>
            <td>{{payment.set_Principal}}</td>
            <td>{{payment.set_End_Balance}}</td>
            <td>{{payment.set_Cumulative_Interest}}</td>
            <td>{{display_data.LPP}}</td>
            <td>{{payment.set_LPP_Ending_Balance}}</td>

          </tr>
        </table>
      </div>
    </div>
  </div>
</body>

</html> -->
