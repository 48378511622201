<div class="container-fluid">
	<div style="
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;
		">
		<div style="display: flex">
			<div id="message" *ngIf="errorMessage.length !== 0">
				<div style="padding: 5px">
					<div id="inner-message">
						<p>
							<ngb-alert type="danger" (closed)="clearErrors()">
								{{ errorMessage }}
							</ngb-alert>
						</p>
					</div>
				</div>
			</div>
			<div *ngIf="tu_stats">
				<div *ngIf="counter === 0 && !saveInProgress" style="display: inline-block">
					<img id="playButton" src="/assets/images/pauseplay.jpg" title="Start" width="40" height="40"
						style="cursor: pointer" (click)="editToStartTimer()" />
					00:00:00
				</div>
				<div *ngIf="counter !== 0" style="display: inline-block">
					<img id="playButton" src="/assets/images/pauseplay.jpg" title="End" width="40" height="40"
						style="cursor: pointer; margin-top: 8px" (click)="clearTimer()" />
					{{ display_time }}
				</div>
			</div>
			<div *ngIf="counter === 0" style="display: flex; margin-left: 5px">
				<div *ngIf="readOnly" style="display: inline-block">
					<button mat-raised-button color="primary"
						style="width: 100px; text-align: center; margin-right: 5px" (click)="editMode()"
						[disabled]="saveInProgress">
						Edit
					</button>
				</div>
				<div *ngIf="!readOnly" style="display: inline-block; padding-right: 5px">
					<button mat-raised-button color="primary"
						style="width: 100px; text-align: center; margin-right: 5px" (click)="userSubmit()"
						[disabled]="saveInProgress">
						Save
					</button>
				</div>
				<span id="messageDisplay" style="font-size: small; margin-top: 5px; display: inline-block"></span>
				<!--                <div style="display: inline-block">-->
				<!--                  <button mat-raised-button color="primary" style="width: 100px; text-align: center"-->
				<!--                          (click)="deleteUnderwritingEntry()"-->
				<!--                          [disabled]="saveInProgress">-->
				<!--                    Reset-->
				<!--                  </button>-->
				<!--                </div>-->
				<br />
			</div>
		</div>
		<select class="form-select" (change)="updateApplicationType($event)" *ngIf="co_app_required"
			style="width: 250px; margin: 0; cursor: pointer">
			<option value="Primary">Primary</option>
			<option value="Co-applicant">Co-applicant</option>
		</select>
	</div>
	<mat-card appearance="outlined" style="
			margin-top: 20px;
			padding: 8px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		" *ngIf="tu_stats">
		<div>
			<table>
				<tr>
					<td colspan="2">
						<strong>Interviewer:</strong> {{ interViewerInfo.interviewer }}
						<small style="font-size: 15px">({{ interViewerInfo.duration }})</small>
					</td>
					<td>
						<strong>Credit Utilization:</strong> {{ tu_stats.credit_util }}%
					</td>
					<td>
						<strong>Collections:</strong> ${{ tu_stats.collection_balance }}({{
						tu_stats.no_collections
						}})
					</td>
					<td>
						<strong>Trades(2-4):</strong> ${{ tu_stats.trades_balance_2_4 }}({{
						tu_stats.no_trades_2_4
						}})
					</td>
					<td><strong>TDSR:</strong> {{ TDSR }}%</td>
					<td><strong>Credit Score:</strong> {{ credit_score }}</td>
				</tr>
				<tr>
					<td colspan="2">
						<strong>Modified By:</strong> {{ interViewerInfo.modified_by }}
						<small style="font-size: 15px">({{ interViewerInfo.modified_at }})</small>
					</td>
					<td><strong>LTV:</strong> {{ underwritingEntry.LTV }}%</td>
					<td>
						<strong>Judgments:</strong> ${{ tu_stats.legal_balance }}({{
						tu_stats.no_legals
						}})
					</td>
					<td>
						<strong>Trades(5-9):</strong> ${{ tu_stats.trades_balance_5_9 }}({{
						tu_stats.no_trades_5_9
						}})
					</td>
					<td><strong>UW Tier:</strong> {{ uw_tier }}</td>
				</tr>
			</table>
			<!--<div class="col-4">
                <div class="row" style="display: flex;">
                    <div class="col-1">
                        <label class="form-label"><strong>Stage</strong></label>
                    </div>
                    <div class="col-8">
                        &lt;!&ndash; <strong>STAGE</strong>                &ndash;&gt;
                        <select class="form-select" [(ngModel)]="underwritingEntry.Stage">
                            <option *ngFor="let item of stageName" [value]="item.display_value">
                                {{item.display_value}}
                            </option>
                        </select>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-primary" id="saveStageButton" (click)="saveStage()">Save</button>
                        <small id="saveStageMessage"></small>
                    </div>

                </div>
            </div>-->
		</div>
	</mat-card>
	<h5>EMPLOYMENT INFORMATION</h5>
	<div class="container-md">
		<div *ngIf="applicantType === 'Primary'">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Employer </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm"
						[(ngModel)]="this.underwritingEntry.Employer" name="data" [readonly]="readOnly" />
				</div>
				<div class="col-sm-2">
					<label>Industry </label>
				</div>
				<div class="col-md-4" style="z-index: 1">
					<ng-select [disabled]="readOnly" [(ngModel)]="underwritingEntry.Industry">
						<ng-option *ngFor="let item of industry_look_up" [value]="item.display_value">
							{{ item.display_value }}</ng-option>
					</ng-select>
				</div>
				<div class="col-sm-2">
					<label>Employment Status </label>
				</div>
				<div class="col-md-4">
					<select [disabled]="readOnly" class="form-select" aria-label="Default select example"
						[(ngModel)]="this.underwritingEntry.Employment_Status1" name="CB">
						<option *ngFor="let i of emp_type_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Occupation </label>
				</div>
				<div class="col-md-4">
					<ng-select [disabled]="readOnly" [(ngModel)]="underwritingEntry.Occupation">
						<ng-option *ngFor="let item of occupation_look_up" [value]="item.display_value">
							{{ item.display_value }}</ng-option>
					</ng-select>
				</div>
				<div class="col-sm-2">
					<label>Work Phone </label>
				</div>
				<div class="col-md-4">
					<input class="form-control form-control-sm" type="text"
						[(ngModel)]="this.underwritingEntry.Work_Phone" [readonly]="readOnly" />
				</div>

				<div class="col-sm-2">
					<label>Payment Frequency </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" name="CB" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Pay_Frequency2">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option *ngFor="let i of salary_frequency_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
				<!-- (change)="calculateNextPayDate(this.underwritingEntry.Last_Pay_Date, this.underwritingEntry.Pay_Frequency2)" -->
				<div class="col-sm-2">
					<label>Work Extension </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Work_Extension" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Last Pay Date </label>
				</div>
				<div class="col-md-4">
					<input type="date" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Last_Pay_Date" name="data" />
					<!-- (change)="calculateNextPayDate(this.underwritingEntry.Last_Pay_Date, this.underwritingEntry.Pay_Frequency2)" -->
				</div>

				<div class="col-sm-2">
					<label>Length of Employment </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Length_of_Employment" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Next Pay Date </label>
				</div>
				<div class="col-md-4">
					<input type="date" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Next_Pay_Date" name="data" />
				</div>

				<div class="col-sm-2">
					<label>Position </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Position" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Income (net paycheque income) </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Income_net_paycheque_income" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Supervisor Name </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Supervisor_Name" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Supervisor Phone Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Supervisor_Phone_Number" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Income Amount (Household Income) </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Income_Amount_Household_Income" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Employer Name (Household Income) </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Employer_Name_Household_Income" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Pay Frequency (Household Income) </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" name="CB" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Pay_Frequency_Household_Income">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option *ngFor="let i of salary_frequency_household_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Additional Employment Details </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Additional_Employment" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Payment Method </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" name="CB" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Payment_Method">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option *ngFor="let i of pay_method_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Previous Employment Section? </label>
				</div>
				<div class="col-sm-2">
					<label *ngIf="this.underwritingEntry.Previous_Employment === true">Yes</label>
					<label *ngIf="this.underwritingEntry.Previous_Employment === false">No</label>
				</div>

				<!-- <div class="col-md-4">
                    <select class="form-select" aria-label="Default select example"
                        [(ngModel)]="this.underwritingEntry.Previous_Employment" name="CB">
                        <option value="none" selected [disabled] hidden>-None-</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div> -->
			</div>
			<!-- </div> -->
			<!-- </div> -->
			<!-- <div class="container-md"> -->
			<!-- <div> -->
			<div class="row row-cols-12" *ngIf="underwritingEntry.Previous_Employment === true">
				<h5 class="pre">PREVIOUS EMPLOYMENT INFORMATION</h5>
				<div class="col-sm-2">
					<label>Previous Empolyment Type </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Employment_Type }}
				</div>
				<div class="col-sm-2">
					<label>Previous Position </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Employer }}
				</div>
				<div class="col-sm-2">
					<label>Previous Employer </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Supervisor_Name }}
				</div>
				<div class="col-sm-2">
					<label>Previous Length of Employment </label>
				</div>
				<div class="col-md-4">
					{{ this.underwritingEntry.Previous_Income_net_paycheque_income }}
				</div>
				<div class="col-sm-2">
					<label>Previous Supervisor Name </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Payment_Method }}
				</div>
				<div class="col-sm-2">
					<label>Previous Supervisor Phone Number </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Position }}
				</div>
				<div class="col-sm-2">
					<label>Previous Income (net paycheque income) </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Length_of_Employment }}
				</div>
				<div class="col-sm-2">
					<label>Previous Pay Frequency </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Supervisor_Phone_number }}
				</div>
				<div class="col-sm-2">
					<label>Previous Payment Method </label>
				</div>
				<div class="col-md-4">
					{{ underwritingEntry.Previous_Pay_Frequency }}
				</div>
			</div>
		</div>
		<div *ngIf="applicantType === 'Co-applicant'">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Employment Type</label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Status" name="CB">
						<option *ngFor="let i of co_app_emp_type_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Employer </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Employer" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Supervisor Name </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Supervisor_Name" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Annual Income </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Sec_Annual_Income" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Payment Method </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" name="CB" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Payment_Method">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option *ngFor="let i of pay_method_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Industry </label>
				</div>
				<div class="col-md-4" style="z-index: 1">
					<ng-select [disabled]="readOnly" [(ngModel)]="this.underwritingEntry.Co_Applicant_Industry">
						<ng-option *ngFor="let item of industry_coapp_look_up" [value]="item.display_value">
							{{ item.display_value }}</ng-option>
					</ng-select>
				</div>
				<div class="col-sm-2">
					<label>Occupation </label>
				</div>
				<div class="col-md-4" style="z-index: 1">
					<ng-select [disabled]="readOnly" [(ngModel)]="underwritingEntry.Co_Applicant_Occupation">
						<ng-option *ngFor="let item of occupation_coapp_look_up" [value]="item.display_value">
							{{ item.display_value }}</ng-option>
					</ng-select>
				</div>
				<div class="col-sm-2">
					<label>Position</label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Secondary_Position" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Years Worked </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Secondary_Years_Worked" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Supervisor Phone Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly" [(ngModel)]="
							this.underwritingEntry.Co_Applicant_Supervisor_Phone_Number
						" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Work Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Secondary_Work_Number" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Pay Frequency (Household Income) </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" name="CB" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Pay_Frequency">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option *ngFor="let i of salary_frequency_look_up" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<h5 class="pre">STATEMENTS/BUDGET</h5>
	<div class="container-md">
		<div>
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Review Payday usage </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Review_any_payday_loan_usage" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Payday Comments </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Payday_Loans_Comments" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Overdraft Limit? </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Overdraft_Limit_Question" name="CB"
						(change)="clearOverdraftLimit()">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2" *ngIf="this.underwritingEntry.Overdraft_Limit_Question === 'Yes'">
					<label>Overdraft Limit </label>
				</div>
				<div class="col-md-4" *ngIf="this.underwritingEntry.Overdraft_Limit_Question === 'Yes'">
					<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Overdraft_Limit" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Is this a join Account? </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Is_this_a_joint_Account" name="CB"
						(change)="clearJointAccount()">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Reason for falling behind </label>
				</div>
				<div class="col-md-4">
					<textarea rows="4" cols="50" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Reason_for_falling_behind" name="data"></textarea>
				</div>
				<div class="col-sm-2" *ngIf="this.underwritingEntry.Is_this_a_joint_Account === 'Yes'">
					<label>Explanation </label>
				</div>
				<div class="col-md-4" *ngIf="this.underwritingEntry.Is_this_a_joint_Account === 'Yes'">
					<textarea rows="4" cols="50" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Is_this_a_joint_Account_Note" name="data"></textarea>
				</div>
			</div>
		</div>
	</div>
	<h5 class="pre">TAXES/DEPENDENTS</h5>
	<div class="container-md">
		<div>
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>When was the last time you filed taxes? </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly" [(ngModel)]="
							this.underwritingEntry.When_was_the_last_time_you_filed_taxes
						" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Up to Date">Up to Date</option>
						<option value="Behind">Behind</option>
						<option value="Never">Never</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>How much do owe to Revenue Canada? </label>
				</div>
				<div class="col-md-4">
					<input type="number" class="form-control form-control-sm" [readonly]="readOnly" [(ngModel)]="
							this.underwritingEntry.How_much_do_you_owe_Revenue_Canada
						" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Have you setup an arrangement with the CRA? </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly" [(ngModel)]="
							this.underwritingEntry.Have_you_setup_an_arrangement_with_the_CRA
						" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Do you live with anyone else? </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Do_you_live_with_anyone_else" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Number of Dependents </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Dependents" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="0">0</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						<option value="8">8</option>
						<option value="9">9</option>
						<option value="10">10</option>
						<option value="10+">10+</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<!-- ADDITIONAL QUESTIONS IF HOMEOWNER IS YES -->
	<h5 class="pre">HOMEOWNER QUESTIONS</h5>
	<div class="container-md">
		<div class="row row-cols-4">
			<div class="col-sm-2">
				<label>Homeowner? </label>
			</div>
			<div class="col-md-4">
				<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
					[(ngModel)]="this.underwritingEntry.Are_you_a_homeowner" name="CB">
					<option value="none" selected [disabled] hidden>-None-</option>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Property Tax Owing? </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<input type="text" class="form-control form-control-sm" [readonly]="readOnly" [(ngModel)]="
						underwritingEntry.Do_you_owe_any_property_tax_Howe_owners_only
					" name="data" />
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Mortgage on property? </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
					[(ngModel)]="underwritingEntry.Is_there_a_mortgage_on_the_property" name="CB">
					<option value="none" selected [disabled] hidden>-None-</option>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Mortage Holder(s)? </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
					[(ngModel)]="underwritingEntry.Who_is_your_mortgage_with" name="data" />
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Purview Amount </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<input type="number" class="form-control form-control-sm" [(ngModel)]="underwritingEntry.Purview_Value"
					name="data" (click)="calculateLTV()" [readonly]="readOnly" />
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Property Amount? </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
					[(ngModel)]="underwritingEntry.What_is_current_value_of_the_property" name="data"
					(click)="calculateLTV()" />
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Mortgage Balance? </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
					[(ngModel)]="underwritingEntry.What_is_the_balance_of_your_mortgage" name="data"
					(click)="calculateLTV()" />
			</div>
			<div class="col-sm-2" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<label>Mortgage Interest Rate? </label>
			</div>
			<div class="col-md-4" *ngIf="underwritingEntry.Are_you_a_homeowner === 'Yes'">
				<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
					[(ngModel)]="underwritingEntry.What_interest_rate_on_your_mortgage" name="data" />
			</div>
		</div>
	</div>
	<!-- Expenses Table -->
	<h5 class="pre">EXPENSES</h5>
	<div class="container-md" id="myExpenses">
		<div class="table-responsive">
			<div [formGroup]="userForm">
				<div class="form-group" formArrayName="ADDITIONAL_EXPENSES">
					<table class="table table-bordered table-hover">
						<thead class="table-light">
							<tr>
								<th class="expenseCloseWidth"></th>
								<th scope="col">Type</th>
								<th scope="col">Creditor Name</th>
								<th scope="col">Amount (CA$)</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="
									let control of userForm.controls.ADDITIONAL_EXPENSES[
										'controls'
									];
									let i = index
								" [formGroupName]="i">
								<td class="expenseCloseWidth">
									<button [disabled]="readOnly" class="btn-close"
										(click)="removeExpenses(i)"></button>
								</td>
								<td>
									<select formControlName="Type" [disabled]="readOnly">
										<option *ngFor="let item of Expense_Types" [ngValue]="item.expenses_type">
											{{ item.expenses_type }}
										</option>
									</select>
								</td>
								<td>
									<input type="text" formControlName="Name1" placeholder="Creditor Name"
										[readonly]="readOnly" />
								</td>
								<td>
									<input type="number" formControlName="Amount" placeholder="Amount"
										[readonly]="readOnly" (click)="addExpenses()" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<button class="btn btn-outline-primary" [disabled]="readOnly" (click)="addExpense()">
					+Add Expenses
				</button>
			</div>
			<div class="col-md-auto">
				<label>Total Monthly Income (CA$)</label>
			</div>
			<div class="col col-lg-2">
				<input type="number" class="form-control" [(ngModel)]="n_total_monthly_income" [readonly]="readOnly"
					(click)="addExpenses()" />
			</div>
			<!-- <button (click)="openMonthlyIncomeCalc()">Calculate</button> -->
		</div>
		<div class="row">
			<div class="col"></div>
			<div class="col col-lg-2" style="display: flex; justify-content: right">
				<img src="/assets/images/incomecalc.png" title="Calculate Average Income" width="20" height="30"
					style="cursor: pointer" (click)="openMonthlyIncomeCalc()" />
			</div>
		</div>
		<div class="row">
			<div class="col"></div>
			<div class="col-md-auto">
				<label>Total Additional Expenses (CA$)</label>
			</div>
			<div class="col col-lg-2">
				<input type="number" readonly class="form-control" [(ngModel)]="n_total_expenses"
					[readonly]="readOnly" />
			</div>
		</div>
		<div class="row">
			<div class="col"></div>
			<div class="col-md-auto">
				<label>TDSR(%)</label>
			</div>
			<div class="col col-lg-2">
				<input type="text" readonly class="form-control" [(ngModel)]="n_tdsr" [readonly]="readOnly" />
			</div>
		</div>
		<div class="row">
			<div class="col"></div>
			<div class="col-md-auto">
				<label>Current Loan Amount Payments (Monthly)(CA$)</label>
			</div>
			<div class="col col-lg-2">
				<input type="number" class="form-control" [(ngModel)]="n_current_loan_amount_payments"
					[readonly]="readOnly" (click)="calculateExpenses()" />
			</div>
		</div>
	</div>
	<!-- Additional Debts Table -->
	<!-- <h5 class="pre">ADDITIONAL DEBTS</h5>
    <div class="container-md" id="myAddDebt">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="table-light">
                    <tr>
                        <th scope="col">Agency</th>
                        <th scope="col">Creditor</th>
                        <th scope="col">Account Balance (CA$)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of underwritingEntry.Additional_Debt">
                        <td>{{item.Agency['name']}}</td>
                        <td>{{item.misc}}</td>
                        <td id="alignAmount">{{item.Account_Balance | currency}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><strong>Total</strong></td>
                        <td><strong>{{totalDebts | currency}}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div> -->
	<h5 class="pre">BANKING</h5>
	<div class="container-md">
		<div *ngIf="applicantType === 'Primary'">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Institution Name </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Financial_Institution_Name" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Institution Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Financial_Institution_Number" name="data"
						(click)="click_bankingValidationPrimary_InsNumber()" />
				</div>
				<div class="col-sm-2">
					<label>Bank Account Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" pattern="[0-9]*" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Bank_Account_Number" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Branch Transit Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Bank_Transit_No" name="data"
						(keydown)="numberOnly($event)" />
				</div>
				<div class="col-sm-2">
					<label>Branch Address </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Branch_Address" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Other Bank Account </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Other_Bank_Account" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>
		</div>
		<div *ngIf="applicantType === 'Co-applicant'">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Institution Name </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly" [(ngModel)]="
							this.underwritingEntry.Co_Applicant_Financial_Institution_Name
						" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Institution Number </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly" [(ngModel)]="
							this.underwritingEntry.Co_ApplicantFinancial_Institution_Number1
						" name="data" (click)="click_bankingValidationCo_Applicant_InsNumber()" />
				</div>
				<div class="col-sm-2">
					<label>Bank Account Number </label>
				</div>
				<div class="col-md-4">
					<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Bank_Account" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Branch Transit Number </label>
				</div>
				<div class="col-md-4">
					<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Bank_Transit_No" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Branch Address </label>
				</div>
				<div class="col-md-4">
					<input type="text" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Co_Applicant_Branch_Address" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Other Bank Account </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.Other_Bank_Account" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<h5 class="pre">APPROVAL</h5>
	<div class="container-md">
		<div>
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Loan Amount </label>
				</div>
				<div class="col-md-4">
					<input type="number" class="form-control form-control-sm" [readonly]="readOnly"
						[(ngModel)]="this.underwritingEntry.Approved_Amount" name="data" />
				</div>
				<div class="col-sm-2">
					<label>First Payment Date </label>
				</div>
				<div class="col-md-4">
					<input type="date" class="form-control form-control-sm" [readonly]="true"
						[(ngModel)]="this.underwritingEntry.Date_Ordered" name="data" />
				</div>
				<div class="col-sm-2">
					<label>Payment Frequency </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="true"
						[(ngModel)]="this.underwritingEntry.Opportunity_Status" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="Monthly">Monthly</option>
						<option value="Semi-Monthly">Semi-Monthly</option>
						<option value="Bi-Weekly">Bi-Weekly</option>
						<option value="Weekly">Weekly</option>
						<option value="15th and EOM">15th and EOM</option>
						<option value="1st and 15th">1st and 15th</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Term in Years </label>
				</div>
				<div class="col-md-4">
					<input type="text" readonly class="form-control form-control-sm" [readonly]="true"
						[(ngModel)]="this.underwritingEntry.Term_of_Loan_Years" name="data" />
				</div>
				<div class="col-sm-2">
					<label>UW Tier </label>
				</div>
				<div class="col-md-4">
					<select class="form-select" aria-label="Default select example" [disabled]="readOnly"
						[(ngModel)]="this.underwritingEntry.UW_Tier" name="CB">
						<option value="none" selected [disabled] hidden>-None-</option>
						<option value="0">0</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<!-- <h5 class="pre">SYSTEM INFO</h5>
    <div class="container-md">
        <div>
            <div class="row row-cols-4">
                <div class="col-sm-2">
                    <label>Exchange Info </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Underwriting Owner </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Currency</label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Created By </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Underwriting Name </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Modified By </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Account Number </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Risk </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Explain NSF/Stopped payments </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Previous bankruptcy/CP/legal items (explain) </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
                <div class="col-sm-2">
                    <label>Notes </label>
                </div>
                <div class="col-md-4">
                    <input required type="text" class="form-control form-control-sm">
                    <small class="invalid-feedback">
                        Application name required
                    </small>
                </div>
            </div>
        </div>
    </div> -->

	<!-- <div id="message" *ngIf="successMessage">
        <div style="padding: 5px;">
            <div id="inner-message">
                <p>
                    <ngb-alert type='success' (closed)="clearErrors()">Application Updated</ngb-alert>
                </p>
            </div>
        </div>
    </div>
    <div id="message" *ngIf="warningMessage">
        <div style="padding: 5px;">
            <div id="inner-message">
                <p>
                    <ngb-alert type='warning' (closed)="clearErrors()">Insufficient Permissions to Edit. Contact IT!
                    </ngb-alert>
                </p>
            </div>
        </div>
    </div> -->
</div>