import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from 'app/api-service.service';
import { Router } from '@angular/router';
import { CellClickedEvent } from 'ag-grid-community';
import { ButtonCellRendererComponent } from '@lmc-app/custom-table/button-cell-renderer/button-cell-renderer.component';
import { Subscription } from 'rxjs';
import { dateFilterAgGrid } from '@lmc-app/utils/filter-functions';

@Component({
	selector: 'app-collection-list',
	templateUrl: './collection-list.component.html',
	styleUrls: ['./collection-list.component.scss'],
})
export class CollectionListComponent implements AfterViewInit, OnDestroy {
	adminDataSubscription: Subscription;
	individualDataSubscription: Subscription;

	defaultColDef_Collection: any;
	public columnDefs_Collection = [];
	public rowData_Collection;
	public gridApi_Collection;
	public gridColumnApi_Collection;
	public gridOptions_Collection;
	rowClassRules = {
		'requested-row': (params) => params?.data?.current_principal_balance < 1500
	};

	sessionInfo: any; // Session information
	adminView = false; // Component permission flag
	filtered_list: any; // The final list for display
	showLoader: boolean;
	showEmptyResults: boolean;
	past_date = new Date();

	allSelected = 'accent';
	brokenSelected = 'basic';
	spvSelected = 'basic';
	mftSelected = 'basic';
	debentureSelected = 'basic';
	qvestSelected = 'basic';
	maturedSelected = 'basic';

	hideNSFDate = false;

	constructor(
		private apiService: ApiServiceService,
		private router: Router
	) {
		this.columnDefs_Collection = [
			{
				headerName: 'Loan Number', field: 'Loan Number', filter: "agTextColumnFilter", pinned: 'left',
				onCellClicked: (event: CellClickedEvent) => this.openLoanMeta(event?.data?.['Loan Number']),
				cellStyle: { cursor: 'pointer', color: '#116abd' },
			},
			{ headerName: 'Name', field: 'Name', filter: "agTextColumnFilter", },
			{ headerName: 'Province', field: 'Province', filter: "agTextColumnFilter", },
			{ headerName: 'Homeowner', field: 'Homeowner', filter: "agTextColumnFilter", },
			{ headerName: 'Past Due Balance', field: 'Past Due Balance', filter: "agNumberColumnFilter", },
			{ headerName: 'Days past due', field: 'Days past due', filter: 'agNumberColumnFilter', },
			{ headerName: 'Current Principal Balance', field: 'Current Principal Balance', filter: "agNumberColumnFilter", },
			{ headerName: 'Collector', field: 'Collector', filter: "agTextColumnFilter", },
			{
				headerName: 'Last PTP Date', field: 'Last PTP Date', filter: 'agDateColumnFilter',
				filterParams: {
					comparator: (filterDate, cellValue) => dateFilterAgGrid(filterDate, cellValue),
					browserDatePickerDateFormat: 'yyyy-mm-dd',
				},
				// valueGetter: (params) =>
				// 	params?.data?.Last_PTP_Date !== null ? params?.data?.Last_PTP_Date : 'Past',
			},
			{
				headerName: 'Last NSF Date', field: 'Last NSF Date', filter: 'agDateColumnFilter',
				filterParams: {
					comparator: (filterDate, cellValue) => dateFilterAgGrid(filterDate, cellValue),
					browserDatePickerDateFormat: 'yyyy-mm-dd',
				},
				// valueGetter: (params) =>
				// 	params?.data?.Last_PTP_Date !== null ? params?.data?.Last_PTP_Date : 'Past',
			},
			{
				headerName: 'Note created', field: 'Note created', filter: 'agDateColumnFilter',
				filterParams: {
					comparator: (filterDate, cellValue) => dateFilterAgGrid(filterDate, cellValue),
					browserDatePickerDateFormat: 'yyyy-mm-dd',
				}
			},
			{ headerName: 'Loan Group', field: 'Loan Group', filter: "agTextColumnFilter", },
			{ headerName: 'loan_group_no', field: 'loan_group_no', hide: true },
			{ headerName: 'Total PTP Amount', field: 'Total PTP Amount' },
			{
				headerName: 'Documents', field: 'Loan Number',
				cellRenderer: ButtonCellRendererComponent,
				cellRendererParams: {
					label: 'Folder Open',
					matIconLabel: 'folder_open',
					clicked: (data: any) => {
						this.openDrive(data?.['Loan Number']);
					},
				}
			},
		];
		this.defaultColDef_Collection = {
			resizable: true
		};
	}

	ngAfterViewInit(): void {
		if (localStorage.getItem('SESSIONID')) {
			this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe(async (res: any) => {
				this.showLoader = true;
				if (res.message === 'Success') {
					this.sessionInfo = res?.['data']?.[0];
					this.adminView = this.sessionInfo?.['collection_list'] === null ? false : this.sessionInfo?.['collection_list'];  // Defining Admin from the permission_component table
					if (this.adminView) { // If the component is set to try on permission_component table
						this.filtered_list = '';
						this.adminDataSubscription = this.apiService.getAdminCollectionList(this.sessionInfo['employeeID']).subscribe((res) => {
							this.filtered_list = res;
							this.gridApi_Collection?.setRowData(this.filtered_list);
							this.hideCollectorName();
							this.hideNSFDateCol();
							// console.log(this.filtered_list)
							// console.log(this.gridApi_Collection, "&&&&&&&&&&&&")
							this.rowData_Collection = this.filtered_list;
							this.showLoader = false;
							this.showEmptyResults = false;
							// }
						});
					} else {  // If false then its collector based list
						this.filtered_list = '';
						this.individualDataSubscription = this.apiService.getCollectionList(this.sessionInfo['employeeID']).subscribe((res) => {
							this.filtered_list = res;
							this.gridApi_Collection?.setRowData(this.filtered_list);
							this.hideCollectorName();
							this.hideNSFDateCol();
							// console.log(this.filtered_list)
							this.rowData_Collection = this.filtered_list;
							this.showLoader = false;
							this.showEmptyResults = false;
						});
					}
				}
			})
		}
	}

	brokenPTPList() {
		this.hideNSFDate = true;
		// console.log(this.filtered_list)
		const nsfList = this.filtered_list.filter((item) => item['ptp_days'] > 0 && item['ptp_days'] < 14);
		this.gridApi_Collection?.setRowData(nsfList);
		this.hideCollectorName();
		this.hideNSFDateCol();
		this.rowData_Collection = nsfList;
		this.brokenSelected = 'accent';
		this.allSelected = 'basic';
		this.spvSelected = 'basic';
		this.mftSelected = 'basic';
		this.debentureSelected = 'basic';
		this.qvestSelected = 'basic';
		this.maturedSelected = 'basic';
	}

	spvList() {
		this.hideNSFDate = false;
		const spvList = this.filtered_list.filter((item) => item['Loan Group'].includes('SPV'));
		this.gridApi_Collection?.setRowData(spvList);
		this.hideCollectorName();
		this.hideNSFDateCol();
		this.rowData_Collection = spvList;
		this.spvSelected = 'accent';
		this.allSelected = 'basic';
		this.brokenSelected = 'basic';
		this.mftSelected = 'basic';
		this.debentureSelected = 'basic';
		this.qvestSelected = 'basic';
		this.maturedSelected = 'basic';
	}

	mftList() {
		this.hideNSFDate = false;
		const mftList = this.filtered_list.filter((item) => item['loan_group_no'] === 3 || item['loan_group_no'] === 16);
		this.gridApi_Collection?.setRowData(mftList);
		this.hideCollectorName();
		this.hideNSFDateCol();
		this.rowData_Collection = mftList;
		this.mftSelected = 'accent';
		this.spvSelected = 'basic';
		this.debentureSelected = 'basic';
		this.qvestSelected = 'basic';
		this.allSelected = 'basic';
		this.brokenSelected = 'basic';
		this.maturedSelected = 'basic';
	}

	debentureList() {
		this.hideNSFDate = false;
		const debentureList = this.filtered_list.filter((item) => item['loan_group_no'] === 1 || item['loan_group_no'] === 27);
		this.gridApi_Collection?.setRowData(debentureList);
		this.hideCollectorName();
		this.hideNSFDateCol();
		this.rowData_Collection = debentureList;
		this.debentureSelected = 'accent';
		this.mftSelected = 'basic';
		this.spvSelected = 'basic';
		this.qvestSelected = 'basic';
		this.allSelected = 'basic';
		this.brokenSelected = 'basic';
		this.maturedSelected = 'basic';
	}

	qvestList() {
		this.hideNSFDate = false;
		const debentureList = this.filtered_list.filter((item) => item['loan_group_no'] === 50 || item['loan_group_no'] === 51);
		this.gridApi_Collection?.setRowData(debentureList);
		this.hideCollectorName();
		this.hideNSFDateCol();
		// console.log(nsfList)
		this.rowData_Collection = debentureList;
		this.qvestSelected = 'accent';
		this.debentureSelected = 'basic';
		this.mftSelected = 'basic';
		this.spvSelected = 'basic';
		this.allSelected = 'basic';
		this.brokenSelected = 'basic';
		this.maturedSelected = 'basic';
	}

	maturedList() {
		this.hideNSFDate = false;
		// console.log(this.filtered_list)
		const maturedList = this.filtered_list.filter((item) => item['Matured'] !== null);
		this.gridApi_Collection?.setRowData(maturedList);
		this.hideCollectorName();
		this.hideNSFDateCol();
		this.rowData_Collection = maturedList;
		this.maturedSelected = 'accent';
		this.brokenSelected = 'basic';
		this.allSelected = 'basic';
		this.spvSelected = 'basic';
		this.mftSelected = 'basic';
		this.debentureSelected = 'basic';
		this.qvestSelected = 'basic';
	}

	resetFilters() {
		this.hideNSFDate = false;
		this.gridApi_Collection!.setFilterModel(null);
		this.gridApi_Collection?.setRowData(this.filtered_list);
		this.hideCollectorName();
		this.hideNSFDateCol();
		// console.log(this.filtered_list)
		this.rowData_Collection = this.filtered_list;
		this.brokenSelected = 'basic';
		this.allSelected = 'accent';
		this.qvestSelected = 'basic';
		this.debentureSelected = 'basic';
		this.mftSelected = 'basic';
		this.spvSelected = 'basic';
		this.maturedSelected = 'basic';
	}

	hideCollectorName() {
		return this.adminView ? this.gridColumnApi_Collection?.setColumnsVisible(['Collector'], true) : this.gridColumnApi_Collection?.setColumnsVisible(['Collector'], false);;
	}

	hideNSFDateCol() {
		return this.hideNSFDate ? this.gridColumnApi_Collection?.setColumnsVisible(['Last NSF Date'], true) : this.gridColumnApi_Collection?.setColumnsVisible(['Last NSF Date'], false);;
	}

	//INCLUDE THIS IN THE SEARCH COMPONENT TO OPEN LOAN META FOR SPECIFIC LOAN FOR NLS (NEW COLOUMN FOR NLS OPEN)
	openLoanMeta(loan_number: number) {
		this.apiService.searchNLSLoan(loan_number).subscribe((res: any[]) => {
			let collection_queue = [];
			if (res.length == 0) {
				alert('Loan Number cannot be found on NLS');
			} else {
				this.gridApi_Collection.forEachNodeAfterFilterAndSort((rowNode, index) => {
					collection_queue.push(rowNode?.data?.['Loan Number'])
				});
				res['isQueue'] = true;
				res['queuedLoans'] = collection_queue;
				this.apiService.sendMessage(res);
				this.router.navigate(['loan-meta']);
			}
		});
	}

	// Open the Drive
	openDrive(loan_number: any) {
		this.apiService.getZOHOLoanNumberDrive(loan_number).subscribe((res) => {
			console.log(res);
			if (res['message']['statusCode'] == '200') {
				let arr = JSON.parse(res.message.body);
				window.open(arr.data[0]['gdriveextension__Drive_URL']);
			}
		});
	}

	onGridReady(params) {
		this.gridApi_Collection = params.api;
		this.gridColumnApi_Collection = params.columnApi;
		this.gridApi_Collection?.setRowData(this.filtered_list);
		this.hideCollectorName();
		this.hideNSFDateCol();
		this.rowData_Collection = this.filtered_list;
	}
	ngOnDestroy() {
		this.adminDataSubscription?.unsubscribe();
		this.individualDataSubscription?.unsubscribe();
		console.log('Collection List Data Unsubscribed!');
	}
}
