import { Component, Input } from '@angular/core';
import { ExcelService } from '@lmc-app/services/excel.service';

@Component({
  selector: 'app-custom-export-excel',
  templateUrl: './custom-export-excel.component.html',
  styleUrls: ['./custom-export-excel.component.scss']
})
export class CustomExportExcelComponent {

  @Input() sheetArray?: any;
  @Input() fileName?: any;

  constructor(private excelService: ExcelService) { }

  exportAsXLSX() {
    this.excelService?.exportToExcel(this.sheetArray, this.fileName);
  }

}
