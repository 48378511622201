<div class="container-fluid">
	<div class="row row-cols-12">
		<!-- <h5>{{timestamp | date:'medium'}}<img title="refresh" src="/assets/images/refresh.png" width="30" height="30" (click)="refresh()"></h5>  -->
	</div>

	<div class="col-sm-12">
		<h5>Loan size based Collections</h5>
	</div>
	<div class="col-sm-12">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>Loan Size</th>
					<th>Bad Debt</th>
					<th>Funded Amount</th>
					<th>Bad Debt Count</th>
					<th>Funded Amount Count</th>
					<th>Delinquency Rate</th>
					<th>Default Amount</th>
					<th>Default Count</th>
					<th>Default Rate</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of groupLoanSizeArray">
					<td *ngIf="item.countDeliquency != '0'">{{ item.groupName }}</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.badDebt | currency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.fundedAmount | currency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.countDeliquency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">{{ item.countFunded }}</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.delinquencyRate }}%
					</td>
					<td *ngIf="item.countDeliquency != '0'">
						{{ item.defaultAmount | currency }}
					</td>
					<td *ngIf="item.countDeliquency != '0'">{{ item.countDefault }}</td>
					<td *ngIf="item.countDeliquency != '0'">{{ item.defaultRate }}%</td>
				</tr>
				<tr>
					<td class="subTableBold">TOTAL</td>
					<td class="subTableBold">{{ amountGroupBadDebt | currency }}</td>
					<td class="subTableBold">{{ amountGroupFunded | currency }}</td>
					<td class="subTableBold">{{ countGroupBadDebt }}</td>
					<td class="subTableBold">{{ countGroupFunded }}</td>
					<td class="subTableBold">
						{{ ((amountGroupBadDebt / amountGroupFunded) * 100).toFixed() }}%
					</td>
					<td class="subTableBold">{{ amountGroupDefault | currency }}</td>
					<td class="subTableBold">{{ countGroupDefault }}</td>
					<td class="subTableBold">
						{{ ((amountGroupDefault / amountGroupFunded) * 100).toFixed() }}%
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
