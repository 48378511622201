import { Component } from '@angular/core';

@Component({
  selector: 'app-lpp-reports',
  templateUrl: './lpp-reports.component.html',
  styleUrls: ['./lpp-reports.component.scss']
})
export class LppReportsComponent {

}
