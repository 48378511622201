<div class="container-fluid">
	<!--ADD PRE-QUALIFYING INFORMATION-->
	<div class="row">
		<div class="sticky">
			<div class="row row-cols-3">
				<div class="col-8" *ngIf="!getparamid" id="headertitle">
					<h5>Create Application</h5>
				</div>
				<div class="col-8" *ngIf="getparamid" id="headertitle">
					<h5>Edit Application</h5>
				</div>
				<div class="col-4" id="alignbtn">
					<a [routerLink]="['/dashboard']" class="btn btn-secondary">Cancel</a>
					&nbsp;
					<!-- <a [routerLink]="['/applications']" class="btn btn-primary" (click)="userSubmit()">Save</a> -->
					<!-- <a [routerLink]="['/applications']" class="btn btn-primary" *ngIf="!getparamid"
                            (click)="userSubmit()" >Save</a> -->
					<button
						class="btn btn-primary"
						(click)="userSubmit()"
						*ngIf="!getparamid"
						[disabled]="this.applicationEntry.applicationForm.invalid"
					>
						Save
					</button>
					&nbsp;
					<button
						class="btn btn-primary"
						(click)="userUpdate()"
						*ngIf="getparamid"
					>
						Save
					</button>
					<!-- <a [routerLink]="['/applications']" class="btn btn-primary" *ngIf="getparamid" (click)="userUpdate()">Save
                        </a> -->
				</div>
			</div>
		</div>
	</div>
	<h5 class="pre">PRE-QUALIFYING</h5>
	<div class="container-md">
		<div [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Application name </label>
				</div>
				<div class="col-md-4">
					<input
						required
						type="text"
						class="form-control form-control-sm"
						[ngClass]="applicationName?.errors ? 'is-invalid' : ''"
						formControlName="Deal_Name"
					/>
					<small class="invalid-feedback"> Application name required </small>
				</div>
				<div class="col-sm-2">
					<label>Which Province do you live in? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="Province1"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readProvinceDropdown"
							value="{{ item.Province_Code }}"
						>
							{{ item.Province_Name }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Residency Status </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="Residency_Status"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readResidencyDropdown"
							value="{{ item.residency_ref_value }}"
						>
							{{ item.residency_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>If needed could you find a Co-Signer? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_signer"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Employment Type </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="employment_type"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readEmploymentDropdown"
							[value]="item.employment_ref_value"
						>
							{{ item.employment_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Are you currently employed (Completed probation) </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="employed"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Do you earn at least $1200 (Primary income) </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="least_earn"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>How do you get paid (Payment Method) </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="payment_method"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPaymentMethodDropdown"
							value="{{ item.payment_ref_value }}"
						>
							{{ item.payment_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Do you have any Active Payday loans? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="payday_loans"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Bankruptcy, Credit Counselling, Consumer Proposal </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="bankruptcy_cc_pp"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readBankruptcyDropdown"
							value="{{ item.bankruptcy_ref_value }}"
						>
							{{ item.bankruptcy_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Are you currently a Home Owner? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="home_owner"
						(click)="hideSubForm()"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Do you have a LM Credit loan? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="lm_credit_loan"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Consolidating HIL </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="consolidating_hil"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>
		</div>
	</div>

	<!--CLIENT - EXISTING MORTGAGE Form-->
	<div class="container-md" id="myHomeOwner">
		<h5 class="hideH5">CLIENT - EXISTING MORTGAGE</h5>
		<div [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Mortgage Balance</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="mortgage_balance"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>EMV (Est. Mortgage Value)</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="mortgage_value"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Loan Details</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="loan_details"
					/>
				</div>
				<div class="col-sm-2">
					<label>Mortgage Details</label>
				</div>
				<div class="col-md-4">
					<textarea
						class="form-control"
						formControlName="mortgage_details"
					></textarea>
				</div>
			</div>
		</div>
	</div>

	<h5>REFERRAL</h5>
	<div class="container-md">
		<div [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Agency Name</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="referral_agency"
					>
						<option *ngFor="let item of readAgencyRecords" [value]="item.id">
							{{ item.Account_Name }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Sales Agent</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						[ngClass]="
							this.applicationEntry?.applicationForm?.get('sales_agent')?.errors
								? 'is-invalid'
								: ''
						"
						formControlName="sales_agent"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readSalesAgentDropdown"
							value="{{ item.sales_agent_ref_value }}"
						>
							{{ item.sales_agent_picklist }}
						</option>
					</select>
					<small class="invalid-feedback">
						Sales agent selection required
					</small>
				</div>
				<div class="col-sm-2">
					<label>Collector Name</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="referral_collector"
					>
						<option *ngFor="let item of readCollectorRecords" [value]="item.id">
							{{ item.Full_Name }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Broker</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="broker"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readBrokerDropdown"
							value="{{ item.broker_ref_value }}"
						>
							{{ item.broker_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Creditor</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="referral_creditor"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readReferralCreditorDropdown"
							value="{{ item.creditor_ref_value }}"
						>
							{{ item.creditor_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Referral method</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="referral_method"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readReferralMethodDropdown"
							value="{{ item.referral_method_ref_value }}"
						>
							{{ item.referral_method_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Creditor Details</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="creditor_details"
					/>
				</div>
				<div class="col-sm-2">
					<label>Referral Date</label>
				</div>
				<div class="col-md-4">
					<input
						type="date"
						class="form-control"
						formControlName="referral_date"
					/>
				</div>
				<div class="col-sm-2">
					<label>Account Number (EDP)</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="account_edp"
					/>
				</div>
				<div class="col-sm-2">
					<label>Closing Date</label>
				</div>
				<div class="col-md-4">
					<input
						type="date"
						class="form-control"
						formControlName="closing_date"
					/>
				</div>
				<div class="col-sm-2">
					<label>Account Balance</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="referral_balance"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Agency Location</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="agency_location"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readAgencyLocationDropdown"
							value="{{ item.agency_location_ref_value }}"
						>
							{{ item.agency_location_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Settlement Amount</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="settlement_amount"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Creditor Portfolio</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="referral_creditor_portfolio"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readCreditPortfolioDropdown"
							[value]="item.credit_portfolio_ref_value"
						>
							{{ item.creditor_portfolio_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Total Requested Amount</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="requested_amount"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Loan Type</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="loan_type"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readLoanTypeDropdown"
							value="{{ item.loan_type_ref_value }}"
						>
							{{ item.loan_type_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Additional Debt? </label>
				</div>
				<div class="col-md-4" (click)="hideSubForm()">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="isDebt"
					>
						<option value="NO" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Lead Source</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="lead_source"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readLeadSourceDropdown"
							value="{{ item.lead_source_ref_value }}"
						>
							{{ item.lead_source_picklist }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<!--ADDITIONAL DEBT Form-->
	<div class="container-md" id="myAddDebt">
		<h5 class="hideH5">ADDITIONAL DEBT</h5>
		<div class="table-responsive">
			<div [formGroup]="this.applicationEntry.applicationForm">
				<div class="form-group" formArrayName="Additional_Debt">
					<table class="table table-bordered table-hover">
						<thead class="table-light">
							<tr>
								<th scope="col">Agency</th>
								<th scope="col">Creditor</th>
								<th scope="col">Creditor Portfolio</th>
								<th scope="col">Account Number</th>
								<th scope="col">Collector</th>
								<th scope="col">Account Balance (CA$)</th>
								<th scope="col">Type of Credit</th>
								<th scope="col">Remove</th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let control of this.applicationEntry.applicationForm.controls
										.Additional_Debt['controls'];
									let i = index
								"
								[formGroupName]="i"
							>
								<td>
									<select formControlName="Agency">
										<option
											*ngFor="let item of readAgencyRecords"
											[ngValue]="item.id"
										>
											{{ item.Account_Name }}
										</option>
									</select>
								</td>
								<td>
									<input type="text" formControlName="misc" />
								</td>
								<td>
									<select formControlName="Creditor_Portfolio">
										<option
											*ngFor="let item of readCreditPortfolioDropdown"
											[value]="item.credit_portfolio_ref_value"
										>
											{{ item.creditor_portfolio_picklist }}
										</option>
									</select>
								</td>
								<td>
									<input
										type="text"
										formControlName="Account_Number"
										placeholder="Account Number"
									/>
								</td>
								<td>
									<select formControlName="Collector">
										<option
											*ngFor="let item of readCollectorRecords"
											[value]="item.id"
										>
											{{ item.Full_Name }}
										</option>
									</select>
								</td>
								<td>
									<input
										type="text"
										formControlName="Account_Balance"
										placeholder="Amount"
										(click)="addDebts()"
									/>
								</td>
								<td>
									<select formControlName="Type_of_Credit">
										<option
											*ngFor="let item of readCreditTypeDropdown"
											[value]="item.credit_type_ref_value"
										>
											{{ item.credit_type_picklist }}
										</option>
									</select>
								</td>
								<td>
									<button class="btn-close" (click)="removeDebts(i)"></button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row">
				<div class="col">
					<button
						class="btn btn-outline-primary"
						(click)="addAdditionalDebts()"
					>
						+Add
					</button>
				</div>
				<div class="col-md-auto">
					<label>Total</label>
				</div>
				<div class="col col-lg-2">
					<input type="text" class="form-control" formControlName="debtTotal" />
				</div>
			</div>
		</form>
	</div>
	<!--ADD CLIENT PERSONAL INFORMATION-->
	<h5>CLIENT - PERSONAL</h5>
	<div class="container-md">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Gender </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="gender"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Male">Male</option>
						<option value="Female">Female</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Do you have another name? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="another_name"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Marital Status </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="marital_status"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readMaritalStatusDropdown"
							[value]="item.marital_status_ref_value"
						>
							{{ item.marital_status_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Alternative Name</label>
				</div>
				<div class="col-sm-4">
					<!--<input type="text" class="form-control" formControlName="uniqueID">-->
					<input
						type="text"
						class="form-control form-control-sm"
						formControlName="alternative_name"
					/>
				</div>
				<div class="col-sm-2">
					<label>Number of Dependents</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="number_dependents"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readNumberDependentsDropdown"
							[value]="item.number_dependent_ref_value"
						>
							{{ item.number_dependent_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Best contact time</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="best_contact_time"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readBestContactTimeDropdown"
							[value]="item.best_time_ref_value"
						>
							{{ item.best_time_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Date of Birth</label>
				</div>
				<div class="col-sm-4">
					<input
						type="date"
						class="form-control"
						formControlName="birth_date"
					/>
				</div>
				<div class="col-sm-2">
					<label>Last Contact Date</label>
				</div>
				<div class="col-sm-4">
					<input
						type="date"
						class="form-control"
						formControlName="last_contact_date"
					/>
				</div>
				<!-- <div class="col-sm-2">
                        <label>First Name</label>
                    </div>
                    <div class="col-sm-4">
                        <input required type="text" class="form-control form-control-sm" [ngClass]="firstname.errors ? 'is-invalid': ''" formControlName="firstname" />
                        <small class="invalid-feedback">
                            First name required
                        </small>
                    </div>
                    <div class="col-sm-2">
                        <label>Middle Name</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" formControlName="middlename" />
                    </div>

                    <div class="col-sm-2">
                        <label>Last Name</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" formControlName="lastname" />
                    </div> -->

				<div class="col-sm-2">
					<label>SIN</label>
				</div>
				<div class="col-sm-4">
					<input
						(keydown)="numberOnly($event)"
						(keyup)="sinValidate($event)"
						placeholder="000000000"
						type="text"
						class="form-control"
						formControlName="S_I_N"
					/>
				</div>
				<div class="col-sm-2">
					<label>Do you currently own a car?</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="own_car"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Email</label>
				</div>
				<div class="col-sm-4">
					<input type="email" class="form-control" formControlName="email" />
				</div>
				<div class="col-sm-2">
					<label>What is the value of the car?</label>
				</div>
				<div class="col-md-4">
					<input
						type="text"
						class="form-control"
						formControlName="car_value"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Mobile</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="mobile"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Phone</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="phone"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>No SIN Reason</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="sin_reason"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readNoSinReasonDropdown"
							[value]="item.no_sin_reason_ref_value"
						>
							{{ item.no_sin_reason_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Required?</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_applicant"
						(click)="hideSubForm()"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Emergency Contacts? </label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="isEmergency"
						(click)="hideSubForm()"
					>
						<option value="NO" selected disabled hidden>-None-</option>
						<option value="Yes">Yes</option>
						<option value="No">Yes, later</option>
					</select>
				</div>
				<!-- <div class="col-sm-2">
                        <label>Creation Date</label>
                    </div>
                    <div class="col-sm-4" *ngIf="!getparamid">
                        <input type="text" class="form-control" formControlName="createdDate">
                    </div>
                    <div class="col-sm-4" *ngIf="getparamid">
                        <input type="text" class="form-control" formControlName="createdDate">
                    </div> -->
			</div>
		</form>
	</div>
	<!--ADD CLIENT PERSONAL INFORMATION Form-->
	<div class="container-md" id="myEmergency">
		<h5>CLIENT -EMERGENCY CONTACT</h5>
		<div [formGroup]="this.applicationEntry.applicationForm">
			<div class="form-group" formArrayName="EMERGANCY_CONTACTS">
				<table class="table table-bordered table-hover">
					<thead class="table-light">
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Relation</th>
							<th scope="col">Phone</th>
							<th scope="col">Email</th>
							<th scope="col">Remove</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let control of this.applicationEntry.applicationForm.controls
									.EMERGANCY_CONTACTS['controls'];
								let i = index
							"
							[formGroupName]="i"
						>
							<td>
								<input type="text" formControlName="Name1" />
							</td>
							<td>
								<input type="text" formControlName="Relation_to_Applicant" />
							</td>
							<td>
								<input
									type="text"
									formControlName="Phone_Number"
									placeholder="(###) ###-####"
									(keyup)="formatPhoneNumber($event)"
								/>
							</td>
							<td>
								<input type="email" formControlName="Email" />
							</td>
							<td>
								<button class="btn-close" (click)="removeEmergency(i)"></button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- <button class="btn btn-outline-primary" (click)="addEmergency()">+ Add</button> -->
	</div>

	<!--ADD CLIENT PERSONAL INFORMATION-->
	<h5>CLIENT - ADDRESS</h5>
	<div class="container-md">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Address</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						autocapitalize="off"
						spellcheck="false"
						id="keyboard"
						class="form-control"
						#search
					/>
				</div>
				<div class="col-sm-2">
					<label>Address Line 2 (Unit number)</label>
				</div>
				<div class="col-sm-4">
					<input type="text" class="form-control" formControlName="address2" />
				</div>
				<div class="col-sm-2">
					<label>Address</label>
				</div>
				<div class="col-sm-4">
					<input type="text" class="form-control" formControlName="address1" />
				</div>
				<div class="col-sm-2">
					<label>City</label>
				</div>
				<div class="col-sm-4">
					<input type="text" class="form-control" formControlName="city" />
				</div>
				<div class="col-sm-2">
					<label>Province</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="province_code"
					/>
				</div>
				<div class="col-sm-2">
					<label>Postal Code</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="postal_code"
					/>
				</div>
				<div class="col-sm-2">
					<label>Length of Residence</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="residence_length"
					/>
				</div>
				<div class="col-sm-2">
					<label>Monthly Payments</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="monthly_payments"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
			</div>
		</form>
	</div>

	<!--ADD CLIENT PERSONAL INFORMATION-->
	<h5>CLIENT - EMPLOYMENT</h5>
	<div class="container-md">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Employer</label>
				</div>
				<div class="col-sm-4">
					<input type="text" class="form-control" formControlName="employer" />
				</div>
				<div class="col-sm-2">
					<label>Pay Frequency</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="pay_frequency"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPayFrequencyDropdown"
							[value]="item.pay_frequency_ref_value"
						>
							{{ item.pay_frequency_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Work Phone</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="work_phone"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Income (net paycheque income)</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="net_paycheque"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Work Extension</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="work_extension"
					/>
				</div>
				<div class="col-sm-2">
					<label>Supervisor Name</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="supervisor_name"
					/>
				</div>
				<div class="col-sm-2">
					<label>Length of Employment</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="employment_length"
					/>
				</div>
				<div class="col-sm-2">
					<label>Supervisor Phone Number</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="supervisor_phone"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Position</label>
				</div>
				<div class="col-sm-4">
					<input type="text" class="form-control" formControlName="position" />
				</div>
				<div class="col-sm-2">
					<label>Additional Employment</label>
				</div>
				<div class="col-sm-4">
					<textarea
						class="form-control"
						formControlName="additional_employment"
					></textarea>
				</div>
				<div class="col-sm-2">
					<label>Income Amount (Household Income)</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="income_amount"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Employer Name (Household Income)</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="employer_name"
					/>
				</div>
				<div class="col-sm-2">
					<label>Pay Frequency(Household Income)</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="household_pay_frequency"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPayFrequencyDropdown"
							[value]="item.pay_frequency_ref_value"
						>
							{{ item.pay_frequency_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Previous Employment Section?</label>
				</div>
				<div class="col-sm-4">
					<input
						type="checkbox"
						formControlName="isPreviousEmployment"
						(change)="checkValue($event)"
					/>
					<!-- <input type="checkbox" formControlName="isPreviousEmployment" (change)="previousEmployment()" /> -->
				</div>
			</div>
		</form>
	</div>

	<!--CLIENT - PREVIOUS EMPLOYMENT Form-->
	<div class="container-md" id="myPrevEmp">
		<h5>CLIENT - PREVIOUS EMPLOYMENT</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Previous Employment Type</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="previous_employment_type"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readEmploymentDropdown"
							[value]="item.employment_ref_value"
						>
							{{ item.employment_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Previous Position</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="previous_position"
					/>
				</div>
				<div class="col-sm-2">
					<label>Previous Employer</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="previous_employer"
					/>
				</div>
				<div class="col-sm-2">
					<label>Previous Length of Employment</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="previous_emp_length"
					/>
				</div>
				<div class="col-sm-2">
					<label>Previous Supervisor Name</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="previous_supervisor_name"
					/>
				</div>
				<div class="col-sm-2">
					<label>Previous Supervisor Phone number</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="previous_supervisor_phone"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Previous Income(net paycheque income)</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="previous_income"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Previous Pay Frequency</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="previous_pay_frequency"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPayFrequencyDropdown"
							[value]="item.pay_frequency_ref_value"
						>
							{{ item.pay_frequency_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Previous Payment Method</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="previous_pay_method"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPaymentMethodDropdown"
							value="{{ item.payment_ref_value }}"
						>
							{{ item.payment_picklist }}
						</option>
					</select>
				</div>
			</div>
		</form>
	</div>

	<!--CLIENT - PREVIOUS EMPLOYMENT Form-->
	<div class="container-md" id="coPersonal">
		<h5>CO-APPLICANT - PERSONAL</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Co-Applicant First Name</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_firstname"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Last Name</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_lastname"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Gender</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_app_gender"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Male">Male</option>
						<option value="Female">Female</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Email</label>
				</div>
				<div class="col-sm-4">
					<input
						type="email"
						class="form-control"
						formControlName="co_app_email"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Date of Birth</label>
				</div>
				<div class="col-sm-4">
					<input
						type="date"
						class="form-control"
						formControlName="co_app_birth"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Home Number</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_homenumber"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant SIN</label>
				</div>
				<div class="col-sm-4">
					<input
						(keydown)="numberOnly($event)"
						(keyup)="sinValidate($event)"
						placeholder="000000000"
						type="text"
						class="form-control"
						formControlName="co_app_sin"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Mobile</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_mobile"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
			</div>
		</form>
	</div>

	<!--CO-APPLICANT - EMERGENCY CONTACT Form-->
	<div class="container-md" id="coEmergency">
		<h5>CO-APPLICANT - EMERGENCY CONTACT</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Co-Applicant emergency contact name</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_emergency_name"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Emergency contact number</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_emergency_number"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant emergency contact relation</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_emergency_relation"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant emergency contact email</label>
				</div>
				<div class="col-sm-4">
					<input
						type="email"
						class="form-control"
						formControlName="co_emergency_email"
					/>
				</div>
			</div>
		</form>
	</div>

	<!--CO-APPLICANT - ADDRESS Form-->
	<div class="container-md" id="coAddress">
		<h5>CO-APPLICANT - ADDRESS</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Address</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						autocapitalize="off"
						spellcheck="false"
						id="keyboard"
						class="form-control"
						#searchAddress
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Address</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_address1"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Residential Status</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_app_residential_status"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option value="Rent">Rent</option>
						<option value="Own">Own</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Address Line 2 (Unit number)</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_address2"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Length of Residence</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_app_residential_length"
					>
						<option
							*ngFor="let item of readResidenceLengthDropdown"
							value="{{ item.residence_length_ref_value }}"
						>
							{{ item.residence_length_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant City</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_city"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Province</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_province"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Postal Code</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_postal"
					/>
				</div>
			</div>
		</form>
	</div>

	<!--CO-APPLICANT - EMPLOYMENT Form-->
	<div class="container-md" id="coEmployment">
		<h5>CO-APPLICANT - EMPLOYMENT</h5>
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Co-Applicant Employment Type</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_app_emp_type"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readEmploymentDropdown"
							[value]="item.employment_ref_value"
						>
							{{ item.employment_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Position</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_position"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Employer</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_employer"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Years Worked</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_years_worked"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Supervisor Name</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_supervisor_name"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Supervisor Phone Number</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_supervisor_phone"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Annual Income</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_annual_income"
						(keydown)="numberOnly($event)"
						(blur)="formatCurrency($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Work Number</label>
				</div>
				<div class="col-sm-4">
					<input
						type="text"
						class="form-control"
						formControlName="co_app_work_number"
						placeholder="(###) ###-####"
						(keyup)="formatPhoneNumber($event)"
					/>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Payment Method</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_app_pay_method"
					>
						<option value=" none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPaymentMethodDropdown"
							value="{{ item.payment_ref_value }}"
						>
							{{ item.payment_picklist }}
						</option>
					</select>
				</div>
				<div class="col-sm-2">
					<label>Co-Applicant Pay Frequency</label>
				</div>
				<div class="col-sm-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="co_app_pay_frequency"
					>
						<option value="none" selected disabled hidden>-None-</option>
						<option
							*ngFor="let item of readPayFrequencyDropdown"
							[value]="item.pay_frequency_ref_value"
						>
							{{ item.pay_frequency_picklist }}
						</option>
					</select>
				</div>
			</div>
		</form>
	</div>
	<!-- DO NOT DELETE<input type="text" formControlName="folderID" />
        <input type="text" formControlName="folderURL" />
        <input type="text" formControlName="filePath" /> This is for folder URL and PATH for ZOHO Workdrive Folder
        <input type="text" formControlName="recordID" />
        <input type="text" formControlName="folderID" />-->
	<h5>DOCUMENTS</h5>
	<div class="container-md">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row">
				<div class="col-sm-2">
					<label
						><input
							type="checkbox"
							formControlName="consentBox"
							(change)="checkFileUpload($event)"
						/>Client Consent</label
					>
				</div>
				<div class="col-sm-10">
					<input
						#consentInput
						id="consentInput"
						type="file"
						(change)="uploadConsent($event.target.files)"
					/><span id="consent"></span>
				</div>
				<!-- <input type="text" formControlName="documentsUploaded"/> This is for document Upload-->
				<div class="col-sm-2">
					<label
						><input
							type="checkbox"
							formControlName="govtidBox"
							(change)="checkFileUpload($event)"
						/>Governtment Issued ID</label
					>
				</div>
				<div class="col-sm-10">
					<input
						type="file"
						id="govtidInput"
						(change)="uploadGovt($event.target.files)"
					/><span id="govtid"></span>
				</div>
				<div class="col-sm-2">
					<label
						><input
							type="checkbox"
							formControlName="bankBox"
							(change)="checkFileUpload($event)"
						/>Bank Statements</label
					>
				</div>
				<div class="col-sm-10">
					<input
						type="file"
						id="bankInput"
						(change)="uploadBank($event.target.files)"
					/><span id="bank"></span>
				</div>
				<div class="col-sm-2">
					<label
						><input
							type="checkbox"
							formControlName="bureauBox"
							(change)="checkFileUpload($event)"
						/>Credit Bureau</label
					>
				</div>
				<div class="col-sm-10">
					<input
						type="file"
						id="bureauInput"
						(change)="uploadBureau($event.target.files)"
					/><span id="bureau"></span>
				</div>
			</div>
			<button (click)="uploadLocal()">Upload</button>
			<!-- <button (click)="getFiles()">Upload</button> -->
		</form>
	</div>
	<h5>DOCUMENT STAGE</h5>
	<div class="container-md">
		<form [formGroup]="this.applicationEntry.applicationForm">
			<div class="row row-cols-4">
				<div class="col-sm-2">
					<label>Stage</label>
				</div>
				<div class="col-md-4">
					<select
						class="form-select"
						aria-label="Default select example"
						formControlName="stage"
					>
						<option *ngFor="let item of readStageRecords" [value]="item.id">
							{{ item.display_value }}
						</option>
					</select>
				</div>
			</div>
		</form>
	</div>
</div>
