import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Pipe({
	name: 'currencyShortForm',
})
export class CurrencyShortFormPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		let exp;
		const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
		if (
			Number.isNaN(value) ||
			(value < 1000 && value >= 0) ||
			!this.isNumeric(value) ||
			(value < 0 && value > -1000)
		) {
			if (!!args && this.isNumeric(value) && !(value < 0) && value != 0) {
				return value.toFixed(args[0]);
			} else {
				return value;
			}
		}

		exp = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
		return (
			formatCurrency(
				Number((value / Math.pow(1000, exp)).toFixed(args[0])),
				'en-CA',
				'$',
				'CAD',
				'1.0'
			) + suffixes[exp - 1]
		);
	}
	isNumeric(value): boolean {
		if (value < 0) value = value * -1;
		return /^-{0,1}\d+$/.test(value) || /^\d+\.\d+$/.test(value);
	}
}
