<div class="container-fluid">
	<div class="row row-cols-12">
		<div class="col-sm-8">
			<h5>Agency based Collections</h5>
		</div>
	</div>
	<div class="col-sm-12">
		<table
			class="table table-bordered table-hover"
			matSort
			(matSortChange)="sortRaw($event)"
		>
			<thead>
				<tr>
					<th mat-sort-header="Agency">Agency</th>
					<th mat-sort-header="Original_Loan_Amount">Original Loan Amount</th>
					<th mat-sort-header="Original_Loan_Amount_Count">
						Original Loan Amount Count
					</th>
					<th mat-sort-header="Current_Balance">Current Balance</th>
					<th mat-sort-header="Principal_recovered">Principal recovered</th>
					<th mat-sort-header="Delinqency_Amount">Delinqency Amount</th>
					<th mat-sort-header="Delinqency_Rate">Delinqency Rate</th>
					<th mat-sort-header="Default_Amount">Default Amount</th>
					<th mat-sort-header="Default_Rate">Default Rate</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of sortedData">
					<td>
						<a
							class="noline"
							target="_self"
							[routerLink]="['/nlsagencycollectionscollectors']"
							[queryParams]="{ agency: item.Agency }"
							>{{ item.Agency }}</a
						>
					</td>
					<td>{{ item.Original_Loan_Amount | currency }}</td>
					<td>{{ item.Original_Loan_Amount_Count }}</td>
					<td>{{ item.Current_Balance | currency }}</td>
					<td>{{ item.Principal_recovered | currency }}</td>
					<td>{{ item.Delinqency_Amount | currency }}</td>
					<td>{{ item.Delinqency_Rate }}%</td>
					<td>{{ item.Default_Amount | currency }}</td>
					<td>{{ item.Default_Rate }}%</td>
				</tr>
				<tr>
					<td class="subTableBold">TOTAL</td>
					<td class="subTableBold">
						{{ total_Original_Loan_Amount | currency }}
					</td>
					<td class="subTableBold">{{ total_Original_Loan_Amount_Count }}</td>
					<td class="subTableBold">{{ total_Current_Balance | currency }}</td>
					<td class="subTableBold">
						{{ total_Principal_recovered | currency }}
					</td>
					<td class="subTableBold">{{ total_Delinqency_Amount | currency }}</td>
					<td class="subTableBold"></td>
					<td class="subTableBold">{{ total_Default_Amount | currency }}</td>
					<td class="subTableBold"></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!--
<div class="container-fluid">
    <div class="row row-cols-12">
        <div class="col-sm-8">
            <h5>Agency based Collections</h5>
        </div>
    </div>
    <div class="col-sm-12">
        <table class="table table-bordered" matSort (matSortChange)="sortData($event)">
            <thead>
                <tr>
                    <th mat-sort-header="groupName">Agency</th>
                    <th mat-sort-header="badDebt">Bad Debt</th>
                    <th mat-sort-header="fundedAmount">Funded Amount</th>
                    <th mat-sort-header="countDeliquency">Bad Debt Count</th>
                    <th mat-sort-header="countFunded">Funded Amount Count</th>
                    <th mat-sort-header="delinquencyRate">Delinquency Rate</th>
                    <th mat-sort-header="defaultAmount">Default Amount</th>
                    <th mat-sort-header="countDefault">Default Count</th>
                    <th mat-sort-header="defaultRate">Default Rate</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of sortedData">
                    <td *ngIf="item.groupName === null && item.countDeliquency != '0'" style="color: red; font-weight: bold;"><a class="noline" target="_self" [routerLink]="['/nlsagencycollectionscollectors']" [queryParams]=
                        "{agency:'null'}">Null</a></td>
                    <td *ngIf="item.groupName === '' && item.countDeliquency != '0'" style="color: red; font-weight: bold;"><a class="noline" target="_self" [routerLink]="['/nlsagencycollectionscollectors']" [queryParams]=
                        "{agency:item.groupName}">Empty</a></td>
                    <td *ngIf="item.groupName != null && item.groupName != '' && item.countDeliquency != '0'"><a class="noline" target="_self" [routerLink]="['/nlsagencycollectionscollectors']" [queryParams]=
                        "{agency:item.groupName}">{{item.groupName}}</a></td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.badDebt | currency}}</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.fundedAmount | currency}}</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.countDeliquency}}</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.countFunded}}</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.delinquencyRate}}%</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.defaultAmount | currency}}</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.countDefault}}</td>
                    <td *ngIf="item.countDeliquency != '0'">{{item.defaultRate}}%</td>
                </tr>
                <tr>
                    <td class="subTableBold">TOTAL</td>
                    <td class="subTableBold">{{amountGroupBadDebt | currency}}</td>
                    <td class="subTableBold">{{amountGroupFunded | currency}}</td>
                    <td class="subTableBold">{{countGroupBadDebt}}</td>
                    <td class="subTableBold">{{countGroupFunded}}</td>
                    <td class="subTableBold">{{(((amountGroupBadDebt)/amountGroupFunded)*100).toFixed()}}%</td>
                    <td class="subTableBold">{{amountGroupDefault | currency}}</td>
                    <td class="subTableBold">{{countGroupDefault}}</td>
                    <td class="subTableBold">{{(((amountGroupDefault)/amountGroupFunded)*100).toFixed()}}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</div> -->
