<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			Agency: {{ agencyName }} | Collector: {{ collectorName }} |
			{{ totalAmount | currency }}
		</h5>
	</div>
	<div>
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>Client Name</th>
					<th>Days past due</th>
					<th>Amount</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of clientArray">
					<td>{{ item.ClientName }}</td>
					<td>{{ item.internal_DPD }}</td>
					<td>{{ item.current_principal_balance | currency }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
