import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { CustomModalService } from '@lmc-app/services/modal/custom-modal.service';
import { ModalNames } from '@lmc-app/services/modal/custom-modal-options';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-insurance-trans-cancellation',
  templateUrl: './insurance-trans-cancellation.component.html',
  styleUrls: ['./insurance-trans-cancellation.component.scss']
})
export class InsuranceTransCancellationComponent implements OnInit, AfterViewInit {

  loan_number = new FormControl('', Validators.required);
  displayRes: any;
  modalDataSubscription: Subscription;
  userName: any;
  btnSpinner = false;
  btnShowText = true;
  constructor(private apiService: ApiServiceService, private customModalService: CustomModalService) { }
  async ngAfterViewInit(): Promise<void> {
    await this.getSessionDetails();
    this.modalDataSubscription = this.customModalService.modelClosedObservable.subscribe((closedOptions) => {
      this.handleModalClosed(closedOptions);
    });
  }
  ngOnInit(): void {
    this.displayRes = '';
  }

  async handleModalClosed(closedOptions = {}) {
    const { modalName, result, options } = closedOptions as any;
    if (modalName === ModalNames.INSURANCE_TRANSACTION_CANCELLATION && result === 'YES' && (typeof options !== undefined || options !== '')) {
      console.log("PROCEED CANCELLATION!");
      this.cancelInsuranceTransactions(options, this.userName);
    }
  }

  openModal(
    modalName,
    headerTitle: string = '',
    bodyText: string = '',
    buttons: Array<string> = [''],
    options = ''
  ) {
    this.customModalService.openModal({
      modalName,
      activateModal: true,
      headerTitle,
      bodyText,
      buttons,
      options
    });
  }

  getFileName() {
    this.apiService.checkLoanNumberName(this.loan_number.value).subscribe((res: any) => {
      if (res?.status && res?.data?.length > 0) {
        this.openModal(
          ModalNames.INSURANCE_TRANSACTION_CANCELLATION,
          `${res?.data?.[0]?.firstname1} ${res?.data?.[0]?.lastname1}`,
          `Do you want to cancel the insurance transactions?`,
          ['YES', 'NO'],
          `${res?.data?.[0]?.acctrefno}`
        );
      } else {
        this.displayRes = `Loan number not found!`
      }
    })
  }

  cancelInsuranceTransactions(acctrefno, reversed_by) {
    this.displayRes = '';
    this.loadResult();
    this.apiService.cancelInsuranceTransactions(acctrefno, reversed_by).subscribe((res: any) => {
      this.displayRes = res?.message;
      this.resultCompleted();
    })
  }

  async getSessionDetails() {
    return new Promise((resolve, reject) => {
      this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe(async (res: any) => {
        if (res.message === 'Success') {
          this.userName = res?.['data']?.[0]?.['full_name'];
          resolve(true);
        }
      })

    })
  }

  loadResult() {
    this.btnSpinner = true;
    this.btnShowText = false;
  }

  resultCompleted() {
    this.btnSpinner = false;
    this.btnShowText = true;
  }

  ngOnDestroy() {
    this.modalDataSubscription?.unsubscribe();
  }

}


