<div style="text-align: center;">
    <h5>TRANSACTION ENTRY: INSURANCE CANCELLATION</h5>
    <div class="center">
        <div>
            <mat-form-field appearance="outline" style="width: 30%; padding: 20px 20px 0 20px;">
                <mat-label>Loan number</mat-label>
                <input matInput type="number" min="1" [formControl]="loan_number" required />
                <mat-error *ngIf="
                loan_number.hasError(
                                    'required'
                                ) && loan_number.touched
                            ">
                    Required
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" style="width: 10%;" [disabled]="loan_number.invalid"
                (click)="getFileName()" disabled={{btnSpinner}}>
                <span *ngIf=" btnSpinner" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="btnShowText">Save</span>
            </button>
        </div>
        <h5 style="color: green;">{{displayRes}}</h5>
    </div>
</div>