import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-current-account-information',
  templateUrl: './current-account-information.component.html',
  styleUrls: ['./current-account-information.component.scss']
})
export class CurrentAccountInformationComponent implements OnInit, OnDestroy {

  loanGroupDataSubscription: Subscription;

  gridData: any;

  getRowStyle: any
  defaultColDef: any;
  public columnDefs = [];
  public rowData;
  public gridApi;
  public gridColumnApi;
  public gridOptions;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  CurrentAccountInfo_Report: any;

  dateSelected: any;
  btnSpinner = false;
  btnShowText = true;
  selectedLoans: any;
  selectedStatus: any;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    //Payment History
    this.columnDefs = [
      { headerName: 'loan_number', field: 'loan_number', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Account Name', field: 'Account Name', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'LoanGroup', field: 'LoanGroup', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'LoanGroup Description', field: 'loan_group_desc', sortable: true, filter: "agTextColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Maturity Date', field: 'Maturity Date', sortable: true, filter: 'agDateColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Status Code', field: 'Status Code', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Current Rate', field: 'Current Rate', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Credit Score', field: 'Credit Score', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Orginal Loan Amount', field: 'Orginal Loan Amount', sortable: true, filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Current Principal Balance', field: 'Current Principal Balance', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Total Past Due Balance', field: 'Total Past Due Balance', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Total Current Due Balance', field: 'Total Current Due Balance', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Interest Balance', field: 'Interest Balance', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Interest Balance As Of Date', field: 'Interest Balance As Of Date', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Trial Principal Balance', field: 'Trial Principal Balance', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Trail Balance Date', field: 'Trail Balance Date', sortable: true, filter: 'agDateColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Insurance Premium', field: 'Insurance Premium', sortable: true, filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Ins tax', field: 'Ins tax', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Industry', field: 'Industry', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Payments Remaining', field: 'Payments Remaining', sortable: true, filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Principal Period', field: 'Principal Period', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Payoff Date', field: 'Payoff Date', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Closed Date', field: 'Closed Date', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Reg Payment', field: 'Reg Payment', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Open Date', field: 'Open Date', sortable: true, filter: 'agDateColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'days_past_due', field: 'days_past_due', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'State', field: 'State', sortable: true, filter: "agDateColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Position', field: 'Position', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'LoanStatus', field: 'LoanStatus', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'TDSR', field: 'TDSR', sortable: true, filter: "agNumberColumnFilter", minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'DOB', field: 'DOB', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Homeowner', field: 'Homeowner', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Write-off', field: 'Write-off', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, },
      { headerName: 'Write-off Date', field: 'Write-off Date', sortable: true, filter: 'agTextColumnFilter', minWidth: 150, maxWidth: 150, width: 150, }

    ]
    this.defaultColDef = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'current_account_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'current_account_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }
  ngOnInit(): void {
    this.gridData = '';
  }

  currentDate(e) {
    this.dateSelected = e;
  }

  getReportType(e) {
    this.selectedLoans = e?.['data'];
  }

  getCustomLoanGroups(e) {
    this.selectedLoans = e;
  }

  selectedLoanStatus(e) {
    this.selectedStatus = e;
  }

  onSubmit() {
    this.btnSpinner = true;
    this.btnShowText = false;
    this.rowData = [];
    this.loanGroupDataSubscription = this.apiService.getCurrentAccInfo(this.dateSelected, this.selectedStatus, this.selectedLoans).subscribe((res) => {
      this.CurrentAccountInfo_Report = res;
      console.log(res)
      this.gridApi.setRowData(this.CurrentAccountInfo_Report);
      this.rowData = this.CurrentAccountInfo_Report;
      this.btnSpinner = false;
      this.btnShowText = true;

    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setRowData(this.CurrentAccountInfo_Report);
    this.rowData = this.CurrentAccountInfo_Report;
  }

  ngOnDestroy() {
    this.loanGroupDataSubscription?.unsubscribe();
  }
}


