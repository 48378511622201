<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img
				title="refresh"
				src="/assets/images/refresh.png"
				width="30"
				height="30"
				(click)="refresh()"
			/>
		</h5>
	</div>
	<div class="row row-cols-12">
		<div class="col-12">
			<div class="cardContainer">
				<div class="cardContainer-scroll">
					<div class="card">
						<div class="container">
							<h4><strong>Underwriting Exception</strong></h4>
							<h2>
								<strong>{{ totalException }}</strong>
							</h2>
							<h2>
								<strong
									>{{ totalExceptionAmount | currency }} ({{
										(
											(totalExceptionAmount / totalNLSfundedMonth) *
											100
										).toFixed()
									}}%)
								</strong>
							</h2>
						</div>
					</div>
					<div class="card">
						<div class="container">
							<h4><strong>Mangager Override and Agency Incentive</strong></h4>
							<h2>
								<strong>{{ +countMO + +countIncentive }}</strong>
							</h2>
							<h2>
								<strong
									>{{ +amountMO + +amountIncentive | currency }} ({{
										(
											((+amountMO + +amountIncentive) / totalNLSfundedMonth) *
											100
										).toFixed()
									}}%)</strong
								>
							</h2>
						</div>
					</div>
					<div class="card">
						<div class="container">
							<h4><strong>HQQAA</strong></h4>
							<h2>
								<strong>{{ countHQQAA }}</strong>
							</h2>
							<h2>
								<strong
									>{{ amountHQQAA | currency }} ({{
										((amountHQQAA / totalNLSfundedMonth) * 100).toFixed()
									}}%)</strong
								>
							</h2>
						</div>
					</div>
					<div class="card">
						<div class="container">
							<h4><strong>Approved Amount Increase</strong></h4>
							<h2>
								<strong>{{ countApproved }}</strong>
							</h2>
							<h2>
								<strong
									>{{ amountApproved | currency }} ({{
										((amountApproved / totalNLSfundedMonth) * 100).toFixed()
									}}%)</strong
								>
							</h2>
						</div>
					</div>
					<div class="card">
						<div class="container">
							<h4><strong>Pending-Manager Approval</strong></h4>
							<h2>
								<strong>{{ totalPMA }}</strong>
							</h2>
							<h2>
								<strong>{{ totalPMAAmount | currency }}</strong>
							</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row row-cols-12">
		<div class="col-4">
			<h5>Underwriting Agency Exception</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Agency</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<div
						*ngIf="
							!exceptionArray || exceptionArray.length === 0;
							else contentGA
						"
					>
						No Underwriting Exception!
					</div>
					<ng-template #contentGA>
						<tr *ngFor="let item of groupAgencyArray">
							<td>
								<a
									class="noline"
									target="_self"
									[routerLink]="['/underwritingagencyexception']"
									[queryParams]="{ agency: item.name }"
									>{{ item.name }}</a
								>
							</td>
							<td>{{ item.count }}</td>
							<td>{{ item.amount | currency }}</td>
						</tr>
						<tr>
							<td class="subTableBold">TOTAL</td>
							<td class="subTableBold">{{ countGroupAgency }}</td>
							<td class="subTableBold">{{ amountGroupAgency | currency }}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>
		<div class="col-4">
			<h5>Underwriting Override Type Exception</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Override Type</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<div
						*ngIf="
							!exceptionArray || exceptionArray.length === 0;
							else contentORT
						"
					>
						No Underwriting Exception!
					</div>
					<ng-template #contentORT>
						<tr *ngFor="let item of groupOverrideTypeArray">
							<td>{{ item.name }}</td>
							<td>{{ item.count }}</td>
							<td>{{ item.amount | currency }}</td>
						</tr>
						<tr>
							<td class="subTableBold">TOTAL</td>
							<td class="subTableBold">{{ countGroupOverrideType }}</td>
							<td class="subTableBold">
								{{ amountGroupOverrideType | currency }}
							</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>

		<div class="col-4">
			<h5>Underwriting Override Exception</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Override</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<div
						*ngIf="
							!exceptionArray || exceptionArray.length === 0;
							else contentOR
						"
					>
						No Underwriting Exception!
					</div>
					<ng-template #contentOR>
						<tr *ngFor="let item of groupOverrideArray">
							<td>{{ item.name }}</td>
							<td>{{ item.count }}</td>
							<td>{{ item.amount | currency }}</td>
						</tr>
						<tr>
							<td class="subTableBold">TOTAL</td>
							<td class="subTableBold">{{ countGroupOverrideType }}</td>
							<td class="subTableBold">
								{{ amountGroupOverrideType | currency }}
							</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
			<h5>Hamza</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Override Type</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<div
						*ngIf="
							!exceptionArray || exceptionArray.length === 0;
							else contentHamza
						"
					>
						No Underwriting Exception!
					</div>
					<ng-template #contentHamza>
						<tr *ngFor="let item of groupOverrideArrayHamza">
							<td>{{ item.name }}</td>
							<td>{{ item.count }}</td>
							<td>{{ item.amount | currency }}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
			<h5>Hari</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Override Type</th>
						<th>Count</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<div
						*ngIf="
							!exceptionArray || exceptionArray.length === 0;
							else contentHari
						"
					>
						No Underwriting Exception!
					</div>
					<ng-template #contentHari>
						<tr *ngFor="let item of groupOverrideArrayHari">
							<td>{{ item.name }}</td>
							<td>{{ item.count }}</td>
							<td>{{ item.amount | currency }}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>
	</div>

	<div class="row row-cols-12">
		<div class="cols-12">
			<h5>Underwriting Exception</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>App. Name</th>
						<th>Creditor</th>
						<th>Agency</th>
						<th>Approved</th>
						<th>Override</th>
						<th>Override Type</th>
						<th>Exception</th>
						<th>Closing</th>
					</tr>
				</thead>
				<tbody>
					<div
						*ngIf="
							!exceptionArray || exceptionArray.length === 0;
							else contentEx
						"
					>
						No Underwriting Exception!
					</div>
					<ng-template #contentEx>
						<tr
							*ngFor="
								let item of exceptionArray
									| paginate
										: {
												id: 'paginateEx',
												itemsPerPage: countEx,
												currentPage: pEx
										  }
							"
						>
							<td>
								<a
									class="noline"
									target="_self"
									[routerLink]="['/applications/summary', item.id]"
									[queryParams]="{ dealname: item.Deal_Name }"
									>{{ item.Deal_Name }}</a
								>
							</td>
							<td>{{ item.Creditt }}</td>
							<td>{{ item.Agency_Formula }}</td>
							<td>{{ item.Approved_Amount | currency }}</td>
							<td>{{ item.Underwriting_Override }}</td>
							<td>{{ item.Override_Type }}</td>
							<td>{{ item.Exception_Funded_Amount | currency }}</td>
							<td>{{ item.Closing_Date }}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
			<div id="paginate" class="text-right">
				<pagination-controls
					id="paginateEx"
					(pageChange)="pEx = $event"
				></pagination-controls>
			</div>
		</div>
	</div>
	<div class="row row-cols-12">
		<div class="col-6">
			<h5>Pending-Manager Approval</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Name</th>
						<th>Agency</th>
						<th>Created</th>
					</tr>
				</thead>
				<tbody>
					<div *ngIf="!pmaArray || pmaArray.length === 0; else contentFR">
						No Pending-Manager Approval!
					</div>
					<ng-template #contentFR>
						<tr
							*ngFor="
								let item of pmaArray
									| paginate : { itemsPerPage: count, currentPage: p }
							"
						>
							<td>
								<a
									class="noline"
									target="_self"
									[routerLink]="['/applications/summary', item.id]"
									[queryParams]="{ dealname: item.Deal_Name }"
									>{{ item.Deal_Name }}</a
								>
							</td>
							<td>{{ item.Agency_Formula }}</td>
							<td>{{ item.Created_Time | date : 'medium' }}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
			<div id="paginate" class="text-right">
				<pagination-controls (pageChange)="p = $event"></pagination-controls>
			</div>
		</div>
		<div class="col-6">
			<h5>Underwriting Queue</h5>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Name</th>
						<th>Agency</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="
							let item of underwritingArray
								| paginate
									: { id: 'paginateQ', itemsPerPage: countQ, currentPage: pQ }
						"
					>
						<td>{{ item.Deal_Name }}</td>
						<td>{{ item.Agency_Formula }}</td>
						<td>{{ item.Amount | currency }}</td>
					</tr>
				</tbody>
			</table>
			<div id="paginate" class="text-right">
				<pagination-controls
					id="paginateQ"
					(pageChange)="pQ = $event"
				></pagination-controls>
			</div>
		</div>
	</div>
</div>
