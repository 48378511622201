import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
class credit_report_input {
	constructor(
		public application_id,
		public loan_number,
		public first_name: string,
		public middle_name: string,
		public last_name: string,
		public dob: string,
		public street: string,
		public province: string,
		public city: string,
		public postal_code: string,
		public telephone: string,
		public sin: string,
		public application_type: string,
		public pulled_by,
		public email,
		public loan_amount: number,
		public token: string
	) {}
}
@Component({
	selector: 'app-pre-approval',
	templateUrl: './pre-approval.component.html',
	styleUrls: ['./pre-approval.component.scss'],
})
export class PreApprovalComponent implements OnInit {
	model = new credit_report_input(
		'2390707000034288237',
		'8263231419',
		'MILO',
		'SEGMENTO',
		'INCLUSIVIO',
		'',
		'',
		'',
		'',
		'',
		'5193867831',
		'',
		'',
		10,
		'travis@lmcredit.com',
		2500,
		'17331'
	);
	tranunion_pulled_by;
	TU_Report;
	approval_status = false;
	createApplicationFlag = true;
	approvedPageFlag = false;
	declinedPageFlag = false;
	viewExistingApplicationFlag = false;
	application_id = '';
	Consent = 'No';

	constructor(private apiService: ApiServiceService) {}

	ngOnInit(): void {}
	async onSubmit() {
		console.log('Input Values');
		console.log(this.model);
		if (this.Consent == 'Yes') {
			if (await this.checkTransunionToken()) {
				this.apiService
					.getTransunionCreditReport(this.model)
					.subscribe((res) => {
						this.TU_Report = res;
						console.log('Transunion Credit Report:');
						console.log(this.TU_Report.REL4Report.TU_FFR_Report[0]);
						if (this.isHit(this.TU_Report)) {
							this.apiService
								.preapprove(
									this.model,
									this.TU_Report.REL4Report.TU_FFR_Report[0]
								)
								.subscribe(async (res) => {
									console.log(res);
									alert(res['message']);
									this.approval_status = res['message'];
									//We can now create the application on ZOHO
									let application_creation_status =
										await this.createZOHOApplication(
											this.model,
											this.TU_Report
										);
									try {
										application_creation_status = JSON.parse(
											application_creation_status['message']
										);
										console.log(application_creation_status);
										if (
											application_creation_status['data'][0]['code'] ==
											'SUCCESS'
										)
											alert('Application Created');
										else alert('Application Creation Failed');
									} catch (err) {
										console.log(err);
										alert('Error Creating Application on ZOHO');
									}
									if (this.approval_status) this.showApprovedPage();
									else this.showDeclinedPage();
								});
						} else {
							alert('No Hit was found');
						}
					});
			}
		} else {
			alert('You Need Consent to Pull Credit for this Client');
		}
	}
	isHit(TU_Report) {
		try {
			if (TU_Report.REL4Report.TU_FFR_Report[0].Hit[0] == 'Y') return true;
			else return false;
		} catch (err) {
			return false;
		}
	}
	async checkTransunionToken() {
		return new Promise((resolve, reject) => {
			if (this.model.token == null || this.model.token == '') {
				resolve(false);
			} else {
				this.apiService
					.checkTransunionToken(this.model.token)
					.subscribe((res) => {
						if (!res['status']) {
							alert('Syntax Error');
							resolve(false);
						} else if (res['data'].length == 0) {
							alert('Invalid Token');
							resolve(false);
						} else {
							this.model['pulled_by'] = res['data'][0]['emp_id'];
							this.tranunion_pulled_by = res['data'][0]['emp_id'];
							resolve(true);
						}
					});
			}
		});
	}
	async createZOHOApplication(model, TU_Report) {
		return new Promise((resolve, reject) => {
			this.apiService
				.createZohoApplication(
					model,
					TU_Report.REL4Report.TU_FFR_Report[0],
					this.approval_status
				)
				.subscribe((res) => {
					resolve(res);
				});
		});
	}
	async generateHash() {
		if (
			confirm('Are you sure you want to send a Consent Form to this Client?')
		) {
			return new Promise((resolve, reject) => {
				// let client_obj = {
				//   first_name: this.model.first_name,
				//   last_name: this.model.last_name,
				//   email: this.model.email,
				//   phone: this.model.telephone,
				//   loan_amount: this.model.loan_amount
				// }
				this.apiService.generateHash(this.model.email).subscribe((res) => {
					console.log('This is the HASH');
					console.log(res['message']);
				});
			});
		}
	}
	createNewApplicationPage() {
		this.createApplicationFlag = true;
		this.viewExistingApplicationFlag = false;
	}
	viewExistingApplication() {
		this.createApplicationFlag = false;
		this.viewExistingApplicationFlag = true;
	}

	sendConsentFormEmailClient(hash, model) {
		let url = 'https://betty-alpha.web.app/transunion-consent-form?id=';
		url = url.concat(hash);
		this.apiService
			.sendConsentFormEmailClient(model, url)
			.subscribe(async (res) => {
				console.log(res);
			});
	}
	createEntryInDB() {}
	showApprovedPage() {
		this.createApplicationFlag = false;
		this.approvedPageFlag = true;
		this.declinedPageFlag = false;
		this.viewExistingApplicationFlag = false;
	}
	showDeclinedPage() {
		this.createApplicationFlag = false;
		this.approvedPageFlag = false;
		this.declinedPageFlag = true;
		this.viewExistingApplicationFlag = false;
	}
}
