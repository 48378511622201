import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';
import { Sort } from '@angular/material/sort';

export interface Head {
	Agency: string;
	Original_Loan_Amount: number;
	Original_Loan_Amount_Count: number;
	Current_Balance: number;
	Principal_recovered: number;
	Delinqency_Amount: number;
	Delinqency_Rate: number;
	Default_Amount: number;
	Default_Rate: number;
}

@Component({
	selector: 'app-nlsagencycollections',
	templateUrl: './nlsagencycollections.component.html',
	styleUrls: ['./nlsagencycollections.component.scss'],
})
export class NlsagencycollectionsComponent implements OnInit {
	groupAgencyArray: Head[] = [];
	sortedData: any;

	nlsRaw: any;

	total_Original_Loan_Amount: any;
	total_Original_Loan_Amount_Count: any;
	total_Current_Balance: any;
	total_Principal_recovered: any;
	total_Delinqency_Amount: any;
	total_Default_Amount: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private router: ActivatedRoute,
		private route: Router
	) {
		// this.sortedData = this.groupAgencyArray.slice();
	}

	ngOnInit(): void {
		// Login Session
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchAgency(); // Fetch the display data
					} else {
						this.route.navigate(['login']);
					}
				});
		} else {
			this.route.navigate(['login']);
		}
	}

	/**
	 * Retrieve the whole overall funded query
	 * Group By Agency
	 * Get the Delinquency and Default
	 */
	fetchAgency() {
		this.apiService.getnlsoverallfunded().subscribe((res) => {
			if (res.message === 'Success') {
				this.nlsRaw = res.data;

				this.total_Original_Loan_Amount = this.customFunction.sumNumbers(
					this.nlsRaw,
					'Original_Loan_Amount'
				);
				this.total_Original_Loan_Amount_Count = this.customFunction.sumNumbers(
					this.nlsRaw,
					'Original_Loan_Amount_Count'
				);
				this.total_Current_Balance = this.customFunction.sumNumbers(
					this.nlsRaw,
					'Current_Balance'
				);
				this.total_Principal_recovered = this.customFunction.sumNumbers(
					this.nlsRaw,
					'Principal_recovered'
				);
				this.total_Delinqency_Amount = this.customFunction.sumNumbers(
					this.nlsRaw,
					'Delinqency_Amount'
				);
				this.total_Default_Amount = this.customFunction.sumNumbers(
					this.nlsRaw,
					'Default_Amount'
				);

				this.sortRaw({ active: 'Original_Loan_Amount', direction: 'desc' });
			}
		});
	}

	sortRaw(sort: Sort) {
		this.sortedData = this.nlsRaw.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'Agency':
					return this.customFunction.compare(a.Agency, b.Agency, isAsc);
				case 'Original_Loan_Amount':
					return this.customFunction.compare(
						a.Original_Loan_Amount,
						b.Original_Loan_Amount,
						isAsc
					);
				case 'Original_Loan_Amount_Count':
					return this.customFunction.compare(
						a.Original_Loan_Amount_Count,
						b.Original_Loan_Amount_Count,
						isAsc
					);
				case 'Current_Balance':
					return this.customFunction.compare(
						a.Current_Balance,
						b.Current_Balance,
						isAsc
					);
				case 'Principal_recovered':
					return this.customFunction.compare(
						a.Principal_recovered,
						b.Principal_recovered,
						isAsc
					);
				case 'Delinqency_Amount':
					return this.customFunction.compare(
						a.Delinqency_Amount,
						b.Delinqency_Amount,
						isAsc
					);
				case 'Delinqency_Rate':
					return this.customFunction.compare(
						a.Delinqency_Rate,
						b.Delinqency_Rate,
						isAsc
					);
				case 'Default_Amount':
					return this.customFunction.compare(
						a.Default_Amount,
						b.Default_Amount,
						isAsc
					);
				case 'Default_Rate':
					return this.customFunction.compare(
						a.Default_Rate,
						b.Default_Rate,
						isAsc
					);
				default:
					return 0;
			}
		});
	}
}
