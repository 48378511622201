<div class="modelPadding">
	<div *ngIf="bankStatement">
		<div style="margin-left: 75%"></div>
		<h3>
			Which one of the following bank accounts is used for EMPLOYER INCOME?
		</h3>
		<mat-tab-group
			[selectedIndex]="selected.value"
			(selectedIndexChange)="selected.setValue($event)"
		>
			<mat-tab
				*ngFor="let acc of accounts; let index = index"
				[label]="account_labels[index]"
			>
				<table>
					<tr>
						<th style="font-size: large">Customer</th>
						<th style="font-size: large">Detected Account</th>
					</tr>
					<tr>
						<td><b>Reference:</b> {{ bankStatement.referenceid }}</td>
						<td><b>Bank:</b> {{ acc.bank }}</td>
					</tr>
					<tr>
						<td><b>Name:</b> {{ bankStatement.name }}</td>
						<td><b>Institution:</b> {{ acc.institution }}</td>
					</tr>
					<tr>
						<td><b>Request Guid:</b> {{ bankStatement.request }}</td>
						<td><b>Transit:</b> {{ acc.transit }}</td>
					</tr>
					<tr>
						<td><b>Address:</b> {{ bankStatement.address }}</td>
						<td><b>Account:</b> {{ acc.account }}</td>
					</tr>
					<tr>
						<td></td>
						<td><b>Available Funds:</b> {{ acc.available_balance }}</td>
					</tr>
				</table>
				<mat-tab-group>
					<mat-tab label="Employer Income">
						<table *ngIf="hasProperties(acc.transactions)">
							<tr>
								<th style="font-size: large">Date</th>
								<th style="font-size: large">Description</th>
								<th style="font-size: large">Credit</th>
							</tr>
							<tr *ngFor="let pays of acc.transactions">
								<td *ngIf="pays.category === 'income/paycheck'">
									{{ pays.date }}
								</td>
								<td *ngIf="pays.category === 'income/paycheck'">
									{{ pays.details }}
								</td>
								<td *ngIf="pays.category === 'income/paycheck'">
									{{ pays.credit }}
								</td>
							</tr>
						</table>
					</mat-tab>
				</mat-tab-group>
				<br />
				<button class="button" (click)="updateZohoApplication_Inverite(acc)">
					Save Income
				</button>
				<button class="buttonRefresh" (click)="doNotUploadZoho()">Close</button>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
