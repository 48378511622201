<span style="padding: 15px;"><strong>Loan Servicing</strong> | Reports/Sanity Checks</span>
<mat-tab-group style="height:  calc(100% - 30px);">
    <mat-tab label="ACH Checks">
        <app-ach-checks></app-ach-checks>
    </mat-tab>
    <mat-tab label="ACH Validation">
        <app-ach-validation></app-ach-validation>
    </mat-tab>
    <mat-tab label="Balloon Payments">
        <app-balloon-payments></app-balloon-payments>
    </mat-tab>
    <mat-tab label="Daily Recon">
        <app-daily-recon></app-daily-recon>
    </mat-tab>
    <mat-tab label="Expired ACH">
        <app-expired-ach></app-expired-ach>
    </mat-tab>
    <mat-tab label="Zero Balance">
        <app-zero-balance></app-zero-balance>
    </mat-tab>
</mat-tab-group>