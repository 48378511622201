import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';
import { ExcelService } from '@lmc-app/services/excel.service';
import { currencyFormatterAgGrid } from '@lmc-app/utils/numbers';
import { GeneralObjects } from '@lmc-app/models/globalObjects';

@Component({
  selector: 'app-arrangement-date-based',
  templateUrl: './arrangement-date-based.component.html',
  styleUrls: ['./arrangement-date-based.component.scss']
})
export class ArrangementDateBasedComponent implements OnDestroy {
  arrangementDataSubscription: Subscription;
  arrangement_report: any;
  start_date: any;
  end_date: any;
  btnSpinner = false;
  btnShowText = true;
  arrangementDetailedList: any;
  arrangementLoanGroupList: any;
  sheetArray = [];    // Array for excel
  fileName = "Arrangement Report";

  selectedDateValue = 'PtP_Date';
  generalObjects = new GeneralObjects(); // To get the date options i.e PtP_date and created_at

  //_Hist
  public rowClassRules = { 'footer-row': (params) => params.node.footer === true };   // To style the footer total 
  defaultColDef_Arr: any;
  autoGroupColumnDef: any;
  public columnDefs_Arr = [];
  public rowData_Arr;
  public gridApi_Arr;
  public gridColumnApi_Arr;
  public gridOptions_Arr;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe, private excelService: ExcelService) {
    //Payment History
    this.columnDefs_Arr = [
      { headerName: 'Created By', field: 'created_by', sortable: true, filter: 'agTextColumnFilter', minWidth: 250 },
      {
        headerName: 'Total ARR Paid', field: 'Total PTP Paid ($)', sortable: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total ARR Paid Count', field: 'Total PTP Paid Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'Total ARR NSF', field: 'Total PTP NSF ($)', sortable: true, filter: "agNumberColumnFilter", valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total ARR NSF Count', field: 'Total PTP NSF Count', sortable: true, filter: "agNumberColumnFilter", aggFunc: 'sum' },
      {
        headerName: 'Total ARR Broken', field: 'Total PTP Broken ($)', sortable: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total ARR Broken Count	', field: 'Total PTP Broken Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'Total ARR Requested/Arranged', field: 'Total PTP Requested_Arranged ($)', sortable: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total ARR Requested/Arranged Count	', field: 'Total PTP Requested_Arranged Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'All ARRs', field: 'All PTPs ($)', sortable: true, filter: "agNumberColumnFilter", valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'All ARRs Count', field: 'All PTPs Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'ARR Conversion Rate', field: 'PTP Conversion Rate', sortable: true, filter: 'agNumberColumnFilter',
      },
    ]
    this.defaultColDef_Arr = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'arrangement_report_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'arrangement_report_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  onGridReady_Arr(params) {
    this.gridApi_Arr = params.api;
    this.gridColumnApi_Arr = params.columnApi;
    this.gridApi_Arr.setRowData(this.arrangement_report);
    this.rowData_Arr = this.arrangement_report

  }

  startDate(e) {
    this.start_date = e;
  }

  endDate(e) {
    this.end_date = e;
  }

  selectedDateOption(e) {
    this.selectedDateValue = e;
  }

  downloadArrangement() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else {
      this.btnSpinner = true;
      this.btnShowText = false;
      this.arrangement_report = null;
      this.rowData_Arr = [];
      this.arrangementDataSubscription = this.apiService.getPtpReport(this.start_date, this.end_date, 'ALL', 'arrangement', this.selectedDateValue).subscribe((res) => {
        this.arrangement_report = res?.['data'];
        this.sheetArray = [];
        this.sheetArray.push(this.arrangement_report);
        console.log(this.arrangement_report)
        this.gridApi_Arr.setRowData(this.arrangement_report);
        this.rowData_Arr = this.arrangement_report;
        this.btnSpinner = false;
        this.btnShowText = true;

        this.getArrangementDetailedReport(this.start_date, this.end_date, 'arrangement', this.selectedDateValue);
        this.getArrangementLoanGroupReport(this.start_date, this.end_date, 'arrangement', this.selectedDateValue);
      })
    }
  }

  getArrangementDetailedReport(startDate?: string, endDate?: string, ptpType?: string, dateOption?: string) {
    this.apiService
      .getPtpDetailedReport(startDate, endDate, ptpType, dateOption)
      .subscribe((res: any) => {
        console.log(res)
        this.arrangementDetailedList = res?.data;
        this.sheetArray.push(this.arrangementDetailedList);
      });
  }

  getArrangementLoanGroupReport(startDate?: string, endDate?: string, ptpType?: string, dateOption?: string) {
    this.apiService
      .getPtpLoanGroupReport(startDate, endDate, ptpType, dateOption)
      .subscribe((res: any) => {
        console.log(res)
        this.arrangementLoanGroupList = res?.data;
        this.sheetArray.push(this.arrangementLoanGroupList);
      });
  }

  exportAsXLSX() {
    this.excelService?.exportToExcel(this.sheetArray, "Arrangement Report");
  }

  ngOnDestroy() {
    this.arrangementDataSubscription?.unsubscribe();
  }

}
