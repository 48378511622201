import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-transunion-consent-form',
  templateUrl: './transunion-consent-form.component.html',
  styleUrls: ['./transunion-consent-form.component.scss']
})
export class TransunionConsentFormComponent implements OnInit {
  testing_url = "https://betty-alpha.web.app/transunion-consent-form?City=&id=$2b$10$ytabIKzTD9p6KYcSbdOQ8e2gzKPn5XIV91XEqSHkmR3D7IPSgFDie"
  hash = ''
  isAccepted = false
  model
  today_date: any
  constructor(private apiService: ApiServiceService, public datepipe: DatePipe) {
    this.today_date = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    let URI = window.location.href
    let decoded = decodeURI(URI)//URI//testing_url/
    const urlParams = new URLSearchParams(decoded);
    this.hash = urlParams.get("id")
    console.log("THE HASH")
    console.log(this.hash)
    this.getPreapprovalClientInfo()
  }
  getPreapprovalClientInfo() {
    this.apiService.getPreapprovalClientInfo(this.hash).subscribe((res) => {
      console.log(res)
      if (res['status']) {
        if (res["data"].length > 0)
          this.model = res['data'][0]
        else
          alert("Could Not Find Client Information")
      }
      else {
        alert('Error Getting Client Information')
      }
    })
  }
  onSubmit() {
    if (this.isAccepted) {
      this.apiService.updatePreapprovalConsent(this.hash, this.model).subscribe((res) => {
        console.log(res)
        if (res["status"]) {
          alert("Your Consent has been recieved")
        }
        else {
          alert("Error Saving Consent")
        }
      })
    }
    else {
      alert("Please check the box before Submitting")
    }
  }

}
