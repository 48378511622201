<div class="custom-tooltip" [style.background-color]="color">
	<p>
		<span>{{ data?.Deal_Name }}</span>
		<span>{{ data?.Application_Number }}</span>
		<span>{{ data?.Stage }}</span>
		<span>{{ data?.Agency_Formula }}</span>
		<span>{{ data?.Collector_Formula }}</span>
		<span>{{ data?.Amount }}</span>
	</p>
</div>
