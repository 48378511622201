<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card>
                <mat-card-header class="mat-card_style">
                    <mat-card-title>Total Files in Collection</mat-card-title>
                    <mat-card-subtitle>
                        <h2>{{totalAssignedFiles}} | {{totalAssignedAmount | currency}}</h2>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="totalAssignedFiles !==0">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th>Collector</th>
                                <th style="text-align: center">Count</th>
                                <th>Total Past Due</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filesAssigned">
                                <td>{{item?.collector_name}}</td>
                                <td style="text-align: center">{{item?.['assigned_count']}}</td>
                                <td>{{item?.total_past_due_balance | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card>
                <mat-card-header class="mat-card_style">
                    <mat-card-title>Promise to Pays Today</mat-card-title>
                    <mat-card-subtitle>
                        <h2>{{totalPTPCreated}} | {{totalPTPCreatedAmount | currency}}</h2>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf=" totalPTPCreated !==0">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th>Collector</th>
                                <th style="text-align: center">Count</th>
                                <th>Total($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ptpData">
                                <td>{{item?.created_by}}</td>
                                <td style="text-align: center">{{item?.['ptp_count']}}</td>
                                <td>{{item?.payment_amount | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card>
                <mat-card-header class="mat-card_style">
                    <mat-card-title>Arrangements Today</mat-card-title>
                    <mat-card-subtitle>
                        <h2>{{totalArrangementCreated}} | {{totalArrangementCreatedAmount | currency}}</h2>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="totalArrangementCreated !==0">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th>Collector</th>
                                <th style="text-align: center">Count</th>
                                <th>Total($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrangementData">
                                <td>{{item?.created_by}}</td>
                                <td style="text-align: center">{{item?.['arrangement_count']}}</td>
                                <td>{{item?.payment_amount | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card>
                <mat-card-header class="mat-card_style">
                    <mat-card-title>Notes created Today</mat-card-title>
                    <mat-card-subtitle>
                        <h2>{{totalFilesCreated}} | {{totalFilesNotCreated}}</h2>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="filesNotCreatedData !==0">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th>Collector</th>
                                <th style="text-align: center">Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filesNotCreatedData">
                                <td>{{item?.assigned_collector}}</td>
                                <td style="text-align: center">{{item?.['countCreated']}} |
                                    {{item?.['countNotCreated']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row" style="padding-top: 10px;">
        <div class="col-3">
            <mat-card>
                <mat-card-header class="mat-card_style">
                    <mat-card-title>Total NSF in last 14 days</mat-card-title>
                    <mat-card-subtitle>
                        <h2>{{totalNSFfiles}} | {{totalPTP14Days}}</h2>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="totalNSFfiles !==0">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th>Collector</th>
                                <th style="text-align: center">NSF Count</th>
                                <th style="text-align: center">Total PTP Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filesNSF">
                                <td>{{item?.assigned_collector}}</td>
                                <td style="text-align: center">{{item?.['nsf_count']}}</td>
                                <td style="text-align: center">{{item?.['ptp_count']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card>
                <mat-card-header class="mat-card_style">
                    <mat-card-title>New Files Today</mat-card-title>
                    <mat-card-subtitle>
                        <h2>{{totalNewCollectionFiles}} | {{totalLoanProfilePastAmount | currency}} |
                            {{totalLoanProfileCurrentAmount | currency}}</h2>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="totalNewCollectionFiles !==0">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th>Agency</th>
                                <th style="text-align: center">Count</th>
                                <th>Arrears</th>
                                <th>Principal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of newCollectionFiles">
                                <td>{{item?.loanProfileName}}</td>
                                <td style="text-align: center">{{item?.['loanProfileCount']}}</td>
                                <td>{{item?.['loanProfilePastAmount'] | currency}}</td>
                                <td>{{item?.['loanProfileCurrentAmount'] | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</div>