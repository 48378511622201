import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';
import { sumNumbers } from '@lmc-app/utils/filter-functions';

@Component({
  selector: 'app-daily-recon',
  templateUrl: './daily-recon.component.html',
  styleUrls: ['./daily-recon.component.scss']
})
export class DailyReconComponent implements OnDestroy {
  Daily_Recon: any;
  dailyReconDataSubscription: Subscription;

  selectedStatus: any;
  start_date: any;
  end_date: any;
  Display_Funded_Amount = 0;
  total_saha_law = 0;
  total_agency_referral = 0;
  selectedLoans: any;
  btnSpinner = false;
  btnShowText = true;

  //_Hist
  getRowStyle_Hist: any;
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public gridOptions_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    //Payment History
    this.columnDefs_Hist = [
      { headerName: 'Application Name', field: 'Application Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'App Number', field: 'App Number', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Funded Amount', field: 'Funded Amount', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Saha Law', field: 'Saha Law', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Agency Referral', field: 'Agency Referral', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Closing Date', field: 'Closing Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Collector Name', field: 'Collector Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Agency Name', field: 'Agency Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Creditor', field: 'Creditor', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Creditor Details', field: 'Creditor Details', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Creditor Portfolio', field: 'Creditor Portfolio', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Stage', field: 'Stage', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Application Owner', field: 'Application Owner', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Loan Group', field: 'Loan Group', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Status Code', field: 'Status Code', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Open Date', field: 'Open Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Setup Date', field: 'Setup Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'hasLPP', field: 'hasLPP', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Insurance Premium', field: 'Insurance Premium', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Ins tax', field: 'Ins tax', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Age', field: 'Age', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'DOB', field: 'DOB', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Agreement Created By', field: 'Agreement Created By', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Sales Agent', field: 'Sales Agent', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Refinanced Amount', field: 'Refinanced Amount', sortable: true, filter: "agNumberColumnFilter" }
    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'daily_recon_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'daily_recon_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  getReportType(e) {
    this.selectedLoans = e?.['data'];
  }

  getCustomLoanGroups(e) {
    this.selectedLoans = e;
  }

  selectedLoanStatus(e) {
    this.selectedStatus = e;
  }

  startDate(e) {
    this.start_date = e;
  }

  endDate(e) {
    this.end_date = e;
  }

  downloadDailyReconReport() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else if (this.selectedLoans.length == 0)
      alert("At least one Loan Group should be selected")
    else {
      console.log(this.selectedLoans, "**********************")
      this.btnSpinner = true;
      this.btnShowText = false;
      this.Daily_Recon = null;
      this.rowData_Hist = [];
      this.Display_Funded_Amount = 0;
      this.dailyReconDataSubscription = this.apiService.getDailyReconReport(this.start_date, this.end_date, this.selectedStatus, this.selectedLoans).subscribe((res) => {
        console.log(res);
        this.Daily_Recon = res;
        this.Display_Funded_Amount = sumNumbers(this.Daily_Recon, 'Funded Amount')?.toFixed(2);
        this.total_saha_law = sumNumbers(this.Daily_Recon, 'Saha Law')?.toFixed(2);
        this.total_agency_referral = sumNumbers(this.Daily_Recon, 'Agency Referral')?.toFixed(2);
        this.gridApi_Hist.setRowData(this.Daily_Recon);
        this.rowData_Hist = this.Daily_Recon;
        this.btnSpinner = false;
        this.btnShowText = true;
      })
    }
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.Daily_Recon);
    this.rowData_Hist = this.Daily_Recon;
  }

  ngOnDestroy() {
    this.dailyReconDataSubscription?.unsubscribe();
  }

}
