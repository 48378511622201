<ngx-extended-pdf-viewer
	[src]="pdfSrc"
	[useBrowserLocale]="true"
	[textLayer]="true"
	[showHandToolButton]="true"
	[showPresentationModeButton]="true"
	[showDrawEditor]="false"
	[showTextEditor]="false"
	[showOpenFileButton]="false"
	[showDownloadButton]="false"
>
</ngx-extended-pdf-viewer>
