import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// import { DatePipe } from '@angular/common';
// import { ApiServiceService } from '@lmc-app/api-service.service';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
// import { GeneralObjects } from '@lmc-app/models/globalObjects';

// interface DropDowns {
//   value: any;
//   viewValue: string;
// }

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
// export class CustomButtonComponent implements OnInit, AfterViewInit {
export class CustomButtonComponent {
  @Input() btnShowText?: boolean;           // Boolean to display Text
  @Input() btnText?: string = '';           // Custom Text
  @Input() btnSpinner?: boolean;            // Button Spinner flag
  @Input() btnColor?: string = '';          // Button Color
  @Input() btnValidation?: string = '';     // Button Validation based on dates
  // @Input() showDateRange?: boolean;         // Display Date Range flag
  // @Input() showReportDropDown?: boolean;    // Display Report Loan Group dropdown flag   
  // @Input() showLoanStatus?: boolean;        // Display Loan Status Dropdown flag
  // @Input() showBreakdowns?: boolean;        // Display Breakdown Dropdown flag
  // @Input() showCurrentDate?: boolean;       // Display current date flag

  @Output() onBtnClick = new EventEmitter<any>();         // Trigger on button click
  // @Output() start_date = new EventEmitter<any>();         // Trigger start date range
  // @Output() end_date = new EventEmitter<any>();           // Trigger end date range      
  // @Output() reportType = new EventEmitter<any>();         // Trigger loan group drop down
  // @Output() customLoanGroups = new EventEmitter<any>();   // Trigger custom loan groups
  // @Output() loanStatus = new EventEmitter<any>();         // Trigger loan status dropdowns
  // @Output() breakdownType = new EventEmitter<any>();      // Trigger breakdown dropdown
  // @Output() current_date = new EventEmitter<any>();       // Trigger current date 

  // selectedReport: any;

  // dateFormRange: FormGroup;
  // dateForm: FormGroup;

  // reports: DropDowns[] = [
  //   { value: 'All', viewValue: 'All' },
  //   { value: 'SPV', viewValue: 'SPV' },
  //   { value: 'MFT', viewValue: 'MFT' },
  //   { value: 'Debenture', viewValue: 'Debenture' },
  //   { value: 'QVEST', viewValue: 'QVEST' },
  //   { value: 'Custom', viewValue: 'Custom' },
  // ];

  // status = [
  //   { id: 0, name: 'Open' },
  //   { id: 1, name: 'Closed' },
  //   { id: 2, name: 'All Statuses' }
  // ];

  // breakdown_report_type = [
  //   { id: 0, name: 'Partial Breakdown' },
  //   { id: 1, name: 'Full Breakdown' },
  // ];

  // selectedLoans = [];
  // selectedLoanGroups = [];
  // loanGroups: any;
  // dropdownList = [];
  // dropdownSettings: IDropdownSettings;
  // selectedStatus = 0;
  // selectedBreakdown = 0;

  // generalObjects = new GeneralObjects(); // To get the loan group no and loan group name
  // debenture_loans: any;
  // mft_loans: any;
  // qvest_loans: any;
  // spv_loans: any;

  // constructor(private apiService: ApiServiceService, private datepipe: DatePipe) { }

  // async ngAfterViewInit(): Promise<void> {
  //   if (this.showReportDropDown) {
  //     let result = await this.getSPVLoanNumbers().then(value => {
  //       this.generalObjects.loan_group_no.SPV = Object.values(value);   // Assign SPV loan numbers
  //     });

  //     this.apiService.getLoanGroups().subscribe((res) => {
  //       this.loanGroups = res;
  //       this.dropdownList = this.loanGroups;
  //       // console.log(this.dropdownList)
  //       this.dropdownSettings = {
  //         singleSelection: false,
  //         idField: 'loan_group_no',
  //         textField: 'loan_group',
  //         selectAllText: 'Select All',
  //         unSelectAllText: 'UnSelect All',
  //         itemsShowLimit: 1000,
  //         allowSearchFilter: true
  //       };
  //       this.debenture_loans = this.loanGroups.filter((item) =>
  //         this.generalObjects.loan_group_no['Debenture'].includes(
  //           item['loan_group_no']
  //         )
  //       );
  //       this.mft_loans = this.loanGroups.filter((item) =>
  //         this.generalObjects.loan_group_no['MFT'].includes(
  //           item['loan_group_no']
  //         )
  //       );
  //       this.qvest_loans = this.loanGroups.filter((item) =>
  //         this.generalObjects.loan_group_no['QVEST'].includes(
  //           item['loan_group_no']
  //         )
  //       );
  //       this.spv_loans = this.loanGroups.filter((item) =>
  //         this.generalObjects.loan_group_no['SPV'].includes(
  //           item['loan_group_no']
  //         )
  //       );
  //       this.reportType.emit({ portfolio: 'SPV', data: this.spv_loans });
  //     });
  //   }

  // };

  // ngOnInit(): void {
  //   this.selectedReport = 'SPV';

  //   this.dateFormRange = new FormGroup({
  //     startDate: new FormControl('', Validators.required),
  //     endDate: new FormControl('', Validators.required)
  //   });


  //   this.dateForm = new FormGroup({
  //     currentDate: new FormControl('', Validators.required)
  //   });
  // }

  onClick() {
    this.onBtnClick.emit();
  }

  // addStartEvent(event: MatDatepickerInputEvent<Date>) {
  //   this.start_date.emit(this.datepipe.transform(event.value, 'yyyy-MM-dd'));
  // }
  // addEndEvent(event: MatDatepickerInputEvent<Date>) {
  //   this.end_date.emit(this.datepipe.transform(event.value, 'yyyy-MM-dd'));
  // }

  // addCurrentDateEvent(event: MatDatepickerInputEvent<Date>) {
  //   this.current_date.emit(this.datepipe.transform(event.value, 'yyyy-MM-dd'));
  // }

  // selectedReportStyle(e) {
  //   this.selectedLoans = [];
  //   this.selectedLoanGroups = [];
  //   // this.reportType.emit(e);
  //   switch (e) {
  //     case 'All':
  //       this.reportType.emit({ portfolio: 'All', data: this.loanGroups });
  //       break;
  //     case 'SPV':
  //       this.reportType.emit({ portfolio: 'SPV', data: this.spv_loans });
  //       break;
  //     case 'MFT':
  //       this.reportType.emit({ portfolio: 'MFT', data: this.mft_loans });
  //       break;
  //     case 'QVEST':
  //       this.reportType.emit({ portfolio: 'QVEST', data: this.qvest_loans });
  //       break;
  //     case 'Debenture':
  //       this.reportType.emit({ portfolio: 'Debenture', data: this.debenture_loans });
  //       break;
  //     // case 'Custom':
  //     //   this.reportType.emit({ portfolio: 'Custom' });
  //     //   break;
  //     default:
  //     // code block
  //   };
  // }

  // onItemSelect(item: any) {
  //   // console.log(item)
  //   this.selectedLoanGroups.push(item);
  //   this.customLoanGroups.emit(this.selectedLoanGroups);
  // }
  // onSelectAll(items: any) {
  //   // console.log(items);
  //   this.customLoanGroups.emit(items);
  // }

  // selectedLoanStatus(e) {
  //   this.loanStatus.emit(e);
  // }

  // selectedBreakdownType(e) {
  //   this.breakdownType.emit(e);
  // }

  // async getSPVLoanNumbers() {
  //   return new Promise((resolve, reject) => {
  //     this.apiService.getSpvLoanNumbers().subscribe((res) => {
  //       resolve(res)
  //     })
  //   })
  // }
}
