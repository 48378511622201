<div>
	<!-- <h5>Amoritzation Schedule</h5> -->
	<ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-material" [columnDefs]="columnDefs"
		[defaultColDef]="defaultColDef" [rowData]="rowData_Amo" [getRowStyle]="getRowStyle_Amo"></ag-grid-angular>
	<h3>Grand Totals</h3>
	<div class="sum-info">
		<strong>Payment Amount : </strong>${{
		Total_PaymentAmount.toLocaleString('en-US')
		}}
	</div>
	<div class="sum-info">
		<strong>Interest Amount : </strong>${{
		Total_InterestAmount.toLocaleString('en-US')
		}}
	</div>
	<div class="sum-info">
		<strong>Principal Amount: </strong>${{
		Total_PrincipalAmount.toLocaleString('en-US')
		}}
	</div>
	<div class="sum-info">
		<strong>Other Amount : </strong>${{
		Total_OtherAmount.toLocaleString('en-US')
		}}
	</div>
</div>