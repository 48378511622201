<span style="display:flex;">
    <span style="padding: 20px 0 0 20px;">
        <mat-form-field class=" table-filter" appearance="outline" style="margin-right: 15px">
            <mat-label>Select Date Option</mat-label>
            <mat-select [(ngModel)]="selectedDateValue" (ngModelChange)="selectedDateOption($event)">
                <mat-option *ngFor="let item of generalObjects['dateOptions']" [value]="item.insertValue">
                    {{item.displayValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </span>
    <span>
        <app-custom-betty-report-filters [showReportDropDown]="false" [showLoanStatus]="false" [showBreakdowns]="false"
            [showCurrentDate]="false" [showDateRange]="true" (start_date)="startDate($event)"
            (end_date)="endDate($event)" [btnColor]="'primary'" [btnShowText]="btnShowText" [btnText]="'Submit'"
            [btnSpinner]="btnSpinner" (onBtnClick)="downloadArrangement()"></app-custom-betty-report-filters>
    </span>
    <span *ngIf="arrangement_report">
        <app-custom-export-excel [sheetArray]="sheetArray" [fileName]="fileName"></app-custom-export-excel>
    </span>
</span>

<div class="row" style="height: calc(100% - 175px); margin: 10px;">
    <app-custom-table class="ag-theme-material" [rowData]="rowData_Arr" [columnDefs]="columnDefs_Arr"
        [defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"
        [groupIncludeTotalFooter]="true" [rowClassRules]="rowClassRules" [hideGlobalFilter]="true" [animateRows]="true"
        [pagination]="true" [paginationPageSize]="100" (gridReady)="onGridReady_Arr($event)">
    </app-custom-table>
</div>