import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiServiceService } from 'app/api-service.service';
interface Payment_Type {
	value: string;
	viewValue: string;
}
interface Status_Type {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-edit-ptp-modal',
	templateUrl: './edit-ptp-modal.component.html',
	styleUrls: ['./edit-ptp-modal.component.scss'],
})
export class EditPtpModalComponent implements OnInit {
	userID;
	roleID;
	existing_PTP;
	date;
	amount;
	payment_type;
	PTP_info;
	changed_By;
	status;

	constructor(
		public dialogRef: MatDialogRef<EditPtpModalComponent>,
		private apiService: ApiServiceService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.PTP_info = this.data;
		this.changed_By = 'accountant'; // Todo: Remove hardcoding this
		this.status = this.PTP_info['status'];
		this.amount = this.PTP_info.payment_amount;
		this.date = this.PTP_info.PtP_Date;
		this.date = this.date.concat('T05:00:00.000Z');
		this.payment_type = this.PTP_info.payment_type;
	}
	p_type: Payment_Type[] = [
		{ value: 'manual_debit', viewValue: 'Manual Debit' },
		{ value: 'customer_deposit', viewValue: 'Customer Deposit' },
	];
	s_type: Status_Type[] = [
		{ value: 'Requested', viewValue: 'Requested' },
		{ value: 'Arranged', viewValue: 'Arranged' },
		{ value: 'Processed', viewValue: 'Processed' },
		{ value: 'Broken', viewValue: 'Broken' },
		{ value: 'NSF', viewValue: 'NSF' },
		{ value: 'Deleted', viewValue: 'Deleted' },
		{ value: 'Cancelled', viewValue: 'Cancelled' },
	];
	myFilter = (d: Date | null): boolean => {
		const day = (d || new Date()).getDay();
		return day !== 0 && day !== 6;
	};

	ngOnInit() {
		// this.userID = this.apiService.getSignInInfo()[0]['email'];
		// this.roleID = this.apiService.getSignInInfo()[0]['roleID'];
		const sessionId = localStorage.getItem('SESSIONID');
		if (sessionId) {
			this.apiService.verifysession(sessionId).subscribe((res: any) => {
				if (res.message === 'Success') {
					const userDetails = res.data?.[0];
					this.userID = userDetails?.['email'];
					this.roleID = userDetails?.['roleID'];
				}
			});
		}
		this.apiService
			.getLocalParentPTP(this.PTP_info.acctrefno)
			.subscribe((res) => {
				this.existing_PTP = res;
				console.log(this.existing_PTP);
			});
	}
	addWorkDays(startDate, days) {
		// Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
		var dow = startDate.getDay();
		var daysToAdd = parseInt(days);
		// If the current day is Sunday add one day
		if (dow == 0) daysToAdd++;
		// If the start date plus the additional days falls on or after the closest Saturday calculate weekends
		if (dow + daysToAdd >= 6) {
			//Subtract days in current working week from work days
			var remainingWorkDays = daysToAdd - (5 - dow);
			//Add current working week's weekend
			daysToAdd += 2;
			if (remainingWorkDays > 5) {
				//Add two days for each working week by calculating how many weeks are included
				daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
				//Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
				if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
			}
		}
		startDate.setDate(startDate.getDate() + daysToAdd);
		return startDate;
	}
	onApplyPTP() {
		let applied_data = [];
		applied_data.push(this.PTP_info);
		if (this.PTP_info?.['status'] == 'NSF')
			alert('This Payment has already been NSF');
		else {
			this.apiService.applyPTP(applied_data, this.userID).subscribe((res) => {
				console.log(res);
				this.apiService.sendClickEvent();
				this.apiService.sendMessage("modal_processing");
				this.dialogRef.close();
				//this.confirmUpdate()
			});
		}
	}
	updatePTP() {
		if (!this.date) {
			this.apiService
				.updateParentPTP(
					this.date,
					this.PTP_info?.parent_id,
					this.changed_By,
					this.userID,
					this.payment_type,
					this.amount,
					this.status
				)
				.subscribe((res) => {
					console.log(res);
					this.apiService.sendClickEvent();
					this.dialogRef.close();
				});
		} else {
			let ptpDate = new Date(this.date);
			let today_date = new Date();
			let three_days = new Date();
			let two_days = new Date();
			let ptp_check;
			let ptp_already_exists = false;
			three_days = this.addWorkDays(new Date(), 2);
			two_days = this.addWorkDays(new Date(), 1);
			for (var i = 0; i < this.existing_PTP.length; i++) {
				console.log(this.existing_PTP[i].PtP_Date);
				ptp_check = this.existing_PTP[i].PtP_Date.split('/')
					.reverse()
					.join('/');
				ptp_check = new Date(ptp_check);
				if (
					ptp_check.getFullYear() == ptpDate.getFullYear() &&
					ptp_check.getMonth() == ptpDate.getMonth() &&
					ptp_check.getDate() == ptpDate.getDate() &&
					this.payment_type == 'manual_debit'
				) {
					ptp_already_exists = true;
					break;
				}
			}
			if (!this.date) alert('PTP Date cannot be blank');
			else if (ptp_already_exists)
				alert('A PTP already exisits with this date');
			else if (this.roleID != 3) {
				//Accounts have no restrictions when changing ptp date
				if (today_date.getHours() >= 18) {
					if (three_days > ptpDate && this.payment_type == 'manual_debit')
						alert(
							"It's passed 6pm, PTP needs to be at least 3 days from today"
						);
					else if (two_days > ptpDate && this.payment_type == 'manual_debit') {
						alert('PTP needs to be at least 2 days from today');
					} else {
						this.apiService
							.updateParentPTP(
								this.date,
								this.PTP_info?.parent_id,
								this.changed_By,
								this.userID,
								this.payment_type,
								this.amount,
								this.status
							)
							.subscribe((res) => {
								console.log(res);
								this.apiService.sendClickEvent();
								this.dialogRef.close();
							});
					}
				} else if (two_days > ptpDate && this.payment_type == 'manual_debit') {
					alert('PTP needs to be at least 2 days from today');
				} else {
					this.apiService
						.updateParentPTP(
							this.date,
							this.PTP_info?.parent_id,
							this.changed_By,
							this.userID,
							this.payment_type,
							this.amount,
							this.status
						)
						.subscribe((res) => {
							console.log(res);
							this.apiService.sendClickEvent();
							this.dialogRef.close();
						});
				}
			} else {
				this.apiService
					.updateParentPTP(
						this.date,
						this.PTP_info?.parent_id,
						this.changed_By,
						this.userID,
						this.payment_type,
						this.amount,
						this.status
					)
					.subscribe((res) => {
						console.log(res);
						this.apiService.sendClickEvent();
						this.dialogRef.close();
					});
			}
		}
	}
	setStatusPTP(status, parentID) {
		this.apiService.setStatusPTP(status, parentID, this.userID).subscribe((res) => {
			console.log(res);
			this.apiService.sendClickEvent();
			this.dialogRef.close();
		});
	}
}
