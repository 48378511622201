<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img
				title="refresh"
				src="/assets/images/refresh.png"
				width="30"
				height="30"
				(click)="refresh()"
			/>
		</h5>
	</div>
	<ul class="tabs" role="tablist">
		<li>
			<input type="radio" name="tabs" id="tab1" checked />
			<label
				for="tab1"
				role="tab"
				aria-selected="true"
				aria-controls="panel1"
				tabindex="0"
				>Origination<br />
				<span class="totalRowPipeline"
					>[{{ totalOriginationCount }}]
					{{ totalOriginationAmount | currency }}</span
				>
			</label>
			<div
				id="tab-content1"
				class="tab-content"
				role="tabpanel"
				aria-labelledby="description"
				aria-hidden="false"
			>
				<!-- <div class="totalRowPipeline"><h5>[{{totalOriginationCount}}] {{totalOriginationAmount | currency}}</h5></div>     -->
				<div class="kanbanContainer">
					<!-- <div class="kanbanContainer-scroll"> -->
					<div class="board-column todo">
						<div class="board-column-header">
							Incomplete Application ({{ countIncomplete }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalIncomplete | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealIncompleteApps">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Needs Documents ({{ countDocuments }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalDocuments | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealNeedDocs">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Pending EC ({{ countPendingEC }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalPendingEC | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealPendingEC">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Pending VOE ({{ countPendingVOE }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalPendingVOE | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealPendingVOE">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<!-- </div>  -->
				</div>
			</div>
		</li>

		<li>
			<input type="radio" name="tabs" id="tab2" />
			<label
				for="tab2"
				role="tab"
				aria-selected="false"
				aria-controls="panel2"
				tabindex="0"
				>Underwriting<br />
				<span class="totalRowPipeline"
					>[{{ totalUnderwritingCount }}]
					{{ totalUnderwritingAmount | currency }}</span
				>
			</label>
			<div
				id="tab-content2"
				class="tab-content"
				role="tabpanel"
				aria-labelledby="specification"
				aria-hidden="true"
			>
				<div class="kanbanContainer">
					<!-- <div class="kanbanContainer-scroll"> -->
					<div class="board-column todo">
						<div class="board-column-header">
							Credit Bureau ({{ countCreditBureau }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalCreditBureau | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealCreditBureau">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Ready for Loans Interview ({{ countLoanInterview }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalLoanInterview | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealLoanInterview">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							File Review ({{ countFileReview }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalFileReview | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealFileReview">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Pending-Manager Approval ({{ countPendingVOE }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalPendingVOE | currency
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealPendingVOE">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<!-- </div> -->
				</div>
			</div>
		</li>
		<li>
			<input type="radio" name="tabs" id="tab3" />
			<label
				for="tab3"
				role="tab"
				aria-selected="false"
				aria-controls="panel3"
				tabindex="0"
				>Closing<br />
				<span class="totalRowPipeline"
					>[{{ totalClosingCount }}] {{ totalClosingAmount | currency }}</span
				>
			</label>
			<div
				id="tab-content3"
				class="tab-content"
				role="tabpanel"
				aria-labelledby="specification"
				aria-hidden="true"
			>
				<div class="kanbanContainer">
					<!-- <div class="kanbanContainer-scroll"> -->
					<div class="board-column todo">
						<div class="board-column-header">
							Conditionally Approved ({{ countCA }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{ totalCA | currency }}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealCA">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Approved ({{ countApproved }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalApproved | currency : 'CAD'
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealApproved">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Agreements ({{ countAgreements }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalAgreements | currency : 'CAD'
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealAgreements">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Validation ({{ countValidation }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalValidation | currency : 'CAD'
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealValidation">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">Funded ({{ countFunded }})</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalFunded | currency : 'CAD'
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealFunded">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="board-column todo">
						<div class="board-column-header">
							Partners Funded ({{ countPF }})
						</div>
						<div class="board-column-content-wrapper">
							<div class="board-column-content">
								<div class="board-item">
									<span class="subStageTotal">{{
										totalPF | currency : 'CAD'
									}}</span>
									<table>
										<tbody class="kanbanBottom">
											<tr *ngFor="let item of todayDealPF">
												<div class="board-item-content">
													<a
														class="noline"
														target="_blank"
														href="https://crm.zoho.com/crm/org642546092/tab/Potentials/{{
															item.id
														}}"
													>
														<td>
															<span class="dealName">{{ item.Deal_Name }}</span
															><br />{{ item.Amount | currency }}
														</td>
													</a>
												</div>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<!-- </div>    -->
				</div>
			</div>
		</li>
	</ul>
</div>
