import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { ColDef } from '@ag-grid-community/core';
import { GridOptions } from 'ag-grid-community';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { DatePipe } from '@angular/common';

interface jsPDFWIthPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-payment-report-loan-servicing',
  templateUrl: './payment-report-loan-servicing.component.html',
  styleUrls: ['./payment-report-loan-servicing.component.scss'],
})

export class PaymentReportLoanServicingComponent implements OnInit, OnChanges {
  @Input() acctref?: any;
  @Input() loanNumber?: any;

  public columnDefs;
  public defaultColDef: ColDef = { flex: 1, resizable: true };
  public detailCellRendererParams;
  public rowDataPaymentHistory: any[];
  public getRowStyle_Master: any;


  client_info: any;
  mydate: any;
  payoff: any;
  showPaymentCard: boolean;

  paymentHistory: any;
  paymentDue: any;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe,) { }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.acctref) {
      // console.log(this.acctref,"acctref____________________")
      this.apiService.getPaymentReportLoanServicing(this.acctref).subscribe((res) => {
        this.rowDataPaymentHistory = res['data'];
      });

      this.columnDefs = [
        { field: "Date Paid", cellRenderer: "agGroupCellRenderer", minWidth: 200, width: 350, suppressSizeToFit: true },
        { field: "Date Due", minWidth: 150, width: 150, },
        { field: "Payment Amount", minWidth: 180, width: 180, },
        { field: "Payment Description", minWidth: 250, width: 250, },
        { field: "Principal", minWidth: 120, width: 120, },
        { field: "Interest", minWidth: 120, width: 120, },
        { field: "Other", minWidth: 120, width: 120, },
        { field: "Status", minWidth: 200, width: 200, },
        { field: "DPD", minWidth: 120, width: 120, }
      ];
      this.getRowStyle_Master = (params) => {
        if (params.data.Status === 'Pending') {
          return { color: '#07b51c' };   // Green
        }
        if (params.data.Status === 'Manual') {
          return { color: '#8B8000' };   // Green
        }
        if (params.data.Status === 'Payment Due') {
          return { color: '#2086e3' };   // Blue
        }
        if (params.data.nsf_flag) {
          return { color: 'red' };   // Blue
        }
      };

      this.detailCellRendererParams = {
        detailGridOptions: {
          columnDefs: [
            { field: "Date Due" },
            { field: "Payment Amount" },
            { field: "Payment Description" },
            { field: "Amount Paid" },
            { field: "Balance" },
            { field: "Pmt#" },
            { field: "DPD" }
          ],
          defaultColDef: { flex: 1, resizable: true },
          rowClassRules: {
            'tot_payment_master_detail': (params) => { return params.data['Payment Description'] === "TOT PAYMENT"; },
            // Even and Odd row color for ptp
            'ptp_payment_master_detail': (params) => {
              if (params.data['Payment Description'] === "PTP" || params.data['Payment Description'] === "ARRANGEMENT") {
                return params.node.rowIndex % 2 === 0;
              }
            }
          }
        },
        getDetailRowData: function (params) {
          params.successCallback(params.data.details);
        }
      };

      this.apiService.getPaymentHistorySummary(this.acctref).subscribe((res) => {
        this.paymentHistory = res;
      });

      this.apiService.getPaymentDueSummary(this.acctref).subscribe((res) => {
        this.paymentDue = res;
      });
    }

  }
  async getPayoffInfo(acctrefno, payoff_date) {
    return new Promise((resolve, reject) => {
      this.apiService.getPayoffInfo(acctrefno, payoff_date).subscribe((res) => {
        resolve(res);
      });
    });
  }

  async downloadPaymentHistoryStatement() {
    this.mydate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    let payoff_info = await this.getPayoffInfo(this.acctref, this.mydate);
    try {
      this.payoff = payoff_info['PayoffAmount'];
    } catch (err) {
      console.log('Maximum licenses have been reached');
      this.payoff = 'NA';
    }
    this.apiService.getPaymentHistoryStatement(this.loanNumber).subscribe((res: any[]) => {
      console.log("Payoff History Statement")
      console.log(res)
      this.client_info = res
      let docs = new jsPDF('portrait', 'px', 'a4') as jsPDFWIthPlugin
      let payment_history = this.client_info.map((entries) => Object.values(entries))
      //docs.addImage(this.image_base64.LMCredit_image, "JPEG", 30, 20, 100, 50)
      docs.setFontSize(12)
      docs.text("STATEMENT OF ACCOUNT", 280, 75)
      docs.setFontSize(10)
      docs.text("Applicant Name: " + this.client_info[0]["name"], 30, 95)
      docs.text("Applicant Address: ", 30, 107)
      docs.text(this.client_info[0]["Debtor Address"].concat(", ").concat(this.client_info[0]["Debtor City"]).toUpperCase().concat(", ").concat(this.client_info[0]["Debtor Province"]), 30, 119)
      docs.text(this.client_info[0]["Debtor Postal Code"].concat(", Canada"), 30, 131)
      docs.text("Payoff Date: " + this.mydate, 280, 95)
      docs.text("Maturity Date: " + this.client_info[0]["curr_maturity_date"], 280, 107)
      docs.text("Next Payment Due: " + this.client_info[0]["next_billing_date"], 280, 119)
      docs.text("Principle Balance: $ " + (this.client_info[0]["current_principal_balance"]).toFixed(2), 280, 131)
      docs.text("Payoff Amount: $ " + (parseFloat(this.payoff)).toFixed(2), 280, 143)
      docs.text("Account Number: " + this.client_info[0]["loan_number"], 280, 155)
      docs.setFontSize(12)
      docs.setFillColor("#000000")
      docs.text("Account Activity", 30, 177)
      docs.autoTable({
        didParseCell: function (table) {

          if (table.section === 'head') {
            table.cell.styles.fillColor = '#687874';
            table.cell.styles.textColor = "ffffff"
          }
        },
        styles: {
          fontSize: 10
        },
        theme: "grid",
        startY: 187,
        head: [["Payment Date", "Payment Description", "Amount", "Payment Method Reference"]],
        body: payment_history
      })
      docs.save(this.client_info[0]["name"] + ".pdf")
    })

  }

}
