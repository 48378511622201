import {
	UntypedFormControl,
	UntypedFormGroup,
	FormBuilder,
	Validators,
	FormArray,
} from '@angular/forms';

export class ChecklistEntry {
	source_of_funding = [
		{ display_value: 'LM Credit' },
		{ display_value: 'LM Mutual Fund Trust' },
		{ display_value: 'SPV' },
		{ display_value: 'MFT 4476' },
	];
	funding_payment_method = [
		{ display_value: 'Cheque' },
		{ display_value: 'EFT' },
		{ display_value: 'Email Money Transfer' },
	];
	refinance_list = [
		{ actual_value: 'No', display_value: 'No' },
		{ actual_value: 'Yes', display_value: 'Yes' },
	];
	frontEndForm: UntypedFormGroup;
	backEndForm: UntypedFormGroup;
	fundingForm: UntypedFormGroup;

	constructor() {
		this.frontEndForm = new UntypedFormGroup({
			valid_id: new UntypedFormControl(false),
			date_of_birth: new UntypedFormControl(false),
			correct_address: new UntypedFormControl(false),
			populate_address: new UntypedFormControl(''),
			consent_signed: new UntypedFormControl(false),
			credit_bureau_pulled: new UntypedFormControl(false),
			income_is_more_than_1200: new UntypedFormControl(false),
			frontend_officer: new UntypedFormControl('', Validators.required),
			loan_interview_complete: new UntypedFormControl(false),
			final_decision_complete: new UntypedFormControl(false),
			verify_tdsr: new UntypedFormControl(false),
			verify_credit_score: new UntypedFormControl(false),
			creditor: new UntypedFormControl(false),
			frontend_status: new UntypedFormControl(false),
			existing_client: new UntypedFormControl(''),
		});
		this.backEndForm = new UntypedFormGroup({
			lpp_included: new UntypedFormControl(false),
			sif_pif_letter: new UntypedFormControl(false),
			payment_instructions: new UntypedFormControl(false),
			date_of_sif_pif: new UntypedFormControl(false),
			borrower_name_matches_pla: new UntypedFormControl(false),
			verify_loan_payment: new UntypedFormControl(false),
			lpp_sold_by: new UntypedFormControl(false),
			loan_agreement_prepared_by: new UntypedFormControl(false),
			loan_date: new UntypedFormControl(false),
			account_balance: new UntypedFormControl(false),
			account_number: new UntypedFormControl(false),
			verify_ec: new UntypedFormControl(false),
			verify_poa: new UntypedFormControl(false),
			verify_voe: new UntypedFormControl(false),
			additional_debts: new UntypedFormControl(false),
			backend_officer: new UntypedFormControl('', Validators.required),
			final_approval_note: new UntypedFormControl(false),
			banking_info_verified: new UntypedFormControl(false),
			backend_status: new UntypedFormControl(false),
			bdm_payment_method: new UntypedFormControl(''),
			manager_exception: new UntypedFormControl('No'),
			uw_conditions_met: new UntypedFormControl(''),
			active_judgement: new UntypedFormControl(''),
		});
		this.fundingForm = new UntypedFormGroup({
			source_of_funding: new UntypedFormControl(''),
			funding_payment_method: new UntypedFormControl(''),
			funding_officer: new UntypedFormControl('', Validators.required),
			funding_officer_email: new UntypedFormControl(''),
			// 'amortization_schedule': new FormControl(false),
			verified_all_documents_uploaded_correctly: new UntypedFormControl(false),
			// 'balance_letters': new FormControl(false),
			balance_letters_file: new UntypedFormControl(''),
			amortization_schedule_file: new UntypedFormControl(''),
			mft_lpp: new UntypedFormControl(false),
			final_funding_officer: new UntypedFormControl(''),
			all_verfied: new UntypedFormControl(false),
			proof_of_payment_file: new UntypedFormControl(''),
			funding_status: new UntypedFormControl(false),
			refinance: new UntypedFormControl('No'),
			referral_agency: new UntypedFormControl(''),
		});
	}

	resetFrontendForm() {
		for (const field in this.frontEndForm.controls) {
			this.frontEndForm.patchValue({
				[field]: false,
			});
		}
		this.frontEndForm.get('populate_address').patchValue('');
		this.frontEndForm.get('frontend_officer').patchValue('');
	}

	resetBackendForm() {
		for (const field in this.backEndForm.controls) {
			console.log();
			this.backEndForm.patchValue({
				[field]: false,
			});
		}
		this.frontEndForm.get('backend_officer').patchValue('');
	}

	// resetFundingForm(){
	//     for (const field in this.fundingForm.controls) {
	//         this.fundingForm.patchValue({
	//             [field]: false
	//         });
	//     }
	// }
}
