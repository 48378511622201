import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../../api-service.service';
import { CustomFunctionService } from '../../../custom-function.service';

@Component({
	selector: 'app-underwritingagencyexception',
	templateUrl: './underwritingagencyexception.component.html',
	styleUrls: ['./underwritingagencyexception.component.scss'],
})
export class UnderwritingagencyexceptionComponent implements OnInit {
	agencyName: string;
	agencyIssuedArray: string[] = [];

	countAgencyMonth: number;
	totalAgencyfundedMonth: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private router: ActivatedRoute,
		private _routerLink: Router
	) {}

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						// Get the collector name from the nlsAgencyFunded page
						this.router.queryParams.subscribe((params) => {
							this.agencyName = params['agency'];
						});

						this.apiService
							.getDealsCreatedUnderwritingException()
							.subscribe((res) => {
								let arr = JSON.parse(res.message.body);
								this.agencyIssuedArray = this.customFunction.singleFilterName(
									arr.data,
									'Agency_Formula',
									this.agencyName
								);
								this.customFunction.sortColumn(
									this.agencyIssuedArray,
									'Exception_Funded_Amount',
									-1,
									1
								);

								this.countAgencyMonth = this.agencyIssuedArray.length;
								this.totalAgencyfundedMonth = this.customFunction.sumNumbers(
									this.agencyIssuedArray,
									'Exception_Funded_Amount'
								);
							});
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}
}
