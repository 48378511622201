import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';

@Component({
  selector: 'app-zero-balance',
  templateUrl: './zero-balance.component.html',
  styleUrls: ['./zero-balance.component.scss']
})
export class ZeroBalanceComponent implements OnInit, AfterViewInit {
  zero_balance_list: any;
  zero_with_interest_list: any;

  zeroFileSheet: any;
  zeroFile = "Zero_Balance-Report";
  interestFileSheet: any;
  interestFile = "Zero_with_interest-Report";

  constructor(private apiService: ApiServiceService) { }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.apiService.getActiveFilesZeroBalance().subscribe((res: any) => {
      this.zeroFileSheet = res?.zeroBalance;
      this.interestFileSheet = res?.zeroWithInterest;
      this.zero_balance_list = res?.zeroBalance[0];
      this.zero_with_interest_list = res?.zeroWithInterest[0];
    });
  }
}