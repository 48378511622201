import { AfterViewInit, Component } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';
import { sumNumbers } from '@lmc-app/utils/filter-functions';

@Component({
  selector: 'app-collection-dasboard-metrics',
  templateUrl: './collection-dasboard-metrics.component.html',
  styleUrls: ['./collection-dasboard-metrics.component.scss']
})
export class CollectionDasboardMetricsComponent implements AfterViewInit {
  constructor(private apiService: ApiServiceService) { }
  ;
  collectionDataSubscription: Subscription;

  ptpData: any;
  totalPTPCreated: any;
  showPTPCard = false;
  totalPTPCreatedAmount: any;

  arrangementData: any;
  totalArrangementCreated: any;
  showArrangementCard = false;
  totalArrangementCreatedAmount: any;

  totalFilesCreated: any;
  showCreatedCard = false;

  filesNotCreatedData: any;
  totalFilesNotCreated: any;
  showNotCreatedCard = false;

  filesNSF: any;
  totalNSFfiles: any;
  totalPTP14Days: any;
  showNSFCard = false;

  filesAssigned: any;
  totalAssignedFiles: any;
  showAssignedCard = false;
  totalAssignedAmount: any;

  newCollectionFiles: any;
  totalNewCollectionFiles: any;
  showNewCollectionFiles = false;
  totalLoanProfilePastAmount: any;
  totalLoanProfileCurrentAmount: any;

  setRealtime: any;

  ngAfterViewInit(): void {
    this.getMetrics();
    this.setRealtime = setInterval(() => {
      this.getMetrics();
    }, 60000);
  }

  getMetrics() {
    this.collectionDataSubscription = this.apiService.getHomeCollectionMetrics().subscribe((res) => {
      this.ptpData = res?.['ptpCreated'][0];
      this.totalPTPCreated = sumNumbers(this.ptpData, 'ptp_count');
      this.totalPTPCreatedAmount = sumNumbers(this.ptpData, 'payment_amount');

      this.arrangementData = res?.['arrangementCreated'][0];
      this.totalArrangementCreated = sumNumbers(this.arrangementData, 'arrangement_count');
      this.totalArrangementCreatedAmount = sumNumbers(this.arrangementData, 'payment_amount');

      this.filesNotCreatedData = res?.['filesNotCreated'];
      this.totalFilesCreated = sumNumbers(this.filesNotCreatedData, 'countCreated');
      this.totalFilesNotCreated = sumNumbers(this.filesNotCreatedData, 'countNotCreated');

      this.filesNSF = res?.['filesNSF'][0];
      this.totalNSFfiles = sumNumbers(this.filesNSF, 'nsf_count');
      this.totalPTP14Days = sumNumbers(this.filesNSF, 'ptp_count')

      this.filesAssigned = res?.['filesAssigned'][0];
      this.totalAssignedFiles = sumNumbers(this.filesAssigned, 'assigned_count');
      this.totalAssignedAmount = sumNumbers(this.filesAssigned, 'total_past_due_balance');

      this.newCollectionFiles = res?.['newCollectionFiles'];
      this.totalNewCollectionFiles = sumNumbers(this.newCollectionFiles, 'loanProfileCount');
      this.totalLoanProfilePastAmount = sumNumbers(this.newCollectionFiles, 'loanProfilePastAmount');
      this.totalLoanProfileCurrentAmount = sumNumbers(this.newCollectionFiles, 'loanProfileCurrentAmount');
    })
  }

  ngOnDestroy() {
    if (this.setRealtime) {
      clearInterval(this.setRealtime);
    }
    this.collectionDataSubscription.unsubscribe();
    console.log("Collection Dashboard unsubscribed!")
  }

}
