<div class="flex-container">
    <mat-card *ngFor="let item of bucketList">
        <mat-card-header>
            <mat-card-title>
                <h5>{{item[0]}}</h5>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <strong>Total:</strong> {{(item[1].split(" "))[0]}}<br>
            <strong>Amount Due:</strong> {{(item[1].split(" "))[2]}}<br>
            <strong>Outstanding:</strong> {{(item[1].split(" "))[1]}}<br>
        </mat-card-content>
    </mat-card>
</div>

<div class="flex-container">
    <mat-card *ngFor="let item of portfolioList">
        <mat-card-header>
            <mat-card-title>
                <h5>{{item[0]}}</h5>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <strong>Total:</strong> {{(item[1].split(" "))[0]}}<br>
            <strong>Amount Due:</strong> {{(item[1].split(" "))[2]}}<br>
            <strong>Outstanding:</strong> {{(item[1].split(" "))[1]}}<br>
        </mat-card-content>
    </mat-card>
    <mat-card style="width: 540px; background-color: #faf9f9;">
        <mat-card-header>
            <mat-card-title>
                <h5>LPP Claim {{statusName}}</h5>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <button mat-stroked-button [color]="allSelected" name="All" (click)="getLPPClaimData(3,'All')">All</button>
            <button mat-stroked-button [color]="arrangedSelected" name="Arranged"
                (click)="getLPPClaimData(5,'Arranged')">Arranged</button>
            <button mat-stroked-button [color]="notArrangedSelected" name="Not Arranged"
                (click)="getLPPClaimData(2,'Not Arranged')">Not
                Arranged</button>
        </mat-card-content>
    </mat-card>
</div>

<div class="row" style="height: calc(100% - 175px); margin: 10px;">
    <app-custom-table class="ag-theme-material" [rowData]="rowData" [columnDefs]="columnDefs" [hideGlobalFilter]="true"
        [defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"
        [animateRows]="true" [pagination]="true" [paginationPageSize]="100" (gridReady)="onGridReady($event)">
    </app-custom-table>
</div>