import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor(private datepipe: DatePipe) { };

    // /**
    //  * To export single worksheet
    //  * @param json One json object
    //  * @param excelFileName 
    //  */
    // public exportToExcel(json: any[], excelFileName: string): void {
    //     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //     const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //     // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     XLSX.writeFile(workbook, excelFileName + '_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx', { bookType: 'xlsx', type: 'buffer' });
    // }

    /**
     * This service should be able to do both single sheet and multiple sheets export
     * @param sheetArray Array of JSON object or objects 
     * @param excelFileName Filename to be saved
     */
    public exportToExcel(sheetArray: any[], excelFileName: string): void {
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();      // Create workbook
        for (let i = 0; i < sheetArray.length; i++) {
            const sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray[i]);     // Get the JSON for the sheet
            XLSX.utils.book_append_sheet(workbook, sheet, sheet[i]);        // Append the sheets to create multiple sheets
        }
        XLSX.writeFile(workbook, excelFileName + '_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx', { bookType: 'xlsx', type: 'buffer' });
    }
}