import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';
import { ExcelService } from '@lmc-app/services/excel.service';
import { currencyFormatterAgGrid } from '@lmc-app/utils/numbers';
import { GeneralObjects } from '@lmc-app/models/globalObjects';

@Component({
  selector: 'app-ptp-date-based',
  templateUrl: './ptp-date-based.component.html',
  styleUrls: ['./ptp-date-based.component.scss']
})
export class PtpDateBasedComponent implements OnDestroy {

  sheetArray = [];
  fileName = 'PTP Report';
  ptpDataSubscription: Subscription;
  ptp_report: any;
  start_date: any;
  end_date: any;
  btnSpinner = false;
  btnShowText = true;
  ptpDetailedList: any;
  ptpLoanGroupList: any;
  // sheetArray = [];    // Array for excel

  selectedDateValue = 'PtP_Date';
  generalObjects = new GeneralObjects(); // To get the date options i.e PtP_date and created_at

  //_Hist
  public rowClassRules = { 'footer-row': (params) => params.node.footer === true };   // To style the footer total 
  defaultColDef_ptp: any;
  autoGroupColumnDef: any;
  public columnDefs_ptp = [];
  public rowData_ptp;
  public gridApi_ptp;
  public gridColumnApi_ptp;
  public gridOptions_ptp;
  public defaultExcelExportParams;
  public defaultCsvExportParams;


  constructor(private apiService: ApiServiceService, private datepipe: DatePipe, private excelService: ExcelService) {
    //Payment History
    this.columnDefs_ptp = [
      { headerName: 'Created By', field: 'created_by', sortable: true, filter: 'agTextColumnFilter', minWidth: 230 },
      {
        headerName: 'Total PTP Paid', field: 'Total PTP Paid ($)', sortable: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total PTP Paid Count', field: 'Total PTP Paid Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'Total PTP NSF', field: 'Total PTP NSF ($)', sortable: true, filter: "agNumberColumnFilter", valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total PTP NSF Count', field: 'Total PTP NSF Count', sortable: true, filter: "agNumberColumnFilter", aggFunc: 'sum' },
      {
        headerName: 'Total PTP Broken', field: 'Total PTP Broken ($)', sortable: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total PTP Broken Count	', field: 'Total PTP Broken Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'Total PTP Requested/Arranged', field: 'Total PTP Requested_Arranged ($)', sortable: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'Total PTP Requested/Arranged Count	', field: 'Total PTP Requested_Arranged Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'All PTPs', field: 'All PTPs ($)', sortable: true, filter: "agNumberColumnFilter", valueFormatter: currencyFormatterAgGrid, aggFunc: 'sum',
      },
      { headerName: 'All PTPs Count', field: 'All PTPs Count', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
      {
        headerName: 'PTP Conversion Rate', field: 'PTP Conversion Rate', sortable: true, filter: 'agNumberColumnFilter',
      },
    ]
    this.defaultColDef_ptp = {
      resizable: true
    }

    this.defaultExcelExportParams = { fileName: 'ptp_report_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'ptp_report_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  onGridReady_Ptp(params) {
    this.gridApi_ptp = params.api;
    this.gridColumnApi_ptp = params.columnApi;
    this.gridApi_ptp.setRowData(this.ptp_report);
    this.rowData_ptp = this.ptp_report

  }

  startDate(e) {
    this.start_date = e;
  }

  endDate(e) {
    this.end_date = e;
  }

  selectedDateOption(e) {
    this.selectedDateValue = e;
  }

  downloadPTP() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else {
      this.btnSpinner = true;
      this.btnShowText = false;
      this.ptp_report = null;
      this.rowData_ptp = [];
      this.ptpDataSubscription = this.apiService.getPtpReport(this.start_date, this.end_date, 'ALL', 'ptp', this.selectedDateValue).subscribe((res) => {
        this.ptp_report = res?.['data'];
        this.sheetArray = [];
        this.sheetArray.push(this.ptp_report);
        console.log(this.ptp_report)
        this.gridApi_ptp.setRowData(this.ptp_report);
        this.rowData_ptp = this.ptp_report;
        this.btnSpinner = false;
        this.btnShowText = true;

        this.getPtpDetailedReport(this.start_date, this.end_date, 'ptp', this.selectedDateValue);
        this.getPtpLoanGroupReport(this.start_date, this.end_date, 'ptp', this.selectedDateValue);
      })
    }
  }

  getPtpDetailedReport(startDate?: string, endDate?: string, ptpType?: string, dateOption?: string) {
    this.apiService
      .getPtpDetailedReport(startDate, endDate, ptpType, dateOption)
      .subscribe((res: any) => {
        console.log(res)
        this.ptpDetailedList = res?.data;
        this.sheetArray.push(this.ptpDetailedList);
      });
  }

  getPtpLoanGroupReport(startDate?: string, endDate?: string, ptpType?: string, dateOption?: string) {
    this.apiService
      .getPtpLoanGroupReport(startDate, endDate, ptpType, dateOption)
      .subscribe((res: any) => {
        console.log(res)
        this.ptpLoanGroupList = res?.data;
        this.sheetArray.push(this.ptpLoanGroupList);
      });
  }

  ngOnDestroy() {
    this.ptpDataSubscription?.unsubscribe();
  }

}
