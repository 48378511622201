import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { dateFilterAgGrid } from '@lmc-app/utils/filter-functions';
import { CellClickedEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { sumNumbers } from '@lmc-app/utils/filter-functions';
import { resolve } from 'dns';
import { GeneralObjects } from '@lmc-app/models/globalObjects';

@Component({
  selector: 'app-ptp-collection-list',
  templateUrl: './ptp-collection-list.component.html',
  styleUrls: ['./ptp-collection-list.component.scss']
})

export class PtpCollectionListComponent implements AfterViewInit, OnDestroy {
  getMonthName = new GeneralObjects();

  adminDataSubscription: Subscription;
  individualDataSubscription: Subscription;

  defaultColDef_Collection: any;
  public columnDefs_Collection = [];
  public rowData_Collector_PTP;
  public gridApi_Collection;
  public gridColumnApi_Collection;
  public gridOptions_Collection;
  rowClassRules = {
    'requested-row': (params) => params?.data?.current_principal_balance < 1500
  };

  sessionInfo: any; // Session information
  adminView = false; // Component permission flag
  filtered_list: any; // The final list for display
  showLoader: boolean;
  showEmptyResults: boolean;

  monthIndicator = 'ALL';
  totalProcessed: any;
  countProcessed: any;
  totalArranged: any;
  countArranged: any;
  totalNSF: any;
  countNSF: any;
  totalRequested: any;
  countRequested: any;
  totalBroken: any;
  countBroken: any;
  totalPTP: any;
  countPTP: any;

  currentSelected = 'basic';
  nextSelected = 'basic';
  calculateFilterSelected = 'basic';
  allSelected = 'accent';

  constructor(private apiService: ApiServiceService, private router: Router) {
    this.columnDefs_Collection = [
      {
        headerName: 'Loan Number', field: 'Loan Number', filter: "agTextColumnFilter", pinned: 'left',
        onCellClicked: (event: CellClickedEvent) => this.openLoanMeta(event?.data?.['Loan Number']),
        cellStyle: { cursor: 'pointer', color: '#116abd' },
      },
      { headerName: 'Name', field: 'Name', filter: "agTextColumnFilter", minWidth: 250 },
      { headerName: 'Type', field: 'Type', filter: "agTextColumnFilter", minWidth: 130, maxWidth: 130 },
      {
        headerName: 'PTP Date', field: 'PTP Date', filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterDate, cellValue) => dateFilterAgGrid(filterDate, cellValue),
          browserDatePickerDateFormat: 'yyyy-mm-dd',
        },
      },
      {
        headerName: 'Collector', field: 'Collector', filter: 'agTextColumnFilter', minWidth: 250
      },
      { headerName: 'Total PTP Amount', field: 'Total PTP Amount', filter: "agNumberColumnFilter", },
      { headerName: 'Processed Amount', field: 'Processed Amount', hide: true, },
      { headerName: 'Arranged Amount', field: 'Arranged Amount', hide: true, },
      { headerName: 'Requested Amount', field: 'Requested Amount', hide: true, },
      { headerName: 'NSF Amount', field: 'NSF Amount', hide: true, },
      { headerName: 'Broken Amount', field: 'Broken Amount', hide: true, },
      { headerName: 'Status', field: 'Status', filter: "agTextColumnFilter", },
      {
        headerName: 'Applied At', field: 'Applied At', filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterDate, cellValue) => dateFilterAgGrid(filterDate, cellValue),
          browserDatePickerDateFormat: 'yyyy-mm-dd',
        },
      },
      {
        headerName: 'Applied By', field: 'Applied By', filter: "agTextColumnFilter",
        valueGetter: (params) => params.data?.['Applied By']?.split('@')[0],
      },
      { headerName: 'Payment Method', field: 'Payment Method', filter: "agTextColumnFilter", },
    ];
    this.defaultColDef_Collection = {
      resizable: true
    };
  }

  ngAfterViewInit(): void {
    this.monthIndicator = 'ALL'
    if (localStorage.getItem('SESSIONID')) {
      this.apiService.verifysession(localStorage.getItem('SESSIONID')).subscribe(async (res: any) => {
        this.showLoader = true;
        if (res.message === 'Success') {
          this.sessionInfo = res?.['data']?.[0];
          this.adminView = this.sessionInfo?.['collection_list'] === null ? false : this.sessionInfo?.['collection_list'];  // Defining Admin from the permission_component table
          if (this.adminView) { // If the component is set to try on permission_component table
            this.filtered_list = '';
            this.adminDataSubscription = this.apiService.getCollectorAdminPTP().subscribe((res) => {
              this.filtered_list = res;
              this.gridApi_Collection?.setRowData(this.filtered_list);
              this.hideCollectorName();
              this.rowData_Collector_PTP = this.filtered_list;
              this.totalAmountCalculations();

              this.showLoader = false;
              this.showEmptyResults = false;
              // }
            });
          } else {  // If false then its collector based list
            this.filtered_list = '';
            this.individualDataSubscription = this.apiService.getCollectorPTP(this.sessionInfo['full_name']).subscribe((res) => {
              this.filtered_list = res;
              this.countPTP = this.filtered_list.length;
              this.totalPTP = sumNumbers(this.filtered_list, 'Total PTP Amount');
              // this.totalAmountCalculations();

              this.gridApi_Collection?.setRowData(this.filtered_list);
              this.hideCollectorName();
              // console.log(this.filtered_list)
              this.rowData_Collector_PTP = this.filtered_list;
              this.totalAmountCalculations();

              this.showLoader = false;
              this.showEmptyResults = false;
            });
          }
        }
      })
    }
  }

  currentMonth() {
    // console.log(this.filtered_list)let today = new Date();
    const currentDate = new Date();
    this.monthIndicator = 'Current Month (' + this.getMonthName.monthNames[currentDate.getMonth()] + ')';
    let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const currentList = this.filtered_list.filter((item) => new Date(item['PTP Date'].concat("T00:00:00")) >= firstDay && new Date(item['PTP Date'].concat("T00:00:00")) <= lastDay);

    this.gridApi_Collection?.setRowData(currentList);
    this.hideCollectorName();
    // console.log(nsfList)
    this.rowData_Collector_PTP = currentList;
    this.totalAmountCalculations();
    this.currentSelected = 'accent';
    this.nextSelected = 'basic';
    this.calculateFilterSelected = 'basic';
    this.allSelected = 'basic';
  }

  nextMonth() {// console.log(this.filtered_list)
    const currentDate = new Date();
    this.monthIndicator = 'Next Month (' + this.getMonthName.monthNames[currentDate.getMonth() + 1] + ')';

    let firstDayofNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    let lastDayofNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
    const nextMonthList = this.filtered_list.filter((item) => new Date(item['PTP Date'].concat("T00:00:00")) >= firstDayofNextMonth && new Date(item['PTP Date'].concat("T00:00:00")) <= lastDayofNextMonth);
    this.gridApi_Collection?.setRowData(nextMonthList);
    this.hideCollectorName();
    // console.log(nsfList)
    this.rowData_Collector_PTP = nextMonthList;
    this.totalAmountCalculations();
    this.currentSelected = 'basic';
    this.nextSelected = 'accent';
    this.calculateFilterSelected = 'basic';
    this.allSelected = 'basic';

  }

  resetFilters() {
    this.gridApi_Collection!.setFilterModel(null);
    this.gridApi_Collection?.setRowData(this.filtered_list);
    this.hideCollectorName();
    // console.log(this.filtered_list)
    this.rowData_Collector_PTP = this.filtered_list;
    this.monthIndicator = 'ALL';
    this.totalAmountCalculations();
    this.currentSelected = 'basic';
    this.nextSelected = 'basic';
    this.calculateFilterSelected = 'basic';
    this.allSelected = 'accent';
  }

  applyFilter() {
    this.currentSelected = 'basic';
    this.nextSelected = 'basic';
    this.calculateFilterSelected = 'accent';
    this.allSelected = 'basic';
    this.totalAmountCalculations();
  }

  hideCollectorName() {
    return this.adminView ? this.gridColumnApi_Collection?.setColumnsVisible(['Collector'], true) : this.gridColumnApi_Collection?.setColumnsVisible(['Collector'], false);;
  }

  //INCLUDE THIS IN THE SEARCH COMPONENT TO OPEN LOAN META FOR SPECIFIC LOAN FOR NLS (NEW COLOUMN FOR NLS OPEN)
  openLoanMeta(loan_number: number) {
    this.apiService.searchNLSLoan(loan_number).subscribe((res: any[]) => {
      if (res.length == 0) {
        alert('Loan Number cannot be found on NLS');
      } else {
        this.apiService.sendMessage(res);
        this.router.navigate(['loan-meta']);
      }
    });
  }

  onGridReady(params) {
    this.gridApi_Collection = params.api;
    this.gridColumnApi_Collection = params.columnApi;
    this.gridApi_Collection?.setRowData(this.filtered_list);
    this.hideCollectorName();
    this.rowData_Collector_PTP = this.filtered_list
  }

  async totalAmountCalculations() {
    let resultTotal = await this.getStatusAmountBreakdown('Total PTP Amount').then((item) => {
      this.countPTP = item['count'];
      this.totalPTP = item['total'];
    })
    let resultProcessed = await this.getStatusAmountBreakdown('Processed Amount').then((item) => {
      this.countProcessed = item['count'];
      this.totalProcessed = item['total'];
    })
    let resultArranged = await this.getStatusAmountBreakdown('Arranged Amount').then((item) => {
      this.countArranged = item['count'];
      this.totalArranged = item['total'];
    })
    let resultRequested = await this.getStatusAmountBreakdown('Requested Amount').then((item) => {
      this.countRequested = item['count'];
      this.totalRequested = item['total'];
    })
    let resultNSF = await this.getStatusAmountBreakdown('NSF Amount').then((item) => {
      this.countNSF = item['count'];
      this.totalNSF = item['total'];
    })
    let resultBroken = await this.getStatusAmountBreakdown('Broken Amount').then((item) => {
      this.countBroken = item['count'];
      this.totalBroken = item['total'];
    })
  }

  async getStatusAmountBreakdown(fieldName: string) {
    return new Promise((resolve, reject) => {
      const filteredList = [];
      this.gridApi_Collection.forEachNodeAfterFilterAndSort((rowNode, index) => {
        if (rowNode?.data?.[fieldName] !== 0) {
          filteredList.push(rowNode?.data?.[fieldName]);

        }
      });
      let resultObject = {};
      resultObject = { count: filteredList?.length, total: (filteredList.length === 0) ? 0 : filteredList?.reduce((prev, next) => parseFloat(prev) + parseFloat(next))?.toFixed(2) };
      resolve(resultObject);
    })

  }

  ngOnDestroy() {
    this.adminDataSubscription?.unsubscribe();
    this.individualDataSubscription?.unsubscribe();
    console.log('Collector PTP List Data Unsubscribed!');
  }
}
