import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-expired-ach',
  templateUrl: './expired-ach.component.html',
  styleUrls: ['./expired-ach.component.scss']
})
export class ExpiredAchComponent implements AfterViewInit, OnDestroy {

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    this.columnDefs = [
      //{ headerName: 'Action', cellRenderer: 'buttonRenderer', cellRendererParams: { onClick: this.onBtnClick.bind(this), label: 'Edit' }, width: 100 },
      { headerName: 'Loan Number', field: 'loan_number' },
      { headerName: 'Name', field: 'Name' },
      { headerName: 'Phone No', field: 'Phone_No' },
      { headerName: 'DOB', field: 'DOB' },
    ]
    this.defaultColDef = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'expired_ACH_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'expired_ACH_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  statusID = 3;
  regionName = "All Regions";
  paper_name = 'all';
  collections: any;
  collectionsDataSubscription: Subscription;

  defaultColDef: any;
  public columnDefs = [];
  public rowData;
  public gridApi;
  public gridColumnApi;
  public gridOptions;
  public Collections: any;  //Information we are getting from our nls table
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  ngAfterViewInit(): void {
    this.collectionsDataSubscription = this.apiService.expiredACHs(this.statusID, this.regionName, 0, 10000, this.paper_name).subscribe(async (res) => {
      this.collections = res;
      this.collections = await this.getNSFCount(this.collections)
      this.gridApi.setRowData(this.collections);
      this.rowData = this.collections
    })
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  async getNSFCount(Collections) {
    return new Promise((resolve, reject) => {
      this.apiService.getNSFCount(Collections).subscribe((res) => {
        Collections = res;
        this.gridApi.setRowData(Collections);
        this.rowData = Collections
        resolve(Collections)
      })
    })
  }

  ngOnDestroy() {
    this.collectionsDataSubscription?.unsubscribe();
  }

}
