import {
	UntypedFormControl,
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	FormArray,
} from '@angular/forms';
import { Controls } from '../models/controls';
import { RegexEntry } from '../models/regexPatterns';

export class ApplicationEntry {
	applicationForm: UntypedFormGroup;
	controls = new Controls();
	regexEntry = new RegexEntry();

	fields = [
		'Co_Applicant_Name',
		'Guarantor_Name',
		'Co_Applicant_Gender',
		'Email',
		'Co_Applicant_Date_of_Birth',
		'Secondary_Home_Number',
		'Secondary_S_I_N',
		'Secondary_Cell_Number',
		'Co_Applicant_emergency_contact_name',
		'Co_Applicant_Emergency_contact_number2',
		'Co_Applicant_emergency_contact_relation',
		'Co_Applicant_emergency_contact_email',
		'Co_Applicant_Property_Address',
		'Co_Applicant_Residential_Status',
		'Co_Applicant_Length_of_Residence',
		'Co_Applicant_Address_Line_2_Unit_number',
		'Co_Applicant_City',
		'Co_Applicant_Province',
		'Co_Applicant_Postal_Code',
		'Co_Applicant_Country',
		'Co_Applicant_Status',
		'Co_Applicant_Industry',
		'Co_Applicant_Employer',
		'Co_Applicant_Occupation',
		'Co_Applicant_Supervisor_Name',
		'Secondary_Position',
		'Sec_Annual_Income',
		'Secondary_Years_Worked',
		'Co_Applicant_Payment_Method',
		'Co_Applicant_Supervisor_Phone_Number',
		'Secondary_Work_Number',
		'Co_Applicant_Pay_Frequency',
		'Co_Applicant_Financial_Institution_Name',
		'Co_ApplicantFinancial_Institution_Number1',
		'Co_Applicant_Bank_Account',
		'Co_Applicant_Branch_Transit_Number',
		'Co_Applicant_Branch_Address',
	];

	constructor(private fb: UntypedFormBuilder) {
		// applicationForm controls define objects
		this.applicationForm = new UntypedFormGroup({
			// Unique Applicant ID to bind Additional Debt and Emergency Contacts to the applicant
			uniqueID: new UntypedFormControl(''),

			// PRE-QUALIFYING
			Deal_Name: new UntypedFormControl('', Validators.required),
			Province1: new UntypedFormControl(''), // Prequalifying Province dropdown: This selection binds the client address location for PROVINCE
			Residency_Status: new UntypedFormControl(''),
			If_needed_could_you_find_a_Co_Singer: new UntypedFormControl(''),
			Employment_Status: new UntypedFormControl(''),
			Do_you_earn_at_least_1200_per_month: new UntypedFormControl(''),
			Employed: new UntypedFormControl(''),
			Payday_loans: new UntypedFormControl(''),
			Payment_Method: new UntypedFormControl(''),
			Are_you_currently_a_Home_Owner: new UntypedFormControl(''),
			Bankruptcy_CC_CP: new UntypedFormControl(''),
			Consent_Form_Signed: new UntypedFormControl(''),

			// CLIENT - EXISTING MORTGAGE
			MTG_Balance: new UntypedFormControl(''),
			Estimated_Value: new UntypedFormControl(''),
			Loan_Det: new UntypedFormControl(''),
			Mortgage_Details: new UntypedFormControl(''),

			// REFERRAL
			Account_Name: new UntypedFormControl(''),
			Sales_Agent: new UntypedFormControl('', Validators.required),
			Referred_By: new UntypedFormControl(''),
			Broker: new UntypedFormControl(''),
			Creditt: new UntypedFormControl(''),
			Referral_type: new UntypedFormControl(''),
			Creditor_Agency_Name: new UntypedFormControl(''),
			Referral_Date: new UntypedFormControl(''),
			Acc_Number: new UntypedFormControl(''),
			Closing_Date: new UntypedFormControl(''),
			Account_Bal: new UntypedFormControl(''),
			Agency_Location: new UntypedFormControl(''),
			Settlement_Amount: new UntypedFormControl(''),
			Creditor_Portfolio: new UntypedFormControl(''),
			Funded_Amount: new UntypedFormControl(''),
			Loan_Type: new UntypedFormControl(''),
			Lead_Source: new UntypedFormControl(''),
			Total: new UntypedFormControl(''),
			Additional_Debt2: new UntypedFormControl(''),
			// Subform ADDITIONAL DEBT
			Additional_Debt: this.fb.array([]),

			// CLIENT - PERSONAL
			Gender: new UntypedFormControl(''),
			Do_you_have_another_name: new UntypedFormControl(''),
			Marital_Status: new UntypedFormControl(''),
			Alternative_Name: new UntypedFormControl(''),
			Number_of_Dependents: new UntypedFormControl(''),
			Best_contact_time: new UntypedFormControl(''),
			Date_of_Birth: new UntypedFormControl(''),
			Last_Contact_Date: new UntypedFormControl(''),
			S_I_N: new UntypedFormControl(''),
			Does_you_own_a_car: new UntypedFormControl(''),
			What_is_the_value_of_the_car: new UntypedFormControl(''),
			Email1: new UntypedFormControl(
				'',
				Validators.pattern(this.regexEntry.emailPattern)
			),
			Mobile: new UntypedFormControl(''),
			Phone: new UntypedFormControl(''),
			No_SIN_Reason: new UntypedFormControl(''),
			Co_Appl_Required: new UntypedFormControl(''),
			Emer_Cont: new UntypedFormControl(''),
			// Subform CLIENT -EMERGENCY CONTACT: FOrmArray
			EMERGANCY_CONTACTS: this.fb.array([]),

			// CLIENT - ADDRESS
			Address1: new UntypedFormControl(''),
			Length_of_Residence: new UntypedFormControl(''),
			Address_Line_2: new UntypedFormControl(''),
			Monthly_Payments: new UntypedFormControl(''),
			City: new UntypedFormControl(''),
			Find_Address: new UntypedFormControl(''),
			Province: new UntypedFormControl(''),
			Postal_Code: new UntypedFormControl(''),
			Country: new UntypedFormControl(''),

			// CLIENT - EMPLOYMENT
			Employer: new UntypedFormControl(''),
			Industry: new UntypedFormControl(''),
			Employment_Status1: new UntypedFormControl(''),
			Occupation: new UntypedFormControl(''),
			Work_Phone: new UntypedFormControl(''),
			Pay_Frequency2: new UntypedFormControl(''),
			Work_Extension: new UntypedFormControl(''),
			Income_net_paycheque_income: new UntypedFormControl(''),
			Length_of_Employment: new UntypedFormControl(''),
			Last_Pay_Date: new UntypedFormControl(''),
			Position: new UntypedFormControl(''),
			Supervisor_Name: new UntypedFormControl(''),
			Previous_Employment: new UntypedFormControl(false),
			Next_Pay_Date: new UntypedFormControl(''),
			Supervisor_Phone_Number: new UntypedFormControl(''),
			Additional_Employment: new UntypedFormControl(''),
			Income_Amount_Household_Income: new UntypedFormControl(''),
			Employer_Name_Household_Income: new UntypedFormControl(''),
			Pay_Frequency_Household_Income: new UntypedFormControl(''),

			// CLIENT - PREVIOUS EMPLOYMENT
			Previous_Employment_Type: new UntypedFormControl(''),
			Previous_Position: new UntypedFormControl(''),
			Previous_Employer: new UntypedFormControl(''),
			Previous_Length_of_Employment: new UntypedFormControl(''),
			Previous_Supervisor_Name: new UntypedFormControl(''),
			Previous_Supervisor_Phone_number: new UntypedFormControl(''),
			Previous_Income_net_paycheque_income: new UntypedFormControl(''),
			Previous_Pay_Frequency: new UntypedFormControl(''),
			Previous_Payment_Method: new UntypedFormControl(''),

			// CO-APPLICANT - PERSONAL
			Co_Applicant_Name: new UntypedFormControl(''),
			Guarantor_Name: new UntypedFormControl(''),
			Co_Applicant_Gender: new UntypedFormControl(''),
			Email: new UntypedFormControl(
				'',
				Validators.pattern(this.regexEntry.emailPattern)
			),
			Co_Applicant_Date_of_Birth: new UntypedFormControl(''),
			Secondary_Home_Number: new UntypedFormControl(''),
			Secondary_S_I_N: new UntypedFormControl(''),
			Secondary_Cell_Number: new UntypedFormControl(''),

			// CO-APPLICANT - EMERGENCY CONTACT
			Co_Applicant_emergency_contact_name: new UntypedFormControl(''),
			Co_Applicant_Emergency_contact_number2: new UntypedFormControl(''),
			Co_Applicant_emergency_contact_relation: new UntypedFormControl(''),
			Co_Applicant_emergency_contact_email: new UntypedFormControl(
				'',
				Validators.pattern(this.regexEntry.emailPattern)
			),

			// CO-APPLICANT - ADDRESS
			Co_Applicant_Property_Address: new UntypedFormControl(''),
			Co_Applicant_Residential_Status: new UntypedFormControl(''),
			Co_Applicant_Length_of_Residence: new UntypedFormControl(''),
			Co_Applicant_Address_Line_2_Unit_number: new UntypedFormControl(''),
			Co_Applicant_City: new UntypedFormControl(''),
			Co_Applicant_Province: new UntypedFormControl(''),
			Co_Applicant_Postal_Code: new UntypedFormControl(''),
			Co_Applicant_Country: new UntypedFormControl(''),

			// CO-APPLICANT - EMPLOYMENT
			Co_Applicant_Status: new UntypedFormControl(''),
			Co_Applicant_Industry: new UntypedFormControl(''),
			Co_Applicant_Employer: new UntypedFormControl(''),
			Co_Applicant_Occupation: new UntypedFormControl(''),
			Co_Applicant_Supervisor_Name: new UntypedFormControl(''),
			Secondary_Position: new UntypedFormControl(''),
			Sec_Annual_Income: new UntypedFormControl(''),
			Secondary_Years_Worked: new UntypedFormControl(''),
			Co_Applicant_Payment_Method: new UntypedFormControl(''),
			Co_Applicant_Supervisor_Phone_Number: new UntypedFormControl(''),
			Secondary_Work_Number: new UntypedFormControl(''),
			Co_Applicant_Pay_Frequency: new UntypedFormControl(''),

			// BANKING DETAILS
			Financial_Institution_Name: new UntypedFormControl(''),
			Co_Applicant_Financial_Institution_Name: new UntypedFormControl(''),
			Financial_Institution_Number: new UntypedFormControl(''),
			Co_ApplicantFinancial_Institution_Number1: new UntypedFormControl(''),
			Bank_Account_Number: new UntypedFormControl(''),
			Co_Applicant_Bank_Account: new UntypedFormControl(''),
			Bank_Transit_No: new UntypedFormControl(''),
			Co_Applicant_Bank_Transit_No: new UntypedFormControl(''),
			Branch_Address: new UntypedFormControl(''),
			Co_Applicant_Branch_Address: new UntypedFormControl(''),
			Stage: new UntypedFormControl('Incomplete Application'),
			App_Number: new UntypedFormControl(''),
			Owner: new UntypedFormControl(''),

			// FUNDED DETAILS
			Opportunity_Status: new UntypedFormControl(''),
			Term_of_Loan: new UntypedFormControl(''),
			Term_of_Loan_Years: new UntypedFormControl(''),
			Tertiary_Annual_Income: new UntypedFormControl(''),
			Total_Ob: new UntypedFormControl(''),
			Referral_Amount: new UntypedFormControl(''),
			LPP_Included: new UntypedFormControl(''),
			Credit_Upgrader_included: new UntypedFormControl(''),
			CU_Fee: new UntypedFormControl(''),
			Sch_Payment_Plus_CU_Fee: new UntypedFormControl(''),
			Loan_Date: new UntypedFormControl(''),
			Date_Ordered: new UntypedFormControl(''),
			Date_Received: new UntypedFormControl(''),
			Interest_APR1: new UntypedFormControl(''),
			Fee_Est: new UntypedFormControl(''),
			Click_n_loan: new UntypedFormControl(false),
			Deferral: new UntypedFormControl(''),
			Loan_Agreement_Created_by: new UntypedFormControl(''),

			// LOAN PROTECTION PLAN (LPP)
			LPP_Type: new UntypedFormControl(''),
			Loan_Protection: new UntypedFormControl(''),
			LPP_with_Scheduled_Payment: new UntypedFormControl(''),
			LPP_Sold_by: new UntypedFormControl(''),
			LPP_Total: new UntypedFormControl(''),
			LPP_Taxes: new UntypedFormControl(''),
			Debt_Total: new UntypedFormControl(''),
			Correct_Total_Obligation_with_LPP: new UntypedFormControl(''),

			// // Total additional debt
			// 'debtTotal': new FormControl({ value: '', disabled: true }),
			// 'isDebt': new FormControl(''),

			// 'isEmergency': new FormControl(''),

			// // Workdrive ZOHO
			// 'folderID': new FormControl({ value: '' }),
			// 'folderURL': new FormControl({ value: '' }),

			// // Record ID
			// 'recordID': new FormControl({ value: '' }),

			// // Local Server
			// 'filePath': new FormControl(''),
			// 'documentsUploaded': new FormControl(''),

			// // To keep track of the type of files uploaded
			// 'consentBox': new FormControl(false),
			// 'govtidBox': new FormControl(false),
			// 'bankBox': new FormControl(false),
			// 'bureauBox': new FormControl(false),

			// // Deal Stage Status
			// 'stage': new FormControl('Incomplete Application')
		});
	}

	getAdditionalSubForm(arr: any) {
		let additionalArray = this.controls.getFormArray(
			this.applicationForm,
			'Additional_Debt'
		);
		for (let i = 0; i < arr.length; i++) {
			additionalArray.push(
				this.fb.group({
					Agency: arr[i].Agency,
					misc: arr[i].misc,
					Creditor_Portfolio: arr[i].Creditor_Portfolio,
					Account_Number: arr[i].Account_Number,
					Collector: arr[i].Collector,
					Account_Balance: arr[i].Account_Balance,
					Type_of_Credit: arr[i].Type_of_Credit,
				})
			);
		}
		this.totalAdditional();
	}

	addAdditionalRows() {
		let additionalArray = this.controls.getFormArray(
			this.applicationForm,
			'Additional_Debt'
		);
		let newAdditional = this.fb.group({
			Agency: '',
			misc: '',
			Creditor_Portfolio: '',
			Account_Number: '',
			Collector: '',
			Account_Balance: '',
			Type_of_Credit: '',
		});

		additionalArray.push(newAdditional);
	}

	totalAdditional() {
		let debttotal = this.applicationForm
			.get('Additional_Debt')
			.value.map((item) => item.Account_Balance)
			.reduce((prev, curr) => prev + curr, 0);
		this.applicationForm.patchValue({
			Total: debttotal.toFixed(2),
		});
	}

	getEmergencySubForm(arr: any) {
		let emergencyArray = this.controls.getFormArray(
			this.applicationForm,
			'EMERGANCY_CONTACTS'
		);
		for (let i = 0; i < arr.length; i++) {
			emergencyArray.push(
				this.fb.group({
					Email: arr[i].Email,
					Name1: arr[i].Name1,
					Phone_Number: arr[i].Phone_Number,
					Relation_to_Applicant: arr[i].Relation_to_Applicant,
				})
			);
		}
	}

	addEmergencyRows() {
		let emergencyArray = this.controls.getFormArray(
			this.applicationForm,
			'EMERGANCY_CONTACTS'
		);
		let newEmergency = this.fb.group({
			Email: '',
			Name1: '',
			Phone_Number: '',
			Relation_to_Applicant: '',
		});

		emergencyArray.push(newEmergency);
	}
}
