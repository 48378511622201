import { splitName } from '@lmc-app/utils/names';
import { formatPhoneNumber } from '@lmc-app/utils/numbers';
export class Agreement {
    completeAgreement
    standardAgreement
    coappAgreementInfo
    LPPInfo
    zoho_info
    recipientInfo
    customFunctions
    insuranceType
    signing_agent
    signing_manager
    constructor(zoho_info, insuranceType, customFunctions, signing_agent, signing_manager) {
        this.signing_agent = signing_agent
        this.signing_manager = signing_manager
        this.insuranceType = insuranceType
        this.zoho_info = zoho_info
        this.customFunctions = customFunctions
        this.standardAgreement = {
            "RECEPIENTS": [
                {
                    isVisible: true,
                    name: "",
                    label: "Client",
                    value: this.zoho_info.Email1 || '',
                },
                {
                    isVisible: false,
                    name: "Co-Applicant Role",
                    label: "Co-Applicant",
                    value: this.zoho_info.Email || '',
                },
                {
                    isVisible: true,
                    name: "",
                    label: "Agent",
                    value: this.signing_agent.email || '',
                }
                ,
                // {
                //     isVisible: true,
                //     name: "",
                //     label: "Manager",
                //     value: this.signing_manager?.email || ''
                // }
            ],
            "AGREEMENT DETAILS": [
                {
                    isVisible: false,
                    name: "Deal.ReformattedDOB",
                    label: "ReformattedDOB",
                    value: this.zoho_info.Reformatted_DOB
                },
                {
                    isVisible: false,
                    name: "Deal.ReformattedLD",
                    label: "ReformattedLD",
                    value: this.zoho_info.Reformatted_LD
                },
                {
                    isVisible: false,
                    name: "Deal.ReformattedLPD",
                    label: "ReformattedLPD",
                    value: this.zoho_info.Reformatted_LPD
                },
                // Commented Document CreatedDate as it gets generated automatic on document creation from pandadocs
                // {
                //     isVisible: false,
                //     name: "Document.CreatedDate",
                //     label: "Agreement Created Date",
                //     value: new Date()
                // },
                {
                    isVisible: true,
                    name: "Deal.PotentialName",
                    label: "Application Name",
                    value: this.zoho_info.Deal_Name || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AppNumber",
                    label: "Application Number",
                    value: this.zoho_info.App_Number || ''
                },
                {
                    isVisible: false,
                    name: "Deal.Province",
                    label: "Province",
                    value: this.zoho_info.Province || ''
                },
                {
                    isVisible: true,
                    name: "",
                    label: "Has LPP?",
                    value: zoho_info.LPP_Included + " (" + insuranceType + ")" || ''
                },
                {
                    isVisible: false,
                    name: "",
                    label: "Has Co-App?",
                    value: zoho_info.Co_Appl_Required || ''
                },
                // {
                //     isVisible: true,
                //     name: "Deal.LoanProtection",
                //     label: "Loan Protection",
                //     value: this.zoho_info.Loan_Protection || ''
                // },
                {
                    isVisible: false,
                    name: "",
                    label: "Insurance Type",
                    value: insuranceType || ''
                },
                {
                    isVisible: false,
                    name: "Deal.TCsTemplateLine2",
                    label: "TCsTemplateLine 2",
                    value: this.zoho_info.T_Cs_template_line_2 || ''
                },

            ],
            "DEBTS": [
                {
                    isVisible: true,
                    name: "Deal.AgencyName1",
                    label: "Agency Name 1",
                    value: this.zoho_info.Agency_Name_1 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumber1",
                    label: "Account Number 1",
                    value: this.zoho_info.Account_Number_1 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance1",
                    label: "Account Balance 1",
                    value: this.zoho_info.Account_Balance_1 || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.AgencyName2",
                    label: "Agency Name 2",
                    value: this.zoho_info.Agency_Name_2 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumber2",
                    label: "Account Number 2",
                    value: this.zoho_info.Account_Number_2 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance2",
                    label: "Account Balance 2",
                    value: this.zoho_info.Account_Balance_2 || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.AgencyName3",
                    label: "Agency Name 3",
                    value: this.zoho_info.Agency_Name_3 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumber3",
                    label: "Account Number 3",
                    value: this.zoho_info.Account_Number_3 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance3",
                    label: "Account Balance 3",
                    value: this.zoho_info.Account_Balance_3 || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.AgencyName4",
                    label: "Agency Name 4",
                    value: this.zoho_info.Agency_Name_4 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumber4",
                    label: "Account Number 4",
                    value: this.zoho_info.Account_Number_4 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance4",
                    label: "Account Balance 4",
                    value: this.zoho_info.Account_Balance_4 || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.AgencyName5",
                    label: "Agency Name 5",
                    value: this.zoho_info.Agency_Name_5 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumber5",
                    label: "Account Number 5",
                    value: this.zoho_info.Account_Number_5 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance5",
                    label: "Account Balance 5",
                    value: this.zoho_info.Account_Balance_5 || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.AgencyName6",
                    label: "Agency Name 6",
                    value: this.zoho_info.Agency_Name_6 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumber6",
                    label: "Account Number 6",
                    value: this.zoho_info.Account_Number_6 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance6",
                    label: "Account Balance 6",
                    value: this.zoho_info.Account_Balance_6 || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.Agency",
                    label: "Agency",
                    value: this.zoho_info.Account_Name.name || ''
                },
                {
                    isVisible: true,
                    name: "Deal.Creditor",
                    label: "Creditor",
                    value: this.zoho_info.Creditt || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountNumberEDP",
                    label: "Account Number (EDP)",
                    value: this.zoho_info.Acc_Number || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AccountBalance",
                    label: "Account Balance",
                    value: this.zoho_info.Account_Bal || '',
                    type: 'currency'
                }
            ],
            "PRIMARY PERSONAL INFORMATION": [
                {
                    isVisible: false,
                    name: "Contact.FirstName",
                    label: "Full Name",
                    value: this.zoho_info.Deal_Name || ''
                },
                {
                    isVisible: false,
                    name: "Contact.LastName",
                    label: "Last Name",
                    value: ''
                },
                {
                    isVisible: true,
                    name: "Contact.Mobile",
                    label: "Mobile",
                    value: formatPhoneNumber(this.zoho_info.Mobile) || ''
                },
                {
                    isVisible: true,
                    name: "Deal.Phone",
                    label: "Phone",
                    value: formatPhoneNumber(this.zoho_info.Phone) || ''
                },
                {
                    isVisible: false,
                    name: "Deal.Mobile",
                    label: "Mobile",
                    value: formatPhoneNumber(this.zoho_info.Mobile) || ''
                },
                {
                    isVisible: true,
                    name: "Deal.Address",
                    label: "Address",
                    value: this.zoho_info.Address1 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.AddressLine2UnitNumber",
                    label: "Unit",
                    value: this.zoho_info.Address_Line_2 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.City",
                    label: "City",
                    value: this.zoho_info.City || ''
                },
                {
                    isVisible: true,
                    name: "Deal.Province",
                    label: "Province",
                    value: this.zoho_info.Province || ''
                },
                {
                    isVisible: true,
                    name: "Deal.PostalCode",
                    label: "Postal Code",
                    value: this.zoho_info.Postal_Code || ''
                },
                {
                    isVisible: true,
                    name: "Deal.DateOfBirth",
                    label: "Date of Birth",
                    value: this.zoho_info.Reformatted_DOB || ''
                },
                {
                    isVisible: true,
                    name: "Deal.Age",
                    label: "Age",
                    value: this.zoho_info.Age || ''
                }
            ],
            "PRIMARY BANKING DETAILS": [
                {
                    isVisible: true,
                    name: "Deal.BankAccountNumber",
                    label: "Bank Account Number",
                    value: this.zoho_info.Bank_Account_Number || ''
                },
                {
                    isVisible: true,
                    name: "Deal.BankTransitNo",
                    label: "Bank Transit No",
                    value: this.zoho_info.Bank_Transit_No || ''
                },
                {
                    isVisible: true,
                    name: "Deal.FinancialInstitutionNumber",
                    label: "Financial Institution Number",
                    value: this.zoho_info.Financial_Institution_Number || ''
                },
                {
                    isVisible: true,
                    name: "Deal.FinancialInstitutionName",
                    label: "Financial Institution Name",
                    value: this.zoho_info.Financial_Institution_Name || ''
                },
                {
                    isVisible: true,
                    name: "Deal.BranchAddress",
                    label: "Branch Address",
                    value: this.zoho_info.Branch_Address || ''
                }
            ],
            "FUNDED DETAILS": [
                {
                    isVisible: true,
                    name: "",
                    label: "Agreement Created By",
                    value: this.zoho_info.Loan_Agreement_Created_by || ''
                },
                {
                    isVisible: true,
                    name: "Deal.LoanDate",
                    label: "Loan Date",
                    value: this.zoho_info.Reformatted_LD || ''
                },
                {
                    isVisible: true,
                    name: "Deal.LastPaymentDate",
                    label: "Last Payment Date",
                    value: this.zoho_info.Reformatted_LPD || ''
                },
                {
                    isVisible: true,
                    name: "Deal.TermOfLoanMonths",
                    label: "Term of Loan (months)",
                    value: this.zoho_info.Term_of_Loan || ''
                },
                {
                    isVisible: true,
                    name: "Deal.TermOfLoanYears",
                    label: "Term of Loan (years)",
                    value: this.zoho_info.Term_of_Loan_Years || ''
                },
                {
                    isVisible: true,
                    name: "Deal.InterestAPR",
                    label: "Interest (APR)",
                    value: ('$' + this.zoho_info.Interest_APR1) || '',
                    type: 'percent'
                },
                {
                    isVisible: true,
                    name: "Deal.Amount",
                    label: "Amount",
                    value: this.zoho_info.Amount || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.ScheduledPaymentsCUFeeIncluded",
                    label: "Scheduled Payments (CU Fee Included)",
                    value: this.zoho_info.Tertiary_Annual_Income || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.PaymentFrequency",
                    label: "Payment Frequency",
                    value: this.zoho_info.Opportunity_Status || ''
                },
                {
                    isVisible: true,
                    name: "Deal.FirstPaymentDate",
                    label: "First Payment Date",
                    value: this.zoho_info.Reformatted_FPD || ''
                },
                {
                    isVisible: true,
                    name: "Deal.TotalCostOfBorrowing",
                    label: "Total Cost of Borrowing",
                    value: this.zoho_info.Referral_Amount || '',
                    type: 'currency'
                },
                {
                    isVisible: true,
                    name: "Deal.TotalObligationEntireTermFormula",
                    label: "Total Obligation Entire Term",
                    value: this.zoho_info.Total_Ob || ''
                },
                {
                    isVisible: true,
                    name: "Deal.SetupFee",
                    label: "Setup Fee",
                    value: this.zoho_info.Fee_Est || '',
                    type: 'currency'
                }
            ]
        }
        this.completeAgreement = this.standardAgreement
        this.recipientInfo = [
            {
                "email": this.zoho_info['Email1'] || '',
                "first_name": splitName(this.zoho_info['Deal_Name'])['firstname'] || '',
                "last_name": splitName(this.zoho_info['Deal_Name'])['lastname'] || '',
                "role": "Client",
                // "signing_order": 1
            },
            // {
            //     "email": this.signing_manager?.email,
            //     "first_name": splitName(this.signing_manager?.full_name)['firstname'],
            //     "last_name": splitName(this.signing_manager?.full_name)['lastname'],
            //     "role": "Manager",
            // },
            {
                "email": this.signing_agent.email,
                "first_name": splitName(this.signing_agent.full_name)['firstname'],
                "last_name": splitName(this.signing_agent.full_name)['lastname'],
                "role": "Agent",
                // "signing_order": 3
            }
        ]
    }
    addCoapp() {
        this.coappAgreementInfo = {
            "CO-APPLICANT PERSONAL INFORMATION": [
                {
                    isVisible: true,
                    name: "Deal.CoApplicantFirstName",
                    label: "First Name",
                    value: this.zoho_info.Co_Applicant_Name || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantLastName",
                    label: "Last Name",
                    value: this.zoho_info.Guarantor_Name || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantMobile",
                    label: "Mobile",
                    value: this.zoho_info.Secondary_Cell_Number || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantAddress",
                    label: "Address",
                    value: this.zoho_info.Co_Applicant_Property_Address || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantCity",
                    label: "City",
                    value: this.zoho_info.Co_Applicant_City || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantProvince",
                    label: "Province",
                    value: this.zoho_info.Co_Applicant_Province || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantPostalCode",
                    label: "Postal Code",
                    value: this.zoho_info.Co_Applicant_Postal_Code || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantAddressLine2UnitNumber",
                    label: "Address Line 2 (Unit number)",
                    value: this.zoho_info.Co_Applicant_Address_Line_2_Unit_number || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantHomeNumber",
                    label: "Home Number",
                    value: this.zoho_info.Secondary_Home_Number || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantDateOfBirth",
                    label: "Date of Birth",
                    value: this.zoho_info.R_co_app_dob || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoAppAge",
                    label: "Co-App age",
                    value: this.zoho_info.Co_App_age || ''
                },
                {
                    isVisible: false,
                    name: "Deal.RCoAppDob",
                    label: "Formatted Coapp DOB",
                    value: this.zoho_info.R_co_app_dob || ''
                }
            ],
            "CO-APPLICANT BANKING DETAILS": [
                {
                    isVisible: true,
                    name: "Deal.CoApplicantBankAccountNumber",
                    label: "Bank Account Number",
                    value: this.zoho_info.Co_Applicant_Bank_Account || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantBankTransitNo",
                    label: "Bank Transit No",
                    value: this.zoho_info.Co_Applicant_Bank_Transit_No || ''
                },
                {
                    isVisible: false,
                    name: "Deal.CoApplicantBranchTransitNumber",
                    label: "Bank Transit No",
                    value: this.zoho_info.Co_Applicant_Bank_Transit_No || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantFinancialInstitutionNumber",
                    label: "Financial Institution Number",
                    value: this.zoho_info.Co_ApplicantFinancial_Institution_Number1 || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantFinancialInstitutionName",
                    label: "Financial Institution Name",
                    value: this.zoho_info.Co_Applicant_Financial_Institution_Name || ''
                },
                {
                    isVisible: true,
                    name: "Deal.CoApplicantBranchAddress",
                    label: "Branch Address",
                    value: this.zoho_info.Co_Applicant_Branch_Address || ''
                },
            ]
        }
        this.completeAgreement = { ...this.completeAgreement, ...this.coappAgreementInfo }
        this.recipientInfo.push(
            {
                "email": this.zoho_info['Email'] || '',
                "first_name": this.zoho_info['Co_Applicant_Name'] || '',
                "last_name": this.zoho_info['Guarantor_Name'] || '',
                "role": "Co-Applicant",
                // "signing_order": 2
            }
        )
        let foundIndex = this.completeAgreement["RECEPIENTS"].findIndex(x => x.name == 'Co-Applicant Role');
        try {
            this.completeAgreement["RECEPIENTS"][foundIndex]['isVisible'] = true
        }
        catch (err) {
            console.log("UNABLE TO GET CO APPLICANT ROLE")
        }
    }
    addLPP() {
        this.completeAgreement["AGREEMENT DETAILS"].push(
            {
                isVisible: false,
                name: "Deal.TaxTemplateLine2",
                label: "Tax template line 2",
                value: this.zoho_info.Tax_template_line_2 || ''
            },
            {
                isVisible: false,
                name: "Deal.TaxTemplateLine",
                label: "Tax template line",
                value: this.zoho_info.Tax_template_line1 || ''
            },
            {
                isVisible: true,
                name: "Deal.LoanProtection",
                label: "Loan Protection",
                value: this.zoho_info.Loan_Protection || '',
                type: 'currency'
            },
            //high cost credit agreements?
        )
        this.completeAgreement["FUNDED DETAILS"].push(
            {
                isVisible: true,
                name: "Deal.TotalObligationForEntireTermWithLPP",
                label: "Total Obligation Entire Term with LPP",
                value: this.zoho_info.Debt_Total || '',
                type: 'currency'
            },
            {
                isVisible: true,
                name: "Deal.INS",
                label: "INS",
                value: this.zoho_info.LPP_Total || '',
                type: 'currency'
            },
            {
                isVisible: true,
                name: "Deal.LPPTaxes",
                label: "LPP Taxes",
                value: this.zoho_info.LPP_Taxes || '',
                type: 'currency'
            })
        this.LPPInfo = {
            isVisible: true,
            name: "Deal.LPPWithScheduledPayment",
            label: "Scheduled Payment with LPP",
            value: this.zoho_info.LPP_with_Scheduled_Payment || '',
            type: 'currency'
        }

        let foundIndex = this.completeAgreement["FUNDED DETAILS"].findIndex(x => x.name == 'Deal.ScheduledPaymentsCUFeeIncluded');
        try {
            this.completeAgreement["FUNDED DETAILS"][foundIndex] = this.LPPInfo
        }
        catch (err) {
            console.log("UNABLE TO GET SCHEDULED PAYMENT WITH LPP")
        }
        if (this.insuranceType == 'Enhanced Insurance') {
            //ADD ENHANCED INSURANCE VARIABLES
        }
    }

    getAgreementInfo = () => this.completeAgreement;
    getRecipientInfo = () => this.recipientInfo;
}

function formatDate(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var dateString =
        y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    return dateString;
}
