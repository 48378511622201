import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { GeneralObjects } from '@lmc-app/models/globalObjects';

@Component({
  selector: 'app-ach-validation',
  templateUrl: './ach-validation.component.html',
  styleUrls: ['./ach-validation.component.scss']
})
export class AchValidationComponent implements OnInit {

  constructor(private apiService: ApiServiceService) { }
  btnSpinner = false;
  btnShowText = true;

  start_date: any;
  end_date: any;
  selectedStatus = 0

  generalObjects = new GeneralObjects(); // To get the loan group no and loan group name
  selectedLoans: any;

  ACHValidation: any;
  sheetArray = [];    // Array for excel
  fileName = "ACH Validation Report";

  ngOnInit(): void {
  }

  startDate(e) {
    this.start_date = e;
  }

  endDate(e) {
    this.end_date = e;
  }

  getReportType(e) {
    this.selectedLoans = e?.['data'];
  }

  getCustomLoanGroups(e) {
    this.selectedLoans = e;
  }

  downloadACHValidation() {
    this.btnSpinner = true;
    this.btnShowText = false;
    this.apiService.getACHValidation(this.start_date, this.end_date, this.selectedStatus, this.selectedLoans).subscribe((res) => {
      this.ACHValidation = res;
      console.log(this.ACHValidation)
      this.sheetArray.push(this.ACHValidation?.[0]);
      this.btnSpinner = false;
      this.btnShowText = true;
    })
  }

}
