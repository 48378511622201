<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img
				title="refresh"
				src="/assets/images/refresh.png"
				width="30"
				height="30"
				(click)="refresh()"
			/>
		</h5>
	</div>
	<table class="table table-bordered" id="myTable">
		<thead>
			<tr>
				<th>Loan Number</th>
				<th>Name</th>
				<th>Agency</th>
				<th>Amount</th>
				<th>Funded Date</th>
				<th>Onboarding Date</th>
			</tr>
		</thead>
		<tbody id="myBody">
			<div *ngIf="!nlsFunded || nlsFunded.length === 0; else content">
				No NLS Onboarding!
			</div>
			<ng-template #content>
				<tr *ngFor="let item of nlsFunded">
					<td>{{ item.loan_number }}</td>
					<td>{{ item.name }}</td>
					<td>{{ item.AgencyName }}</td>
					<td>{{ item.original_note_amount | currency }}</td>
					<td>{{ item.funded_date | date : 'mediumDate' }}</td>
					<td>{{ item.transaction_date | date : 'medium' }}</td>
				</tr>
				<tr style="font-weight: bold">
					<td>Total</td>
					<td>{{ this.countNLS }}</td>
					<td>{{ this.totalNLSamount | currency }}</td>
				</tr>
			</ng-template>
		</tbody>
	</table>
</div>
