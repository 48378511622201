<button *ngIf="counter === 0" (click)="editToStartTimer()">
	Start Interview</button
>&nbsp;
<div *ngIf="counter != 0" style="display: inline-block">
	<button (click)="clearTimer()">Stop Interview</button>&nbsp;
</div>
<section style="float: right">
	<div *ngIf="counter; else elseBlock" class="timer-counter-label">
		{{ display_time }}
	</div>
	<ng-template #elseBlock>
		<div class="timer-counter-label">00:00:00</div>
	</ng-template>
</section>
