/*
 * Dev/Staging Specific modules
 * */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@lmc-env/environment';

export const extModules = [
	StoreDevtoolsModule.instrument({
		maxAge: 25,
		logOnly: environment.production, // Safe side ignoring logs in production if this is not replaced in angular.json
		autoPause: true,
	}),
];
