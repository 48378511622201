<nav class="app-nav-bar navbar fixed-top navbar-expand-lg navbar-light" *ngIf="!hideNavBar">
	<div class="container-fluid">
		<a class="navbar-brand" [routerLink]="['/dashboard']">
			<img src="/assets/images/lmcredit.png" alt="LM Credit Logo" height="20" />
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="!isExternalView">
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="dashboardDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						Dashboards
					</a>
					<ul class="dropdown-menu" aria-labelledby="dashboardDropdown">
						<li>
							<a class="dropdown-item" [routerLink]="['/salesdashboard']">Sales Dashboard</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/underwritingdashboard']">Underwriting
								Dashboard</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/underwritingexception']">Underwriting
								Exception</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlscollections']">NLS Dashboard</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/dealspipeline']">Pipeline</a>
						</li>
					</ul>
				</li>
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="fundingReportsDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						Reports
					</a>
					<ul class="dropdown-menu" aria-labelledby="fundingReportsDropdown">
						<li>
							<a class="dropdown-item" [routerLink]="['/nlsdashboard']">Today's Funding</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlsagencyfunded']">Funding Report</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlsagencycollections']">Agency</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlsloansizecollections']">Loan Size</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlslocationcollections']">Location</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlsportfoliocollections']">Portfolio</a>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/nlsexception']">Exception</a>
						</li>
						<li class="dropdown-submenu">
							<a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
								href="#">Collections</a>
							<ul class="dropdown-menu">
								<li>
									<a class="dropdown-item"
										[routerLink]="['/reports/collections/payments-received']">Payments
										Received</a>
								</li>
							</ul>
						</li>
						<li>
							<a class="dropdown-item" [routerLink]="['/uw-performance']">UW Performance</a>
						</li>
					</ul>
				</li>
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="collectionReportsDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						Collections
					</a>
					<ul class="dropdown-menu" aria-labelledby="collectionReportsDropdown">
						<li>
							<a class="dropdown-item" *ngIf="collectionManagerReportComponentFlag"
								[routerLink]="['/reports']">Manager Reports</a>
						</li>
						<li>
							<a class="dropdown-item" *ngIf="collectionListComponentFlag || userRoleID === 7"
								[routerLink]="['/collection-list']">My List</a>
						</li>
					</ul>
				</li>
				<li class="nav-item dropdown" *ngIf="ptpFinanceFlag">
					<a class="nav-link dropdown-toggle" href="#" id="loanServicingDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						Loan Servicing
					</a>
					<ul class="dropdown-menu" aria-labelledby="loanServicingDropdown">
						<li>
							<a class="dropdown-item" [routerLink]="['/promise-to-pays']">Promise to Pays</a>
						</li>
						<li class="dropdown-submenu" *ngIf="loanServicingReportsFlag">
							<a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
								href="#">Reports</a>
							<ul class="dropdown-menu">
								<li>
									<a class="dropdown-item"
										[routerLink]="['/loan-servicing/reports/account-informations']">Account
										Informations</a>
								</li>
								<li>
									<a class="dropdown-item"
										[routerLink]="['/loan-servicing/reports/payment-breakdowns']">Payment
										Breakdowns</a>
								</li>
								<li>
									<a class="dropdown-item"
										[routerLink]="['/loan-servicing/reports/sanity-checks']">Sanity
										Checks</a>
								</li>
								<li>
									<a class="dropdown-item"
										[routerLink]="['/loan-servicing/reports/lpp-reports']">LPP</a>
								</li>
							</ul>
						</li>
						<li *ngIf="insuranceCancellationFlag">
							<a class="dropdown-item" [routerLink]="['/loan-servicing/insurance-cancellaction']">INS
								Cancellation</a>
						</li>
					</ul>
				</li>

				<li class="nav-item dropdown" *ngIf="adminDashboardFlag">
					<a class="nav-link dropdown-toggle" href="#" id="adminSettingDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						Admin Dashboard
					</a>
					<ul class="dropdown-menu" aria-labelledby="adminSettingDropdown">
						<li>
							<a class="dropdown-item" [routerLink]="['/admin-settings']">Admin Settings</a>
						</li>
					</ul>
				</li>
			</ul>
			<ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="isExternalView">
				{{fullName}}
			</ul>
			<div *ngIf="!isExternalView">
				<form class="d-flex search-container">
					<div class="input-group">
						<input class="form-control" type="search" placeholder="Search" aria-label="Search"
							id="search-bar-input" name="search-input" (keyup)="onSearchKeywordChange($event)"
							(keyup.enter)="onSearchClicked()" [(ngModel)]="searchKeyword" />
						<button class="btn btn-outline-secondary" type="submit" (click)="onSearchClicked()">
							<i class="fa fa-search" aria-hidden="true"></i>
						</button>
					</div>
				</form>
			</div>

			<div class="me-2" id="logout">
				<a class="logout" [routerLink]="['/login']" (click)="logout()">Logout</a>
				<span style="float: right"> &nbsp; | &nbsp;</span>
				<select class="history-select" aria-label="Default select example" [(ngModel)]="currentRoute"
					(change)="routeTo(currentRoute)">
					<i class="fa fa-home"></i>
					<option [value]="" disabled selected>-Recent Items-</option>
					<option *ngFor="let item of pageHistoryArray" [value]="item.summary_link">
						{{ item.deal_name }} - {{ item.opened_date | date : 'medium' }}
					</option>
				</select>
			</div>

			<div style="
					display: flex;
					justify-content: right;
					color: black;
					font-size: 14px;
				" class="me-1">
				{{ appVersion }}
			</div>
		</div>
	</div>
</nav>