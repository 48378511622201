import { DatePipe } from '@angular/common';
import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spv-custom-account-information',
  templateUrl: './spv-custom-account-information.component.html',
  styleUrls: ['./spv-custom-account-information.component.scss']
})
export class SpvCustomAccountInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  dropdownList = [];
  LoanGroups: any;
  PaymentBreakdown_Report: any;
  spvDataSubscription: Subscription;
  loanGroupDataSubscription: Subscription;

  //_Hist
  getRowStyle_Hist: any
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public gridOptions_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  newList: any[] = [];

  btnSpinner = false;
  btnShowText = true;
  selectedStatus = 2    // 'All Statuses'
  start_date: any;
  end_date: any;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    //Payment History
    this.columnDefs_Hist = [
      { headerName: 'Loan Group', field: 'loanGroup', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Principal', field: 'principal', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Interest', field: 'interest', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Fees', field: 'fees', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Late Charges', field: 'lateCharges', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Insurance Premium', field: 'insurancePremium', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Insurance Tax', field: 'insuranceTax', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'UDBs', field: 'udbs', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Payment Amount', field: 'paymentAmount', sortable: true, filter: "agNumberColumnFilter" }
    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'spv_account_vintage_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'spv_account_vintage_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.loanGroupDataSubscription = this.apiService.getLoanGroups().subscribe((res) => {
      this.LoanGroups = res;
      this.dropdownList = this.LoanGroups?.filter(item => item.loan_group?.includes('SPV'));;
    })
  }

  startDate(e) {
    this.start_date = e;
  }
  endDate(e) {
    this.end_date = e;
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.newList);
    this.rowData_Hist = this.newList
  }

  downloadPaymentBreakdown() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else {
      this.btnSpinner = true;
      this.btnShowText = false;
      this.PaymentBreakdown_Report = [];
      this.rowData_Hist = []
      this.newList = [];

      this.spvDataSubscription = this.apiService.getSPVpaymentBreakdown(this.start_date, this.end_date, this.selectedStatus, this.dropdownList).subscribe((res) => {
        this.PaymentBreakdown_Report = res
        console.log(this.PaymentBreakdown_Report, "this.PaymentBreakdown_Report")
        let loanGroupList = filterDuplicates(this.PaymentBreakdown_Report, 'loan_group_desc').sort();

        for (let i = 0; i < loanGroupList.length; i++) {
          let principal = 0;
          let interest = 0;
          let fees = 0;
          let lateCharges = 0;
          let insurancePremium = 0;
          let insuranceTax = 0;
          let udbs = 0;
          let paymentAmount = 0;
          principal = filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Principal');
          interest = filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Interest');
          fees = filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Fees');
          lateCharges = parseFloat(filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Late Charges'));
          insurancePremium = filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Insurance Premium');
          insuranceTax = filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Ins tax');
          paymentAmount = filterAddFields(this.PaymentBreakdown_Report, 'loan_group_desc', loanGroupList?.[i], 'Payment Amount');

          this.newList.push(new summaryReport(loanGroupList[i], formatResult(principal), formatResult(interest), formatResult(fees), formatResult(lateCharges), formatResult(insurancePremium), formatResult(insuranceTax), formatResult(udbs), formatResult(paymentAmount)))
        }
        this.gridApi_Hist.setRowData(this.newList);
        this.rowData_Hist = this.newList;
        this.btnSpinner = false;
        this.btnShowText = true;
      })
    }
  }

  ngOnDestroy() {
    this.loanGroupDataSubscription?.unsubscribe();
    this.spvDataSubscription?.unsubscribe();
  }
}

function filterDuplicates(arr: any, fieldName: any) {
  return arr.map(item => item?.[fieldName])?.filter((value, index, self) => self?.indexOf(value) === index);
}

function filterAddFields(arr: any, loanGroupDesc: any, loanGroupList: any, fieldName: any) {
  return arr?.filter(item => item?.[loanGroupDesc] === loanGroupList)?.map(item => item?.[fieldName])?.reduce((prev, next) => parseFloat(prev) + parseFloat(next));
}

function summaryReport(loanGroup: any, principal: any, interest: any, fees: any, lateCharges: any, insurancePremium: any, insuranceTax: any, udbs: any, paymentAmount: any) {
  this.loanGroup = loanGroup;
  this.principal = principal;
  this.interest = interest;
  this.fees = fees;
  this.lateCharges = lateCharges;
  this.insurancePremium = insurancePremium;
  this.insuranceTax = insuranceTax;
  this.udbs = udbs;
  this.paymentAmount = paymentAmount;
}
function formatResult(num) {
  return (isNaN(num) ? '0.00' : num?.toFixed(2))
}
