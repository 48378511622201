<div class="container-fluid">
	<div class="row row-cols-12">
		<h5>
			{{ timestamp | date : 'medium'
			}}<img
				title="refresh"
				src="/assets/images/refresh.png"
				width="30"
				height="30"
				(click)="refresh()"
			/>
		</h5>
	</div>

	<table class="table table-bordered" id="myTable">
		<thead>
			<tr>
				<th>Name</th>
				<th>Province</th>
				<th>Modified Time</th>
				<th>Agency Name</th>
			</tr>
		</thead>
		<tbody id="myBody">
			<div
				*ngIf="
					!loanInterviewsArray || loanInterviewsArray.length === 0;
					else contentLT0
				"
			>
				No Ready for loan interview!
			</div>
			<ng-template #contentLT0>
				<tr
					*ngFor="
						let item of loanInterviewsArray
							| paginate
								: { id: 'pagination0', itemsPerPage: count, currentPage: p }
					"
				>
					<td>
						<a
							class="noline"
							target="_self"
							[routerLink]="['/applications/summary', item.id]"
							[queryParams]="{ dealname: item.Deal_Name }"
							>{{ item.Deal_Name }}</a
						>
					</td>
					<td>{{ item.Province }}</td>
					<td>{{ item.Modified_Time | date : 'medium' }}</td>
					<td>
						{{ item.Agency_Formula }} - {{ item.Collector_Formula }} |
						{{ item['Created_By.email'].split('@')[0] }}
					</td>
				</tr>
			</ng-template>
		</tbody>
	</table>
	<div id="paginate" class="text-right">
		<pagination-controls
			id="pagination0"
			(pageChange)="p = $event"
		></pagination-controls>
	</div>
</div>
