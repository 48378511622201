<div class="container-fluid">
	<h1>PTP Report Comparison Tool</h1>
	<div class="row row-cols-12">
		<h3>PTP Report 1</h3>
		<div class="col-2">
			<label class="form-label">Start Date</label>
			<input type="date" class="form-control" [(ngModel)]="startDate" />
		</div>
		<div class="col-2">
			<label class="form-label">End Date</label>
			<input type="date" class="form-control" [(ngModel)]="endDate" />
		</div>
		<div class="col-1">
			<br />
			<button
				class="btn btn-success"
				(click)="getPtpReport(true, startDate, endDate)"
				[disabled]="!startDate || !endDate"
			>
				Submit
			</button>
		</div>
		<div class="col-1">
			<br />
			<button
				class="btn btn-secondary"
				(click)="exportToExcel(startDate, endDate)"
				*ngIf="ptpList"
			>
				Export
			</button>
		</div>
	</div>
	<div class="row row-cols-12" *ngIf="ptpList">
		<div class="col-12">
			<table #ptpTable class="table table-bordered table-hover ptp-table">
				<thead>
					<tr>
						<th>Created By</th>
						<th>Total PTP Paid</th>
						<th>Total PTP Paid Count</th>
						<th>Total PTP NSF</th>
						<th>Total PTP NSF Count</th>
						<th>Total PTP Broken</th>
						<th>Total PTP Broken Count</th>
						<th>All PTPs</th>
						<th>All PTPs Count</th>
						<th>PTP Conversion Rate</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of ptpList">
						<td>{{ item['created_by'] }}</td>
						<td>{{ item['Total PTP Paid ($)']?.toFixed(2) }}</td>
						<td>{{ item['Total PTP Paid Count'] }}</td>
						<td>{{ item['Total PTP NSF ($)']?.toFixed(2) }}</td>
						<td>{{ item['Total PTP NSF Count'] }}</td>
						<td>{{ item['Total PTP Broken ($)']?.toFixed(2) }}</td>
						<td>{{ item['Total PTP Broken Count'] }}</td>
						<td>{{ item['All PTPs ($)']?.toFixed(2) }}</td>
						<td>{{ item['All PTPs Count'] }}</td>
						<td>{{ item['PTP Conversion Rate']?.toFixed(2) }}</td>
					</tr>
					<tr>
						<td style="font-weight: bold">Totals</td>
						<td>{{ ptpTotals['totalPTPPaid']?.toFixed(2) }}</td>
						<td>{{ ptpTotals['totalPTPPaidCount'] }}</td>
						<td>{{ ptpTotals['totalPTPNSF']?.toFixed(2) }}</td>
						<td>{{ ptpTotals['totalPTPNSFCount'] }}</td>
						<td>{{ ptpTotals['totalPTPBroken']?.toFixed(2) }}</td>
						<td>{{ ptpTotals['totalPTPBrokenCount'] }}</td>
						<td>{{ ptpTotals['totalAllPTPs']?.toFixed(2) }}</td>
						<td>{{ ptpTotals['totalAllPTPsCount'] }}</td>
						<td></td>
					</tr>
				</tbody>
			</table>
			<table
				#ptpDetailedTable
				style="display: none"
				class="table table-bordered table-hover ptp-table"
			>
				<thead>
					<tr>
						<th>Parent ID</th>
						<th>Payment Amount</th>
						<th>Payment Paid</th>
						<th>Payment Remaining</th>
						<th>Comments</th>
						<th>PTP Date</th>
						<th>Account Reference No</th>
						<th>Invalid</th>
						<th>Paid</th>
						<th>NLS Data</th>
						<th>Created At</th>
						<th>Created By</th>
						<th>Empty Parent</th>
						<th>Completed</th>
						<th>Loan Number</th>
						<th>Name</th>
						<th>Status</th>
						<th>Payment Type</th>
						<th>Adjusted</th>
						<th>Modified By</th>
						<th>Broken PTP</th>
						<th>Cancelled By</th>
						<th>Deleted By</th>
						<th>Applied By</th>
						<th>Cancelled At</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of ptpDetailedList">
						<td>{{ item['parent_id'] }}</td>
						<td>{{ item['payment_amount']?.toFixed(2) }}</td>
						<td>{{ item['payment_paid']?.toFixed(2) }}</td>
						<td>{{ item['payment_remaining']?.toFixed(2) }}</td>
						<td>{{ item['comments'] }}</td>
						<td>{{ item['PtP_Date'] }}</td>
						<td>{{ item['acctrefno'] }}</td>
						<td>{{ item['Invalid'] }}</td>
						<td>{{ item['Paid'] }}</td>
						<td>{{ item['nls_data'] }}</td>
						<td>{{ item['created_at'] }}</td>
						<td>{{ item['created_by'] }}</td>
						<td>{{ item['emptyParent'] }}</td>
						<td>{{ item['completed'] }}</td>
						<td>{{ item['loan_number'] }}</td>
						<td>{{ item['name'] }}</td>
						<td>{{ item['status'] }}</td>
						<td>{{ item['payment_type'] }}</td>
						<td>{{ item['adjusted'] }}</td>
						<td>{{ item['modified_by'] }}</td>
						<td>{{ item['broken_ptp'] }}</td>
						<td>{{ item['cancelled_by'] }}</td>
						<td>{{ item['deleted_by'] }}</td>
						<td>{{ item['applied_by'] }}</td>
						<td>{{ item['cancelled_at'] }}</td>
					</tr>
				</tbody>
			</table>
			<table
				#ptpLoanGroup
				style="display: none"
				class="table table-bordered table-hover ptp-table"
			>
				<thead>
					<tr>
						<th>Payment Type</th>
						<th>Status</th>
						<th>Payment Amount</th>
						<th>Payment Paid</th>
						<th>Broken PTP</th>
						<th>Loan Number</th>
						<th>Loan Group</th>
						<th>Created By</th>
						<th>Created At</th>
						<th>PTP Date</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of ptpLoanGroupList">
						<td>{{ item['payment_type'] }}</td>
						<td>{{ item['status'] }}</td>
						<td>{{ item['payment_amount']?.toFixed(2) }}</td>
						<td>{{ item['payment_paid']?.toFixed(2) }}</td>
						<td>{{ item['broken_ptp'] }}</td>
						<td>{{ item['loan_number'] }}</td>
						<td>{{ item['loan_group'] }}</td>
						<td>{{ item['created_by'] }}</td>
						<td>{{ item['created_at'] }}</td>
						<td>{{ item['PtP_Date'] }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<div class="container-fluid">
	<div class="row row-cols-12">
		<h3>PTP Report 2</h3>
		<div class="col-2">
			<label class="form-label">Start Date</label>
			<input type="date" class="form-control" [(ngModel)]="prevWeekStartDate" />
		</div>
		<div class="col-2">
			<label class="form-label">End Date</label>
			<input type="date" class="form-control" [(ngModel)]="prevWeekEndDate" />
		</div>
		<div class="col-1">
			<br />
			<button
				class="btn btn-success"
				(click)="getPtpReport(true, prevWeekStartDate, prevWeekEndDate)"
				[disabled]="!prevWeekStartDate || !prevWeekEndDate"
			>
				Submit
			</button>
		</div>
		<div class="col-1">
			<br />
			<button
				class="btn btn-secondary"
				(click)="exportToExcel(prevWeekStartDate, prevWeekEndDate)"
				*ngIf="ptpList"
			>
				Export
			</button>
		</div>
	</div>
	<div class="row row-cols-12" *ngIf="prevWeekptpList">
		<div class="col-12">
			<table #ptpTable class="table table-bordered table-hover ptp-table">
				<thead>
					<tr>
						<th>Created By</th>
						<th>Total PTP Paid</th>
						<th>Total PTP Paid Count</th>
						<th>Total PTP NSF</th>
						<th>Total PTP NSF Count</th>
						<th>Total PTP Broken</th>
						<th>Total PTP Broken Count</th>
						<th>All PTPs</th>
						<th>All PTPs Count</th>
						<th>PTP Conversion Rate</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of prevWeekptpList">
						<td>{{ item['created_by'] }}</td>
						<td>{{ item['Total PTP Paid ($)']?.toFixed(2) }}</td>
						<td>{{ item['Total PTP Paid Count'] }}</td>
						<td>{{ item['Total PTP NSF ($)']?.toFixed(2) }}</td>
						<td>{{ item['Total PTP NSF Count'] }}</td>
						<td>{{ item['Total PTP Broken ($)']?.toFixed(2) }}</td>
						<td>{{ item['Total PTP Broken Count'] }}</td>
						<td>{{ item['All PTPs ($)']?.toFixed(2) }}</td>
						<td>{{ item['All PTPs Count'] }}</td>
						<td>{{ item['PTP Conversion Rate']?.toFixed(2) }}</td>
					</tr>
					<tr>
						<td style="font-weight: bold">Totals</td>
						<td>{{ prevWeekptpTotals['totalPTPPaid']?.toFixed(2) }}</td>
						<td>{{ prevWeekptpTotals['totalPTPPaidCount'] }}</td>
						<td>{{ prevWeekptpTotals['totalPTPNSF']?.toFixed(2) }}</td>
						<td>{{ prevWeekptpTotals['totalPTPNSFCount'] }}</td>
						<td>{{ prevWeekptpTotals['totalPTPBroken']?.toFixed(2) }}</td>
						<td>{{ prevWeekptpTotals['totalPTPBrokenCount'] }}</td>
						<td>{{ prevWeekptpTotals['totalAllPTPs']?.toFixed(2) }}</td>
						<td>{{ prevWeekptpTotals['totalAllPTPsCount'] }}</td>
						<td></td>
					</tr>
				</tbody>
			</table>
			<table
				#ptpDetailedTable
				style="display: none"
				class="table table-bordered table-hover ptp-table"
			>
				<thead>
					<tr>
						<th>Parent ID</th>
						<th>Payment Amount</th>
						<th>Payment Paid</th>
						<th>Payment Remaining</th>
						<th>Comments</th>
						<th>PTP Date</th>
						<th>Account Reference No</th>
						<th>Invalid</th>
						<th>Paid</th>
						<th>NLS Data</th>
						<th>Created At</th>
						<th>Created By</th>
						<th>Empty Parent</th>
						<th>Completed</th>
						<th>Loan Number</th>
						<th>Name</th>
						<th>Status</th>
						<th>Payment Type</th>
						<th>Adjusted</th>
						<th>Modified By</th>
						<th>Broken PTP</th>
						<th>Cancelled By</th>
						<th>Deleted By</th>
						<th>Applied By</th>
						<th>Cancelled At</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of prevWeekptpDetailedList">
						<td>{{ item['parent_id'] }}</td>
						<td>{{ item['payment_amount']?.toFixed(2) }}</td>
						<td>{{ item['payment_paid']?.toFixed(2) }}</td>
						<td>{{ item['payment_remaining']?.toFixed(2) }}</td>
						<td>{{ item['comments'] }}</td>
						<td>{{ item['PtP_Date'] }}</td>
						<td>{{ item['acctrefno'] }}</td>
						<td>{{ item['Invalid'] }}</td>
						<td>{{ item['Paid'] }}</td>
						<td>{{ item['nls_data'] }}</td>
						<td>{{ item['created_at'] }}</td>
						<td>{{ item['created_by'] }}</td>
						<td>{{ item['emptyParent'] }}</td>
						<td>{{ item['completed'] }}</td>
						<td>{{ item['loan_number'] }}</td>
						<td>{{ item['name'] }}</td>
						<td>{{ item['status'] }}</td>
						<td>{{ item['payment_type'] }}</td>
						<td>{{ item['adjusted'] }}</td>
						<td>{{ item['modified_by'] }}</td>
						<td>{{ item['broken_ptp'] }}</td>
						<td>{{ item['cancelled_by'] }}</td>
						<td>{{ item['deleted_by'] }}</td>
						<td>{{ item['applied_by'] }}</td>
						<td>{{ item['cancelled_at'] }}</td>
					</tr>
				</tbody>
			</table>
			<table
				#ptpLoanGroup
				style="display: none"
				class="table table-bordered table-hover ptp-table"
			>
				<thead>
					<tr>
						<th>Payment Type</th>
						<th>Status</th>
						<th>Payment Amount</th>
						<th>Payment Paid</th>
						<th>Broken PTP</th>
						<th>Loan Number</th>
						<th>Loan Group</th>
						<th>Created By</th>
						<th>Created At</th>
						<th>PTP Date</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of prevWeekptpLoanGroupList">
						<td>{{ item['payment_type'] }}</td>
						<td>{{ item['status'] }}</td>
						<td>{{ item['payment_amount']?.toFixed(2) }}</td>
						<td>{{ item['payment_paid']?.toFixed(2) }}</td>
						<td>{{ item['broken_ptp'] }}</td>
						<td>{{ item['loan_number'] }}</td>
						<td>{{ item['loan_group'] }}</td>
						<td>{{ item['created_by'] }}</td>
						<td>{{ item['created_at'] }}</td>
						<td>{{ item['PtP_Date'] }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
