import {
	Component,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { ApiServiceService } from '../../../../api-service.service';
import { concatMap, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { formatCurrency } from '@angular/common';

@Component({
	selector: 'app-reports-dashboard',
	templateUrl: './reports-dashboard.component.html',
	styleUrls: ['./reports-dashboard.component.scss'],
})
export class ReportsDashboardComponent implements OnInit, OnChanges {
	@ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

	public balanceTypes = {
		TOTAL_PAST_DUE: 'TOTAL_PAST_DUE',
		CURRENT_PRINCIPAL: 'CURRENT_PRINCIPAL',
	};
	public loanGroupSelected = 'All';
	public delinquencyPastDue: any;
	public delinquencyPastDueTotal: any;
	public delinquencyCurrentPrincipal: any;
	public insolvencyTotalBK: any;
	public insolvencyTotalCP: any;
	public insolvencyTotalCC: any;
	public delinquencyCurrentPrincipalTotal: any;
	public delinquencyPastDueRatio: any;
	public delinquencyPastDueTotalRatio: any;
	public delinquencyCurrentPrincipalRatio: any;
	public insolvencyTotalBKRatio: any;
	public insolvencyTotalCPRatio: any;
	public insolvencyTotalCCRatio: any;

	public pieChartBuckets = [
		{
			start: 1,
			end: 30,
			label: '',
			percent: 0,
		},
		{
			start: 31,
			end: 60,
			label: '',
			percent: 0,
		},
		{
			start: 61,
			end: 90,
			label: '',
			percent: 0,
		},
		{
			start: 91,
			end: 120,
			label: '',
			percent: 0,
		},
		{
			start: 121,
			end: 360,
			label: '',
			percent: 0,
		},
		{
			start: 361,
			end: null,
			label: '',
			percent: 0,
		},
	];

	public pieChartOptions: ChartConfiguration<
		'pie',
		number[],
		string | string[]
	>['options'] = {
		responsive: false,
		maintainAspectRatio: false,
		plugins: {
			title: {
				display: true,
				text: 'Bucket Distribution of Collections',
				align: 'start',
				color: '#7AA2D5',
				font: {
					weight: 'bold',
					size: 18,
				},
			},
			legend: {
				display: true,
				position: 'left',
			},
			datalabels: {
				anchor: 'end',
				align: 'end',
				offset: 20,
				formatter: (value, ctx) => {
					if (ctx.chart.data.labels) {
						return `${
							ctx.chart.data.labels[ctx.dataIndex].toString().split('\n')[0]
						}\n(${ctx.chart.data?.datasets?.[0].data?.[ctx.dataIndex]}%)`;
					}
				},
			},
		},
	};
	public pieChartData: ChartData<'pie', number[], string | string[]> = {
		labels: [],
		datasets: [
			{
				data: [],
			},
		],
	};
	public pieChartType: ChartConfiguration<
		'pie',
		number[],
		string | string[]
	>['type'] = 'pie';
	public pieChartPlugins = [DatalabelsPlugin];

	constructor(private apiService: ApiServiceService) {}

	ngOnInit() {
		this.pieChartBuckets.forEach(
			(val) => (val.label = `${val.start}-${val.end ? val.end : '∞'}`)
		);
		this.refreshDashboardData();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.loanGroupSelected) {
			this.refreshDashboardData();
		}
	}

	public refreshDashboardData(): void {
		this.refreshPieChartData().then(() => {
			this.chart?.update();
		});
		this.refreshDelinquencyCard();
		this.refreshInsolvenciesCard();
	}
	public async refreshPieChartData() {
		this.resetPieChart();
		await this.apiService
			.getReportsDashboardDelinquencyRatio(
				this.balanceTypes.CURRENT_PRINCIPAL,
				null,
				null,
				this.loanGroupSelected
			)
			.subscribe((res: any) => {
				const total = res?.data?.[0]?.current_principal_balance;
				from(this.pieChartBuckets)
					.pipe(
						concatMap((bucket) => {
							return this.apiService.getReportsDashboardDelinquencyRatio(
								this.balanceTypes.TOTAL_PAST_DUE,
								bucket?.start,
								bucket?.end,
								this.loanGroupSelected
							);
						})
					)
					.subscribe((res: any) => {
						const delinquencyAmount =
							res?.data?.[0]?.[`total_past_due_balance`];
						const delinquencyRatio = (delinquencyAmount * 100) / (total || 1);
						this.pieChartData.labels.push(
							`${res.start}-${res.end ? res.end : '∞'}\n${formatCurrency(
								delinquencyAmount,
								'en-CA',
								'$',
								'CAD'
							)}`
						);
						this.pieChartData.datasets?.[0]?.data.push(
							Number(delinquencyRatio?.toFixed(1))
						);
						if (
							this.pieChartData.labels.length &&
							this.pieChartData.labels.length === this.pieChartBuckets.length
						) {
							this.chart?.update();
						}
						console.log('Res', res, delinquencyAmount, delinquencyRatio);
					});
			});
	}
	public refreshDelinquencyCard(): void {
		this.apiService
			.getReportsDashboardDelinquencyRatio(
				this.balanceTypes.TOTAL_PAST_DUE,
				1,
				90,
				this.loanGroupSelected
			)
			.pipe(
				mergeMap((res: any) => {
					this.delinquencyPastDue = res?.data?.[0]?.[`total_past_due_balance`];
					console.log(
						'getReportsDashboardDelinquencyRatioFirst',
						res?.data?.[0]?.[`total_past_due_balance`]
					);
					return this.apiService.getReportsDashboardDelinquencyRatio(
						this.balanceTypes.TOTAL_PAST_DUE,
						1,
						null,
						this.loanGroupSelected
					);
				})
			)
			.pipe(
				mergeMap((res: any) => {
					this.delinquencyPastDueTotal =
						res?.data?.[0]?.[`total_past_due_balance`];
					console.log(
						'getReportsDashboardDelinquencyRatioFirst',
						res?.data?.[0]?.[`total_past_due_balance`]
					);
					return this.apiService.getReportsDashboardDelinquencyRatio(
						this.balanceTypes.CURRENT_PRINCIPAL,
						90,
						null,
						this.loanGroupSelected
					);
				})
			)
			.pipe(
				mergeMap((res: any) => {
					this.delinquencyCurrentPrincipal =
						res?.data?.[0]?.[`current_principal_balance`];
					console.log(
						'getReportsDashboardDelinquencyRatioSecond',
						res?.data?.[0]?.[`current_principal_balance`]
					);
					return this.apiService.getReportsDashboardDelinquencyRatio(
						this.balanceTypes.CURRENT_PRINCIPAL,
						null,
						null,
						this.loanGroupSelected
					);
				})
			)
			.subscribe((res: any) => {
				this.delinquencyCurrentPrincipalTotal =
					res?.data?.[0]?.current_principal_balance || 1;
				console.log(
					'getReportsDashboardDelinquencyRatioTotal',
					res?.data?.[0]?.current_principal_balance
				);
				if (this.delinquencyCurrentPrincipalTotal) {
					this.delinquencyPastDueRatio = (
						(this.delinquencyPastDue * 100) /
						this.delinquencyCurrentPrincipalTotal
					)?.toFixed(2);
					this.delinquencyPastDueTotalRatio = (
						(this.delinquencyPastDueTotal * 100) /
						this.delinquencyCurrentPrincipalTotal
					)?.toFixed(2);
					this.delinquencyCurrentPrincipalRatio = (
						(this.delinquencyCurrentPrincipal * 100) /
						this.delinquencyCurrentPrincipalTotal
					)?.toFixed(2);
					console.log('delinquencyPastDueRatio', this.delinquencyPastDueRatio);
					console.log(
						'delinquencyCurrentPrincipalRatio',
						this.delinquencyCurrentPrincipalRatio
					);
				}
			});
	}
	public refreshInsolvenciesCard(): void {
		this.apiService
			.getReportsDashboardDelinquencyRatio(
				this.balanceTypes.CURRENT_PRINCIPAL,
				null,
				null,
				this.loanGroupSelected,
				'BK'
			)
			.pipe(
				mergeMap((res: any) => {
					this.insolvencyTotalBK =
						res?.data?.[0]?.[`current_principal_balance`];
					return this.apiService.getReportsDashboardDelinquencyRatio(
						this.balanceTypes.CURRENT_PRINCIPAL,
						null,
						null,
						this.loanGroupSelected,
						'CP'
					);
				})
			)
			.pipe(
				mergeMap((res: any) => {
					this.insolvencyTotalCP =
						res?.data?.[0]?.[`current_principal_balance`];
					return this.apiService.getReportsDashboardDelinquencyRatio(
						this.balanceTypes.CURRENT_PRINCIPAL,
						null,
						null,
						this.loanGroupSelected,
						'CC'
					);
				})
			)
			.pipe(
				mergeMap((res: any) => {
					this.insolvencyTotalCC =
						res?.data?.[0]?.[`current_principal_balance`];
					return this.apiService.getReportsDashboardDelinquencyRatio(
						this.balanceTypes.CURRENT_PRINCIPAL,
						null,
						null,
						this.loanGroupSelected
					);
				})
			)
			.subscribe((res: any) => {
				this.delinquencyCurrentPrincipalTotal =
					res?.data?.[0]?.current_principal_balance || 1;
				if (this.delinquencyCurrentPrincipalTotal) {
					this.insolvencyTotalBKRatio = (
						(this.insolvencyTotalBK * 100) /
						this.delinquencyCurrentPrincipalTotal
					)?.toFixed(2);
					this.insolvencyTotalCPRatio = (
						(this.insolvencyTotalCP * 100) /
						this.delinquencyCurrentPrincipalTotal
					)?.toFixed(2);
					this.insolvencyTotalCCRatio = (
						(this.insolvencyTotalCC * 100) /
						this.delinquencyCurrentPrincipalTotal
					)?.toFixed(2);
					console.log('insolvencyTotalBKRatio', this.insolvencyTotalBKRatio);
					console.log('insolvencyTotalCPRatio', this.insolvencyTotalCPRatio);
					console.log('insolvencyTotalCCRatio', this.insolvencyTotalCCRatio);
				}
			});
	}

	public chartClicked({
		event,
		active,
	}: {
		event: ChartEvent;
		active: {}[];
	}): void {
		console.log(event, active);
	}

	public chartHovered({
		event,
		active,
	}: {
		event: ChartEvent;
		active: {}[];
	}): void {
		console.log(event, active);
	}

	changeLabels(): void {
		this.pieChartData.labels = ['one', 'two', 'three'];

		this.chart?.update();
	}

	addSlice(): void {
		if (this.pieChartData.labels) {
			this.pieChartData.labels.push(['Line 1', 'Line 2', 'Line 3']);
		}

		this.pieChartData.datasets[0].data.push(400);

		this.chart?.update();
	}

	removeSlice(): void {
		if (this.pieChartData.labels) {
			this.pieChartData.labels.pop();
		}

		this.pieChartData.datasets[0].data.pop();

		this.chart?.update();
	}

	changeLegendPosition(): void {
		if (this.pieChartOptions?.plugins?.legend) {
			this.pieChartOptions.plugins.legend.position =
				this.pieChartOptions.plugins.legend.position === 'left'
					? 'top'
					: 'left';
		}

		this.chart?.render();
	}

	toggleLegend(): void {
		if (this.pieChartOptions?.plugins?.legend) {
			this.pieChartOptions.plugins.legend.display =
				!this.pieChartOptions.plugins.legend.display;
		}

		this.chart?.render();
	}

	resetPieChart() {
		this.pieChartData = {
			labels: [],
			datasets: [
				{
					data: [],
				},
			],
		};
	}

	onLoanGroupFilterChange(e) {
		this.loanGroupSelected = e.target?.value;
		this.refreshDashboardData();
	}
}
