import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
	CellClickedEvent,
	ColDef,
	GridReadyEvent,
	IRowNode,
	SideBarDef, ExcelExportParams, CsvExportParams, ISelectCellEditorParams
} from 'ag-grid-community';
import 'ag-grid-enterprise';

@Component({
	selector: 'app-custom-table',
	templateUrl: './custom-table.component.html',
	styleUrls: ['./custom-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CustomTableComponent implements OnChanges {
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;

	@Input() columnDefs?: ColDef[];
	@Input() rowData?: Array<any> | null | undefined;
	/*@Input() set rowData(value) {
		if (!value?.length) {
			this.gridApi?.showLoadingOverlay();
		} else {
			// this.gridApi?.hideOverlay();
		}
	}*/
	@Input() set showLoader(value) {
		if (value) {
			this.gridApi?.showLoadingOverlay();
		} else {
			this.gridApi?.hideOverlay();
		}
	}
	@Input() rowSelection?: 'single' | 'multiple' | undefined;
	@Input() animateRows?: boolean | undefined;
	@Input() pagination?: boolean | undefined;
	@Input() paginationPageSize?: number | undefined;
	@Input() hideGlobalFilter?: boolean | undefined;
	@Input() showToolPanel?: boolean | undefined;
	@Input() sideBar?: SideBarDef | string | string[] | boolean | null;
	@Input() summaryDataToolPanel?: any;
	@Input() icons?: any;
	@Input() dropdownFilters?: Array<any>;
	@Input() filterOneSelected?: string;
	@Input() filterTwoSelected?: string;
	@Input() rowClassRules?: any;
	@Input() defaultExcelExportParams?: ExcelExportParams;
	@Input() defaultCsvExportParams?: CsvExportParams;
	@Input() groupIncludeFooter?: boolean;
	@Input() groupIncludeTotalFooter?: boolean;
	@Input() autoGroupColumnDef?: any;

	@Output() rowDoubleClicked = new EventEmitter<GridReadyEvent>();
	@Output() gridReady = new EventEmitter<GridReadyEvent>();
	@Output() cellClicked = new EventEmitter<CellClickedEvent>();
	@Output() cellValueChanged = new EventEmitter<string>();

	gridApi;
	gridColumnApi;

	filterValue: string;

	currentPage: number;
	totalPages: number;
	public tooltipShowDelay = 0;
	public tooltipHideDelay = 2000;
	public showTopPagination: boolean;
	selectedFilters = [];

	public overlayLoadingTemplate =
		'<span class="ag-overlay-loading-center">Loading Data...</span>';
	public overlayNoRowsTemplate =
		'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">This is a custom \'no rows\' overlay</span>';

	public defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		// tooltipComponent: CustomTooltipComponent,
	};

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.selectedFilter?.firstChange) {
			console.log(
				'Selected filter change in custom table',
				changes.selectedFilter?.currentValue
			);
		}
		if (changes.filterOneSelected?.firstChange) {
			this.applyFilter(
				changes.filterOneSelected?.currentValue,
				this.dropdownFilters?.[0]
			);
		}
		if (changes.filterTwoSelected?.firstChange) {
			this.applyFilter(
				changes.filterTwoSelected?.currentValue,
				this.dropdownFilters?.[1]
			);
		}
	}

	onRowDoubleClicked(e) {
		this.rowDoubleClicked.next(e);
	}

	onCellValueChanged(e) {
		this.cellValueChanged.next(e);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit({
			defaultMinWidth: 100,
		});
		// let columnIds = [];
		// this.gridColumnApi.getColumns().forEach(column => {
		//   columnIds.push(column.colId);
		// });
		// this.gridColumnApi.autoSizeColumns(columnIds, false);
		this.gridReady.next(params);
	}

	onPaginationChanged(e) {
		if (this.gridApi!) {
			this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;
			this.totalPages = this.gridApi.paginationGetTotalPages();
		}
	}

	onCellClicked(e) {
		this.cellClicked.next(e);
	}

	onFilterTextBoxChanged() {
		this.gridApi.setQuickFilter(this.filterValue);
	}

	onBtFirst() {
		this.gridApi.paginationGoToFirstPage();
	}

	onBtLast() {
		this.gridApi.paginationGoToLastPage();
	}

	onBtNext() {
		this.gridApi.paginationGoToNextPage();
	}

	onBtPrevious() {
		this.gridApi.paginationGoToPreviousPage();
	}

	applyFilter(selectedFilterValue, item) {
		const selectedFilterIndex = this.selectedFilters.findIndex(
			(filter) => filter.selectedFilter === item.filterColumn
		);
		if (selectedFilterIndex > -1) {
			this.selectedFilters[selectedFilterIndex].selectedFilterValue =
				selectedFilterValue;
		} else {
			this.selectedFilters?.push({
				selectedFilterValue,
				selectedFilter: item.filterColumn,
			});
		}
		this.gridApi?.onFilterChanged();

		// PTP table specific
		if (item?.filterColumn === 'status') {
			switch (selectedFilterValue) {
				case 'Arranged': {
					this.gridColumnApi?.setColumnsVisible(['apply'], false);
					this.gridColumnApi?.setColumnsVisible(['delete'], true);
					break;
				}
				case 'Requested': {
					this.gridColumnApi?.setColumnsVisible(['apply'], true);
					this.gridColumnApi?.setColumnsVisible(['delete'], true);
					break;
				}
				case 'Processed': {
					this.gridColumnApi?.setColumnsVisible(['apply'], false);
					this.gridColumnApi?.setColumnsVisible(['delete'], false);
					break;
				}
				case 'Deleted': {
					this.gridColumnApi?.setColumnsVisible(['apply'], false);
					this.gridColumnApi?.setColumnsVisible(['delete'], false);
					break;
				}
				case 'Cancelled': {
					this.gridColumnApi?.setColumnsVisible(['apply'], false);
					this.gridColumnApi?.setColumnsVisible(['delete'], true);
					break;
				}
				default: {
					this.gridColumnApi?.setColumnsVisible(['apply'], false);
					this.gridColumnApi?.setColumnsVisible(['delete'], false);
					break;
				}
			}
		}
	}

	resetFilter() {
		// this.selectedFilters = [];
		this.gridApi?.setFilterModel(null);
	}

	isExternalFilterPresent() {
		return this.selectedFilters[0]?.selectedFilterValue !== 'All';
	}

	doesExternalFilterPass(node: IRowNode<any>) {
		const { data } = node;
		let filterPass = true;
		if (data) {
			let test = this.selectedFilters.filter(
				(filter) => data[filter.selectedFilter] === filter.selectedFilterValue
			);
			return test.length === this.selectedFilters.length;
		}
		return filterPass;
	}
}
