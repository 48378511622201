import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@lmc-env/environment';
import { LicenseManager } from  'ag-grid-enterprise';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-041326 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( LM Credit )_is_granted_a_( Single Application )_Developer_License_for_the_application_( LM Credit )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( LM Credit )_need_to_be_licensed___( LM Credit )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 April 2024 )____[v2]_MTcxMzU2NzYwMDAwMA==5f2a8ea48f043050912892f4313d5b79");

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
