import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';

@Component({
	selector: 'app-nlsagencycollectionscollectorclients',
	templateUrl: './nlsagencycollectionscollectorclients.component.html',
	styleUrls: ['./nlsagencycollectionscollectorclients.component.scss'],
})
export class NlsagencycollectionscollectorclientsComponent implements OnInit {
	collectorName: string;
	agencyName: string;
	clientArray: string[] = [];
	totalAmount: any;

	constructor(
		private apiService: ApiServiceService,
		private router: ActivatedRoute,
		private _routerLink: Router,
		private customfunction: CustomFunctionService
	) {}

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						// Get the creditor name the nlsagencygrouped page link
						this.router.queryParams.subscribe((params) => {
							(this.collectorName = params['collector']),
								(this.agencyName = params['agency']);
						});

						// Send the creditor name to get the agencies listed under the creditor
						this.apiService
							.getNLSagencyCollectionsCollectorClients(
								this.collectorName,
								this.agencyName
							)
							.subscribe((res) => {
								console.log(res.data);
								this.clientArray = res.data;
								this.customfunction.sortColumn(
									this.clientArray,
									'current_principal_balance',
									-1,
									1
								);
								this.totalAmount = this.customfunction.sumNumbers(
									res.data,
									'current_principal_balance'
								);
								console.log(this.totalAmount);
							});
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}
}
