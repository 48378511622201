<!-- <div class="row" style="height: calc(100% - 20px); margin: 10px;">
    <app-custom-table class="ag-theme-material" [defaultExcelExportParams]="defaultExcelExportParams"
        [defaultCsvExportParams]="defaultCsvExportParams" [rowData]="rowData" [columnDefs]="columnDefs"
        [hideGlobalFilter]="true" [animateRows]="true" [pagination]="true" [paginationPageSize]="100"
        (gridReady)="onGridReady_Hist($event)">
    </app-custom-table>
</div> -->

<mat-tab-group style="height: calc(100% - 20px); margin: 10px;">
    <mat-tab label="Unfiltered LPP Raw Data">
        <app-custom-table class="ag-theme-material" [defaultExcelExportParams]="defaultExcelExportParams"
            [defaultCsvExportParams]="defaultCsvExportParams" [rowData]="rowData" [columnDefs]="columnDefs"
            [hideGlobalFilter]="true" [animateRows]="true" [pagination]="true" [paginationPageSize]="100"
            (gridReady)="onGridReady_Hist($event)">
        </app-custom-table>
    </mat-tab>
    <mat-tab label="Filtered LPP Data">
        <app-custom-table class="ag-theme-material" [defaultExcelExportParams]="defaultExcelExportParams"
            [defaultCsvExportParams]="defaultCsvExportParams" [rowData]="rowData" [columnDefs]="columnDefs"
            [hideGlobalFilter]="true" [animateRows]="true" [pagination]="true" [paginationPageSize]="100"
            (gridReady)="onGridReady_FilteredLPP($event)">
        </app-custom-table>
    </mat-tab>
</mat-tab-group>