import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomModalService } from './services/modal/custom-modal.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	modalOptions: any;
	modalActiveSubscription: Subscription;

	constructor(private customModalService: CustomModalService) {}
	ngOnInit() {
		this.modalActiveSubscription =
			this.customModalService.modalActive.subscribe((modalOptions) => {
				this.modalOptions = modalOptions;
			});
	}

	ngOnDestroy() {
		this.modalActiveSubscription.unsubscribe();
	}
}
