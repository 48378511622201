<span class="spinner-border" id="loading" *ngIf="spinner === true"></span>
<div *ngIf="collection_info" style="padding-left: 30px; padding-top: 30px">
  <mat-drawer-container>
    <mat-drawer #drawer mode="side" position="end" [opened]="true">
      <div style="width: 600px; padding: 0 10px;">

        <mat-card appearance="outlined" style="padding: 10px; position: relative;">
          <h5>Add Note</h5>
          <div style="float: left; display: flex">
            <div>
              <mat-form-field appearance="outline" style="font-weight: bold">
                <mat-label>Comment Type</mat-label>
                <mat-select [(ngModel)]="selectedType" (selectionChange)="displayEmails(selectedType)">
                  <mat-option *ngFor=" let ct of comment_types" [value]="ct.value">
                    {{ ct.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div style="padding-left: 4px">
              <mat-form-field appearance="outline" style="font-weight: bold">
                <mat-label>Disposition</mat-label>
                <mat-select [(ngModel)]="selectedType_d">
                  <mat-option *ngFor="let d of dispositions" [value]="d.name">
                    {{ d.id }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="selectedType === 'Email Notes' && selectedType_d === 'Internal Email'">
            <mat-form-field appearance="outline" style="font-weight: bold; width: 100%;">
              <mat-label>Select an email address</mat-label>
              <mat-select [(ngModel)]="selectedEmail">
                <mat-option *ngFor="let item of officeEmails" [value]="item.email_addresses">
                  {{ item.email_addresses }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div *ngIf="selectedType === 'Email Notes' && selectedType_d === 'External Email'">
            <mat-form-field appearance="outline" style="font-weight: bold; width: 100%;">
              <mat-label>Type external email address</mat-label>
              <input type="email" matInput [formControl]="emailFormControl">
              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div> -->
          <div>
            <textarea style="width: 100%" name="Comments" placeholder="Comments" rows="5"
              [(ngModel)]="comment"></textarea>
          </div>
          <div>
            <button mat-raised-button color="primary" style="background-color: green; width: 150px;" class="saveNote"
              (click)="createComment()">Save</button>
          </div>
          <!-- <div class="row">
            <div class="col-6" style="display: flex; flex-direction: column; align-items: end;">
              <button mat-raised-button color="primary" style="background-color: green; width: 100%;" class="saveNote"
                (click)="createComment()">Save</button>
              <mat-slide-toggle class="example-margin" color="Primary" [(ngModel)]="isEmail" (change)="displayEmails()">
                Email Notification
              </mat-slide-toggle>

            </div>
            <div class="col-6">
              <mat-form-field *ngIf="isEmail" appearance="outline"
                style="font-weight: bold; margin-top: 4px; margin-left: 5px; padding: none; width: 100%;">
                <mat-label>Select an email address</mat-label>
                <mat-select [(ngModel)]="selectedEmail">
                  <mat-option *ngFor="let item of officeEmails" [value]="item.email_addresses">
                    {{ item.email_addresses }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
        </mat-card>
        <br />
        <div>
          <table style="width: 100%" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th style="min-width: 18%; width: 18%">Created</th>
                <th style="width: 82%">Note</th>
              </tr>
            </thead>
            <tbody>
              <tr style="cursor: pointer" *ngFor="
							let item of Comments_table
								| paginate : { itemsPerPage: count, currentPage: p }
						" (dblclick)="openCommentForm(item)">
                <td style="width: 15%">{{ item.created_at }}</td>
                <td style="width: 85%">
                  <strong>{{ item.type }} [{{ item.disposition }}] -
                    {{ item.created_by.split('@')[0] }}</strong><br />{{ item.comment }}<span style="font-size: medium;"
                    *ngIf="item.email_sent_to"><br /><strong>Emailed:</strong> {{
                    item.email_sent_to }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div id="paginate" class="text-right">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>
      </div>
    </mat-drawer>

    <div>
      <div (click)="drawer.toggle(); toggleSideNav()" class="sidenav-toggle-button" style="margin-left: 5px"
        [ngClass]="isSideNavMenuOpen ? 'sidenav-open' : 'sidenav-closed'">
        <i class="fa fa-chevron-right" aria-hidden="true"
          [@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'right' : 'left'"></i>
      </div>
      <div>
        <mat-accordion>
          <mat-expansion-panel [(expanded)]="panelOpenState">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div>
                  <div style="display: inline-block; vertical-align: middle;">
                    <h4 style="margin-top: 5px;"><a style="text-decoration: none;" [routerLink]="[
											'/applications/summary/',
											collection_info.cifnumber
										]">
                        {{ collection_info.Name }}</a> ({{ collection_info.Loan_Number }})
                      <button mat-stroked-button *ngIf="isTurnkey" (click)="openTurnkeyModal()">Turnkey</button>
                    </h4>
                  </div>
                  <div *ngIf="isQueue" style="float: right">
                    <div *ngIf="loanIndex !== 0" style="display: inline-block">
                      <button (click)="viewPreviousLoan()" mat-mini-fab color="primary"
                        style="margin-left: 10px; margin-right: 10px; background-color: darkgrey;">
                        <mat-icon>chevron_left</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="loanIndex !== queuedLoans.length - 1" style="display: inline-block">
                      <button mat-mini-fab color="primary" style="background-color: darkgrey;"
                        (click)="viewNextLoan()"><mat-icon>chevron_right</mat-icon></button>
                    </div>
                  </div>
                </div>
              </mat-panel-title>
              <div class="top-info">
                <strong class="general-labels">Assigned Collector: </strong>{{ collection_info['assigned_collector']
                }}
              </div>
            </mat-expansion-panel-header>
            <div class="top-info">
              <strong class="general-labels">Maturity Date: </strong>{{ meta.maturity_date }}
            </div>
            <div class="top-info">
              <strong class="general-labels">Total Balance: </strong>
              <span [ngStyle]="{'color' : isMatured ? 'red':'black'}">{{totalBalance |
                currency}}</span>
            </div>
            <div class="top-info" *ngIf="!isMatured">
              <strong class="general-labels">Past Due: </strong>${{meta.total_due}}
            </div>
            <div class="top-info">
              <strong class="general-labels">DPD: </strong>{{ collection_info.Days_Missed }}
            </div>
            <div class="top-info">
              <strong class="general-labels">Unbilled Interest: </strong>${{unbilledInterest}}
            </div>
            <div class="top-info">
              <strong class="general-labels">Scheduled Amount: </strong>${{ meta.amortized_payment_amount}}
            </div>
            <div class="top-info">
              <strong class="general-labels">Status: </strong>{{ meta.loan_status }}
            </div>
            <div class="top-info">
              <strong class="general-labels">Status Codes: </strong>{{ meta.status_code }}
            </div>
            <div class="top-info">
              <strong>Loan Group: </strong>{{ meta.loan_group }}
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <div>
          <mat-tab-group (selectedTabChange)="selectedTabValue($event)">
            <mat-tab label="Details">
              <br /><br />
              <div class="row row-cols-12" style="height: 700px;">
                <div class="col-4">
                  <h4>Balance as of {{test_date | date:'yyyy-MM-dd'}}</h4>
                  <div style="font-size: 15px">
                    <div>
                      <strong class="general-labels">Principal
                      </strong>{{ collection_info.current_principal_balance | currency }}
                      <br />
                      <strong class="general-labels">Interest: </strong>{{
                      (collection_info.current_interest_balance)?.toFixed(2) | currency}}
                      <br />
                      <strong class="general-labels">Fees: </strong>{{meta.eff_fees_balance | currency}}
                      <br />
                      <strong>Insurance: </strong>{{meta.insurance | currency}}
                      <br />
                    </div>
                  </div>
                  <br><br /><br />
                  <h4>Loan Information</h4>
                  <div style="font-size: 15px">
                    <div class="float-child-1-metta">
                      <strong class="general-labels">Original Loan Amount: </strong>${{
                      collection_info.original_note_amount
                      }}
                      <br />
                      <strong class="general-labels">Open Date: </strong>{{ meta.open_date }}
                      <br />
                      <strong class="general-labels">Total Obligation (est): </strong>${{
                      total_obligation_entire_term.toLocaleString('en-US') }}
                      <br />
                      <strong>Origination Date: </strong>{{ meta.origination_date }}
                      <br />
                      <strong class="general-labels">Maturity Date: </strong>{{ meta.maturity_date }}
                      <br />
                      <strong>Closed Date: </strong>{{ meta.closed_date }}
                      <br />
                      <strong>Current Loan Amount: </strong>${{
                      collection_info.current_note_amount
                      }}
                      <br />
                      <strong>Current Rate: </strong>{{ meta.current_rate }}%
                      <br />
                      <strong>Per Diem: </strong>${{ meta.perdiem }}
                      <br />
                      <strong class="general-labels">Payment Frequency: </strong>{{ meta.principal_period }}
                      <br />
                      <strong>acctrefno: </strong>{{ collection_info.acctrefno }}
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <h4>Client Information</h4>
                  <div style="font-size: 15px">
                    <div>
                      <strong>Address:</strong>{{ collection_info.Address }}
                      <br />
                      <strong>City: </strong>{{ collection_info.City }}
                      <br />
                      <strong>Province: </strong>{{ collection_info.Province }}
                      <br />
                      <strong>Postal Code: </strong>{{ collection_info.Postal_Code }}
                      <br />
                      <strong style="font-size: 15px">Phone Number: </strong>{{ collection_info.Phone_No }}
                      <br />
                      <strong>Email: </strong>{{ collection_info.Email }}
                      <br />
                      <strong>DOB: </strong>{{ collection_info.DOB }}
                      <br />
                      <strong>Emergency Contact:</strong>{{ collection_info['EmergencyContact'] }}
                    </div>
                  </div>
                  <br />
                  <h4>Employment Information</h4>
                  <div style="font-size: 15px">
                    <div class="float-child-1-metta">
                      <strong>Work Phone: </strong>{{ collection_info['Work Phone'] }}
                      <br />
                      <strong>Industry: </strong>{{ collection_info['Industry'] }}
                      <br />
                      <strong>Employer: </strong>{{ collection_info['Employer'] }}
                      <br />
                      <strong>Position: </strong>{{ collection_info['Position'] }}
                      <br />
                      <strong>Additional Employment Info: </strong>{{ collection_info['Additional Employment Info'] }}
                      <br />
                      <strong>Employemnt Status: </strong>{{ collection_info['Employemnt Status'] }}
                      <br />
                      <strong>Supervisor Name: </strong>{{ collection_info['Supervisor Name'] }}
                      <br />
                      <strong>Supervisor Phone: </strong>{{ collection_info['Supervisor Phone'] }}
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <h4>Co Applicant Information</h4>
                  <div style="font-size: 15px">
                    <div class="float-child-1-metta">
                      <strong>Co-Debtor First Name: </strong>{{ collection_info['Co-Debtor First Name'] }}
                      <br />
                      <strong>Co-Debtor Last Name: </strong>{{ collection_info['Co-Debtor Last Name'] }}
                      <br />
                      <strong>Co-Debtor Address: </strong>{{ collection_info['Co-Debtor Address'] }}
                      <br />
                      <strong>Co-Debtor City: </strong>{{ collection_info['Co-Debtor City'] }}
                      <br />
                      <strong>Co-Debtor Province: </strong>{{ collection_info['Co-Debtor Province'] }}
                      <br />
                      <strong>Co-Debtor Postal Code: </strong>{{ collection_info['Co-Debtor Postal Code'] }}
                      <br />
                      <strong>Co-Debtor Phone Number: </strong>{{ collection_info['Co-Debtor Phone Number'] }}
                      <br />
                      <strong>Co-Debtor DOB: </strong>{{ collection_info['Co-Debtor DOB'] }}
                      <br />
                      <strong>Co-Debtor Email: </strong>{{ collection_info['Co-Debtor Email'] }}
                      <br />
                      <strong>Co-Debtor Employment Type: </strong>{{ collection_info['Co-Debtor Employment Type'] }}
                      <br />
                      <strong>Co-Debtor Employer: </strong>{{ collection_info['Co-Debtor Employer'] }}
                      <br />
                      <strong>Co-Debtor Occupation: </strong>{{ collection_info['Co-Debtor Occupation'] }}
                      <br />
                      <strong>Co-Debtor Supervisor Name: </strong>{{ collection_info['Co-Debtor Supervisor Name'] }}
                      <br />
                      <strong>Co-Debtor Supervisor Number: </strong>{{ collection_info['Co-Debtor Supervisor Number'] }}
                      <br />
                      <strong>Co-Debtor Annual Income: </strong>{{ collection_info['Co-Debtor Annual Income'] }}
                      <br />
                      <strong>Co-Debtor Emergency Name: </strong>{{ collection_info['Co-Debtor Emergency Name'] }}
                      <br />
                      <strong>Co-Debtor Emergency Number: </strong>{{ collection_info['Co-Debtor Emergency Number'] }}
                      <br />
                      <strong>Co-Debtor Emergency Relation: </strong>{{ collection_info['Co-Debtor Emergency Relation']
                      }}
                      <br />
                      <strong>Co-Debtor Employment Email: </strong>{{ collection_info['Co-Debtor Employment Email'] }}
                    </div>
                  </div>
                </div>
              </div>


            </mat-tab>
            <mat-tab label="Payments">
              <app-payment-report-loan-servicing [loanNumber]="collection_info.Loan_Number"
                [acctref]="collection_info.acctrefno"></app-payment-report-loan-servicing>
            </mat-tab>
            <mat-tab label="Promises">
              <h5>Create Promise to Pay</h5>
              <app-promise-pay [data]="[
							collection_info,
							payment_history,
							payment_due,
							{ user_name: userName, zoho_user_id: zohoUserID }
						]" [collection_info]="collection_info" [meta]="meta" [existing_PTP]="payment_due" [Collector]="userName"
                [type]="'ptp'" [empID]="empID" [totalPTPCreated]="totalPTPCreated"
                [promisesFlag]="promisesFlag"></app-promise-pay>
              <h5>Promise to Pay</h5>
              <div style="flex: 1 1 auto; height: 600px">
                <app-custom-table style="width: 100%; height: 100%" class="ag-theme-material" [rowData]="rowData_PTP"
                  [columnDefs]="columnDefs_Due" (cellValueChanged)="onCellValueChanged($event)" [animateRows]="true"
                  [pagination]="true" [paginationPageSize]="20" [hideGlobalFilter]="true"
                  (gridReady)="onGridReady_Due($event)">
                </app-custom-table>
              </div>
            </mat-tab>
            <mat-tab label="Arrangements">
              <h5>Create Arrangement</h5>
              <app-promise-pay [data]="[
							collection_info,
							payment_history,
							payment_due,
							{ user_name: userName, zoho_user_id: zohoUserID }
						]" [collection_info]="collection_info" [meta]="meta" [existing_PTP]="payment_due" [Collector]="userName"
                [type]="'arrangement'" [empID]="empID"></app-promise-pay>
              <h5>Arrangements</h5>
              <div style="flex: 1 1 auto; height: 700px">
                <app-custom-table style="width: 100%; height: 100%" class="ag-theme-material"
                  [rowData]="rowData_arrangement" [columnDefs]="columnDefs_Due"
                  (cellValueChanged)="onCellValueChanged($event)" [animateRows]="true" [pagination]="true"
                  [paginationPageSize]="20" [hideGlobalFilter]="true" (gridReady)="onGridReady_Due($event)">
                </app-custom-table>
              </div>
            </mat-tab>
            <mat-tab label="Deferred">
              <div>
                <ag-grid-angular style="width: 100%; height: 700px" class="ag-theme-material" [rowData]="rowData_Def"
                  [columnDefs]="columnDefs_Def" [defaultColDef]="defaultColDef_Def" [animateRows]="true"
                  [pagination]="true" [paginationPageSize]="20" (gridReady)="onGridReady_Def($event)"
                  [getRowStyle]="getRowStyle_Def">
                </ag-grid-angular>
              </div>
            </mat-tab>

            <mat-tab label="Future Payments">
              <app-amo-schedule [openAmoFlag]="openAmoFlag" [acctrefno]="collection_info.acctrefno"></app-amo-schedule>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

  </mat-drawer-container>
</div>