import { AfterViewInit, Component, Inject, OnInit, Optional } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-turnkey-data',
  templateUrl: './turnkey-data.component.html',
  styleUrls: ['./turnkey-data.component.scss']
})
export class TurnkeyDataComponent implements AfterViewInit, OnInit {
  // Transactions ag-grid
  public columnDefs_Repayments;
  public defaultColDef_Repayments: ColDef = { flex: 1, resizable: true };
  public rowDataRepayment: any[];

  // Schedules ag-grid
  public columnDefs_Schedules;
  public defaultColDef_Schedules: ColDef = { flex: 1, resizable: true };
  public rowDataSchedules: any[];
  public getRowStyle_Schedule: any;

  turnkeyData: any;
  loanStatus: string;
  repayments: any;
  showLoader: boolean;
  scheduleData: any;

  constructor(private apiService: ApiServiceService, public dialogRef: MatDialogRef<TurnkeyDataComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  } ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  ;


  ngAfterViewInit(): void {
    this.apiService.getTurnkeyData(this.data).subscribe((res: any) => {
      this.turnkeyData = res[0];
      this.loanStatus = this.turnkeyData?.IsActive === true ? 'Open' : 'Closed';

      this.repayments = this.turnkeyData?.Repayments;
      this.rowDataRepayment = this.turnkeyData?.Repayments;

      this.scheduleData = this.turnkeyData?.Schedule;
      this.sortDesc(this.repayments);
      console.log(this.repayments)
      this.showLoader = false;
    })

    this.columnDefs_Repayments = [
      { headerName: 'Payment Date', field: 'OperationDate', filter: "agDateColumnFilter", minWidth: 150, width: 150, },
      { headerName: 'Amount', field: 'Amount', filter: "agNumberColumnFilter", minWidth: 120, width: 120, },
      { headerName: 'AmountLeft', field: 'AmountLeft', filter: "agNumberColumnFilter", minWidth: 140, width: 140, },
      { headerName: 'Interest', field: 'Interest', filter: "agNumberColumnFilter", minWidth: 120, width: 120, },
      { headerName: 'Principal', field: 'Principal', filter: 'agNumberColumnFilter', minWidth: 120, width: 120, },
      { headerName: 'PaymentType', field: 'PaymentType', filter: "agTextColumnFilter", minWidth: 140, width: 140, },
      { headerName: 'IsSuccessful', field: 'IsSuccessful', filter: "agTextColumnFilter", minWidth: 140, width: 140, },
      { headerName: 'PaymentChannel', field: 'ServiceId', filter: "agTextColumnFilter", minWidth: 200, width: 200, },
      { headerName: 'Status', field: 'Status', filter: "agTextColumnFilter", minWidth: 200, width: 200, }
    ];

    this.columnDefs_Schedules = [
      { headerName: 'Due Date', field: 'DueDate', filter: "agDateColumnFilter", minWidth: 150, width: 150, },
      { headerName: 'Status', field: 'Status', filter: "agTextColumnFilter", minWidth: 150, width: 150, },
      { headerName: 'Total', field: 'Total', filter: "agTextColumnFilter", minWidth: 100, width: 100, },
      { headerName: 'Fee', field: 'Fee', filter: "agNumberColumnFilter", minWidth: 100, width: 100, },
      { headerName: 'Tax', field: 'Tax', filter: "agNumberColumnFilter", minWidth: 100, width: 100, },
      { headerName: 'Interest', field: 'Interest', filter: "agNumberColumnFilter", minWidth: 120, width: 120, },
      { headerName: 'Other', field: 'Other', filter: "agNumberColumnFilter", minWidth: 100, width: 100, },
      { headerName: 'PastDue', field: 'PastDue', filter: 'agNumberColumnFilter', minWidth: 120, width: 120, },
      { headerName: 'Principal', field: 'Principal', filter: "agTextColumnFilter", minWidth: 120, width: 120, },
    ];
    this.getRowStyle_Schedule = (params) => {
      if (params.data.Status === 'Missed') {
        return { color: '#ff0000' };   // Red
      }
    };
  }

  onOK() {
    this.dialogRef.close(true);
  }

  sortDesc = (data: any) => {
    return data.sort((a: any, b: any) => a.OperationDate - b.OperationDate)
  }
}
