// import { ISummaryState } from "./summary.interface";

export interface SummaryState {
	summary: any;
}

export const initialState: SummaryState = {
	summary: {
		id: '',
		Deal_Name: '',
	},
};
