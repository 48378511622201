<div style="display: flex; justify-content: flex-start">
	<mat-form-field class="table-filter" appearance="outline" *ngIf="!hideGlobalFilter" style="margin-right: 15px">
		<mat-label>Global Filter</mat-label>
		<input matInput type="text" [(ngModel)]="filterValue" (input)="onFilterTextBoxChanged()"
			placeholder="Start typing to filter table..." />
		<mat-icon *ngIf="!filterValue" matSuffix>filter_list</mat-icon>
		<button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="filterValue = ''">
			<mat-icon>close</mat-icon>
		</button>
	</mat-form-field>
	<mat-form-field appearance="outline" *ngFor="let item of dropdownFilters; let filterIndex = index"
		style="margin-right: 15px">
		<mat-label>{{ item.label }}</mat-label>
		<mat-select (selectionChange)="applyFilter($event.value, item)"
			[(value)]="filterIndex === 0 ? filterOneSelected : filterTwoSelected">
			<mat-option *ngFor="let filter of item.filters" [value]="filter.value">
				{{ filter.label }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<button mat-raised-button color="primary" *ngIf="showToolPanel" (click)="resetFilter()"
		style="display: flex; align-items: center; justify-content: center">
		Reset Filters
	</button>
	<div *ngIf="currentPage && totalPages && showTopPagination">
		<span>Page {{ currentPage }} of {{ totalPages }}</span>
		<button mat-icon-button (click)="onBtFirst()"
			style="display: flex; align-items: center; justify-content: center">
			<mat-icon>first_page</mat-icon>
		</button>
		<button mat-icon-button (click)="onBtPrevious()"
			style="display: flex; align-items: center; justify-content: center">
			<mat-icon>chevron_left</mat-icon>
		</button>
		<button mat-icon-button (click)="onBtNext()"
			style="display: flex; align-items: center; justify-content: center">
			<mat-icon>chevron_right</mat-icon>
		</button>
		<button mat-icon-button (click)="onBtLast()"
			style="display: flex; align-items: center; justify-content: center">
			<mat-icon>last_page</mat-icon>
		</button>
	</div>
</div>
<ag-grid-angular style="width: 100%; height: 100%; font-size: 15px" class="ag-theme-material" [columnDefs]="columnDefs"
	[defaultColDef]="defaultColDef" [rowData]="rowData" [rowSelection]="'multiple'" [animateRows]="true"
	[pagination]="true" [paginationPageSize]="paginationPageSize" [cacheQuickFilter]="true"
	[enableCellTextSelection]="true" [rowClassRules]="rowClassRules"
	[defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"
	[groupIncludeFooter]="groupIncludeFooter" [groupIncludeTotalFooter]="groupIncludeTotalFooter"
	[autoGroupColumnDef]="autoGroupColumnDef" [isExternalFilterPresent]="isExternalFilterPresent.bind(this)"
	[doesExternalFilterPass]="doesExternalFilterPass.bind(this)" [tooltipShowDelay]="tooltipShowDelay"
	[tooltipHideDelay]="tooltipHideDelay" [sideBar]="showToolPanel ? sideBar : null" [icons]="icons"
	[overlayLoadingTemplate]="overlayLoadingTemplate" (rowDoubleClicked)="onRowDoubleClicked($event)"
	(cellValueChanged)="onCellValueChanged($event)" (gridReady)="onGridReady($event)"
	(cellClicked)="onCellClicked($event)" (paginationChanged)="onPaginationChanged($event)"></ag-grid-angular>