<!-- <html>
	<head>
		<script
			type="text/javascript"
			src="https://code.jquery.com/jquery-2.1.3.js"
		></script>
		<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.2/jspdf.debug.js"></script>
	</head>

	<body> -->
<div *ngIf="isOldCalculator">
	<h5>Used old loan calculator!</h5>
</div>
<div style="padding-right: 5px" *ngIf="!isOldCalculator">
	<div class="alert alert-danger" role="alert" *ngIf="!hasCreditorPortfolio">
		Please fill out the necessary Creditor Portfolios on ZOHO and refresh the page!
	</div>
	<div class="alert alert-danger" role="alert" *ngIf="!hasAccountBalance">
		Please fill out the necessary Account Balance on ZOHO and refresh the page!
	</div>
	<div class="alert alert-danger" role="alert" *ngIf="!hasCustomSettlement">
		Its a custom settlement, please fill out the necessary custom agency amount on ZOHO and refresh the page!
	</div>
	<span class="spinner-border" id="loading" *ngIf="spinner === true"></span>
	<div *ngIf="loanInput.Application_Name">
		<div class="float-child-left" style="font-size: 20px; font-weight: bold">
			<br />
			<div style="padding-left: 1%">
				<div class="float-child-left-input">
					<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
						<mat-label>Interest Type</mat-label>
						<mat-select [disabled]="read_only_flag" (selectionChange)="LoanTypeChange($event.value)"
							[(ngModel)]="loanInput.Loan_Type">
							<mat-option *ngFor="let lt of loan_type" [value]="lt.value">
								{{ lt.viewValue }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="loanInput.Loan_Type === 'Custom'">
						<mat-form-field style="font-weight: bold; width: 90%">
							<mat-label>Interest Rate</mat-label>
							<input [readonly]="read_only_flag" matInput type="number"
								[(ngModel)]="loanInput.Interest_Rate" />
							<button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
								(click)="value = ''">
								<mat-icon>close</mat-icon>
							</button>
						</mat-form-field>
					</div>
					<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
						<mat-label>Loan Protection (LPP)?</mat-label>
						<mat-select [disabled]="read_only_flag" [(ngModel)]="loanInput.Insurance">
							<mat-option *ngFor="let lt of insurance_protection" [value]="lt.value">
								{{ lt.viewValue }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<br />
					<mat-form-field style="width: 90%;">
						<span style="position: relative; float: left; z-index: 1;"><mat-spinner *ngIf="loanAmountLoader"
								matSuffix diameter="16"
								style="position: absolute; margin-left: 1em;"></mat-spinner></span>
						<mat-label>Loan Amount</mat-label>
						<input [readonly]="disableLoanAmount" matInput type="number" [min]="loanConfig?.amount?.min"
							[max]="loanConfig?.amount?.max" [ngModel]="loanInput.Loan_Amount"
							(ngModelChange)="onDurationUpdate($event)" />
						<mat-error *ngIf="loanInput.Loan_Amount < loanConfig?.amount?.min">
							Loan amount must be greater than {{ loanConfig?.amount?.min }}
						</mat-error>
						<mat-error *ngIf="loanInput.Loan_Amount > loanConfig?.amount?.max">
							Loan amount must be less than {{ loanConfig?.amount?.max }}
						</mat-error>
					</mat-form-field>
					<br />
					<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
						<mat-label>Term</mat-label>
						<mat-select [disabled]="read_only_flag" [(ngModel)]="loanInput.Term_Type">
							<mat-option *ngFor="let lt of term_type" [value]="lt.value">
								{{ lt.viewValue }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<br />
					<mat-form-field appearance="fill" style="width: 90%">
						<mat-label>Funded Date</mat-label>
						<input [disabled]="read_only_flag" matInput [matDatepickerFilter]="myFilter"
							[matDatepicker]="datepicker1" placeholder="Funded Date" [(ngModel)]="loanInput.Funded_Date"
							required />
						<mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
						<mat-datepicker #datepicker1></mat-datepicker>
					</mat-form-field>
					<br>
					<div style="display: flex; justify-content: space-between; width: 90%">
						<button class="buttonSubmit" (click)="calculate(false)">
							Submit
						</button>
						<div *ngIf="Payments">
							<button (click)="resetForm()" class="buttonReset">Reset</button>
						</div>
					</div>
				</div>
				<div class="float-child-right-input">
					<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
						<mat-label>Manager Overwrite</mat-label>
						<mat-select [disabled]="non_admin_user" (selectionChange)="updateException($event)"
							[(ngModel)]="loanInput.exception">
							<mat-option *ngFor="let lt of manager_overwrite" [value]="lt.value">
								{{ lt.viewValue }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field style="width: 90%">
						<mat-label>Loan Protection (LPP) Type</mat-label>
						<input [readonly]="true" matInput type="string" [(ngModel)]="display_data.isEnhanced" />
						<button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
							(click)="value = ''">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
					<br />
					<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
						<mat-label>Period (Years)</mat-label>
						<mat-select [(ngModel)]="loanInput.Duration">
							<!-- <mat-select [disabled]="!loanInput.exception" [(ngModel)]="loanInput.Duration"> -->
							<mat-option *ngFor="let lt of loan_period" [value]="lt.value">
								{{ lt.viewValue }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<br />
					<mat-form-field appearance="fill" style="font-weight: bold; width: 90%">
						<mat-label>Credit Upgrader?</mat-label>
						<mat-select [disabled]="read_only_flag" [(ngModel)]="loanInput.CU">
							<mat-option *ngFor="let lt of credit_upgrader" [value]="lt.value">
								{{ lt.viewValue }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<br />

					<mat-form-field appearance="fill" style="width: 90%">
						<mat-label>Start Date</mat-label>
						<input [disabled]="read_only_flag" matInput [matDatepickerFilter]=""
							[matDatepicker]="datepicker2" placeholder="Start Date" [(ngModel)]="loanInput.Start_Date"
							required />
						<mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
						<mat-datepicker #datepicker2></mat-datepicker>
					</mat-form-field>
					<br />
					<!-- <div *ngIf="Payments">
						<button (click)="resetForm()" class="buttonReset">Reset</button>
					</div> -->
				</div>
			</div>
			<br />
		</div>
		<br />
		<div class="float-child-right">
			<div *ngIf="Payments">
				<div
					style="box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; padding: 10px 10px 0px 10px;">
					<table style="width: 100%;">
						<thead>
							<th class="cellSpacing" style="border-top: none;">Creditor</th>
							<th class="cellSpacing" style="border-top: none;">Portfolio</th>
							<th class="cellSpacing" style="border-top: none;">Custom Settlement</th>
							<th class="cellSpacing" style="border-top: none;">Account Balance</th>
							<!-- <th class="cellSpacing" style="border-top: none;">dsRate</th> -->
							<!-- <th class="cellSpacing" style="border-top: none;">dsFeeRate</th> -->
							<th class="cellSpacing" style="border-top: none;">Deducted Amount</th>
							<th class="cellSpacing" style="border-top: none;">Agency Amount</th>
							<th class="cellSpacing" style="border-top: none;">Legal Fee</th>
							<th class="cellSpacing" style="border-top: none;">Loan Amount</th>
						</thead>
						<tbody>
							<tr *ngFor="let debt of additionalDebt_settlment">
								<td class="cellSpacing">{{debt.creditor}}</td>
								<td class="cellSpacing">{{debt.portfolio}}</td>
								<td class="cellSpacing">{{debt.Custom_Settlement}}</td>
								<td class="cellSpacing">{{debt.account_balance | currency}}</td>
								<!-- <td class="cellSpacing">{{(debt.dsRate * 100).toFixed(0)}}</td> -->
								<!-- <td class="cellSpacing">{{(debt.dsFeeRate * 100).toFixed(0)}}</td> -->
								<td class="cellSpacing">{{debt.dsAmount === 0 ? '-' : debt.dsAmount | currency}}</td>
								<td class="cellSpacing">{{debt.dsLoanAmount | currency}}</td>
								<td class="cellSpacing">{{(debt.Legal_Fee | currency)}}</td>
								<td class="cellSpacing">{{debt.Settlement_Amount | currency}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="float-child-left-main-loan-calc">
					<h5 style="text-align: center">
						{{ Payments.Term_Type }} Scheduled Payments
					</h5>
					<h2 style="font-weight: bold; text-align: center">
						${{ display_data.Payment }}
					</h2>
					<br />
					<p style="text-align: left; font-size: 20px">
						Principal and Interest(P+I)
						<span style="float: right; font-size: 20px">
							${{ display_data.Scheduled }}
						</span>
					</p>
					<hr />
					<p style="text-align: left; font-size: 20px">
						LPP
						<span style="font-size: 20px; padding-left: 60%">
							${{ display_data.LPP
							}}<span class="supsub"><sup class="superscript">${{ display_data.Insurance }}</sup><sub
									class="subscript">${{ display_data.Tax }}</sub></span>
						</span>
					</p>
					<hr />
					<p style="text-align: left; font-size: 20px">
						Last Payment Amount
						<span style="float: right; font-size: 20px">
							${{ display_data.Last_payment_amount }}
						</span>
					</p>
					<hr />
					<p style="text-align: left; font-size: 20px">
						CU Fee
						<span style="float: right; font-size: 20px">
							${{ display_data.CU_fee }}
						</span>
					</p>
					<hr />

					<table style="width: 100%;">
						<tr>
							<th style="font-size: 20px; text-align: left">Frequency</th>
							<th style="font-size: 20px">P+I</th>
							<th style="font-size: 20px">LPP</th>
							<th style="font-size: 20px">Total</th>
						</tr>
						<tr>
							<td height="50" style="
											text-align: left;
											font-size: 20px;
											width: 30%;
											padding-left: 1%;
										">
								Monthly
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.Amortization_Interest_Monthly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.LPP_Monthly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.Payment_Amount_Insurance_Monthly }}
							</td>
						</tr>
						<tr>
							<td height="50" style="text-align: left; font-size: 20px; padding-left: 1%">
								Bi-weekly
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="bi-weekly-bwp">
								${{ Payments.Amortization_Interest_Biweekly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.LPP_Biweekly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.Payment_Amount_Insurance_Biweekly }}
							</td>
						</tr>
						<tr>
							<td height="50" style="text-align: left; font-size: 20px; padding-left: 1%">
								Weekly
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="weekly-wp">
								${{ Payments.Amortization_Interest_Weekly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.LPP_Weekly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.Payment_Amount_Insurance_Weekly }}
							</td>
						</tr>
						<tr>
							<td height="50" style="text-align: left; font-size: 20px; padding-left: 1%">
								Semi-monthly
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="weekly-wp">
								${{ Payments.Amortization_Interest_SemiMonthly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.LPP_SemiMonthly }}
							</td>
							<td height="50" style="
											text-align: right;
											font-size: 20px;
											padding-right: 1%;
										" id="monthly-mp">
								${{ Payments.Payment_Amount_Insurance_SemiMonthly }}
							</td>
						</tr>
					</table>
				</div>
				<div class="float-child-right-main-loan-calc">
					<div style="padding-right: 5px">
						<h5 style="text-align: center">Total Obligation Entire Term</h5>
						<h2 style="font-weight: bold; text-align: center">
							${{ display_data.Total_obligation_entire_term }}
						</h2>
						<br />
						<p style="text-align: left; font-size: 20px">
							Total Cost of Borrowing
							<span style="float: right; font-size: 20px">
								${{ display_data.Total_cost_of_borrowing }}
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							Funded Date
							<span style="float: right; font-size: 20px">
								{{ display_data.Funded_date }}
							</span>
						</p>
						<hr />

						<p style="text-align: left; font-size: 20px">
							Start Date
							<span style="float: right; font-size: 20px">
								{{ display_data.Start_date }}
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							Maturity Date
							<span style="float: right; font-size: 20px">
								{{ display_data.Maturity_date }}
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							Setup Fee
							<span style="float: right; font-size: 20px">
								${{ display_data.Setup_fee }}
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							Accumulated Interest
							<span style="float: right; font-size: 20px">
								${{ display_data.Accumulative_interest }}
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							LPP total Interest
							<span style="float: right; font-size: 20px">
								${{ display_data.LPP_total_interest }}
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							Interest Rate
							<span style="float: right; font-size: 20px">
								{{ Payments.Interest_Rate }}%
							</span>
						</p>
						<hr />
						<p style="text-align: left; font-size: 20px">
							Term(months)
							<span style="float: right; font-size: 20px">
								{{ display_data.Term }}
							</span>
						</p>
						<hr />
						<br />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="Payments">
		<div id="content" #content>
			<div class="mat-box-shadow margin-333" *ngIf="Payments">
				<table id="customers" #content>
					<tr>
						<th>Payment No.</th>
						<th>Payment Date</th>
						<th>Beginning Balance</th>
						<th>Scheduled Payment</th>
						<th>Interest</th>
						<th>Principal</th>
						<th>Ending Balance</th>
						<th>Cumulative Interest</th>
						<th>LPP</th>
						<th>Ending Balance with LPP</th>
					</tr>

					<tr *ngFor="let payment of ProperSchedule">
						<td>{{ payment.set_Payment_Number }}</td>
						<td>{{ payment.set_Payment_Date }}</td>
						<td>{{ payment.set_Beg_Balance }}</td>
						<td>{{ payment.set_Amortization }}</td>
						<td>{{ payment.set_Interest }}</td>
						<td>{{ payment.set_Principal }}</td>
						<td>{{ payment.set_End_Balance }}</td>
						<td>{{ payment.set_Cumulative_Interest }}</td>
						<td>{{ display_data.LPP }}</td>
						<td>{{ payment.set_LPP_Ending_Balance }}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</div>
<!-- </body>
</html> -->