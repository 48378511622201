<div class="container-fluid" style="width: 100vw; height: 100vh; padding: 0">
	<mat-sidenav-container
		class="reports-sidenav-container"
		style="width: 100%; height: 100%"
	>
		<mat-sidenav
			#sidenav
			mode="side"
			[class.mat-elevation-z4]="true"
			class="reports-sidenav"
			opened="true"
			disableClose="true"
		>
			<mat-nav-list>
				<mat-list-item
					[routerLink]="['reports-dashboard']"
					[routerLinkActive]="['active-reports-link']"
				>
					<span style="display: flex; align-items: center">
						<mat-icon
							style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							"
							>dashboard</mat-icon
						><span
							style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							"
							>Dashboard</span
						>
					</span>
				</mat-list-item>
				<mat-list-item
					[routerLink]="['roll-overs']"
					[routerLinkActive]="['active-reports-link']"
				>
					<span
						style="
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 14px;
						"
						>Roll Overs</span
					>
				</mat-list-item>
			</mat-nav-list>
		</mat-sidenav>
		<mat-sidenav-content>
			<div
				(click)="sidenav.toggle(); toggleSideNav()"
				class="sidenav-toggle-button"
				[ngClass]="isSideNavMenuOpen ? 'sidenav-open' : 'sidenav-closed'"
			>
				<div
					class="icon-bar"
					[@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'line' : 'topX'"
				></div>
				<div
					class="icon-bar"
					[@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'line' : 'hide'"
				></div>
				<div
					class="icon-bar"
					[@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'line' : 'bottomX'"
				></div>
			</div>
			<router-outlet> </router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
