import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { ApiServiceService } from '../../../../api-service.service';
import { CustomFunctionService } from '../../../../custom-function.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-nlscollections',
	templateUrl: './nlscollections.component.html',
	styleUrls: ['./nlscollections.component.scss'],
})
export class NlscollectionsComponent implements OnInit {
	countOverallCollections: number;
	totalOverallAmount: any;

	filteredCollectionsArray: string[] = [];
	countNLScollections: number;
	totalNLScollectionAmount: any;

	totalNLScollectionDefaultAmount: any;

	one_to_thirty_array: string[] = [];
	count_one_to_thirty: number;
	amount_one_to_thirty: any;

	thirtyone_to_sixty_array: string[] = [];
	count_thirtyone_to_sixty: number;
	amount_thirtyone_to_sixty: any;

	sixtyone_to_ninety_array: string[] = [];
	count_sixtyone_to_ninety: number;
	amount_sixtyone_to_ninety: any;

	ninetyone_to_onetwenty_array: string[] = [];
	count_ninetyone_to_onetwenty: number;
	amount_ninetyone_to_onetwenty: any;

	onetwentyone_to_threesixtyfive_array: string[] = [];
	count_onetwentyone_to_threesixtyfive: number;
	amount_onetwentyone_to_threesixtyfive: any;

	threesixtyfive_plus_array: string[] = [];
	count_threesixtyfive_plus: number;
	amount_threesixtyfive_plus: any;

	count_insolvency: number;
	amount_insolvency: any;

	cp_array: string[] = [];
	count_cp: number;
	amount_cp: any;

	cc_array: string[] = [];
	count_cc: number;
	amount_cc: any;

	bk_array: string[] = [];
	count_bk: number;
	amount_bk: any;

	third_party_array: string[] = [];
	count_third_party: number;
	amount_third_party: any;

	deceased_array: string[] = [];
	count_deceased: number;
	amount_deceased: any;

	timestamp: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private route: Router
	) {}

	ngOnInit(): void {
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchNLScollections();
						this.fetchNLSBucketCollections();
						this.fetchNLSinsolvencyCollections();
						this.fetchNLSotherCollections();
					} else {
						this.route.navigate(['login']);
					}
				});
		} else {
			this.route.navigate(['login']);
		}

		// setTimeout(function() {
		//   location.reload();
		// }, 120000);
	}

	// Fetch the the whole collections
	// Break the response to overall, delinquency and default for the first pane information on html
	fetchNLScollections() {
		this.apiService.getnlscollections().subscribe((res) => {
			this.countOverallCollections = res.data.length;
			this.totalOverallAmount = this.customFunction.sumNumbers(
				res.data,
				'current_principal_balance'
			); // Grand Total of current_principal_balance

			// Delinquency  Days past due(internal_DPD)>0
			let arrDelinquency = res.data.filter((item) => item['internal_DPD'] > 0);
			this.countNLScollections = arrDelinquency.length;
			this.totalNLScollectionAmount = this.customFunction.sumNumbers(
				arrDelinquency,
				'current_principal_balance'
			); // Total of current_principal_balance Delinquency

			// Default Days past due(internal_DPD>120
			let arrDefault = res.data.filter((item) => item['internal_DPD'] > 120);
			this.totalNLScollectionDefaultAmount = this.customFunction.sumNumbers(
				arrDefault,
				'current_principal_balance'
			); // Total of current_principal_balance Default
			// console.log(this.totalNLScollectionDefaultAmount)

			// this.countNLScollections = res.data[0]['countNLS']
			// this.totalNLScollectionAmount = res.data[0]['totalNLS']
		});
	}

	// Fetch the collections inclusive of status_code: internal(12), external(13), non responsive(21) and NULL to identify the buckets based on Days past due(internal_DPD)
	// Buckets based on Days past due(internal_DPD): 1-30,31-60,61-90,91-120,121-365,365+
	fetchNLSBucketCollections() {
		this.apiService.getnlsbucketcollections().subscribe((res) => {
			// console.log(res.data)
			// Bucket (1-30)
			this.one_to_thirty_array = this.customFunction.bucketFilter(
				res.data,
				'internal_DPD',
				1,
				30
			);
			if (this.one_to_thirty_array.length != null) {
				this.count_one_to_thirty = this.one_to_thirty_array.length;
				this.amount_one_to_thirty = this.customFunction.sumNumbers(
					this.one_to_thirty_array,
					'current_principal_balance'
				);
			} else {
				this.count_one_to_thirty = 0;
				this.amount_one_to_thirty = 0;
			}

			// Bucket (31-60)
			this.thirtyone_to_sixty_array = this.customFunction.bucketFilter(
				res.data,
				'internal_DPD',
				31,
				60
			);
			if (this.thirtyone_to_sixty_array.length != null) {
				this.count_thirtyone_to_sixty = this.thirtyone_to_sixty_array.length;
				this.amount_thirtyone_to_sixty = this.customFunction.sumNumbers(
					this.thirtyone_to_sixty_array,
					'current_principal_balance'
				);
			} else {
				this.count_thirtyone_to_sixty = 0;
				this.amount_thirtyone_to_sixty = 0;
			}

			// Bucket (61-90)
			this.sixtyone_to_ninety_array = this.customFunction.bucketFilter(
				res.data,
				'internal_DPD',
				61,
				90
			);
			if (this.sixtyone_to_ninety_array.length != null) {
				this.count_sixtyone_to_ninety = this.sixtyone_to_ninety_array.length;
				this.amount_sixtyone_to_ninety = this.customFunction.sumNumbers(
					this.sixtyone_to_ninety_array,
					'current_principal_balance'
				);
			} else {
				this.count_sixtyone_to_ninety = 0;
				this.amount_sixtyone_to_ninety = 0;
			}

			// Bucket (91-120)
			this.ninetyone_to_onetwenty_array = this.customFunction.bucketFilter(
				res.data,
				'internal_DPD',
				91,
				120
			);
			if (this.ninetyone_to_onetwenty_array.length != null) {
				this.count_ninetyone_to_onetwenty =
					this.ninetyone_to_onetwenty_array.length;
				this.amount_ninetyone_to_onetwenty = this.customFunction.sumNumbers(
					this.ninetyone_to_onetwenty_array,
					'current_principal_balance'
				);
			} else {
				this.count_ninetyone_to_onetwenty = 0;
				this.amount_ninetyone_to_onetwenty = 0;
			}

			// Bucket (121-365)
			this.onetwentyone_to_threesixtyfive_array =
				this.customFunction.bucketFilter(res.data, 'internal_DPD', 121, 365);
			if (this.onetwentyone_to_threesixtyfive_array.length != null) {
				this.count_onetwentyone_to_threesixtyfive =
					this.onetwentyone_to_threesixtyfive_array.length;
				this.amount_onetwentyone_to_threesixtyfive =
					this.customFunction.sumNumbers(
						this.onetwentyone_to_threesixtyfive_array,
						'current_principal_balance'
					);
			} else {
				this.count_onetwentyone_to_threesixtyfive = 0;
				this.amount_onetwentyone_to_threesixtyfive = 0;
			}

			// Bucket (365+)
			this.threesixtyfive_plus_array = res.data.filter(
				(item) => item['internal_DPD'] > 365
			);
			if (this.onetwentyone_to_threesixtyfive_array.length != null) {
				this.count_threesixtyfive_plus = this.threesixtyfive_plus_array.length;
				this.amount_threesixtyfive_plus = this.customFunction.sumNumbers(
					this.threesixtyfive_plus_array,
					'current_principal_balance'
				);
			} else {
				this.count_threesixtyfive_plus = 0;
				this.amount_threesixtyfive_plus = 0;
			}
		});
	}

	// Fetch insolvency collections (BK(10,27), CC(25,26), CP(11,24)): Excluded status_code null
	fetchNLSinsolvencyCollections() {
		this.apiService.getnlsinsolvencycollections().subscribe((res) => {
			// console.log(res.data)
			this.count_insolvency = res.data.length;
			this.amount_insolvency = this.customFunction.sumNumbers(
				res.data,
				'current_principal_balance'
			);

			// CP (CP+CP Arrangement)
			this.cp_array = this.customFunction.filterTwoCompares(
				res.data,
				'status_code_no',
				11,
				24
			);
			this.count_cp = this.cp_array.length;
			this.amount_cp = this.customFunction.sumNumbers(
				this.cp_array,
				'current_principal_balance'
			);

			// CC (CC+CC Arrangement)
			this.cc_array = this.customFunction.filterTwoCompares(
				res.data,
				'status_code_no',
				25,
				26
			);
			this.count_cc = this.cc_array.length;
			this.amount_cc = this.customFunction.sumNumbers(
				this.cc_array,
				'current_principal_balance'
			);

			// BK (BK+BK Arrangement)
			this.bk_array = this.customFunction.filterTwoCompares(
				res.data,
				'status_code_no',
				10,
				27
			);
			this.count_bk = this.bk_array.length;
			this.amount_bk = this.customFunction.sumNumbers(
				this.bk_array,
				'current_principal_balance'
			);
		});
	}

	// Fetch the rest of the collections (Third party(17), deceased(19), lpp claim(14))
	fetchNLSotherCollections() {
		this.apiService.getnlsothercollections().subscribe((res) => {
			// console.log(res.data)
			this.third_party_array = this.customFunction.singleFilterName(
				res.data,
				'status_code_no',
				17
			);
			this.count_third_party = this.third_party_array.length;
			this.amount_third_party = this.customFunction.sumNumbers(
				this.third_party_array,
				'current_principal_balance'
			);

			this.deceased_array = this.customFunction.singleFilterName(
				res.data,
				'status_code_no',
				19
			);
			this.count_deceased = this.deceased_array.length;
			this.amount_deceased = this.customFunction.sumNumbers(
				this.deceased_array,
				'current_principal_balance'
			);
		});
	}

	// Window Refresh
	refresh() {
		window.location.reload();
	}
}
