<div class="login-page">
	<div class="bottomright">{{ appVersion }}</div>
	<div class="form">
		<img src="/assets/images/lmcredit.png" alt="LM Credit Logo" height="35" style="margin: 0 15px 15px 15px" />
		<form class="login-form" [formGroup]="signinForm" (submit)="Signin($event)">
			<input id="username" type="text" placeholder="username" [formControl]="signinForm.controls['username']" />
			<small *ngIf="
					signinForm.controls['username'].hasError('required') &&
					signinForm.controls['username'].touched
				" class="form-error-msg">
				Username is required
			</small>

			<input id="password" type="password" placeholder="password" [formControl]="signinForm.controls['password']"
				value="" /><small *ngIf="
					signinForm.controls['password'].hasError('required') &&
					signinForm.controls['password'].touched
				" class="form-error-msg">
				Password is required
			</small>
			<button [disabled]="signinForm.invalid">login</button>
			<div style="margin-top: 20px">
				<!-- <a
					href="javascript:void(0)"
					(click)="handleCantLogin(signinForm.controls['username'].value)"
					>Can't log in?</a
				> -->
				<a href="javascript:void(0)" (click)="openMoreDetails()">Can't log in?</a>
			</div>
		</form>
	</div>
</div>