export class loanMeta {
	accrued_interest;
	payoff;
	total_due;
	Status;
	status_code;
	amortized_payment_amount;
	closed_date;
	current_rate;
	loan_group;
	maturity_date;
	open_date;
	origination_date;
	principal_period;
	loan_status;
	loan_group_no;
	account_no;
	eff_fees_balance;
	perdiem;
	insurance_tax;
	insurance_premium;
	insurance;
}
