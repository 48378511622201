import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../api-service.service';
import { Router } from '@angular/router';
import { CustomFunctionService } from '../../../custom-function.service';

@Component({
	selector: 'app-salesdashboard',
	templateUrl: './salesdashboard.component.html',
	styleUrls: ['./salesdashboard.component.scss'],
})
export class SalesdashboardComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private _routerLink: Router,
		private customFunction: CustomFunctionService
	) {}

	// Overall
	totalApplications: any;
	totalAmount: any;

	incompleteArray: any;
	totalIncomplete: any;
	totalIncompleteAmount: any;

	needsDocsArray: any;
	totalNeedsDocs: any;
	totalNeedsDocsAmount: any;

	pendingECArray: any;
	totalPendingEC: any;
	totalPendingECAmount: any;

	pendingVOEArray: any;
	totalPendingVOE: any;
	totalPendingVOEAmount: any;

	timestamp = Date();
	groupedStage: string[] = []; // Array to store the new filtered and calculated objects
	groupedAgency: string[] = []; // Array to store the new filtered and calculated objects

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchDeals();
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	// API call to fetch the deals created for today
	fetchDeals() {
		this.apiService.getDealsCreatedDashboard().subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			// console.log(arr.data)

			this.totalApplications = arr.info.count; // Deals count from the response

			this.totalAmount = this.customFunction
				.sumNumbers(arr.data, 'Amount')
				.toFixed(2);

			const filterStage = this.customFunction.filterDuplicates(
				arr.data,
				'Stage'
			); // Filter duplicate Agency

			//  Loop the filtered stage through the response array
			for (let i = 0; i < filterStage.length; i++) {
				let countStage = 0;
				let subTotalAmount = 0;
				let stage = '';
				for (let j = 0; j < arr.data.length; j++) {
					if (filterStage[i] === arr.data[j].Stage) {
						stage = arr.data[j].Stage; // stage name
						subTotalAmount += arr.data[j].Amount; // sum the amount of the stage occurrences
						countStage++; // count the stage occurrences
					}
				}
				this.groupedStage.push(
					new this.customFunction.groupByName(stage, countStage, subTotalAmount)
				); // Push the loop objects to the new array list: groupedStageInfo
			}
			this.customFunction.sortColumn(this.groupedStage, 'amount', -1, 1);

			const filterAgency = this.customFunction.filterDuplicates(
				arr.data,
				'Account_Name.Account_Name'
			); // Filter duplicate Agency

			//  Loop the filtered Agency through the response array
			for (let i = 0; i < filterAgency.length; i++) {
				let countAgency = 0;
				let subTotalAmount = 0;
				let agency = '';
				for (let j = 0; j < arr.data.length; j++) {
					if (filterAgency[i] === arr.data[j]['Account_Name.Account_Name']) {
						agency = arr.data[j]['Account_Name.Account_Name']; // stage name
						subTotalAmount += arr.data[j].Amount; // sum the amount of the stage occurrences
						countAgency++; // count the stage occurrences
					}
				}
				this.groupedAgency.push(
					new this.customFunction.groupByName(
						agency,
						countAgency,
						subTotalAmount
					)
				); // Push the loop objects to the new array list: groupedStageInfo
			}

			this.customFunction.sortColumn(this.groupedAgency, 'amount', -1, 1);

			this.incompleteArray = this.customFunction.singleFilterName(
				arr.data,
				'Stage',
				'Incomplete Application'
			); // Filter the response with the API Name and create a new list
			this.totalIncomplete = this.incompleteArray.length; // Length of the new array
			this.totalIncompleteAmount = this.customFunction.sumNumbers(
				this.incompleteArray,
				'Amount'
			); // Total Amount of the new array

			this.needsDocsArray = this.customFunction.singleFilterName(
				arr.data,
				'Stage',
				'Needs Documents'
			);
			this.totalNeedsDocs = this.needsDocsArray.length;
			this.totalNeedsDocsAmount = this.customFunction.sumNumbers(
				this.needsDocsArray,
				'Amount'
			); // Total Amount of the new array

			this.pendingECArray = this.customFunction.singleFilterName(
				arr.data,
				'Stage',
				'Pending EC'
			);
			this.totalPendingEC = this.pendingECArray.length;
			this.totalPendingECAmount = this.customFunction.sumNumbers(
				this.pendingECArray,
				'Amount'
			); // Total Amount of the new array

			this.pendingVOEArray = this.customFunction.singleFilterName(
				arr.data,
				'Stage',
				'Pending VOE'
			);
			this.totalPendingVOE = this.pendingVOEArray.length;
			this.totalPendingVOEAmount = this.customFunction.sumNumbers(
				this.pendingVOEArray,
				'Amount'
			); // Total Amount of the new array
		});
	}

	// Window Refresh
	refresh() {
		window.location.reload();
		// setTimeout(function() {
		//   location.reload();
		// }, 120000);
	}
}
