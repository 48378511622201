import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';
import { Sort } from '@angular/material/sort';

export interface Head {
	groupName: string;
	badDebt: number;
	fundedAmount: number;
	countDeliquency: number;
	countFunded: number;
	delinquencyRate: number;
	defaultAmount: number;
	countDefault: number;
	defaultRate: number;
}

@Component({
	selector: 'app-nlscreditorcollectionscollector',
	templateUrl: './nlscreditorcollectionscollector.component.html',
	styleUrls: ['./nlscreditorcollectionscollector.component.scss'],
})
export class NlscreditorcollectionscollectorComponent implements OnInit {
	agencyName: string;
	creditorName: string;
	groupCollectorArray: Head[] = [];
	sortedData: Head[];
	countGroupBadDebt: number;
	amountGroupBadDebt: any;
	countGroupFunded: number;
	amountGroupFunded: any;
	countGroupDefault: number;
	amountGroupDefault: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private router: ActivatedRoute,
		private _routerLink: Router
	) {}

	ngOnInit(): void {
		// Check for valid session on pages
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						// Get the creditor name the nlsagencygrouped page link
						this.router.queryParams.subscribe((params) => {
							(this.creditorName = params['creditor']),
								(this.agencyName = params['agency']);
						});
						// Send the creditor name to get the agencies listed under the creditor
						this.apiService
							.getNLScreditorCollectionsCollector(
								this.creditorName,
								this.agencyName
							)
							.subscribe((res) => {
								if (res.message === 'Success') {
									const filterCollectors = this.customFunction.filterDuplicates(
										res.data,
										'CollectorName'
									);

									// Loop through the filtered provinces
									for (let i = 0; i < filterCollectors.length; i++) {
										let countDeliquency = 0;
										let countDefault = 0;
										let fieldName = '';
										let badDebt = 0;
										let defaultAmount = 0;
										let fundedAmount = 0;
										let countFunded = 0;
										let delinquencyRate = '';
										let defaultRate = '';

										// Loop through the overall raw data
										for (let j = 0; j < res.data.length; j++) {
											// If filtered collector match the raw data province
											if (filterCollectors[i] === res.data[j].CollectorName) {
												fieldName = res.data[j].CollectorName; // Get the collector name
												fundedAmount += res.data[j].original_note_amount; // Add the orignal funded amount to the respective collector
												countFunded++; // count the funded occurrences
												// Delinquency
												if (res.data[j].internal_DPD > 0) {
													badDebt += res.data[j].current_principal_balance; // Add the principal balance to the respective collector
													countDeliquency++; // count the delinquency occurrences
												}
												// Default
												if (res.data[j].internal_DPD > 120) {
													defaultAmount +=
														res.data[j].current_principal_balance; // Add the principal balance to the respective collector
													countDefault++; // count the default occurrences
												}
											}
										}

										delinquencyRate = (
											(badDebt / fundedAmount) *
											100
										).toFixed();
										defaultRate = (
											(defaultAmount / fundedAmount) *
											100
										).toFixed();

										this.groupCollectorArray.push(
											new this.customFunction.groupCollections(
												fieldName,
												badDebt,
												fundedAmount,
												countDeliquency,
												countFunded,
												parseInt(delinquencyRate),
												defaultAmount,
												countDefault,
												parseInt(defaultRate)
											)
										); // Push the loop objects to the new array list
										this.countGroupBadDebt = this.customFunction.sumNumbers(
											this.groupCollectorArray,
											'countDeliquency'
										); // Total up the numbers
										this.amountGroupBadDebt = this.customFunction.sumNumbers(
											this.groupCollectorArray,
											'badDebt'
										);
										this.countGroupFunded = this.customFunction.sumNumbers(
											this.groupCollectorArray,
											'countFunded'
										);
										this.amountGroupFunded = this.customFunction.sumNumbers(
											this.groupCollectorArray,
											'fundedAmount'
										);
										this.countGroupDefault = this.customFunction.sumNumbers(
											this.groupCollectorArray,
											'countDefault'
										);
										this.amountGroupDefault = this.customFunction.sumNumbers(
											this.groupCollectorArray,
											'defaultAmount'
										);
									}
									this.sortData({ active: 'defaultRate', direction: 'desc' });
								} else {
									this.groupCollectorArray = [];
									this.countGroupBadDebt = 0;
									this.amountGroupBadDebt = 0;
									this.countGroupFunded = 0;
									this.amountGroupFunded = 0;
									this.countGroupDefault = 0;
									this.amountGroupDefault = 0;
								}
							});
					} else {
						this._routerLink.navigate(['login']);
					}
				});
		} else {
			this._routerLink.navigate(['login']);
		}
	}

	sortData(sort: Sort) {
		const data = this.groupCollectorArray.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData = data;
			return;
		}

		this.sortedData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'groupName':
					return this.customFunction.compare(a.groupName, b.groupName, isAsc);
				case 'badDebt':
					return this.customFunction.compare(a.badDebt, b.badDebt, isAsc);
				case 'fundedAmount':
					return this.customFunction.compare(
						a.fundedAmount,
						b.fundedAmount,
						isAsc
					);
				case 'countDeliquency':
					return this.customFunction.compare(
						a.countDeliquency,
						b.countDeliquency,
						isAsc
					);
				case 'countFunded':
					return this.customFunction.compare(
						a.countFunded,
						b.countFunded,
						isAsc
					);
				case 'delinquencyRate':
					return this.customFunction.compare(
						a.delinquencyRate,
						b.delinquencyRate,
						isAsc
					);
				case 'defaultAmount':
					return this.customFunction.compare(
						a.defaultAmount,
						b.defaultAmount,
						isAsc
					);
				case 'countDefault':
					return this.customFunction.compare(
						a.countDefault,
						b.countDefault,
						isAsc
					);
				case 'defaultRate':
					return this.customFunction.compare(
						a.defaultRate,
						b.defaultRate,
						isAsc
					);
				default:
					return 0;
			}
		});
	}
}
