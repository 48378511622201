<div class="container-fluid" id="print-section">
  <h3 style="margin: 15px 0 5px 10px">UW Performance Report</h3>
  <div class="row row-cols-12" *ngIf="!isDashboard">
    <div class="col-2">
      <label class="form-label">Start Date</label>
      <input type="date" class="form-control" [(ngModel)]="startDate"/>
    </div>
    <div class="col-2">
      <label class="form-label">End Date</label>
      <input type="date" class="form-control" [(ngModel)]="endDate"/>
    </div>
    <div class="col-1">
      <br>
      <button class="btn btn-success" (click)="getUWPerformanceReport()" [disabled]="!startDate || !endDate">Submit</button>
    </div>
    <div class="col-1">
      <br>
<!--      <button class="btn btn-secondary" (click)="exportToExcel()" *ngIf="uwpList?.length">Export</button>-->
      <button matSuffix mat-icon-button class="print-button" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
        <mat-icon>print</mat-icon>
      </button>
    </div>
  </div>
  <div class="row row-cols-12" *ngIf="uwpList?.length">
    <div class="col-12">
      <table #uwpTable class="table table-bordered table-hover uwp-table">
        <thead>
        <tr>
          <th>Underwriter</th>
          <th>Loan Interviews</th>
          <th>Approved</th>
          <th>Declined</th>
          <th>File Review</th>
          <th>Needs Documents</th>
          <th>Frontend Checklist</th>
          <th>Backend Checklist</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of uwpList">
          <td>{{item['created_by']}}</td>
          <td>{{item['Loan_Interview_Count']}}</td>
          <td>{{item['Approved']}} ({{item['Approved_Percent'] || 0}}%)</td>
          <td>{{item['Declined']}} ({{item['Declined_Percent'] || 0}}%)</td>
          <td>{{item['File_Review']}}</td>
          <td>{{item['Needs_Documents_Missing_Docs']}}</td>
          <td>{{item['Front_End_Status_Complete']}}</td>
          <td>{{item['Back_End_Status_Complete']}}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Totals</td>
          <td>{{uwpTotals['Loan_Interview_Count']}}</td>
          <td>{{uwpTotals['Approved']}} ({{uwpTotals['Approved_Percent']?.toFixed(2) || 0}}%)</td>
          <td>{{uwpTotals['Declined']}} ({{uwpTotals['Declined_Percent']?.toFixed(2) || 0}}%)</td>
          <td>{{uwpTotals['File_Review']}}</td>
          <td>{{uwpTotals['Needs_Documents_Missing_Docs']}}</td>
          <td>{{uwpTotals['Front_End_Status_Complete']}}</td>
          <td>{{uwpTotals['Back_End_Status_Complete']}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
