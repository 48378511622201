<div class="container-fluid" style="width: 100vw; height: 100vh; padding: 0">
	<mat-sidenav-container class="reports-sidenav-container" style="width: 100%; height: 100%; position: relative">
		<mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" class="reports-sidenav"
			[opened]="!isLandedFromZoho" disableClose="true">
			<mat-nav-list>
				<mat-list-item (click)="updateSelectedTab('general-information')" [ngClass]="
						selectedTab === 'general-information' ? 'active-reports-link' : ''
					" *ngIf="(!isLandedFromZoho || selectedTab === 'general-information')">
					<span style="display: flex; align-items: center">
						<i class="fa fa-info-circle" style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							" aria-hidden="true"></i>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">General</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('pandadocs')"
					[ngClass]="selectedTab === 'pandadocs' ? 'active-reports-link' : ''"
					*ngIf="pandaDocsAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'pandadocs')">
					<span style="display: flex; align-items: center">
						<i class="fa fa-file-text" style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							" aria-hidden="true"></i>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Documents</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('checklist')"
					[ngClass]="selectedTab === 'checklist' ? 'active-reports-link' : ''"
					*ngIf="checklistAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'checklist')">
					<span style="display: flex; align-items: center">
						<mat-icon style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							">checklist_rtl</mat-icon>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Checklist</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('underwriting')" [ngClass]="
						selectedTab === 'underwriting' ? 'active-reports-link' : ''
					" *ngIf="underwritingAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'underwriting')">
					<span style="display: flex; align-items: center">
						<mat-icon style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							">image_search</mat-icon>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Underwriting</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('calculator')"
					[ngClass]="selectedTab === 'calculator' ? 'active-reports-link' : ''"
					*ngIf="loanCalculatorAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'calculator')">
					<span style="display: flex; align-items: center">
						<i class="fa fa-calculator" style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							" aria-hidden="true"></i>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Loan Calculator</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('transunion')"
					[ngClass]="selectedTab === 'transunion' ? 'active-reports-link' : ''"
					*ngIf="transunionAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'transunion')">
					<span style="display: flex; align-items: center">
						<mat-icon style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							">credit_score</mat-icon>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Transunion</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('inverite')"
					[ngClass]="selectedTab === 'inverite' ? 'active-reports-link' : ''"
					*ngIf="inveriteAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'inverite')">
					<span style="display: flex; align-items: center">
						<i class="fa fa-university" style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							" aria-hidden="true"></i>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Inverite</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('loaninterview')" [ngClass]="
						selectedTab === 'loaninterview' ? 'active-reports-link' : ''
					" *ngIf="loanInterviewAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'loaninterview')">
					<span style="display: flex; align-items: center">
						<mat-icon style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							">phone_in_talk</mat-icon>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Loan Interview</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('banking')" [ngClass]="
						selectedTab === 'banking'
							? 'active-reports-link'
							: ''
					">
					<span style="display: flex; align-items: center">
						<mat-icon style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							">account_balance_wallet</mat-icon>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Banking</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('oldCalculator')"
					[ngClass]="selectedTab === 'oldCalculator' ? 'active-reports-link' : ''"
					*ngIf="oldloanCalculatorAccessRoles.includes(employeeId) && (!isLandedFromZoho || selectedTab === 'oldCalculator')">
					<span style="display: flex; align-items: center">
						<i class="fa fa-calculator" style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							" aria-hidden="true"></i>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Old Loan Calculator</span>
					</span>
				</mat-list-item>
				<mat-list-item (click)="updateSelectedTab('underwriting-exception')" [ngClass]="
						selectedTab === 'underwriting-exception'
							? 'active-reports-link'
							: ''
					" *ngIf="exceptionAccessRoles.includes(roleID) && (!isLandedFromZoho || selectedTab === 'underwriting-exception')">
					<span style="display: flex; align-items: center">
						<mat-icon style="
								margin-right: 5px;
								height: 16px;
								width: 16px;
								font-size: 16px;
							">back_hand</mat-icon>
						<span style="
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 14px;
							">Exception</span>
					</span>
				</mat-list-item>
			</mat-nav-list>
		</mat-sidenav>

		<mat-sidenav-content>
			<!--      <div (click)="sidenav.toggle();toggleSideNav()" class="sidenav-toggle-button" [ngClass]="isSideNavMenuOpen ? 'sidenav-open' : 'sidenav-closed'">-->
			<!--        <div class="icon-bar" [@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'line' : 'topX'"></div>-->
			<!--        <div class="icon-bar" [@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'line' : 'hide'"></div>-->
			<!--        <div class="icon-bar" [@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'line' : 'bottomX'"></div>-->
			<!--      </div>-->
			<div style="display: flex">
				<div (click)="sidenav.toggle(); toggleSideNav()" class="sidenav-toggle-button"
					[ngClass]="isSideNavMenuOpen ? 'sidenav-open' : 'sidenav-closed'">
					<i class="fa fa-chevron-right" aria-hidden="true"
						[@sideNavMenuAnimationState]="isSideNavMenuOpen ? 'right' : 'left'"></i>
				</div>
				<app-summary-bar [expandSummaryPanel]="selectedTab === 'general-information'"></app-summary-bar>
			</div>
			<div style="padding: 10px; height: 100%">
				<app-generalinformation [hidden]="selectedTab !== 'general-information'" [summaryData]="summaryData"
					*ngIf="(!isLandedFromZoho || selectedTab === 'general-information')"></app-generalinformation>
				<app-pandadocs [hidden]="selectedTab !== 'pandadocs'" [summaryData]="summaryData"
					*ngIf="(!isLandedFromZoho || selectedTab === 'pandadocs')"></app-pandadocs>
				<app-checklist [hidden]="selectedTab !== 'checklist'" [summaryData]="summaryData"
					*ngIf="(!isLandedFromZoho || selectedTab === 'checklist')"></app-checklist>
				<app-underwriting [hidden]="selectedTab !== 'underwriting'"
					*ngIf="(!isLandedFromZoho || selectedTab === 'underwriting')"></app-underwriting>
				<app-loancalculator [hidden]="selectedTab !== 'calculator'"
					*ngIf="(!isLandedFromZoho || selectedTab === 'calculator')"></app-loancalculator>
				<app-transunion [hidden]="selectedTab !== 'transunion'"
					*ngIf="(!isLandedFromZoho || selectedTab === 'transunion')"></app-transunion>
				<app-inverite [hidden]="selectedTab !== 'inverite'"
					*ngIf="(!isLandedFromZoho || selectedTab === 'inverite')"></app-inverite>
				<app-loan-interview [hidden]="selectedTab !== 'loaninterview'" [summaryData]="summaryData"
					*ngIf="(!isLandedFromZoho || selectedTab === 'loaninterview')"></app-loan-interview>
				<app-banking-summary [hidden]="selectedTab !== 'banking'"
					*ngIf="(!isLandedFromZoho || selectedTab === 'banking')"></app-banking-summary>
					<app-old-loan-calculator [hidden]="selectedTab !== 'oldCalculator'"
						*ngIf="(!isLandedFromZoho || selectedTab === 'oldCalculator')"></app-old-loan-calculator>
				<app-underwriting-exception [hidden]="selectedTab !== 'underwriting-exception'"
					*ngIf="(!isLandedFromZoho || selectedTab === 'underwriting-exception')"></app-underwriting-exception>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>