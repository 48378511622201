import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiServiceService {
	uri: string = environment.DO_URL;

	loggedInStatus = false;
	private subject = new Subject<any>();
	message: any;
	searchMessage: any;
	signInInfo: any;
	loanInfo: any;
	private messageSource = new BehaviorSubject('default message');
	constructor(private http: HttpClient) { }
	testing() {
		return this.http.get(this.uri + '/testing');
	}
	getIP() {
		return this.http.get(this.uri + '/getIP');
	}
	getLoanInfo() {
		return this.loanInfo;
	}
	sendLoanInfo(data: any) {
		this.loanInfo = data;
	}
	getMessage() {
		return this.message;
	}
	sendMessage(something) {
		this.message = something;
	}
	getSearchMessage() {
		return this.searchMessage;
	}
	sendSearchMessage(something) {
		this.searchMessage = something;
	}
	setLoggedIn(value: boolean) {
		this.loggedInStatus = value;
	}
	get isLoggedIn() {
		return this.loggedInStatus;
	}
	sendSignInInfo(value: any) {
		this.signInInfo = value;
	}
	getSignInInfo() {
		return this.signInInfo;
	}
	sendClickEvent() {
		this.subject.next();
	}
	getClickEvent(): Observable<any> {
		return this.subject.asObservable();
	}
	verify_signin(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/verify-signin', data);
	}

	sendLoginFailureEmail(username: string, clientSubmission: any) {
		const data = {
			username,
			clientSubmission
		};
		return this.http.post(this.uri + '/sendLoginFailureEmail', data);
	}
	getLenders() {
		return this.http.get(this.uri + '/getlenders');
	}
	deleteLenders(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/deletelenders', data);
	}
	addLender(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/addlender', data);
	}
	updateAll(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/updateAll', data);
	}
	getProvinces() {
		return this.http.get(this.uri + '/getProvinces');
	}
	getFilteredLenders(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/filteredTable', data);
	}
	updateLender(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/updateLender', data);
	}
	calculate_am(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/calculateAmortization', data);
	}
	isLPPAllowed(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/isLPPAllowed', data);
	}
	calculate_am_click_loan(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/calculate_am_click_loan', data);
	}
	calculateAmortization_months(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/calculateAmortization-months', data);
	}
	calculateEnahanced(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/calculateEnahanced', data);
	}
	getGoogleDrive(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/uploadGD', data);
	}
	getAllZohoApplications() {
		return this.http.get(this.uri + '/getAllApplications');
	}
	getZohoApplication(application_id: any) {
		const data = {
			application_id: application_id,
		};
		return this.http.post(this.uri + '/getZohoApplication', data);
	}
	updateZohoApplication(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/updateZohoApplication', data);
	}
	updateLoanPaymentsMonthlyUnderwriting(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(
			this.uri + '/updateLoanPaymentsMonthlyUnderwriting',
			data
		);
	}
	updateDiffCollections(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/syncCollectionsTable', data);
	}
	getNLSCollections() {
		return this.http.get(this.uri + '/getNLSCollections');
	}
	getStatuses() {
		return this.http.get(this.uri + '/getStatuses');
	}
	getFollowUpDate(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getFollowUpDate', data);
	}
	getLoans(status: any, region: any) {
		const data = {
			status: status,
			region: region,
		};
		return this.http.post(this.uri + '/getLoans', data);
	}
	getFilteredCollections(
		status: any,
		region: any,
		start,
		finish,
		type_col: any,
		paper_name: any,
		acctrefs_with_status: any
	) {
		const data = {
			status: status,
			region: region,
			start: start,
			finish: finish,
			type_col: type_col,
			paper_name: paper_name,
			acctrefs_with_status: acctrefs_with_status,
		};
		return this.http.post(this.uri + '/getFilteredCollections', data);
	}
	getCollectionCounts(paper_name: any) {
		const data = {
			paper_name: paper_name,
		};
		return this.http.post(this.uri + '/getCollectionCounts', data);
	}
	getUpdatedCollectionCounts(
		paper_name: any,
		status_code_no: any,
		isCollections: any
	) {
		const data = {
			paper_name: paper_name,
			status_code_no: status_code_no,
			isCollections: isCollections,
		};
		return this.http.post(this.uri + '/getUpdatedCollectionCounts', data);
	}
	getPaper(
		status: any,
		region: any,
		start,
		finish,
		type_col: any,
		paper_name: any,
		acctrefs_with_status: any
	) {
		const data = {
			status: status,
			region: region,
			start: start,
			finish: finish,
			type_col: type_col,
			paper_name: paper_name,
			acctrefs_with_status: acctrefs_with_status,
		};
		return this.http.post(this.uri + '/getPaper', data);
	}
	getDeferredDateRange(start_date, end_date) {
		const data = {
			start_date: start_date,
			end_date: end_date,
		};
		return this.http.post(this.uri + '/getDeferredDateRange', data);
	}
	nsfMissed(status: any, region: any, start, finish) {
		const data = {
			status: status,
			region: region,
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/nsfMissed', data);
	}
	expiredACHs(status: any, region: any, start, finish, paper_name) {
		const data = {
			status: status,
			region: region,
			start: start,
			finish: finish,
			paper_name: paper_name,
		};
		return this.http.post(this.uri + '/expiredACHs', data);
	}
	updateCollector(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/updateCollector', data);
	}
	getCollectionBucket(start: any, finish: any) {
		const data = {
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getCollectionBucket', data);
	}
	getPtPArrangedCollections(start, finish) {
		const data = {
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getPtPArrangedCollections', data);
	}
	getRolloverCollections(start, finish) {
		const data = {
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getRolloverCollections', data);
	}
	getFollowupCollections(start, finish) {
		const data = {
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getFollowupCollections', data);
	}
	deleteInboxCollection(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/deleteInboxCollection', data);
	}
	getPaymentHistory(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getPaymentHistory', data);
	}
	getPaymentDue(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getPaymentDue', data);
	}
	getAllPaymentDue(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getAllPaymentDue', data);
	}
	getParentPTP(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getParentPTP', data);
	}

	// get everything from ParentPTP table
	getAllParentPTPs() {
		return this.http.get(this.uri + '/getAllParentPTPs');
	}

	getAllPaidPTPs() {
		return this.http.get(this.uri + '/getAllPaidPTPs');
	}
	getAllNSFPTP() {
		return this.http.get(this.uri + '/getAllNSFPTP');
	}
	getAllParentPTPCollections() {
		return this.http.get(this.uri + '/getAllParentPTPCollections');
	}
	getMissedParentPTP() {
		return this.http.get(this.uri + '/getMissedParentPTP');
	}
	getPendingCancellationsPTP() {
		return this.http.get(this.uri + '/getPendingCancellationsPTP');
	}
	getAllCustomerDepositPTP() {
		return this.http.get(this.uri + '/getAllCustomerDepositPTP');
	}
	getLocalParentPTP(acctrefno) {
		const data = {
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/getLocalParentPTP', data);
	}
	getAllAdjustedParentPTP() {
		return this.http.get(this.uri + '/getAllAdjustedParentPTP');
	}
	acceptPTPAdjustedDate(parent_id: any) {
		const data = {
			parent_id: parent_id,
		};
		return this.http.post(this.uri + '/acceptPTPAdjustedDate', data);
	}
	applyPTP(info, userID) {
		const data = {
			info: info,
			userID: userID,
		};
		return this.http.post(this.uri + '/applyPTP', data);
	}
	createPTP(
		collection_info: any,
		something: any,
		date: any,
		comments: any,
		PtP_Amount: any,
		created_by: any,
		payment_type,
		type: string,
		arrangementType: string
	) {
		const data = {
			collection_info: collection_info,
			info: something,
			date: date,
			comments: comments,
			PtP_Amount: PtP_Amount,
			created_by: created_by,
			payment_type: payment_type,
			type,
			arrangementType,
		};
		return this.http.post(this.uri + '/createPTP', data);
	}
	cancelPTPParent(parent_id: any, userID, acctrefno: any) {
		const data = {
			parent_id: parent_id,
			userID: userID,
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/cancelPTPParent', data);
	}
	deletePTPParent(something: any, userID) {
		const data = {
			info: something,
			userID: userID,
		};
		return this.http.post(this.uri + '/deletePTPParent', data);
	}
	updateParentPTP(
		date: any,
		parent_id,
		changed_By,
		userID,
		payment_type,
		payment_amount,
		status
	) {
		const data = {
			date: date,
			parent_id: parent_id,
			changed_By: changed_By,
			userID: userID,
			payment_type: payment_type,
			payment_amount: payment_amount,
			status,
		};
		return this.http.post(this.uri + '/updateParentPTP', data);
	}

	setStatusPTP(status: string, parentID: number, userID: string) {
		const data = {
			status: status,
			parentID: parentID,
			userID: userID
		};
		return this.http.put(this.uri + '/setStatusPTP', data);
	}

	getManualPayments(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getManualPayments', data);
	}
	getAllManualPayments() {
		return this.http.get(this.uri + '/getAllManualPayments');
	}
	createManualPayment(amount: any, date: any, acctrefno: any) {
		const data = {
			amount: amount,
			date: date,
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/createManualPayment', data);
	}
	deleteManualPayment(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/deleteManualPayment', data);
	}
	getDashboard() {
		return this.http.get(this.uri + '/dashboard');
	}
	updateFollowUp(date: any, loan_number: any) {
		const data = {
			date: date,
			loan_number: loan_number,
		};
		return this.http.post(this.uri + '/updateFollowUp', data);
	}
	getDeferredPayments(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getDeferredPayments', data);
	}
	createComment(
		acctrefno: any,
		dealName: any,
		comment: string,
		type: string,
		disposition: any,
		userName: any,
		zohoUserID: any,
		cifnumber: any,
		email_sent: any,
		email_sent_to: any
	) {
		const data = {
			acctrefno: acctrefno,
			dealName: dealName,
			comment: comment,
			type: type,
			disposition: disposition,
			created_by: userName,
			created_by_zoho_id: zohoUserID,
			zoho_app_record_id: cifnumber,
			email_sent: email_sent,
			email_sent_to: email_sent_to
		};
		return this.http.post(this.uri + '/createComment', data);
	}
	getComments(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getComments', data);
	}
	deleteComment(something: any, acctrefno: any) {
		const data = {
			info: something,
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/deleteComment', data);
	}
	// updateComment(comment_info: any, userID: any) {
	//   const data = {
	//     comment_info: comment_info,
	//     userID: userID
	//   }
	//   return this.http.post(this.uri + "/updateComment", data)
	// }
	updateComment(comment_info: any) {
		const data = {
			comment_info: comment_info,
		};
		return this.http.post(this.uri + '/updateComment', data);
	}
	getLoandetails(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getLoandetails', data);
	}
	getLoanGroups() {
		return this.http.get(this.uri + '/getLoanGroups');
	}
	getStandardReport(
		start_date: any,
		end_date: any,
		date_type: any,
		status: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			date_type: date_type,
			status: status,
		};
		return this.http.post(this.uri + '/getStandardReport', data);
	}
	getPaymentBreakdown(
		start_date: any,
		end_date: any,
		status: any,
		loan_group: any,
		report_type: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			status: status,
			loan_group: loan_group,
			report_type: report_type,
		};
		return this.http.post(this.uri + '/payment-breakdown', data);
	}
	getMonthlyAccountInformation(date: any, status: any, loan_group: any) {
		const data = {
			date: date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/monthly-account', data);
	}
	getWeeklyAccountInformation(date: any, status: any, loan_group: any) {
		const data = {
			date: date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/weekly-account', data);
	}
	getInsuranceRemitReport(
		start_date: any,
		end_date: any,
		status: any,
		loan_group: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/insurance-remit', data);
	}
	getAllAmoSchedules(
		start_date: any,
		end_date: any,
		status: any,
		loan_group: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/getAllAmoSchedules', data);
	}
	getPaymentHistoryStatement(loan_number: any) {
		const data = {
			loan_number: loan_number,
		};
		return this.http.post(this.uri + '/getPaymentHistoryStatement', data);
	}
	getCollectionsDashboardRange(start_date, end_date, start, finish) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getCollectionsDashboardRange', data);
	}
	getCollectionsDashboardHistory(start_date, end_date, start, finish) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getCollectionsDashboardHistory', data);
	}
	syncPTP() {
		return this.http.get(this.uri + '/syncPTP');
	}
	getCalculatorInstance(application_id) {
		const data = {
			application_id: application_id,
		};
		return this.http.post(this.uri + '/getCalculatorInstance', data);
	}
	getPayoffStatement(acctrefno) {
		const data = {
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/getPayoffStatement', data);
	}
	getAccruedInterest(payoff_date, acctrefno) {
		const data = {
			payoff_date: payoff_date,
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/getAccruedInterest', data);
	}
	getBucket(start, finish) {
		const data = {
			start: start,
			finish: finish,
		};
		return this.http.post(this.uri + '/getBucket', data);
	}
	getPayoffStatment(loan_number) {
		const data = {
			loan_number: loan_number,
		};
		return this.http.post(this.uri + '/getPayoffStatment', data);
	}
	getNSFCount(acctrefs) {
		const data = {
			acctrefs: acctrefs,
		};
		return this.http.post(this.uri + '/getNSFCount', data);
	}
	getNSFCounttest(acctrefs) {
		const data = {
			acctrefs: acctrefs,
		};
		return this.http.post(this.uri + '/getNSFCounttest', data);
	}
	getACHValidation(
		start_date: any,
		end_date: any,
		status: any,
		loan_group: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/ach-validation', data);
	}
	getDeletedPTPs() {
		return this.http.get(this.uri + '/getDeletedPTPs');
	}
	getHistAccInfo(date: any, status: any, loan_group: any) {
		const data = {
			date: date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/getHistAccInfo', data);
	}
	getCurrentAccInfo(date: any, status: any, loan_group: any) {
		const data = {
			date: date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/getCurrentAccInfo', data);
	}
	getLPPCancellationReport(LPPCancellationReportType: any) {
		const data = {
			LPPCancellationReportType,
		};
		return this.http.post(this.uri + '/getLPPCancellationReport', data);
	}
	getFilteredLPPCancellationReport() {
		return this.http.get(this.uri + '/getFilteredLPPCancellationReport');
	}
	getSpartanReport() {
		return this.http.get(this.uri + '/getSpartanReport');
	}
	getMFTLPP(start_date: any, end_date: any) {
		const data = {
			start_date: start_date,
			end_date: end_date
		};
		return this.http.post(this.uri + '/getMFTLPP', data);
	}

	getMFTLPP4476(start_date: any, end_date: any) {
		const data = {
			start_date: start_date,
			end_date: end_date,
		}
		return this.http.post(this.uri + "/getMFTLPP4476", data)
	}
	getIncorrectBillingStartDateReport(
		start_date: any,
		end_date: any,
		maturity_date: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			maturity_date: maturity_date,
		};
		return this.http.post(this.uri + '/getIncorrectBillingStartDate', data);
	}
	getZohoLoggedInUser(user_id: any) {
		const data = {
			user_id: user_id,
		};
		return this.http.post(this.uri + '/getZohoLoggedInUser', data);
	}
	getPayoffInfo(acctrefno, payoff_date) {
		const data = {
			acctrefno: acctrefno,
			payoff_date: payoff_date,
		};
		return this.http.post(this.uri + '/getPayoffInfo', data);
	}
	getNLSAmortizationSchedule(acctrefno, run_through_date) {
		const data = {
			acctrefno: acctrefno,
			run_through_date: run_through_date,
		};
		return this.http.post(this.uri + '/getNLSAmortizationSchedule', data);
	}
	syncDB(today_date: any) {
		const data = {
			today_date: today_date,
		};
		return this.http.post(this.uri + '/syncDB', data);
	}
	processRollovers(today_date: any, start_date: any, end_date: any) {
		const data = {
			today_date: today_date,
			start_date: start_date,
			end_date: end_date,
		};
		return this.http.post(this.uri + '/processRollovers', data);
	}
	pushClientsToNLS(loan_data: any, isTest: boolean) {
		const data = {
			loan_data: loan_data,
			isTest: isTest,
		};
		return this.http.post(this.uri + '/pushClientsToNLS', data);
	}
	pushLoanToNLS(loan_data: any, isTest: boolean) {
		const data = {
			loan_data: loan_data,
			isTest: isTest,
		};
		return this.http.post(this.uri + '/pushLoanToNLS', data);
	}
	pushRecurringToNLS(loan_data: any, isTest: boolean) {
		const data = {
			loan_data: loan_data,
			isTest: isTest,
		};
		return this.http.post(this.uri + '/pushRecurringToNLS', data);
	}

	getValidatedZohoApplication(application_id: any) {
		const data = {
			application_id: application_id,
		};
		return this.http.post(this.uri + '/getValidatedZohoApplication', data);
	}

	getValidatedZohoApplicationUpdated(application_id: any) {
		const data = {
			application_id: application_id
		}
		return this.http.post(this.uri + "/getValidatedZohoApplicationUpdated", data)
	}

	getInveriteBanks() {
		return this.http.get(this.uri + '/getInveriteBanks');
	}
	createInveriteRequest(application: any) {
		const data = {
			application: application,
		};
		return this.http.post(this.uri + '/createInveriteRequest', data);
	}
	updateInverite(inverite_info: any) {
		const data = {
			inverite_info: inverite_info,
		};
		return this.http.post(this.uri + '/updateInverite', data);
	}
	getInveriteRequest(application_id, application_type, bank_id) {
		const data = {
			application_id: application_id,
			application_type: application_type,
			bank_id: bank_id,
		};
		return this.http.post(this.uri + '/getInveriteRequest', data);
	}
	getInveriteRequestApplication(application_id, application_type) {
		const data = {
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/getInveriteRequestApplication', data);
	}
	checkInveriteRequestStatus(guid) {
		const data = {
			guid: guid,
		};
		return this.http.post(this.uri + '/checkInveriteRequestStatus', data);
	}
	showInveriteBankStatements(guid) {
		const data = {
			guid: guid,
		};
		return this.http.post(this.uri + '/showInveriteBankStatements', data);
	}
	getNLSStatuses() {
		return this.http.get(this.uri + '/getNLSStatuses');
	}
	getNLSStatus(acctrefno: number) {
		const data = {
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/getNLSStatus', data);
	}
	updateNLSStatus(acctrefno: any, status_code: any, allstatuses: any) {
		const data = {
			acctrefno: acctrefno,
			status_code: status_code,
			allstatuses: allstatuses,
		};
		return this.http.post(this.uri + '/updateNLSStatus', data);
	}
	getTransunionCreditReport(model: any) {
		const data = {
			model: model,
		};
		return this.http.post(this.uri + '/getTransunionCreditReport', data);
	}
	getEquifaxCreditReport(model: any) {
		const data = {
			model: model,
		};
		return this.http.post(this.uri + '/getEquifaxCreditReport', data);
	}
	getAutomatedPaymentsReport() {
		return this.http.get(this.uri + '/getAutomatedPaymentsReport');
	}
	getLGACHCompanyNEACH() {
		return this.http.get(this.uri + '/getLGACHCompanyNEACH');
	}
	getTransunionDB(application_id: string, application_type: string) {
		const data = {
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/getTransunionDB', data);
	}
	checkIfTUReportExists(application_id: string, application_type: string) {
		const data = {
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/checkIfTUReportExists', data);
	}
	updateTransunionDB(model: any, trans_credit_report) {
		const data = {
			model: model,
			trans_credit_report: trans_credit_report,
		};
		return this.http.post(this.uri + '/updateTransunionDB', data);
	}
	preapprove(model: any, TU_Report) {
		const data = {
			model: model,
			TU_Report: TU_Report,
		};
		return this.http.post(this.uri + '/preapprove', data);
	}
	createZohoApplication(model: any, TU_Report, approval_status: boolean) {
		const data = {
			model: model,
			TU_Report: TU_Report,
			approval_status: approval_status,
		};
		return this.http.post(this.uri + '/createZohoApplication', data);
	}
	uploadTransCreditReportGD(folder_id, base64, file_name) {
		const data = {
			folder_id: folder_id,
			base64: base64,
			file_name: file_name,
		};
		return this.http.post(this.uri + '/uploadTransCreditReportGD', data);
	}
	uploadInveriteReportGD(folder_id, base64, file_name) {
		const data = {
			folder_id: folder_id,
			base64: base64,
			file_name: file_name,
		};
		return this.http.post(this.uri + '/uploadInveriteGD', data);
	}
	getInveritePDF(guid: any) {
		const data = {
			guid: guid,
		};
		return this.http.post(this.uri + '/getInveritePDF', data);
	}
	updateZohoApplication_Transunion(
		transunion_data: any,
		application_id: string,
		application_type
	) {
		const data = {
			transunion_data: transunion_data,
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/updateZohoApplication_Transunion', data);
	}
	updateZohoApplication_Inverite(
		inverite_data: any,
		application_id: string,
		application_type
	) {
		const data = {
			inverite_data: inverite_data,
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/updateZohoApplication_Inverite', data);
	}
	getPTPStatusLoans(ptp_status: any) {
		const data = {
			ptp_status: ptp_status,
		};
		return this.http.post(this.uri + '/getPTPStatusLoans', data);
	}
	// getCollectionPaymentDue(acctrefno) {
	//   return this.http.post(this.uri + "/getCollectionPaymentDue", {acctrefno})
	// }
	checkAcc_Check_Status(guid: any) {
		const data = {
			guid: guid,
		};
		return this.http.post(this.uri + '/checkAcc_Check_Status', data);
	}
	updateAcc_Check(application_id: any, application_type) {
		const data = {
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/updateAcc_Check', data);
	}
	updateTranunionGDStatus(application_id: any, application_type) {
		const data = {
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/updateTranunionGDStatus', data);
	}
	updateZOHO_Upload_Status(application_id: any, application_type) {
		const data = {
			application_id: application_id,
			application_type: application_type,
		};
		return this.http.post(this.uri + '/updateZOHO_Upload_Status', data);
	}
	checkTransunionToken(token: any) {
		const data = {
			token: token,
		};
		return this.http.post(this.uri + '/checkTransunionToken', data);
	}

	getDailyReconReport(
		start_date: any,
		end_date: any,
		status: any,
		loan_group: any
	) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			status: status,
			loan_group: loan_group,
		};
		return this.http.post(this.uri + '/getDailyReconReport', data);
	}
	getBalloonPayments(start_date: any, end_date: any) {
		const data = {
			start_date: start_date,
			end_date: end_date,
		};
		return this.http.post(this.uri + '/getBalloonPayments', data);
	}
	getTransunionCodes() {
		return this.http.get(this.uri + '/getTransunionCodes');
	}
	updateExceptionCalculator(application_id, exception_status) {
		const data = {
			application_id: application_id,
			exception_status: exception_status,
		};
		return this.http.post(this.uri + '/updateExceptionCalculator', data);
	}
	getExceptionCalculator(application_id) {
		const data = {
			application_id: application_id,
		};
		return this.http.post(this.uri + '/getExceptionCalculator', data);
	}

	getLoanConfig(): Observable<any> {
		return this.http.get(this.uri + '/getCCS');
	}

	deleteGuid(guid: string) {
		const data = {
			guid: guid,
		};
		return this.http.post(this.uri + '/deleteGuid', data);
	}
	getPaymentHistorySummary(acctrefno) {
		const data = {
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/getPaymentHistorySummary', data);
	}
	populateAddressFieldsZOHO(
		address: any,
		full_address: any,
		address_only: any,
		application_id: any
	) {
		const data = {
			address: address,
			full_address: full_address,
			address_only: address_only,
			application_id: application_id,
		};
		return this.http.post(this.uri + '/populateAddressFieldsZOHO', data);
	}
	generateHash(model) {
		const data = {
			model: model,
		};
		return this.http.post(this.uri + '/generateHash', data);
	}
	decryptHash(hash, some_model) {
		const data = {
			hash: hash,
			some_model: some_model,
		};
		return this.http.post(this.uri + '/decryptHash', data);
	}
	sendConsentFormEmailClient(model, url) {
		const data = {
			model: model,
			url: url,
		};
		return this.http.post(this.uri + '/sendConsentFormEmailClient', data);
	}
	checkTransunionAdminToken(admin_token: string) {
		const data = {
			admin_token: admin_token,
		};
		return this.http.post(this.uri + '/checkTransunionAdminToken', data);
	}

	/* ******************************************************************************************************************************************************************************* */
	// Get Data to display on the User Management Portal: Local Database
	getAllData(): Observable<any> {
		return this.http.get(this.uri + '/readapplicationform');
	}

	// Get Agency to display on the dropdown of Additional Debt Subform: API GET FROM TESTROUTE
	getAgencyRecords(): Observable<any> {
		return this.http.get(this.uri + '/getAgencyRecords');
	}

	// Get Collector to display on the dropdown of Additional Debt Subform: API GET FROM TESTROUTE
	getCollectorRecords(): Observable<any> {
		return this.http.get(this.uri + '/getCollectorRecords');
	}

	// Get residency to display
	getResidencyDesc(): Observable<any> {
		return this.http.get(this.uri + '/residency');
	}

	// Get Provinces to display
	getProvinceDesc(): Observable<any> {
		return this.http.get(this.uri + '/provinces');
	}

	// Get Marital Status to display
	getMaritalStatus(): Observable<any> {
		return this.http.get(this.uri + '/maritalstatus');
	}

	// Get Number of Dependents to display
	getNumberDependents(): Observable<any> {
		return this.http.get(this.uri + '/numberdependents');
	}

	// Get Best Contact Time to display
	getBestContactTime(): Observable<any> {
		return this.http.get(this.uri + '/bestcontacttime');
	}

	// Get No SIN reason to display
	getNoSinReason(): Observable<any> {
		return this.http.get(this.uri + '/nosinreason');
	}

	// Get Payment Method to display
	getPaymentMethodDesc(): Observable<any> {
		return this.http.get(this.uri + '/paymentmethod');
	}

	// Get Bankruptcy to display
	getBankruptcyDesc(): Observable<any> {
		return this.http.get(this.uri + '/bankruptcy');
	}

	// Get employment type to display
	getEmploymentDesc(): Observable<any> {
		return this.http.get(this.uri + '/employment');
	}

	// Get Sales Agent to display
	getSalesAgent(): Observable<any> {
		return this.http.get(this.uri + '/salesagent');
	}

	// Get Broker to display
	getBroker(): Observable<any> {
		return this.http.get(this.uri + '/broker');
	}

	// Get Creditor to display
	getCreditor(): Observable<any> {
		return this.http.get(this.uri + '/creditor');
	}

	// Get Referral Method to display
	getReferralMethod(): Observable<any> {
		return this.http.get(this.uri + '/referralmethod');
	}

	// Get Agency Location to display
	getAgencyLocation(): Observable<any> {
		return this.http.get(this.uri + '/agencylocation');
	}

	// Get Loan Type to display
	getLoanType(): Observable<any> {
		return this.http.get(this.uri + '/loantype');
	}

	// Get Lead Source to display
	getLeadSource(): Observable<any> {
		return this.http.get(this.uri + '/leadsource');
	}

	// Get Pay Frequency to display
	getPayFrequency(): Observable<any> {
		return this.http.get(this.uri + '/payfrequency');
	}

	// Get Residence Length to display
	getResidenceLength(): Observable<any> {
		return this.http.get(this.uri + '/residencelength');
	}

	// Get Creditor Portfolio desc to display on addtional debt
	getCreditorPortfolioDesc(): Observable<any> {
		return this.http.get(this.uri + '/creditorPortfolio');
	}

	// Get Creditor Portfolio desc to display on addtional debt
	getCreditTypeDesc(): Observable<any> {
		return this.http.get(this.uri + '/creditorType');
	}

	// Create client
	createClient(data: any): Observable<any> {
		return this.http.post(this.uri + '/new-application', data);
	}

	// Push to Zoho Application
	createZohoApplicant(data: any): Observable<any> {
		return this.http.post(this.uri + '/createZohoApplicant', data);
	}

	// Delete the data
	deleteData(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/deleteclients', data);
	}

	// Get single applicant data from local database for edit
	getSingleData(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/applications/edit', data);
	}

	// Get emergency contacts for update
	getEmergencyContacts(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getEmergencyContacts/', data);
	}

	// Get emergency contacts for update
	getAdditionalDebt(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getAdditionalDebt/', data);
	}

	createFolder(data: any): Observable<any> {
		return this.http.post(this.uri + '/createFolderWorkdrive', data);
	}
	// Get FolderID created
	getFolderID(): Observable<any> {
		return this.http.get(this.uri + '/releaseFolderID');
	}

	//upload to local
	uploadLocal(data: any): Observable<any> {
		return this.http.post(this.uri + '/localFile', data);
	}
	checklistUploadFile(
		data: any,
		folderID: any,
		folderName: any,
		fileSuffix: any
	): Observable<any> {
		return this.http.post(this.uri + '/checklistUploadFile', data, {
			params: { folderID, folderName, fileSuffix },
		});
	}
	// checklistUploadFile(data: any, applicationID: any, folderName: any): Observable<any> {
	//   const info = {
	//     data: data,
	//     applicationID: applicationID,
	//     folderName: folderName
	//   }
	//   return this.http.post(this.uri + "/checklistUploadFile", info);
	// }

	// Check clients on checklist exist on NLS or not
	checkExistingClientOnNLS(Deal_Name: any, dob: any): Observable<any> {
		const data = {
			Deal_Name,
			dob,
		};
		return this.http.post(this.uri + '/checkExistingClientOnNLS', data);
	}

	// Upload File to Workdrive
	uploadFileDrive(data: any): Observable<any> {
		return this.http.post(this.uri + '/uploadFileWorkdrive', data);
	}

	// Upload Govt ID File to Workdrive
	uploadFileDriveGovtID(data: any): Observable<any> {
		return this.http.post(this.uri + '/uploadFileWorkdriveGovtID', data);
	}

	// Upload Govt ID File to Workdrive
	uploadFileDriveBank(data: any): Observable<any> {
		return this.http.post(this.uri + '/uploadFileWorkdriveBankStatement', data);
	}

	// Upload Govt ID File to Workdrive
	uploadFileDriveBureau(data: any): Observable<any> {
		return this.http.post(this.uri + '/uploadFileWorkdriveCreditBureau', data);
	}

	// Get Stage Picklist
	getStage(): Observable<any> {
		return this.http.get(this.uri + '/getStage');
	}

	// Get files information uploaded
	checkFiles(): Observable<any> {
		return this.http.get(this.uri + '/releaseFilesUploaded');
	}

	// Get client applications: SEARCH
	getSearchApplication(data: any): Observable<any> {
		return this.http.get(this.uri + '/getSearchApplication/' + data);
	}

	// Get All ZOHO Applications
	getAllZOHOApplications(): Observable<any> {
		return this.http.get(this.uri + '/getAllZOHOApplications');
	}

	// Update individual ZOHO Applications
	updateZohoDeals(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.put(this.uri + '/updateZohoDeals', data);
	}

	// Update individual ZOHO Applications
	updateZohoApplicationInterface(
		something: any,
		zohoAppID: any
	): Observable<any> {
		const data = {
			info: something,
			zohoApplicationID: zohoAppID,
		};
		return this.http.put(this.uri + '/updateZohoApplicationInterface', data);
	}

	// Update the whole data on the applicant
	updateClients(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.put(this.uri + '/updateLocalApplication', data);
	}

	// Update ZOHO Folder name
	updateZohoFolder(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.patch(this.uri + '/updateZohoFolder', data);
	}

	// Get single applicant data for clone from ZOHO CRM
	getSingleAppZOHO(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/applications/clone', data);
	}

	// Get single applicant data for clone from ZOHO CRM
	getContacts(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getContacts', data);
	}

	//******************************************************SUMMARY REPORT************************************ */
	// Get summary of the application
	getSingleAppSummary(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/applications/summary', data);
	}

	// Get App Notes for Summary
	getappNotes(data: any): Observable<any> {
		return this.http.get(this.uri + '/appNotes/' + data);
	}

	// Get Underwriting details
	getUnderwritingDetails(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/underwritingdetails/', data);
	}

	// Add Notes
	addNotes(something: any, id: any, loggedZohoID: any) {
		const data = {
			loggedZohoID: loggedZohoID,
			info: something,
			idInfo: id,
		};
		return this.http.post(this.uri + '/addNotes', data);
	}

	// Get App Notes for Summary
	getappNoteIndividual(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/appNoteIndividual/', data);
	}

	/**
	 *
	 * @param something User Form values
	 * @param noteID Application Note ID
	 * @returns
	 */
	updateAppNotes(something: any, id: any) {
		const data = {
			info: something,
			noteID: id,
		};
		return this.http.post(this.uri + '/updateAppNotes', data);
	}

	updateSummaryUnderwriting(something: any, id: any) {
		const data = {
			info: something,
			underwritingID: id,
		};
		return this.http.post(this.uri + '/updateSummaryUnderwriting', data);
	}

	updateSummaryStage(something: any, id: any): Observable<any> {
		const data = {
			stage: something,
			recordID: id,
		};
		return this.http.post(this.uri + '/updateSummaryStage', data);
	}
	//Update ZOHO application Stage
	updateZohoApplicationStage(applicationID: string, stage: string) {
		const data = {
			applicationID: applicationID,
			stage: stage,
		};
		return this.http.post(this.uri + '/updateZohoApplicationStage', data);
	}

	// Get Stage Picklist for Summary
	getStageSummary(): Observable<any> {
		return this.http.get(this.uri + '/getStageSummary');
	}

	// Get the loan interview questions
	getInterviewQuestions(): Observable<any> {
		return this.http.get(this.uri + '/interviewquestions');
	}

	// UPDATE Loan interview answers on zoho underwriter
	updateLoanInterview(something: any, id: any, ownerID: any): Observable<any> {
		const data = {
			info: something,
			underwritingID: id,
			zoho_owner_id: ownerID,
		};
		return this.http.post(this.uri + '/updateLoanInterview', data);
	}

	// Add local database
	addLoanInterviewlocal(
		something: any,
		recordID: any,
		id: any,
		interviewID: any,
		type: any,
		additional: any,
		tdsr: any,
		loggedName: any
	): Observable<any> {
		const data = {
			info: something,
			zohoRecordID: recordID,
			underwritingID: id,
			createdBy: interviewID,
			appType: type,
			totalAdditional: additional,
			tdsr: tdsr,
			loggedName: loggedName,
		};
		return this.http.post(this.uri + '/addLoanInterviewlocal', data);
	}

	// Get the not subjects
	getNoteSubjects(): Observable<any> {
		return this.http.get(this.uri + '/notesubjects');
	}

	// Get the subject description
	getSubjectDesc(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/subjectDesc', data);
	}

	// Add Notes
	addLoanInterviewNotesZOHO(something: any, id: any, interviewID: any) {
		const data = {
			info: something,
			zohoRecordID: id,
			interviewerZohoID: interviewID,
		};
		return this.http.post(this.uri + '/addLoanInterviewNoteszoho', data);
	}

	// Push the Interviewer name to ZOHO
	zohoInterviewerApplication(
		something: any,
		id: any,
		name: any,
		tdsr: any,
		type: any
	) {
		const data = {
			info: something,
			zohoRecordID: id,
			interviewerName: name,
			tdsr: tdsr,
			appType: type,
		};
		return this.http.post(this.uri + '/zohoInterviewerApplication', data);
	}

	// Add Notes
	addLoanInterviewNotesLocal(
		something: any,
		id: any,
		interviewID: any,
		appName: any,
		interviewer: any
	): Observable<any> {
		const data = {
			info: something,
			zohoRecordID: id,
			interviewerZohoID: interviewID,
			dealName: appName,
			createdBy: interviewer,
		};
		return this.http.post(this.uri + '/addLoanInterviewNoteslocal', data);
	}

	// Add Notes
	getNoteAdded(id: any, interviewID: any): Observable<any> {
		const data = {
			zohoRecordID: id,
			interviewerZohoID: interviewID,
		};
		return this.http.post(this.uri + '/getnoteadded', data);
	}

	// Add Validation Checklist to Local Platform
	addChecklistLocal(
		recordID: any,
		validatorID: any,
		appType: any,
		validID: any,
		dob: any,
		address: any,
		consent: any,
		creditBureau: any,
		income1200: any,
		tdsr: any,
		score: any,
		ltComplete: any,
		finalDecision: any,
		verifyEC: any,
		verifyPOA: any,
		verifyVOE: any,
		loanAmount: any,
		zohoChecklist: any
	): Observable<any> {
		const data = {
			zohoRecordID: recordID,
			validatorZohoID: validatorID,
			applicantType: appType,
			checklistValidID: validID,
			checklistDoB: dob,
			checklistAddress: address,
			checklistConsent: consent,
			checklistCreditBureau: creditBureau,
			checklistIncome1200: income1200,
			checklistTdsr: tdsr,
			checklistScore: score,
			checklistLTComplete: ltComplete,
			checklistFinalDecision: finalDecision,
			checklistVerifyEC: verifyEC,
			checklistVerifyPOA: verifyPOA,
			checklistVerifyVOE: verifyVOE,
			checklistLoanAmount: loanAmount,
			zohoChecklistComplete: zohoChecklist,
		};
		return this.http.post(this.uri + '/addChecklistLocal', data);
	}

	// Update Validation Checklist to Local Platform
	updateChecklistLocal(
		recordID: any,
		validatorID: any,
		appType: any,
		validID: any,
		dob: any,
		address: any,
		consent: any,
		creditBureau: any,
		income1200: any,
		tdsr: any,
		score: any,
		ltComplete: any,
		finalDecision: any,
		verifyEC: any,
		verifyPOA: any,
		verifyVOE: any,
		loanAmount: any,
		zohoChecklist: any
	): Observable<any> {
		const data = {
			zohoRecordID: recordID,
			validatorZohoID: validatorID,
			applicantType: appType,
			checklistValidID: validID,
			checklistDoB: dob,
			checklistAddress: address,
			checklistConsent: consent,
			checklistCreditBureau: creditBureau,
			checklistIncome1200: income1200,
			checklistTdsr: tdsr,
			checklistScore: score,
			checklistLTComplete: ltComplete,
			checklistFinalDecision: finalDecision,
			checklistVerifyEC: verifyEC,
			checklistVerifyPOA: verifyPOA,
			checklistVerifyVOE: verifyVOE,
			checklistLoanAmount: loanAmount,
			zohoChecklistComplete: zohoChecklist,
		};
		return this.http.post(this.uri + '/updateChecklistLocal', data);
	}

	// Push the checklist status on the Application custom field api named Primary_Checklist_Status
	zohoChecklistStatus(
		id: any,
		status: any,
		name: any,
		type: any
	): Observable<any> {
		const data = {
			zohoRecordID: id,
			checklistStatus: status,
			validatorName: name,
			appType: type,
		};
		return this.http.post(this.uri + '/zohoChecklistStatus', data);
	}

	/*
	// Push the checklist status on the Application custom field api named Primary
	zohoPrimaryChecklistStatus(id: any, status: any, name: any){
	  const data = {
		zohoRecordID: id,
		checklistStatus: status,
		validatorName: name
	  }
	  return this.http.post(this.uri + "/zohoCoAppChecklistStatus", data)
	}

	// Push the checklist status on the Application custom field api named Co_applicant_Checklist_Status
	zohoCoAppChecklistStatus(id: any, status: any, name: any){
	  const data = {
		zohoRecordID: id,
		checklistStatus: status,
		validatorName: name
	  }
	  return this.http.post(this.uri + "/zohoCoAppChecklistStatus", data)
	}
  */

	// Get validation checklist Primary
	getEmployeeEmail(zoho_id) {
		const data = { zoho_id: zoho_id }
		return this.http.post(this.uri + "/getEmployeeEmail", data)
	}
	getValidation(recordID: any, type: any): Observable<any> {
		const data = {
			zohoRecordID: recordID,
			appType: type,
		};
		return this.http.post(this.uri + '/getValidation', data);
	}

	// Get validation checklist Co-applicant
	getValidationCoApp(recordID: any, type: any): Observable<any> {
		const data = {
			zohoRecordID: recordID,
			appType: type,
		};
		return this.http.post(this.uri + '/getValidationCoApp', data);
	}

	//*************************************************************ZOHO Dashboard******************************************** */
	// Get deals report Dashboard
	getDealsCreatedDashboard(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedDashboard');
	}

	// Get deals report Underwriting for Underwriting Checklist
	getDealsCreatedUnderwritingChecklist(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingChecklist');
	}

	// Get deals report Underwriting Ready for Loan Interview
	getDealsCreatedUnderwritingRLI(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingRLI');
	}

	// Get deals report Underwriting Ready for Loan Interview Monthly
	getDealsCreatedUnderwritingRLImonthly(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingRLImonthly');
	}

	// Get deals report Underwriting Ready for Credit Bureau
	getDealsCreatedUnderwritingCB(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingCB');
	}

	// Get deals report Underwriting File Review
	getDealsCreatedUnderwritingFR(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingFR');
	}

	// Underwriting Dashboard: UW unresponsive
	getUWunresponsive(): Observable<any> {
		return this.http.get(this.uri + '/getUWunresponsive');
	}

	// Underwriting Dashboard: UW needs docs
	getUWneedsDocs(): Observable<any> {
		return this.http.get(this.uri + '/getUWneedsDocs');
	}

	// Update Call Status from Scheduled Loan Interview: Underwriting Dashboard
	updateCallStatus(call_id: any, something: any, user: any) {
		const data = {
			call_id: call_id,
			info: something,
			user: user,
		};
		return this.http.post(this.uri + '/updateCallStatus', data);
	}

	// Validator Queue Underwriting Complete
	getUnderwritingComplateValidator(): Observable<any> {
		return this.http.get(this.uri + '/getUnderwritingComplateValidator');
	}

	// Get deals report Underwriting File Review
	getDealsCreatedUnderwritingPMA(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingPMA');
	}

	// Get deals report Pipeline
	getDealsCreatedPipeline(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedPipeline');
	}

	// Get deals report Underwriting Dashboard Call schedule loan interview
	getUnderwritingScheduled(): Observable<any> {
		return this.http.get(this.uri + '/getUnderwritingScheduled');
	}

	// Get Stage Picklist for Summary
	getCalls(): Observable<any> {
		return this.http.get(this.uri + '/getCalls');
	}

	// Get deals report Underwriting Dashboard Call schedule loan interview
	getDealsCreatedUnderwritingException(): Observable<any> {
		return this.http.get(this.uri + '/getDealsCreatedUnderwritingException');
	}

	/**************************************************************LOGIN******************************* */
	verifysession(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/verifysession', data);
	}
	updatesession(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/updatesession', data);
	}

	// Insert page history
	insertPageHistory(something: any, id: any, user: any): Observable<any> {
		const data = {
			dealName: something,
			recordID: id,
			username: user,
		};
		return this.http.post(this.uri + '/pagehistory', data);
	}

	// GET page history
	getPageHistory(user: any): Observable<any> {
		const data = {
			username: user,
		};
		return this.http.post(this.uri + '/getpagehistory', data);
	}

	// **************************************************NLS Dashboard************************************************************/
	// Get NLS Data: Local Database
	getNLSFundedDashboard(): Observable<any> {
		return this.http.get(this.uri + '/nlsFundedNow');
	}

	// // Get NLS Data: Local Database
	// getNLSFundedMonth(): Observable<any> {
	//   return this.http.get(this.uri + "/nlsFundedMonth");
	// }

	// Get NLS Data: Local Database
	getNLSFundedMonth(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/nlsFundedMonth', data);
	}

	// Get summary of the application
	getNLSAgencyCollectors(something: any, num: any): Observable<any> {
		const data = {
			info: something,
			monthNum: num,
		};
		return this.http.post(this.uri + '/nlsAgencyCollectors', data);
	}
	//Get Underwriting without specifiying ID
	allunderwritingdetails(page: Number, per_page: Number) {
		const data = {
			page: page,
			per_page: per_page,
		};
		return this.http.post(this.uri + '/allunderwritingdetails', data);
	}

	// GET NLS Collections
	getnlscollections(): Observable<any> {
		return this.http.get(this.uri + '/nlscollections');
	}

	getnlsbucketcollections(): Observable<any> {
		return this.http.get(this.uri + '/nlsbucketcollections');
	}

	getnlsinsolvencycollections(): Observable<any> {
		return this.http.get(this.uri + '/nlsinsolvencycollections');
	}

	getnlsothercollections(): Observable<any> {
		return this.http.get(this.uri + '/nlsothercollections');
	}

	getnlsgroupcollectorcollections(): Observable<any> {
		return this.http.get(this.uri + '/nlscollectorgroupcollections');
	}
	// Overall nls funded Agency
	getnlsoverallfunded(): Observable<any> {
		return this.http.get(this.uri + '/agencycollections');
	}

	// Overall nls funded
	getnlsoverallfundedCheck(start: any, end: any): Observable<any> {
		const data = {
			startDate: start,
			endDate: end,
		};
		return this.http.post(this.uri + '/nlsoverallfundedcheck', data);
	}

	// Get nls collection agency collectors
	getNLSagencyCollectionsCollectors(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/nlsagencycollectionscollectors', data);
	}

	// Get nls collection creditor collectors
	getNLScreditorCollectionsCollector(
		something: any,
		agency: any
	): Observable<any> {
		const data = {
			info: something,
			agencyName: agency,
		};
		return this.http.post(this.uri + '/nlscreditorcollectionscollector', data);
	}

	// Get nls collection client details under respective collectors of the agency
	getNLSagencyCollectionsCollectorClients(
		something: any,
		agency: any
	): Observable<any> {
		const data = {
			info: something,
			agencyName: agency,
		};
		return this.http.post(
			this.uri + '/nlsagencycollectionscollectorclients',
			data
		);
	}

	// Get nls collection portfolio breakdwown
	getNLSportfolioCollectionsbreakdown(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/nlsportfoliocollectionsbreakdown', data);
	}

	getUnderwritingSettings() {
		return this.http.get(this.uri + '/getUnderwritingSettings');
	}
	getApplicationSettings() {
		return this.http.get(this.uri + '/getApplicationSettings');
	}
	getUnderwriters() {
		return this.http.get(this.uri + '/getUnderwriters');
	}
	// getzoho_Employment_Statuses() {
	//   return this.http.get(this.uri + '/getzoho_Employment_Statuses')
	// }
	// getzoho_Industries() {
	//   return this.http.get(this.uri + "/getzoho_Industries")
	// }
	// getzoho_Occupations() {
	//   return this.http.get(this.uri + "/getzoho_Occupations")
	// }
	getzoho_Override_Types(): Observable<any> {
		return this.http.get(this.uri + '/getzoho_Override_Types');
	}
	getzoho_Overrides(): Observable<any> {
		return this.http.get(this.uri + '/getzoho_Overrides');
	}
	getzoho_Expense_Types(): Observable<any> {
		return this.http.get(this.uri + '/getzoho_Expense_Types');
	}
	createUnderwriting(underwriting, subform): Observable<any> {
		const d = {
			underwriting: underwriting,
			subform: subform,
		};
		return this.http.post(this.uri + '/createUnderwriting', d);
	}
	editUnderwritingEntry(underwriting, subform) {
		const d = {
			underwriting: underwriting,
			subform: subform,
		};
		return this.http.post(this.uri + '/editUnderwritingEntry', d);
	}
	deleteUnderwritingEntry(applicationID, applicantType): Observable<any> {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/deleteUnderwritingEntry', data);
	}
	getUnderwritingEntry(applicationID, applicantType) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/getUnderwritingEntry', data);
	}
	// getUnderwritingAddDebts(applicationID, applicantType) {
	//   const data = {
	//     applicationID: applicationID,
	//     applicantType: applicantType
	//   }
	//   return this.http.post(this.uri + "/getUnderwritingAddDebts", data)
	// }
	getUnderwritingExpenses(applicationID, applicantType) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/getUnderwritingExpenses', data);
	}
	getUnderwritingZohoAppDetails(applicationID): Observable<any> {
		const data = {
			applicationID: applicationID,
		};
		return this.http.post(this.uri + '/getUnderwritingZohoAppDetails', data);
	}

	getLookupsdeals(): Observable<any> {
		return this.http.get(this.uri + '/getLookupsdeals');
	}

	getLookupsunderwriting(): Observable<any> {
		return this.http.get(this.uri + '/getLookupsunderwriting');
	}
	ListDocumentsPandadocs(applicationID) {
		const data = {
			applicationID: applicationID,
		};
		return this.http.post(this.uri + '/ListDocumentsPandadocs', data);
	}

	// Exception zoho
	zohoAppException(something: any, applicationID: any): Observable<any> {
		const data = {
			info: something,
			zohoRecordID: applicationID,
		};
		return this.http.post(this.uri + '/zohoAppException', data);
	}

	// Exception zoho Underwriting
	zohoUnderwritingException(
		something: any,
		underwritingID: any
	): Observable<any> {
		const data = {
			info: something,
			underwritingID: underwritingID,
		};
		return this.http.post(this.uri + '/zohoUnderwritingException', data);
	}

	// Exception save Local
	addExceptionslocal(
		something: any,
		applicationID: any,
		ownerID: any,
		name: any
	): Observable<any> {
		const data = {
			info: something,
			zohoRecordID: applicationID,
			zoho_owner_id: ownerID,
			full_name: name,
		};
		return this.http.post(this.uri + '/addExceptionslocal', data);
	}

	getExceptionslocal(applicationID: any): Observable<any> {
		const data = {
			zohoRecordID: applicationID,
		};
		return this.http.post(this.uri + '/getExceptionslocal', data);
	}
	getTransunionStats(applicationID, applicantType) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/getTransunionStats', data);
	}

	updateZohoFromUnderwriting(
		something: any,
		applicationID: any,
		appType: any
	): Observable<any> {
		const data = {
			info: something,
			applicationID: applicationID,
			applicationType: appType,
		};
		return this.http.post(this.uri + '/updateZohoFromUnderwriting', data);
	}

	recordLoanInterviewDuration(timeInfo: any) {
		const data = {
			timeInfo: timeInfo,
		};
		return this.http.post(this.uri + '/recordLoanInterviewDuration', data);
	}
	updateLoanInterviewComplete(applicationID: any, applicantType: any) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/updateLoanInterviewComplete', data);
	}
	updateUnderwritingZoho(underwritingID: any, info: any, expenses: any) {
		const data = {
			underwritingID: underwritingID,
			info: info,
			expenses: expenses,
		};
		return this.http.post(this.uri + '/updateUnderwritingZoho', data);
	}
	addunderwritingincomecalculation(appID: any, appType: any, info: any) {
		const data = {
			zohoRecordID: appID,
			appType: appType,
			info: info,
		};
		return this.http.post(this.uri + '/addunderwritingincomecalculation', data);
	}

	// Get Agency to display on the dropdown of Additional Debt Subform: API GET FROM TESTROUTE
	getAgencies(): Observable<any> {
		return this.http.get(this.uri + '/getAgencies');
	}
	getUnderwritingIDPrimary(applicationID: String) {
		const data = {
			applicationID: applicationID,
		};
		return this.http.post(this.uri + '/getUnderwritingIDPrimary', data);
	}
	getPaymentDueSummary(acctrefno) {
		const data = {
			acctrefno: acctrefno,
		};
		return this.http.post(this.uri + '/getPaymentDueSummary', data);
	}
	searchNLSLoan(searchData: any) {
		const data = {
			searchData: searchData,
		};
		return this.http.post(this.uri + '/searchNLSLoan', data);
	}
	//create Note Local DB
	createNoteLocalDB(
		notes_info: any,
		appName: any,
		zoho_app_record_id: any,
		created_by: any,
		created_by_zoho_id: any,
		email_sent: any,
		email_sent_to: any
	): Observable<any> {
		const data = {
			notes_info: notes_info,
			deal_name: appName,
			zoho_app_record_id: zoho_app_record_id,
			created_by: created_by,
			created_by_zoho_id: created_by_zoho_id,
			email_sent: email_sent,
			email_sent_to: email_sent_to
		};
		return this.http.post(this.uri + '/createNoteLocalDB', data);
	}
	//update Note Local DB
	updateNoteLocalDB(
		noteID: any,
		notes_info: any,
		appName: any,
		zoho_app_record_id: any,
		modified_by: any,
		modified_by_zoho_id: any
	): Observable<any> {
		const data = {
			app_note_id: noteID,
			notes_info: notes_info,
			deal_name: appName,
			zoho_app_record_id: zoho_app_record_id,
			modified_by: modified_by,
			modified_by_zoho_id: modified_by_zoho_id,
		};
		return this.http.post(this.uri + '/updateNoteLocalDB', data);
	}
	//delete Note Local DB
	deleteNoteLocalDB(notes_info: any): Observable<any> {
		const data = {
			app_note_id: notes_info,
		};
		return this.http.post(this.uri + '/deleteNoteLocalDB', data);
	}
	//Get Notes Local DB
	getNoteLocalDB(zoho_app_record_id: any): Observable<any> {
		const data = {
			zoho_app_record_id: zoho_app_record_id,
		};
		return this.http.post(this.uri + '/getNoteLocalDB', data);
	}
	//Get individual note
	getIndividualNoteLocalDB(notes_info: any): Observable<any> {
		const data = {
			app_note_id: notes_info,
		};
		return this.http.post(this.uri + '/getIndividualNoteLocalDB', data);
	}
	//Get communication method
	getNoteCommunication(): Observable<any> {
		return this.http.get(this.uri + '/getNoteCommunication');
	}
	//Delete Note on ZOHO
	deleteNoteZOHO(notes_info: any): Observable<any> {
		const data = {
			notes_info: notes_info,
		};
		return this.http.post(this.uri + '/deleteNoteZOHO', data);
	}
	//Update most recent note on ZOHO Application
	updateRecentNoteZohoApplication(id: any, notes_info: any, created_by: any) {
		const data = {
			zoho_app_record_id: id,
			notes_info: notes_info,
			created_by: created_by,
		};
		return this.http.post(this.uri + '/updateRecentNoteZohoApplication', data);
	}
	// Get ZOHO users
	getUsers(): Observable<any> {
		return this.http.get(this.uri + '/getUsers');
	}
	//Create Front end checklist local
	uploadFrontEndChecklist(
		userForm: any,
		applicationID: any,
		applicantType: any
	) {
		const data = {
			userForm: userForm,
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/uploadFrontEndChecklist', data);
	}
	//Create Back end checklist local
	uploadBackEndChecklist(
		userForm: any,
		applicationID: any,
		applicantType: any
	) {
		const data = {
			userForm: userForm,
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/uploadBackEndChecklist', data);
	}
	//Create Funding checklist local
	uploadFundingChecklist(
		userForm: any,
		applicationID: any,
		applicantType: any
	) {
		const data = {
			userForm: userForm,
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/uploadFundingChecklist', data);
	}
	//Create Checklist Folder
	createChecklistFolders(applicationID: any) {
		const data = {
			applicationID: applicationID,
		};
		return this.http.post(this.uri + '/createChecklistFolders', data);
	}
	//Update Checklist Status
	updateChecklistStatus(
		userForm: any,
		applicationID: any,
		checklistStage: any,
		appType: any
	) {
		const data = {
			userForm: userForm,
			applicationID: applicationID,
			checklistStage: checklistStage,
			applicantType: appType,
		};
		return this.http.post(this.uri + '/updateChecklistStatus', data);
	}

	//Update email sent status
	updateEmailStatus(applicationID: any, emailStage: any) {
		const data = {
			applicationID: applicationID,
			emailStage: emailStage,
		};
		return this.http.post(this.uri + '/updateEmailStatus', data);
	}
	// Get the checklist record
	getChecklistInfo(applicationID: any, applicantType: any): Observable<any> {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/getChecklistInfo', data);
	}
	// Get the checklist record
	getChecklistDetails(
		applicationID: any,
		coAppRequired: any,
		stageStatus: any
	): Observable<any> {
		const data = {
			applicationID: applicationID,
			coAppRequired: coAppRequired,
			stageStatus: stageStatus,
		};
		return this.http.post(this.uri + '/getChecklistDetails', data);
	}

	// Get client applications: SEARCH
	getZOHOLoanNumberDrive(something: any): Observable<any> {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/getZOHOLoanNumberDrive', data);
	}
	//Checklist Emails
	//Frontend Completion
	frontendChecklistCompleteEmail(
		userform: any,
		applicationID: any,
		applicantType: any
	) {
		const data = {
			userform: userform,
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/frontendChecklistCompleteEmail', data);
	}
	//Backend Completion
	backendChecklistCompleteEmail(
		userform: any,
		applicationID: any,
		applicantType: any
	) {
		const data = {
			userform: userform,
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/backendChecklistCompleteEmail', data);
	}
	//Funded Completion
	fundedChecklistCompleteEmail(
		userform: any,
		applicationID: any,
		applicantType: any
	) {
		const data = {
			userform: userform,
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/fundedChecklistCompleteEmail', data);
	}

	// Add checklist log
	addChecklistLog(
		applicationID: any,
		applicantType: any,
		checklist_log: any,
		checklist_stage: any,
		loggedZohoID: any
	) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
			checklist_log: checklist_log,
			checklist_stage: checklist_stage,
			loggedZohoID: loggedZohoID,
		};
		return this.http.post(this.uri + '/addChecklistLog', data);
	}

	// Get checklist log
	// addLog(applicationID: any, log_details: any, checklist_stage: any, loggedZohoID: any, options: {applicantType?: any; checklist_stage?: any;}) {
	//   const data = {
	//     applicationID: applicationID,
	//     log_details: log_details,
	//     loggedZohoID: loggedZohoID,
	//     options: {
	//       applicantType: applicantType,
	//       checklist_stage: checklist_stage
	//     }
	//   }
	//   return this.http.post(this.uri + "/getChecklistLog", data);
	// }

	// Get checklist log
	getChecklistLog(
		applicationID: any,
		applicantType: any,
		checklist_stage: any
	) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
			checklist_stage: checklist_stage,
		};
		return this.http.post(this.uri + '/getChecklistLog', data);
	}
	readDirectory(folderID: any, folderName: any): Observable<any> {
		const data = {
			folderID: folderID,
			folderName: folderName,
		};
		return this.http.post(this.uri + '/readDirectory', data);
	}

	readFile(folderID: any, folderName: any, fileName: any) {
		const data = {
			folderID: folderID,
			folderName: folderName,
			fileName: fileName,
		};
		return this.http.post(this.uri + '/readFile', data);
	}

	deleteFile(folderID: any, folderName: any, fileName: any) {
		const data = {
			folderID: folderID,
			folderName: folderName,
			fileName: fileName,
		};
		return this.http.post(this.uri + '/deleteFile', data);
	}

	updateNoteIDLocal(app_note_id, zoho_note_id) {
		const data = {
			app_note_id: app_note_id,
			zoho_note_id: zoho_note_id,
		};
		return this.http.post(this.uri + '/updateNoteIDLocal', data);
	}

	//update Note Local DB
	changeNoteStatus(
		noteID: any,
		note_status: any,
		zoho_app_record_id: any,
		modified_by: any,
		modified_by_zoho_id: any
	): Observable<any> {
		const data = {
			app_note_id: noteID,
			note_status: note_status,
			zoho_app_record_id: zoho_app_record_id,
			modified_by: modified_by,
			modified_by_zoho_id: modified_by_zoho_id,
		};
		return this.http.post(this.uri + '/changeNoteStatus', data);
	}
	updateInterviewerUnderwriting(
		applicationID: String,
		applicantType: String,
		interviewer: String
	) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
			interviewer: interviewer,
		};
		return this.http.post(this.uri + '/updateInterviewerUnderwriting', data);
	}
	getInterviewerInfo(applicationID: String, applicantType: String) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/getInterviewerInfo', data);
	}
	sendEmailFundingOfficer(
		finalFundingOfficer,
		applicationID,
		name,
		coAppRequired,
		purpose,
		serverURL
	) {
		const data = {
			finalFundingOfficer: finalFundingOfficer,
			applicationID: applicationID,
			name: name,
			coAppRequired: coAppRequired,
			purpose: purpose,
			serverURL: serverURL,
		};
		return this.http.post(this.uri + '/sendEmailFundingOfficer', data);
	}
	emailNotesToIT(note: any, applicationID: any, dealName: any, createdBy: any) {
		const data = {
			noteInfo: note,
			applicationID: applicationID,
			dealName: dealName,
			createdBy: createdBy,
		};
		return this.http.post(this.uri + '/emailNotesToIT', data);
	}

	nlsExceptionCollections(startDate: any, endDate: any, asOfDate: any) {
		const data = {
			startDate: startDate,
			endDate: endDate,
			asOfDate: asOfDate,
		};
		return this.http.post(this.uri + '/nlsExceptionCollections', data);
	}

	nlsExceptionDetailedBreakdownCollections(
		startDate: any,
		endDate: any,
		asOfDate: any,
		reportType: any
	) {
		const data = {
			startDate: startDate,
			endDate: endDate,
			asOfDate: asOfDate,
			reportType: reportType,
		};
		return this.http.post(
			this.uri + '/nlsExceptionDetailedBreakdownCollections',
			data
		);
	}

	getReportsDashboardDelinquencyRatio(
		balanceType: any,
		start: any,
		end: any,
		loanGroupSelected?: string,
		statusCode?: string
	) {
		const data = {
			balanceType,
			start,
			end,
			loanGroupSelected,
			statusCode,
		};
		return this.http.post(this.uri + '/reportsDashboardDelinquencyRatio', data);
	}
	// todo ptp created report

	getPtpCreatedReport(startDate: string, endDate: string) {
		const data = { startDate, endDate };
		return this.http.post(this.uri + '/ptpCreatedReport', data)
	}

	getPtpReport(startDate: string, endDate: string, loanGroupSelected: string, ptpType: string, dateOption: string) {
		const data = {
			startDate,
			endDate,
			loanGroupSelected,
			ptpType,
			dateOption
		};
		return this.http.post(this.uri + '/ptpReport', data);
	}

	getPtpDetailedReport(startDate: string, endDate: string, ptpType: string, dateOption: string) {
		const data = {
			startDate,
			endDate,
			ptpType,
			dateOption
		};
		return this.http.post(this.uri + '/ptpDetailedReport', data);
	}

	getPtpLoanGroupReport(startDate: string, endDate: string, ptpType: string, dateOption: string) {
		const data = {
			startDate,
			endDate,
			ptpType,
			dateOption
		};
		return this.http.post(this.uri + '/ptpLoanGroupReport', data);
	}

	getCollectionList(employeeID) {
		const data = {
			employeeID,
		};
		return this.http.post(this.uri + '/collection-list', data);
	}

	getNsfCollectionList() { return this.http.get(this.uri + '/getNsfCollectionList'); }

	getCollectorNames() {
		return this.http.get(this.uri + '/collectorNames');
	}

	getAdminCollectionList(employeeID) {
		const data = {
			employeeID,
		};
		return this.http.post(this.uri + '/admin-collection-list', data);
	}

	getSpvLoanNumbers() {
		return this.http.get(this.uri + "/spvLoanNumbers");
	}

	getUWPerformanceReport(startDate: string, endDate: string) {
		const data = {
			startDate,
			endDate,
		};
		return this.http.post(this.uri + '/uwPerformance', data);
	}

	checkCheckBoxes(applicationID, checklistStage, hasCoapp, isLPP, appType) {
		const data = {
			applicationID: applicationID,
			checklistStage: checklistStage,
			hasCoapp: hasCoapp,
			isLPP: isLPP,
			applicantType: appType,
		};
		return this.http.post(this.uri + '/checkCheckBoxes', data);
	}

	//Update ZOHO application Stage and Checklist Completed (Under Underwriting Fields) for Final Funded
	updateStageClosingDate(applicationID: string, stage: string) {
		const data = {
			applicationID: applicationID,
			stage: stage,
		};
		return this.http.post(this.uri + '/updateStageClosingDate', data);
	}

	//Update Collection Letters
	updateCollectionLetters(loan_number: any, letters_info: any) {
		const data = {
			loan_number: loan_number,
			letters_info: letters_info,
		};
		return this.http.post(this.uri + '/updateCollectionLetters', data);
	}

	getDocumentPanda(pandadocsInfo: any, recipientInfo: any, tokens: any) {
		const data = {
			pandadocsInfo,
			recipientInfo,
			tokens,
		};
		return this.http.post(this.uri + '/GetDocumentPanda', data);
	}

	getDocumentStatus(doc_id: string) {
		const data = {
			doc_id,
		};
		return this.http.post(this.uri + '/DocumentStatus', data);
	}

	sendDocumentPanda(doc_id: string) {
		const data = {
			doc_id,
		};
		return this.http.post(this.uri + '/SendDocumentPanda', data);
	}

	downloadAgreementPanda() {
		return this.http.post(this.uri + '/downloadAgreementPanda', {});
	}

	deleteDocumentPanda(doc_id: string, doc_status: string) {
		const data = {
			doc_id,
			doc_status
		};
		return this.http.post(this.uri + '/DeleteDocumentPanda', data);
	}

	updateTDSRInfo(applicationID: any, applicantType: any) {
		const data = {
			applicationID: applicationID,
			applicantType: applicantType,
		};
		return this.http.post(this.uri + '/updateTDSRInfo', data);
	}

	updateMonthlyPaymentsUnderwriting(
		applicationID: any,
		Payment_Amount_Insurance_Monthly: any
	) {
		const data = {
			applicationID: applicationID,
			Payment_Amount_Insurance_Monthly: Payment_Amount_Insurance_Monthly,
		};
		console.log(applicationID);
		return this.http.post(
			this.uri + '/updateMonthlyPaymentsUnderwriting',
			data
		);
	}

	//---------------------Intermedia Calls-----------------------
	getUniteAuth() {
		return this.http.post(this.uri + '/getUniteAuth', {});
	}
	getUniteCalls(token: string) {
		let tempFrom = new Date();
		let tempTo = new Date();
		tempFrom.setHours(0, 0, 0, 0);
		tempTo.setHours(23, 59, 59, 999);
		return this.http.post(this.uri + '/getUniteCalls', {
			dateFrom: tempFrom.toISOString(),
			dateTo: tempTo.toISOString(),
			token,
		});
	}
	getFilesTouched(position: string) {
		return this.http.post(this.uri + '/getFilesTouched', {
			position,
		});
	}
	getEmployeesForUniteCalls(position: string) {
		return this.http.post(this.uri + '/getEmployeesForUniteCalls', {
			position,
		});
	}

	//-------------TV Dashboard-------------
	getCurrentMonthFundedTV() {
		return this.http.get(this.uri + "/getCurrentMonthFundedTV");
	}

	getCurrentMonthCollectionTV() {
		return this.http.get(this.uri + "/getCurrentMonthCollectionTV");
	}

	getTargetVariables() {
		return this.http.get(this.uri + "/getTargetVariables");
	}

	getTVCollectionGroups(loanGroupName: any) {
		return this.http.post(this.uri + "/getTVCollectionGroups", { loanGroupName });
	}

	getTVAgencyFunded() {
		return this.http.get(this.uri + "/getTVAgencyFunded");
	}

	getTVCreditorFunded() {
		return this.http.get(this.uri + "/getTVCreditorFunded");
	}

	getPaymentReportLoanServicing(acctref: number) {
		return this.http.post(this.uri + '/getPaymentReportLoanServicing', {
			acctref,
		});
	}

	getBtnPermission() {
		return this.http.get(this.uri + "/getBtnPermission");
	}

	// Finance Reports
	getSPVpaymentBreakdown(start_date: any, end_date: any, status: any, loan_group: any) {
		const data = {
			start_date: start_date,
			end_date: end_date,
			status: status,
			loan_group: loan_group
		}
		return this.http.post(this.uri + "/spv-payment-breakdown", data)
	}

	getDoublePayments() {
		return this.http.get(this.uri + "/getDoublePayments")
	}

	// Collection dashboard metrics
	getHomeCollectionMetrics() {
		return this.http.get(this.uri + "/getHomeCollectionMetrics")
	}

	// Get Pandocs template details from database
	getPandadocsTemplates(insurance_type: any, lpp_primary_flg: any, coapp_flag: any, lpp_coapp_flag: any, province_name: any) {
		const data = {
			insurance_type,
			lpp_primary_flg,
			coapp_flag,
			lpp_coapp_flag,
			province_name
		}
		return this.http.post(this.uri + "/getPandadocsTemplates", data)
	}

	// Get office emails
	getOfficeEmails() {
		return this.http.get(this.uri + "/getOfficeEmails")
	}

	// Email Notes 
	toggleEmailNotes(note: any, loanNumber: any, dealName: any, createdBy: any, emailTo: any, serverURL: any, emailComponent: any, senderName: any) {
		const data = {
			noteInfo: note,
			loanNumber: loanNumber,
			dealName: dealName,
			createdBy: createdBy,
			emailTo: emailTo,
			serverURL: serverURL,
			emailComponent: emailComponent,
			senderName: senderName
		};
		return this.http.post(this.uri + '/toggleEmailNotes', data);
	}

	// getCollectorPTP
	getCollectorPTP(collectorName: string) {
		const data = { collectorName };
		return this.http.post(this.uri + "/getCollectorPTP", data);
	}

	// getCollectorAdminPTP
	getCollectorAdminPTP() {
		return this.http.get(this.uri + "/getCollectorAdminPTP");
	}

	//
	assignCollectorListInterface(inputValue: string, inputList: any, collectorInfo: any, managerAssigned: boolean) {
		const data = { inputValue, inputList, collectorInfo, managerAssigned };
		return this.http.post(this.uri + "/assignCollectorListInterface", data)
	}

	// Cancel transaction entry for insurance reverse manual cancellation
	cancelInsuranceTransactions(acctrefno: number, reversed_by: string) {
		const data = { acctrefno, reversed_by };
		return this.http.post(this.uri + "/cancel-all-ins-trans", data);
	}

	// Get the name of the file
	checkLoanNumberName(loan_number: string) {
		const data = { loan_number };
		return this.http.post(this.uri + "/check-loan-number-name", data);
	}

	// GET NSF Date reports
	getNSFDateReport(nsf_date: any, loan_groups: any) {
		const data = { nsf_date, loan_groups };
		return this.http.post(this.uri + "/getNSFDateReport", data);
	}

	// Rapid Program
	createEntryPreapproval(model, hash) {
		const data = {
			model: model,
			hash: hash
		}
		return this.http.post(this.uri + "/createEntryPreapproval", data)
	}

	checkConsentStatus(hash: string) {
		const data = {
			hash: hash
		}
		return this.http.post(this.uri + "/checkConsentStatus", data)
	}

	getPreapprovalClientInfo(hash: string) {
		const data = {
			hash: hash
		}
		return this.http.post(this.uri + "/getPreapprovalClientInfo", data)
	}

	updatePreapprovalConsent(hash: string, model) {
		const data = {
			hash: hash,
			model: model
		}
		return this.http.post(this.uri + "/updatePreapprovalConsent", data)
	}

	updatePTPCreatedBy(parent_id: any, created_by: any) {
		const data = {
			parent_id: parent_id,
			created_by: created_by
		}
		return this.http.post(this.uri + "/updatePTPCreatedBy", data)
	}

	getActiveFilesZeroBalance() {
		return this.http.get(this.uri + "/getActiveFilesZeroBalance")
	}

	isTurnkeyFile(acctref: any) {
		const data = { acctref }
		return this.http.post(this.uri + "/isTurnkeyFile", data)
	}

	getTurnkeyData(nlsData: any) {
		const data = { nlsData }
		return this.http.post(this.uri + "/getTurnkeyData", data)
	}

	getExternalAgencyList(agencyId: string) {
		const data = { agencyId };
		return this.http.post(this.uri + "/getExternalAgencyList", data);
	}

	getAllClientBankings(applicationId: string) {
		const data = { applicationId };
		return this.http.post(this.uri + "/getAllClientBankings", data);
	}

	getcalculatedNewLoanAmount(){
		return this.http.get(this.uri + "/getcalculatedNewLoanAmount");	
	}

	updateAdditionalDebtZOHOApplication(something: any) {
		const data = {
			info: something,
		};
		return this.http.post(this.uri + '/updateAdditionalDebtZOHOApplication', data);
	}

}
