import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomFunctionService } from '../../custom-function.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SummaryDataService } from '@lmc-services/summary/summary-data.service';
import { NoteEntry } from '@lmc-models/noteInputs';
import { Controls } from '@lmc-models/controls';
import { GeneralObjects } from '@lmc-models/globalObjects';
import { Validators } from '@angular/forms';
import { environment } from '@lmc-env/environment';
import { resolve } from 'dns';
import { rejects } from 'assert';

@Component({
	selector: 'app-generalinformation',
	templateUrl: './generalinformation.component.html',
	styleUrls: ['./generalinformation.component.scss'],
})
export class GeneralinformationComponent implements OnInit, OnDestroy {
	@Input() zohoApplicationID: any;
	@Input() underwritingID: any;

	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;

	constructor(
		private store: Store,
		private summaryDataService: SummaryDataService,
		private apiService: ApiServiceService,
		private route: Router,
		private activatedRoute: ActivatedRoute,
		private customFunction: CustomFunctionService
	) { }

	// PRE-QUALIFYING
	app_note_id: any;
	Deal_Name: any;
	Amount: any;
	Residency_Status: any;
	Employment_Status: any;
	home_ownder: any;
	bankruptcy: any;
	earn_1200_month: any;
	payday_loans: any;
	if_needed_cosigner: any;
	employed: any;
	payment_method: any;
	have_lmc_loan: any;
	pre_qualifying_mortgage_balance: any;
	loan_details: any;
	emv: any;
	mortgage_details: any;

	Account_Name: any;
	Sales_Agent: any;
	Referred_By: any;
	Creditt: any;
	Creditor_Agency_Name: any;
	Referral_Date: any;
	Account_Balance: any;
	Settlement_Amount: any;
	Creditor_Portfolio: any;
	Funded_Amount: any;
	Additional_Debt2: any;
	Stage: any;

	//Referral
	creditor_details: any;
	acct_edp: any;
	acct_bal: any;
	debts: [];
	totalDebts: any;

	// Client Information
	primaryValidStatus: any; // Checklist status for Primary
	coAppValidStatus: any; // Checklist status for Co-applicant

	Gender: any;
	Marital_Status: any;
	Age: any;
	S_I_N: any;
	primary_email: any;

	Address1: any;
	Address_Line_2: any;
	City: any;
	Province: any;
	Postal_Code: any;
	residence_length: any;
	Monthly_Payments: any;

	fullAddressPrimary: any;

	Employer: any;
	client_emp_status: any;
	Work_Phone: any;
	Work_Extension: any;
	emp_length: any;
	Position: any;
	Pay_Frequency: any;
	income_net: any;
	Supervisor_Name: any;
	supervisor_number: any;
	Additional_Employment: any;
	household_income: any;
	household_employer: any;
	household_frequency: any;
	previous_employment: any;
	primary_industry: any;
	primary_occupation: any;

	previous_emp: any;
	Previous_Position: any;
	Previous_Employer: any;
	prev_emp_length: any;
	prev_supervisor_name: any;
	prev_supervisor_phone: any;
	previous_income: any;
	prev_pay_frequency: any;
	pre_pay_method: any;

	co_app_required: any;
	co_app_firstname: any;
	co_app_lastname: any;
	co_app_dob: any;
	co_app_address_unit: any;
	co_app_address: any;
	co_app_address_city: any;
	co_app_address_province: any;
	co_app_address_postal: any;
	co_applicant_employer: any;
	co_app_secondary_position: any;
	co_app_secondary_years_worked: any;
	co_app_sec_annual_income: any;
	co_applicant_pay_frequency: any;
	co_app_emp_status: any;
	Co_App_Credit_Score: any;
	co_app_industry: any;
	co_app_occupation: any;
	Secondary_Cell_Number: any;

	co_app_fullAddress: any;

	co_app_email: any;

	// Underwriting
	Industry: any;
	Employment_Status_Underwriting: any;
	Payment_Frequency: any;
	Occupation: any;
	Payment_Method: any;
	Employment_Details: any;

	Credit_Score: any;
	uw_tier: any;
	Review_any_payday_loan_usage: any;
	Overdraft_Limit: any;
	Is_this_a_joint_Account: any;
	Reason_for_falling_behind: any;
	Payday_Loans_Comments: any;
	Other_Bank_Account: any;

	last_filed_taxes: any;
	owe_Revenue_Canada: any;
	arrangement_CRA: any;
	live_anyone_else: any;
	Dependents: any;
	homeowner: any;
	LTV: any;
	owe_property_tax: any;
	property_mortgage: any;
	mortgage_who: any;
	current_value: any;
	mortgage_balance: any;
	mortgage_interest: any;

	Institution_Name: any;
	Institution_Number: any;
	account_number: any;
	transit_number: any;
	Branch_Address: any;

	Mobile: any;
	Phone: any;

	ownerName: any;
	expenses: [];
	Total_Monthly_Income: any;
	Total_Additional_Expenses: any;
	TDSR: any;
	Current_Loan_Amount: any;

	Loan_Amount: any;
	pay_frequency1: any;
	Term_in_Months: any;
	first_pay_date: any;
	payment_plan: any;

	Household_Income_Exception: any;
	Exception_Increase: any;
	Exception_Funded_Amount: any;
	Override: any;
	Override_Type: any;

	editLoanAmount: any;
	editEceptionIncrease: any;
	editOverride: any;
	editOverrideType: any;

	isEnabled: boolean;

	readStageRecords: any;

	userName: string;
	underwritingInformation: any;
	underwritingExpenses: any;
	applicantType: string = 'Primary';
	zoho_AgentView: boolean = false;
	// Underwriting Loan Interview Script
	// underwritingNameLT: any;
	// underwritingZohoIdLT: any;

	// User login information
	loggedName: any;
	loggedZohoID: any;
	userRole: any;
	roleID: any;

	newAddExpenses: any;
	questionBanks: any;

	// Primary
	totalSubformAdditional: any;
	tdsr: any;
	primaryStatus: any;

	// Co-Applicant
	coAppTotalSubformAdditional: any;
	coAppTdsr: any;
	coAppStatus: any;

	co_app_underwritingID: any;

	LPP_Sold_by: any;
	Loan_Agreement_Created_by: any;
	Loan_Protection: any;
	LPP_with_Scheduled_Payment: any;
	summary$: Observable<any>;

	noteEntry = new NoteEntry();
	controls = new Controls();
	callAttempts = new GeneralObjects();

	loggedEmail: any;

	p = 1;
	count = 10;

	spinner = false;

	// Note Email Toggle notification
	isEmail = false;
	officeEmails: any;
	selectedEmail = '';
	serverURL: string = environment.iframeURL;

	isExternalView: boolean;

	@Input() summaryData: any;

	ngOnInit(): void {
		this.spinner = true;
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			this.loggedName = userData['full_name'];
			this.loggedZohoID = userData['zoho_owner_id'];
			this.userRole = userData['position'];
			this.roleID = userData['roleID'];
			this.loggedEmail = userData['email'];
			if (this.roleID === 12) {
				this.noteEntry.noteForm.disable();
			} else {
				this.noteEntry.noteForm.enable();
			}
			this.isExternalView = this.roleID === 12 ? true : false;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				(summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.primaryValidStatus = summaryData.Primary_Checklist_Status;
						this.coAppValidStatus = summaryData.Co_applicant_Checklist_Status;
						this.Deal_Name = summaryData.Deal_Name;
						this.Amount = summaryData.Amount;
						this.Residency_Status = summaryData.Residency_Status;
						this.Employment_Status = summaryData.Employment_Status; // Prequalifying employment status
						this.client_emp_status = summaryData.Employment_Status1;
						this.home_ownder = summaryData.Are_you_currently_a_Home_Owner;
						this.bankruptcy = summaryData.Bankruptcy_CC_CP;
						this.earn_1200_month =
							summaryData.Do_you_earn_at_least_1200_per_month;
						this.payday_loans = summaryData.Payday_loans;
						this.if_needed_cosigner =
							summaryData.If_needed_could_you_find_a_Co_Singer;
						this.employed = summaryData.Employed;
						this.payment_method = summaryData.Payment_Method;
						this.have_lmc_loan = summaryData.Consent_Form_Signed;
						this.pre_qualifying_mortgage_balance = summaryData.MTG_Balance;
						this.loan_details = summaryData.Loan_Det;
						this.emv = summaryData.Estimated_Value;
						this.mortgage_details = summaryData.Mortgage_Details;
						this.Credit_Score = summaryData.Credit_Score;
						this.Co_App_Credit_Score = summaryData.Co_Applicant_Credit_Score;
						// this.uw_tier = summaryData.UW_Tier     // From Application once we use the underwriting local platform as it gets pushed from the local

						if (summaryData.Account_Name != null) {
							this.Account_Name = summaryData.Account_Name['name'];
						}
						this.Sales_Agent = summaryData.Sales_Agent;
						this.Referred_By = summaryData.Collector_Formula;
						this.Creditt = summaryData.Creditt;
						this.Creditor_Agency_Name = summaryData.Creditor_Agency_Name;
						this.Referral_Date = summaryData.Referral_Date;
						this.Account_Balance = summaryData.Account_Balance;
						this.Settlement_Amount = summaryData.Settlement_Amount;
						this.Creditor_Portfolio = summaryData.Creditor_Portfolio;
						this.Funded_Amount = summaryData.Funded_Amount;
						this.Additional_Debt2 = summaryData.Additional_Debt2;
						// this.Stage = summaryData.Stage

						this.creditor_details = summaryData.Creditor_Agency_Name;
						this.acct_edp = summaryData.Acc_Number;
						this.acct_bal = summaryData.Account_Bal;
						this.debts = summaryData.Additional_Debt;
						this.totalDebts = summaryData.Total;

						this.Gender = summaryData.Gender;
						this.Marital_Status = summaryData.Marital_Status;
						// this.Age = summaryData.Age
						if (summaryData.Date_of_Birth != null) {
							this.Age = this.customFunction.getAge(summaryData.Date_of_Birth);
						}
						this.S_I_N = summaryData.S_I_N;

						this.Mobile = summaryData.Mobile;
						this.Phone = summaryData.Phone;
						this.primary_email = summaryData.Email1;

						this.Address1 = summaryData.Address1;
						this.Address_Line_2 = summaryData.Address_Line_2;
						this.City = summaryData.City;
						this.Province = summaryData.Province1;
						this.Postal_Code = summaryData.Postal_Code;
						this.residence_length = summaryData.Length_of_Residence;
						this.Monthly_Payments = summaryData.Monthly_Payments;

						this.fullAddressPrimary =
							this.Address1 +
							', ' +
							this.City +
							', ' +
							this.Province +
							', ' +
							this.Postal_Code;

						this.Employer = summaryData.Employer;
						this.Work_Phone = summaryData.Work_Phone;
						this.Work_Extension = summaryData.Work_Extension;
						this.emp_length = summaryData.Length_of_Employment;
						this.Position = summaryData.Position;
						this.Pay_Frequency = summaryData.Pay_Frequency2;
						this.income_net = summaryData.Income_net_paycheque_income;
						this.Supervisor_Name = summaryData.Supervisor_Name;
						this.supervisor_number = summaryData.Supervisor_Phone_Number;
						this.Additional_Employment = summaryData.Additional_Employment;
						this.household_income = summaryData.Income_Amount_Household_Income;
						this.household_employer =
							summaryData.Employer_Name_Household_Income;
						this.household_frequency =
							summaryData.Pay_Frequency_Household_Income;
						this.previous_employment = summaryData.Previous_Employment;

						this.previous_emp = summaryData.Previous_Employment_Type;
						this.Previous_Position = summaryData.Previous_Position;
						this.Previous_Employer = summaryData.Previous_Employer;
						this.prev_emp_length = summaryData.Previous_Length_of_Employment;
						this.prev_supervisor_name = summaryData.Previous_Supervisor_Name;
						this.prev_supervisor_phone =
							summaryData.Previous_Supervisor_Phone_number;
						this.previous_income =
							summaryData.Previous_Income_net_paycheque_income;
						this.prev_pay_frequency = summaryData.Previous_Pay_Frequency;
						this.pre_pay_method = summaryData.Previous_Payment_Method;
						this.primary_industry = summaryData.Industry;
						this.primary_occupation = summaryData.Occupation;

						this.co_app_required = summaryData.Co_Appl_Required;
						this.co_app_firstname = summaryData.Co_Applicant_Name;
						this.co_app_lastname = summaryData.Guarantor_Name;
						this.co_app_dob = summaryData.Co_Applicant_Date_of_Birth;
						this.co_app_address_unit =
							summaryData.Co_Applicant_Address_Line_2_Unit_number;
						this.co_app_address = summaryData.Co_Applicant_Property_Address;
						this.co_app_address_city = summaryData.Co_Applicant_City;
						this.co_app_address_province = summaryData.Co_Applicant_Province;
						this.co_app_address_postal = summaryData.Co_Applicant_Postal_Code;
						this.co_applicant_employer = summaryData.Co_Applicant_Employer;
						this.co_app_secondary_position = summaryData.Secondary_Position;
						this.co_app_secondary_years_worked =
							summaryData.Secondary_Years_Worked;
						this.co_app_sec_annual_income = summaryData.Sec_Annual_Income;
						this.co_applicant_pay_frequency =
							summaryData.Co_Applicant_Pay_Frequency;
						this.co_app_emp_status = summaryData.Co_Applicant_Status;
						this.co_app_industry = summaryData.Co_Applicant_Industry;
						this.co_app_occupation = summaryData.Co_Applicant_Occupation;

						this.co_app_email = summaryData.Email;
						this.Secondary_Cell_Number = summaryData.Secondary_Cell_Number;

						this.co_app_fullAddress =
							this.co_app_address +
							', ' +
							this.co_app_address_city +
							', ' +
							this.co_app_address_province +
							', ' +
							this.co_app_address_postal;

						this.underwritingID = summaryData.Underwriting_ID;

						this.co_app_underwritingID =
							summaryData.Underwriting_ID_Co_Applicant;

						if (summaryData.LPP_Sold_by != null) {
							this.LPP_Sold_by = summaryData.LPP_Sold_by['name'];
						}
						this.Loan_Agreement_Created_by =
							summaryData.Loan_Agreement_Created_by;
						this.Loan_Protection = summaryData.Loan_Protection;
						this.LPP_with_Scheduled_Payment =
							summaryData.LPP_with_Scheduled_Payment;

						this.getUndewritingEntry(this.zohoApplicationID, 'Primary'); //Getting Underwriting Info from Database here
						this.getUnderwrintingEntryExpenses(
							this.zohoApplicationID,
							'Primary'
						); //Getting Underwriting Expenses Information from Database here
						this.loadNotes();
						this.spinner = false;
					}
				}
			);
	}

	getUndewritingEntry(applicationID, applicantType) {
		this.apiService
			.getUnderwritingEntry(applicationID, applicantType)
			.subscribe((res) => {
				res?.['status']
					? (this.underwritingInformation = res?.['data']?.[0])
					: console.log('Error Getting Underwriting Information from Database');
				// console.log(res);
				console.log('UNDEWRITING INFORMATION FROM DATABASE');
				// console.log(this.underwritingInformation);
			});
	}
	getUnderwrintingEntryExpenses(applicationID, applicantType) {
		this.apiService
			.getUnderwritingExpenses(applicationID, applicantType)
			.subscribe((res) => {
				res?.['status']
					? (this.underwritingExpenses = res?.['data'])
					: console.log('Error Getting Underwriting Expenses');
				// console.log(res);
				console.log('UNDERWRITING EXPENSES FROM DATABASE');
				// console.log(this.underwritingExpenses);
			});
	}
	changeUnderwritingApplicantType(applicationID, event) {
		let applicantType = event.target.value;
		this.underwritingExpenses = null;
		this.underwritingInformation = null;
		this.getUndewritingEntry(applicationID, applicantType);
		this.getUnderwrintingEntryExpenses(applicationID, applicantType);
	}

	loadNotes() {
		this.noteEntry.editID = '';
		this.getNoteSubjects();
		this.noteEntry.noteForm.get('communication').setValue('INT');
		this.apiService.getNoteLocalDB(this.zohoApplicationID).subscribe((res) => {
			if (res.message == 'Success') {
				if (res.data.Length != 0) {
					this.noteEntry.displayAdminNotes = res.data;
					this.noteEntry.displayUserNotes =
						this.customFunction.filterNotListedName(
							res.data,
							'note_status',
							'Deleted'
						);
					this.spinner = false;
				}
			}
		});
	}

	deleteNote(id: any) {
		if (confirm('Are you sure you want to delete the Note?')) {
			this.apiService.deleteNoteLocalDB(id).subscribe((res) => {
				if (res.message == 'Success') {
					this.loadNotes();
					this.resetNote();
					this.controls.displayMessage(
						'saveNoteButton',
						false,
						'saveNoteMessage',
						'',
						'',
						'none'
					);
				}
			});
		}
	}

	changeNoteStatus(id: any) {
		console.log(id, "@#@#@#@#@#@#")
		if (confirm('Are you sure you want to delete the Note?')) {
			this.apiService
				.changeNoteStatus(
					id,
					'Deleted',
					this.zohoApplicationID,
					this.loggedName,
					this.loggedZohoID
				)
				.subscribe((res) => {
					console.log(res, 'Delete Status!');
					this.loadNotes();
				});
		}
	}

	editNote(id: any) {
		this.apiService.getIndividualNoteLocalDB(id).subscribe((res) => {
			this.noteEntry.editID = id;
			this.noteEntry.noteForm.patchValue({
				subject: res.data[0]['note_subject'],
				description: res.data[0]['description'],
				communication: res.data[0]['communication_method'],
				note: res.data[0]['note'],
			});
		});
	}

	getNoteSubjects() {
		this.apiService.getNoteSubjects().subscribe((res) => {
			if (!res['status']) {
				// console.log(res)
			} else {
				this.noteEntry.subjects = res['data'];
			}
		});
	}

	callAttemptFlag = false;
	onchangeNoteSubject() {
		const searchIndex = this.noteEntry.subjects.findIndex(
			(sub) => sub.subject == this.noteEntry.noteForm.get('subject').value
		);
		this.noteEntry.noteForm.patchValue({
			description: this.noteEntry.subjects[searchIndex]['description'],
		});
		this.callAttemptFlag =
			this.noteEntry.noteForm.get('subject').value === 'Loan Interview Attempt';

		this.displayEmails();
	}

	async saveNote(id: any) {
		if (typeof id === 'undefined' || id === '') {
			this.controls.displayMessage(
				'saveNoteButton',
				false,
				'saveNoteMessage',
				'Save in progress...',
				'orange',
				'block'
			);
			// console.log(this.Deal_Name)
			let result = await this.emailNotes();

			this.apiService
				.createNoteLocalDB(
					this.noteEntry.noteForm.value,
					this.Deal_Name,
					this.zohoApplicationID,
					this.loggedName,
					this.loggedZohoID,
					this.isEmail,
					this.selectedEmail
				)
				.subscribe((res) => {
					if (res.message == 'Success') {
						let app_note_id = '';
						try {
							app_note_id = res['data'][0]['app_note_id'];
						} catch (err) {
							app_note_id = '';
						}
						this.selectedEmail = '';
						this.isEmail = false;
						// Push to ZOHO
						this.apiService
							.addLoanInterviewNotesZOHO(
								this.noteEntry.noteForm.value,
								this.zohoApplicationID,
								this.loggedZohoID
							)
							.subscribe((res) => {
								// console.log(res)
								if (res['message']['statusCode'] === 201) {
									let zoho_note_id = res['app_id'];
									this.apiService
										.updateNoteIDLocal(app_note_id, zoho_note_id)
										.subscribe((res) => {
											// console.log(res)
										});

									if (this.callAttemptFlag) {
										// Making a object for RLI_Called_Count in order to use same API as Application
										let callObject = {
											RLI_Called_Count:
												this.noteEntry.noteForm.get('RLI_Called_Count').value,
										};
										this.apiService
											.updateZohoApplicationInterface(
												callObject,
												this.zohoApplicationID
											)
											.subscribe((res) => {
												console.log(
													res,
													'-------------------------------------'
												);
											});
									}
									// this.loadZohoAppNotes();
									this.controls.displayMessage(
										'saveNoteButton',
										false,
										'saveNoteMessage',
										'Save successful!',
										'green',
										'block'
									);
									this.loadNotes();
									this.resetNote();
								} else {
									this.apiService
										.emailNotesToIT(
											this.noteEntry.noteForm.value,
											this.zohoApplicationID,
											this.Deal_Name,
											this.loggedName
										)
										.subscribe((res) => {
											console.log(res);
										});
									console.log(res, 'ZOHO Note push failed!');
								}

								// Implement after complete transition to platform notes: This updated the recent note on ZOHO
								// this.apiService.updateRecentNoteZohoApplication(this.zohoApplicationID,this.noteEntry.noteForm.value,this.loggedName).subscribe((res)=>{
								//   // console.log(res, "********************")
								//   this.loadZohoAppNotes();
								// })
							});
					} else {
						this.controls.displayMessage(
							'saveNoteButton',
							false,
							'saveNoteMessage',
							'Save unsuccessful!',
							'red',
							'block'
						);
						console.log(res, 'Platform: ZOHO Note save failed!');
					}
				});
		} else {
			this.controls.displayMessage(
				'saveNoteButton',
				true,
				'saveNoteMessage',
				'Save in progress...',
				'orange',
				'block'
			);
			this.apiService
				.updateNoteLocalDB(
					id,
					this.noteEntry.noteForm.value,
					this.Deal_Name,
					this.zohoApplicationID,
					this.loggedName,
					this.loggedZohoID
				)
				.subscribe((res) => {
					if (res.message == 'Success') {
						this.controls.displayMessage(
							'saveNoteButton',
							false,
							'saveNoteMessage',
							'Save successful!',
							'green',
							'block'
						);
						this.loadNotes();
						this.resetNote();
					} else {
						this.controls.displayMessage(
							'saveNoteButton',
							false,
							'saveNoteMessage',
							'Save unsuccessful!',
							'red',
							'block'
						);
					}
				});
		}
	}

	resetNote() {
		this.controls.resetControl(this.noteEntry.noteForm, 'subject');
		this.controls.resetControl(this.noteEntry.noteForm, 'description');
		this.controls.resetControl(this.noteEntry.noteForm, 'RLI_Called_Count');
		this.noteEntry.noteForm.get('communication').setValue('INT');
		this.controls.resetControl(this.noteEntry.noteForm, 'note');

		this.callAttemptFlag = false;
	}

	displayEmails() {
		const validators = [Validators.required];		// Validator
		if (this.noteEntry.noteForm.get('subject').value === 'Email Notes') {
			this.noteEntry.noteForm.get('email_address').addValidators(validators);		// Add Validator on Email when toggle selected 
			this.apiService.getOfficeEmails().subscribe((res) => {
				this.officeEmails = res?.['data'];
			})
		} else {
			this.selectedEmail = '';		// Clear the email address selected when toggle unselected
			this.noteEntry.noteForm.get('email_address').removeValidators(validators);		// Remove Validator on Email when toggle unselected
		}
		this.noteEntry.noteForm.get('email_address').updateValueAndValidity();		// Setting the validators based on the above
	}

	async emailNotes() {
		return new Promise((resolve, reject) => {
			if (this.noteEntry.noteForm.get('subject').value === 'Email Notes' && this.selectedEmail !== '') {
				this.apiService.toggleEmailNotes(this.noteEntry.noteForm.get('note').value, this.zohoApplicationID, this.Deal_Name, this.loggedEmail, this.selectedEmail, this.serverURL, 'summary', this.loggedName).subscribe((res) => {
					console.log(res, "Email Notes")
					this.isEmail = true;
					resolve(true);
				})
			} else {
				this.isEmail = false;
				this.selectedEmail = '';
				resolve(true);
			}
		});
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}
