<h1 style="text-align: center">Pre-Approval</h1>
<div style="padding-left: 3%; padding-top: 3%">
	<div *ngIf="createApplicationFlag">
		<form>
			<div style="display: inline-block">
				<label><strong>Loan Amount</strong></label
				><br />
				<input
					placeholder="Loan_Amount"
					[(ngModel)]="model.loan_amount"
					name="Loan_Amount"
				/>
			</div>
			<div style="padding-left: 2%; display: inline-block">
				<label><strong>Token</strong></label
				><br />
				<input
					type="password"
					placeholder="Token"
					name="Token"
					[(ngModel)]="model.token"
				/>
			</div>
			<div style="padding-left: 2%; display: inline-block">
				<label><strong>Consent to Pull Credit</strong></label
				><br />
				<select
					name="consent"
					id="consent"
					style="width: 175px"
					[(ngModel)]="Consent"
				>
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
			<br />
			<div style="padding-top: 1%; display: inline-block">
				<label><strong>First Name</strong></label
				><br />
				<input
					placeholder="First Name"
					[(ngModel)]="model.first_name"
					name="First_Name"
				/>
			</div>
			<div style="padding-left: 2%; display: inline-block">
				<label><strong>Middle Name</strong></label
				><br />
				<input
					placeholder="Middle Name"
					[(ngModel)]="model.middle_name"
					name="Middle_Name"
				/>
			</div>
			<div style="padding-left: 2%; display: inline-block">
				<label><strong>Last Name</strong></label
				><br />
				<input
					placeholder="Last Name"
					[(ngModel)]="model.last_name"
					name="Last_Name"
				/>
			</div>
			<br />
			<div style="padding-top: 1%; display: inline-block">
				<label><strong>SIN</strong></label
				><br />
				<input placeholder="SIN" [(ngModel)]="model.sin" name="sin" />
			</div>
			<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
				<label><strong>Phone Number</strong></label
				><br />
				<input placeholder="Phone" [(ngModel)]="model.telephone" name="Phone" />
			</div>
			<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
				<label><strong>Date of Birth</strong></label
				><br />
				<input
					placeholder="Date of Birth"
					[(ngModel)]="model.dob"
					name="Date_of_Birth"
				/>
			</div>
			<br />
			<div style="padding-top: 1%; display: inline-block">
				<label><strong>Street No.</strong></label
				><br />
				<input readonly placeholder="Street Number" />
			</div>
			<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
				<label><strong>Address</strong></label
				><br />
				<input
					style="width: 210%"
					placeholder="Address"
					[(ngModel)]="model.street"
					name="Address"
				/>
			</div>
			<br />
			<div style="padding-top: 1%; display: inline-block">
				<label><strong>City</strong></label
				><br />
				<input placeholder="City" [(ngModel)]="model.city" name="City" />
			</div>
			<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
				<label><strong>Province</strong></label
				><br />
				<input
					placeholder="Province"
					[(ngModel)]="model.province"
					name="Province"
				/>
			</div>
			<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
				<label><strong>Postal Code</strong></label
				><br />
				<input
					placeholder="Postal Code"
					[(ngModel)]="model.postal_code"
					name="Postal Code"
				/>
			</div>
			<br />
		</form>
		<br /><br />
		<button
			(click)="onSubmit()"
			class="buttonSubmit"
			style="display: inline-block"
		>
			Submit
		</button>
	</div>

	<br />

	<!-- Approved View -->
	<div *ngIf="approvedPageFlag" style="text-align: center">
		<h2>This Client has been Pre-Approved</h2>
		<p>The Client's information has been sent to LM Credit</p>
	</div>
	<!-- Declined View -->
	<div *ngIf="declinedPageFlag" style="text-align: center">
		<h2>This Client has been Declined</h2>
		<p>
			The Client's Credit Bureau does not meet the requirements to be
			Pre-Approved. The Client's information has been sent to LM Credit
		</p>
	</div>
</div>
