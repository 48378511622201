import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-nlsportfoliocollections',
	templateUrl: './nlsportfoliocollections.component.html',
	styleUrls: ['./nlsportfoliocollections.component.scss'],
})
export class NlsportfoliocollectionsComponent implements OnInit {
	groupPortfolioArray = [];
	one_five: string[] = [];
	fiveone_ten: string[] = [];
	tenone_fifteen: string[] = [];

	countGroupBadDebt: number;
	amountGroupBadDebt: any;
	countGroupFunded: number;
	amountGroupFunded: any;
	countGroupDefault: number;
	amountGroupDefault: any;

	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService,
		private route: Router
	) {}

	ngOnInit(): void {
		// Login Session
		if (localStorage.getItem('SESSIONID')) {
			this.apiService
				.verifysession(localStorage.getItem('SESSIONID'))
				.subscribe((res: any) => {
					if (res.message === 'Success') {
						this.fetchPortfolio(); // Fetch the display data
					} else {
						this.route.navigate(['login']);
					}
				});
		} else {
			this.route.navigate(['login']);
		}
	}

	fetchPortfolio() {
		this.apiService.getnlsoverallfunded().subscribe((res) => {
			// console.log(res.data)
			if (res.message === 'Success') {
				//-----------------------BUCKET 1 (Debenture: Default(1) + Debenture(27))------------------------------------------------------------------------------
				let countDeliquency1 = 0;
				let countDefault1 = 0;
				let fieldName1 = '';
				let badDebt1 = 0;
				let defaultAmount1 = 0;
				let fundedAmount1 = 0;
				let countFunded1 = 0;
				let delinquencyRate1 = '';
				let defaultRate1 = '';

				const bucket1 = res.data.filter(
					(item) =>
						item['loan_group_no'] == '1' || item['loan_group_no'] == '27'
				);

				for (let i = 0; i < bucket1.length; i++) {
					// If filtered loan portfolio match the raw data loan portfolio
					fieldName1 = 'DEBENTURE'; // Get the loan portfolio name
					fundedAmount1 += bucket1[i].original_note_amount; // Add the orignal funded amount to the respective loan portfolio
					countFunded1++; // count the funded occurrences
					// Delinquency
					if (bucket1[i].internal_DPD > 0) {
						badDebt1 += bucket1[i].current_principal_balance; // Add the principal balance to the respective loan portfolio
						countDeliquency1++; // count the delinquency occurrences
					}
					// Default
					if (bucket1[i].internal_DPD > 120) {
						defaultAmount1 += bucket1[i].current_principal_balance; // Add the principal balance to the respective loan portfolio
						countDefault1++; // count the default occurrences
					}
				}

				delinquencyRate1 = ((badDebt1 / fundedAmount1) * 100).toFixed();
				defaultRate1 = ((defaultAmount1 / fundedAmount1) * 100).toFixed();
				this.groupPortfolioArray.push(
					new this.customFunction.groupCollections(
						fieldName1,
						badDebt1,
						fundedAmount1,
						countDeliquency1,
						countFunded1,
						parseInt(delinquencyRate1),
						defaultAmount1,
						countDefault1,
						parseInt(defaultRate1)
					)
				);

				//-----------------------BUCKET 2 (SPV: Everything excluding (1,27,3,16,)------------------------------------------------------------------------------
				let countDeliquency2 = 0;
				let countDefault2 = 0;
				let fieldName2 = '';
				let badDebt2 = 0;
				let defaultAmount2 = 0;
				let fundedAmount2 = 0;
				let countFunded2 = 0;
				let delinquencyRate2 = '';
				let defaultRate2 = '';

				const bucket2 = res.data.filter(
					(item) =>
						item['loan_group_no'] != '3' &&
						item['loan_group_no'] != '16' &&
						item['loan_group_no'] != '1' &&
						item['loan_group_no'] != '27'
				);

				for (let i = 0; i < bucket2.length; i++) {
					// If filtered loan portfolio match the raw data province
					fieldName2 = 'SPV'; // Get the loan portfolio name
					fundedAmount2 += bucket2[i].original_note_amount; // Add the orignal funded amount to the respective loan portfolio
					countFunded2++; // count the funded occurrences
					// Delinquency
					if (bucket2[i].internal_DPD > 0) {
						badDebt2 += bucket2[i].current_principal_balance; // Add the principal balance to the respective loan portfolio
						countDeliquency2++; // count the delinquency occurrences
					}
					// Default
					if (bucket2[i].internal_DPD > 120) {
						defaultAmount2 += bucket2[i].current_principal_balance; // Add the principal balance to the respective loan portfolio
						countDefault2++; // count the default occurrences
					}
				}

				delinquencyRate2 = ((badDebt2 / fundedAmount2) * 100).toFixed();
				defaultRate2 = ((defaultAmount2 / fundedAmount2) * 100).toFixed();
				this.groupPortfolioArray.push(
					new this.customFunction.groupCollections(
						fieldName2,
						badDebt2,
						fundedAmount2,
						countDeliquency2,
						countFunded2,
						parseInt(delinquencyRate2),
						defaultAmount2,
						countDefault2,
						parseInt(defaultRate2)
					)
				);

				//-----------------------BUCKET 3 (MFT: Default(3) + Debenture(16))------------------------------------------------------------------------------
				let countDeliquency3 = 0;
				let countDefault3 = 0;
				let fieldName3 = '';
				let badDebt3 = 0;
				let defaultAmount3 = 0;
				let fundedAmount3 = 0;
				let countFunded3 = 0;
				let delinquencyRate3 = '';
				let defaultRate3 = '';

				const bucket3 = res.data.filter(
					(item) =>
						item['loan_group_no'] == '3' || item['loan_group_no'] == '16'
				);

				for (let i = 0; i < bucket3.length; i++) {
					// If filtered loan portfolio match the raw data province
					fieldName3 = 'MFT'; // Get the loan portfolio name
					fundedAmount3 += bucket3[i].original_note_amount; // Add the orignal funded amount to the respective loan portfolio
					countFunded3++; // count the funded occurrences
					// Delinquency
					if (bucket3[i].internal_DPD > 0) {
						badDebt3 += bucket3[i].current_principal_balance; // Add the principal balance to the respective loan portfolio
						countDeliquency3++; // count the delinquency occurrences
					}
					// Default
					if (bucket3[i].internal_DPD > 120) {
						defaultAmount3 += bucket3[i].current_principal_balance; // Add the principal balance to the respective loan portfolio
						countDefault3++; // count the default occurrences
					}
				}

				delinquencyRate3 = ((badDebt3 / fundedAmount3) * 100).toFixed();
				defaultRate3 = ((defaultAmount3 / fundedAmount3) * 100).toFixed();
				this.groupPortfolioArray.push(
					new this.customFunction.groupCollections(
						fieldName3,
						badDebt3,
						fundedAmount3,
						countDeliquency3,
						countFunded3,
						parseInt(delinquencyRate3),
						defaultAmount3,
						countDefault3,
						parseInt(defaultRate3)
					)
				);

				this.customFunction.sortColumn(
					this.groupPortfolioArray,
					'defaultRate',
					-1,
					1
				);

				// ***************TOTAL
				this.countGroupBadDebt = this.customFunction.sumNumbers(
					this.groupPortfolioArray,
					'countDeliquency'
				); // Total up the numbers
				this.amountGroupBadDebt = this.customFunction.sumNumbers(
					this.groupPortfolioArray,
					'badDebt'
				);
				// this.countGroupFunded = this.customFunction.sumNumbers(this.groupLoanSizeArray,'countFunded');
				this.countGroupFunded = res.data.length;
				this.amountGroupFunded = this.customFunction.sumNumbers(
					res.data,
					'original_note_amount'
				);
				this.countGroupDefault = this.customFunction.sumNumbers(
					this.groupPortfolioArray,
					'countDefault'
				);
				this.amountGroupDefault = this.customFunction.sumNumbers(
					this.groupPortfolioArray,
					'defaultAmount'
				);
			} else {
				this.groupPortfolioArray = [];
				this.countGroupBadDebt = 0;
				this.amountGroupBadDebt = 0;
				this.countGroupFunded = 0;
				this.amountGroupFunded = 0;
				this.countGroupDefault = 0;
				this.amountGroupDefault = 0;
			}
		});
	}
}

function bucketFilter(
	arr: any,
	fieldName: any,
	firstValue: any,
	secondValue: any
) {
	return arr.filter(
		(item) => item[fieldName] >= firstValue && item[fieldName] <= secondValue
	);
}
