<div *ngIf="(adminView || this.collectorIDs?.includes(this.sessionInfo?.employeeID)) && nsfTotalCount>0">
    <h4>Promised to pays Broken ({{past_date | date:'yyyy/MM/dd'}} - {{current_date | date:'yyyy/MM/dd'}})</h4>
    <div class="overlayDiv"><button mat-stroked-button (click)="resetFilters()">Reset Filters</button></div>
</div>
<div class="row" style="height: calc(100% - 200px);"
    *ngIf="(adminView || this.collectorIDs?.includes(this.sessionInfo?.employeeID)) && nsfTotalCount>0">
    <app-custom-table class="ag-theme-material" class="collection-wrapper" [rowData]="rowData_nsf"
        [showLoader]="showLoader" [columnDefs]="columnDefs_nsf" [hideGlobalFilter]="true" [animateRows]="true"
        [pagination]="false" [paginationPageSize]="10000" (gridReady)="onGridReady($event)">
    </app-custom-table>
</div>