<div class="container-fluid roll-over-container">
	<div class="roll-over-filters">
		<mat-form-field>
			<mat-label>Roll over start days</mat-label>
			<input matInput type="number" [(ngModel)]="rolloverStart" />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Roll over end days</mat-label>
			<input matInput type="number" [(ngModel)]="rolloverEnd" />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Roll over size</mat-label>
			<input matInput type="number" [(ngModel)]="rolloverPeriodSize" />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Roll over limit</mat-label>
			<input matInput type="number" [(ngModel)]="rolloverPeriodEnd" />
		</mat-form-field>
		<button
			class="btn btn-outline-secondary"
			type="submit"
			(click)="refreshRollOverData()"
		>
			Refresh
		</button>
	</div>

	<div class="row row-cols-12" *ngIf="buckets">
		<div class="col-12">
			<table class="table table-bordered table-hover roll-over-table">
				<thead>
					<tr>
						<th>Period</th>
						<th>Total</th>
						<th>Roll over IN ({{ rolloverStart }} days)</th>
						<th>Roll over OUT ({{ rolloverEnd }} days)</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of buckets">
						<td>{{ item['start'] }} - {{ item['end'] }}</td>
						<td>
							{{
								item['totalEndAmount']
									| currency : 'CAD' : 'symbol-narrow' : '3.2'
							}}
							({{ item['totalEndTotal'] }})
						</td>
						<td>
							{{
								item['rolloverStartAmount']
									| currency : 'CAD' : 'symbol-narrow' : '3.2'
							}}
							({{ item['rolloverStartTotal'] }})
						</td>
						<td>
							{{
								item['rolloverEndAmount']
									| currency : 'CAD' : 'symbol-narrow' : '3.2'
							}}
							({{ item['rolloverEndTotal'] }})
						</td>
					</tr>
					<tr>
						<td style="font-weight: bold">Grand Totals</td>
						<td>
							<span>{{
								grandTotals.totalEndAmount
									| currency : 'CAD' : 'symbol-narrow' : '3.2'
							}}</span>
							({{ grandTotals.totalEndTotal }})
						</td>
						<td>
							{{
								grandTotals.rolloverStartAmount
									| currency : 'CAD' : 'symbol-narrow' : '3.2'
							}}
							({{ grandTotals.rolloverStartTotal }})
						</td>
						<td>
							{{
								grandTotals.rolloverEndAmount
									| currency : 'CAD' : 'symbol-narrow' : '3.2'
							}}
							({{ grandTotals.rolloverEndTotal }})
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
