<span style="padding: 15px;"><strong>Loan Servicing</strong> | Reports/LPP</span>
<mat-tab-group style="height:  calc(100% - 30px);">
    <mat-tab label="Claims">
        <app-lpp-claims></app-lpp-claims>
    </mat-tab>
    <mat-tab label="Cancellation">
        <app-lpp-cancellation></app-lpp-cancellation>
    </mat-tab>
    <mat-tab label="Insurance Remit">
        <app-insurance-remit></app-insurance-remit>
    </mat-tab>
</mat-tab-group>
