import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CustomModalService } from '../../services/modal/custom-modal.service';
import { ModalNames } from '../../services/modal/custom-modal-options';
import { SummaryDataService } from '../../services/summary/summary-data.service';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

declare var require: any;
export class credit_report_input {
	constructor(
		public application_id,
		public loan_number,
		public first_name: string,
		public middle_name: string,
		public last_name: string,
		public dob: string,
		public street: string,
		public address2: string,
		public province: string,
		public city: string,
		public postal_code: string,
		public telephone: string,
		public sin: string,
		public application_type: string,
		public pulled_by,
		public token: string
	) { }
}

@Component({
	selector: 'app-transunion',
	templateUrl: './transunion.component.html',
	styleUrls: ['./transunion.component.scss'],
})
export class TransunionComponent implements OnInit, OnDestroy {
	title = 'htmltopdf';
	@ViewChild('pdfTable')
	pdfTable!: ElementRef;
	zohoInfo: any;
	model = new credit_report_input(
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		null,
		''
	);
	showInput = true; //false
	hideForm = true;
	TU_Report;
	application_id;
	loan_number;
	folder_id;
	application_type;
	application_type_label = '';
	base64: any;
	ZOHO_Upload_Status = 'Incomplete';
	Google_Drive_Upload_Status = 'Incomplete';
	Transunion_Status = 'Not Pulled';
	consentCheck = false;
	consentChecked = false;
	tranunion_pulled_by;
	mortgages = [];
	trades = [];
	red_trades = ['09'];
	orange_trades = ['05', '06', '07', '08'];
	yellow_trades = ['02', '03', '04'];
	testing_list = ['test'];
	transunion_codes;
	joint_descriptions = {
		I: 'Individual Account',
		C: 'Joint Contractual Responsibility',
		J: 'Joint',
		P: 'Participating',
	};

	zohoApplicationID: string;
	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;
	summary$: Observable<any>;
	summaryData: any;
	userData: any;
	modalDataSubscription: Subscription;

	constructor(
		private store: Store,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		private apiService: ApiServiceService,
		private customModalService: CustomModalService
	) { }

	ngOnInit() {
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				async (summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.summaryData = summaryData;
						let something = await this.getTransunionCodes();
						this.application_id = this.zohoApplicationID;
						this.loan_number = this.summaryData?.Application_Number;
						this.folder_id = this.summaryData?.gdriveextension__Drive_Folder_ID;
						this.application_type = 'primary';
						await this.loadTransunionPage();
					}
				}
			);
		// this.summaryDataSubscription = this.apiService
		// 	.getSingleAppSummary(this.currentZohoAppIdFromUrl)
		// 	.subscribe(async (res: any) => {
		// 		if (res['message']['statusCode'] == '200') {
		// 			const parsedResponse = JSON.parse(res.message.body);
		// 			const summaryDataParsed = parsedResponse.data?.[0];
		// 			this.summaryData = summaryDataParsed;
		// 			this.zohoApplicationID = this.summaryData.id;
		// 			let something = await this.getTransunionCodes();
		// 			this.application_id = this.zohoApplicationID;
		// 			this.loan_number = this.summaryData?.Application_Number;
		// 			this.folder_id = this.summaryData?.gdriveextension__Drive_Folder_ID;
		// 			this.application_type = 'primary';
		// 			await this.loadTransunionPage();
		// 		}
		// 	}
		// 	);
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			const roleID = userData?.roleID;
			this.userData = userData;
		});
		this.modalDataSubscription = this.customModalService.modelClosedObservable.subscribe((closedOptions) => {
			this.handleModalClosed(closedOptions);
		});
	}

	async loadTransunionPage() {
		if (this.application_type == 'primary')
			this.application_type_label = 'Primary';
		else if (this.application_type == 'co_applicant')
			this.application_type_label = 'Co-Applicant';
		console.log(await this.populateFields());
	}

	updateApplicationType(e) {
		this.application_type = e.target.value;
		this.loadTransunionPage();
	}

	async downloadAsPDF() {
		this.base64 = await this.createBase64File();
		await this.uploadTransCreditReportGD();
	}
	createMortgageList() {
		this.mortgages = [];
		this.trades = [];
		const TU_Report_Trade =
			this.TU_Report.REL4Report?.TU_FFR_Report?.[0]?.Trades?.[0]?.Trade;
		if (TU_Report_Trade) {
			for (var i = 0; i < TU_Report_Trade?.length; i++) {
				if (TU_Report_Trade?.[i]?.Type == 'M') {
					console.log('We found an M');
					this.mortgages.push(TU_Report_Trade[i]);
				} else {
					this.trades.push(TU_Report_Trade[i]);
				}
			}
			console.log('THE MORTGAGES:');
			// console.log(this.mortgages);
			// console.log(this.trades);
		}
	}
	updateTransunionDB(e, index) {
		console.log(e.checked);
		console.log(this.TU_Report);
		console.log('This is the result:');
		console.log(
			this.TU_Report.REL4Report?.TU_FFR_Report?.[0]?.Trades?.[0]?.Trade?.[
			index
			]?.['Referral']
		);
		console.log(
			this.TU_Report.REL4Report?.TU_FFR_Report?.[0]?.Trades?.[0]?.Trade?.[
			index
			]?.['Cons']
		);
		this.apiService
			.updateTransunionDB(this.model, this.TU_Report)
			.subscribe((res) => {
				this.apiService
					.getTransunionDB(this.application_id, this.application_type)
					.subscribe((res) => {
						console.log('getTransunionDB response', res);
					});
			});
	}
	addCheckBoxestoTrades() {
		const TU_Report_Trade =
			this.TU_Report.REL4Report?.TU_FFR_Report?.[0]?.Trades?.[0]?.Trade;
		if (TU_Report_Trade) {
			if (!TU_Report_Trade?.[0].hasOwnProperty('Referral')) {
				for (var i = 0; i < TU_Report_Trade?.length; i++) {
					TU_Report_Trade[i]['Referral'] = false;
				}
			}
			if (!TU_Report_Trade?.[0].hasOwnProperty('Cons')) {
				for (var i = 0; i < TU_Report_Trade?.length; i++) {
					TU_Report_Trade[i]['Cons'] = false;
				}
			}
		}
	}
	addCheckBoxestoCollections() {
		const TU_Report_Collection =
			this.TU_Report.REL4Report?.TU_FFR_Report?.[0]?.Collections?.[0]
				?.Collection;
		if (TU_Report_Collection) {
			if (!TU_Report_Collection?.[0].hasOwnProperty('Referral')) {
				for (var i = 0; i < TU_Report_Collection?.length; i++) {
					TU_Report_Collection[i]['Referral'] = false;
				}
			}
			if (!TU_Report_Collection?.[0].hasOwnProperty('Cons')) {
				for (var i = 0; i < TU_Report_Collection?.length; i++) {
					TU_Report_Collection[i]['Cons'] = false;
				}
			}
		}
	}
	addCheckBoxestoLegals() {
		const TU_Report_Legal =
			this.TU_Report.REL4Report?.TU_FFR_Report?.[0]?.Legals?.[0]?.Legal;
		if (TU_Report_Legal) {
			if (!TU_Report_Legal?.[0].hasOwnProperty('Referral')) {
				for (var i = 0; i < TU_Report_Legal?.length; i++) {
					TU_Report_Legal[i]['Referral'] = false;
				}
			}
			if (!TU_Report_Legal?.[0].hasOwnProperty('Cons')) {
				for (var i = 0; i < TU_Report_Legal?.length; i++) {
					TU_Report_Legal[i]['Cons'] = false;
				}
			}
		}
	}
	async transformTransunionReport() {
		this.addCheckBoxestoTrades();
		this.addCheckBoxestoCollections();
		this.addCheckBoxestoLegals();
		this.createMortgageList();
		let result = await this.getTransunionCodes();
		if (result) {
			if (this.hasProperties(this.trades)) {
				for (var i = 0; i < this.trades.length; i++) {
					let n1;
					try {
						n1 = this.transunion_codes.find(
							(x) => x['NarrativeCode'] === this.trades[i]['Narrative1'][0]
						);
					} catch (err) { }
					if (n1) this.trades[i]['Description1'] = n1['Description'];
					else this.trades[i]['Description1'] = '';
					let n2;
					try {
						n2 = this.transunion_codes.find(
							(x) => x['NarrativeCode'] === this.trades[i]['Narrative2'][0]
						);
					} catch (err) { }
					if (n2) this.trades[i]['Description2'] = n2['Description'];
					else this.trades[i]['Description2'] = '';
				}
			}
			//
			if (this.hasProperties(this.mortgages)) {
				for (var i = 0; i < this.mortgages.length; i++) {
					let n1;
					try {
						n1 = this.transunion_codes.find(
							(x) => x['NarrativeCode'] === this.mortgages[i]['Narrative1'][0]
						);
					} catch (err) { }
					if (n1) this.mortgages[i]['Description1'] = n1['Description'];
					else this.mortgages[i]['Description1'] = '';
					let n2;
					try {
						n2 = this.transunion_codes.find(
							(x) => x['NarrativeCode'] === this.mortgages[i]['Narrative2'][0]
						);
					} catch (err) { }
					if (n2) this.mortgages[i]['Description2'] = n2['Description'];
					else this.mortgages[i]['Description2'] = '';
				}
			}
			//
			if (
				this.hasProperties(
					this.TU_Report.REL4Report.TU_FFR_Report[0].Collections
				)
			) {
				for (
					var i = 0;
					i <
					this.TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection
						.length;
					i++
				) {
					let n1;
					try {
						n1 = this.transunion_codes.find(
							(x) =>
								x['NarrativeCode'] ===
								this.TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
									.Collection[i]['Narrative'][0]
						);
					} catch (err) { }
					if (n1)
						this.TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection[
							i
						]['Description'] = n1['Description'];
					else
						this.TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection[
							i
						]['Description'] = '';
				}
			}
			//
			if (
				this.hasProperties(this.TU_Report.REL4Report.TU_FFR_Report[0].Bankings)
			) {
				for (
					var i = 0;
					i <
					this.TU_Report.REL4Report.TU_FFR_Report[0].Bankings[0].Banking.length;
					i++
				) {
					let n1;
					try {
						n1 = this.transunion_codes.find(
							(x) =>
								x['NarrativeCode'] ===
								this.TU_Report.REL4Report.TU_FFR_Report[0].Bankings[0].Banking[
								i
								]['Narrative'][0]
						);
					} catch (err) { }
					if (n1)
						this.TU_Report.REL4Report.TU_FFR_Report[0].Bankings[0].Banking[i][
							'Description'
						] = n1['Description'];
					else
						this.TU_Report.REL4Report.TU_FFR_Report[0].Bankings[0].Banking[i][
							'Description'
						] = '';
				}
			}
			//
			if (
				this.hasProperties(
					this.TU_Report.REL4Report.TU_FFR_Report[0].Bankruptcies
				)
			) {
				for (
					var i = 0;
					i <
					this.TU_Report.REL4Report.TU_FFR_Report[0].Bankruptcies[0].Bankruptcy
						.length;
					i++
				) {
					let n1;
					try {
						n1 = this.transunion_codes.find(
							(x) =>
								x['NarrativeCode'] ===
								this.TU_Report.REL4Report.TU_FFR_Report[0].Bankruptcies[0]
									.Bankruptcy[i]['Narrative'][0]
						);
					} catch (err) { }
					if (n1)
						this.TU_Report.REL4Report.TU_FFR_Report[0].Bankruptcies[0].Bankruptcy[
							i
						]['Description'] = n1['Description'];
					else
						this.TU_Report.REL4Report.TU_FFR_Report[0].Bankruptcies[0].Bankruptcy[
							i
						]['Description'] = '';
				}
			}
		}
	}
	async getTransunionCodes() {
		return new Promise((resolve, reject) => {
			let result = this.apiService.getTransunionCodes().subscribe((res) => {
				if (res['status']) {
					this.transunion_codes = res['data'];
					console.log('TRANSUNION CODES');
					// console.log(this.transunion_codes);
					resolve(true);
				} else {
					alert('Error getting Transunion Codes');
					console.log(result);
					resolve(false);
				}
			});
		});
	}

	async handleModalClosed(closedOptions = {}) {
		const { modalName, result } = closedOptions as any;
		if (
			modalName === ModalNames.TRANSUNION_HIT_CONFIRMATION &&
			result === 'OK'
		) {
			this.onHitConsentConfirmed();
		} else if (
			modalName === ModalNames.TRANSUNION_RE_HIT_MANAGER_CONFIRMATION &&
			result === 'OK'
		) {
			let isManager = await this.checkAdminToken();
			if (isManager === true) {
				this.onAdminHitConfirmation();
			}
		}
	}
	async checkTransunionToken() {
		return new Promise((resolve) => {
			this.apiService
				.checkTransunionToken(this.model.token)
				.subscribe((res) => {
					if (!res['status']) {
						resolve('syntax');
					} else if (res['data'].length == 0) {
						resolve(false);
					} else {
						this.model['pulled_by'] = res['data'][0]['emp_id'];
						this.tranunion_pulled_by = res['data'][0]['emp_id'];
						resolve(true);
					}
				});
		});
	}
	checkAdminToken() {
		return new Promise((resolve) => {
			this.apiService
				.checkTransunionAdminToken(this.model.token)
				.subscribe((res) => {
					if (!res['status']) {
						resolve(false);
					} else if (res['data'].length == 0) {
						resolve(false);
					} else {
						resolve(true);
					}
				});
		});
	}
	async onSubmit() {
		this.consentChecked = !this.consentCheck;
		if (!this.consentCheck) {
			return;
		}
		console.log('UPDATED MODEL');
		console.log(this.model);
		console.log(this.consentCheck);
		if (this.consentCheck) {
			let token_status = await this.checkTransunionToken();
			let isManager = await this.checkAdminToken();
			switch (token_status) {
				case 'cancelled':
					break;
				case 'syntax': {
					alert('Syntax Error');
					break;
				}
				case false: {
					alert(`Invalid ${isManager ? 'Manager ' : ''}Token`);
					break;
				}
				case true: {
					this.apiService
						.checkIfTUReportExists(this.application_id, this.application_type)
						.subscribe(async (res: any) => {
							console.log('checkIfTUReportExists', res);

							if (res?.data && res?.data.length === 0) {
								this.openModal(
									ModalNames.TRANSUNION_HIT_CONFIRMATION,
									'Hit Consent',
									'Are you sure you want to pull the Credit Report for this Client?',
									['Cancel', 'OK']
								);
							} else if (res?.data?.length) {
								this.openModal(
									ModalNames.TRANSUNION_RE_HIT_MANAGER_CONFIRMATION,
									'Hit Consent By Manager',
									`Credit Information has already been pulled for this application.
                ${!isManager
										? "Double pull requires Manager Token, please contact Hamza, Zohar or Frank to pull this client's Credit Report. "
										: ''
									}
                Are you sure you want to pull again? ${!isManager ? 'Please enter manager token to proceed. ' : ''
									}`,
									['Cancel', 'OK']
								);
							} else {
								console.log('Error in fetching database');
							}
						});
					break;
				}
				default:
					break;
			}
		} else {
			alert('YOU NEED CONSENT TO PULL THE CREDIT FOR THIS CLIENT');
		}
	}

	async onHitConsentConfirmed() {
		this.apiService
			.getTransunionCreditReport(this.model)
			.subscribe(async (res) => {
				this.TU_Report = res;
				await this.transformTransunionReport();
				console.log(this.TU_Report);
				try {
					if (this.TU_Report.REL4Report.TU_FFR_Report[0].Hit[0] == 'Y') {
						this.openModal(
							ModalNames.TRANSUNION_HIT_FOUND,
							'Transunion Hit Found',
							'Hit was found for ' +
							this.model.first_name +
							' ' +
							this.model.last_name,
							['OK']
						);
						console.log(this.model, "StandardHitModel_______________________________");
						console.log(this.TU_Report, "StandardHitTU_______________________________");
						this.apiService
							.updateTransunionDB(this.model, this.TU_Report)
							.subscribe(async (res) => {
								console.log('Updating Transunion DB');
								console.log(res);
								if (res['status']) {
									//await this.downloadAsPDF()
									this.updateZohoApplication_Transunion();
									console.log('Client has been updated to Transunion DB');
								} else {
									alert('Update Failed');
								}
							});
					} else {
						this.openModal(
							ModalNames.TRANSUNION_HIT_NOT_FOUND,
							'Transunion Hit Not Found',
							'No Hit for ' +
							this.model.first_name +
							' ' +
							this.model.last_name,
							['OK']
						);
						this.TU_Report = false;
					}
				} catch (err) {
					console.log('Error Getting Transunion Report', err);
					alert('');
					//email
				}
			});
	}

	async onAdminHitConfirmation() {
		//Must get the Admin token here
		let admin_token_status = await this.checkAdminToken();
		switch (admin_token_status) {
			case 'cancelled':
				break;
			case 'syntax': {
				alert('Syntax Error');
				break;
			}
			case false: {
				alert('Invalid Manager Token');
				break;
			}
			case true: {
				console.log('________');
				console.log(this.model);
				this.apiService
					.getTransunionCreditReport(this.model)
					.subscribe((res) => {
						this.TU_Report = res;
						this.transformTransunionReport();
						console.log(this.TU_Report);
						try {
							if (this.TU_Report.REL4Report.TU_FFR_Report[0].Hit[0] == 'Y') {
								this.openModal(
									ModalNames.TRANSUNION_HIT_FOUND,
									'Transunion Hit Found',
									'Hit was found for ' +
									this.model.first_name +
									' ' +
									this.model.last_name,
									['OK']
								);
								console.log(this.model, "StandardHitModel_______________________________");
								console.log(this.TU_Report, "StandardHitTU_______________________________");
								this.Transunion_Status = 'Successfully Pulled';
								this.apiService
									.updateTransunionDB(this.model, this.TU_Report)
									.subscribe(async (res) => {
										console.log('Updating Transunion DB');
										console.log(res);
										if (res['status']) {
											//await this.downloadAsPDF()
											this.updateZohoApplication_Transunion();
											console.log('Client has been updated to Transunion DB');
										} else {
											alert('Update Failed');
										}
									});
							} else {
								this.openModal(
									ModalNames.TRANSUNION_HIT_NOT_FOUND,
									'Transunion Hit Not Found',
									'No Hit for ' +
									this.model.first_name +
									' ' +
									this.model.last_name,
									['OK']
								);
								this.Transunion_Status = 'No Hit';
								this.TU_Report = false;
							}
						} catch (err) {
							console.log('Error Getting Transunion Report');
						}
					});
				break;
			}
			default:
				break;
		}
	}
	async createBase64File() {
		return new Promise((resolve, reject) => {
			var element = document.getElementById('testing');
			html2canvas(element).then((canvas) => {
				var HTML_Width = canvas.width;
				var HTML_Height = canvas.height;
				var top_left_margin = 15;
				var PDF_Width = HTML_Width + top_left_margin * 2;
				var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
				var canvas_image_width = HTML_Width;
				var canvas_image_height = HTML_Height;

				var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
				console.log(canvas.height + '  ' + canvas.width);

				var imgData = canvas.toDataURL('image/jpeg', 1.0);
				var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
				pdf.addImage(
					imgData,
					'JPG',
					top_left_margin,
					top_left_margin,
					canvas_image_width,
					canvas_image_height
				);

				for (var i = 1; i <= totalPDFPages; i++) {
					pdf.addPage();
					let margin = -(PDF_Height * i) + top_left_margin * 4;
					if (i > 1) {
						margin = margin + i * 8;
					}
					console.log(top_left_margin);
					console.log(top_left_margin);
					console.log(-(PDF_Height * i) + top_left_margin * 4);
					pdf.addImage(
						imgData,
						'JPG',
						top_left_margin,
						margin,
						canvas_image_width,
						canvas_image_height
					);
				}

				//pdf.save("HTML-Document.pdf");
				pdf.setFontSize(5);
				let base64 = pdf.output('datauristring');
				let something = base64.toString();
				console.log('HERE IS THE BASE 64');
				something = something.split(/,(.+)/)[1];
				console.log(something);
				resolve(something);
			});
		});
	}
	quickDownload() {
		console.log('We are doing a download here');
		var element = document.getElementById('testing');
		html2canvas(element).then((canvas) => {
			var HTML_Width = canvas.width;
			var HTML_Height = canvas.height;
			var top_left_margin = 15;
			var PDF_Width = HTML_Width + top_left_margin * 2;
			var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
			var canvas_image_width = HTML_Width;
			var canvas_image_height = HTML_Height;

			var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
			console.log(canvas.height + '  ' + canvas.width);

			var imgData = canvas.toDataURL('image/jpeg', 1.0);
			var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
			pdf.addImage(
				imgData,
				'JPG',
				top_left_margin,
				top_left_margin,
				canvas_image_width,
				canvas_image_height
			);

			for (var i = 1; i <= totalPDFPages; i++) {
				pdf.addPage();
				let margin = -(PDF_Height * i) + top_left_margin * 4;
				if (i > 1) {
					margin = margin + i * 8;
				}
				console.log(top_left_margin);
				console.log(top_left_margin);
				console.log(-(PDF_Height * i) + top_left_margin * 4);
				pdf.addImage(
					imgData,
					'JPG',
					top_left_margin,
					margin,
					canvas_image_width,
					canvas_image_height
				);
			}

			pdf.setFontSize(5);
			let base64 = pdf.output('datauristring');
			let something = base64.toString();
			console.log('HERE IS THE BASE 64');
			something = something.split(/,(.+)/)[1];
			console.log(something);
			pdf.save(this.model.first_name + '_' + this.model.last_name + '.pdf');
		});
	}
	async uploadTransCreditReportGD() {
		return new Promise((resolve, reject) => {
			this.apiService
				.uploadTransCreditReportGD(
					this.folder_id,
					this.base64,
					this.model.first_name.concat(' ').concat(this.model.last_name)
				)
				.subscribe((res) => {
					if (res['status']) {
						this.apiService
							.updateTranunionGDStatus(
								this.application_id,
								this.application_type
							)
							.subscribe((res) => {
								console.log(res);
								if (res['status']) {
									this.Google_Drive_Upload_Status = 'Complete';
									console.log('Google Drive flag set to true');
								} else {
									console.log('Error updating the google drive flag');
								}
							});
					}
					console.log(res);
					alert(res['message']);
					resolve(res);
				});
		});
	}
	async populateFields() {
		return new Promise((resolve, reject) => {
			this.TU_Report = null;
			this.apiService
				.getTransunionDB(this.application_id, this.application_type)
				.subscribe(async (res: []) => {
					let final = [];
					final = res;
					// console.log(final);
					if (final.length == 0) {
						console.log('Client does not Exist on Transunion DB');
						this.hideForm = false;
						console.log('Attempting to Connect to ZOHO CRM');
						if (await this.NLSApiError()) {
							console.log('Unable to Connect to ZOHO CRM');
							this.model = new credit_report_input(
								this.application_id,
								this.loan_number,
								'',
								'',
								'',
								'',
								'',
								'',
								'',
								'',
								'',
								'',
								'',
								this.application_type,
								this.tranunion_pulled_by,
								''
							);
							console.log('MODEL DATA:');
							console.log(this.model);
							resolve('Done populating');
						} else {
							console.log('Success Connecting to ZOHO CRM');
							console.log('MODEL DATA:');
							console.log(this.model);
							resolve('Done populating');
						}
					} else {
						console.log('Client already exists on the database');
						this.hideForm = true;
						this.Transunion_Status = 'Successfully Pulled';
						this.model = new credit_report_input(
							final?.[0]?.['application_id'],
							final?.[0]?.['loan_number'],
							final?.[0]?.['first_name'],
							final?.[0]?.['middle_name'],
							final?.[0]?.['last_name'],
							final?.[0]?.['dob'],
							final?.[0]?.['street'],
							final?.[0]?.['address2'],
							final?.[0]?.['province'],
							final?.[0]?.['city'],
							final?.[0]?.['postal_code'],
							final?.[0]?.['telephone'],
							final?.[0]?.['sin'],
							final?.[0]?.['application_type'],
							final?.[0]?.['pulled_by'],
							final?.[0]?.['token']
						);
						this.TU_Report = JSON.parse(
							final?.[0]?.['trans_credit_report'] || '{}'
						);
						this.transformTransunionReport();
						console.log('MODEL DATA:');
						// console.log(this.model);
						console.log('TU Report');
						// console.log(this.TU_Report);
						if (final?.[0]?.['zoho_upload'] == 1) {
							this.ZOHO_Upload_Status = 'Complete';
						} else {
							this.ZOHO_Upload_Status = 'Incomplete';
						}
						if (final?.[0]?.['gd_upload'] == 1) {
							this.Google_Drive_Upload_Status = 'Complete';
						} else {
							this.Google_Drive_Upload_Status = 'Incomplete';
						}
						resolve('Done populating');
					}
				});
		});
	}
	showInputs() {
		this.showInput = !this.showInput;
	}
	showHideForm() {
		this.hideForm = !this.hideForm;
	}
	async NLSApiError() {
		return new Promise((resolve, reject) => {
			this.apiService
				.getZohoApplication(this.application_id)
				.subscribe((res) => {
					if (!res) {
						alert('UNABLE TO CONNECT TO ZOHO - PLEASE TRY AGAIN IN 1 MINUTE');
						resolve(true);
					} else {
						this.zohoInfo = res['data'][0];
						this.showInput = true;
						console.log('ZOHO DATA');
						console.log(this.zohoInfo);
						let first_name = '';
						let middle_name = '';
						let last_name = '';
						let dob;
						let postal_code;
						let telephone;
						if (this.application_type == 'primary') {
							let full_name = this.zohoInfo['Deal_Name'].split(' ');
							first_name = full_name[0];
							if (full_name.length > 1) {
								try {
									last_name = full_name[full_name.length - 1];
								} catch (err) {
									middle_name = '';
									last_name = '';
								}
								if (full_name.length >= 2) {
									middle_name = full_name
										.slice(1, full_name.length - 1)
										.join(' ');
								}
							}
							try {
								postal_code = this.zohoInfo['Postal_Code'].replaceAll(' ', '');
							} catch (err) {
								postal_code = '';
							}
							try {
								telephone = this.zohoInfo['Phone'].replaceAll(' ', '');
								telephone = telephone.replaceAll('-', '');
							} catch (err) {
								telephone = '';
							}
							let sin;
							if (!this.zohoInfo['S_I_N']) sin = '';
							else sin = this.zohoInfo['S_I_N'];
							this.model = new credit_report_input(
								this.application_id,
								this.loan_number,
								first_name,
								middle_name,
								last_name,
								this.zohoInfo['Date_of_Birth'],
								this.zohoInfo['Address1'],
								this.zohoInfo['Address_Line_2'],
								this.zohoInfo['Province'],
								this.zohoInfo['City'],
								postal_code,
								telephone,
								sin,
								this.application_type,
								this.tranunion_pulled_by,
								''
							);

							resolve(false);
						} else if (this.application_type == 'co_applicant') {
							first_name = this.zohoInfo['Co_Applicant_Name'];
							middle_name = '';
							last_name = this.zohoInfo['Guarantor_Name'];
							try {
								postal_code = this.zohoInfo[
									'Co_Applicant_Postal_Code'
								].replaceAll(' ', '');
							} catch (err) {
								postal_code = '';
							}
							try {
								telephone = this.zohoInfo['Secondary_Cell_Number'].replaceAll(
									' ',
									''
								);
								telephone = telephone.replaceAll('-', '');
							} catch (err) {
								telephone = '';
							}
							let sec_sin;
							if (!this.zohoInfo['Secondary_S_I_N']) sec_sin = '';
							else sec_sin = this.zohoInfo['Secondary_S_I_N'];
							this.model = new credit_report_input(
								this.application_id,
								this.loan_number,
								first_name,
								middle_name,
								last_name,
								this.zohoInfo['Co_Applicant_Date_of_Birth'],
								this.zohoInfo['Co_Applicant_Property_Address'],
								this.zohoInfo['Co_Applicant_Address_Line_2_Unit_number'],
								this.zohoInfo['Co_Applicant_Province'],
								this.zohoInfo['Co_Applicant_City'],
								postal_code,
								telephone,
								sec_sin,
								this.application_type,
								this.tranunion_pulled_by,
								''
							);

							resolve(false);
						}
					}
				});
		});
	}
	updateZohoApplication_Transunion() {
		this.apiService
			.updateZohoApplication_Transunion(
				this.TU_Report,
				this.application_id,
				this.application_type
			)
			.subscribe((res) => {
				console.log('THE RESULT FROM UPDATING THE ZOHO APPLICATION');
				if (res['statusCode'] == 200) {
					this.openModal(
						ModalNames.TRANSUNION_ZOHO_UPDATED_WITH_REPORT,
						'ZOHO Updated',
						'Credit Score pushed to ZOHO Application',
						['OK']
					);
					this.apiService
						.updateZOHO_Upload_Status(
							this.application_id,
							this.application_type
						)
						.subscribe((res) => {
							console.log(res);
							if (res['status']) {
								console.log('zoho flag set to true');
								this.ZOHO_Upload_Status = 'Complete';
							} else {
								console.log('Error updating the zoho flag');
							}
						});
				} else {
					console.log('Error updating zoho application');
				}
			});
	}
	hasProperties(something) {
		try {
			return Object.entries(something).length > 0;
		} catch (err) {
			return false;
		}
	}

	openModal(
		modalName,
		headerTitle: string = '',
		bodyText: string = '',
		buttons: Array<string> = [''],
		options = {}
	) {
		this.customModalService.openModal({
			modalName,
			activateModal: true,
			headerTitle,
			bodyText,
			buttons,
			options
		});
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
		this.modalDataSubscription?.unsubscribe();
	}
}
