import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { loanInput } from '@lmc-models/loanInput';
import { ApiServiceService } from '../../api-service.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SummaryDataService } from 'app/services/summary/summary-data.service';
import { ActivatedRoute } from '@angular/router';
import { SharedServiceService } from '@lmc-app/shared-service.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';

interface jsPDFWIthPlugin extends jsPDF {
	autoTable: (options: UserOptions) => jsPDF;
}
interface Manager_Overwrite {
	value: boolean;
	viewValue: string;
}
interface IPeriodDropDown {
	value: number;
	viewValue: string;
}

interface Loan_Type {
	value: string;
	viewValue: string;
}
interface Term_Type {
	value: string;
	viewValue: string;
}
interface Province {
	value: string;
	viewValue: string;
}
interface Credit_Upgrader {
	value: string;
	viewValue: string;
}
interface Insurance {
	value: string;
	viewValue: string;
}
interface Food {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-loancalculator',
	templateUrl: './loancalculator.component.html',
	styleUrls: ['./loancalculator.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LoancalculatorComponent implements OnInit, AfterViewInit, OnDestroy {
	foods: Food[] = [
		{ value: 'steak-0', viewValue: 'Steak' },
		{ value: 'pizza-1', viewValue: 'Pizza' },
		{ value: 'tacos-2', viewValue: 'Tacos' },
	];
	@HostListener('change', ['$event.target'])
	@ViewChild('content')
	content: ElementRef;
	@ViewChild('fileImportInput') fileImportInput: any;
	loan_type: Loan_Type[] = [
		{ value: 'Standard', viewValue: 'Standard (34.99%)' },
		{ value: 'Old Standard', viewValue: 'Old Standard (49.99%)' },
		{ value: 'Click and Loan', viewValue: 'Click and Loan (25.99%)' },
		{ value: 'Custom', viewValue: 'Variable Interest Rate' },
	];
	term_type: Term_Type[] = [
		{ value: 'Monthly', viewValue: 'Monthly' },
		{ value: 'Weekly', viewValue: 'Weekly' },
		{ value: 'Bi-Weekly', viewValue: 'Bi-Weekly' },
		{ value: '15th and EOM', viewValue: '15th and EOM' },
		{ value: '1st and 15th', viewValue: '1st and 15th' },
	];
	manager_overwrite: Manager_Overwrite[] = [
		{ value: true, viewValue: 'Yes' },
		{ value: false, viewValue: 'No' },
	];

	loan_period: IPeriodDropDown[] = [
		{ value: 1, viewValue: '1' },
		{ value: 2, viewValue: '2' },
		{ value: 3, viewValue: '3' },
		{ value: 4, viewValue: '4' },
		{ value: 5, viewValue: '5' },
	];

	province: Province[] = [
		{ value: 'ON', viewValue: 'Ontario' },
		{ value: 'MB', viewValue: 'Manitoba' },
		{ value: 'NL', viewValue: 'NewFoundLand' },
		{ value: 'SK', viewValue: 'Saskatchewan' },
		{ value: 'NA', viewValue: 'Other' },
	];
	credit_upgrader: Credit_Upgrader[] = [
		{ value: 'Yes', viewValue: 'Yes' },
		{ value: 'No', viewValue: 'No' },
	];
	insurance_protection: Insurance[] = [
		{ value: 'Yes', viewValue: 'Yes' },
		{ value: 'No', viewValue: 'No' },
	];
	display_data = {
		Payment: '',
		Scheduled: '',
		LPP: '',
		Insurance: '',
		Tax: '',
		Total_obligation_entire_term: '',
		Total_cost_of_borrowing: '',
		Funded_date: '',
		Start_date: '',
		Maturity_date: '',
		First_payment_amount: '',
		Last_payment_amount: '',
		Setup_fee: '',
		CU_fee: '',
		Accumulative_interest: '',
		LPP_total_interest: '',
		Term: '',
		isEnhanced: 'Standard',
	};

	loanConfig = {
		amount: {
			min: 5000,
			max: 25000,
		},
	};

	zohoexists: boolean;
	ProperSchedule = [];
	read_only_flag = false;
	loanInput = new loanInput();
	Payments;
	payment_Flag = false;
	scheduleArray: any;
	pdf: any;
	doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWIthPlugin;
	Application: any;
	court: any;
	clients: any;
	client_info = [];
	csvRecords: any[] = [];
	header = false;
	start_date_temp;
	funded_date_temp;
	start_date_calc;
	funded_date_calc;
	non_admin_user = true;
	zohoApplicationID: string;
	currentRoute: string;
	currentZohoAppIdFromUrl: string;
	summaryDataSubscription: Subscription;
	summary$: Observable<any>;
	summaryData: any;
	userData: any;
	spinner = false;
	debtSettlementFees: any;

	disableLoanAmount: boolean = true;
	isOldCalculator: boolean = false;

	dsRate = 0;
	dsFeeRate = 0;
	dsAmount = 0;
	dsLoanAmount = 0;
	dsFee = 0;
	newLoanAmount = 0;

	additionalDebts: any;

	totalAccountBalance: any;
	grandTotalNewAmount: any;

	additionalDebt_settlment = [];		// Store new calculation

	isInterfaceLoad: boolean = true;

	hasCreditorPortfolio: any;
	hasAccountBalance: any;
	hasCustomSettlement: any;

	countZOHOCustomSettlment: any;
	approvedAmountMatched: any;

	validateExistingLoanAmount: any;
	loanAmountLoader: boolean = true;
	performDurationUpdate: boolean = true;	// Not to calculate duration if loan calculator entry found
	dbLoanAmount: any;

	constructor(
		private store: Store,
		private summaryDataService: SummaryDataService,
		private activatedRoute: ActivatedRoute,
		public datepipe: DatePipe,
		private apiService: ApiServiceService,
		private sharedService: SharedServiceService,
		private fb: FormBuilder
	) {
		this.populateCalc();
	}

	async ngAfterViewInit(): Promise<void> {
		this.loanInput.Is_Old_Calculator = false;
		this.debtSettlementFees = await this.getnewLoanAmount();
		this.disableLoanAmount = this.debtSettlementFees['disable_loan_amount'];
	}

	ngOnInit() {
		this.spinner = true;
		this.activatedRoute.url.subscribe((event) => {
			this.currentRoute = event[0].path;
		});
		this.activatedRoute.pathFromRoot[1].url.subscribe((val) => {
			this.currentZohoAppIdFromUrl = val[2].path;
		});
		this.summaryDataService.userDataObservable.subscribe((userData: any) => {
			this.userData = userData;
		});
		this.summaryDataSubscription =
			this.summaryDataService.summaryDataObservable.subscribe(
				async (summaryData: any) => {
					if (
						!this.currentZohoAppIdFromUrl ||
						this.currentZohoAppIdFromUrl === summaryData.id
					) {
						this.zohoApplicationID = summaryData.id;
						this.summaryData = summaryData;
						this.hasCreditorPortfolio = await this.validateCreditorPortfolio(this.summaryData);
						this.hasAccountBalance = await this.validateAccountBalance(this.summaryData);
						this.hasCustomSettlement = await this.validateCustomSettlementAmount(this.summaryData);
						this.countZOHOCustomSettlment = await this.countCustomSettlement(this.summaryData);
						this.validateExistingLoanAmount = await this.validateLoanAmountMatch(this.summaryData, this.summaryData?.Account_Bal);
						this.approvedAmountMatched = await this.checkApprovedAmountZOHO();

						await this.populateCalc();
					}

				}

			);
	}

	getUnderwritingIDPrimary() {
		this.apiService
			.getUnderwritingIDPrimary(this.loanInput.application_id)
			.subscribe((res) => {
				console.log('ZOHO APPLICATION INFORMATION');
				console.log(res);
			});
	}
	Zoho_Connectivity_Issues(urlParams) {
		this.loanInput.application_id =
			urlParams.get('Application_ID') || this.zohoApplicationID;
		this.loanInput.Application_Name =
			urlParams.get('Application_Name') || this.summaryData?.Deal_Name;
		this.loanInput.Province_Name =
			urlParams.get('Province_Name') || this.summaryData?.Province1;
		this.loanInput.Province =
			urlParams.get('Province') || this.summaryData?.Province;
		this.loanInput.id =
			urlParams.get('App_Number') || this.summaryData?.Application_Number;
		this.loanInput.Loan_Type = 'Standard';
		this.loanInput.CU = 'No';
		this.loanInput.Interest_Rate = 34.99;
		// this.loanInput.Interest_Rate = 49.99;
	}
	async getLoggedInUser(user_id) {
		return new Promise((resolve, reject) => {
			if (user_id) {
				this.apiService.getZohoLoggedInUser(user_id).subscribe((res) => {
					if (!res) {
						console.log('Error Getting Logged in User Details');
						resolve('');
					} else {
						let user = res['users'][0]['full_name'];
						// if (
						// 	res['users'][0]['id'] == '2390707000028597016' ||
						// 	res['users'][0]['id'] == '2390707000000113007' ||
						// 	res['users'][0]['id'] == '2390707000063682025'
						// )
						// 	this.non_admin_user = false;
						console.log('Here is the user');
						console.log(res);
						const zohoUserID = res['users'][0]['id'];

						// Platform button permissions
						this.apiService.getBtnPermission().subscribe((res) => {
							let managerAccess = res?.[0]?.loan_calc_manager;
							if (managerAccess?.includes(zohoUserID)) {
								console.log(zohoUserID, "_________Access granted")
								this.non_admin_user = false;
							}
						})
						resolve(user);
					}
				});
			}
		});
	}
	async getZohoApplication(urlParams) {
		return new Promise((resolve, reject) => {
			this.apiService.getZohoApplication(urlParams.get('Application_ID') || this.zohoApplicationID).subscribe(async (res) => {
				if (!res) {
					alert('UNABLE TO CONNECT TO ZOHO - PLEASE TRY AGAIN IN 1 MINUTE');
					resolve(false);
				} else {
					console.log('Application details from ZOHO has been retrieved');
					console.log(res);
					this.Application = res;
					const appData = this.Application?.['data']?.[0];
					this.loanInput.id = appData?.App_Number;
					// this.loanInput.Loan_Amount = appData?.Amount;
					// this.loanInput.Loan_Amount = appData?.Account_Bal;
					// this.onDurationUpdate(appData?.Account_Bal, true);
					// this.onDurationUpdate(appData?.Amount, true);
					this.loanInput.Folder_ID =
						appData?.gdriveextension__Drive_Folder_ID;
					this.loanInput.dob = appData?.Date_of_Birth;
					this.loanInput.Loan_Type = 'Standard';
					this.loanInput.Interest_Rate = 34.99;
					// this.loanInput.Interest_Rate = 49.99;
					this.loanInput.Insurance = 'No';
					// this.originalRequestedAmount = appData?.Account_Bal;
					// await this.calculateNewLoanAmount(this.loanInput.Loan_Amount);
					// console.log(appData, "+++++++++++++++++++++++")
					await this.populateNewCalculation(appData);
					this.loanInput.Loan_Amount = this.totalAccountBalance;
					this.onDurationUpdate(this.totalAccountBalance, true);
					console.log(appData, "______________")
					resolve(true);
				}
			});
		});
	}
	populateCalc() {
		return new Promise(async (resolve, reject) => {
			// let testing_url = "https://lm.credit/calculator?City=&Application_ID=2390707000184727716&Province_Name=Alberta&Province=AB&Application_Name=JOANNA%20READER&UserId=2390707000159959001&App_Number=8263236121&Date_of_Birth=Dec%2031,%201974&Approved_Amount=CA$%206,725.00"
			let testing_url =
				'https://betty-alpha.web.app/forms/loancalculator?City=&Application_ID=2390707000034288237&Province_Name=Saskatchewan&Province=SK&Application_Name=Betty%20LPP%20Test&UserId=2390707000063682025&App_Number=82632%20-15972&Date_of_Birth=Nov%2025,%201982&Approved_Amount=CA$%2049,139.00&UnderwritingID=2390707000201423001';
			let URI = window.location.href;
			let decoded = decodeURI(URI); //URI//testing_url/
			const urlParams = new URLSearchParams(decoded);
			console.log('ZOHO APPLICATION INFORMATION', urlParams);

			this.loanInput.loan_calc_created_by = await this.getLoggedInUser(
				urlParams.get('UserId') || this.userData?.zoho_owner_id
			);
			this.loanInput.underwriting_id =
				urlParams.get('UnderwritingID') || this.summaryData?.Underwriting_ID;
			this.loanInput.id =
				urlParams.get('App_Number') || this.summaryData?.Application_Number;
			this.loanInput.application_id =
				urlParams.get('Application_ID') || this.summaryData?.id;
			this.loanInput.Application_Name =
				urlParams.get('Application_Name') || this.summaryData?.Deal_Name;
			this.loanInput.Province =
				urlParams.get('Province') || this.summaryData?.Province;
			this.loanInput.Province_Name =
				urlParams.get('Province_Name') || this.summaryData?.Province1;
			this.loanInput.Loan_Type = 'Standard';
			this.loanInput.CU = 'No';
			//this.getUnderwritingIDPrimary()
			console.log(await this.getCalculatorException());
			await this.getLoanConfig();
			if (
				this.loanInput.Province != 'ON' &&
				this.loanInput.Province != 'MB' &&
				this.loanInput.Province != 'NL' &&
				this.loanInput.Province != 'SK'
			)
				this.loanInput.Province = 'NA';
			if (await this.checkCalculatorInstance()) {
				console.log('Calculator already exists in the database');
				if (this.loanInput.custom_settlement_counts !== this.countZOHOCustomSettlment) {
					this.spinner = false;
					this.loanAmountLoader = false;
					this.resetForm();
				} else if (this.validateExistingLoanAmount !== this.approvedAmountMatched) {
					this.spinner = false;
					this.loanAmountLoader = false;
					this.resetForm();
				} else {
					this.performDurationUpdate = false;
					this.calculate(true);
				}
			} else {
				if (await this.getZohoApplication(urlParams)) {
					console.log('Connected to ZOHO for new Calculator Creation');
					console.log(urlParams, "_____CONNECTING ZOHO")
					this.spinner = false;
				} else {
					this.Zoho_Connectivity_Issues(urlParams);
					this.performDurationUpdate = true;
					this.calculate(false);
				}
			}
			resolve(true);

		})
	}

	getLoanConfig() {
		this.apiService.getLoanConfig().subscribe((res) => {
			if (!res?.['status']) {
				console.log('Loan config retrieval failed');
			} else {
				try {
					const clientConfig = JSON.parse(res?.['data']?.[0]?.config);
					if (clientConfig) {
						this.loanConfig = clientConfig?.loanConfig;
					}
				} catch (e) {
					console.log('Error setting up load config', e);
				}
			}
		});
	}

	async getCalculatorException() {
		return new Promise((resolve, reject) => {
			this.apiService
				.getExceptionCalculator(this.loanInput.application_id)
				.subscribe((res) => {
					if (!res['status']) {
						this.loanInput.exception = false;
						resolve('Error getting exception status from table');
					} else {
						if (res['data'].length == 0) {
							this.loanInput.exception = false;
							resolve('exception does not exist');
						} else {
							if (res['data'][0]['ex'] == 1) this.loanInput.exception = true;
							else this.loanInput.exception = false;
							resolve('exception status set');
						}
					}
				});
		});
	}

	async checkCalculatorInstance() {
		return new Promise(async (resolve, reject) => {
			this.apiService
				.getCalculatorInstance(this.loanInput.application_id)
				.subscribe(async (res) => {
					let calc_info = res;
					this.zohoexists = false;
					this.isOldCalculator = (res) ? (calc_info[0]['is_old_calculator']) : false;
					if (res) {
						this.zohoexists = true;
						this.loanInput.Loan_Type = calc_info[0]['loan_type'];
						this.loanInput.Interest_Rate = calc_info[0]['interest_rate'];
						this.loanInput.CU = calc_info[0]['credit_upgrader'];
						this.loanInput.Insurance = calc_info[0]['insurance_protection'];
						this.loanInput.custom_settlement_counts = calc_info[0]['custom_settlement_counts'];

						// const zohoData = await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
						// this.loanInput.Loan_Amount = zohoData['Account_Bal'];
						this.loanInput.Loan_Amount = calc_info[0]['original_loan_amount'];
						this.loanInput.Creditor_Portfolio = calc_info[0]['creditor_portfolio'];
						// this.loanInput.Loan_Amount = calc_info[0]['loan_amount'];
						this.dbLoanAmount = calc_info[0]['loan_amount'];
						this.loanInput.Duration = calc_info[0]['period'];
						this.loanInput.Term_Type = calc_info[0]['term_type'];
						console.log('Here it is from the database:');
						console.log(calc_info[0]['start_date']);
						console.log(calc_info[0]['funded_date']);
						calc_info[0]['start_date'] = calc_info[0]['start_date'].substring(
							0,
							10
						);
						calc_info[0]['funded_date'] = calc_info[0]['funded_date'].substring(
							0,
							10
						);
						console.log('____________________________');
						this.loanInput.Start_Date = this.datepipe
							.transform(calc_info[0]['start_date'], 'yyyy-MM-dd')
							.concat('T05:00:00.000Z');
						this.loanInput.Funded_Date = this.datepipe
							.transform(calc_info[0]['funded_date'], 'yyyy-MM-dd')
							.concat('T05:00:00.000Z');
						console.log(this.loanInput.Start_Date);
						console.log(this.loanInput.Funded_Date);

						this.loanInput.created_by = calc_info[0]['created_by'];
						// this.performDurationUpdate = false;
						resolve(true);
					}
					// this.performDurationUpdate = true;
					resolve(false);
				});
		});
	}
	LoanTypeChange(value: string) {
		switch (value) {
			case 'Standard': {
				this.loanInput.Interest_Rate = 34.99;
				break;
			}
			case 'Old Standard': {
				this.loanInput.Interest_Rate = 49.99;
				break;
			}
			case 'Click and Loan': {
				this.loanInput.Interest_Rate = 25.99;
				this.loanInput.Setup_Fee_APR = 0.24;
				break;
			}
			default: {
				this.loanInput.Interest_Rate = 0;
			}
		}
	}
	formatDate(date) {
		console.log('THE DATES');
		console.log(date);
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}
	// myFilter = (d: Date | null): boolean => {
	//   const day = (d || new Date()).getDay();
	//   // Prevent Saturday and Sunday from being selected.
	//   return day !== 0 && day !== 6;
	// }
	myFilter = (d: Date | null): boolean => {
		const date = d || new Date();
		//Prevent Today from being selected.
		return !this.sameDay(date, new Date());
	};
	formatMaturityDate(date) {
		var d = new Date(date);
		d.setDate(d.getDate() + 1); //adding one day to maturity date
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		let year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	updateException(event) {
		let ex_status = 0;
		console.log('Checkbox has been checked');
		console.log(event.value);
		if (event.value) ex_status = 1;
		this.apiService
			.updateExceptionCalculator(this.loanInput.application_id, ex_status)
			.subscribe((res) => {
				console.log(res);
			});
	}

	onDurationUpdate(duration, onFirstLoad?: boolean) {
		console.log(duration, "&&&%%%%%%%%%%")
		this.loanInput.Loan_Amount = duration;
		if (this.loanInput.exception && !onFirstLoad) {
			return;
		}

		if (
			duration < this.loanConfig?.amount?.min ||
			duration > this.loanConfig?.amount?.max
		) {
			this.loanInput.Duration = null;
			return;
		}

		switch (true) {
			case duration >= 0 && duration <= 2499:
				return (this.loanInput.Duration = 1);
			case duration > 2499 && duration <= 4999:
				return (this.loanInput.Duration = 2);
			case duration > 4999 && duration <= 9999:
				return (this.loanInput.Duration = 3);
			case duration > 9999 && duration <= 12499:
				return (this.loanInput.Duration = 4);
			case duration > 12499 && duration <= 25000:
				return (this.loanInput.Duration = 5);
			default:
				return (this.loanInput.Duration = null);
		}
	}

	sameDay(d1, d2) {
		return (
			d1.getFullYear() === d2.getFullYear() &&
			d1.getMonth() === d2.getMonth() &&
			d1.getDate() === d2.getDate()
		);
	}
	async validationAfterCalculation(existing_flag) {
		return new Promise(async (resolve, reject) => {
			if (!existing_flag) {
				let date1;
				let date2;
				let diff_days;
				let diff_time;
				try {
					date1 = new Date(this.loanInput.Start_Date.toString());
					date2 = new Date(this.loanInput.Funded_Date.toString());
				} catch (error) {
					alert('These dates are invalid');
					this.isInterfaceLoad = true;
					resolve(false);
				}
				diff_time = date1.getTime() - date2.getTime();
				diff_days = diff_time / (1000 * 60 * 60 * 24);
				console.log(diff_days);
				diff_days = parseInt(diff_days.toFixed(0));
				console.log(diff_days);
				if (!this.loanInput.exception) {
					//Check whether Balloon Payment Exists
					if (
						parseFloat(this.display_data.Payment) <
						parseFloat(this.display_data.Last_payment_amount)
					) {
						let allowed_amount =
							(25 / 100) * parseFloat(this.display_data.Payment);
						if (
							parseFloat(this.display_data.Last_payment_amount) -
							allowed_amount >=
							parseFloat(this.display_data.Payment)
						) {
							alert(
								'Balloon Payment Detected, Please Re-Adjust the Interest Adjustment Period'
							);
							console.log(
								'Scheduled Payment : ' + parseFloat(this.display_data.Payment)
							);
							console.log(
								'Last Payment : ' +
								parseFloat(this.display_data.Last_payment_amount)
							);
							this.isInterfaceLoad = true;
							resolve(false);
							return;
						}
					}
					//Check Interest Adjustment Period
					//turn on overwrite manager hamza and zohar

					switch (this.loanInput.Term_Type) {
						case 'Weekly': {
							if (diff_days <= 12 || diff_days >= 20) {
								alert(
									'For Weekly frequency, Interest Adjustment Period should be between 12 to 20 days. Manager Overwrite Required'
								);
								this.isInterfaceLoad = true;
								resolve(false);
								break;
							}
							this.isInterfaceLoad = false;
							resolve(true);
							break;
						}
						case 'Monthly': {
							if (diff_days <= 12 || diff_days >= 60) {
								alert(
									'For Monthly frequency, Interest Adjustment Period should be between 12 to 60 days. Manager Overwrite Required'
								);
								resolve(false);
								this.isInterfaceLoad = true;
								break;
							}
							this.isInterfaceLoad = false;
							resolve(true);
							break;
						}
						case 'Bi-Weekly': {
							if (diff_days <= 14 || diff_days >= 40) {
								alert(
									'For Bi-Weekly frequency, Interest Adjustment Period should be between 14 and 40 days. Manager Overwrite Required'
								);
								this.isInterfaceLoad = true;
								resolve(false);
								break;
							}
							this.isInterfaceLoad = false;
							resolve(true);
							break;
						}
						case '15th and EOM': {
							if (diff_days <= 15 || diff_days >= 40) {
								alert(
									'For Semi-Monthly frequency, Interest Adjustment Period should be between 15 and 40 days. Manager Overwrite Required'
								);
								this.isInterfaceLoad = true;
								resolve(false);
								break;
							}
							this.isInterfaceLoad = false;
							resolve(true);
							break;
						}
						case '1st and 15th': {
							if (diff_days <= 15 || diff_days >= 40) {
								alert(
									'For Semi-Monthly frequency, Interest Adjustment Period should be between 15 and 40 days. Manager Overwrite Required'
								);
								this.isInterfaceLoad = true;
								resolve(false);
								break;
							}
							this.isInterfaceLoad = false;
							resolve(true);
							break;
						}
						default: {
							console.log(this.loanInput.Term_Type);
							alert('Not a valid Payment Frequency');
							this.isInterfaceLoad = true;
							resolve(false);
						}
					}
				}
				resolve(true);
				return;
			} else {
				console.log('_____________________________________');
				console.log('THE CALCULATOR EXISTS BUT VALIDATION WILL NOT BE APPLIED');
				this.isInterfaceLoad = false;
				resolve(true);
			}
		});
	}
	async validateInput(existing_flag) {
		return new Promise((resolve, reject) => {
			if (
				typeof this.loanInput.Funded_Date == 'undefined' ||
				typeof this.loanInput.Start_Date == 'undefined' ||
				!this.loanInput.Loan_Amount ||
				!this.loanInput.Duration ||
				!this.loanInput.CU ||
				!this.loanInput.Insurance ||
				!this.loanInput.Term_Type ||
				!this.loanInput.Loan_Type
			) {
				alert('Missing Loan Information');
				resolve(false);
				return;
			}
			// if (!this.loanInput.exception) { //If there is an exception then we can use this
			// if (diff_days > 16) {
			//   alert("Invalid Dates - Interest Adjustment Period should be less than 16 days")
			//   resolve(false)
			// }
			this.apiService.isLPPAllowed(this.loanInput).subscribe((res) => {
				if (!res) {
					alert('This Applicant does not qualify for LPP');
					resolve(false);
					return;
				}
			});
			//}
			resolve(true);
		});
	}

	async calculate(existing_flag) {
		this.spinner = true;
		this.loanAmountLoader = true;
		this.loanInput.existing_flag = existing_flag;
		console.log('HERE IS THE LOAN INPUT');
		console.log(this.loanInput);
		this.Payments = null;
		this.ProperSchedule = [];
		if (await this.validateInput(existing_flag)) {
			this.read_only_flag = true;
			switch (this.loanInput.Loan_Type) {
				case 'Standard': {
					await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
					this.loanInput.Original_Loan_Amount = this.totalAccountBalance;
					this.loanInput.Loan_Amount = this.grandTotalNewAmount;
					const onlyFirstCalulation = (this.performDurationUpdate) ? this.onDurationUpdate(this.loanInput.Loan_Amount, true) : '';
					// console.log(this.loanInput.Loan_Amount, "CHECK____________________________")
					this.apiService.calculate_am(this.loanInput).subscribe(async (res) => {
						console.log('CALC FROM THE BACKEND');
						console.log(res);
						this.createAmSchedule(res);
						const onSaveSend = (!this.isInterfaceLoad) ? this.updateLoanAmount(this.grandTotalNewAmount) : '';
						this.spinner = false;
					});
					break;
				}
				case 'Old Standard': {
					await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
					this.loanInput.Original_Loan_Amount = this.totalAccountBalance;
					this.loanInput.Loan_Amount = this.grandTotalNewAmount;
					const onlyFirstCalulation = (this.performDurationUpdate) ? this.onDurationUpdate(this.loanInput.Loan_Amount, true) : '';
					this.apiService.calculate_am(this.loanInput).subscribe(async (res) => {
						console.log('CALC FROM THE BACKEND');
						console.log(res);
						this.createAmSchedule(res);
						const onSaveSend = (!this.isInterfaceLoad) ? this.updateLoanAmount(this.grandTotalNewAmount) : '';
						this.spinner = false;
					});
					break;
				}
				case 'Click and Loan': {
					await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
					this.loanInput.Original_Loan_Amount = this.totalAccountBalance;
					this.loanInput.Loan_Amount = this.grandTotalNewAmount;
					const onlyFirstCalulation = (this.performDurationUpdate) ? this.onDurationUpdate(this.loanInput.Loan_Amount, true) : '';
					this.apiService.calculate_am_click_loan(this.loanInput).subscribe((res) => {
						this.createAmSchedule(res);
						const onSaveSend = (!this.isInterfaceLoad) ? this.updateLoanAmount(this.grandTotalNewAmount) : '';
						this.spinner = false;
					});
					break;
				}
				case 'Custom': {
					await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
					this.loanInput.Original_Loan_Amount = this.totalAccountBalance;
					this.loanInput.Loan_Amount = this.grandTotalNewAmount;
					const onlyFirstCalulation = (this.performDurationUpdate) ? this.onDurationUpdate(this.loanInput.Loan_Amount, true) : '';
					this.apiService.calculate_am(this.loanInput).subscribe((res) => {
						this.createAmSchedule(res);
						const onSaveSend = (!this.isInterfaceLoad) ? this.updateLoanAmount(this.grandTotalNewAmount) : '';
						this.spinner = false;
					});
					break;
				}
				default: {
					await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
					this.loanInput.Original_Loan_Amount = this.totalAccountBalance;
					this.loanInput.Loan_Amount = this.grandTotalNewAmount;
					const onlyFirstCalulation = (this.performDurationUpdate) ? this.onDurationUpdate(this.loanInput.Loan_Amount, true) : '';
					this.apiService.calculate_am(this.loanInput).subscribe((res) => {
						this.createAmSchedule(res);
						const onSaveSend = (!this.isInterfaceLoad) ? this.updateLoanAmount(this.grandTotalNewAmount) : '';
						this.spinner = false;
					});
				}
			}
		}
	}
	// originalRequestedAmount: any;
	getOriginalRequestedLoanAmount(applicationId: any) {
		return new Promise((resolve, reject) => {
			this.additionalDebt_settlment = [];
			this.apiService.getZohoApplication(applicationId).subscribe(async (res: any) => {
				// console.log(res?.data[0]?.Account_Bal, "Original Requested Amount");
				await this.populateNewCalculation(res?.data[0]);
				this.loanInput.Loan_Amount = this.totalAccountBalance;
				// this.originalRequestedAmount = this.totalAccountBalance;
				resolve(res?.data[0]);
			})
		})
	}
	async resetForm() {
		await this.checkLoanCalculatorEntry();
		this.loanAmountLoader = true;
		this.additionalDebt_settlment = [];
		this.ProperSchedule = [];
		this.Payments = null;
		this.read_only_flag = false;
		this.loanInput.Loan_Type = 'Standard';
		this.loanInput.Interest_Rate = 34.99;
		// this.loanInput.Interest_Rate = 49.99;
		this.zohoexists = false;
		await this.getOriginalRequestedLoanAmount(this.zohoApplicationID);
		this.loanInput.Loan_Amount = this.totalAccountBalance;
		this.loanInput.Original_Loan_Amount = this.totalAccountBalance;
		// this.onDurationUpdate(this.loanInput.Loan_Amount, true);
	}
	async createAmSchedule(res) {
		this.Payments = res;
		for (var i = 0; i < this.Payments.Term_of_Loan; i++) {
			this.ProperSchedule.push(this.Payments.schedule[i]);
			this.Payments.schedule[i].set_LPP_Ending_Balance = (
				parseFloat(this.Payments.schedule[i].set_Beg_Balance) -
				parseFloat(this.Payments.LPP) -
				parseFloat(this.Payments.schedule[i].set_Interest) -
				parseFloat(this.Payments.schedule[i].set_Principal)
			).toFixed(2);
		}
		if (this.Payments['isEnhanced']) {
			this.display_data.isEnhanced = 'Enhanced';
			this.Payments['isEnhanced'] = 'Enhanced';
		} else {
			this.display_data.isEnhanced = 'Standard';
			this.Payments['isEnhanced'] = 'Standard';
		}
		this.Payments.schedule[
			this.Payments.Term_of_Loan - 1
		].set_LPP_Ending_Balance = '0.00';
		this.display_data.Payment = (
			this.Payments.Amortization_Interest +
			this.Payments.LPP +
			this.Payments.CU_Fee
		).toFixed(2);
		this.adjustLastPaymentDateandAmount();
		this.display_data.Scheduled =
			this.Payments.Amortization_Interest.toFixed(2);
		this.display_data.LPP = this.Payments.LPP.toFixed(2);
		let LPP = parseFloat(this.Payments.LPP.toFixed(2));
		this.display_data.Insurance = (
			LPP - parseFloat(this.Payments.Tax_Amount_Per_Payment)
		).toFixed(2);
		this.display_data.Tax = this.Payments.Tax_Amount_Per_Payment.toFixed(2);
		this.Payments.Funded_Date = this.formatDate(this.Payments.Funded_Date);
		this.display_data.Funded_date = this.Payments.Funded_Date;
		this.display_data.Total_obligation_entire_term =
			this.Payments.Total_Obligation_Entire_Term_Final.toFixed(2);
		this.display_data.Total_cost_of_borrowing = (
			parseFloat(this.Payments.Actual_Cumulative_Interest.toFixed(2)) +
			parseFloat(this.Payments.Setup_Fee.toFixed(2))
		).toFixed(2);
		this.Payments.Start_Date = this.formatDate(this.Payments.Start_Date);
		this.display_data.Start_date = this.Payments.Start_Date;
		//this.display_data.Maturity_date = this.Payments.Maturity_Date
		this.display_data.First_payment_amount =
			this.Payments.schedule[0].set_Amortization;
		//Last Payment amount should be changed here
		//this.display_data.Last_payment_amount = ((parseFloat(this.Payments.schedule[this.Payments.Term_of_Loan - 1].set_Amortization)) + this.Payments.LPP).toFixed(2)//Should the LPP be included over here
		this.display_data.Setup_fee = this.Payments.Setup_Fee.toFixed(2);
		this.display_data.CU_fee = this.Payments.CU_Fee.toFixed(2);
		this.display_data.Accumulative_interest =
			this.Payments.Actual_Cumulative_Interest.toFixed(2);
		this.display_data.LPP_total_interest =
			this.Payments.LPP_total_interest.toFixed(2);
		this.display_data.Term = (this.Payments.Duration * 12).toString();
		this.payment_Flag = true;
		this.scheduleArray = this.ProperSchedule.map((entries) =>
			Object.values(entries)
		);
		this.doc.text('Amortization Schedule', 160, 30);
		this.doc.setFontSize(12);
		this.doc.text(
			'Applicant Name: ' + this.loanInput.Application_Name.toString(),
			30,
			45
		);
		//this.doc.text("Loan Number: " + this.loanInput.Loan_Number.toString(), 30, 55)
		this.doc.text('Loan Date: ' + this.loanInput.Loan_Date, 30, 65);
		this.doc.text('Term Type: ' + this.loanInput.Term_Type, 30, 75);
		this.doc.text('Term/Term Due: NEED INFO', 30, 85);
		this.doc.text('Maturity Date: ' + this.loanInput.Last_Payment_Date, 30, 95);
		this.doc.text('Loan Amount: ' + this.loanInput.Loan_Amount, 30, 105);
		this.doc.text('Scheduled Payments: ' + this.Payments.Amortization, 30, 115);
		this.doc.text('Starting/Current Rate: NEED INFO', 30, 125);
		let col = [
			'Payment',
			'Payment Date',
			'Loan Amount',
			'Amortization Payment',
			'Balance',
		];
		this.doc.autoTable({
			styles: {
				fontSize: 12,
			},
			theme: 'grid',
			startY: 150,
			head: [col],
			body: this.scheduleArray,
		});
		if (await this.validationAfterCalculation(this.loanInput.existing_flag)) {
			this.updateZOHOApplication();
			this.updateAdditionalDebtZOHOApplication();
			this.updateMonthlyPaymentsUnderwriting();
			//this.updateLoanPaymentsMonthlyUnderwriting()
			//UPDATE TDSR HERE
		} else {
			this.resetForm();
		}
		this.spinner = false;
	}
	updateTDSRMonthlyPayments() { }
	adjustLastPaymentDateandAmount() {
		let schedule_payment = this.display_data.Payment;
		console.log('Adjusting Last Payment');
		for (var i = 0; i < this.Payments.Term_of_Loan; i++) {
			if (parseFloat(this.Payments.schedule[i].set_LPP_Ending_Balance) < 0) {
				try {
					if (
						parseFloat(this.Payments.schedule[i - 1].set_LPP_Ending_Balance) <
						parseFloat(schedule_payment)
					) {
						console.log('WE ARE IN THE CONDITION ');
						this.display_data.Last_payment_amount = parseFloat(
							this.Payments.schedule[i - 1].set_LPP_Ending_Balance
						).toFixed(2);
						this.display_data.Maturity_date = this.formatMaturityDate(
							this.Payments.schedule[i].set_Payment_Date
						);
						this.Payments.Maturity_Date = this.display_data.Maturity_date;
						console.log(this.display_data.Last_payment_amount);
						console.log(this.display_data.Maturity_date);
						return;
					} else {
						console.log('WE ARE IN THE ELSE CONDITION');
						console.log(
							parseFloat(this.Payments.schedule[i - 1].set_LPP_Ending_Balance)
						);
						console.log(parseFloat(schedule_payment));
						this.display_data.Last_payment_amount = (
							parseFloat(this.Payments.schedule[i - 1].set_Amortization) +
							this.Payments.LPP
						).toFixed(2);
						this.display_data.Maturity_date = this.formatMaturityDate(
							this.Payments.schedule[i - 1].set_Payment_Date
						);
						this.Payments.Maturity_Date = this.display_data.Maturity_date;
						console.log(this.display_data.Last_payment_amount);
						console.log(this.display_data.Maturity_date);
						return;
					}
				} catch (err) {
					alert('error calculating Amo schedule');
					this.Payments.Maturity_Date = this.formatMaturityDate(
						this.Payments.schedule[this.Payments.Term_of_Loan - 1]
							.set_Payment_Date
					);
					this.display_data.Maturity_date = this.Payments.Maturity_Date;
					this.display_data.Last_payment_amount = (
						parseFloat(
							this.Payments.schedule[this.Payments.Term_of_Loan - 1]
								.set_Amortization
						) + this.Payments.LPP
					).toFixed(2); //Should the LPP be included over here
					return;
				}
			}
		}
		this.Payments.Maturity_Date = this.formatMaturityDate(
			this.Payments.schedule[this.Payments.Term_of_Loan - 1].set_Payment_Date
		);
		this.display_data.Maturity_date = this.Payments.Maturity_Date;
		this.display_data.Last_payment_amount = (
			parseFloat(
				this.Payments.schedule[this.Payments.Term_of_Loan - 1].set_Amortization
			) + this.Payments.LPP
		).toFixed(2); //Should the LPP be included over here
	}
	SavePDF() {
		this.doc.save(this.loanInput.Application_Name.concat(' AM Schedule.pdf'));
	}
	async checkApprovedAmountZOHO(): Promise<number> {
		return new Promise((resolve, reject) => {
			this.apiService.getZohoApplication(this.zohoApplicationID).subscribe((res) => {
				resolve(res['data'][0]['Amount']);
			});
		});
	}
	// // async checkApprovedAmountZOHO() {
	// // 	return new Promise((resolve, reject) => {
	// // 		this.apiService
	// // 			.getZohoApplication(this.zohoApplicationID)
	// // 			.subscribe((res) => {
	// // 				if (!res) {
	// // 					alert('UNABLE TO CONNECT TO ZOHO - PLEASE TRY AGAIN IN 1 MINUTE');
	// // 					resolve(false);
	// // 				} else {
	// // 					console.log('HERE IS THE Amount');
	// // 					let approved_amount = res['data'][0]['Amount'];
	// // 					console.log(approved_amount);
	// // 					if (this.loanInput.Loan_Amount != approved_amount) {
	// // 						resolve(false);
	// // 					} else {
	// // 						resolve(true);
	// // 					}
	// // 				}
	// // 			});
	// // 	});
	// // }
	async updateZOHOApplication() {
		if (!this.zohoexists) {
			console.log('Zoho will be updated');
			const mainSettlementAmount = this.additionalDebt_settlment.filter(item => item.source === 'main')[0]['Settlement_Amount'];
			const mainLegalFee = this.additionalDebt_settlment.filter(item => item.source === 'main')[0]['Legal_Fee'];
			const totalLegalFees = this.additionalDebt_settlment.map(item => item?.Legal_Fee).reduce((prev, next) => parseFloat((prev + next).toFixed(2)));
			const mainAccountBalance = this.additionalDebt_settlment.filter(item => item.source === 'main')[0]['dsLoanAmount'];
			// console.log(mainSettlementAmount, "&&&&&CHECK MAIN SETTLEMENT AMOUNT!")
			this.Payments.mainSettlementAmount = mainSettlementAmount;
			this.Payments.mainLegalFee = mainLegalFee;
			this.Payments.customZOHOCount = this.countZOHOCustomSettlment;
			this.Payments.totalLegalFees = totalLegalFees;
			this.Payments.mainAccountBalance = mainAccountBalance;
			this.apiService.updateZohoApplication(this.Payments).subscribe((res) => {
				if (!res)
					alert(
						'UNABLE TO CONNECT TO ZOHO - FUNDING DETAILS MUST BE MANUALLY UPDATED ON THE ZOHO APPLICATION'
					);
			});
		} else {
			console.log('Zoho was not updated');
		}
		// let approved_amount_check = await this.checkApprovedAmountZOHO()
		// if (approved_amount_check) {
		//   if (!this.zohoexists) {
		//     console.log("Zoho will be updated")
		//     this.apiService.updateZohoApplication(this.Payments).subscribe((res) => {
		//       if (!res)
		//         alert("UNABLE TO CONNECT TO ZOHO - FUNDING DETAILS MUST BE MANUALLY UPDATED ON THE ZOHO APPLICATION")
		//     })
		//   }
		//   else {
		//     console.log("Zoho was not updated")
		//   }
		// }
		// else {
		//   alert("LOAN AMOUNT IN CALCUALTOR DOES NOT MATCH APPROVED AMOUNT ON APPLICATION. FAILED TO UPDATE ZOHO")
		// }
	}

	async updateAdditionalDebtZOHOApplication() {
		if (!this.zohoexists) {
			console.log('Zoho will be updated');
			this.apiService.updateAdditionalDebtZOHOApplication(this.additionalDebt_settlment).subscribe((res) => {
				// console.log(res, "UDPATEZOHO++++++++++++++++")
				if (!res)
					alert(
						'UNABLE TO CONNECT TO ZOHO - FUNDING DETAILS MUST BE MANUALLY UPDATED ON THE ZOHO APPLICATION'
					);
			});
		} else {
			console.log('Zoho was not updated');
		}
	}
	async updateMonthlyPaymentsUnderwriting() {
		this.apiService
			.updateMonthlyPaymentsUnderwriting(
				this.Payments.application_id,
				this.Payments.Payment_Amount_Insurance_Monthly
			)
			.subscribe((res) => {
				console.log('Updating Monthly Payments');
				console.log(res);
			});
	}
	uploadGD() {
		if (
			confirm(
				'Are you sure you want to upload this Amortization schedule to Google Drive?'
			)
		) {
			this.loanInput.base64_PDF = JSON.stringify(
				this.doc.output('datauristring')
			);
			this.apiService.getGoogleDrive(this.loanInput).subscribe((res) => { });
		}
	}

	// Get the debt settlement variables
	getnewLoanAmount() {
		return new Promise((resolve, reject) => {
			this.apiService.getcalculatedNewLoanAmount().subscribe((res: any) => {
				resolve(res);
			})
		})
	}

	// calculateNewLoanAmount(originalRequested: any) {
	// 	return new Promise(async (resolve, reject) => {
	// 		const dsCreditorFees = this.getFees(this.loanInput.Creditor_Portfolio, this.debtSettlementFees);
	// 		if (this.loanInput.Creditor_Portfolio === 'contingency') {
	// 			this.dsRate = dsCreditorFees?.contingency_dsRate;
	// 			this.dsFeeRate = dsCreditorFees?.contingency_dsFeeRate;
	// 			this.dsAmount = (originalRequested * this.dsRate);
	// 			this.dsLoanAmount = originalRequested - this.dsAmount;
	// 			this.dsFee = this.dsLoanAmount * this.dsFeeRate;
	// 			this.newLoanAmount = (this.dsLoanAmount + this.dsFee);
	// 			resolve(true);
	// 		} else if (this.loanInput.Creditor_Portfolio === 'purchase') {
	// 			this.dsRate = dsCreditorFees?.purchase_dsRate;
	// 			this.dsFeeRate = dsCreditorFees?.purchase_dsFeeRate;
	// 			this.dsAmount = (originalRequested * this.dsRate);
	// 			this.dsLoanAmount = originalRequested - this.dsAmount;
	// 			this.dsFee = this.dsLoanAmount * this.dsFeeRate;
	// 			this.newLoanAmount = (this.dsLoanAmount + this.dsFee);
	// 			resolve(true);
	// 		} else {
	// 			alert('Missing Loan Information');
	// 			resolve(false);
	// 			return;
	// 		}
	// 	})

	// }

	// Identify the variable values based on the naming
	getFees(creditorPortfolio: any, debtSettlementFees: any) {
		return Object.keys(debtSettlementFees).reduce((result, key) => {
			creditorPortfolio = creditorPortfolio !== null ? creditorPortfolio : '';
			if (key.startsWith(creditorPortfolio.toLowerCase())) {
				result[key] = debtSettlementFees[key];
			}
			return result;
		}, {} as Record<string, number>)
	}

	// Add the calculated debt settlement amount
	getNewSettlementAmount(id: any, source: any, creditor: any, portfolio: any, originalRequested: any, customSettlement: any, customAgencyAmount: any) {
		return new Promise(async (resolve, reject) => {
			const dsCreditorFees = this.getFees(portfolio, this.debtSettlementFees);
			if (portfolio === 'Contingency') {
				this.dsRate = dsCreditorFees?.contingency_dsRate;
				this.dsFeeRate = dsCreditorFees?.contingency_dsFeeRate;
			} else if (portfolio === 'Purchase') {
				this.dsRate = dsCreditorFees?.purchase_dsRate;
				this.dsFeeRate = dsCreditorFees?.purchase_dsFeeRate;
			} else if (portfolio === 'Open') {
				this.dsRate = dsCreditorFees?.open_dsRate;
				this.dsFeeRate = dsCreditorFees?.open_dsFeeRate;
			}
			this.dsAmount = customSettlement === 'Yes' ? 0 : parseFloat((originalRequested * this.dsRate).toFixed(2));
			this.dsLoanAmount = customSettlement === 'Yes' ? customAgencyAmount : parseFloat((originalRequested - this.dsAmount).toFixed(2));
			this.dsFee = parseFloat((this.dsLoanAmount * this.dsFeeRate).toFixed(2));
			this.newLoanAmount = parseFloat((this.dsLoanAmount + this.dsFee).toFixed(2));
			// console.log(this.dsRate,this.dsFeeRate,this.dsAmount,this.dsLoanAmount,this.dsFee,this.newLoanAmount,"&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&=======================")
			this.additionalDebt_settlment.push({
				id: id,
				source: source,
				creditor: creditor,
				portfolio: portfolio,
				dsRate: this.dsRate,
				dsFeeRate: this.dsFeeRate,
				dsAmount: this.dsAmount,
				dsLoanAmount: this.dsLoanAmount,
				Legal_Fee: this.dsFee,
				account_balance: originalRequested,
				Settlement_Amount: this.newLoanAmount,
				Custom_Settlement: customSettlement
			})
			// console.log(this.additionalDebt_settlment, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&=======================")
			// resolve(this.additionalDebt_settlment);
			resolve(true)
		})
	}

	// Merge parent and child debts
	populateNewCalculation(summaryData: any) {
		return new Promise(async (resolve, reject) => {
			this.additionalDebt_settlment = [];
			this.additionalDebts = summaryData?.Additional_Debt2 === 'Yes' ? summaryData?.Additional_Debt : [];
			// console.log(this.additionalDebts.map(item => (item?.Custom_Settlement === 'Yes') ? item?.Custom_Agency_Amount : item?.Account_Balance), "@@@@@@@CHECK additional total")
			const totalAdditionalDebt = (this.additionalDebts.length > 0) ? this.additionalDebts.map(item => (item?.Custom_Settlement === 'Yes') ? item?.Custom_Agency_Amount : item?.Account_Balance).reduce((prev, next) => prev + next) : 0;
			this.totalAccountBalance = (summaryData?.Custom_Settlement === 'Yes') ? (summaryData?.Custom_Agency_Amount) + totalAdditionalDebt : (summaryData?.Account_Bal) + totalAdditionalDebt;
			this.totalAccountBalance = parseFloat(this.totalAccountBalance.toFixed(2));
			this.loanInput.Creditor_Portfolio = summaryData?.Creditor_Portfolio;
			await this.getNewSettlementAmount(summaryData?.id, 'main', summaryData?.Creditt, summaryData?.Creditor_Portfolio, summaryData?.Account_Bal, summaryData?.Custom_Settlement, summaryData?.Custom_Agency_Amount);
			if (this.additionalDebts.length > 0) {
				for (let i = 0; i < this.additionalDebts.length; i++) {
					await this.getNewSettlementAmount(this.additionalDebts[i]?.id, 'additional_debt', this.additionalDebts[i]?.misc, this.additionalDebts[i]?.Creditor_Portfolio, this.additionalDebts[i]?.Account_Balance, this.additionalDebts[i]?.Custom_Settlement, this.additionalDebts[i]?.Custom_Agency_Amount);
				}
			}
			this.grandTotalNewAmount = parseFloat((this.additionalDebt_settlment.map(item => item?.Settlement_Amount).reduce((prev, next) => prev + next)).toFixed(2));
			this.loanAmountLoader = false;
			resolve(true);
		})
	}

	validateCreditorPortfolio(zohoData: any) {
		return new Promise((resolve, reject) => {
			const portifolioObject = ['contingency', 'purchase', 'open'];
			if (zohoData?.Additional_Debt2 === 'Yes' && objectExist(zohoData?.Additional_Debt)) {
				const parentPortfolioExist = portifolioObject.includes(convertLowerCase(zohoData?.Creditor_Portfolio));
				const childPortfolioExist = zohoData?.Additional_Debt.every(portfolio => {
					return (portfolio?.Custom_Settlement !== 'Yes') ? portifolioObject.includes(convertLowerCase(portfolio?.Creditor_Portfolio)) : true;
				}
				);
				resolve(parentPortfolioExist && childPortfolioExist);
			} else {
				const parentPortfolioExist = portifolioObject.includes(convertLowerCase(zohoData?.Creditor_Portfolio));
				resolve(parentPortfolioExist);
			}
		})
	}

	validateAccountBalance(zohoData: any) {
		return new Promise((resolve, reject) => {
			if (zohoData?.Additional_Debt2 === 'Yes' && objectExist(zohoData?.Additional_Debt)) {
				const parentAccountBalExist = zohoData?.Account_Bal !== null;
				const childAccountBalExist = zohoData?.Additional_Debt.every(account => {
					return (account?.Custom_Settlement !== 'Yes') ? account?.Account_Balance !== null : true;
				}
				);
				resolve(parentAccountBalExist && childAccountBalExist);
			} else {
				const parentAccountBalExist = zohoData?.Account_Bal !== null;
				resolve(parentAccountBalExist);
			}
		})
	}

	validateCustomSettlementAmount(zohoData: any) {
		return new Promise((resolve, reject) => {
			if (zohoData?.Additional_Debt2 === 'Yes' && objectExist(zohoData?.Additional_Debt)) {
				const parentCustomAgencyAmount = zohoData?.Custom_Settlement === 'Yes' ? ((zohoData?.Custom_Agency_Amount !== null) ? true : false) : true;
				const childCustomAgencyAmount = zohoData?.Additional_Debt.every(custom =>
					custom?.Custom_Settlement === 'Yes' ? ((custom?.Custom_Agency_Amount !== null) ? true : false) : true
				);
				resolve(parentCustomAgencyAmount && childCustomAgencyAmount);
			} else {
				const parentCustomAgencyAmount = zohoData?.Custom_Settlement === 'Yes' ? ((zohoData?.Custom_Agency_Amount !== null) ? true : false) : true;
				resolve(parentCustomAgencyAmount);
			}
		})
	}

	validateLoanAmountMatch(summaryData: any, accountBalance: any): Promise<number> {
		return new Promise(async (resolve, reject) => {
			let mainResult = await this.loadValidateLoanAmount(summaryData, accountBalance);
			const additionalDebtsV = (summaryData?.Additional_Debt2 === 'Yes') ? summaryData?.Additional_Debt : [];
			let subResult = 0;
			if (additionalDebtsV.length > 0) {
				for (let i = 0; i < additionalDebtsV.length; i++) {
					let result = await this.loadValidateLoanAmount(additionalDebtsV[i], additionalDebtsV[i]?.Account_Balance);
					subResult += result;
				}
			}
			resolve(parseFloat((mainResult + subResult).toFixed(2)))
		})
	}

	loadValidateLoanAmount(summaryData: any, accountBalance: any): Promise<number> {
		return new Promise((resolve, reject) => {
			const dsCreditorFees = this.getFees(summaryData?.Creditor_Portfolio, this.debtSettlementFees);
			let dsRateV = 0;
			let dsFeeRateV = 0.28;
			if (summaryData?.Creditor_Portfolio === 'Contingency') {
				dsRateV = dsCreditorFees?.contingency_dsRate;
				dsFeeRateV = dsCreditorFees?.contingency_dsFeeRate;
			} else if (summaryData?.Creditor_Portfolio === 'Purchase') {
				dsRateV = dsCreditorFees?.purchase_dsRate;
				dsFeeRateV = dsCreditorFees?.purchase_dsFeeRate;
			} else if (summaryData?.Creditor_Portfolio === 'Open') {
				dsRateV = dsCreditorFees?.open_dsRate;
				dsFeeRateV = dsCreditorFees?.open_dsFeeRate;
			}

			const dsAmountV = summaryData?.Custom_Settlement === 'Yes' ? 0 : parseFloat((accountBalance * dsRateV).toFixed(2));
			const dsLoanAmountV = summaryData?.Custom_Settlement === 'Yes' ? summaryData?.Custom_Agency_Amount : parseFloat((accountBalance - dsAmountV).toFixed(2));
			const dsFeeV = parseFloat((dsLoanAmountV * dsFeeRateV).toFixed(2));
			const newLoanAmountV = parseFloat((dsLoanAmountV + dsFeeV).toFixed(2));
			resolve(newLoanAmountV);
		})
	}

	countCustomSettlement(zohoData: any) {
		return new Promise((resolve, reject) => {
			let count = zohoData?.Custom_Settlement === 'Yes' ? 1 : 0;
			count += zohoData?.Additional_Debt2 === 'Yes' ? zohoData?.Additional_Debt.filter(item => item?.Custom_Settlement === 'Yes').length : 0;
			resolve(count);
		})
	}

	updateLoanAmount(newLoanAmount: any) {
		this.sharedService.updateMessage(newLoanAmount);
	}

	checkLoanCalculatorEntry() {
		return new Promise((resolve, reject) => {
			this.apiService
				.getCalculatorInstance(this.loanInput.application_id)
				.subscribe(async (res) => {
					this.performDurationUpdate = (res) ? false : true;
					resolve(true);
				})
		})
	}

	ngOnDestroy() {
		this.summaryDataSubscription?.unsubscribe();
	}
}

const convertLowerCase = (portfolio: any) => {
	return portfolio !== null ? portfolio.toLowerCase() : '';
}

const objectExist = (zohoData: any) => {
	return (zohoData.length > 0) ? true : false;
}
