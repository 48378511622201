import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../../../api-service.service';
import { CustomFunctionService } from '../../../../../custom-function.service';

@Component({
	selector: 'app-nlsdashboard',
	templateUrl: './nlsdashboard.component.html',
	styleUrls: ['./nlsdashboard.component.scss'],
})
export class NlsdashboardComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private customFunction: CustomFunctionService
	) {}

	timestamp = Date();
	totalNLSamount: any;
	nlsFunded: any;
	countNLS: any;

	ngOnInit(): void {
		this.fetchNLSdata();
	}

	fetchNLSdata() {
		this.apiService.getNLSFundedDashboard().subscribe((res) => {
			this.nlsFunded = res.data;

			this.countNLS = res.data.length;
			this.totalNLSamount = this.customFunction
				.sumNumbers(this.nlsFunded, 'original_note_amount')
				.toFixed(2);

			// console.log(this.nlsFunded)
			// console.log(this.totalNLSamount)
		});
	}

	refresh() {
		window.location.reload();
	}
}
