<div style="width: 500px; height: 325px;">
    <div style="text-align: center;" *ngIf="inquiryComplete else inquiryForm">
        <h2 style="color: rgb(87, 194, 87); margin-top: 100px;">Submitted</h2>
        <mat-icon
            style="color: rgb(87, 194, 87);height: 200px; width: 500px; font-size: 150px;">check_circle_outline</mat-icon>
    </div>
    <ng-template #inquiryForm>
        <div mat-dialog-content>
            <div>
                What is your email address? *
                <input class=" inputStyle" [formControl]="inquiryEmail">
                <sup *ngIf="inquiryEmail.hasError('required') && inquiryEmail.touched">
                    Email address is required
                </sup>
            </div>
            <div>
                How did you arrive at the lm.credit website? *
                <select class="inputStyle" [formControl]="inquirySource">
                    <option>Given website by LM Credit Agent</option>
                    <option>Searched website through Google</option>
                    <option>I got the website from info@lmcredit.com</option>
                    <option>I got the website from noreply@lmcredit.com</option>
                    <option>I am not sure how I got to this website</option>
                </select>
                <sup *ngIf="inquirySource.hasError('required') && inquirySource.touched">
                    Source is required
                </sup>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button [disabled]="inquiryEmail.invalid || inquirySource.invalid"
                (click)="handleCantLogin()">OK</button>
        </div>
    </ng-template>
</div>