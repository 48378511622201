<div class="container-fluid">
	<h5>{{ agencyName }} Collectors</h5>
	<table class="table table-bordered" id="myTable">
		<thead>
			<tr>
				<th>Collector</th>
				<th>Count</th>
				<th>Amount</th>
			</tr>
		</thead>
		<tbody id="myBody">
			<div
				*ngIf="!collectorsArray || collectorsArray.length === 0; else content"
			>
				No NLS Onboarding!
			</div>
			<ng-template #content>
				<tr *ngFor="let item of collectorsArray">
					<td>{{ item.CollectorName }}</td>
					<td>{{ item.CollecterCount }}</td>
					<td>{{ item.TotalAmount | currency }}</td>
				</tr>
				<tr style="font-weight: bold">
					<td>Total</td>
					<td>{{ countCollectedMonth }}</td>
					<td>{{ totalCollectedfundedMonth | currency }}</td>
				</tr>
			</ng-template>
		</tbody>
	</table>
</div>
