<div class="row row-cols-2" style="padding: 20px;">
    <div class="col-2">
        <mat-form-field class=" table-filter" appearance="outline" style="margin-right: 15px; width: 100%;">
            <mat-label>Select Input Option</mat-label>
            <mat-select [(ngModel)]="selectedInputValue" (ngModelChange)="selectedInputOption($event)">
                <mat-option *ngFor="let item of input_value" [value]="item.insertValue">
                    {{item.insertValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-10">
        <mat-form-field appearance="outline" style="width: 80%">
            <mat-label>Enter {{selectedInputValue}}</mat-label>
            <textarea matInput rows="1" cols="20" [formControl]="inputList" placeholder="Ex.: 1,2,3,4,5"
                required></textarea>
            <mat-error *ngIf="
                    inputList.hasError(
                                    'required'
                                ) && inputList.touched
                            ">
                {{selectedInputValue}} required
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-2">
        <mat-slide-toggle [(ngModel)]="isManager" style="float: right;">Manager Assigned</mat-slide-toggle>
        <mat-form-field class=" table-filter" appearance="outline" style="margin-right: 15px; width: 100%">
            <mat-label>Select Collector</mat-label>
            <mat-select [(ngModel)]="selectedEmpID" (selectionChange)="selectedCollector($event)"
                [formControl]="collectorName" required>
                <mat-option *ngFor="let item of collectorNames" [value]="item.employeeID">
                    {{item.full_name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="
                collectorName.hasError(
                                    'required'
                                ) && collectorName.touched
                            ">
                Collector name required
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-10">
        <div style="display: flex; padding-top: 25px;">
            <div style="display: flex; flex-direction: column;">
                <button mat-raised-button color='primary'
                    style="align-items: center; justify-content: center; width: 150px; margin-right: 10px;"
                    (click)="assignCollectorList()" [disabled]="inputList.invalid || collectorName.invalid">
                    <span *ngIf=" btnSpinner" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span *ngIf="btnShowText">Save</span>
                </button>
            </div>
            <h5 *ngIf="displayResult">
                UPDATED:
                SPV: {{displayResult?.SPV}}
                MFT: {{displayResult?.MFT}}
                Debenture: {{displayResult?.Debenture}}
                QVEST: {{displayResult?.QVEST}}
            </h5>
        </div>
    </div>
</div>