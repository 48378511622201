import { Component, Inject, Optional, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from '@lmc-app/api-service.service';

@Component({
  selector: 'app-login-inquiry-modal',
  templateUrl: './login-inquiry-modal.component.html',
  styleUrls: ['./login-inquiry-modal.component.scss']
})
export class LoginInquiryModalComponent implements OnInit {
  inquiryEmail = new FormControl('', Validators.required);
  inquirySource = new FormControl('', Validators.required);

  inquiryComplete: boolean = false;

  constructor(private apiservice: ApiServiceService, public dialogRef: MatDialogRef<LoginInquiryModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  handleCantLogin = () => {
    let clientInquiryObject = {
      emailAddress: this.inquiryEmail.value,
      source: this.inquirySource.value
    };
    this.apiservice.sendLoginFailureEmail(this.data, clientInquiryObject).subscribe(() => {
      // console.log('Login failure email sent');
      this.inquiryComplete = true;
    });
  };
}
