import { Component, OnInit, Optional, Inject } from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	UntypedFormArray,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from '../api-service.service';

@Component({
	selector: 'app-monthlyincomecalc',
	templateUrl: './monthlyincomecalc.component.html',
	styleUrls: ['./monthlyincomecalc.component.scss'],
})
export class MonthlyincomecalcComponent implements OnInit {
	salaryFrequency: any;
	salaryPool: any;
	fromDialog: string;

	// Loan Interview Notes
	incomeForm = new UntypedFormGroup({
		months: new UntypedFormControl(''),
		payType: new UntypedFormControl(''),
		averageSalary: new UntypedFormControl('', Validators.required),
		SALARIES: this.fb.array([]), // Subform
	});

	constructor(
		private apiService: ApiServiceService,
		private fb: UntypedFormBuilder,
		public dialogRef: MatDialogRef<MonthlyincomecalcComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {}

	calculate() {
		let totalSubformAdditional = this.incomeForm
			.get('SALARIES')
			.value.map((item) => item.amount)
			.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
		if (this.salaryFrequency === 'Equal') {
			this.incomeForm.patchValue({
				averageSalary: totalSubformAdditional.toFixed(2),
			});
		}
		if (this.salaryFrequency === 'Variable') {
			this.incomeForm.patchValue({
				averageSalary: (
					totalSubformAdditional / this.incomeForm.get('months').value
				).toFixed(2),
			});
		}
	}

	payType(e) {
		this.salaryFrequency = e.target.value;
		if (this.salaryFrequency === 'Variable') {
			document.getElementById('displayMonths').style.display = 'block';
			this.clear();
			this.salaryForm();
		} else {
			document.getElementById('displayMonths').style.display = 'none';
			this.clear();
			this.salaryForm();
		}
	}

	generateForm() {
		this.clear();
		this.salaryForm();
	}

	salaryForm() {
		let num;
		if (this.salaryFrequency === 'Equal') {
			num = 1;
		} else {
			num = this.incomeForm.get('months').value;
		}

		// Push the values into subform array
		this.salaryPool = this.incomeForm.get('SALARIES') as UntypedFormArray;
		for (let i = 0; i < num; i++) {
			this.salaryPool.push(
				this.fb.group({
					amount: '',
				})
			);
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	okSend() {
		let list = this.incomeForm.get('SALARIES').value.map((item) => item.amount);

		let expression =
			list.join('+') +
			'/' +
			list.length +
			'=' +
			this.incomeForm.get('averageSalary').value;
		this.apiService.sendClickEvent();
		this.dialogRef.close({
			event: 'close',
			data: this.incomeForm.value,
			dataExpression: expression,
		});
	}

	clear() {
		let salaryPool = this.incomeForm.get('SALARIES') as UntypedFormArray;
		while (salaryPool.length !== 0) {
			salaryPool.removeAt(0);
		}

		this.incomeForm.get('averageSalary').setValue('');
		this.incomeForm.get('months').reset;
	}
}
