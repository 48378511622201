<div class="row">
	<!-- <ng-multiselect-dropdown
       name="city"
       [data]="primaryIndustry"
       [(ngModel)]="selectedItem"
       [settings]="dropdownSettings"
       (onSelect)="onItemSelect($event)"
       [disabled]="disabled"></ng-multiselect-dropdown> -->
	<div class="col-sm-3" *ngIf="co_app_required === 'Yes'; else elseCoAppRequired">
		<select class="form-select" style="font-weight: bold; font-size: large; width: auto"
			(change)="switchInterview($event.target.value)" [(ngModel)]="applicationType">
			<option value="" disabled selected>- Select Loan Interview -</option>
			<option value="Primary">Start Primary Loan Interview</option>
			<option value="Co-Applicant">Start Co-Applicant Loan Interview</option>
		</select>
		<!-- <div class="row">
            <div class="col-2" *ngIf="coAppRequired  === 'Yes'; else elseCoAppRequired">
                <select class="form-select" (change)="clickType($event.target.value)">
                    <option value="Primary Applicant" selected>Primary Applicant</option>
                    <option value="Co-Applicant">Co-Applicant</option>
                </select>
            </div>
            <ng-template #elseCoAppRequired>
                <div class="col-2">
                    <select class="form-select" (change)="clickType($event.target.value)">
                        <option value="Primary Applicant" selected>Primary Applicant</option>
                    </select>
                </div>
            </ng-template>
        </div> -->
	</div>
	<ng-template #elseCoAppRequired>
		<div class="col-sm-3">
			<select class="form-select" style="font-weight: bold; font-size: large; width: auto"
				(change)="switchInterview($event.target.value)" [(ngModel)]="applicationType">
				<option value="" disabled selected>- Select Loan Interview -</option>
				<option value="Primary">Start Primary Loan Interview</option>
			</select>
		</div>
	</ng-template>
</div>
<div class="row row-cols-12" id="loanInterviewDisplay">
	<span class="spinner-border" id="loading" *ngIf="spinner === true"></span>
	<div class="row" style="justify-content: right">
		<div class="sticky">
			<div class="col-sm-10" style="float: right">
				<div *ngIf="counter; else elseBlock" class="timer-counter-label">
					{{ display_time }}
				</div>
				<ng-template #elseBlock>
					<div class="timer-counter-label">00:00:00</div>
				</ng-template>
			</div>

			<!-- <div class="col-sm-2" style="float: left;">
                <img id="stopButton" src="/assets/images/stop.png" title="Stop" width="20" height="20"
                    style="cursor: pointer; margin-top: 8px;" (click)="editToStartTimer()">
                <img id="playButton" src="/assets/images/play.png" title="Start" width="20" height="20"
                    style="cursor: pointer; margin-top: 8px;" (click)="editToStartTimer()">
            </div> -->
		</div>
	</div>
	<div class="col-sm-12">
		<form [formGroup]="underwritingExForm">
			<!-- <label class="interviewFont">Hi, can I please speak with <strong>{{Deal_Name}}</strong>
                ?</label><br><br> -->
			<label class="interviewFont"><br /><strong>(If applicant was transferred over for interview)</strong><br />
				Thank you for holding. My name is <strong>{{ loggedName }}</strong>. I am one of the Underwriters here
				at LM Credit and will be conducting
				your loan interview. I am just waiting for TransUnion to release your
				credit report to me. Is it ok if I put you on a one-minute hold?<br /><br />
			</label>
			<label class="interviewFont"><strong>(If UW does outbound call to applicant)</strong><br />
				Hello, may I please speak with <strong>{{ Deal_Name }}</strong>? My name is <strong>{{ loggedName
					}}</strong> I am one of the
				Underwriters here at LM Credit, how are you doing today? I am calling
				because you are ready to have a loan interview completed and should take
				about 10 to 15 mins. is now a good time?<br /><br />
			</label>
			<!-- <label class="interviewFont">My name is <strong>{{loggedName}}</strong> from LM Credit
                and I am calling to conduct a loan interview, which takes about 10
                mins.</label><br><br> -->
			<label class="interviewFont">Before we start, please note all calls are recorded for quality
				assurance and training purposes, and to protect your privacy, can you
				please confirm your Date of Birth and full address?</label><br /><br />
			<span class="interviewFont">
				DOB:
				<input type="date" class="input-control" formControlName="dob" /><br /><br />
				Unit:
				<input type="text" class="input-control" formControlName="unit" />&nbsp;Address:
				<input type="text" class="input-control" formControlName="address" />
				&nbsp; City:
				<input type="text" class="input-control" formControlName="city" />
				&nbsp; Province:
				<select class="input-control" formControlName="province">
					<option *ngFor="let i of provinceList" [Value]="i.refValue">
						{{ i.picklistValue }}
					</option>
				</select>
				&nbsp;Postal Code:
				<input type="text" class="input-control" formControlName="postalCode" /> </span><br /><br />
			<!-- <span id="onlyPrimaryQuestion"> -->
			<span *ngIf="applicationType === 'Primary'">
				<label class="interviewFont">You have been referred over to us from &nbsp;
					<ng-select formControlName="agencies">
						<ng-option *ngFor="let i of agencyName" [value]="i.id">{{
							i.Account_Name
							}}</ng-option>
					</ng-select>
				</label>
				<label class="interviewFont">&nbsp; regarding a&nbsp;
					<ng-select formControlName="creditt">
						<ng-option *ngFor="let i of creditorName" [value]="i.display_value">{{ i.display_value }}
						</ng-option>
					</ng-select>
				</label>
				<label class="interviewFont">
					matter and they are asking for
					<input type="number" class="input-control" formControlName="accountBalance" />
					is that correct? </label><br /><br />
				<label class="interviewFont">---Thank you---</label><br /><br />
				<label class="interviewFont">1) You are currently working
					<select formControlName="jobStatus" class="input-control">
						<option *ngFor="let i of primaryEmpStatus" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
					at
					<input type="text" class="input-control" formControlName="company" />
					as a
					<input type="text" class="input-control" formControlName="position" />
					for
					<input type="text" class="input-control" formControlName="empLength" />
					and get paid
					<input type="text" class="input-control" formControlName="salary" />
					&nbsp;
					<select formControlName="frequency" class="input-control">
						<option *ngFor="let i of primaryPayFrequency" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
					? </label><br /><br />
				<div class="row">
					<div class="col-4">
						<label class="interviewFont">Industry: &nbsp;
							<ng-select formControlName="industry">
								<ng-option *ngFor="let item of primaryIndustry" [value]="item.display_value">
									{{ item.display_value }}</ng-option>
							</ng-select>
						</label>
					</div>
					<div class="col-4">
						<label class="interviewFont">Occupation: &nbsp;
							<ng-select formControlName="occupation">
								<ng-option *ngFor="let i of primaryOccupation" [value]="i.display_value">
									{{ i.display_value }}
								</ng-option>
							</ng-select>
						</label>
					</div>
				</div>
				<br />
				<label class="interviewFont">2) Are you paid direct deposit or with a cheque?
					<select formControlName="Payment_Method" class="input-control">
						<option value="Direct deposit">Direct deposit</option>
						<option value="Cheque">Cheque</option>
						<option value="Cash">Cash</option>
					</select>
					Perfect! When was your last pay?
					<input type="date" class="input-control" formControlName="Last_Pay_Date" />When do you get paid
					next?
					<input type="date" class="input-control" formControlName="Next_Pay_Date" /> </label><br /><br />
			</span>
			<!-- <span id="onlyCoAppQuestion"> -->
			<span *ngIf="applicationType === 'Co-Applicant'">
				<label class="interviewFont">---Thank you---</label><br /><br />

				<label class="interviewFont">1) You are currently working
					<select formControlName="jobStatus" class="input-control">
						<option *ngFor="let i of coAppEmpStatus" [value]="i.display_value">
							{{ i.display_value }}
						</option>
					</select>
					at
					<input type="text" class="input-control" formControlName="company" />
					as a
					<input type="text" class="input-control" formControlName="position" />
					for
					<input type="text" class="input-control" formControlName="empLength" />
					and get paid
					<input type="text" class="input-control" formControlName="salary" />
					&nbsp;
					<select class="input-control" formControlName="frequency">
						<option *ngFor="let item of this.lookUps.pay_frequency" [value]="item.display_value">
							{{ item.display_value }}
						</option>
					</select>
					<!-- <input type="text" class="input-control" formControlName="frequency"> -->
					?<br /><br /> </label><br /><br />
				<div class="row">
					<div class="col-4">
						<label class="interviewFont">Industry: &nbsp;
							<ng-select formControlName="industry">
								<ng-option *ngFor="let i of coAppIndustry" [value]="i.display_value">{{ i.display_value
									}}</ng-option>
							</ng-select>
						</label>
					</div>
					<div class="col-4">
						<label class="interviewFont">Occupation: &nbsp;
							<ng-select formControlName="occupation">
								<ng-option *ngFor="let i of coAppOccupation" [value]="i.display_value">{{
									i.display_value }}</ng-option>
							</ng-select>
						</label>
					</div>
				</div>
				<br />
			</span>
			<label class="interviewFont">3) Do you have any active payday loans?
				<select class="input-control" formControlName="Review_any_payday_loan_usage"
					(change)="paydayCommentsPrimary($event)">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
				<!-- <span id="paydayCommentsPrimaryText"> -->
				<div *ngIf="
						underwritingExForm.get('Review_any_payday_loan_usage').value ===
						'Yes'
					">
					Payday Comments
					<input class="input-control" style="width: 500px" type="text"
						formControlName="Payday_Loans_Comments" />
				</div>
			</label><br /><br />
			<label class="interviewFont">4) Do you have an overdraft limit?
				<select class="input-control" formControlName="Overdraft_Limit_Question"
					(change)="overdraftPrimary($event)">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
				<!-- <span id="overdraftPrimary"> -->
				<div *ngIf="
						underwritingExForm.get('Overdraft_Limit_Question').value === 'Yes'
					">
					<input class="input-control" type="number" formControlName="Overdraft_Limit" />
				</div>
				(Explain overdraft if client does not understand)
			</label><br /><br />
			<label class="interviewFont">5) Is your bank account joint with anyone else?
				<select class="input-control" formControlName="Yes_No_Is_this_a_joint_Account"
					(change)="jointAcctPrimary($event)">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
				(if yes, ask if the other person joint has payroll that gets deposit in
				that account)
				<!-- <span id="jointAcctPrimary"> -->
				<div *ngIf="
						underwritingExForm.get('Yes_No_Is_this_a_joint_Account').value ===
						'Yes'
					">
					<input class="input-control" style="width: 500px" type="text"
						formControlName="Is_this_a_joint_Account" />
				</div>
			</label><br /><br />
			<label class="interviewFont" style="line-height: normal">6) Other Bank Account
				<select class="input-control" formControlName="Other_Bank_Account">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select> </label><br /><br />
			<label class="interviewFont" style="line-height: normal">7) Are you up to date with your taxes?
				<select class="input-control" formControlName="When_was_the_last_time_you_filed_taxes">
					<option value="Up to Date">Up to Date</option>
					<option value="Behind">Behind</option>
					<option value="Never">Never</option>
				</select> </label><br /><br />
			<label class="interviewFont">8) Do you currently have anything owing to the CRA? (If so how much?)
				<input class="input-control" type="number"
					formControlName="How_much_do_you_owe_Revenue_Canada" /></label><br /><br />
			<label class="interviewFont">9) Have you setup an arrangement with the CRA?
				<select class="input-control" formControlName="Have_you_setup_an_arrangement_with_the_CRA">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</label>
			<br /><br />
			<label class="interviewFont">10) And how did you get behind on your collection items? </label><textarea
				class="input-control" rows="1" cols="40" onfocus="this.rows=2; "
				formControlName="Reason_for_falling_behind"></textarea><br /><br />
			<label class="interviewFont">11) Is anyone helping you with household expenses?
				<select class="input-control" formControlName="Do_you_live_with_anyone_else">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select> </label><br /><br />
			<label class="interviewFont">12) How many dependents do you have?
				<select class="input-control" formControlName="Dependents">
					<option value="0">0</option>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
					<option value="9">9</option>
					<option value="10">10</option>
					<option value="10+">10+</option>
				</select> </label><br /><br />
			<label class="interviewFont">
				13) Are you a homeowner?
				<select class="input-control" formControlName="are_u_homeowner" (change)="interviewHomeOwner($event)">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
				<!-- <span id="interviewHomeOwner"> -->
				<div *ngIf="underwritingExForm.get('are_u_homeowner').value === 'Yes'">
					(If homeowner, ask if they are up to date with their property
					taxes.)<br />
					i. Do you owe any property tax?
					<input class="input-control" type="text" style="margin-top: 5px"
						formControlName="property_taxes" /><br />
					ii. Is there a mortgage on the property?
					<select style="margin-top: 5px" class="input-control" formControlName="mortgage_property">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select><br />
					iii. Who is your mortgage with?
					<input type="text" class="input-control" style="margin-top: 5px"
						formControlName="mortgage_with" /><br />
					iv. What is current value of the property?
					<input type="number" class="input-control" style="margin-top: 5px"
						formControlName="property_value" /><br />
					v. What is the balance of your mortgage?
					<input type="number" class="input-control" style="margin-top: 5px"
						formControlName="mortgage_balance" /><br />
					vi. What interest rate on your mortgage?
					<input type="number" class="input-control" style="margin-top: 5px"
						formControlName="mortgage_interest" /><br />
				</div>
			</label><br /><br />

			<label class="interviewFont">---Expenses---</label><br /><br />

			<label class="interviewFont">1) I am now going to go through your monthly expenses with
				you</label><br /><br />
			<div class="table-responsive">
				<form class="form-group" formArrayName="ADDITIONAL_EXPENSES">
					<table class="table table-bordered table-hover">
						<thead class="table-light">
							<tr class="interviewFont">
								<th class="expenseCloseWidth"></th>
								<!-- <th scope="col">Question</th> -->
								<th scope="col">Type</th>
								<th scope="col">Creditor Name</th>
								<th scope="col">Amount</th>
							</tr>
						</thead>
						<tbody class="interviewFont">
							<tr *ngFor="
									let control of underwritingExForm.controls
										.ADDITIONAL_EXPENSES['controls'];
									let i = index
								" [formGroupName]="i">
								<td class="expenseCloseWidth">
									<button class="btn-close" (click)="removeSubformExpenses(i)"
										style="width: 10px; float: left"></button>
								</td>
								<!-- <td>
                                    <select class="form-select" formControlName="questions_subform">
                                        <option *ngFor='let item of questionBanks'
                                            [ngValue]="item.question_id">
                                            {{item.question}}
                                        </option>
                                    </select>
                                    <input type="text" class="form-control" formControlName="questions_subform" [attr.disabled]="true"/>
                                </td> -->
								<td>
									<select class="form-select" formControlName="Type">
										<option *ngFor="let item of questionBanks" [ngValue]="item.expenses_type">
											{{ item.expenses_type }}
										</option>
									</select>
									<!-- <input type="text" class="form-control" formControlName="Type" [attr.disabled]="true"/> -->
								</td>
								<td>
									<input type="text" class="form-control" formControlName="Name1" />
								</td>
								<td style="width: 150px">
									<input type="number" class="form-control" formControlName="Amount"
										(keydown)="addSubformAmountEnter($event)" (change)="addSubformAmount()" />
								</td>
							</tr>
						</tbody>
					</table>
					<button class="btn btn-outline-primary" (click)="addUnderwitingSubform()" style="margin-top: 10px">
						+ Add Expenses
					</button>
				</form>
				<br />
				<table>
					<thead>
						<tr>
							<th style="min-width: 200px"></th>
							<th style="min-width: 200px"></th>
							<th style="min-width: 200px"></th>
						</tr>
					</thead>
					<tbody>
						<tr class="interviewFont">
							<td class="alignExpensesText">Total Income (Monthly)</td>
							<td>
								<input class="input-control" type="number" formControlName="totalMonthSubform"
									(keydown)="enteredTotalMonthSubform($event)"
									(change)="clickedTotalMonthSubform()" />
								&nbsp;
								<img src="/assets/images/incomecalc.png" title="Calculate Average Income" width="20"
									height="30" style="cursor: pointer" (click)="openCalc()" />
							</td>
							<td>
								<sub *ngIf="
										displayMonthlyIncome != null && displayMonthlyIncome != ''
									">{{ displayMonthlyIncome }}
								</sub>
							</td>
						</tr>
						<tr class="interviewFont">
							<td class="alignExpensesText">Total Expenses (Monthly)</td>
							<td>
								<strong>{{ totalSubformAdditional | currency }}</strong>
							</td>
						</tr>
						<tr class="interviewFont">
							<td class="alignExpensesText">TDSR</td>
							<td>
								<strong>{{ tdsr }}%</strong>
							</td>
						</tr>
						<tr class="interviewFont">
							<td class="alignExpensesText">Loan Payments (Monthly)($)</td>
							<td>
								<input type="number" class="input-control" formControlName="currentLoanSubform"
									(keydown)="enteredCurrentLoanSubform($event)"
									(change)="clickedCurrentLoanSubform()" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<br /><br />

			<label class="interviewFont">Institution Name<br /><input type="text" class="input-control"
					formControlName="Financial_Institution_Name" /></label>
			&nbsp;
			<label class="interviewFont">Institution Number<br /><input type="text" class="input-control"
					formControlName="Financial_Institution_Number"
					(keydown)="this.functions.numberOnly($event)" /></label>
			&nbsp;
			<label class="interviewFont">Bank Account Number<br /><input type="text" class="input-control"
					formControlName="Bank_Account_Number" (keydown)="this.functions.numberOnly($event)" /></label>
			&nbsp;
			<label class="interviewFont">Branch Transit Number<br /><input type="text" class="input-control"
					formControlName="Bank_Transit_No" (keydown)="this.functions.numberOnly($event)" /></label>
			&nbsp;
			<label class="interviewFont">Branch Address<br /><input type="text" class="input-control"
					formControlName="Branch_Address" /></label>
			<br /><br />
			<!-- <label class="interviewFont">UW Tier Score
                <select formControlName="uw_tier" class="input-control">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select><br>
            </label> <br><br> -->
			<label class="interviewFont">2) I am quickly going to check your bank statements and credit report
				(Check active trades & put amount and CB in the TDS underwriting tab
				drop down ex. Credit cards, car payments, installment loans etc...
				)</label>
			<br /><br />
			<label class="interviewFont">3) I am now going to go through some of your transactions and locate
				payroll deposits as well. (Check for insurance payments, payday loans,
				etc... ) </label><br /><br />
			<label class="interviewFont">4) Please give me a moment while I get an average of your monthly
				income (complete the income formula and place that amount in the income
				field in the in underwriting TDS tab)</label><br /><br />
			<label class="interviewFont">5) Do you have any other loans or bills that you are responsible
				for?</label><br /><br />
			<label class="interviewFont">--Final Statments--</label><br /><br />
			<p class="interviewFont">
				Thank you <strong>{{ Deal_Name }}</strong>, now that I have all your information I will submit the
				application to the credit bureau department & a representative will call you back with a decision.
				<br /><br />
				Please wait by your phone and someone will be in contact with you
				shortly. Thank you so much for your time, Have a great day!
			</p>
		</form>
		<div id="message" *ngIf="errorMessageLog.length !== 0">
			<div style="padding: 5px">
				<div id="inner-message">
					<p>
						<ngb-alert type="danger" (closed)="clearErrors()">
							{{ errorMessage }}
						</ngb-alert>
					</p>
				</div>
			</div>
		</div>
		<h1 id="saveMessage"></h1>
		<!-- <button class="btn btn-primary" (click)="pushZoho()" id="saveLoanInterviewButton">Save</button> -->
		<button class="btn btn-primary" (click)="saveInterviewScript()" id="saveLoanInterviewButton">
			Save
		</button>
		<br />
		<div *ngIf="show_notes_stage === true">
			<hr />
			<form [formGroup]="loanInterviewNote" class="row" style="text-align: left">
				<h5>NOTE DETAILS- {{ applicationType }} Applicant</h5>
				<div class="col-sm-6">
					<label class="form-label">Note subject</label>
					<select class="form-select" formControlName="subject" (change)="matchSubject($event)">
						<option Value="" selected>-Select-</option>
						<option *ngFor="let item of subjects" [Value]="item.subject">
							{{ item.subject }}
						</option>
					</select>
					<small class="form-label" *ngIf="
							loanInterviewNote.controls['subject'].hasError('required') &&
							loanInterviewNote.controls['subject'].touched
						" class="form-error-msg">Subject is required<br /></small>
					<label class="form-label">Description</label>
					<input class="form-control" formControlName="description" [attr.disabled]="true" />
					<h1 id="successfulNote"></h1>
					<button class="btn btn-primary" id="saveLTNoteButton" (click)="saveLoanInterviewNotes()"
						[disabled]="loanInterviewNote.invalid">
						Save
					</button>
				</div>
				<div class="col-sm-6">
					<label class="form-label">Note</label>
					<textarea class="form-control" style="margin-top: 0px" rows="4" formControlName="note"></textarea>
					<small *ngIf="
							loanInterviewNote.controls['note'].hasError('required') &&
							loanInterviewNote.controls['note'].touched
						" class="form-error-msg">Note is required</small>
				</div>
			</form>
			<hr />
			<form [formGroup]="loanInterviewStage" class="row" style="text-align: left">
				<div class="row">
					<div class="col-sm-4">
						<label class="form-label">Stage</label>
						<select class="form-select" formControlName="Stage">
							<option *ngFor="let i of stageName" [value]="i.id">
								{{ i.display_value }}
							</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-1">
						<h1 id="successfulStage"></h1>
						<button class="btn btn-primary" (click)="saveStageLoanInterview()" id="saveLTStageButton">
							Save
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>