import { Component } from '@angular/core';

@Component({
  selector: 'app-ptp-date-report',
  templateUrl: './ptp-date-report.component.html',
  styleUrls: ['./ptp-date-report.component.scss']
})
export class PtpDateReportComponent {

}
