<ng-template #emptystring>
	<td></td>
</ng-template>
<div style="padding-left: 3%" id="testing">
	<select class="form-select" (change)="updateApplicationType($event)"
		style="width: 250px; margin-bottom: 5px; cursor: pointer">
		<option value="primary">Primary</option>
		<option value="co_applicant">Co-applicant</option>
	</select>
	<div *ngIf="TU_Report">
		<div class="containermiddle">
			<img src="../../../assets/images/transunion-full-logo.png" width="400" height="100" alt="" class="mb-05" />
		</div>
		<div class="containerright">
			<strong style="font-size: 20px">{{ application_type_label }} (CREDIT SCORE :
				{{
				TU_Report.REL4Report.TU_FFR_Report[0].ScoreProducts[0].ScoreProduct[0]
				.Score
				}})</strong><br />
			<div style="padding-left: 6%; font-size: 12px">
				<div style="padding-top: 1%">
					<strong>SUBJECT ID:</strong>
					{{ TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].FirstName }}
					{{ TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].LastName }}
				</div>
				<div style="padding-top: 1%">
					<strong>REFERENCE ID:</strong>
					{{ TU_Report.REL4Report.UserReference }}
				</div>
				<div style="padding-top: 1%">
					<strong>BRANCH:</strong> LM CREDIT - EXTENSION OF CREDIT
				</div>
				<div style="padding-top: 1%">
					<strong>RESULT ISSUED:</strong>
					{{
					TU_Report.REL4Report.Date[0]
					.slice(0, 4)
					.concat('-')
					.concat(TU_Report.REL4Report.Date[0].slice(4, 6))
					.concat('-')
					.concat(TU_Report.REL4Report.Date[0].slice(6, 8))
					}}
				</div>
			</div>
		</div>
		<br /><br /><br /><br /><br /><br /><br />
	</div>
	<!-- <div class="child">
                <div class="containerleft">
                    <img src="assets/images/transunion-full-logo.png" alt="" class="mb-05">
                </div>
                <div style="padding-left: 1%;padding-top: 1%;" class="containerright">
                    <strong style="font-size: 20px;">THIS ORDER (CREDIT SCORE :
                        {{TU_Report.REL4Report.TU_FFR_Report[0].ScoreProducts[0].ScoreProduct[0].Score}})</strong><br>
                    <div style="padding-left: 6%;">
                        <div style="padding-top: 1%;"><strong>SUBJECT ID:</strong>
                            {{TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].FirstName}}
                            {{TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].LastName}}</div>
                        <div style="padding-top: 1%;"><strong>REFERENCE ID:</strong>
                            {{TU_Report.REL4Report.UserReference}}</div>
                        <div style="padding-top: 1%;"><strong>BRANCH:</strong> LM CREDIT - EXTENSION OF CREDIT
                        </div>
                        <div style="padding-top: 1%;"><strong>RESULT ISSUED:</strong>
                            {{(TU_Report.REL4Report.Date[0]).slice(0,4).concat("-").concat((TU_Report.REL4Report.Date[0]).slice(4,6)).concat("-").concat((TU_Report.REL4Report.Date[0]).slice(6,8))}}
                        </div>

                    </div>

                </div>
            </div> -->
	<!-- <div *ngIf="TU_Report">
            <button class="buttonBlue" (click)="downloadAsPDF()">Save to Drive</button><br>
            <button class="buttonBlue" (click)="updateZohoApplication_Transunion()">Update on ZOHO</button><br>
        </div> -->
	<div *ngIf="showInput">
		<div>
			<div *ngIf="hideForm; else elseForm">
				<div style="display: inline-block; padding-right: 8px">
					<img src="../../../assets/images/plus.png" width="20" height="20" />
				</div>
				<div style="display: inline-block; margin-top: -15px">
					<a href="javascript:void(0)" (click)="showHideForm()" style="font-size: 15px; color: blue">
						<strong>SHOW FORM</strong></a><br />
				</div>
			</div>
			<ng-template #elseForm>
				<div style="display: inline-block; padding-right: 8px">
					<img src="../../../assets/images/minus.jpg" width="20" height="20" />
				</div>
				<div style="display: inline-block">
					<a href="javascript:void(0)" (click)="showHideForm()"
						style="font-size: 15px; color: blue"><strong>HIDE
							FORM</strong></a><br />
				</div>
			</ng-template>
		</div>
		<br />
		<div [hidden]="hideForm">
			<div>
				<form>
					<div style="display: inline-block">
						<label><strong>First Name</strong></label><br />
						<input placeholder="First Name" [(ngModel)]="model.first_name" name="First_Name" />
					</div>
					<div style="padding-left: 2%; display: inline-block">
						<label><strong>Middle Name</strong></label><br />
						<input placeholder="Middle Name" [(ngModel)]="model.middle_name" name="Middle_Name" />
					</div>
					<div style="padding-left: 2%; display: inline-block">
						<label><strong>Last Name</strong></label><br />
						<input placeholder="Last Name" [(ngModel)]="model.last_name" name="Last_Name" />
					</div>
					<br />
					<div style="padding-top: 1%; display: inline-block">
						<label><strong>SIN</strong></label><br />
						<input placeholder="SIN" [(ngModel)]="model.sin" name="sin" />
					</div>
					<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
						<label><strong>Phone Number</strong></label><br />
						<input placeholder="Phone" [(ngModel)]="model.telephone" name="Phone" />
					</div>
					<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
						<label><strong>Date of Birth</strong></label><br />
						<input placeholder="Date of Birth" [(ngModel)]="model.dob" name="Date_of_Birth" />
					</div>
					<br />
					<div style="padding-top: 1%; display: inline-block; width: 400px">
						<label><strong>Address</strong></label><br />
						<input style="width: 100%" placeholder="Address" [(ngModel)]="model.street" name="Address" />
					</div>
					<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
						<label><strong>Address 2</strong></label><br />
						<input placeholder="City" [(ngModel)]="model.address2" name="Address-2" />
					</div>
					<br />
					<div style="padding-top: 1%; display: inline-block">
						<label><strong>City</strong></label><br />
						<input placeholder="City" [(ngModel)]="model.city" name="City" />
					</div>
					<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
						<label><strong>Province</strong></label><br />
						<input placeholder="Province" [(ngModel)]="model.province" name="Province" />
					</div>
					<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
						<label><strong>Postal Code</strong></label><br />
						<input placeholder="Postal Code" [(ngModel)]="model.postal_code" name="Postal Code" />
					</div>
					<br />
					<div style="padding-top: 1%; display: inline-block">
						<label><strong>Transunion Status</strong></label><br />
						<input readonly placeholder="Transunion Status" [(ngModel)]="Transunion_Status"
							name="transunion_status" />
					</div>
					<div style="padding-top: 1%; display: inline-block">
						<label><strong>Transunion Token</strong></label><br />
						<input type="password" placeholder="Token" [(ngModel)]="model.token" name="token" />
					</div>
					<div style="padding-top: 1%; padding-left: 2%; display: inline-block">
						<label><strong>Migrate to ZOHO</strong></label><br />
						<input readonly placeholder="Migrate to ZOHO" [(ngModel)]="ZOHO_Upload_Status"
							name="zoho_upload_status" />
					</div>
					<br />
					<div style="display: inline-block; padding-top: 2%">
						<mat-label> Consent to Pull Credit </mat-label>
						<mat-checkbox name="consent-toggle" color="primary" [(ngModel)]="consentCheck">
						</mat-checkbox>
						<br />
						<mat-error *ngIf="consentChecked">
							YOU NEED CONSENT TO PULL THE CREDIT FOR THIS CLIENT
						</mat-error>
					</div>
				</form>
			</div>
			<br />
			<button (click)="onSubmit()" class="buttonSubmit" style="display: inline-block" *ngIf="!externalView">
				Submit
			</button>
		</div>
		<div *ngIf="TU_Report">
			<div style="text-align: right; padding-right: 2%">
				<a href="javascript:void(0)" (click)="quickDownload()" style="font-size: 15px; color: blue">Download
					Credit Report</a><br />
			</div>
			<div id="pdfTable" #pdfTable>
				<div style="font-size: 25px; background-color: rgb(231, 228, 228)">
					Order Results
				</div>
				<table>
					<tr>
						<th></th>
						<th><strong>Surname</strong></th>
						<th><strong>Given Name(s)</strong></th>
						<th><strong>Soc.Ins.No</strong></th>
						<th><strong>Birth</strong></th>
					</tr>
					<tr>
						<td><strong>Subject</strong></td>
						<td *ngIf="
								hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Names);
								else emptystring
							">
							{{
							TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].LastName
							}}
						</td>
						<td *ngIf="
								hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Names);
								else emptystring
							">
							{{
							TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].FirstName
							}}
						</td>
						<td *ngIf="
								hasProperties(
									TU_Report.REL4Report.TU_FFR_Report[0].PersonalInformation
								);
								else emptystring
							">
							{{
							TU_Report.REL4Report.TU_FFR_Report[0].PersonalInformation[0].SIN
							}}
						</td>
						<td *ngIf="
								hasProperties(
									TU_Report.REL4Report.TU_FFR_Report[0].PersonalInformation[0]
										.DOB
								);
								else emptystring
							">
							{{
							TU_Report.REL4Report.TU_FFR_Report[0].PersonalInformation[0].DOB[0]
							.slice(0, 4)
							.concat('-')
							.concat(
							TU_Report.REL4Report.TU_FFR_Report[0].PersonalInformation[0].DOB[0].slice(
							4,
							6
							)
							)
							.concat('-')
							.concat(
							TU_Report.REL4Report.TU_FFR_Report[0].PersonalInformation[0].DOB[0].slice(
							6,
							8
							)
							)
							}}
						</td>
					</tr>
				</table>
				<br />
				<table>
					<tr>
						<th><strong>On File</strong></th>
						<th><strong>Last Inq</strong></th>
						<th><strong>Current Residence</strong></th>
						<th><strong>Telephone</strong></th>
						<th><strong>Prev phone</strong></th>
					</tr>
					<tr>
						<td *ngIf="
								hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].OnFileDate);
								else emptystring
							">
							{{
							TU_Report.REL4Report.TU_FFR_Report[0].OnFileDate[0]
							.slice(0, 4)
							.concat('-')
							.concat(
							TU_Report.REL4Report.TU_FFR_Report[0].OnFileDate[0].slice(
							4,
							6
							)
							)
							.concat('-')
							.concat(
							TU_Report.REL4Report.TU_FFR_Report[0].OnFileDate[0].slice(
							6,
							8
							)
							)
							}}
						</td>
						<td *ngIf="hasProperties(TU_Report.REL4Report.Date); else emptystring">
							{{
							TU_Report.REL4Report.Date[0]
							.slice(0, 4)
							.concat('-')
							.concat(TU_Report.REL4Report.Date[0].slice(4, 6))
							.concat('-')
							.concat(TU_Report.REL4Report.Date[0].slice(6, 8))
							}}
						</td>
						<td></td>
						<td *ngIf="
								hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Telephones);
								else emptystring
							">
							{{
							TU_Report.REL4Report.TU_FFR_Report[0].Telephones[0].Telephone[0]
							.Number
							}}
						</td>
						<td></td>
					</tr>
				</table>
				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Names)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Names
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Name Type</strong></td>
							<td><strong>First Name</strong></td>
							<td><strong>Middle Name</strong></td>
							<td><strong>Last Name</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg['$']['NameType'] }}</td>
							<td>{{ msg.FirstName }}</td>
							<td>{{ msg.MiddleName }}</td>
							<td>{{ msg.LastName }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Addresses)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Residence(s)
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Type</strong></th>
							<th><strong>Street</strong></th>
							<th><strong>City</strong></th>
							<th><strong>Prov</strong></th>
							<th><strong>Postal</strong></th>
							<th><strong>Cnfrm</strong></th>
						</tr>
						<tr *ngFor="
								let resi of TU_Report.REL4Report.TU_FFR_Report[0].Addresses[0]
									.Address;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ resi['$']['AddrType'] }}</td>
							<td>{{ resi.Street }}</td>
							<td>{{ resi.City }}</td>
							<td>{{ resi.Prov }}</td>
							<td>{{ resi.Postal }}</td>
							<td *ngIf="hasProperties(resi.DateReported); else emptystring">
								{{
								resi.DateReported[0]
								.slice(0, 4)
								.concat('-')
								.concat(resi.DateReported[0].slice(4, 6))
								.concat('-')
								.concat(resi.DateReported[0].slice(6, 8))
								}}
							</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Employments)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Employment(s)
						</span>
					</div>
					<table *ngIf="
							hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Employments)
						">
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Date Hired</strong></td>
							<td><strong>Date Separated</strong></td>
							<td><strong>Date Verified</strong></td>
							<td><strong>Emp Name</strong></td>
							<td><strong>Income</strong></td>
							<td><strong>Occupation</strong></td>
							<td><strong>PayBasis</strong></td>
						</tr>
						<tr *ngFor="
								let emp of TU_Report.REL4Report.TU_FFR_Report[0].Employments[0]
									.Employment;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td *ngIf="hasProperties(emp.DateHired); else emptystring">
								{{
								emp.DateHired[0]
								.slice(0, 4)
								.concat('-')
								.concat(emp.DateHired[0].slice(4, 6))
								.concat('-')
								.concat(emp.DateHired[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(emp.DateSeparated); else emptystring">
								{{
								emp.DateSeparated[0]
								.slice(0, 4)
								.concat('-')
								.concat(emp.DateSeparated[0].slice(4, 6))
								.concat('-')
								.concat(emp.DateSeparated[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(emp.DateVerified); else emptystring">
								{{
								emp.DateVerified[0]
								.slice(0, 4)
								.concat('-')
								.concat(emp.DateVerified[0].slice(4, 6))
								.concat('-')
								.concat(emp.DateVerified[0].slice(6, 8))
								}}
							</td>
							<td>{{ emp.EmpName }}</td>
							<td>{{ emp.Income }}</td>
							<td>{{ emp.Occupation }}</td>
							<td>{{ emp.PayBasis }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Messages)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Messages
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Code</strong></td>
							<td><strong>Message</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0].Messages[0]
									.Message;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg.Code }}</td>
							<td>{{ msg.Text }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].TransAlerts)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Trans Alerts
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Code</strong></td>
							<td><strong>Message</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0].TransAlerts[0]
									.TransAlert;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg.Code }}</td>
							<td>{{ msg.Text }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(trades)" style="font-weight: 500; font-size: 15px">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Trades
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Reptd</strong></th>
							<th><strong>Open</strong></th>
							<th style="width: 10%"><strong>Last</strong></th>
							<th><strong>H.Credit</strong></th>
							<th><strong>Balance</strong></th>
							<th><strong>PastDue</strong></th>
							<th><strong>Terms</strong></th>
							<th><strong>Payment Pattern 30/60/90/#M MOP</strong></th>
							<th><strong>Description</strong></th>
							<th style="width: 10%"><strong>Joint Description</strong></th>
							<th><strong>MOP</strong></th>
							<th><strong>Referral</strong></th>
							<th><strong>Consolidate</strong></th>
						</tr>
						<tr *ngFor="let trd of trades; let index = index">
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ index + 1 }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.MemberCode }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.MemberName }}<br />
								{{ trd.Phone }}
							</td>
							<td *ngIf="hasProperties(trd.DateLastActivity); else emptystring" [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{
								trd.DateLastActivity[0]
								.slice(0, 4)
								.concat('-')
								.concat(trd.DateLastActivity[0].slice(4, 6))
								.concat('-')
								.concat(trd.DateLastActivity[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.HighCredit }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Balance }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.PastDue }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Payment }}/{{ trd.Frequency }}
							</td>
							<td *ngIf="hasProperties(trd.PaymentPattern); else emptystring" [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.PaymentPattern[0].substring(0, 24) }}<br />
								{{ trd.Plus30 }} {{ trd.Plus60 }} {{ trd.Plus90 }}
								{{ trd.MonthsReviewed }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Description1 }}<br />{{ trd.Description2 }}
							</td>
							<td *ngIf="hasProperties(trd.Joint); else emptystring" [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ joint_descriptions[trd.Joint] }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Type }}{{ trd.MOP[0].slice(1) }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								<mat-checkbox name="consent" color="primary" class="example-margin"
									(change)="updateTransunionDB($event, index)" [(ngModel)]="
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral']
									">
								</mat-checkbox>
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Referral'] == true,
									'highlight-grey':
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Balance'][0] === '0',
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								<mat-checkbox name="consent" color="primary" class="example-margin"
									(change)="updateTransunionDB($event, index)" [(ngModel)]="
										TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[
											index
										]['Cons']
									">
								</mat-checkbox>
							</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(mortgages)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Mortgages
						</span>
					</div>
					<table class="reducewidth">
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Reptd</strong></th>
							<th><strong>Open</strong></th>
							<th><strong>Last</strong></th>
							<th><strong>H.Credit</strong></th>
							<th><strong>Balance</strong></th>
							<th><strong>PastDue</strong></th>
							<th><strong>Terms</strong></th>
							<th><strong>Payment Pattern 30/60/90/#M MOP</strong></th>
							<th><strong>Description</strong></th>
							<th><strong>Joint Description</strong></th>
							<th><strong>MOP</strong></th>
						</tr>
						<tr *ngFor="let trd of mortgages; let index = index">
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ index + 1 }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.MemberCode }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.MemberName }}<br />
								{{ trd.Phone }}
							</td>
							<td *ngIf="hasProperties(trd.DateLastActivity); else emptystring" [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{
								trd.DateLastActivity[0]
								.slice(0, 4)
								.concat('-')
								.concat(trd.DateLastActivity[0].slice(4, 6))
								.concat('-')
								.concat(trd.DateLastActivity[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.HighCredit }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Balance }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.PastDue }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Payment }}/{{ trd.Frequency }}
							</td>
							<td *ngIf="hasProperties(trd.PaymentPattern); else emptystring" [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.PaymentPattern[0].substring(0, 24) }}<br />
								{{ trd.Plus30 }} {{ trd.Plus60 }} {{ trd.Plus90 }}
								{{ trd.MonthsReviewed }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Description1 }}<br />{{ trd.Description2 }}
							</td>
							<td *ngIf="hasProperties(trd.Joint); else emptystring" [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ joint_descriptions[trd.Joint] }}
							</td>
							<td [ngClass]="{
									'highlight-red': red_trades.includes(trd.MOP[0]),
									'highlight-orange': orange_trades.includes(trd.MOP[0]),
									'highlight-blue': yellow_trades.includes(trd.MOP[0])
								}">
								{{ trd.Type }}{{ trd.MOP[0].slice(1) }}
							</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Legals)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Legal Items
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Case Number</strong></th>
							<th><strong>Rvsd</strong></th>
							<th><strong>Reptd</strong></th>
							<th><strong>Date Satisfied</strong></th>
							<th><strong>Plaintiff's Name</strong></th>
							<th><strong>Type</strong></th>
							<th><strong>Amount</strong></th>
							<th><strong>Reference</strong></th>
							<th><strong>Status Code</strong></th>
							<th><strong>Balance</strong></th>
							<th><strong>Referral</strong></th>
							<th><strong>Consolidate</strong></th>
						</tr>
						<tr *ngFor="
								let leg of TU_Report.REL4Report.TU_FFR_Report[0].Legals[0]
									.Legal;
								let index = index
							">
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ index + 1 }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.CaseNumber }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}" *ngIf="hasProperties(leg.DateRevised); else emptystring">
								{{
								leg.DateRevised[0]
								.slice(0, 4)
								.concat('-')
								.concat(leg.DateRevised[0].slice(4, 6))
								.concat('-')
								.concat(leg.DateRevised[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}" *ngIf="hasProperties(leg.DateReported); else emptystring">
								{{
								leg.DateReported[0]
								.slice(0, 4)
								.concat('-')
								.concat(leg.DateReported[0].slice(4, 6))
								.concat('-')
								.concat(leg.DateReported[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}" *ngIf="hasProperties(leg.DateSatisfied); else emptystring">
								{{
								leg.DateSatisfied[0]
								.slice(0, 4)
								.concat('-')
								.concat(leg.DateSatisfied[0].slice(4, 6))
								.concat('-')
								.concat(leg.DateSatisfied[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.Plaintiff }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.Type }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.OriginalAmount }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.Reference }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.StatusCode }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								{{ leg.CurrentBalance }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								<mat-checkbox name="consent" color="primary" class="example-margin"
									(change)="updateTransunionDB($event, index)" [(ngModel)]="
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral']
									">
								</mat-checkbox>
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Referral'] == true
								}">
								<mat-checkbox name="consent" color="primary" class="example-margin"
									(change)="updateTransunionDB($event, index)" [(ngModel)]="
										TU_Report.REL4Report.TU_FFR_Report[0].Legals[0].Legal[
											index
										]['Cons']
									">
								</mat-checkbox>
							</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Collections)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Collections
						</span>
					</div>
					<table *ngIf="
							hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Collections)
						">
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Rvsd</strong></th>
							<th><strong>Reptd</strong></th>
							<th><strong>Creditor's Name</strong></th>
							<th><strong>Amount</strong></th>
							<th><strong>Balance</strong></th>
							<th><strong>Description</strong></th>
							<th><strong>Referral</strong></th>
							<th><strong>Consolidate</strong></th>
						</tr>
						<tr *ngFor="
								let col of TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
									.Collection;
								let index = index
							">
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								{{ index + 1 }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}" *ngIf="hasProperties(col.DateVerified); else emptystring">
								{{
								col.DateVerified[0]
								.slice(0, 4)
								.concat('-')
								.concat(col.DateVerified[0].slice(4, 6))
								.concat('-')
								.concat(col.DateVerified[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}" *ngIf="hasProperties(col.DateReported); else emptystring">
								{{
								col.DateReported[0]
								.slice(0, 4)
								.concat('-')
								.concat(col.DateReported[0].slice(4, 6))
								.concat('-')
								.concat(col.DateReported[0].slice(6, 8))
								}}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								{{ col.MemberName }}/{{ col.Creditor }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								{{ col.OriginalAmount }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								{{ col.CurrentBalance }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								{{ col.Description }}
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								<mat-checkbox name="consent" color="primary" class="example-margin"
									(change)="updateTransunionDB($event, index)" [(ngModel)]="
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral']
									">
								</mat-checkbox>
							</td>
							<td [ngClass]="{
									'highlight-neon':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons'] == true,
									'highlight-green':
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Referral'] == true
								}">
								<mat-checkbox name="consent" color="primary" class="example-margin"
									(change)="updateTransunionDB($event, index)" [(ngModel)]="
										TU_Report.REL4Report.TU_FFR_Report[0].Collections[0]
											.Collection[index]['Cons']
									">
								</mat-checkbox>
							</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Inquiries)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Inquiries
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Date</strong></th>
							<th><strong>Credit</strong></th>
							<th><strong>Granter</strong></th>
						</tr>
						<tr *ngFor="
								let inq of TU_Report.REL4Report.TU_FFR_Report[0].Inquiries[0]
									.Inquiry;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td *ngIf="hasProperties(inq.Date); else emptystring">
								{{
								inq.Date[0]
								.slice(0, 4)
								.concat('-')
								.concat(inq.Date[0].slice(4, 6))
								.concat('-')
								.concat(inq.Date[0].slice(6, 8))
								}}
							</td>
							<td>{{ inq.MemberCode }}</td>
							<td>{{ inq.MemberName }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Errors)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Errors
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Code</strong></td>
							<td><strong>Message</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0].Errors[0]
									.Error;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg.Code }}</td>
							<td>{{ msg.Description }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Bankruptcies)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Bankruptcies
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Assets</strong></th>
							<th><strong>Date Discharged</strong></th>
							<th><strong>Date Reported</strong></th>
							<th><strong>Date Revised</strong></th>
							<th><strong>Filling Number</strong></th>
							<th><strong>Liabilities</strong></th>
							<th><strong>Narrative</strong></th>
							<th><strong>Description</strong></th>
							<th><strong>Reference</strong></th>
							<th><strong>Trustee Company</strong></th>
							<th><strong>Trustee Name</strong></th>
						</tr>
						<tr *ngFor="
								let bank of TU_Report.REL4Report.TU_FFR_Report[0]
									.Bankruptcies[0].Bankruptcy;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ bank.Assets }}</td>
							<td *ngIf="hasProperties(bank.DateDischarged); else emptystring">
								{{
								bank.DateDischarged[0]
								.slice(0, 4)
								.concat('-')
								.concat(bank.DateDischarged[0].slice(4, 6))
								.concat('-')
								.concat(bank.DateDischarged[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(bank.DateReported); else emptystring">
								{{
								bank.DateReported[0]
								.slice(0, 4)
								.concat('-')
								.concat(bank.DateReported[0].slice(4, 6))
								.concat('-')
								.concat(bank.DateReported[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(bank.DateRevised); else emptystring">
								{{
								bank.DateRevised[0]
								.slice(0, 4)
								.concat('-')
								.concat(bank.DateRevised[0].slice(4, 6))
								.concat('-')
								.concat(bank.DateRevised[0].slice(6, 8))
								}}
							</td>
							<td>{{ bank.FilingNumber }}</td>
							<td>{{ bank.Liabilities }}</td>
							<td>{{ bank.Narrative }}</td>
							<td>{{ bank.Description }}</td>
							<td>{{ bank.Reference }}</td>
							<td>{{ bank.TrusteeCompany }}</td>
							<td>{{ bank.TrusteeName }}</td>
						</tr>
					</table>
				</div>

				<div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Bankings)">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Bankings
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>AccountDesignator</strong></th>
							<th><strong>AccountNumber</strong></th>
							<th><strong>Balance</strong></th>
							<th><strong>DateClosed</strong></th>
							<th><strong>DateOpened</strong></th>
							<th><strong>DateVerified</strong></th>
							<th><strong>IndustryCode</strong></th>
							<th><strong>Narrative</strong></th>
							<th><strong>StatusCode</strong></th>
							<th><strong>SubscriberCode</strong></th>
							<th><strong>SubscriberName</strong></th>
							<th><strong>TypeAccount</strong></th>
							<th><strong>Description</strong></th>
						</tr>
						<tr *ngFor="
								let bnk of TU_Report.REL4Report.TU_FFR_Report[0].Bankings[0]
									.Banking;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ bnk.AccountDesignator }}</td>
							<td>{{ bnk.AccountNumber }}</td>
							<td>{{ bnk.Balance }}</td>
							<td *ngIf="hasProperties(bnk.DateClosed); else emptystring">
								{{
								bnk.DateClosed[0]
								.slice(0, 4)
								.concat('-')
								.concat(bnk.DateClosed[0].slice(4, 6))
								.concat('-')
								.concat(bnk.DateClosed[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(bnk.DateOpened); else emptystring">
								{{
								bnk.DateOpened[0]
								.slice(0, 4)
								.concat('-')
								.concat(bnk.DateOpened[0].slice(4, 6))
								.concat('-')
								.concat(bnk.DateOpened[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(bnk.DateVerified); else emptystring">
								{{
								bnk.DateVerified[0]
								.slice(0, 4)
								.concat('-')
								.concat(bnk.DateVerified[0].slice(4, 6))
								.concat('-')
								.concat(bnk.DateVerified[0].slice(6, 8))
								}}
							</td>
							<td>{{ bnk.IndustryCode }}</td>
							<td>{{ bnk.Narrative }}</td>
							<td>{{ bnk.StatusCode }}</td>
							<td>{{ bnk.SubscriberCode }}</td>
							<td>{{ bnk.SubscriberName }}</td>
							<td>{{ bnk.TypeAccount }}</td>
							<td>{{ bnk.Description }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(
							TU_Report.REL4Report.TU_FFR_Report[0].ConsumerStatements
						)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Consumer Statements
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Type</strong></th>
							<th><strong>Text</strong></th>
						</tr>
						<tr *ngFor="
								let cs of TU_Report.REL4Report.TU_FFR_Report[0]
									.ConsumerStatements[0].ConsumerStatement;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ cs.Type }}</td>
							<td>{{ cs.Text }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].RegisteredItems)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Registered Items
						</span>
					</div>
					<table>
						<tr>
							<th><strong>No.</strong></th>
							<th><strong>Account</strong></th>
							<th><strong>CurrentBalance</strong></th>
							<th><strong>DateFiled</strong></th>
							<th><strong>DateMatures</strong></th>
							<th><strong>DateRevised</strong></th>
							<th><strong>MemberName</strong></th>
							<th><strong>Narrative1</strong></th>
							<th><strong>Narrative2</strong></th>
							<th><strong>Phone</strong></th>
							<th><strong>Security</strong></th>
						</tr>
						<tr *ngFor="
								let bnk of TU_Report.REL4Report.TU_FFR_Report[0]
									.RegisteredItems[0].RegisteredItem;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ bnk.Account }}</td>
							<td>{{ bnk.CurrentBalance }}</td>
							<td *ngIf="hasProperties(bnk.DateFiled); else emptystring">
								{{
								bnk.DateFiled[0]
								.slice(0, 4)
								.concat('-')
								.concat(bnk.DateFiled[0].slice(4, 6))
								.concat('-')
								.concat(bnk.DateFiled[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(bnk.DateMatures); else emptystring">
								{{
								bnk.DateMatures[0]
								.slice(0, 4)
								.concat('-')
								.concat(bnk.DateMatures[0].slice(4, 6))
								.concat('-')
								.concat(bnk.DateMatures[0].slice(6, 8))
								}}
							</td>
							<td *ngIf="hasProperties(bnk.DateRevised); else emptystring">
								{{
								bnk.DateRevised[0]
								.slice(0, 4)
								.concat('-')
								.concat(bnk.DateRevised[0].slice(4, 6))
								.concat('-')
								.concat(bnk.DateRevised[0].slice(6, 8))
								}}
							</td>
							<td>{{ bnk.MemberName }}</td>
							<td>{{ bnk.Narrative1 }}</td>
							<td>{{ bnk.Narrative2 }}</td>
							<td>{{ bnk.Phone }}</td>
							<td>{{ bnk.Security }}</td>
						</tr>
					</table>
				</div>
				<!-- <div *ngIf="hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].ScoreProducts)">
                        <div style="width: 100%; height: 20px; border-bottom: 1px solid black; padding-left: 5%">
                            <span style="color:blue;font-size: 20px; background-color: #ffffff; padding: 0 10px;">
                                Score Products
                            </span>
                        </div>
                        <table>
                            <tr>
                                <th><strong>No.</strong></th>
                                <th><strong>Factor1</strong></th>
                                <th><strong>Factor2</strong></th>
                                <th><strong>Factor3</strong></th>
                                <th><strong>Factor4</strong></th>
                                <th><strong>Product</strong></th>
                                <th><strong>Score</strong></th>
                                <th><strong>ScoreCard</strong></th>
                                <th><strong>ScoreDerogatoryAlert</strong></th>
                            </tr>
                            <tr
                                *ngFor="let bnk of TU_Report.REL4Report.TU_FFR_Report[0].ScoreProducts[0].ScoreProduct;let index=index">
                                <td>{{index+1}}</td>
                                <td>{{bnk.Factor1}}</td>
                                <td>{{bnk.Factor2}}</td>
                                <td>{{bnk.Factor3}}</td>
                                <td>{{bnk.Factor4}}</td>
                                <td>{{bnk.Product}}</td>
                                <td>{{bnk.Score}}</td>
                                <td>{{bnk.ScoreCard}}</td>
                                <td>{{bnk.ScoreDerogatoryAlert}}</td>
                            </tr>
                        </table>
                    </div> -->
				<div *ngIf="
						hasProperties(
							TU_Report.REL4Report.TU_FFR_Report[0].MiscellaneousStatements
						)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Miscellaneous Statements
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Statement</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0]
									.MiscellaneousStatements[0].MiscellaneousStatement;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg.Text }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(
							TU_Report.REL4Report.TU_FFR_Report[0].WatchListScreenings
						)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							WatchList Screenings
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Product</strong></td>
							<td><strong>ProductStatus</strong></td>
							<td><strong>SearchStatus</strong></td>
							<td><strong>Messages</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0]
									.WatchListScreenings[0].WatchListScreenings;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg.Product }}</td>
							<td>{{ msg.ProductStatus }}</td>
							<td>{{ msg.SearchStatus }}</td>
							<td>{{ msg.Messages }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(
							TU_Report.REL4Report.TU_FFR_Report[0].AccountNetCharacteristics
						)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Account Net Characteristics
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>ID</strong></td>
							<td><strong>Value</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0]
									.AccountNetCharacteristics[0].AccountNetCharacteristic;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg.ID }}</td>
							<td>{{ msg.Value }}</td>
						</tr>
					</table>
				</div>
				<div *ngIf="
						hasProperties(TU_Report.REL4Report.TU_FFR_Report[0].Telephones)
					">
					<div style="
							width: 100%;
							height: 20px;
							border-bottom: 1px solid black;
							padding-left: 5%;
						">
						<span style="
								color: rgb(3, 177, 207);
								font-size: 20px;
								background-color: #ffffff;
								padding: 0 10px;
							">
							Telephones
						</span>
					</div>
					<table>
						<tr>
							<td><strong>No.</strong></td>
							<td><strong>Phone Type</strong></td>
							<td><strong>Number</strong></td>
						</tr>
						<tr *ngFor="
								let msg of TU_Report.REL4Report.TU_FFR_Report[0].Telephones[0]
									.Telephone;
								let index = index
							">
							<td>{{ index + 1 }}</td>
							<td>{{ msg['$']['PhoneType'] }}</td>
							<td>{{ msg.Number }}</td>
						</tr>
					</table>
				</div>
				<hr />
				<strong>This completes the file for
					{{ TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].FirstName }}
					{{
					TU_Report.REL4Report.TU_FFR_Report[0].Names[0].Name[0].LastName
					}}</strong>
			</div>
		</div>
	</div>
</div>