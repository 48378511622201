<app-custom-betty-report-filters [showReportDropDown]="true" (reportType)="getReportType($event)"
    (customLoanGroups)="getCustomLoanGroups($event)" [showLoanStatus]="false" [showBreakdowns]="false"
    [showCurrentDate]="false" [showDateRange]="true" (start_date)="startDate($event)" (end_date)="endDate($event)"
    [btnColor]="'primary'" [btnShowText]="btnShowText" [btnText]="'Submit'" [btnSpinner]="btnSpinner"
    (onBtnClick)="downloadACHValidation()"></app-custom-betty-report-filters>

<div class="row" *ngIf="ACHValidation">
    <div class="col">
        <span style="display:flex;">
            <span>
                <h4 style="margin-top: 17px;">TOTAL: {{ACHValidation?.[0].length}}</h4>
            </span>
            <span>
                <app-custom-export-excel [sheetArray]="sheetArray" [fileName]="fileName"></app-custom-export-excel>
            </span>
        </span>
        <div class="tableFixHead">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Loan Group</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let summ of ACHValidation?.[1]">
                        <td>{{summ?.loan_group}}</td>
                        <td>{{summ?.total}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>