import { DatePipe } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';

@Component({
  selector: 'app-lpp-claims',
  templateUrl: './lpp-claims.component.html',
  styleUrls: ['./lpp-claims.component.scss']
})
export class LppClaimsComponent implements AfterViewInit {
  defaultColDef: any;
  public columnDefs = [];
  public rowData;
  public gridApi;
  public gridColumnApi;
  public gridOptions;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    this.columnDefs = [
      //{ headerName: 'Action', cellRenderer: 'buttonRenderer', cellRendererParams: { onClick: this.onBtnClick.bind(this), label: 'Edit' }, width: 100 },
      { headerName: 'Loan Number', field: 'Loan_Number', sortable: true, filter: "agTextColumnFilter", width: 200 },
      { headerName: 'Name', field: 'Name', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Phone No', field: 'Phone_No', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'DOB', field: 'DOB', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'Status', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'NSF Count', field: 'nsf_count', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Status Code', field: 'status_code', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Amount Arranged', field: 'Amount Arranged', sortable: true, filter: 'agNumberColumnFilter' },
      { headerName: 'Outstanding Balance', field: 'Outstanding Balance', sortable: true, filter: 'agNumberColumnFilter' },
    ]
    this.defaultColDef = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'lpp_claim_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'lpp_claim_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  };

  collectionList: any;
  collectionCounts: any
  bucketList: any;
  portfolioList: any;
  statusID = 3;
  bucket_id = 13;
  paper_name = 'all';
  bucketName = "All";
  statusName = "All";
  regionName = "All Regions";
  ptp_status = '';

  allSelected = 'accent';
  arrangedSelected = 'basic';
  notArrangedSelected = 'basic';

  ngAfterViewInit(): void {
    this.apiService.getUpdatedCollectionCounts(null, 14, false).subscribe(async (res) => {
      this.collectionCounts = res[0];
      const bucket = ['1-15', '16-30', '31-60', '61-90', '91-120', '120 +'];
      const portfolio = ['Debenture', 'SPV', 'MFT'];
      this.bucketList = Object.entries(this.collectionCounts).filter(([key]) => bucket.includes(key)).sort(); // Sort ['1-15', '120 +', '31-60', '61-90', '91-120', '16-30']
      arraymove(this.bucketList, 1, 5);   // Arrange the default sort ['1-15', '16-30', '31-60', '61-90', '91-120', '120 +'] 

      this.portfolioList = Object.entries(this.collectionCounts).filter(([key]) => portfolio.includes(key)).sort();
      this.getLPPClaimData(3, 'All');
    })
  }

  getLPPClaimData(status, ptp_status) {
    let acctrefs_with_status;
    this.statusID = status;
    this.statusName = ptp_status;
    this.apiService.getFilteredCollections(this.statusID, this.regionName, 0, 10000, 14, this.paper_name, acctrefs_with_status).subscribe(async (res) => {
      this.collectionList = res;
      if (ptp_status != "All") {
        this.collectionList = this.collectionList.filter((e) => {
          console.log(e.Status)
          return e.Status == ptp_status
        })
      }
      if (ptp_status === 'All') {
        this.allSelected = 'accent';
        this.arrangedSelected = 'basic';
        this.notArrangedSelected = 'basic';
      } else if (ptp_status === 'Arranged') {
        this.allSelected = 'basic';
        this.arrangedSelected = 'accent';
        this.notArrangedSelected = 'basic';
      } else if (ptp_status === 'Not Arranged') {
        this.allSelected = 'basic';
        this.arrangedSelected = 'basic';
        this.notArrangedSelected = 'accent';
      }
      this.collectionList = await this.getNSFCount(this.collectionList)
      this.gridApi.setRowData(this.collectionList);
      this.rowData = this.collectionList;
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  async getNSFCount(Collections) {
    return new Promise((resolve, reject) => {
      this.apiService.getNSFCount(Collections).subscribe((res) => {
        Collections = res;
        this.gridApi.setRowData(Collections);
        this.rowData = Collections;
        resolve(Collections)
      })
    })
  }
}

const arraymove = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

