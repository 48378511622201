import { Component, OnDestroy } from '@angular/core';
import { ApiServiceService } from '@lmc-app/api-service.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-insurance-remit',
  templateUrl: './insurance-remit.component.html',
  styleUrls: ['./insurance-remit.component.scss']
})
export class InsuranceRemitComponent implements OnDestroy {
  insurace_remit_data: any;
  InsuranceRemitDataSubscription: Subscription;

  selectedStatus: any;
  start_date: any;
  end_date: any;
  selectedLoans: any;
  btnSpinner = false;
  btnShowText = true;

  //_Hist
  getRowStyle_Hist: any;
  defaultColDef_Hist: any;
  public columnDefs_Hist = [];
  public rowData_Hist;
  public gridApi_Hist;
  public gridColumnApi_Hist;
  public gridOptions_Hist;
  public defaultExcelExportParams;
  public defaultCsvExportParams;

  constructor(private apiService: ApiServiceService, private datepipe: DatePipe) {
    //Payment History
    this.columnDefs_Hist = [
      { headerName: 'Description', field: 'Description', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'DateRec', field: 'DateRec', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Group policy', field: 'Group policy', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Loan number', field: 'Loan number', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor First Name', field: 'Debtor First Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Last Name', field: 'Debtor Last Name', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Address', field: 'Debtor Address', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor City', field: 'Debtor City', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Province', field: 'Debtor Province', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor Postal Code', field: 'Debtor Postal Code', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Debtor Phone Number', field: 'Debtor Phone Number', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Debtor Dob', field: 'Debtor Dob', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Co-Debtor First Name', field: 'Co-Debtor First Name', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor Last Name', field: 'Co-Debtor Last Name', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor Address', field: 'Co-Debtor Address', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor City', field: 'Co-Debtor City', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor Province', field: 'Co-Debtor Province', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Co-Debtor Postal Code', field: 'Co-Debtor Postal Code', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor Phone Number', field: 'Co-Debtor Phone Number', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Co-Debtor DOB', field: 'Co-Debtor DOB', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Closing Date', field: 'Closing Date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Loan termination date', field: 'Loan termination date', sortable: true, filter: "agDateColumnFilter" },
      { headerName: 'Principle Loan Amount', field: 'Principle Loan Amount', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'ToInterest', field: 'ToInterest', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'ToPrincipal', field: 'ToPrincipal', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Loan Amount', field: 'Loan Amount', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Payment Frequency', field: 'Payment Frequency', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Total LPP Premium per Payment', field: 'Total LPP Premium per Payment', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Retail sales tax', field: 'Retail sales tax', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Net LPP Premium', field: 'Net LPP Premium', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Lender Expense Allowance', field: 'Lender Expense Allowance', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Balance Owing', field: 'Balance Owing', sortable: true, filter: "agNumberColumnFilter" },
      { headerName: 'Is Enhanced?', field: 'is_enhanced', sortable: true, filter: "agTextColumnFilter" },
      { headerName: 'Loan Group', field: 'Loan Group', sortable: true, filter: "agTextColumnFilter" },
    ]
    this.defaultColDef_Hist = {
      resizable: true
    }
    this.defaultExcelExportParams = { fileName: 'insurance_remit_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.xlsx' };
    this.defaultCsvExportParams = { fileName: 'insurance_remit_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }

  getReportType(e) {
    this.selectedLoans = e?.['data'];
  }

  getCustomLoanGroups(e) {
    this.selectedLoans = e;
  }

  selectedLoanStatus(e) {
    this.selectedStatus = e;
  }

  startDate(e) {
    this.start_date = e;
  }

  endDate(e) {
    this.end_date = e;
  }

  downloadDailyReconReport() {
    if (this.start_date > this.end_date)
      alert("Invalid Dates")
    else if (this.selectedLoans.length == 0)
      alert("At least one Loan Group should be selected")
    else {
      this.btnSpinner = true;
      this.btnShowText = false;
      this.insurace_remit_data = null;
      this.rowData_Hist = []
      this.InsuranceRemitDataSubscription = this.apiService.getInsuranceRemitReport(this.start_date, this.end_date, this.selectedStatus, this.selectedLoans).subscribe((res) => {
        this.insurace_remit_data = res;
        console.log(this.insurace_remit_data)
        this.gridApi_Hist.setRowData(this.insurace_remit_data);
        this.rowData_Hist = this.insurace_remit_data;
        this.btnSpinner = false;
        this.btnShowText = true;
      })
    }
  }

  onGridReady_Hist(params) {
    this.gridApi_Hist = params.api;
    this.gridColumnApi_Hist = params.columnApi;
    this.gridApi_Hist.setRowData(this.insurace_remit_data);
    this.rowData_Hist = this.insurace_remit_data;
  }

  ngOnDestroy() {
    this.InsuranceRemitDataSubscription?.unsubscribe();
  }

}

function totalFundedAmount(arr: any) {
  return arr.map(item => item?.['Funded Amount'])?.reduce((prev, next) => parseFloat(prev) + parseFloat(next))?.toFixed(2);
}
