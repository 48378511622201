<form [formGroup]="exceptionNote">
	<h5>ADD NOTE</h5>
	<div class="row">
		<div class="col-6">
			<label class="form-label">Subject</label>
			<select class="form-select" formControlName="subject">
				<option Value="" selected>-Select-</option>
				<option *ngFor="let item of subjects" [Value]="item.subject">
					{{ item.subject }}
				</option>
			</select>
			<small class="form-label" *ngIf="
					exceptionNote.controls['subject'].hasError('required') &&
					exceptionNote.controls['subject'].touched
				" class="form-error-msg">Subject is required<br /></small>
			<label class="form-label">Note</label>
			<textarea class="form-control" style="margin-top: 0px" rows="4" formControlName="note"></textarea>
			<small *ngIf="
					exceptionNote.controls['note'].hasError('required') &&
					exceptionNote.controls['note'].touched
				" class="form-error-msg">Note is required</small>
			<h1 id="successfulExceptionNote"></h1>
			<button class="btn btn-primary" id="saveExceptionNoteButton" (click)="saveExceptionNote()"
				[disabled]="exceptionNote.invalid">
				Save
			</button>
		</div>
		<div class="col-6">
			<form [formGroup]="stageForm">
				<h5>STAGE</h5>
				<div class="row">
					<div class="col-sm-4">
						<select class="form-select" aria-label="Default select example" formControlName="Stage">
							<option *ngFor="let i of stageLookUp" [value]="i.id">
								{{ i.display_value }}
							</option>
						</select>
						<h1 id="successfulExceptionStage"></h1>
						<button class="btn btn-primary" (click)="updateExceptionStage()" id="exceptionUpdateStage">
							Save
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</form>

<hr />

<form [formGroup]="exceptionForm">
	<h5>MANAGER APPROVAL</h5>
	<div class="row row-cols-4">
		<div class="col-sm-2">
			<label>Loan Amount</label>
		</div>
		<div class="col-md-4">
			<input type="number" class="form-control" formControlName="Loan_Amount"
				(change)="autoCalculateFunded()" /><br />
		</div>
		<div class="col-sm-2">
			<label>Loyalty Payment Plan </label>
		</div>
		<div class="col-md-4">
			<select class="form-select" aria-label="Default select example" formControlName="Loyalty_Payment_Plan">
				<option value="none" selected disabled hidden>-None-</option>
				<option value="Yes">Yes</option>
				<option value="No">No</option>
			</select>
		</div>
		<div class="col-sm-2">
			<label>Household Income </label>
		</div>
		<div class="col-md-4">
			<select class="form-select" aria-label="Default select example" formControlName="Household_Income">
				<option value="none" selected disabled hidden>-None-</option>
				<option value="Yes">Yes</option>
				<option value="No">No</option>
			</select><br />
		</div>
		<div class="col-sm-2">
			<label>Override </label>
		</div>
		<div class="col-md-4">
			<select class="form-select" aria-label="Default select example" formControlName="override">
				<option *ngFor="let item of override_look_up" [value]="item.display_value">
					{{ item.display_value }}
				</option>
			</select><br />
		</div>
		<div class="col-sm-2">
			<label>Exception Increase </label>
		</div>
		<div class="col-md-4">
			<input type="number" class="form-control" formControlName="Exception_Increase"
				(change)="autoCalculateFunded()" /><br />
		</div>
		<div class="col-sm-2">
			<label>Override Type </label>
		</div>
		<div class="col-md-4">
			<select class="form-select" aria-label="Default select example" formControlName="override_type">
				<option *ngFor="let item of override_type_look_up" [value]="item.display_value">
					{{ item.display_value }}
				</option>
			</select><br />
		</div>
		<div class="col-sm-2">
			<label>Exception Funded Amount </label>
		</div>
		<div class="col-md-4">
			<input type="number" class="form-control" formControlName="Exception_Funded_Amount" readonly /><br />
		</div>
	</div>

	<h1 id="successfulException"></h1>
	<button class="btn btn-primary" id="exceptionButton" (click)="saveException()">
		Save</button><br />
</form>