<div class="container-fluid">
  <div style="display: flex">
    <div class="float-child-left-main" style="overflow-y: auto; height: 100vh">
      <div *ngIf="displayInfo">
        <div *ngFor="
            let section of displayInfo | keyvalue : keyPreservedOrder;
            let indexOfelement = index
          ">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-{{ indexOfelement }}">
            <ngb-panel title="{{ section.key }}">
              <h5>{{ section.key }}</h5>
              <ng-template ngbPanelContent>
                <div *ngIf="section.key==='DEBTS' else elseAccord">
                  <h5>Referral</h5>
                  <table class="tb">
                    <tr>
                      <th>Agency</th>
                      <th>Creditor</th>
                      <th>Acct#</th>
                      <th>Balance</th>
                    </tr>
                    <tr>
                      <td>{{section.value[18].value}}</td>
                      <td>{{section.value[19].value}}</td>
                      <td>{{section.value[20].value}}</td>
                      <td>
                        {{section.value[21].type==='currency'?(section.value[21].value|currency):section.value[21].value}}
                      </td>
                    </tr>
                  </table>
                  <br>
                  <h5>Additional Debts</h5>
                  <table class="tb">
                    <tr>
                      <th>Creditor</th>
                      <th>Acct#</th>
                      <th>Balance</th>
                    </tr>
                    <tr *ngIf="section.value[0].value">
                      <td>{{section.value[0].value}}</td>
                      <td>{{section.value[1].value}}</td>
                      <td>
                        {{section.value[2].type==='currency'?(section.value[2].value|currency):section.value[2].value}}
                      </td>
                    </tr>
                    <tr *ngIf="section.value[3].value">
                      <td>{{section.value[3].value}}</td>
                      <td>{{section.value[4].value}}</td>
                      <td>
                        {{section.value[5].type==='currency'?(section.value[5].value|currency):section.value[5].value}}
                      </td>
                    </tr>
                    <tr *ngIf="section.value[6].value">
                      <td>{{section.value[6].value}}</td>
                      <td>{{section.value[7].value}}</td>
                      <td>
                        {{section.value[8].type==='currency'?(section.value[8].value|currency):section.value[8].value}}
                      </td>
                    </tr>
                    <tr *ngIf="section.value[9].value">
                      <td>{{section.value[9].value}}</td>
                      <td>{{section.value[10].value}}</td>
                      <td>
                        {{section.value[11].type==='currency'?(section.value[11].value|currency):section.value[11].value}}
                      </td>
                    </tr>
                    <tr *ngIf="section.value[12].value">
                      <td>{{section.value[12].value}}</td>
                      <td>{{section.value[13].value}}</td>
                      <td>
                        {{section.value[14].type==='currency'?(section.value[14].value|currency):section.value[14].value}}
                      </td>
                    </tr>
                    <tr *ngIf="section.value[15].value">
                      <td>{{section.value[15].value}}</td>
                      <td>{{section.value[16].value}}</td>
                      <td>
                        {{section.value[17].type==='currency'?(section.value[17].value|currency):section.value[17].value}}
                      </td>
                    </tr>
                  </table>
                </div>
                <ng-template #elseAccord>
                  <div *ngFor="let tokens of section.value">
                    <div *ngIf="tokens.isVisible">
                      <div class="row">
                        <div class="col-6"><span class="align-top"
                            style=" display:inline-block;  padding: 0px 0px 8px 0px;">
                            <strong>{{tokens.label}}</strong></span></div>
                        <div class="col-6"><span style="text-align:left;" class="align-top">
                            {{tokens.type=== 'currency' ? (tokens.value | currency) : (tokens.value)}}
                          </span></div>


                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
    <div class=" float-child-right-main">
      <div style="display: flex; align-items: baseline;">
        <a style="color:white !important" class="btn btn-primary top-buttons download-button"
          [ngClass]="!editMode ? 'disabled' : ''" target="_blank" [href]="getSanitizedUrl(pdfSrc)"
          (click)="onClickDownload($event)" [download]="pdfName">
          Download
        </a>
        <button class="btn btn-success top-buttons" (click)="sendAttempt()" [ngClass]="!editMode ? 'disabled' : ''">
          Send
        </button>
        <button class="btn btn-danger top-buttons" (click)="deleteDocumentAttempt()"
          [ngClass]="!editMode ? 'disabled' : ''">
          Discard
        </button>
        <h4 style="margin-left: 10%;">
          {{ agreementName }}
        </h4>
      </div>
      <div class="pdf-viewer-container">
        <div *ngIf="spinner">
          <h2>{{loadingMessage}}</h2>
          <span class="spinner-border" id="loading"></span>
        </div>
        <span class="spinner-border" id="loading" *ngIf="showLoadingSpinner"></span>
        <div *ngIf="pdfSrc">
          <app-pdf-viewer [pdfSrc]="pdfBlob"></app-pdf-viewer>
        </div>
        <!-- <pdf-viewer [src]="pdfSrc" [show-all]="true" [render-text]="true" [original-size]="false" [autoresize]="true"
          [fit-to-page]="false" [show-borders]="true" class="pdf-viewer"></pdf-viewer> -->
      </div>
    </div>
  </div>
</div>