import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ApplicationEntry } from '../models/application';
import { LookUps } from '../models/lookUps';
import { CustomFunctionService } from '../custom-function.service';
import { Controls } from '../models/controls';
import { RegexEntry } from '../models/regexPatterns';
import { Functions } from '../models/commonFunctions';

@Component({
	selector: 'app-application',
	templateUrl: './application.component.html',
	styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
	constructor(
		private apiService: ApiServiceService,
		private router: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private customFunction: CustomFunctionService
	) {}

	getparamid: any; // For Edit ngOnInit() to get the ID

	applicationEntry = new ApplicationEntry(this.fb);

	lookUps = new LookUps(this.customFunction);

	controls = new Controls();
	regexEntry = new RegexEntry();
	functions = new Functions();

	totalObligation: any;

	loader = false; // For loading

	// Flags to display the form
	coAppRequiredDisplay = false;
	previousEmpDisplay = false;
	homeOwnerDisplay = false;
	additionalDebtDisplay = false;
	emergencyContactsDisplay = false;

	async ngOnInit(): Promise<void> {
		this.getparamid = this.router.snapshot?.paramMap.get('id'); // This will retrieve the ID that is linked on the routerlink from the html
		this.getApplication();
	}

	getApplication() {
		this.loader = true;
		this.getLookUps();
		this.apiService.getSingleAppZOHO(this.getparamid).subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			// console.log(arr)
			for (const field in this.applicationEntry.applicationForm.controls) {
				if (field.match(arr.data[0]) || field.search(arr.data[0])) {
					this.customFunction.setControlValue(
						this.applicationEntry.applicationForm,
						field,
						arr.data[0][field]
					);
				}
			}
			if (!arr.data[0]['LPP_Sold_by']) {
				this.applicationEntry.applicationForm
					.get('Owner')
					.patchValue(arr.data[0]['Owner']['id']);
			}
			if (!arr.data[0]['Owner']) {
				this.applicationEntry.applicationForm
					.get('LPP_Sold_by')
					.patchValue(arr.data[0]['LPP_Sold_by']['id']);
			}
			// this.applicationEntry.applicationForm.patchValue({
			//   Owner: arr.data[0]['Owner']['id'],
			//   LPP_Sold_by: arr.data[0]['LPP_Sold_by']['id']
			// })

			this.applicationEntry.getAdditionalSubForm(
				arr.data[0]['Additional_Debt']
			);
			this.applicationEntry.getEmergencySubForm(
				arr.data[0]['EMERGANCY_CONTACTS']
			);

			this.totalObligation = arr.data[0]['Amount'];

			this.displayEvent(
				this.controls.getControlName(
					this.applicationEntry.applicationForm,
					'Previous_Employment'
				).value,
				'previousEmp'
			);
			this.displayEvent(
				this.controls.getControlName(
					this.applicationEntry.applicationForm,
					'Are_you_currently_a_Home_Owner'
				).value,
				'homeOwner'
			);
			this.displayEvent(
				this.controls.getControlName(
					this.applicationEntry.applicationForm,
					'Additional_Debt2'
				).value,
				'additionalDebt'
			);
			this.displayEvent(
				this.controls.getControlName(
					this.applicationEntry.applicationForm,
					'Emer_Cont'
				).value,
				'emergencyContacts'
			);
			this.displayEvent(
				this.controls.getControlName(
					this.applicationEntry.applicationForm,
					'Co_Appl_Required'
				).value,
				'coAppRequired'
			);

			this.loader = false;
		});
	}

	// setControlValue(control: string, value: string) {
	//   this.applicationEntry.applicationForm.patchValue({
	//     [control]: value
	//   });
	// }

	getLookUps() {
		this.apiService.getLookupsdeals().subscribe((res) => {
			if (res['message']['statusCode'] == '200') {
				let arr = JSON.parse(res.message.body);
				this.lookUps.Employment_Status = this.lookUps.getLookUp(
					arr,
					'Employment_Status'
				);
				this.lookUps.Residency_Status = this.lookUps.getLookUp(
					arr,
					'Residency_Status'
				);
				this.lookUps.Province1 = this.lookUps.getLookUp(arr, 'Province1');
				this.lookUps.Payment_Method = this.lookUps.getLookUp(
					arr,
					'Payment_Method'
				);
				this.lookUps.Bankruptcy_CC_CP = this.lookUps.getLookUp(
					arr,
					'Bankruptcy_CC_CP'
				);
				this.lookUps.Sales_Agent = this.lookUps.getLookUp(arr, 'Sales_Agent');
				this.lookUps.Creditt = this.lookUps.getLookUp(arr, 'Creditt');
				this.lookUps.Referral_type = this.lookUps.getLookUp(
					arr,
					'Referral_type'
				);
				this.lookUps.Broker = this.lookUps.getLookUp(arr, 'Broker');
				this.lookUps.Agency_Location = this.lookUps.getLookUp(
					arr,
					'Agency_Location'
				);
				this.lookUps.Creditor_Portfolio = this.lookUps.getLookUp(
					arr,
					'Creditor_Portfolio'
				);
				this.lookUps.Loan_Type = this.lookUps.getLookUp(arr, 'Loan_Type');
				this.lookUps.Lead_Source = this.lookUps.getLookUp(arr, 'Lead_Source');
				this.lookUps.Marital_Status = this.lookUps.getLookUp(
					arr,
					'Marital_Status'
				);
				this.lookUps.Number_of_Dependents = this.lookUps.getLookUp(
					arr,
					'Number_of_Dependents'
				);
				this.lookUps.Best_contact_time = this.lookUps.getLookUp(
					arr,
					'Best_contact_time'
				);
				this.lookUps.No_SIN_Reason = this.lookUps.getLookUp(
					arr,
					'No_SIN_Reason'
				);
				this.lookUps.Industry = this.lookUps.getLookUp(arr, 'Industry');
				this.lookUps.Employment_Status1 = this.lookUps.getLookUp(
					arr,
					'Employment_Status1'
				);
				this.lookUps.Occupation = this.lookUps.getLookUp(arr, 'Occupation');
				this.lookUps.Pay_Frequency2 = this.lookUps.getLookUp(
					arr,
					'Pay_Frequency2'
				);
				this.lookUps.Pay_Frequency_Household_Income = this.lookUps.getLookUp(
					arr,
					'Pay_Frequency_Household_Income'
				);
				this.lookUps.Previous_Employment_Type = this.lookUps.getLookUp(
					arr,
					'Previous_Employment_Type'
				);
				this.lookUps.Previous_Payment_Method = this.lookUps.getLookUp(
					arr,
					'Previous_Payment_Method'
				);
				this.lookUps.Co_Applicant_Length_of_Residence = this.lookUps.getLookUp(
					arr,
					'Co_Applicant_Length_of_Residence'
				);
				this.lookUps.Co_Applicant_Status = this.lookUps.getLookUp(
					arr,
					'Co_Applicant_Status'
				);
				this.lookUps.Co_Applicant_Industry = this.lookUps.getLookUp(
					arr,
					'Co_Applicant_Industry'
				);
				this.lookUps.Co_Applicant_Occupation = this.lookUps.getLookUp(
					arr,
					'Co_Applicant_Occupation'
				);
				this.lookUps.Stage = this.lookUps.getLookUp(arr, 'Stage');
				this.lookUps.LPP_Included = this.lookUps.getLookUp(arr, 'LPP_Included');
				this.lookUps.LPP_Type = this.lookUps.getLookUp(arr, 'LPP_Type');
			}
		});

		this.apiService.getUsers().subscribe((res) => {
			let arr = JSON.parse(res.message.body);
			this.lookUps.Users = arr.users;
			this.customFunction.sortColumn(this.lookUps.Users, 'full_name', 1, -1);
		});
	}

	updateZohoDeals() {
		this.controls.displayMessage(
			'updateZoho',
			false,
			'saveMessage',
			'Save in progress...',
			'orange',
			'block'
		);
		this.apiService
			.updateZohoApplicationInterface(
				this.applicationEntry.applicationForm.value,
				this.getparamid
			)
			.subscribe((res) => {
				if (res['message']['statusCode'] == '200') {
					this.controls.displayMessage(
						'updateZoho',
						false,
						'saveMessage',
						'Save Successful',
						'green',
						'block'
					);
				} else {
					console.log(res);
					this.controls.displayMessage(
						'updateZoho',
						false,
						'saveMessage',
						'Save unsuccessful',
						'red',
						'block'
					);
				}
			});
	}

	resetPreviousEmployment() {
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Employment_Type'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Position'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Employer'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Length_of_Employment'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Supervisor_Name'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Supervisor_Phone_number'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Income_net_paycheque_income'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Pay_Frequency'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Previous_Payment_Method'
		);
	}

	resetHomeOwner() {
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'MTG_Balance'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Estimated_Value'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Loan_Det'
		);
		this.controls.resetControl(
			this.applicationEntry.applicationForm,
			'Mortgage_Details'
		);
	}

	resetAdditionalDebt() {
		this.controls.resetSubForm(
			this.applicationEntry.applicationForm,
			'Additional_Debt'
		);
		this.controls.resetControl(this.applicationEntry.applicationForm, 'Total');
	}

	resetCoApp() {
		this.applicationEntry.fields.forEach((element) => {
			this.controls.resetControl(
				this.applicationEntry.applicationForm,
				element
			);
		});
	}

	displayEvent(value: any, id: any) {
		switch (id) {
			case 'previousEmp':
				if (value === true) {
					this.previousEmpDisplay = true;
				} else {
					this.previousEmpDisplay = false;
					try {
						this.resetPreviousEmployment();
					} catch (err) {
						console.log('No Previous Employee!');
					}
				}
				break;
			case 'homeOwner':
				if (value === 'Yes') {
					this.homeOwnerDisplay = true;
				} else {
					this.homeOwnerDisplay = false;
					try {
						this.resetHomeOwner();
					} catch (err) {
						console.log('Not a homeowner!');
					}
				}
				break;
			case 'additionalDebt':
				if (value === 'Yes') {
					this.additionalDebtDisplay = true;
				} else {
					this.additionalDebtDisplay = false;
					try {
						this.resetAdditionalDebt();
					} catch (err) {
						console.log('No additional debts!');
					}
				}
				break;
			case 'coAppRequired':
				if (value === 'Yes') {
					this.coAppRequiredDisplay = true;
				} else {
					this.coAppRequiredDisplay = false;
					try {
						this.resetCoApp();
					} catch (err) {
						console.log('No Co-applicant!');
					}
				}
				break;
			case 'emergencyContacts':
				if (value === 'Yes') {
					this.emergencyContactsDisplay = true;
				} else {
					this.emergencyContactsDisplay = false;
					try {
						this.controls.resetSubForm(
							this.applicationEntry.applicationForm,
							'EMERGANCY_CONTACTS'
						);
					} catch (err) {
						console.log('No Emergency Contacts!');
					}
				}
				break;
			default:
		}
	}

	calculateTotalObligation() {
		this.applicationEntry.applicationForm
			.get('Total_Ob')
			.patchValue(
				parseFloat(this.totalObligation) +
					parseFloat(
						this.applicationEntry.applicationForm.get('Referral_Amount').value
					)
			);
	}

	calculateLoanYears() {
		this.applicationEntry.applicationForm
			.get('Term_of_Loan_Years')
			.patchValue(
				this.applicationEntry.applicationForm.get('Term_of_Loan').value / 12
			);
	}

	calculatePaymentCUFee() {
		this.applicationEntry.applicationForm
			.get('Sch_Payment_Plus_CU_Fee')
			.patchValue(
				this.applicationEntry.applicationForm.get('Tertiary_Annual_Income')
					.value - this.applicationEntry.applicationForm.get('CU_Fee').value
			);
	}

	calculateLPPScheduledPayment() {
		this.applicationEntry.applicationForm
			.get('LPP_with_Scheduled_Payment')
			.patchValue(
				this.applicationEntry.applicationForm.get('Tertiary_Annual_Income')
					.value +
					this.applicationEntry.applicationForm.get('Loan_Protection').value
			);
	}

	calculateINS() {
		this.applicationEntry.applicationForm
			.get('LPP_Total')
			.patchValue(
				this.applicationEntry.applicationForm.get('Loan_Protection').value -
					this.applicationEntry.applicationForm.get('LPP_Taxes').value
			);
	}
}
