<div style="text-align: left;">
	<span>
		<h4><mat-icon> bar_chart</mat-icon> Summary</h4>
		<dl style="font-size: x-large; padding: 30px 0px 0px 15px;">
			<dt class="totalStyle" *ngFor="let item of params?.summaryData">
				{{ item.label }}:
				<span *ngIf="item.value>0 ; else zeroFiles"><b style="color:red">{{ item.value }}</b></span>
				<ng-template #zeroFiles>
					<b style="color:black">{{ item.value }}</b>
				</ng-template>
			</dt>
		</dl>
	</span>
</div>