<mat-radio-group style="margin-left: 25px;" aria-label="Select an option" [(ngModel)]="isDefault"
    (change)="getApplicantTypeBanks()">
    <mat-radio-button value="primary">Primary</mat-radio-button>
    <mat-radio-button *ngIf="Co_Appl_Required" value="co_applicant">Co-Applicant</mat-radio-button>
</mat-radio-group>
<div class="container-fluid" style="display: flex;">
    <div class="sideMenu" style="position: relative;">
        <mat-spinner *ngIf="loadAllBanks" [diameter]="20" class="custom-spinner"></mat-spinner>
        <div *ngIf="!loadAllBanks">
            <ul *ngFor="let bank of applicantTypeBanks">
                <li (click)="displayBankingInformation(bank.guid)"
                    [ngClass]="{'active-item': activeGuid === bank.guid}">
                    <span class="icon-wrapper">
                        <mat-icon *ngIf="activeGuid === bank.guid">label</mat-icon>
                    </span>
                    <span>{{bank?.bank_name}}</span>
                </li>
            </ul>
        </div>
    </div>
    <div style="margin-left: 15px;width: 100%; position: relative;">
        <p *ngIf="!isVerified">No banking statements available!</p>
        <mat-spinner *ngIf="loadStatements" class="custom-spinner"></mat-spinner>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms"
            *ngIf="isVerified && !loadStatements">
            <mat-tab *ngFor="let account of bankStatement;"
                [label]="account?.type.concat(' [').concat(currentBalance(account?.current_balance)).concat(']')">
                <div style="padding:25px; ">
                    <table>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>3 Months</th>
                                <th>6 Months</th>
                                <th>9 Months</th>
                                <th>12 Months</th>
                                <th>All Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Number of NSFs</td>
                                <td>{{account?.statistics?.quarter_3_months?.number_of_nsf}}</td>
                                <td>{{account?.statistics?.quarter_6_months?.number_of_nsf}}</td>
                                <td>{{account?.statistics?.quarter_9_months?.number_of_nsf}}</td>
                                <td>{{account?.statistics?.quarter_12_months?.number_of_nsf}}</td>
                                <td>{{account?.statistics?.quarter_all_time?.number_of_nsf}}</td>
                            </tr>
                            <tr>
                                <td>Number of Overdraft</td>
                                <td>{{account?.statistics?.quarter_3_months?.number_of_overdraft}}</td>
                                <td>{{account?.statistics?.quarter_6_months?.number_of_overdraft}}</td>
                                <td>{{account?.statistics?.quarter_9_months?.number_of_overdraft}}</td>
                                <td>{{account?.statistics?.quarter_12_months?.number_of_overdraft}}</td>
                                <td>{{account?.statistics?.quarter_all_time?.number_of_overdraft}}</td>
                            </tr>
                            <tr>
                                <td>Deposits</td>
                                <td>{{account?.statistics?.quarter_3_months?.amount_of_deposit | currency}}</td>
                                <td>{{account?.statistics?.quarter_6_months?.amount_of_deposit | currency}}</td>
                                <td>{{account?.statistics?.quarter_9_months?.amount_of_deposit | currency}}</td>
                                <td>{{account?.statistics?.quarter_12_months?.amount_of_deposit | currency}}</td>
                                <td>{{account?.statistics?.quarter_all_time?.amount_of_deposit | currency}}</td>
                            </tr>
                            <tr>
                                <td>Withdrawals</td>
                                <td>{{account?.statistics?.quarter_3_months?.amount_of_withdrawal | currency}}</td>
                                <td>{{account?.statistics?.quarter_6_months?.amount_of_withdrawal | currency}}</td>
                                <td>{{account?.statistics?.quarter_9_months?.amount_of_withdrawal | currency}}</td>
                                <td>{{account?.statistics?.quarter_12_months?.amount_of_withdrawal | currency}}</td>
                                <td>{{account?.statistics?.quarter_all_time?.amount_of_withdrawal | currency}}</td>
                            </tr>
                            <tr>
                                <td>Cashflow</td>
                                <td [ngStyle]="cashflowColor(account?.statistics?.quarter_3_months?.amount_of_deposit -
                                account?.statistics?.quarter_3_months?.amount_of_withdrawal)">
                                    {{cashflow(account?.statistics?.quarter_3_months?.amount_of_deposit -
                                    account?.statistics?.quarter_3_months?.amount_of_withdrawal)}}
                                </td>
                                <td [ngStyle]="cashflowColor(account?.statistics?.quarter_6_months?.amount_of_deposit -
                                account?.statistics?.quarter_6_months?.amount_of_withdrawal)">
                                    {{cashflow(account?.statistics?.quarter_6_months?.amount_of_deposit -
                                    account?.statistics?.quarter_6_months?.amount_of_withdrawal)}}</td>
                                <td [ngStyle]="cashflowColor(account?.statistics?.quarter_9_months?.amount_of_deposit -
                                account?.statistics?.quarter_9_months?.amount_of_withdrawal)">
                                    {{cashflow(account?.statistics?.quarter_9_months?.amount_of_deposit -
                                    account?.statistics?.quarter_9_months?.amount_of_withdrawal)}}</td>
                                <td [ngStyle]="cashflowColor(account?.statistics?.quarter_12_months?.amount_of_deposit -
                                account?.statistics?.quarter_12_months?.amount_of_withdrawal)">
                                    {{cashflow(account?.statistics?.quarter_12_months?.amount_of_deposit -
                                    account?.statistics?.quarter_12_months?.amount_of_withdrawal)}}</td>
                                <td [ngStyle]="cashflowColor(account?.statistics?.quarter_all_time?.amount_of_deposit -
                                account?.statistics?.quarter_all_time?.amount_of_withdrawal)">
                                    {{cashflow(account?.statistics?.quarter_all_time?.amount_of_deposit -
                                    account?.statistics?.quarter_all_time?.amount_of_withdrawal)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>