import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
// @ts-ignore
import {
	ModalDismissReasons,
	NgbActiveModal,
	NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ICustomModalOptions } from '../services/modal/custom-modal-options';
import { BehaviorSubject } from 'rxjs';
import { CustomModalService } from '../services/modal/custom-modal.service';

@Component({
	selector: 'app-custom-modal',
	templateUrl: './custom-modal.component.html',
	styleUrls: ['./custom-modal.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [NgbActiveModal],
})
export class CustomModalComponent implements OnInit, OnChanges {
	closeResult = new BehaviorSubject<any>('');
	modalActive;
	modalRef;
	@ViewChild('modalContent', { static: false }) modal: ElementRef;

	@Input() modalOptions: ICustomModalOptions = {
		activateModal: false,
		modalName: '',
		headerTitle: '',
		bodyText: '',
		buttons: ['Cancel'],
		options: {}
	};

	constructor(
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private customModalService: CustomModalService
	) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.modalOptions) {
			this.modalActive = changes.modalOptions?.currentValue?.activateModal;
			if (this.modalActive) {
				this.openModalDialogCustomClass();
			} else if (this.modalActive === false) {
				this.closeModal('inactive-modal');
			}
		}
	}
	openModalDialogCustomClass() {
		const hasOpenModals = this.modalService.hasOpenModals();
		const delayToOpenModal = hasOpenModals ? 2000 : 0;
		setTimeout(() => {
			this.modalRef = this.modalService.open(this.modal, {
				modalDialogClass: 'dark-modal',
				centered: true,
				size: 'l',
				scrollable: true,
				animation: !hasOpenModals,
			});
			this.modalRef.result.then(
				(result) => {
					this.closeResult.next(`Closed: ${result}`);
					this.customModalService.closeModal({
						result,
						...this.modalOptions,
					});
				},
				(reason) => {
					const dismissReason = this.getDismissReason(reason);
					this.closeResult.next(`Dismissed: ${dismissReason}`);
					this.customModalService.closeModal({
						reason,
						...this.modalOptions,
					});
				}
			);
		}, delayToOpenModal);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'BACKDROP_CLICK';
		} else {
			return `${reason}`;
		}
	}

	closeModal(button) {
		this.activeModal.close(button);
		this.modalRef.close(button);
		if (this.modalService.hasOpenModals()) {
			this.modalService.dismissAll();
		}
	}

	dismissModal(button) {
		this.activeModal.dismiss(button);
		this.modalRef.dismiss(button);
	}
}
